// Hooks
import { PCBAssemblyFeederSetAddEditModal } from "../modals/AddEditPcbAssemblyFeederSet";
import { PCBAssemblyFeederSetNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { usePcbAssemblyFeederSetList } from "nestor/hooks/api/usePcbAssembly";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssemblyFeederSet } from "nestor/types/manufacturing/pcb_assembly";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// Classes
// Components
// Contexts
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorPcbAssemblyFeederSet;
type FWLst = {};
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;
type HOOK = {};

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      PCBAssemblyFeederSetNavigationColumn,
      //            NUIDColumn,

      SimpleColumn("name", "Name", (e) => e.name),

      //   SimpleColumn("sku", "SKU", (e) => e.sku?.nuid),

      ButtonsColumn<T, FW, HOOK>("actions", "", [EditButton(), RemoveButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        _nav: "0px",
      };
    },
  });
})();

const GenericContainer = (function () {
  return HasDeletion(
    HasEdition(
      HasAddition(
        HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
        PCBAssemblyFeederSetAddEditModal,
        "Add a new assembly project",
        "plus",
        "primary"
      ),
      PCBAssemblyFeederSetAddEditModal
    ),

    "Confirm feeder set deletion",
    (v: T) => `Are you sure to revoke the PCB feeder set "${v.name}" ?`,
    (props) => {
      return useNestorAPI<undefined, NestorPcbAssemblyFeederSet>(
        (el) => {
          assert(el !== undefined);
          return NestorServerRoutes.pcb_assembly_feeder_set_revoke(el.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();

export const PCBAssemblyFeederSetListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  return (
    <div className="m-2">
      <CardComponent header="PCB Assembly list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericContainer
            //cardHeader="Gateway list"
            list={usePcbAssemblyFeederSetList()}
            fwProps={{}}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
