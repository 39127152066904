import { useLaboratoryList } from "nestor/hooks/api/useLaboratoryList";
import { NestorLaboratory } from "nestor/types/company/laboratory";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { LaboratoryRenderer } from "../../renderers/LaboratoryRenderer";

export const useLaboratorySearchField = FormSearchHookFactory<NestorLaboratory, NestorLaboratory["id"]>({
  apiListFactory: useLaboratoryList,
  template: GenericListRenderer((el) => <LaboratoryRenderer laboratory={el} />),
  traverser_display: (el) => {
    return `${el.name} ${el.room ? `(${el.room.name}) ` : ""} `; // ${/*el.laboratory ? `(${el.laboratory.name})` : ""*/}
  },

  uniqueKeyField: "id",
  filterKeys: {
    name: "contains",
  },
});
