import { NestorRoom } from "nestor/types/company/room";

export const RoomRenderer = ({ room, fallbackText }: { room?: NestorRoom | null; fallbackText?: string }) => {
  if (!room) {
    return <div className="text-muted">{fallbackText || null}</div>;
  }
  return (
    <>
      <div className="fw-bold">
        <em className="bi bi-door-open"></em> {room.displayed_name} {room.alternate_name || ""}
      </div>
      {room.floor?.building ? (
        <div className="text-muted">
          <div className="text-muted">
            <em className="bi bi-building"></em> {room.floor.building.name} - Fl {room.floor.floor}
          </div>
        </div>
      ) : null}
    </>
  );
};
