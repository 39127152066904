import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { LaboratoryList } from "./LaboratoryList";

export function LaboratoriesMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List of laboratories", url + "/list");
  // const create = useMenuElement("Create a new device", url + "/create");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...list}>
          <LaboratoryList></LaboratoryList>
        </XemwayMenuProvider>
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
