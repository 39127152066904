import { ContainerActions } from "nestor/components/nestor/stock/containers/Actions";
import { ContainerContent } from "nestor/components/nestor/stock/containers/Content";
import { ContainerEditCreateForm } from "nestor/components/nestor/stock/containers/Edit";
import { ContainerInfos, ContainerOneLine } from "nestor/components/nestor/stock/containers/Info";
import { SkuJournalCard } from "nestor/components/nestor/stock/Journal";
import { NestorPermissionContextProvider, useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useContainerSingle } from "nestor/hooks/api/useContainerSingle";
import { NestorContainer } from "nestor/types/stock/container";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

const ContainerDetailInner: React.FunctionComponent<{
  container: NestorContainer;
  refresh: Function;
}> = (props) => {
  const route = useRouteMatch<{ container: string }>();

  const canEdit = useHasPermission("edit");

  useMenuElement("Details", route.url + "/details");
  useMenuElement("Edit information", route.url + "/edit", canEdit);
  useMenuElement("Journal", route.url + "/journal");
  useMenuElement("Content (PCB, Inventory & Containers)", route.url + "/content");
  useMenuElement("Actions", route.url + "/actions");

  return (
    <Switch>
      <Route path={`${route.url}/details`}>
        <ContainerInfos container={props.container} refresh={props.refresh}></ContainerInfos>
      </Route>

      {canEdit ? (
        <Route path={`${route.url}/edit`}>
          <ContainerOneLine container={props.container} refresh={props.refresh}></ContainerOneLine>
          <ContainerEditCreateForm container={props.container}></ContainerEditCreateForm>
        </Route>
      ) : null}

      <Route path={`${route.url}/journal`}>
        <ContainerOneLine container={props.container} refresh={props.refresh}></ContainerOneLine>
        <SkuJournalCard container={props.container}></SkuJournalCard>
      </Route>

      <Route path={`${route.url}/content`}>
        <ContainerOneLine container={props.container} refresh={props.refresh}></ContainerOneLine>
        <ContainerContent container={props.container}></ContainerContent>
      </Route>

      <Route path={`${route.url}/actions`}>
        <ContainerOneLine container={props.container} refresh={props.refresh}></ContainerOneLine>
        <ContainerActions container={props.container}></ContainerActions>
      </Route>

      <Route>
        <Redirect to={`${route.url}/details`} />
      </Route>
    </Switch>
  );
};

export const ContainerDetail: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ container: string }>();
  const container = useContainerSingle(route.params.container);
  const menu = useMenuElement(
    container.element ? `Container ${container.element.nuid || "-- no nuid"}` : "Loading...",
    `${route.url}/${route.params.container}`
  );

  return (
    <XemwayMenuProvider {...menu}>
      {container.loadingIO}
      <NestorPermissionContextProvider ressource="container" id={route.params.container}>
        <div className="container-fluid p-3">
          {container.element ? (
            <ContainerDetailInner container={container.element} refresh={container.refresh} />
          ) : null}
        </div>
      </NestorPermissionContextProvider>
    </XemwayMenuProvider>
  );
};
