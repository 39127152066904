import { MsalContext } from "@azure/msal-react";
import { MenuContainer } from "@csem/shared-utils";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

export const MainView: React.FunctionComponent<{
  menuTop?: JSX.Element;
  menuBottom?: JSX.Element;
  children: React.ReactNode;
}> = (props) => {
  const msalCtx = useContext(MsalContext);
  return (
    <div className="row vh-100 g-0">
      <div className="col-sm-3 col-lg-2">
        <div className="navbar navbar-expand-sm" id="xem-menu">
          <span className="navbar-brand">
            <Link to="/">
              <img alt="CSEM logo" className="logo" src={process.env.PUBLIC_URL + "/logo_csem.png"} />
            </Link>
          </span>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#xem-menu-content">
            <i className="bi-menu-down text-light"></i>
          </button>

          <div id="xem-menu-content" className="collapse navbar-collapse  flex-column align-items-stretch">
            {props.menuTop || null}
            <div className="d-flex flex-column align-items-stretch">
              <MenuContainer />

              <div className="align-self-end me-2">
                {
                  <a onClick={() => msalCtx.instance.logoutRedirect()}>
                    <i className="bi bi-box-arrow-right"></i>
                    &nbsp; Logout
                  </a>
                }
              </div>
              {props.menuBottom || null}
            </div>
          </div>
        </div>
      </div>
      <div className=" col-sm-9 col-lg-10" id="xem-main">
        {props.children}
      </div>
    </div>
  );
};
