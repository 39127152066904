import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorTask } from "nestor/types/manufacturing/task";
import assert from "assert";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormTextareaField } from "@csem/forms";
import { useTaskProgressHours } from "../../../../modals/AddEditTaskProgress";

export const TerminateTaskModal = StandardFormAddEditModalFactory<NestorTask, any, any>({
  title: (el) => (!el ? "" : `Terminate the task`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    assert(el);

    const hours = useTaskProgressHours();

    const description_deadline = useFormTextareaField({
      label: "Deadline exceeded (reasons)",
      originalValue: el?.description_deadline,
      defaultValue: "",
    });

    const description_deliverables = useFormTextareaField({
      label: "Deliverables",
      originalValue: el?.description_deliverables,
      defaultValue: "",
    });

    const apiResult = useNestorAPI(NestorServerRoutes.task_terminate(el.id), false, undefined, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        hours: hours.value,
        description_deadline: description_deadline.value,
        description_deliverables: description_deliverables.value,
      });
    };

    return {
      fields: {
        hours,
        description_deadline,
        description_deliverables,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
