import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import { NestorDateTimeString } from "../util/dates";
import { NestorBlob, NestorBlobId } from "./blob";

export type NestorAttachmentId = number;

enum NestorAttachableType {
  PCBLAYOUT = "layout",
  PCB = "pcb",
  DEVICE_MODEL = "model",
  ORDER = "order",
  TASK = "task",
}

export enum NestorAttachmentTag {
  DELIVERY_NOTE = "deliverynote",
  DELIVERY = "delivery",
  INCOMING_INSPECTION = "incomingInspection",
  METROLOGICAL_RECORD = "metrological_record",
  METROLOGICAL_PROCEDURE = "metrological_procedure",
  PICTURE = "picture",
  USER_MANUAL = "user_manual",
  INVOICE = "invoice",
  PHOTO = "photo",
  INSPECTION = "inspection",
  REPORT = "report",
  MANUAL = "manual",
  PO = "po",
  ORDER_CONFIRMATION = "order_confirmation",
  OFFER = "Offer",
  CERTIFICATE_CONFORIMITY = "certificate_conformity",
  OTHER = "Other",
  QUALIFICATION_REPORT = "qualification_report",
  QUALITY_AGREEMENT_PLAN = "quality_agreement",
  CALIBRATION_CERTIFICATE = "calibration_certificate",
  CERTIFICATE = "other_certificate",
  MINUTES_OF_MEETING = "minutes_meeting",
  MSDS = "MSDS",
  EF611 = "EF611"
}

export type NestorAttachment = {
  id: NestorAttachmentId;

  attachable_type: NestorAttachableType;
  attachable_id: number;

  name: string | null;
  comment: string | null;
  tag: NestorAttachmentTag;
  employee_id: NestorEmployeeId;
  employee?: NestorEmployee;

  blob_id: NestorBlobId;
  blob?: NestorBlob;

  deleted_at: NestorDateTimeString | null;
  created_at: NestorDateTimeString;
  updated_at: NestorDateTimeString | null;
};
