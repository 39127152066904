"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ButtonsColumn: () => ButtonsColumn,
  CheckboxFilterColumn: () => CheckboxFilterColumn,
  CheckboxFilterFactory: () => CheckboxFilterFactory,
  DefaultStandardTableFilter: () => DefaultStandardTableFilter,
  DefaultTableFilter: () => DefaultTableFilter,
  DivRenderer: () => DivRenderer,
  EditButton: () => EditButton,
  EmptyRow: () => EmptyRow,
  FilterableColumn: () => FilterableColumn,
  FormSearchHookFactory: () => FormSearchHookFactory,
  FormSearchMultHookFactory: () => FormSearchMultHookFactory,
  FormSearchOneHookFactory: () => FormSearchOneHookFactory,
  GenericListContent: () => GenericListContent,
  GenericListContentBasic: () => GenericListContentBasic,
  HasAddition: () => HasAddition,
  HasButton: () => HasButton,
  HasDeletion: () => HasDeletion,
  HasEdition: () => HasEdition,
  HasInlineEdition: () => HasInlineEdition,
  HasModalMethod: () => HasModalMethod,
  HasPagination: () => HasPagination,
  HasRefresh: () => HasRefresh,
  HasRevokation: () => HasRevokation,
  HasSearch: () => HasSearch,
  HasStdTablePagination: () => HasStdTablePagination,
  HasTableFilterClear: () => HasTableFilterClear,
  HelpColumn: () => HelpColumn,
  HtmlColumn: () => HtmlColumn,
  IsSelectable: () => IsSelectable,
  ListRenderer: () => ListRenderer,
  NavigationColumn: () => NavigationColumn,
  NavigationLookup: () => NavigationLookup,
  RemoveButton: () => RemoveButton,
  RevokeButton: () => RevokeButton,
  SearchElFilterFactory: () => SearchElFilterFactory,
  SelectButton: () => SelectButton,
  SimpleColumn: () => SimpleColumn,
  SortableColumn: () => SortableColumn,
  StandardTableFilterContext: () => StandardTableFilterContext,
  StandardTableFilterCtxProvider: () => StandardTableFilterCtxProvider,
  TableFilterContext: () => TableFilterContext,
  TableFilterCtxProvider: () => TableFilterCtxProvider,
  TableRenderer: () => TableRenderer,
  TableSearch: () => TableSearch,
  TextFilterFactory: () => TextFilterFactory,
  createFilterCtxProvider: () => createFilterCtxProvider,
  createTableFilterContext: () => createTableFilterContext,
  groupedColumn: () => groupedColumn,
  useActiveElement: () => useActiveElement,
  useAutoSearchTrigger: () => useAutoSearchTrigger,
  useTableFilterChanged: () => useTableFilterChanged,
  useTableSearchBoxLogic: () => useTableSearchBoxLogic
});
module.exports = __toCommonJS(src_exports);

// src/tables/filters/Factories/Checkbox.tsx
var import_jsx_runtime = require("react/jsx-runtime");
var CheckboxFilterFactory = (factory_props) => {
  return function Filter(props) {
    return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(import_jsx_runtime.Fragment, {
      children: factory_props.filters.map((f) => {
        var _a;
        return /* @__PURE__ */ (0, import_jsx_runtime.jsx)("div", {
          className: "mb-2",
          children: /* @__PURE__ */ (0, import_jsx_runtime.jsxs)("label", {
            children: [
              /* @__PURE__ */ (0, import_jsx_runtime.jsx)("input", {
                className: "checkbox",
                type: "checkbox",
                checked: !!((_a = props.originalValue) == null ? void 0 : _a[f.filterKey]),
                onChange: (e) => {
                  props.onChange(e.target.checked ? f.on : f.off, "eq", f.filterKey);
                }
              }),
              " ",
              f.label
            ]
          })
        }, f.filterKey);
      })
    });
  };
};

// src/filtering/FilterContext.tsx
var import_shared_utils = require("@csem/shared-utils");
var import_lodash = __toESM(require("lodash"));
var import_react = __toESM(require("react"));
var import_react_router_dom = require("react-router-dom");

// src/filtering/FilterTypes.ts
var DefaultTableFilter = {
  take: 20,
  skip: 0,
  sort: [],
  filter: {
    logic: "and",
    filters: [
      {
        logic: "and",
        filters: []
      },
      {
        logic: "and",
        filters: [
          { logic: "or", filters: [] },
          { logic: "and", filters: [] }
        ]
      }
    ]
  }
};

// src/filtering/FilterContext.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
function createTableFilterContext() {
  return import_react.default.createContext(void 0);
}
function createFilterCtxProvider(_defaultValue, Context, serialize, deserialize) {
  return ({
    enablePersistance = true,
    defaultValue,
    persistKey,
    persistWithURL,
    ...props
  }) => {
    const [state, setState] = (0, import_react.useState)(
      defaultValue ? {
        default: defaultValue,
        val: defaultValue
      } : {
        default: import_lodash.default.cloneDeep(_defaultValue),
        val: import_lodash.default.cloneDeep(_defaultValue)
      }
    );
    const { url } = (0, import_react_router_dom.useRouteMatch)();
    const sState = (0, import_react.useMemo)(() => serialize(state.val), [state]);
    const persistState = (0, import_shared_utils.usePersist)(sState, persistWithURL ? url : "", persistKey || "");
    (0, import_react.useEffect)(() => {
      if (persistState && enablePersistance) {
        setState((s) => {
          return {
            ...s,
            val: deserialize(persistState, s.val, defaultValue)
          };
        });
      }
    }, [persistState, enablePersistance, defaultValue]);
    (0, import_react.useEffect)(() => {
      if (defaultValue) {
        setState((s) => {
          if (defaultValue !== s.default) {
            return {
              val: defaultValue,
              default: defaultValue
            };
          }
          return s;
        });
      }
    }, [defaultValue]);
    const _setState = (0, import_react.useMemo)(() => {
      return (v) => {
        setState((s) => {
          return {
            ...s,
            val: typeof v === "function" ? v(s.val) : v
          };
        });
      };
    }, []);
    return /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(Context.Provider, {
      value: { state: state.val, setState: _setState },
      children: props.children
    });
  };
}
var TableFilterContext = createTableFilterContext();
var TableFilterCtxProvider = createFilterCtxProvider(
  DefaultTableFilter,
  TableFilterContext,
  (el) => el,
  (el, src) => el
);

// src/tables/filters/FilterTypes.ts
var DefaultStandardTableFilter = {
  take: 20,
  skip: 0,
  sort: [],
  filter: {
    logic: "and",
    filters: [
      { logic: "and", filters: [] },
      {
        logic: "and",
        filters: [
          { logic: "or", filters: [] },
          { logic: "and", filters: [] }
        ]
      }
    ]
  }
};

// src/tables/filters/FilterContext.tsx
var StandardTableFilterContext = createTableFilterContext();
var StandardTableFilterCtxProvider = createFilterCtxProvider(
  DefaultStandardTableFilter,
  StandardTableFilterContext,
  (e) => e.filter.filters[1].filters,
  (e, t, d) => {
    let _t = { ...t };
    if (d) {
      _t.filter.filters[0] = d.filter.filters[0];
      _t.sort = d.sort;
    }
    if (!Array.isArray(e)) {
      return _t;
    }
    _t.filter.filters[1].filters = e;
    return _t;
  }
);

// src/tables/StandardTable.tsx
var import_assert = __toESM(require("assert"));
var import_lodash2 = __toESM(require("lodash"));
var import_lodash3 = require("lodash");
var import_react2 = require("react");
var import_react_router_dom2 = require("react-router-dom");
var DOMPurify = __toESM(require("dompurify"));
var import_jsx_runtime3 = require("react/jsx-runtime");
var useTableFilterChanged = function(field) {
  let ctxFilter = (0, import_react2.useContext)(StandardTableFilterContext);
  const setState = ctxFilter == null ? void 0 : ctxFilter.setState;
  return (0, import_react2.useCallback)(
    (v, filterableOperator, fieldKey) => {
      if (!setState) {
        return;
      }
      setState((s) => {
        (0, import_assert.default)(field);
        let s2 = (0, import_lodash3.cloneDeep)(s || DefaultStandardTableFilter);
        let _field;
        let nest = false;
        if (typeof field === "object") {
          if (!fieldKey) {
            return s;
          }
          const fieldFromKey = field[fieldKey];
          if (Array.isArray(fieldFromKey)) {
            nest = true;
            _field = fieldFromKey;
          } else {
            _field = [fieldFromKey];
          }
        } else {
          _field = [field];
        }
        if (field !== void 0) {
          s2.filter.filters[1].filters[1] = cleanFilter(s2.filter.filters[1].filters[1], _field);
        }
        if (typeof v === "string" || typeof v === "number" || Array.isArray(v) || v === null) {
          if (nest) {
            s2.filter.filters[1].filters[1].filters.push({
              logic: "or",
              filters: _field.map((field2) => {
                return {
                  field: field2,
                  operator: filterableOperator || "contains",
                  value: v
                };
              })
            });
          } else {
            _field.forEach((field2) => {
              s2.filter.filters[1].filters[1].filters.push({
                field: field2,
                operator: filterableOperator || "contains",
                value: v
              });
            });
          }
        }
        s2.skip = 0;
        return s2;
      });
    },
    [setState, field]
  );
};
var _findFilter = (filter, field, filters) => {
  if (filters === void 0) {
    filters = {};
  }
  const _fieldsToLookFor = {};
  if (typeof field == "string") {
    _fieldsToLookFor.__default = [field];
  } else {
    for (let [filterKey, fieldNames] of Object.entries(field)) {
      if (Array.isArray(fieldNames)) {
        _fieldsToLookFor[filterKey] = fieldNames;
      } else {
        _fieldsToLookFor[filterKey] = [fieldNames];
      }
    }
  }
  for (let filter_it of filter.filters) {
    if ("field" in filter_it) {
      for (let [filterKey, fieldNames] of Object.entries(_fieldsToLookFor)) {
        if (fieldNames.includes(filter_it.field)) {
          filters[filterKey] = filter_it.value;
        }
      }
    } else {
      _findFilter(filter_it, field, filters);
    }
  }
  return filters;
};
var findFilter = (filter, field) => {
  const filters = _findFilter(filter, field, void 0);
  if (Object.values(filters).length > 0) {
    return {
      found: true,
      filters
    };
  }
  return { found: false };
};
function cleanFilter(filter, field) {
  filter.filters = filter.filters.filter((f) => {
    if ("field" in f) {
      return field.indexOf(f.field) === -1;
    } else {
      const o = cleanFilter(f, field);
      if (o.filters.length === 0) {
        return void 0;
      } else {
        return o;
      }
    }
  });
  return filter;
}
var groupedColumn = (columns, groupTitle) => {
  return Object.assign(columns, { group: groupTitle });
};
var SortableColumn = (column, field, firstIsAsc) => {
  (0, import_assert.default)(field || column.field, "One of the existing or overwriting field must be defined");
  if (field && (column.field === field || !column.field) || !field && column.field) {
    return Object.assign(column, {
      field: field || column.field,
      sortable: true,
      sortableFirstIsAsc: firstIsAsc === void 0 ? true : false
    });
  } else {
    (0, import_assert.default)(false, `Fields ${field} and ${column.field} don't match`);
  }
};
var FilterableColumn = (column, filter, field) => {
  (0, import_assert.default)(field || column.field, "One of the existing or overwriting field must be defined");
  if (field && (column.field === field || !column.field) || !field && column.field) {
    return Object.assign(column, {
      field: field || column.field,
      filterable: filter
    });
  } else {
    (0, import_assert.default)(false, `Fields ${field} and ${column.field} don't match`);
  }
};
var CheckboxFilterColumn = (name, label, field, renderer, filters) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el) => {
      return renderer(el);
    }),
    CheckboxFilterFactory({
      label: "Tags",
      filters
    }),
    field
  );
};
var NavigationLookup = ({ icon = "search", ...props }) => {
  return /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(import_react_router_dom2.NavLink, {
    to: props.path,
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("em", {
        className: `bi bi-${icon}`
      }),
      " ",
      props.text ? props.text : ""
    ]
  });
};
var NavigationColumn = (name, title, path) => {
  return {
    name,
    label: title,
    renderer: function(el, fw) {
      return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(NavigationLookup, {
        path: path(el, fw)
      });
    }
  };
};
var ButtonsColumn = (name, title, buttons) => {
  return {
    name,
    label: title,
    renderer: function(el, fw, hk) {
      return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("div", {
        className: "d-flex flex-nowrap",
        children: buttons.map((btn) => {
          if (btn.visibility && !btn.visibility(el, fw, hk)) {
            return null;
          }
          const theme = import_lodash2.default.isFunction(btn.theme) ? btn.theme(el, fw) : btn.theme;
          const title2 = import_lodash2.default.isFunction(btn.title) ? btn.title(el, fw) : btn.title;
          return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("button", {
            className: `btn btn-${theme}`,
            onClick: (e) => btn.cb(el, fw, hk),
            children: title2
          }, title2);
        })
      });
    }
  };
};
var SimpleColumn = (name, title, renderer) => {
  return {
    name,
    label: title,
    renderer
  };
};
var HelpColumn = (column, text) => {
  return {
    ...column,
    help: text
  };
};
var HtmlColumn = (name, title, renderer, maxHeight = 100) => {
  const _renderer = (value, fw, hookOut) => {
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("div", {
      style: { maxHeight: maxHeight + "px", overflowY: "auto" },
      dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(renderer(value, fw, hookOut)) }
    });
  };
  return {
    name,
    label: title,
    renderer: _renderer
  };
};
var EmptyRow = (props) => {
  if (props.when) {
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("tr", {
      children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("td", {
        colSpan: props.span,
        className: "text-center p-4 text-muted",
        children: "Nothing to show"
      })
    });
  }
  return null;
};
var TableRenderer = ({
  key,
  columns,
  options,
  useColumnVisibility = () => {
    return {};
  },
  useColumnWidth = () => {
    return {};
  },
  useRowVisibility = () => {
    return true;
  },
  useLineParamInjection,
  headerGroups = false
}) => {
  const RowTemplate = function RowTemplate2(props) {
    const out = useLineParamInjection(props.el, props);
    const visible = useRowVisibility(props.el, props);
    if (!visible) {
      return null;
    }
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("tr", {
      children: columns.map((c) => {
        if (props.columnVisibility[c.name] === false) {
          return null;
        }
        return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("td", {
          children: c.renderer(props.el, props, out)
        }, c.name);
      })
    });
  };
  const SortAndFilter = ({ ...props }) => {
    var _a, _b, _c, _d;
    const FilterUI = props.filterable;
    const field = props.field;
    let ctxFilter = (0, import_react2.useContext)(StandardTableFilterContext);
    const ref = (0, import_react2.useRef)(null);
    const filterChanged = useTableFilterChanged(field);
    const state = ctxFilter == null ? void 0 : ctxFilter.state;
    const filtered = (0, import_react2.useMemo)(() => {
      const o = field !== void 0 && state && state.filter ? findFilter(state.filter.filters[1].filters[1], field) : { found: false };
      return o;
    }, [state, field]);
    if (!props.field || !ctxFilter) {
      return null;
    }
    const sortedAsc = ((_b = (_a = ctxFilter.state) == null ? void 0 : _a.sort[0]) == null ? void 0 : _b.field) === props.field ? ((_d = (_c = ctxFilter.state) == null ? void 0 : _c.sort[0]) == null ? void 0 : _d.dir) === "asc" : void 0;
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(import_jsx_runtime3.Fragment, {
      children: [
        props.sortable ? /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("button", {
          onClick: (e) => {
            (0, import_assert.default)(ctxFilter);
            ctxFilter.setState((s) => {
              (0, import_assert.default)(props.field);
              let s2 = Object.assign({}, s || DefaultStandardTableFilter);
              let field2;
              if (typeof props.field === "object") {
                const first = Object.values(props.field)[0];
                (0, import_assert.default)(!Array.isArray(first));
                field2 = first;
              } else {
                field2 = props.field;
              }
              s2.sort = [
                {
                  field: field2,
                  dir: sortedAsc === void 0 ? props.sortableFirstIsAsc ? "asc" : "desc" : sortedAsc ? "desc" : "asc"
                }
              ];
              return s2;
            });
          },
          className: "btn btn-sm btn-default p-1",
          children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("i", {
            className: `bi ${sortedAsc === true ? "bi-sort-up" : sortedAsc === false ? "bi-sort-down" : "bi-sort-up text-black-50"}`
          })
        }) : null,
        ctxFilter && props.filterable ? /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", {
          className: "dropdown",
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("button", {
              className: "btn btn-sm btn-default p-1",
              "data-bs-toggle": "dropdown",
              onClick: () => {
                if (ref.current) {
                  ref.current.focus();
                }
              },
              children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("i", {
                className: `bi bi-${filtered.found ? "funnel-fill" : "funnel"}`
              })
            }),
            /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("div", {
              className: "dropdown-menu p-3 fw-normal",
              style: { minWidth: "320px" },
              children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(FilterUI, {
                ref,
                onChange: filterChanged,
                originalValue: filtered.found ? filtered.filters : void 0
              })
            })
          ]
        }) : null
      ]
    });
  };
  let ColumnHeader = (props) => {
    if (props.visible === false) {
      return null;
    }
    let style = {};
    if (props.width) {
      style.width = props.width;
    }
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("th", {
      style,
      children: /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", {
        className: "d-flex justify-content-between align-items-end",
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", {
            children: [
              props.label === void 0 ? props.name : typeof props.label == "function" ? props.label(props.fw) : props.label,
              props.help ? /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(import_jsx_runtime3.Fragment, {
                children: [
                  "\xA0",
                  /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("span", {
                    className: "text-decoration-underline",
                    title: props.help,
                    children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("sup", {
                      children: "?"
                    })
                  })
                ]
              }) : null
            ]
          }),
          /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("div", {
            className: "d-flex",
            children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(SortAndFilter, {
              ...props
            })
          })
        ]
      })
    });
  };
  let Table = function Table2(props) {
    var _a, _b;
    let useHkVis;
    if (props.fwProps.useColumnVisibility) {
      useHkVis = props.fwProps.useColumnVisibility;
    } else {
      useHkVis = useColumnVisibility;
    }
    const columnVisibility = useHkVis(
      columns.map((c) => c.name),
      props.fwProps
    );
    const columnWidth = useColumnWidth(
      columns.map((c) => c.name),
      props.fwProps
    );
    const groups = [];
    if (headerGroups) {
      columns.sort((a, b) => {
        if (a.group && b.group) {
          return a.group < b.group ? -1 : 1;
        }
        return 0;
      });
      let lastGroup = void 0;
      for (let c of columns) {
        if (c.group === lastGroup && groups.length > 0) {
          groups[groups.length - 1].cols += 1;
        } else {
          groups.push({ title: c.group || "", cols: 1 });
          lastGroup = c.group;
        }
      }
    }
    let nCols = 0;
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("div", {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("table", {
          className: `table ${(options == null ? void 0 : options.striped) === false ? "" : "table-striped"} ${(options == null ? void 0 : options.small) ? "table-sm" : ""} align-middle csem-table`,
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("thead", {
              children: [
                headerGroups && /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("tr", {
                  children: groups.map((g) => {
                    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("th", {
                      colSpan: g.cols,
                      className: "text-center",
                      children: g.title
                    });
                  })
                }),
                /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("tr", {
                  children: columns.map((c) => {
                    nCols++;
                    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(ColumnHeader, {
                      width: columnWidth[c.name],
                      fw: props.fwProps,
                      visible: columnVisibility[c.name],
                      ...c
                    }, c.name);
                  })
                })
              ]
            }),
            /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)("tbody", {
              children: [
                ((_a = props.list.state) == null ? void 0 : _a.loading) ? /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("tr", {
                  children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("td", {
                    colSpan: nCols,
                    className: "text-center p-4 text-muted",
                    children: "Loading..."
                  })
                }) : null,
                /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(EmptyRow, {
                  span: nCols,
                  when: props.list.list && props.list.list.length === 0
                }),
                (_b = props.list.list) == null ? void 0 : _b.map((r) => {
                  return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(RowTemplate, {
                    columnVisibility,
                    ...props.fwProps,
                    el: r
                  }, r[key].toString());
                })
              ]
            })
          ]
        }),
        props.bottomContent
      ]
    });
  };
  return Table;
};

// src/Pagination.tsx
var import_assert2 = __toESM(require("assert"));
var import_react3 = require("react");
var import_jsx_runtime4 = require("react/jsx-runtime");
var PaginationAPI = function({
  page,
  pageSize,
  count,
  pageSpan,
  ctx
}) {
  (0, import_assert2.default)(ctx, "The filter context must be defined for the pagination to work.");
  const _ctx = (0, import_react3.useContext)(ctx);
  (0, import_assert2.default)(_ctx, "The filter context must exist for the pagination to work.");
  const { setState } = _ctx;
  let pageChange = (0, import_react3.useCallback)(
    (p) => {
      if (!pageSize) {
        return;
      }
      setState((s) => {
        (0, import_assert2.default)(s, "Doesn't exist !!");
        return {
          ...s,
          skip: (p - 1) * pageSize,
          take: pageSize
        };
      });
    },
    [setState, pageSize]
  );
  if (page !== void 0 && pageSize !== void 0 && count !== void 0) {
    return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Pagination, {
      onPageChange: pageChange,
      pageSpan,
      page,
      pageSize,
      count
    });
  } else {
    return null;
  }
};
var Item = ({
  page,
  children,
  selectedPage,
  numPages,
  onPageChange
}) => {
  const disabled = page < 1 || page > numPages;
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)("li", {
    style: { cursor: "pointer" },
    onClick: (e) => {
      e.stopPropagation();
      if (!disabled)
        onPageChange(page);
    },
    className: "page-item" + (disabled ? " disabled" : "") + (page === selectedPage ? " active" : ""),
    children: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)("button", {
      type: "button",
      className: `btn ${selectedPage === page ? "btn-primary" : "btn-link"}`,
      children
    })
  });
};
var Pagination = ({
  page,
  pageSize,
  count,
  onPageChange,
  pageSpan = 5
}) => {
  const numPages = Math.ceil(count / pageSize);
  const minPage = Math.max(page - pageSpan, 1);
  const maxPage = Math.min(page + pageSpan, numPages);
  const pages = [];
  for (let pageIt = minPage; pageIt <= maxPage; pageIt++) {
    pages.push(
      /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Item, {
        numPages,
        onPageChange,
        page: pageIt,
        selectedPage: page,
        children: pageIt
      }, pageIt)
    );
  }
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsxs)("ul", {
    className: "pagination justify-content-center",
    children: [
      /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Item, {
        numPages,
        onPageChange,
        page: Math.max(1, page - pageSpan * 2),
        children: "<<"
      }),
      minPage > 1 ? /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Item, {
        numPages,
        onPageChange,
        page: minPage - 1,
        children: "..."
      }) : null,
      pages,
      maxPage < numPages ? /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Item, {
        numPages,
        onPageChange,
        page: maxPage + 1,
        children: "..."
      }) : null,
      /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(Item, {
        numPages,
        onPageChange,
        page: Math.min(numPages, page + pageSpan * 2),
        children: ">>"
      })
    ]
  });
};

// src/Common.tsx
var import_api = require("@csem/api");
var import_shared_utils2 = require("@csem/shared-utils");
var import_shared_utils3 = require("@csem/shared-utils");
var import_lodash4 = require("lodash");
var import_react4 = require("react");
var import_jsx_runtime5 = require("react/jsx-runtime");
function _HasDeletion(CollectionComponent, deleteModalTitle, deleteModalText, useDeleteAPICall, methodName) {
  const Comp = function(props) {
    const deleteAPICall = useDeleteAPICall(props);
    const { Modal: DeleteModal, invoke } = (0, import_shared_utils2.useConfirmationModal)(
      deleteModalTitle,
      deleteModalText,
      deleteAPICall.doQuery,
      void 0,
      deleteAPICall
    );
    const { fwProps, listTemplate } = props;
    const ListTemplateComponentMod = (0, import_react4.useMemo)(() => {
      return function Comp2(_props) {
        const Tpl = listTemplate;
        return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(Tpl, {
          ..._props,
          fwProps: {
            ...fwProps,
            ..._props.fwProps,
            [methodName]: invoke
          }
        });
      };
    }, [listTemplate, fwProps, invoke]);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      listTemplate: ListTemplateComponentMod,
      additionalContent: [...props.additionalContent || [], DeleteModal]
    });
  };
  return Comp;
}
function HasDeletion(CollectionComponent, deleteModalTitle, deleteModalText, useDeleteAPICall) {
  return _HasDeletion(CollectionComponent, deleteModalTitle, deleteModalText, useDeleteAPICall, "remove");
}
function HasRevokation(CollectionComponent, deleteModalTitle, deleteModalText, useDeleteAPICall) {
  return _HasDeletion(CollectionComponent, deleteModalTitle, deleteModalText, useDeleteAPICall, "revoke");
}
function HasButton(CollectionComponent, key, position, text, icon, theme = "success", useVisibilityHook = () => true, clickCallback) {
  const Comp = function(props) {
    const visible = useVisibilityHook(props.fwProps);
    const btn = /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)("button", {
      className: `btn btn-${theme}`,
      onClick: () => clickCallback(props.list, props.fwProps),
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("i", {
          className: `bi bi-${icon}`
        }),
        " ",
        text
      ]
    }, key);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      righthandContent: [...props.righthandContent || [], visible && position === "right" ? btn : null],
      lefthandContent: [...props.lefthandContent || [], visible && position === "left" ? btn : null]
    });
  };
  return Comp;
}
function HasAddition(CollectionComponent, ModalFactory, addText, addIcon, btnTheme = "success", useVisibilityHook = () => true) {
  const Comp = function(props) {
    const { showModal, Modal } = (0, import_shared_utils3.useModalOfT)(ModalFactory, props.list.refresh);
    const visible = useVisibilityHook(props.fwProps);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      righthandContent: [
        ...props.righthandContent || [],
        visible ? /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)("button", {
          className: `btn btn-${btnTheme}`,
          onClick: () => showModal(void 0, props.fwProps),
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("i", {
              className: `bi bi-${addIcon}`
            }),
            " ",
            addText
          ]
        }, "__add") : null
      ],
      additionalContent: [...props.additionalContent || [], Modal]
    });
  };
  return Comp;
}
function HasInlineEdition(CollectionComponent, idMapper, useSave) {
  const Comp = function(props) {
    const [store, setStore] = (0, import_react4.useState)({});
    const commit = (0, import_react4.useCallback)((el) => {
      setStore((s) => {
        return {
          ...s,
          [idMapper(el)]: el
        };
      });
    }, []);
    let newPropsList = props.list;
    if (newPropsList.list) {
      const newList = [];
      for (let el of newPropsList.list) {
        if (store.hasOwnProperty(idMapper(el))) {
          newList.push(store[idMapper(el)]);
        } else {
          newList.push(el);
        }
      }
      newPropsList = {
        ...props.list,
        list: newList
      };
    }
    const { fwProps, listTemplate } = props;
    const save = useSave(props.fwProps);
    const ListTemplateComponentMod = (0, import_react4.useMemo)(() => {
      return function InnerComponentInlineEdition(_props) {
        const Tpl = listTemplate;
        return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(Tpl, {
          ..._props,
          fwProps: (0, import_react4.useMemo)(() => {
            return {
              ...fwProps,
              ..._props.fwProps,
              commit
            };
          }, [_props.fwProps])
        });
      };
    }, [commit, fwProps, listTemplate]);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)(import_jsx_runtime5.Fragment, {
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_api.IOState, {
          source: save.state
        }),
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
          ...props,
          list: newPropsList,
          listTemplate: ListTemplateComponentMod,
          righthandContent: [
            ...props.righthandContent || [],
            /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)("button", {
              className: `btn btn-primary`,
              onClick: () => save.doQuery(void 0, store),
              children: [
                /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("i", {
                  className: `bi bi-save`
                }),
                " Save"
              ]
            }, "__save")
          ]
        })
      ]
    });
  };
  return Comp;
}
function HasEdition(CollectionComponent, ModalFactory) {
  const Comp = function(props) {
    const { showModal, Modal } = (0, import_shared_utils3.useModalOfT)(ModalFactory, props.list.refresh);
    const { fwProps, listTemplate } = props;
    const ListTemplateComponentMod = (0, import_react4.useMemo)(() => {
      return function C(_props) {
        (0, import_react4.useEffect)(() => {
        }, []);
        const Tpl = listTemplate;
        return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(Tpl, {
          ..._props,
          fwProps: (0, import_react4.useMemo)(() => {
            return {
              ...fwProps,
              ..._props.fwProps,
              edit: showModal
            };
          }, [_props.fwProps])
        });
      };
    }, [fwProps, listTemplate, showModal]);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      additionalContent: [...props.additionalContent || [], Modal],
      listTemplate: ListTemplateComponentMod
    });
  };
  return Comp;
}
function HasModalMethod(CollectionComponent, ModalFactory, methodName) {
  const Comp = function(props) {
    const { showModal, Modal } = (0, import_shared_utils3.useModalOfT)(ModalFactory, props.list.refresh);
    const { fwProps, listTemplate } = props;
    const ListTemplateComponentMod = (0, import_react4.useMemo)(() => {
      return function InnerCompModalMethod(_props) {
        const Tpl = listTemplate;
        return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(Tpl, {
          ..._props,
          fwProps: (0, import_react4.useMemo)(() => {
            return {
              ...fwProps,
              ..._props.fwProps,
              [methodName]: showModal
            };
          }, [_props.fwProps])
        });
      };
    }, [listTemplate, showModal]);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      additionalContent: [...props.additionalContent || [], Modal],
      listTemplate: ListTemplateComponentMod
    });
  };
  return Comp;
}
function HasPagination(CollectionComponent, pageSpan = 5, ctx) {
  const Comp = function(props) {
    var _a, _b, _c;
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      bottomContent: [
        ...props.bottomContent || [],
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(PaginationAPI, {
          page: (_a = props.list.pagination) == null ? void 0 : _a.page,
          count: (_b = props.list.pagination) == null ? void 0 : _b.count,
          pageSize: (_c = props.list.pagination) == null ? void 0 : _c.pageSize,
          pageSpan,
          ctx
        }, "__pagination")
      ]
    });
  };
  return Comp;
}
function HasSearch(CollectionComponent, filterKeys, filterLogic = "or", searchLabel = "Search", SearchBox) {
  const Comp = function(props) {
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      lefthandContent: [
        ...props.lefthandContent || [],
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("div", {
          className: "col-4",
          children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(SearchBox, {
            label: searchLabel,
            filterKeys,
            filterLogic
          })
        }, "__search")
      ]
    });
  };
  return Comp;
}
function HasTableFilterClear(CollectionComponent) {
  const Comp = function(props) {
    const filter = (0, import_react4.useContext)(StandardTableFilterContext);
    let ctn = null;
    if (filter.state.filter.filters[1].filters[0].filters.length > 0 || filter.state.filter.filters[1].filters[1].filters.length > 0) {
      ctn = /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("button", {
        className: "btn btn-outline-danger",
        onClick: () => {
          filter.setState((s) => {
            const s2 = (0, import_lodash4.cloneDeep)(s);
            s2.unique = "";
            s2.filter.filters[1].filters[0].filters = [];
            s2.filter.filters[1].filters[1].filters = [];
            return s2;
          });
        },
        children: "Clear active filters"
      }, "__clear");
    }
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      lefthandContent: [...props.lefthandContent || [], ctn]
    });
  };
  return Comp;
}
function HasRefresh(CollectionComponent, btnStyle = "primary") {
  const Comp = function(props) {
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      righthandContent: [
        ...props.righthandContent || [],
        /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)("button", {
          className: `btn btn-${btnStyle}`,
          onClick: props.list.refresh,
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("i", {
              className: "bi bi-arrow-repeat"
            }),
            " Refresh"
          ]
        }, "__refresh")
      ]
    });
  };
  return Comp;
}
function IsSelectable(CollectionComponent, ctx, selectKey) {
  const Comp = function(props) {
    const selectableCtx = (0, import_react4.useContext)(ctx);
    const onSelect = (0, import_react4.useCallback)(
      (el) => {
        selectableCtx == null ? void 0 : selectableCtx.toggle(el, selectKey);
      },
      [selectableCtx]
    );
    const { fwProps } = props;
    const extendedFW = (0, import_react4.useMemo)(() => {
      let out = {
        ...fwProps,
        onSelect
      };
      if (selectableCtx) {
        out = { ...out, actionable: true };
      }
      return out;
    }, [onSelect, fwProps, selectableCtx]);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(CollectionComponent, {
      ...props,
      fwProps: extendedFW
    });
  };
  return Comp;
}
function useAutoSearchTrigger(list) {
  var _a;
  const ctx = (0, import_react4.useContext)(TableFilterContext);
  const altctx = (0, import_react4.useContext)(StandardTableFilterContext);
  const ctxState = (_a = ctx || altctx) == null ? void 0 : _a.state;
  const listSetSearchParams = list.setSearchParams;
  (0, import_react4.useEffect)(() => {
    if (!ctxState) {
      return;
    }
    listSetSearchParams((u) => {
      const u2 = new URLSearchParams(u);
      u2.set("filtering", JSON.stringify(ctxState));
      return u2;
    });
  }, [ctxState, listSetSearchParams]);
}
function GenericListContentBasic() {
  return function Content({
    additionalContent = [],
    lefthandContent = [],
    righthandContent = [],
    bottomContent = [],
    topContent = [],
    includeSearchContext = true,
    ...props
  }) {
    const ListTpl = props.listTemplate;
    const Content2 = /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)(import_jsx_runtime5.Fragment, {
      children: [
        props.list.loadingIO,
        /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("div", {
          className: "row",
          children: /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)("div", {
            className: "container-fluid d-flex justify-content-end mb-2",
            children: [
              lefthandContent,
              /* @__PURE__ */ (0, import_jsx_runtime5.jsx)("div", {
                className: "flex-fill"
              }),
              righthandContent
            ]
          })
        }),
        additionalContent,
        ListTpl ? /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(ListTpl, {
          topContent,
          bottomContent,
          list: props.list,
          fwProps: props.fwProps
        }) : null
      ]
    });
    return Content2;
  };
}
function GenericListContent() {
  const GenericListContentBasicC = GenericListContentBasic();
  return function Content(props) {
    useAutoSearchTrigger(props.list);
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(GenericListContentBasicC, {
      ...props
    });
  };
}

// src/lists/SelectableListGroup.tsx
var import_react5 = require("react");
var import_jsx_runtime6 = require("react/jsx-runtime");
var import_react6 = require("react");
var useActiveElement = (api, key, selectFirstByDefault = false) => {
  const { list } = api;
  const [selectedEl, setSelectedEl] = (0, import_react5.useState)();
  const [selectedIndex, setSelectedIndex] = (0, import_react5.useState)();
  (0, import_react5.useEffect)(() => {
    if (list && selectFirstByDefault) {
      setSelectedIndex(0);
    }
  }, [list, key, selectFirstByDefault]);
  const next = (0, import_react5.useCallback)(() => {
    if (!list) {
      return;
    }
    setSelectedIndex((i) => {
      if (i === void 0) {
        return 0;
      }
      if (i === list.length - 1) {
        return i;
      } else {
        return i + 1;
      }
    });
  }, [list]);
  const prev = (0, import_react5.useCallback)(() => {
    if (!list) {
      return;
    }
    setSelectedIndex((i) => {
      if (i === void 0) {
        return 0;
      }
      if (i === 0) {
        return i;
      } else {
        return i - 1;
      }
    });
  }, [list]);
  (0, import_react5.useEffect)(() => {
    if (!list || selectedIndex === void 0) {
      return;
    }
    if (list.length >= selectedIndex) {
      setSelectedEl(list[selectedIndex]);
    }
  }, [selectedIndex, list, key]);
  return {
    next,
    prev,
    selectedIndex,
    selectedEl,
    setSelectedEl
  };
};
var ListRenderer = (Template, key) => {
  return function List(props) {
    var _a, _b, _c, _d;
    if (props.fwProps.ref) {
      props.fwProps.ref.current = void 0;
    }
    let style = {};
    if (props.fwProps.maxHeight) {
      style.maxHeight = props.fwProps.maxHeight + "px";
    }
    return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("ul", {
      className: "h-100 list-group",
      children: [
        (_a = props.topContent) == null ? void 0 : _a.map(
          (el, index) => el ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("li", {
            className: "list-group-item",
            children: el
          }, index) : null
        ),
        /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("ul", {
          className: "list-group list-group-overflow",
          style,
          onMouseEnter: () => {
            var _a2, _b2;
            (_b2 = (_a2 = props.fwProps).setActive) == null ? void 0 : _b2.call(_a2, void 0);
          },
          children: [
            ((_b = props.list.state) == null ? void 0 : _b.loading) ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("li", {
              className: "list-group-item text-muted text-center",
              children: "Loading..."
            }) : null,
            (_c = props.list.list) == null ? void 0 : _c.map((r, index) => {
              if (props.fwProps.active !== void 0 && props.fwProps.ref && props.fwProps.active === r) {
                props.fwProps.ref.current = r;
              }
              return /* @__PURE__ */ (0, import_react6.createElement)(Template, {
                ...props.fwProps,
                active: props.fwProps.active === r,
                key: String(r[key]),
                el: r
              });
            }),
            props.list.list && props.list.list.length === 0 ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("li", {
              className: "list-group-item text-center text-muted",
              children: "No items"
            }) : null
          ]
        }),
        (_d = props.bottomContent) == null ? void 0 : _d.map((el, index) => /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("li", {
          className: "list-group-item",
          children: el
        }, index))
      ]
    });
  };
};
var DivRenderer = (Template, key) => {
  return function List(props) {
    var _a, _b, _c, _d;
    return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)("div", {
      className: "row",
      children: [
        (_a = props.topContent) == null ? void 0 : _a.map((el, index) => el),
        ((_b = props.list.state) == null ? void 0 : _b.loading) ? /* @__PURE__ */ (0, import_jsx_runtime6.jsx)("div", {
          className: "list-group-item text-muted text-center",
          children: "Loading..."
        }) : null,
        (_c = props.list.list) == null ? void 0 : _c.map((r, index) => {
          return /* @__PURE__ */ (0, import_react6.createElement)(Template, {
            ...props.fwProps,
            key: String(r[key]),
            el: r
          });
        }),
        (_d = props.bottomContent) == null ? void 0 : _d.map((el, index) => el)
      ]
    });
  };
};

// src/forms/hooks/fields/useSearchField.tsx
var import_forms = require("@csem/forms");
var import_assert4 = __toESM(require("assert"));
var import_react9 = require("react");
var import_lodash5 = require("lodash");

// src/lists/Searchbox/Component.tsx
var import_assert3 = __toESM(require("assert"));
var import_react8 = require("react");

// src/search/SearchBoxFactory.tsx
var import_react7 = require("react");
var import_jsx_runtime7 = require("react/jsx-runtime");
function SearchBoxFactory(useHook, ctx) {
  const Search2 = ({ onChange, clear = true, resetOnWrite = false, icon = "search", ...props }) => {
    const doSearch = useHook(props);
    const _ctx = (0, import_react7.useContext)(ctx);
    const search = (0, import_react7.useRef)(null);
    const tO = (0, import_react7.useRef)();
    return /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)("div", {
      className: "input-group",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)("div", {
          className: "input-group-text",
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("i", {
              className: `bi bi-${icon}`
            }),
            " ",
            props.label ? /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(import_jsx_runtime7.Fragment, {
              children: [
                "\xA0",
                props.label
              ]
            }) : null
          ]
        }),
        /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("input", {
          type: "search",
          value: _ctx == null ? void 0 : _ctx.state.unique,
          className: "form-control",
          ref: search,
          onChange: function(e) {
            doSearch(e.target.value);
            if (resetOnWrite && search.current) {
              if (tO.current) {
                clearTimeout(tO.current);
              }
              tO.current = setTimeout(() => {
                if (search.current) {
                  search.current.select();
                }
              }, 500);
            }
            onChange == null ? void 0 : onChange(e.target.value);
          }
        }),
        clear ? /* @__PURE__ */ (0, import_jsx_runtime7.jsx)("button", {
          className: "btn btn-outline-secondary",
          onClick: () => {
            doSearch("");
            onChange == null ? void 0 : onChange("");
          },
          children: "Clear"
        }) : null
      ]
    });
  };
  return Search2;
}

// src/lists/Searchbox/Component.tsx
function useListSearchBoxLogic({ filterKeys, filterLogic = "or" }) {
  const ctx = (0, import_react8.useContext)(TableFilterContext);
  if (filterKeys) {
    (0, import_assert3.default)(ctx, "Sadly, the context TableFilterContext doesn't exist");
  }
  const ctxSetState = ctx == null ? void 0 : ctx.setState;
  const doSearch = (0, import_react8.useCallback)(
    (e) => {
      let v = e;
      if (filterKeys) {
        (0, import_assert3.default)(ctxSetState);
        ctxSetState((s) => {
          let s2 = Object.assign({}, s || DefaultStandardTableFilter);
          if (e.length > 0) {
            s2.unique = e;
            const myFilters = Object.entries(filterKeys).map(([field, operator]) => {
              return {
                field,
                operator,
                value: v
              };
            });
            s2.filter.filters[1].filters[0].filters = myFilters;
            s2.filter.filters[1].filters[1].filters = [];
          } else {
            s2.filter.filters[1].filters[0].filters = [];
            s2.filter.filters[1].filters[1].filters = [];
            s2.unique = "";
          }
          return s2;
        });
      }
    },
    [filterKeys, ctxSetState]
  );
  return doSearch;
}
var Search = SearchBoxFactory(useListSearchBoxLogic, TableFilterContext);

// src/forms/hooks/fields/useSearchField.tsx
var import_jsx_runtime8 = require("react/jsx-runtime");
var InnerContent = function InnerContent2({
  apiList,
  label,
  disabled,
  error,
  noMargin,
  CtnRenderer,
  ListCtnRenderer,
  updateValue,
  val,
  floating,
  originalValue,
  uniqueKeyField,
  traverser_display,
  liftSearchValue,
  setSelectedItem,
  filterKeys
}) {
  const ctx = (0, import_react9.useContext)(TableFilterContext);
  const doSearch = useListSearchBoxLogic({
    filterKeys,
    filterLogic: "or"
  });
  const [visible, setVisibility] = (0, import_react9.useState)(false);
  const [searchDisplayValue, updateDisplayValue] = (0, import_react9.useState)("");
  const activity = useActiveElement(apiList, uniqueKeyField, true);
  const { selectedEl, setSelectedEl } = activity;
  const refT = (0, import_react9.useRef)();
  const fw = (0, import_react9.useMemo)(() => {
    return {
      onSelect: (el) => {
        if (el === void 0) {
          updateValue(void 0);
          setSelectedItem(void 0);
        } else if (el === null) {
          updateValue(void 0);
          setSelectedItem(void 0);
          liftSearchValue(void 0);
        } else {
          updateValue(el[uniqueKeyField]);
          setSelectedItem(el);
          updateDisplayValue(traverser_display(el));
          const sel = el[uniqueKeyField];
          liftSearchValue(sel);
          setVisibility(false);
        }
      },
      ref: refT,
      active: selectedEl,
      setActive: setSelectedEl
    };
  }, [
    traverser_display,
    selectedEl,
    setSelectedEl,
    refT,
    uniqueKeyField,
    liftSearchValue,
    updateValue,
    setSelectedItem
  ]);
  const ref = (0, import_react9.useRef)(null);
  (0, import_react9.useEffect)(() => {
    if (!visible) {
      return;
    }
    const cb = function(e) {
      if (e.target !== ref.current)
        setVisibility(false);
    };
    document.addEventListener("click", cb, false);
    return () => {
      document.removeEventListener("click", cb);
    };
  }, [visible]);
  const [loadSeq, setLoadSeq] = (0, import_react9.useState)(0);
  const doNotEmptyField = (0, import_react9.useRef)(false);
  (0, import_react9.useEffect)(() => {
    if (loadSeq === 2 && apiList.list && apiList.list.length === 1) {
      setLoadSeq(0);
      setSelectedItem(apiList.list[0]);
      updateDisplayValue(traverser_display(apiList.list[0]));
      liftSearchValue(apiList.list[0][uniqueKeyField]);
    }
  }, [apiList.list, loadSeq, traverser_display, uniqueKeyField, liftSearchValue]);
  (0, import_react9.useEffect)(() => {
    setLoadSeq((s) => s === 1 ? 2 : s);
  }, [apiList.list]);
  const setState = ctx == null ? void 0 : ctx.setState;
  (0, import_react9.useEffect)(() => {
    if (val === void 0 || !setState) {
      if (!doNotEmptyField.current) {
        updateDisplayValue("");
      }
      liftSearchValue(void 0);
      doNotEmptyField.current = false;
      return;
    }
    doNotEmptyField.current = false;
    setState((f) => {
      const newF = (0, import_lodash5.cloneDeep)(f);
      newF.filter.filters[1].filters[1].filters = [
        {
          field: uniqueKeyField,
          operator: "eq",
          value: String(val)
        }
      ];
      return newF;
    });
    setLoadSeq(1);
  }, [setState, val, uniqueKeyField, liftSearchValue]);
  (0, import_react9.useEffect)(() => {
    if (originalValue !== void 0) {
      updateValue(originalValue);
    }
  }, [originalValue, updateValue]);
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_jsx_runtime8.Fragment, {
    children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", {
      className: `${noMargin === true ? "" : "mb-2"} input-group flex-nowrap `,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", {
          className: `${floating === false ? "" : "form-floating"} flex-grow-1`,
          style: { position: "relative" },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("input", {
              ref,
              type: "text",
              disabled,
              value: searchDisplayValue,
              className: "form-control" + (error ? " is-invalid" : ""),
              onChange: function(e) {
                doSearch(e.target.value);
                doNotEmptyField.current = true;
                updateValue(void 0);
                setSelectedItem(void 0);
                updateDisplayValue(e.target.value);
                liftSearchValue(e.target.value);
                setVisibility(true);
              },
              onFocus: () => {
                var _a;
                if (apiList.list && ((_a = apiList.list) == null ? void 0 : _a.length) > 0) {
                  setVisibility(true);
                }
              },
              onClick: (e) => {
              },
              onKeyDown: (e) => {
                if (e.key === "ArrowDown") {
                  activity.next();
                }
                if (e.key === "ArrowUp") {
                  activity.prev();
                }
                if (e.key === "Enter") {
                  if (refT.current) {
                    fw.onSelect(refT.current);
                  }
                }
              }
            }),
            floating === false ? null : /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(import_forms.FormLabel, {
              label,
              error
            })
          ]
        }),
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", {
          className: "input-group-text",
          children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("em", {
            className: "bi bi-search"
          })
        }),
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", {
          className: visible ? "visible" : "invisible",
          style: {
            position: "absolute",
            top: "100%",
            zIndex: 1e3
          },
          onClick: (e) => e.stopPropagation(),
          children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(CtnRenderer, {
            includeSearchContext: false,
            fwProps: fw,
            list: apiList,
            listTemplate: ListCtnRenderer
          })
        })
      ]
    })
  });
};
var FormSearchHookFactory = (factory_props) => {
  const CtnRenderer = HasPagination(GenericListContent(), void 0, TableFilterContext);
  const ListCtnRenderer = ListRenderer(factory_props.template, factory_props.uniqueKeyField);
  const initFilter = (0, import_lodash5.cloneDeep)(DefaultStandardTableFilter);
  initFilter.filter.filters[0].filters = factory_props.initialFilter || [];
  const useFormSearch = function(props) {
    const { label, disabled, floating, helpText, filterKeys, ...fieldLogic } = props;
    const _filterKeys = factory_props.filterKeys || filterKeys;
    (0, import_assert4.default)(_filterKeys);
    const { val, error, triggerOnDemandValidation, updateValue, setValidationError, clearValue } = (0, import_forms.useFieldLogic)(fieldLogic, props);
    const [selectedItem, setSelectedItem] = (0, import_react9.useState)();
    const [searchValue, setSearchValue] = (0, import_react9.useState)();
    const useAPI = props.apiListFactory || factory_props.apiListFactory;
    (0, import_assert4.default)(useAPI);
    const apiList = useAPI();
    const Content = /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(TableFilterCtxProvider, {
      defaultValue: initFilter,
      children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(InnerContent, {
        apiList,
        label,
        error,
        floating,
        uniqueKeyField: factory_props.uniqueKeyField,
        traverser_display: factory_props.traverser_display,
        originalValue: fieldLogic.originalValue,
        updateValue,
        filterKeys: _filterKeys,
        noMargin: props.noMargin,
        val,
        disabled,
        CtnRenderer,
        ListCtnRenderer,
        setSelectedItem,
        liftSearchValue: setSearchValue
      })
    });
    return {
      validate: triggerOnDemandValidation,
      Content,
      value: val,
      error: error !== void 0,
      setValidationError,
      updateValue,
      selectedItem,
      searchValue,
      clearValue
    };
  };
  return useFormSearch;
};
var FormSearchMultHookFactory = (factory_props) => {
  const useField = FormSearchHookFactory(factory_props);
  const useFormSearch = function(props) {
    const {
      label,
      disabled,
      helpText,
      filterKeys,
      ...fieldLogic
    } = props;
    const { val, error, triggerOnDemandValidation, updateValue, setValidationError, clearValue } = (0, import_forms.useFieldLogic)(
      fieldLogic,
      props
    );
    const field = useField({
      helpText,
      label,
      apiListFactory: props.apiListFactory,
      defaultValue: void 0
    });
    const [values, setValues] = (0, import_react9.useState)([]);
    (0, import_react9.useEffect)(() => {
      if (Array.isArray(props.originalValue)) {
        _setOrig(props.originalValue);
      }
    }, [props.originalValue]);
    const [_orig, _setOrig] = (0, import_react9.useState)([]);
    (0, import_react9.useEffect)(() => {
      if (field.selectedItem === void 0) {
        return;
      }
      setValues((v) => {
        (0, import_assert4.default)(factory_props.uniqueKeyField);
        (0, import_assert4.default)(field.selectedItem);
        if (field.selectedItem === void 0) {
          return v;
        }
        if (v.map((v2) => v2[factory_props.uniqueKeyField]).includes(field.selectedItem[factory_props.uniqueKeyField])) {
          return v;
        }
        return [...v, field.selectedItem];
      });
      updateValue((v) => {
        (0, import_assert4.default)(field.selectedItem);
        (0, import_assert4.default)(factory_props.uniqueKeyField);
        if (field.selectedItem === void 0) {
          return v;
        }
        const k = field.selectedItem[factory_props.uniqueKeyField];
        if (v.includes(k))
          return v;
        return [...v, k];
      });
      _setOrig((o) => {
        return [...o.slice(1)];
      });
    }, [field.selectedItem]);
    (0, import_react9.useEffect)(() => {
      if (_orig.length > 0) {
        field.updateValue(_orig[0]);
      }
    }, [_orig]);
    const Content = /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", {
      className: "mb-2",
      children: [
        field.Content,
        /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("ul", {
          className: "list-group mb-2",
          children: values.map((val2, index) => {
            let _key;
            const key = val2[factory_props.uniqueKeyField];
            return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("li", {
              className: "list-group-item",
              children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", {
                className: "d-flex justify-content-between",
                children: [
                  /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("span", {
                    children: factory_props.traverser_display(val2)
                  }),
                  /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("button", {
                    className: "btn btn-primary",
                    onClick: (s) => {
                      setValues((v) => {
                        return [...v.slice(0, v.indexOf(val2)), ...v.slice(v.indexOf(val2) + 1)];
                      });
                      updateValue((v) => {
                        return [...v.slice(0, v.indexOf(key)), ...v.slice(v.indexOf(key) + 1)];
                      });
                    },
                    children: /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("em", {
                      className: "bi bi-trash"
                    })
                  })
                ]
              })
            }, String(key));
          })
        })
      ]
    });
    return {
      validate: triggerOnDemandValidation,
      Content,
      value: val,
      error: error !== void 0,
      setValidationError,
      updateValue,
      selectedItems: values,
      clearValue
    };
  };
  return useFormSearch;
};

// src/forms/hooks/fields/useSearchOneField.tsx
var import_forms2 = require("@csem/forms");
var import_assert5 = __toESM(require("assert"));
var import_react10 = require("react");
var import_jsx_runtime9 = require("react/jsx-runtime");
var InnerContent3 = function InnerContent4({
  apiList,
  label,
  disabled,
  error,
  updateValue,
  noMargin,
  val,
  originalValue,
  searchKeyField,
  uniqueKeyField,
  externalVal,
  setExternalValue,
  liftSearchValue,
  setSelectedItem
}) {
  const ctx = (0, import_react10.useContext)(TableFilterContext);
  const filterKeys = (0, import_react10.useMemo)(() => {
    return { [searchKeyField]: "eq" };
  }, [searchKeyField]);
  useAutoSearchTrigger(apiList);
  const doSearch = useListSearchBoxLogic({
    filterKeys,
    filterLogic: "and"
  });
  const list = apiList.list;
  const [searchDisplayValue, updateDisplayValue] = (0, import_react10.useState)("");
  (0, import_react10.useEffect)(
    function() {
      if (Array.isArray(list) && list.length > 0 && list.length == 1) {
        updateDisplayValue(list[0][searchKeyField]);
      }
    },
    [list, searchKeyField, updateDisplayValue]
  );
  const [loadSeq, setLoadSeq] = (0, import_react10.useState)(0);
  (0, import_react10.useEffect)(() => {
    if (list && list.length === 1) {
      setLoadSeq(0);
      updateValue(list[0][uniqueKeyField]);
      setSelectedItem(list[0]);
      const e = list[0][searchKeyField];
      liftSearchValue(e);
      updateDisplayValue(list[0][searchKeyField] || "");
      setExternalValue(list[0][uniqueKeyField]);
    } else if (list && list.length === 0) {
      updateValue(void 0);
      setSelectedItem(void 0);
    }
  }, [list, loadSeq, uniqueKeyField, searchKeyField, liftSearchValue, setExternalValue]);
  (0, import_react10.useEffect)(() => {
    setLoadSeq((s) => s === 1 ? 2 : s);
  }, [list]);
  const searchUnique = (0, import_react10.useCallback)(
    (externalVal2) => {
      if (externalVal2 === val) {
        return;
      }
      if (externalVal2 === void 0 || !ctx) {
        updateValue(void 0);
        updateDisplayValue("");
        setSelectedItem(void 0);
        liftSearchValue(void 0);
        return;
      }
      let f = {
        skip: 0,
        take: 20,
        sort: [],
        filter: {
          logic: "and",
          filters: [
            {
              filters: [],
              logic: "or"
            },
            {
              logic: "and",
              filters: [
                {
                  logic: "or",
                  filters: [
                    {
                      field: uniqueKeyField,
                      operator: "eq",
                      value: String(externalVal2)
                    }
                  ]
                },
                {
                  logic: "and",
                  filters: []
                }
              ]
            }
          ]
        }
      };
      ctx.setState(f);
      setLoadSeq(1);
    },
    [ctx == null ? void 0 : ctx.setState, val, uniqueKeyField, liftSearchValue, updateDisplayValue, setSelectedItem]
  );
  (0, import_react10.useEffect)(() => {
    if (externalVal === void 0) {
      setSelectedItem(void 0);
      liftSearchValue(void 0);
      updateDisplayValue("");
      setExternalValue(void 0);
    } else {
      searchUnique(externalVal);
    }
  }, [externalVal, liftSearchValue, searchUnique, setExternalValue, setSelectedItem]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_jsx_runtime9.Fragment, {
    children: /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("div", {
      className: `${noMargin === true ? "" : "mb-2"} input-group flex-nowrap `,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("div", {
          className: "form-floating flex-grow-1",
          style: { position: "relative" },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("input", {
              type: "text",
              disabled,
              value: searchDisplayValue,
              className: "form-control" + (error ? " is-invalid" : ""),
              onChange: function(e) {
                doSearch(e.target.value);
                updateValue(void 0);
                updateDisplayValue(e.target.value);
                liftSearchValue(e.target.value);
              },
              onFocus: () => {
              },
              onClick: (e) => {
              },
              onKeyDown: (e) => {
              }
            }),
            /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_forms2.FormLabel, {
              label,
              error
            })
          ]
        }),
        /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("div", {
          className: "input-group-text",
          children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("em", {
            className: "bi bi-search"
          })
        })
      ]
    })
  });
};
var FormSearchOneHookFactory = (factory_props) => {
  const useFormSearch = function(props) {
    const { label, disabled, helpText, filterKeys, ...fieldLogic } = props;
    const { val, error, triggerOnDemandValidation, updateValue, setValidationError } = (0, import_forms2.useFieldLogic)(
      fieldLogic,
      props
    );
    const [selectedItem, setSelectedItem] = (0, import_react10.useState)();
    const [searchValue, setSearchValue] = (0, import_react10.useState)();
    const useAPI = props.apiListFactory || factory_props.apiListFactory;
    (0, import_assert5.default)(useAPI);
    const apiList = useAPI();
    const [internalValue, internalUpdateValue] = (0, import_react10.useState)(void 0);
    const defaultValue = props.defaultValue;
    const clearValue = (0, import_react10.useCallback)(() => {
      internalUpdateValue(defaultValue);
      updateValue(defaultValue);
    }, [updateValue, defaultValue]);
    const Content = /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(TableFilterCtxProvider, {
      defaultValue: factory_props.initialFilter || void 0,
      children: /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(InnerContent3, {
        apiList,
        label,
        error,
        uniqueKeyField: factory_props.uniqueKeyField,
        searchKeyField: factory_props.searchKeyField,
        noMargin: props.noMargin,
        originalValue: fieldLogic.originalValue,
        updateValue: internalUpdateValue,
        externalVal: val,
        setExternalValue: updateValue,
        val: internalValue,
        disabled,
        setSelectedItem,
        liftSearchValue: setSearchValue
      })
    });
    return {
      validate: triggerOnDemandValidation,
      Content,
      value: internalValue,
      error: error !== void 0,
      setValidationError,
      updateValue,
      selectedItem,
      searchValue,
      clearValue
    };
  };
  return useFormSearch;
};

// src/tables/filters/TextFilter.tsx
var import_react11 = __toESM(require("react"));
var import_jsx_runtime10 = require("react/jsx-runtime");
var TextFilterFactory = (factory_props) => {
  return import_react11.default.forwardRef((props, ref) => {
    const [v, setV] = (0, import_react11.useState)("");
    const [changed, setChanged] = (0, import_react11.useState)(false);
    const onChanged = props.onChange;
    const originalValue = props.originalValue;
    (0, import_react11.useEffect)(() => {
      if (originalValue) {
        setV(String(Object.values(originalValue)[0]));
      }
    }, [originalValue]);
    (0, import_react11.useEffect)(() => {
      if (!changed) {
        return;
      }
      if (v !== null && v.length > 0) {
        onChanged(v, factory_props.operator);
      } else {
        onChanged(void 0);
      }
      setChanged(false);
    }, [v, changed, onChanged]);
    return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)("div", {
      className: "input-group",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)("div", {
          className: "form-floating flex-grow-1",
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("input", {
              type: "text",
              className: "form-control",
              value: v,
              ref,
              onChange: (e) => {
                setV(e.target.value);
              },
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  setChanged(true);
                }
              }
            }),
            /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("label", {
              children: factory_props.label || "Search term"
            })
          ]
        }),
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("button", {
          className: "btn btn-outline-primary",
          type: "button",
          onClick: () => {
            setChanged(true);
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("em", {
            className: "bi bi-search"
          })
        }),
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("button", {
          className: "btn btn-outline-secondary",
          type: "button",
          onClick: () => {
            setV("");
            setChanged(true);
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)("em", {
            className: "bi bi-x"
          })
        })
      ]
    });
  });
};

// src/tables/filters/Searchbox/Component.tsx
var import_assert6 = __toESM(require("assert"));
var import_react12 = require("react");
function useTableSearchBoxLogic({ filterKeys, filterLogic = "or" }) {
  const ctx = (0, import_react12.useContext)(StandardTableFilterContext);
  if (filterKeys) {
    (0, import_assert6.default)(ctx, "Sadly, the context StandardTableFilterContext doesn't exist");
  }
  const ctxSetState = ctx == null ? void 0 : ctx.setState;
  const doSearch = (0, import_react12.useCallback)(
    (e) => {
      let v = e;
      if (filterKeys) {
        (0, import_assert6.default)(ctxSetState);
        ctxSetState((s) => {
          let s2 = Object.assign({}, s || DefaultStandardTableFilter);
          if (e.length > 0) {
            s2.unique = e;
            const myFilters = Object.entries(filterKeys).map(([field, operator]) => {
              return {
                field,
                operator,
                value: v
              };
            });
            s2.filter.filters[1].filters[0].filters = myFilters;
          } else {
            s2.filter.filters[1].filters[0].filters = [];
            s2.unique = "";
          }
          return s2;
        });
      }
    },
    [filterKeys, ctxSetState]
  );
  return doSearch;
}
var TableSearch = SearchBoxFactory(useTableSearchBoxLogic, StandardTableFilterContext);

// src/tables/filters/Factories/Search.tsx
var import_react13 = require("react");
var import_react14 = __toESM(require("react"));
var import_lodash6 = require("lodash");
var import_jsx_runtime11 = require("react/jsx-runtime");
var SearchElFilterFactory = (factory_props) => {
  const CtnRenderer = HasPagination(GenericListContent(), 1, TableFilterContext);
  const ListCtnRenderer = ListRenderer(factory_props.template, factory_props.key);
  function CtxedFilter(props) {
    const useAPI = factory_props.apiListFactory;
    const apiList = useAPI();
    const { onChange } = props;
    const { selectedEl, setSelectedEl } = useActiveElement(apiList, factory_props.key);
    const searchChanged = (0, import_react13.useCallback)(
      (value) => {
        if (value.length === 0) {
          setSelectedEl(void 0);
          onChange(void 0);
        }
      },
      [onChange, setSelectedEl]
    );
    const onSelect = (0, import_react13.useCallback)(
      (el) => {
        if (el === void 0) {
          onChange(void 0);
        } else {
          onChange(el === null ? null : factory_props.traverser(el), "eq", factory_props.filterFieldKey);
          if (el !== null) {
            setSelectedEl(el);
          }
        }
      },
      [onChange, setSelectedEl]
    );
    const FactoryTop = factory_props.top;
    const top = (0, import_react13.useMemo)(
      () => [
        /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(Search, {
          filterLogic: "or",
          filterKeys: factory_props.filterKeys,
          onChange: searchChanged
        }, "__search"),
        FactoryTop ? /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(FactoryTop, {
          onSelect
        }) : null
      ],
      [searchChanged, FactoryTop, onSelect]
    );
    const fw = (0, import_react13.useMemo)(() => {
      return {
        active: selectedEl,
        onSelect
      };
    }, [selectedEl, onSelect]);
    return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(CtnRenderer, {
      topContent: top,
      fwProps: fw,
      list: apiList,
      listTemplate: ListCtnRenderer
    });
  }
  const initialFilter = (0, import_lodash6.cloneDeep)(DefaultStandardTableFilter);
  initialFilter.filter.filters[0].filters = factory_props.initialFilter || [];
  return import_react14.default.forwardRef(
    (props, ref) => {
      return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(TableFilterCtxProvider, {
        defaultValue: initialFilter,
        children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(CtxedFilter, {
          ...props
        })
      });
    }
  );
};

// src/tables/HasPagination.tsx
function HasStdTablePagination(CollectionComponent, pageSpan = 5) {
  return HasPagination(
    CollectionComponent,
    pageSpan,
    StandardTableFilterContext
  );
}

// src/tables/Buttons.tsx
var RemoveButton = (label = "Delete", visibility = () => true) => {
  return {
    title: label || "Delete",
    theme: "danger",
    visibility,
    cb: (e, fw) => {
      var _a;
      (_a = fw.remove) == null ? void 0 : _a.call(fw, e, fw);
    }
  };
};
var RevokeButton = (label = "Revoke", visibility = () => true) => {
  return {
    title: label || "Revoke",
    theme: "danger",
    visibility,
    cb: (e, fw) => {
      var _a;
      (_a = fw.revoke) == null ? void 0 : _a.call(fw, e, fw);
    }
  };
};
var EditButton = (label = "Edit", visibility = () => true) => {
  return {
    title: label || "Edit",
    theme: "info",
    visibility,
    cb: (e, fw) => {
      fw.edit(e, fw);
    }
  };
};
var SelectButton = (label = "Select") => {
  return {
    title: (e, fw) => {
      var _a;
      return ((_a = fw.selectContent) == null ? void 0 : _a.find((el) => el.id === e.id)) ? "Unselect" : "Select";
    },
    theme: (e, fw) => {
      var _a;
      return ((_a = fw.selectContent) == null ? void 0 : _a.find((el) => el.id === e.id)) ? "warning" : "info";
    },
    visibility: (e, fw) => {
      return !!fw.onSelect && fw.selectable !== false;
    },
    cb: (e, fw) => {
      var _a;
      (_a = fw.onSelect) == null ? void 0 : _a.call(fw, e);
    }
  };
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ButtonsColumn,
  CheckboxFilterColumn,
  CheckboxFilterFactory,
  DefaultStandardTableFilter,
  DefaultTableFilter,
  DivRenderer,
  EditButton,
  EmptyRow,
  FilterableColumn,
  FormSearchHookFactory,
  FormSearchMultHookFactory,
  FormSearchOneHookFactory,
  GenericListContent,
  GenericListContentBasic,
  HasAddition,
  HasButton,
  HasDeletion,
  HasEdition,
  HasInlineEdition,
  HasModalMethod,
  HasPagination,
  HasRefresh,
  HasRevokation,
  HasSearch,
  HasStdTablePagination,
  HasTableFilterClear,
  HelpColumn,
  HtmlColumn,
  IsSelectable,
  ListRenderer,
  NavigationColumn,
  NavigationLookup,
  RemoveButton,
  RevokeButton,
  SearchElFilterFactory,
  SelectButton,
  SimpleColumn,
  SortableColumn,
  StandardTableFilterContext,
  StandardTableFilterCtxProvider,
  TableFilterContext,
  TableFilterCtxProvider,
  TableRenderer,
  TableSearch,
  TextFilterFactory,
  createFilterCtxProvider,
  createTableFilterContext,
  groupedColumn,
  useActiveElement,
  useAutoSearchTrigger,
  useTableFilterChanged,
  useTableSearchBoxLogic
});
