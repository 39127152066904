import { NestorContext } from "nestor/contexts/NestorContext";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { LabelPrinterSizes, NestorLabelPrinter } from "nestor/types/util/label_printer";
import assert from "assert";
import { usePersist } from "@csem/shared-utils";
import { useCallback, useContext, useState } from "react";

const label = ["labelPrinter"];
export const LabelPrinterButton = (props: {
  url: string;
  text?: string;
  additionalQueryParameters?: { [x: string]: string | string[] };
  size: LabelPrinterSizes;
}) => {
  const ctx = useContext(NestorContext);
  assert(ctx);

  const api = useNestorAPI(props.url, label);
  const { setSearchParams } = api;

  const [defaultLabelPrinter, setDefaultLabelPrinter] = useState<NestorLabelPrinter>();
  const preferredLabelPrinter = usePersist(defaultLabelPrinter, "_label_printer");

  const execute = useCallback(
    (p: NestorLabelPrinter) => {
      const params = new URLSearchParams();
      params.set("labelPrinter", p.cups_name);
      if (props.additionalQueryParameters) {
        for (let i in props.additionalQueryParameters) {
          if (typeof props.additionalQueryParameters[i] == "string") {
            params.set(i, props.additionalQueryParameters[i] as string);
          } else if (Array.isArray(props.additionalQueryParameters[i])) {
            (props.additionalQueryParameters[i] as string[]).forEach((v) => {
              params.append(i + "[]", v);
            });
          }
        }
      }
      setSearchParams(params);
    },

    [props.additionalQueryParameters, setSearchParams]
  );

  return (
    <div className="dropdown dropend">
      <div className="btn-group ">
        <button
          className={"btn btn-outline-secondary flex-fill " + (api.state.success ? "bg-success text-white" : "")}
          type="button"
          disabled={true}
        >
          <em className="bi bi-qr-code"></em> {props.text || ""}
        </button>
        {preferredLabelPrinter ? (
          <button
            className="btn btn-outline-secondary "
            type="button"
            onClick={() => {
              if (preferredLabelPrinter) {
                execute(preferredLabelPrinter);
              }
            }}
          >
            {preferredLabelPrinter?.name || ""}
          </button>
        ) : null}

        <button className="btn btn-outline-secondary dropdown-toggle " data-bs-toggle="dropdown" type="button"></button>
        <ul className="dropdown-menu">
          <li className="dropdown-item disabled">Tape size: {props.size}</li>
          {ctx?.printers.map((p) => (
            <li>
              <button
                className="dropdown-item"
                key={p.id}
                onClick={(e) => {
                  e.preventDefault();
                  execute(p);
                  setDefaultLabelPrinter(p);
                }}
              >
                {p.name} {p.room ? <>({p.room.name})</> : null}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
