import { NestorContainerId } from "nestor/types/stock/container";
import { NestorSkuId } from "nestor/types/stock/sku_container";
import { NestorSkuTransfer } from "nestor/types/stock/sku_transfer";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";
type T = NestorSkuTransfer;

export const useSkuJournal = (props: { containerId?: NestorContainerId; skuId?: NestorSkuId }): apiList<T> => {
  let url: string;
  if (props.containerId) {
    url = `list/stock/containers/${props.containerId}/journal`;
  } else if (props.skuId) {
    url = `list/stock/skus/${props.skuId}/movements`;
  } else {
    throw new Error("Not implemented");
  }
  return useNestorListApiCall<T>(url, "sku");
};
