import { NestorManufacturer } from "nestor/types/purchasing/manufacturer";

export const ManufacturerRenderer = ({
  manufacturer,
  id,
}: {
  manufacturer?: NestorManufacturer;
  id?: string | number;
}) => {
  if (!manufacturer) {
    return null;
  }

  return (
    <>
      <div className="fw-bold">{manufacturer.name}</div>
      {id ? <div className="text-muted">{id}</div> : null}
    </>
  );
};
