import { NestorOrderPaymentMode } from "nestor/types/purchasing/orders";
import { useFormSelectObj } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

export const useFieldPaymentMode = (mode: NestorOrderPaymentMode | undefined) => {
  return useFormSelectObj({
    label: "Means of payment",
    originalValue: mode || "invoice",

    defaultValue: "invoice",
    options: {
      invoice: "By invoice",
      credit_card: "By credit card",
      proforma: "By proforma invoice",
      credit_note: "By credit note",
      other: "Other",
    },
    validationOnChange: useFieldOptRequired(true),
  });
};
