// Hooks

// Classes

// Components

// Contexts
import { DeviceRenderer } from "nestor/components/forms/fields/DeviceSearch";
import { usePcbFunctionalTestField } from "nestor/components/forms/fields/PcbFunctionTest";
import { PcbMassAddModal } from "nestor/components/modals/AddPcb";
import { PcbEditModal } from "nestor/components/nestor/manufacturing/pcb/modals/EditPcb";
import { SkuRenderer } from "nestor/components/renderers/SkuRenderer";
//# Tables
//## Nestor
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { DeviceColumn } from "nestor/components/tables/columns/DeviceColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { LocationColumn } from "nestor/components/tables/columns/LocationColumn";
import { PCBNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { NestorSelectContext } from "nestor/contexts/NestorSelectContext";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { usePcbList } from "nestor/hooks/api/usePcbList";
import { NestorPcb, NestorPcbFunctionalTest, NestorPcbInspectionTest } from "nestor/types/manufacturing/pcb";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { NestorParcelId } from "nestor/types/purchasing/parcel";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import { NestorLocationFilter } from "nestor/types/_internal/locationFilter";
import extendFilterByLocation from "nestor/util/ExtendFilterWithLocation";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { FilterProps } from "@csem/lists";
import { RemoveButton, SelectButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { useOnChange } from "@csem/shared-utils";
import { cloneDeep } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { LabelPrinterButton } from "../../../util/LabelPrinter";

type CtxUnderlyingType<T> = T extends React.Context<infer U> ? U : never;

type T = NestorPcb;
type FWLst = {
  layout?: NestorPcbLayout;
  selectable?: boolean;
  actionable?: boolean;
  onSelect?: (e: T) => void;
  selectAll?: (b: boolean) => void;
  //selected?: Array<number>;
  selectCtx?: CtxUnderlyingType<typeof NestorSelectContext>;
};
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;
type HOOK = {};

const PCBFilter = (props: FilterProps<NestorPcbFunctionalTest>) => {
  const oV = Object.values(props.originalValue || {})[0] as NestorPcbFunctionalTest;

  const field = usePcbFunctionalTestField(oV || undefined, undefined);
  const onChange = props.onChange;
  const v = field.value;

  useOnChange(
    () => {
      onChange(v as NestorPcbFunctionalTest, "eq");
    },
    [v],
    [onChange]
  );

  return field.Content;
};

const GenericPCBTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      PCBNavigationColumn,
      NUIDColumn,

      SortableColumn(
        SimpleColumn("number", "Number", (e) => e.number),
        "number"
      ),
      EmployeeColumn("created_by", "Created by", "employee_id", (e) => e.employee),

      FilterableColumn(
        SimpleColumn(
          "variant",
          "Variant",
          (e) => e.current_attributes?.find((a) => a.shorthand === "variant")?.details.value || "N/A"
        ),
        TextFilterFactory({ label: "Variant" }),
        "current_variant.value"
      ),

      FilterableColumn(
        SimpleColumn(
          "revision",
          "Revision",
          (e) => e.current_attributes?.find((a) => a.shorthand === "revision")?.details.value || "N/A"
        ),
        TextFilterFactory({ label: "Revision" }),
        "current_revision.value"
      ),

      SimpleColumn("sku", "Bare PCB SKU", (e) => <SkuRenderer sku={e.sku} />),

      FilterableColumn(
        SimpleColumn("test", "Functional test", (e) => {
          const c =
            NestorPcbFunctionalTest[
              (e.current_attributes?.find((a) => a.shorthand === "functional_test")?.details
                .value as keyof typeof NestorPcbFunctionalTest) || undefined
            ];
          return c;
        }),
        PCBFilter,
        "functional_test"
      ),

      FilterableColumn(
        SimpleColumn("vis_insp", "Visual inspection", (e) => {
          const c =
            NestorPcbInspectionTest[
              (e.current_attributes?.find((a) => a.shorthand === "visual_inspection")?.details
                .value as keyof typeof NestorPcbInspectionTest) || undefined
            ];
          return c;
        }),
        PCBFilter,
        "visual_inspection"
      ),

      LocationColumn("loc", "Location", (e) => e.location),
      SimpleColumn(
        "print",
        (fw) => {
          return (
            <>
              <div className="d-flex flex-nowrap align-items-center">
                <LabelPrinterButton
                  additionalQueryParameters={{
                    ids: fw.selectCtx?.store?.filter((e) => e.type === "pcb").map((e) => String(e.el.id)) || [],
                  }}
                  size={LabelPrinterSizes.SZ_9}
                  url={`manufacturing/pcbs/any/label/pdf/print`}
                />

                {/* {fw.onSelect && fw.selected && fw.selectAll ? (
                  <input
                    type="checkbox"
                    className="ms-1"
                    checked={fw.selected?.length > 0}
                    onChange={(e) => {
                      assert(fw.onSelect);
                      fw.selectAll?.(e.target.checked);
                    }}
                  />
                  ) : null}*/}
              </div>
            </>
          );
        },

        (el, fw) => {
          return (
            <div className="d-flex flex-nowrap align-items-center">
              <LabelPrinterButton size={LabelPrinterSizes.SZ_9} url={`manufacturing/pcbs/${el.id}/label/pdf/print`} />
              {fw.onSelect && fw.selectCtx ? (
                <input
                  type="checkbox"
                  className="ms-1"
                  checked={!!fw.selectCtx.store.find((e) => e.el.id == el.id && e.type === "pcb")}
                  onChange={(e) => {
                    assert(fw.onSelect);
                    fw.onSelect(el);
                  }}
                />
              ) : null}
            </div>
          );
        }
      ),

      // DeviceColumn("device", "System / Device", "device.id", (e) => e.device?.[0]),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
      ButtonsColumn("actions", "", [
        {
          title: "Edit",
          theme: "info",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            //fw.edit(e, fw);
          },
        },

        RemoveButton("Delete", (e) => {
          return !!e.permissions?.delete;
        }),

        SelectButton(),
      ]),
    ],

    useColumnWidth: () => {
      return {
        _nav: "0px",
        print: "220px",
        created_by: "125px",
        number: "50px",
        nuid: "50px",
        variant: "50px",
        version: "50px",
        created_at: "120px",
        actions: "100px",
      };
    },
    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnVisibility: (columns, fw) => {
      const vis: any = {};

      if (!fw.actionable) {
        vis.actions = false;
      }

      if (fw.selectable === true) {
        return (vis.print = false);
      }

      return vis;
    },
  });
})();

const GenericPCBListContainer = HasDeletion(
  HasEdition(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
      PcbMassAddModal,
      "Add new PCAs",
      "plus",
      "success"
    ),
    PcbEditModal
  ),
  "Confirm PCA deletion",
  (v: T) => `Are you sure to delete PCA "${v.nuid}" ?`,
  (props) => {
    return useNestorAPI<undefined, NestorPcb>(
      (r) => {
        assert(r !== undefined);
        return `manufacturing/pcbs/${r.id}`;
      },
      false,
      undefined,
      { method: "DELETE" },
      props.list.refresh
    );
  }
);

export const PCBListCard = ({
  layout,
  selectable,
  ...props
}: {
  selectable?: boolean;
  layout?: NestorPcbLayout;
  parcel_id?: NestorParcelId;
} & NestorLocationFilter) => {
  const pcbList = usePcbList(layout?.id);
  useMemo(() => {
    console.log("LC");
  }, []);
  const selectableCtx = useContext(NestorSelectContext);

  const toggle = selectableCtx?.toggle;
  const els = selectableCtx?.store;

  const onSelect = useMemo(() => {
    console.log("Toggle redefined");
    if (!toggle) {
      return undefined;
    }
    return (pcb: NestorPcb) => {
      toggle(pcb, "pcb");
    };
  }, [toggle]);

  // Removed: introduced cyclic dependencies, as selectAll is part of FW, which triggers a re-render of the table
  /*
  const selectAll = useMemo(() => {
    if (!set || !list) {
      return undefined;
    }

    return (onOff: boolean) => {
      set(list, "pcb", onOff);
    };
  }, [set, list]);
*/
  /* const selected = useMemo(() => {
    if (!els) {
      return [];
    }
    const out: Array<number> = [];

    for (const el of els) {
      if (el.type === "pcb") {
        out.push(el.el.id);
      }
    }

    return out;
  }, [els]);*/

  const fw = useMemo(() => {
    console.log("Redefine FW");
    return {
      layout,
      actionable: true,
      selectable,
      selectCtx: selectableCtx,
      onSelect,
      // selectAll,
    };
  }, [onSelect, selectable, selectableCtx, layout]); // selectAll,
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    if (props.parcel_id) {
      defaultFilter.filter.filters[0].filters.push({
        field: "pcb_package.package_id",
        operator: "eq",
        value: props.parcel_id,
      });
    }

    defaultFilter.filter.logic = "and";
    defaultFilter.take = 1000;

    extendFilterByLocation(defaultFilter, props);
    console.log("redefine filter");
    return defaultFilter;
  }, [props.container_id, props.room_id, props.laboratory_id, props.location_information, props.parcel_id]);

  return (
    <div className="mb-2">
      <CardComponent header="PCA list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericPCBListContainer
            //cardHeader="Gateway list"
            list={pcbList}
            fwProps={fw}
            listTemplate={GenericPCBTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
