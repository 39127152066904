import { NestorSupplier } from "nestor/types/purchasing/supplier";
import {
  ProjectCriticalSupplierListCard,
  ProjectCriticalSupplierListCardSimple,
} from "../../company/projects/SuppliersQuality";
import { SupplierInformationCard } from "../../company/suppliers/SupplierInformationCard";
import { ContactListCard } from "../contacts/List";
import { SupplierAccountsCard } from "./Accounts";

export const SupplierInfos = (props: { supplier: NestorSupplier; refresh: Function }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-3 col-12">
          <SupplierInformationCard supplier={props.supplier} />
        </div>
        <div className="col-md-9 col-12">
          <ContactListCard supplier={props.supplier} />
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-md-3 col-12">
          <ProjectCriticalSupplierListCardSimple
            shortform={true}
            supplier={props.supplier}
          ></ProjectCriticalSupplierListCardSimple>
        </div>
        <div className="col-md-9 col-12">
          <SupplierAccountsCard supplier={props.supplier} />
        </div>
      </div>
    </>
  );
};
