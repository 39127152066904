import { useCallback, useEffect, useRef, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { NestorContextProvider } from "../contexts/NestorContext";
import { NestorUserContextProvider } from "../contexts/NestorUserContext";
import { NestorSignature } from "./company/signature/Signature";
import { NestorMainContent } from "./MainContent";
import * as _ from "process";
import {
  AuthenticationResult,
  Configuration,
  InteractionRequiredAuthError,
  InteractionType,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider, useMsal } from "@azure/msal-react";
import assert from "assert";
import { Auth, RootMenuProvider } from "@csem/shared-utils";
import "../../assets/style/custom.scss";

const useHandleMsalAccessToken = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [token, setToken] = useState<string>();
  const t = useRef<NodeJS.Timeout>();

  const account = accounts[0];

  const acquireToken = useCallback(() => {
    let timeout: any = undefined;
    const accessTokenRequest: SilentRequest = {
      scopes: ["user.read"],
      account: account,
      forceRefresh: false,
    };

    const processResponse = (response: AuthenticationResult) => {
      Auth.setAuthToken(response.idToken);
      const exp = response.expiresOn;
      assert(exp);
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(acquireToken, exp.getTime() - Date.now());
      t.current = timeout;
      setToken(response.accessToken);
    };

    instance
      .acquireTokenSilent(accessTokenRequest)
      .then(processResponse)
      .catch(function (error) {
        //Acquire token silent failure, and send an interactive request
        if (error instanceof InteractionRequiredAuthError) {
          instance
            .acquireTokenPopup(accessTokenRequest)
            .then(processResponse)
            .catch(function (error) {
              // Acquire token interactive failure
            });
        }
      });
  }, [account, instance]);

  useEffect(() => {
    acquireToken();

    if (t.current) {
      clearTimeout(t.current);
    }
  }, [acquireToken]);

  return token;
};
const NestorMainLoggedIn = () => {
  const msal = useHandleMsalAccessToken();

  if (!msal) {
    return <></>;
  }

  return (
    <NestorContextProvider>
      <NestorUserContextProvider>
        <NestorMainContent></NestorMainContent>
      </NestorUserContextProvider>
    </NestorContextProvider>
  );
};

//const NestorMainWithLogin = WithLoginEnvironment(NestorMainLoggedIn, Login, "nestor");

// Configuration object constructed.
const config: Configuration = {
  auth: {
    redirectUri: document.location.href, // process.env.REACT_APP_URL
    authority: "https://login.microsoftonline.com/8a404457-6fc6-4cbe-b496-ad67eb677312",
    clientId: "9dc907aa-f6b2-4332-9050-d4a51db75898",
  },
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);

function NestorMain() {
  const { url } = useRouteMatch();
  useEffect(() => {
    const icon = document.querySelector("link[rel~=icon]") as HTMLLinkElement;
    if (icon) {
      icon.href = process.env.PUBLIC_URL + "/nestor/nestor-logo.svg";
      icon.type = "image/svg+xml";
    }

    const title = document.querySelector("title") as HTMLTitleElement;
    title.text = "Nestor@CSEM";
  }, []);

  if (!process.env.REACT_APP_LOGIN) {
    Auth.setAuthToken("");
  }

  return (
    <RootMenuProvider>
      <MsalProvider instance={publicClientApplication}>
        <Switch>
          <Route path={url + "signature/:key"}>
            <NestorSignature></NestorSignature>
          </Route>
          <Route>
            {!process.env.REACT_APP_LOGIN ? (
              <NestorUserContextProvider>
                <NestorMainContent></NestorMainContent>
              </NestorUserContextProvider>
            ) : (
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <NestorMainLoggedIn></NestorMainLoggedIn>
              </MsalAuthenticationTemplate>
            )}
          </Route>
        </Switch>
      </MsalProvider>
    </RootMenuProvider>
  );
}

const NestorApp = () => {
  return (
    <BrowserRouter>
      <NestorMain></NestorMain>
    </BrowserRouter>
  );
};
export default NestorApp;
