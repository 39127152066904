import {
  TaskDownloadEF952,
  TaskEditBT,
  TaskInfoCard,
  TaskInfoFromBT,
  TaskInfoFromRequester,
  TaskProgressCard,
} from "nestor/components/nestor/manufacturing/tasks/Info";
import { ConversationTableCard } from "nestor/components/nestor/util/ConversationTable";
import { NestorTask } from "nestor/types/manufacturing/task";

export const TaskInfo = ({ task, refresh }: { task: NestorTask; refresh: Function }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-5 col-12 mb-2">
          <TaskInfoCard task={task} refresh={refresh} />
        </div>
        <div className="col-md-7 col-12 mb-2">
          <div className="row">
            <div className="vstack gap-2 col-6">
              <TaskEditBT task={task} refresh={refresh} />

              {task.state === "stopped" && <TaskDownloadEF952 task={task} />}
              <TaskInfoFromRequester task={task} />
            </div>
            <div className="col-6 vstack gap-2">
              <TaskProgressCard task={task} />

              <TaskInfoFromBT task={task} />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-2">
          <ConversationTableCard type="task" id={task.id}></ConversationTableCard>
        </div>
      </div>
    </>
  );
};
