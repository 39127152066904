import { useFormSelectObj } from "@csem/forms";

export const useSkuUnitsField = (label: string, disabled: boolean, value: string | undefined) => {
  return useFormSelectObj({
    label: label,
    disabled: disabled,
    defaultValue: undefined,
    options: {
      mm: "Millimeters",
      cm: "Centimeters",
      m: "Meter",

      g: "Grams",
      kg: "Kilogramms",

      l: "Liters",
      units: "Units",
    },
    originalValue: value,
  });
};
