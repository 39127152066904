import NestorServerRoutes from "nestor/server_routes";
import { NestorCalibrationId } from "nestor/types/inventory/calibration";
import { NestorCalibrationConfirmation } from "nestor/types/inventory/calibration_confirmation";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorCalibrationConfirmation;

export const useCalibrationConfirmations = (elId: NestorCalibrationId): apiList<T> => {
  // There can be no confirmation
  return useNestorListApiCall<T>(NestorServerRoutes.device_metrological_confirmation_list(elId));
};

export const useCalibrationConfirmationSingle = (elId: NestorCalibrationId): NestorApiElement<T | null, undefined> => {
  // There can be no confirmation
  const apiCall = useNestorAPI<T>(NestorServerRoutes.device_metrological_confirmation_single(elId), true);
  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    state: apiCall.state,
    loadingIO: <IOState loadingMessage="Loading confirmation..." source={apiCall.state} success={null} />,
  };
};
