import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceType } from "nestor/types/inventory/device_type";
import { NestorPartCategoryId } from "nestor/types/stock/part_category";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";
type T = NestorDeviceType;

export const useDeviceTypeSelectField = (
  props: CommonFieldUI<string | number | undefined> & {
    categoryId?: NestorPartCategoryId | string;
  }
) => {
  return useFormSelectRemoteField<T, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: props.categoryId ? `inventory/categories/${props.categoryId}/types` : undefined,
    keyMap: (el: T) => el.id.toString(),
    valMap: (el: T) => el.name,
  });
};
