import { TaskAddEditCard } from "nestor/components/nestor/manufacturing/tasks/AddEdit";
import { NestorTask } from "nestor/types/manufacturing/task";

export const TaskEdit = (props: { task: NestorTask }) => {
  return (
    <div className="container-fluid p-3">
      <TaskAddEditCard task={props.task} />
    </div>
  );
};
