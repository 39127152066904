import { useProjectList } from "nestor/hooks/api/useProject";
import { NestorSector } from "nestor/types/company/sector";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorProjectWorkpackage } from "nestor/types/projects/workpackage";
import { GenericListRenderer } from "@csem/forms";
import { FilterLogic, FilterOperator, FilterProps, SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { ProjectRenderer } from "../../renderers/ProjectRenderer";
import { useCallback, useEffect, useState } from "react";
import { useAllSectionsField } from "nestor/components/forms/fields/SectorList";
import { useOnChange } from "@csem/shared-utils";

export const SearchByProject = SearchElFilterFactory<NestorProject, NestorProject["id"]>({
  label: "Project",
  apiListFactory: useProjectList,
  traverser: (el) => el.id,
  filterKeys: {
    code: "contains",
    name: "contains",
  },
  template: GenericListRenderer((e) => <ProjectRenderer project={e} />),
  key: "id",
});

const SearchByCostCenter = function ({
  onChange,
}: {
  onChange: (value: number | undefined | string, operator: FilterOperator) => void;
}) {
  const sections = useAllSectionsField({
    label: "Group",
    originalValue: undefined,
    defaultValue: undefined,
  });

  const v = sections.value;
  useOnChange(
    function () {
      onChange(typeof v == "string" ? parseInt(v) : undefined, "eq");
    },
    [v],
    [onChange]
  );

  return sections.Content;
};

export const ProjectColumn = <T,>(
  name: string,
  label: string,
  field: string,
  filterByProject: boolean,
  filterByCostCenter: boolean,
  renderer: (el: T) => NestorProject | null | undefined,
  wpRenderer?: (el: T) => NestorProjectWorkpackage | null,
  costCenterRenderer?: (el: T) => NestorSector | null
) => {
  const wrapper = function Filter(props: FilterProps<string | number>) {
    const [type, setType] = useState<"project" | "cost_center">("project");

    const ProjectButton = filterByProject ? (
      <button
        className={`btn btn-${type === "project" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("project");
        }}
      >
        <em className="bi bi-briefcase"></em> Project
      </button>
    ) : null;

    const CostCenterButton = filterByCostCenter ? (
      <button
        className={`btn btn-${type === "cost_center" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("cost_center");
        }}
      >
        <em className="bi bi-bounding-box"></em> Group
      </button>
    ) : null;

    const costCenterChanged = useCallback(
      (v: string | number | undefined, o: FilterOperator) => {
        props.onChange(undefined, "contains", "project");
        props.onChange(undefined, "contains", "sector");

        props.onChange(v, o, "sector");
      },
      [props.onChange]
    );

    return (
      <>
        <div className="btn-group">
          {ProjectButton}
          {CostCenterButton}
        </div>
        {type === "project" ? (
          <SearchByProject
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "project");
              props.onChange(undefined, "contains", "sector");
              props.onChange(v, o, "project");
            }}
          />
        ) : type === "cost_center" ? (
          <SearchByCostCenter onChange={costCenterChanged} />
        ) : null}
      </>
    );
  };

  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return (
        <ProjectRenderer
          project={renderer(el)}
          cost_center={costCenterRenderer ? costCenterRenderer(el) : null}
          workpackage={wpRenderer ? wpRenderer(el) : null}
        />
      );
    }),
    wrapper,
    {
      project: "project_id",
      sector: "section.id",
    }
  );
};
