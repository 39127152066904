import { useEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { useSupplierSearchField } from "nestor/components/forms/fields/SupplierSearch";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useMyId } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorProjectSupplier } from "nestor/types/projects/project_suppliers";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useProjectSearchField } from "nestor/components/forms/fields/ProjectSearch";
import { NestorSupplier } from "nestor/types/purchasing/supplier";

import { useSupplierQualificationList } from "nestor/hooks/api/useSupplierQualification";
import { useSupplierQualificationSearchField } from "nestor/components/forms/fields/SupplierQualificationSearch";
import { NestorSupplierQualification } from "nestor/types/purchasing/supplier_qualification";

export const CriticalSupplierProjectAddEditModal = StandardFormAddEditModalFactory<
  NestorProjectSupplier,
  any & { supplier?: NestorSupplier; supplier_qualification?: NestorSupplierQualification; project?: NestorProject },
  any
>({
  title: (el, fw) => (el ? `Edit the link to the supplier` : `Link to a supplier`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const myId = useMyId();
    const employee_id = useEmployeeSearchField({
      label: "Employee",
      originalValue: el?.employee_id,
      defaultValue: myId,
      validationOnChange: useFieldOptRequired(true),
    });

    const project_id = useProjectSearchField({
      label: "Project",
      originalValue: el?.project_id || fw.project?.id || undefined,
      defaultValue: undefined,
    });
    const supplier_id = useSupplierSearchField({
      label: "Supplier",
      originalValue: el?.supplier_id || fw.supplier?.id || undefined,
      defaultValue: undefined,
    });

    const qualification = useSupplierQualificationSearchField({
      apiListFactory: function useApi() {
        return useSupplierQualificationList(supplier_id.value || -1);
      },
      label: "Qualification",
      defaultValue: undefined,
      originalValue: el?.supplier_qualification_id || fw.supplier_qualification?.id || undefined,
    });
    const scope = useFormTextField({
      label: "Scope",
      originalValue: el?.scope || "",
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const _class = useFormSelectObj({
      label: "Class",
      originalValue: el?.class || "B",
      defaultValue: "C",
      options: { A: "A", B: "B", C: "C" },
    });

    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.suppliers.criticalities.project_create()
        : NestorServerRoutes.suppliers.criticalities.project_update(el.id),
      false,
      undefined,
      NestorPOST
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        employee_id: employee_id.value,
        project_id: project_id.value,
        supplier_id: supplier_id.value,
        supplier_qualification_id: qualification.value,
        scope: scope.value,
        class: _class.value,
      });
    };

    return {
      fields: {
        employee_id,
        supplier_id,
        qualification,
        project_id,
        scope,
        _class,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
