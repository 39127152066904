// Hooks

// Classes

// Components

// Contexts
import { SupplierQuickAddEditModal } from "nestor/components/nestor/purchasing/suppliers/Modals/Add";
import { AddressRenderer } from "nestor/components/renderers/AddressRenderer";
import { ContactRenderer } from "nestor/components/renderers/ContactRenderer";
import { SupplierNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { useSupplierList } from "nestor/hooks/api/useSupplierList";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { CardComponent } from "@csem/shared-utils";
import { DefaultStandardTableFilter, HasTableFilterClear, StandardTableFilterCtxProvider } from "@csem/lists";
import { TableSearch } from "@csem/lists";
// ## Generic
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { FilterableColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasRefresh, HasSearch } from "@csem/lists";
//# Tables
//## Nestor
import { DateColumn } from "../../../tables/columns/DateColumn";
import { useMemo } from "react";
import { cloneDeep } from "lodash";

type T = NestorSupplier;

const GenericSupplierTable = (function () {
  return TableRenderer<T, {}, {}, {}, "id">({
    key: "id",
    columns: [
      SupplierNavigationColumn,
      NUIDColumn,

      FilterableColumn(
        SortableColumn(
          SimpleColumn("name", "Name", (e) => e.name),
          "name"
        ),
        TextFilterFactory({ label: "Name" })
      ),

      SimpleColumn("address", "Address", (e) => <AddressRenderer address={e} />),
      SimpleColumn("context", "Contact", (e) => <ContactRenderer contact={e} />),
      SimpleColumn("n_orders", "# Orders", (e) => e.orders_count || "N/A"),
      //   SimpleColumn("account", "Account number", (e) => e.account),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth() {
      return {
        _nav: "0px",
        nuid: "65px",
      };
    },
  });
})();

const GenericSupplierListContainer = HasTableFilterClear(
  HasSearch(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<{}, T>())),
      SupplierQuickAddEditModal,
      "Add a new supplier",
      "plus",
      "primary"
    ),

    {
      name: "contains",
      nuid: "eq",
      account: "contains",
      address: "contains",
      city: "contains",
      postal_code: "eq",
    },
    "or",
    undefined,
    TableSearch
  )
);

export const SupplierListCard = () => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort[0] = {
      dir: "asc",
      field: "name",
    };
    return defaultFilter;
  }, []);

  return (
    <div className="m-2">
      <CardComponent header="Supplier list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericSupplierListContainer
            //cardHeader="Gateway list"
            list={useSupplierList()}
            fwProps={{}}
            listTemplate={GenericSupplierTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
