// Hooks

// Classes

// Components
//## Nestor
import { DeviceTypeModal } from "nestor/components/modals/AddEditDeviceType";
import { DeviceTypeMergeModal } from "nestor/components/modals/MergeDeviceType";
import { useDeviceTypeList } from "nestor/hooks/api/useDeviceTypeList";
import { useAmIElevated } from "nestor/hooks/me";
import { NestorDeviceType } from "nestor/types/inventory/device_type";
import { CardComponent } from "@csem/shared-utils";
import { EditButton, HasTableFilterClear } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// Contexts
// ## Generic
import { ButtonsColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasEdition,
  HasModalMethod,
  HasPagination,
  HasRefresh,
  HasSearch,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { DeviceListModal, InspectDevices } from "../devices/ModalList";
type T = NestorDeviceType;
type FW = { is_elevated: boolean };
type FWList = EditMethod<T, FW> & { merge?: (e: T, fw: FW) => void } & Partial<InspectDevices<T, FW>>;

export const GenericTypeTable = (function () {
  return TableRenderer<T, FW, FWList, {}, "id">({
    key: "id",
    columns: [
      //   DeviceNavigationColumn,

      // NUIDColumn,

      SimpleColumn("category", "Category", (e, fw, hk) => e.category?.name),

      SortableColumn(
        SimpleColumn("name", "Name", (e, fw, hk) => e.name),
        "name"
      ),

      SimpleColumn("num_model", "# Device models", (e, fw, hk) => e.models_count || "N/A"),
      //   SimpleColumn("num_devices", "# Devices", (e, fw, hk) => e.devices_count || "N/A"),

      SimpleColumn("devices", "View devices", (e, fw, hk) => {
        return (
          <button type="button" onClick={(_) => fw.inspect_devices?.(e, fw)} className="btn btn-secondary">
            <em className="bi bi-search"></em>
          </button>
        );
      }),

      ButtonsColumn("actions", "Actions", [
        EditButton("Rename", (e, fw) => fw.is_elevated),
        {
          title: "Merge into another type",
          theme: "warning",
          cb: (e, fw) => {
            fw.merge?.(e, fw);
          },
        },
      ]),
      //  DateColumn("created_at", "Created", "created_at", e => e.created_at)
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return { actions: "300px", devices: "80px" };
    },
  });
})();

const GenericDeviceTypeListContainer = (function () {
  return HasTableFilterClear(
    HasModalMethod(
      HasSearch(
        HasModalMethod(
          HasEdition(
            HasAddition(
              HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())),
              DeviceTypeModal,
              "Add a new type",
              "plus",
              "primary"
            ),
            DeviceTypeModal
          ),
          DeviceTypeMergeModal,
          "merge"
        ),

        {
          name: "contains",
        },
        undefined,
        undefined,
        TableSearch
      ),

      DeviceListModal("model.device_type_id"),
      "inspect_devices"
    )
  );
})();

export const DeviceTypesListCard = () => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "asc",
        field: "name",
      },
    ];

    return defaultFilter;
  }, []);
  const is_elevated = useAmIElevated();
  return (
    <div className="m-2">
      <CardComponent header="Listing of all device types">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericDeviceTypeListContainer
            //cardHeader="Gateway list"
            list={useDeviceTypeList()}
            fwProps={{ is_elevated }}
            listTemplate={GenericTypeTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
