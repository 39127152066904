import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { CardComponent } from "@csem/shared-utils";
import { SupplierFeedbackList } from "../orders/feedbacks/modals/FeedbackList";

export const SupplierFeedbackListCard = (props: { supplier: NestorSupplier }) => {
  return (
    <CardComponent header="Feedback list" additionalClasses={"h-100"}>
      <SupplierFeedbackList supplierId={props.supplier.id} maxHeight={800} />
    </CardComponent>
  );
};
