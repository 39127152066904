import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import { IOState } from "@csem/api";
import { useConfirmationModal } from "@csem/shared-utils";
import moment from "moment";
import { useCallback, useEffect } from "react";
import { InfoBadge } from "../../util/InfoBadge";

export const SignatureStatus = (props: { order: NestorOrder }) => {
  return (
    <div>
      {props.order.signatures && props.order.signatures.length > 0 && (
        <>
          {" "}
          <h6>Signing status: </h6>
          <div className="vstack gap-2 mt-2">
            {props.order.signatures?.map((s) => (
              <InfoBadge
                fullWidth={true}
                theme={s.signed_at ? "success" : "info"}
                icon={s.signed_at ? "check" : "hourglass-split"}
              >
                <div>{s.signed_by?.fullname}</div>
                <small>({s.signed_at ? moment(s.signed_at).format("YYYY-MM-DD") : "Not signed yet"})</small>
              </InfoBadge>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
/*
const OpenPDFFile = (props: { order: NestorOrder }) => {
  const apiFetchPDFCSEMPath = useNestorAPI<{ url: string }, string>(
    NestorServerRoutes.signature_get_csem_path_win(props.order.id),
    false
  );

  return (
    <button
      className="btn btn-info w-100 h-100"
      onClick={() => {
        apiFetchPDFCSEMPath.doQuery().then((out) => {
          if (!out) return;
          const url = out?.url;
          window.open(url, "_blank");
        });
      }}
    >
      Open PDF in Nestor
    </button>
  );
};
*/
const FetchPDFFile = (props: { order: NestorOrder }) => {
  const hasStartedWF = Array.isArray(props.order.signatures) && props.order.signatures.length > 0;
  const apiFetchPDF = useNestorAPI<NestorOrder, string>(
    () => `purchasing/orders/${props.order.id}/signing_fetch_pdf`,
    false
  );

  const apiFetchPDFCSEMPath = useNestorAPI<{ url: string }, string>(
    NestorServerRoutes.signature_get_csem_path_win(props.order.id),
    true
  );

  const { Modal: ModalConfirmFetchPDF, invoke: invokeFetchPDF } = useConfirmationModal(
    "Fetch PDF from server",
    <>
      <div className="text-danger fw-bold">WARNING</div>
      You are about the fetch the PDF from the file server. This will <strong>work only once</strong>. Make sure the PDF
      has properly been signed first !<br />
      <code>{apiFetchPDFCSEMPath.result?.url}</code>
    </>,
    function (lang: string) {
      apiFetchPDF.doQuery();
    },
    "Oops. Un expected error occured",
    apiFetchPDF
  );

  return (
    <>
      {ModalConfirmFetchPDF}
      <IOState source={apiFetchPDF.state} successMessage="PDF fetched and added (please verify, though)" />
      <button
        type="button"
        disabled={!hasStartedWF}
        onClick={() => invokeFetchPDF("__")}
        className="btn btn-warning w-100 h-100"
      >
        Fetch PDF from file server
      </button>
    </>
  );
};

export const SignatureButtons = ({ order, refresh }: { order: NestorOrder; refresh: Function }) => {
  const apiStartSigningWF = useNestorAPI<NestorOrder, string>(
    (el?: string) => `purchasing/orders/${order.id}/signing_process/${el}`,
    false
  );
  const { state: stateSigningWFStart, doQuery: doStartSigningWF } = apiStartSigningWF;

  const apiRemind = useNestorAPI<NestorOrder, string>(() => {
    return NestorServerRoutes.signature_remind(order.id);
  }, false);

  const apiCancel = useNestorAPI<NestorOrder, string>(
    () => {
      return NestorServerRoutes.signature_cancel(order.id);
    },
    false,
    undefined,
    { method: "DELETE" }
  );

  const stateSigningWFCancel = apiCancel.state;

  const { Modal, invoke: invokeConfirmationModal } = useConfirmationModal(
    "Restart signing process",
    <>
      You are about to restart the signing process. All existing signatures will be revoked and both signees will
      receive a new e-mail. Is that really what you want to do ?
    </>,
    function (lang: string) {
      doStartSigningWF(lang);
    },
    "Oops. Un expected error occured",
    apiStartSigningWF
  );

  const { Modal: SAPModal, invoke: invokeSAPModal } = useConfirmationModal<string>(
    "SAP number missing",
    <>
      <div className="text-danger">
        <strong>WARNING !</strong> The SAP number is missing. You may proceed, but make sure the ordered amount is
        within what is allowed by your BU for orders without an SAP number. <br />
        <strong>Are you sure you want to proceed ?</strong>
      </div>
    </>,
    function (lang: string) {
      doStartSigningWF(lang);
    },
    "Oops. Un expected error occured",
    apiStartSigningWF
  );

  const hasStartedWF = Array.isArray(order.signatures) && order.signatures.length > 0;

  useEffect(() => {
    if (stateSigningWFStart.success) {
      refresh();
    }
  }, [stateSigningWFStart, refresh]);

  useEffect(() => {
    if (stateSigningWFCancel.success) {
      refresh();
    }
  }, [stateSigningWFCancel, refresh]);

  const start = useCallback(
    (lang: string) => {
      if (!order.sap_id) {
        invokeSAPModal(lang);
      } else if (Array.isArray(order.signatures) && order.signatures.length > 0) {
        // If the order has already been signed, trigger a warning
        invokeConfirmationModal(lang);
      } else {
        // Otherwise, start directly
        doStartSigningWF(lang);
      }
    },
    [order, doStartSigningWF, invokeConfirmationModal, invokeSAPModal]
  );

  if (order.signee_left_id === null || order.signee_right_id === null) {
    return <div className="alert alert-info">Assign signees before starting the signing process</div>;
  }

  return (
    <div>
      {Modal} {SAPModal}
      <IOState
        source={stateSigningWFStart}
        successMessage="Signing process started. We need to wait for the signees to do their part"
      />
      <IOState source={apiRemind.state} successMessage="Reminder e-mail sent" />
      <h6>Start signing process in: </h6>
      {order.signing_notified_at ? (
        <div className="text-danger">
          Request / reminder sent on the {moment(order.signing_notified_at).format("DD.MM.YYYY")}
        </div>
      ) : null}
      <div className="btn-group w-100">
        <button type="button" disabled={true} className="btn btn-primary w-100 h-100 text-nowrap">
          Start workflow :
        </button>
        <button type="button" onClick={() => start("en")} className="btn btn-outline-primary w-100 h-100">
          en
        </button>
        <button type="button" onClick={() => start("fr")} className="btn btn-outline-primary w-100 h-100">
          fr
        </button>
        <button type="button" onClick={() => start("de")} className="btn btn-outline-primary w-100 h-100">
          de
        </button>
      </div>
      <div className="btn-group w-100">
        <button
          type="button"
          disabled={!hasStartedWF}
          onClick={() => apiRemind.doQuery()}
          className="btn btn-primary w-100 h-100"
        >
          Send reminder
        </button>

        <button
          type="button"
          disabled={!hasStartedWF}
          onClick={() => apiCancel.doQuery()}
          className="btn btn-danger w-100 h-100"
        >
          Cancel workflow
        </button>
      </div>
      <FetchPDFFile order={order} />
      {/*}   <OpenPDFFile order={order} />*/}
    </div>
  );
};

export const SigningCard = ({ order, refresh, admin }: { admin: boolean; order: NestorOrder; refresh: Function }) => {
  return (
    <CardComponent header="Signing workflow">
      <div className="row">
        {admin && (
          <div className="col-xl-6 col-12">
            <SignatureButtons order={order} refresh={refresh} />
          </div>
        )}
        <div className="col-xl-6 col-12">
          <SignatureStatus order={order} />
        </div>
      </div>
    </CardComponent>
  );
};
