import { NestorPart } from "nestor/types/stock/part";
import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";

export const usePartSingle = (elId: number | string): NestorApiElement<NestorPart, undefined> => {
  const apiCall = useNestorAPI<NestorPart>(`stock/parts/${elId}`, true);
  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading part..." source={apiCall.state} success={null} />,
  };
};
