import { OrderListCard } from "nestor/components/nestor/purchasing/orders/List";
import { SupplierEditCreateForm } from "nestor/components/nestor/purchasing/suppliers/Edit";
import { SupplierFeedbackListCard } from "nestor/components/nestor/purchasing/suppliers/FeedbackList";
import { SupplierInfos } from "nestor/components/nestor/purchasing/suppliers/Info";
import { ConversationCard } from "nestor/components/nestor/util/Conversation";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { NestorPermissionContextProvider } from "nestor/contexts/NestorPermissionContext";
import { useSupplierSingle } from "nestor/hooks/api/useSupplierSingle";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { SupplierQualifications } from "./Qualifications";
import { ProjectCriticalSupplierListCard } from "nestor/components/nestor/company/projects/SuppliersQuality";
//import { ProjectCriticalSupplierListCard } from "nestor/components/nestor/company/projects/SuppliersQuality";

const SupplierDetailInner: React.FunctionComponent<{
  supplier: NestorSupplier;
  refresh: Function;
}> = (props) => {
  const route = useRouteMatch<{ supplier: string }>();
  useMenuElement("Details", route.url + "/details");
  useMenuElement("Edit information", route.url + "/edit");
  useMenuElement("Evaluations", route.url + "/evaluations");

  useMenuElement("Documents", route.url + "/documents");
  const conversations = useMenuElement("Conversation", route.url + "/conversation");
  useMenuElement("List of orders", route.url + "/orders");

  const qualifications = useMenuElement("Qualifications", route.url + "/qualifications");
  useMenuElement("Critical projects", route.url + "/critical_projects");

  return (
    <NestorPermissionContextProvider ressource="supplier" id={route.params.supplier}>
      <Switch>
        <Route path={`${route.url}/details`}>
          <SupplierInfos supplier={props.supplier} refresh={props.refresh}></SupplierInfos>
        </Route>
        <Route path={`${route.url}/edit`}>
          <SupplierEditCreateForm supplier={props.supplier} />
        </Route>
        <Route path={`${route.url}/qualifications`}>
          <XemwayMenuProvider {...qualifications}>
            <SupplierHeader supplier={props.supplier} />
            <SupplierQualifications supplier={props.supplier} />
          </XemwayMenuProvider>
        </Route>
        <Route path={`${route.url}/evaluations`}>
          <SupplierFeedbackListCard supplier={props.supplier} />
        </Route>
        <Route path={`${route.url}/documents`} key="documents">
          <SupplierHeader supplier={props.supplier} />
          <DocumentListCard type="supplier" id={props.supplier.id}></DocumentListCard>
        </Route>
        <Route path={`${route.url}/conversation`} key="conversation">
          <XemwayMenuProvider {...conversations}>
            <SupplierHeader supplier={props.supplier} />
            <ConversationCard type="supplier" id={props.supplier.id}></ConversationCard>
          </XemwayMenuProvider>
        </Route>
        <Route path={`${route.url}/orders`}>
          <SupplierHeader supplier={props.supplier} />
          <OrderListCard supplierId={props.supplier.id}></OrderListCard>
        </Route>

        <Route path={`${route.url}/critical_projects`}>
          <SupplierHeader supplier={props.supplier} />
          <ProjectCriticalSupplierListCard supplier={props.supplier}></ProjectCriticalSupplierListCard>
        </Route>

        <Route>
          <Redirect to={`${route.url}/details`} />
        </Route>
      </Switch>
    </NestorPermissionContextProvider>
  );
};

export const SupplierDetail: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ supplier: string }>();
  const supplier = useSupplierSingle(route.params.supplier);
  const menu = useMenuElement(
    supplier.element ? `Supplier ${supplier.element.nuid}` : "Loading...",
    `Supplier ${route.params.supplier}`
  );

  return (
    <XemwayMenuProvider {...menu}>
      {supplier.loadingIO}
      <div className="container-fluid p-3">
        {supplier.element ? <SupplierDetailInner supplier={supplier.element} refresh={supplier.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};

const SupplierHeader = (props: { supplier: NestorSupplier }) => {
  return <h2 className="border-bottom mb-4 mx-3 text-muted">Supplier: {props.supplier.name}</h2>;
};
