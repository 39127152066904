import { LocationRenderer } from "nestor/components/renderers/LocationRenderer";
import NestorFrontendRoutes from "nestor/frontend_routes";
import { useNestorListApiCall } from "nestor/hooks/api/useNestorListApi";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { CardComponent } from "@csem/shared-utils";
import { TableFilterCtxProvider } from "@csem/lists";
import { NavigationLookup } from "@csem/lists";
import { IOState } from "@csem/api";
import { useAutoSearchTrigger } from "@csem/lists";
import { InventoryNumberSpan } from "./InventoryNumber";

const AssociateDevice = ({ device }: { device: NestorDevice }) => {
  return (
    <li className="list-group-item d-flex justify-content-between">
      <InventoryNumberSpan text={device.nuid} device={device} />
      <div>
        {device.model?.model}
        <br />
        <div className="text-muted">{device.model?.manufacturer?.name}</div>
      </div>
      <LocationRenderer location={device.location} fallbackText="No information"></LocationRenderer>
      <NavigationLookup icon="search" path={NestorFrontendRoutes.device_details(device.id)} />
    </li>
  );
};

const AssociatedToCardInner = ({ device }: { device: NestorDevice }) => {
  const api = useNestorListApiCall<NestorDevice>(
    NestorServerRoutes.device_associated_to(device.id),
    "associated devices"
  );

  useAutoSearchTrigger(api);

  return (
    <CardComponent header="Associated to">
      <ul className="list-group">
        {api.list ? (
          api.list.map((device) => <AssociateDevice device={device}></AssociateDevice>)
        ) : (
          <IOState source={api.state} />
        )}

        {api.list && api.list.length === 0 ? (
          <li className="list-group-item text-muted text-center">Not associated to any items</li>
        ) : null}
      </ul>
    </CardComponent>
  );
};

const AssociatedToCard = ({ device }: { device: NestorDevice }) => {
  return (
    <TableFilterCtxProvider>
      <AssociatedToCardInner device={device} />
    </TableFilterCtxProvider>
  );
};

export default AssociatedToCard;
