import { NestorOrderable } from "nestor/types/purchasing/article";
import { NestorPart } from "nestor/types/stock/part";
import { NestorContainerSku } from "nestor/types/stock/sku_container";

export const SkuContainerRenderer = ({
  skuctn,
  fallbackText,
}: {
  skuctn?: NestorContainerSku;
  fallbackText?: string;
}) => {
  if (!skuctn || !skuctn.sku) {
    return <div className="text-muted">{fallbackText || null}</div>;
  }

  const sku = skuctn.sku;

  return (
    <>
      <div className="fw-bold">
        <em className="bi bi-cpu"></em> {sku.nuid}{" "}
        {sku.article?.description ||
          (sku.article?.orderable_type === NestorOrderable.PART
            ? (sku.article?.orderable as NestorPart | undefined)?.description
            : "")}
      </div>

      <div className="d-flex  text-muted">
        {skuctn.container ? (
          <div className="pe-2">
            <em className="bi bi-stack"></em> {skuctn.container.name}
          </div>
        ) : null}

        {sku.employee ? (
          <div>
            <em className="bi bi-person"></em> {sku.employee.initials}
          </div>
        ) : null}
      </div>
    </>
  );
};
