import { useDeviceParkSearchField } from "nestor/components/forms/fields/DeviceParkSearch";
import { FormDeviceQuick } from "nestor/components/nestor/inventory/devices/modals/AddDevice";
import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useDeviceWrite } from "nestor/hooks/api/useDeviceWrite";
import { useAmIInventoryAdmin } from "nestor/hooks/me";
import { NestorDevice } from "nestor/types/inventory/device";
import { CardComponent } from "@csem/shared-utils";
import { useButtonRadioField } from "@csem/forms";
import { useFormCheckboxField } from "@csem/forms";
import { useFormFileField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import React from "react";
import { useQuickEditFieldsDevice } from "./AddEditQuick";

export const useDeviceBorrowingFields = (el?: NestorDevice) => {
  const is_fixed_f = useFormCheckboxField({
    label: "May be borrowed",
    defaultValue: false,
    originalValue: !el?.is_fixed,
  });

  const borrow_max_duration_days_f = useFormSelectObj({
    label: "Maximum borrowing duration",
    defaultValue: el ? undefined : 360,
    originalValue: el?.borrow_max_duration_days,
    options: {
      1: "1 day",
      7: "1 week",
      14: "2 weeks",
      31: "1 month",
      61: "2 months",
      90: "3 months",
      180: "6 months",
      360: "1 year",
      720: "2 year",
      1440: "4 year",
      100000: "Seriously ?? Longer still ?",
    },
  });

  return {
    is_fixed_f,
    borrow_max_duration_days_f,
  };
};
/**
 * Shows a device edition form, with API communication
 * @param props
 * @param props.device A device to edit
 * @returns A JSX.Element representing the form
 */
export const DeviceEditCreateForm: React.FunctionComponent<{
  device: NestorDevice;
}> = (props) => {
  const form = useFormAddEditDevice(props.device);
  return (
    <>
      <IOState loadingMessage="Saving the device in progres..." source={form.state} />
      {form.Content}
    </>
  );
};

const useFormAddEditDevice = (el: NestorDevice | undefined) => {
  const {
    manufacturer_id_f,
    model_id_f,
    owner_id_f,
    location_f,
    serialnumber_f,
    onManufacturerAdded,
    onDeviceModelAdded,
  } = useQuickEditFieldsDevice(el);

  const macaddress_f = useFormTextField({
    label: "MAC address",
    defaultValue: "",
    originalValue: el?.mac_address,
    type: FieldValueType.STRING,
  });

  const hostname_f = useFormTextField({
    label: "Hostname",
    defaultValue: "",
    originalValue: el?.hostname,
    type: FieldValueType.STRING,
  });

  /*
  const is_reference_f = useFormCheckboxField({
    label: "Is a metrological reference (used to calibrate other devices)",
    defaultValue: false,
    originalValue: !!el?.is_reference,
  });
*/
  const is_networked_f = useFormCheckboxField({
    label: "Connected to the CSEM network",
    defaultValue: false,
    originalValue: !!el?.is_networked,
  });

  const is_obsolete_f = useFormCheckboxField({
    label: "Mark as obsolete",
    defaultValue: false,
    originalValue: !!el?.is_obsolete,
  });

  const to_update = useFormCheckboxField({
    label: "Draft inventory",
    defaultValue: false,
    originalValue: !!el?.to_update,
  });
  /*
  const is_calibrated_f = useFormCheckboxField({
    label: "Requires calibration",
    defaultValue: false,
    originalValue: el ? el?.is_calibrated > 0 : false,
  });
*/
  const calibration_f = useButtonRadioField<"reference" | "calibrated" | "none">({
    options: [
      {
        value: "reference",
        text: "Reference device",
        textHelp: "A device used to calibrate devices",
        theme: "danger",
      },
      {
        value: "calibrated",
        text: "Calibrated device",
        textHelp: "Requires periodic calibration",
        theme: "warning",
      },
      {
        value: "none",
        text: "No calibration",
        textHelp: "",
        theme: "primary",
      },
    ],
    defaultValue: "none",
    label: "",
    originalValue: el?.is_reference ? "reference" : el?.is_calibrated ? "calibrated" : "none",
  });

  const device_park_id = useDeviceParkSearchField({
    label: "Device park",
    defaultValue: undefined,
    originalValue: el?.device_park_id || undefined,
  });
  /*
    const metrological_period_days_f = useFormSelectObj({
        label: "Metrological check interval",
        defaultValue: 365,
        originalValue: el?.metrological_period_days,
        options: {
            180: "6 months",
            365: "1 year",
            730: "2 years",
            1095: "3 years",
            1825: "5 years",
            3650: "10 years",
            7300: "20 years"
        }
    });

    const calibration_period_days_f = useFormSelectObj({
        label: "Calibration interval",
        defaultValue: 365,
        originalValue: el?.calibration_period_days,
        options: {
            180: "6 months",
            365: "1 year",
            730: "2 years",
            1095: "3 years",
            1825: "5 years",
            3650: "10 years",
            7300: "20 years"
        }
    });
*/
  const { is_fixed_f, borrow_max_duration_days_f } = useDeviceBorrowingFields(el);

  const picture = useFormFileField({
    label: "Picture",
    defaultValue: undefined,
    originalValue: !!el?.picture || undefined,
  });

  const apiResult = useDeviceWrite();

  const elevated = useAmIInventoryAdmin();

  const form = useForm({
    onSubmit: () => {
      // There might be a file in this form, so we need to use a multipart upload
      let f = new FormData();
      f.set("model_id", String(model_id_f.value));
      f.set("owner_id", String(owner_id_f.value));

      // Location fields
      f.set("locationType", location_f.type);
      f.set("room_id", String(location_f.room_id.value));
      f.set("laboratory_id", String(location_f.laboratory_id.value));
      f.set("container_id", String(location_f.container_id.value));
      f.set("location_other", location_f.location_other.value);

      // Device park
      if (device_park_id.value) {
        f.set("device_park_id", String(device_park_id.value));
      }

      f.set("serialnumber", serialnumber_f.value);
      f.set("borrow_max_duration_days", !is_fixed_f.value ? "0" : String(borrow_max_duration_days_f.value));
      f.set("is_calibrated", calibration_f.value !== "none" ? "1" : "0");

      if (elevated) {
        f.set("is_obsolete", is_obsolete_f.value ? "1" : "0");
      }
      f.set("is_fixed", is_fixed_f.value ? "0" : "1");
      f.set("is_networked", is_networked_f.value ? "1" : "0");
      f.set("is_reference", calibration_f.value === "reference" ? "1" : "0");
      f.set("hostname", hostname_f.value);
      f.set("mac_address", macaddress_f.value);
      f.set("to_update", to_update.value ? "1" : "0");

      if (picture.value instanceof File) {
        f.set("file", picture.value);
      }

      apiResult.doQuery(el, f);
    },
    fields: {
      manufacturer_id_f,
      model_id_f,
      owner_id_f,
      serialnumber_f,
      borrow_max_duration_days_f,
      is_obsolete_f,
      is_fixed_f,
      is_networked_f,
      calibration_f,
      hostname_f,
      to_update,
      macaddress_f,
      device_park_id,
    },
    Template: FormDevice,
    fw: {
      locationSwitchButtons: location_f.SwitchButtons,
      locationField: location_f.Field,
      el,
      onManufacturerAdded,
      onDeviceModelAdded,
    },
  });

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

type FieldNames =
  | "manufacturer_id_f"
  | "model_id_f"
  | "owner_id_f"
  | "serialnumber_f"
  | "borrow_max_duration_days_f"
  | "is_obsolete_f"
  | "is_fixed_f"
  | "calibration_f"
  | "is_networked_f"
  | "hostname_f"
  | "macaddress_f"
  | "to_update"
  | "device_park_id";

export const FormDevice: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  el?: NestorDevice;
  onManufacturerAdded: (result: any) => void;
  onDeviceModelAdded: (result: any) => void;

  locationSwitchButtons: JSX.Element;
  locationField: JSX.Element;

  submit: Function;
}> = (props) => {
  const elevated = useAmIInventoryAdmin();

  const can_edit = useHasPermission("edit");

  if (!can_edit) {
    return <div className="alert alert-danger">You do not have the permissions to edit this inventory</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col-12 mb-2 d-flex ">
          <button type="button" className="btn btn-success btn-lg flex-grow-1" onClick={() => props.submit()}>
            Save the device
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mb-2">
          <CardComponent header="Main information">
            <FormDeviceQuick {...props} />
            {props.fields.device_park_id.Content}

            {elevated && props.fields.is_obsolete_f.Content}
            {props.fields.to_update.Content}
          </CardComponent>
        </div>
        <div className="col-md-6 mb-2">
          <div className="vstack gap-2">
            <CardComponent header="Calibration">
              <div className="d-flex justify-content-center">{props.fields.calibration_f.Content}</div>

              {/*{props.fields.is_calibrated_f.value ? (
                            <>
                                {props.fields.calibration_period_days_f.Content}
                                {props.fields.metrological_period_days_f.Content}
                            </>
                        ) : null}*/}
            </CardComponent>
            <CardComponent header="Networking">
              {props.fields.is_networked_f.Content}

              {props.fields.is_networked_f.value ? (
                <>
                  {props.fields.hostname_f.Content}
                  {props.fields.macaddress_f.Content}
                </>
              ) : null}
            </CardComponent>

            <DeviceEditBorrowing
              field_fixed={props.fields.is_fixed_f}
              field_duration={props.fields.borrow_max_duration_days_f}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const DeviceEditBorrowing = (props: { field_fixed: Field<any>; field_duration: Field<any> }) => {
  return (
    <CardComponent header="Borrowing">
      {props.field_fixed.Content}
      {props.field_fixed.value ? <>{props.field_duration.Content}</> : null}
    </CardComponent>
  );
};
