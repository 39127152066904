import { useEmployeeBTMult, useEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { usePcbLayoutSearchField } from "nestor/components/forms/fields/PcbLayoutSearch";
import { useTaskTypeSearchField } from "nestor/components/forms/fields/TaskTypeSearch";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useAmIBT, useAmIBTAdmin, useMyId } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorTask } from "nestor/types/manufacturing/task";
import { CardComponent } from "@csem/shared-utils";
import { useFormDateField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { useFormTextareaField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { CombineCustomValidation, InTheFuture, IsAfter } from "@csem/forms";
import { IOState } from "@csem/api";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router";
import url from "url";
import { useProjectWPField } from "../../purchasing/orders/Edit";

export const useBTAdminFormFragment = (el?: NestorTask) => {
  const priority = useFormTextField({
    label: "Priority",
    originalValue: el?.priority,
    type: FieldValueType.NUMBER,
    defaultValue: 0,
  });

  const assignees = useEmployeeBTMult({
    label: "Assignees",
    defaultValue: useMemo(() => [], []),
    originalValue: useMemo(() => el?.assignees?.map((v) => v.id) || [], [el]),
  });

  return { assignees, priority };
};

const DateInTheFuture = CombineCustomValidation<string | undefined>(InTheFuture);

export const useTaskAddEditForm = (el: NestorTask | undefined) => {
  // nname, type, num_items, requested_hours, earliest_start_date, due_date, server_path, description_changes, description_deadline, deliverables,

  const isAdmin = useAmIBTAdmin();
  const myId = useMyId();

  const disabled = !!el?.granted && !isAdmin;
  const name = useFormTextField({
    label: "Task name",
    originalValue: el?.name,
    type: FieldValueType.STRING,
    defaultValue: "",
    disabled: disabled,
    validationOnChange: useFieldOptRequired<string>(!disabled),
  });

  const type_id = useTaskTypeSearchField({
    label: "Task type",
    defaultValue: undefined,
    originalValue: el?.task_type_id,
    disabled: disabled,

    validationOnChange: useFieldOptRequired(!disabled),
  });

  const requested_by_id = useEmployeeSearchField({
    label: "Requester",
    originalValue: el?.requested_by_id || myId || undefined,
    defaultValue: undefined,
  });

  const { project_id, project_workpackage_id } = useProjectWPField(el, true);

  const pcb_layout_id = usePcbLayoutSearchField({
    label: "PCB Layout",
    originalValue: el?.pcb_layout_id || undefined,
    defaultValue: undefined,
    disabled: disabled,

    validationOnChange: useFieldOptRequired(!disabled),
  });

  const num_items = useFormTextField({
    label: "Number of items",
    originalValue: el?.num_items,
    type: FieldValueType.NUMBER,
    defaultValue: 1,
    disabled: disabled,

    validationOnChange: useFieldOptRequired<number>(!disabled),
  });

  const requested_hours = useFormTextField({
    label: "Number of hours requested",
    originalValue: el?.requested_hours,
    type: FieldValueType.NUMBER,
    defaultValue: 1,
    disabled: disabled,

    validationOnChange: useFieldOptRequired<number>(!disabled),
  });

  const earliest_start_date = useFormDateField({
    label: "Earliest starting date",
    originalValue: el?.earliest_start_date,
    defaultValue: moment().format("YYYY-MM-DD"),
    disabled: !!el?.granted, // && !isAdmin,

    validationOnChange: useFieldOptRequired<string | undefined>(!disabled, !el?.granted ? DateInTheFuture : undefined),
  });

  const { value: earliest_start_date_value } = earliest_start_date;
  const DueDateValidation = useMemo(() => {
    return CombineCustomValidation<string | undefined>(
      InTheFuture,
      IsAfter(earliest_start_date_value, "Due date must be later than the starting date")
    );
  }, [earliest_start_date_value]);

  const due_date = useFormDateField({
    label: "Due date",
    originalValue: el?.due_date,
    defaultValue: moment().format("YYYY-MM-DD"),
    disabled: !!el?.granted,

    validationOnChange: useFieldOptRequired<string | undefined>(
      !disabled,
      !el?.granted ? DueDateValidation : undefined
    ),
  });
  /*
    const description_deadline = useFormTextareaField({
        label: "Deadline exceeded (reasons)",
        originalValue: el?.description_deadline,
        defaultValue: ""
    });
*/
  const server_path = useFormTextField({
    label: "Path of files on the server",
    originalValue: el?.server_path,
    type: FieldValueType.STRING,
    defaultValue: "",
  });
  /*
    const description_deliverables = useFormTextareaField({
        label: "Deliverables",
        originalValue: el?.description_deliverables,
        defaultValue: ""
    });
*/
  const instructions = useFormTextareaField({
    label: "Instructions",
    originalValue: el?.instructions,
    defaultValue: "",
    validationOnChange: useFieldOptRequired(true),
  });
  /*
  const description_changes = useFormTextareaField({
    label: "Comments",
    originalValue: el?.description_changes,
    defaultValue: "",
  });
*/
  const { priority, assignees } = useBTAdminFormFragment(el);

  const state = useFormSelectObj({
    label: "State",
    options: {
      pending: "Pending",
      progress: "In progress",
      blocked: "Blocked",
      stopped: "Stopped",
    },
    originalValue: el?.state,
    defaultValue: "normal",
  });

  /*
    const granted = useFormCheckboxField({
        label: "Granted",
        defaultValue: false,
        originalValue: !!el?.granted
    });*/
  const apiResult = useNestorAPI<{ response: { id: number } }>(
    !el ? NestorServerRoutes.task_create() : NestorServerRoutes.task_update(el.id),
    false,
    undefined,
    NestorPOST
  );

  const route = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    if (apiResult.result && apiResult.result.response.id && !el) {
      const redirectURL = url.resolve(route.url, apiResult.result.response.id + "");
      history.push(redirectURL);
    }
  }, [apiResult.result, route.url, history, el]);

  const form = useForm({
    fields: {
      name,
      project_id,
      project_workpackage_id,

      type_id,
      pcb_layout_id,
      num_items,

      requested_by_id,
      //  description_changes,
      instructions,
      //     description_deliverables,
      server_path,
      earliest_start_date,
      due_date,
      //    description_deadline,
      priority,
      requested_hours,
      state,
      assignees,
    },

    fieldVisibility: {
      pcb_layout_id: !!type_id.selectedItem?.has_pcbid || false,
      num_items: !!type_id.selectedItem?.has_count || false,
    },

    Template: FormTaskTemplate,
    fw: {
      el,
    },

    onSubmit: () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        task_type_id: type_id.value,
        project_id: project_id.value,
        project_workpackage_id: project_workpackage_id.value,
        pcb_layout_id: pcb_layout_id.value,
        requested_by_id: requested_by_id.value,
        //   description_changes: description_changes.value,
        instructions: instructions.value,
        //  description_deliverables: description_deliverables.value,
        server_path: server_path.value,
        //   description_deadline: description_deadline.value,
        due_date: due_date.value,
        earliest_start_date: earliest_start_date.value,
        requested_hours: requested_hours.value,
        num_items: num_items.value,
        state: state.value,
        priority: priority.value,
        _assignees: assignees.value,
      });
    },
  });

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

export const TaskAddEditCard = (props: { task?: NestorTask }) => {
  const form = useTaskAddEditForm(props.task);
  return (
    <>
      <IOState source={form.state} />
      {form.Content}
    </>
  );
};

type FieldNames =
  | "name"
  | "type_id"
  | "project_id"
  | "project_workpackage_id"
  | "pcb_layout_id"
  // | "description_changes"
  | "instructions"
  | "requested_by_id"
  //| "description_deliverables"
  | "server_path"
  // | "description_deadline"
  | "due_date"
  | "earliest_start_date"
  | "requested_hours"
  | "num_items"
  | "assignees"
  | "state"
  | "priority";

export const FormTaskTemplate: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  el?: NestorTask;
  submit: Function;
  visibility: Record<FieldNames, boolean>;
}> = (props) => {
  const isBT = useAmIBT();
  const isBTAdmin = useAmIBTAdmin();
  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <button type="button" className="btn btn-success w-100" onClick={(e) => props.submit()}>
            Save !
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-2">
          <CardComponent header="General information" additionalClasses="h-100">
            {props.fields.name.Content}
            {props.fields.requested_by_id.Content}
            {props.fields.project_id.Content}
            {props.fields.project_workpackage_id.Content}
            {props.fields.requested_hours.Content}
            <h6>Task details</h6>
            {props.fields.type_id.Content}

            {props.visibility.pcb_layout_id === false ? null : props.fields.pcb_layout_id.Content}
            {props.visibility.num_items === false ? null : props.fields.num_items.Content}
          </CardComponent>
        </div>

        <div className="col-md-6 mb-2">
          <CardComponent header="Requester" additionalClasses="h-100">
            {props.fields.earliest_start_date.Content}
            {props.fields.due_date.Content}
            {props.fields.server_path.Content}
            {props.fields.instructions.Content}
          </CardComponent>
        </div>
      </div>
      {!!props.el && (
        <div className="row">
          {isBTAdmin && (
            <div className="col-md-6 mb-2">
              <CardComponent header="BT Admin">
                {props.fields.priority.Content}
                {props.fields.assignees.Content}
              </CardComponent>
            </div>
          )}
          {isBT && (
            <div className="col-md-6 mb-2">
              <CardComponent header="BT Employee">
                {props.fields.state.Content}
                {/*props.fields.description_changes.Content*/}
              </CardComponent>
            </div>
          )}
        </div>
      )}
    </>
  );
};
