import { UnavailabilityAddEditModal } from "nestor/components/modals/AddEditUnavailability";
import { UnavailabilityEndModal } from "nestor/components/modals/EndUnavailability";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useUnavailabilityList } from "nestor/hooks/api/useUnavailabilityList";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceUnavailability, NestorDeviceUnavailabilityCause } from "nestor/types/inventory/unavailability";
import { CardComponent } from "@csem/shared-utils";
import { EditButton } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasEdition,
  HasModalMethod,
  HasPagination,
  HasRefresh,
} from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";

type T = NestorDeviceUnavailability;
type FWLst = { device: NestorDevice };

type FW = EditMethod<T, FWLst> & { end?: (el: T, fw: FWLst) => void }; // & RemoveMethod<T, FWLst>;

const TagRenderer = (props: { el: T; device: NestorDevice }) => {
  switch (props.el.cause) {
    case NestorDeviceUnavailabilityCause.CALIBRATION:
      return <div className="d-block badge bg-white text-info">calibration</div>;
    case NestorDeviceUnavailabilityCause.DAMAGED:
      return <div className="d-block badge bg-white text-danger">damaged</div>;
    case NestorDeviceUnavailabilityCause.REPARATION:
      return <div className="d-block badge bg-white text-warning">repair</div>;
    case NestorDeviceUnavailabilityCause.SERVICE:
      return <div className="d-block badge bg-white text-secondary">service</div>;
    case NestorDeviceUnavailabilityCause.OTHER:
      return <div className="d-block badge bg-white text-dark">other</div>;
  }

  return null;
};

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("why", "Reason", (e, fw) => <TagRenderer el={e} device={fw.device} />),
      DateColumn("start", "Since", "started", (e) => e.started),
      EmployeeColumn("started_by", "By", "start_employee_id", (e) => e.start_employee),

      SimpleColumn("comment", "Comment", (e) => e.comment),

      DateColumn("ended", "Until", "ended", (e) => e.ended),
      EmployeeColumn("ended_by", "By", "end_employee_id", (e) => e.end_employee),

      SimpleColumn("comment_end", "Comment (return)", (e) => e.comment_end),

      ButtonsColumn("actions", "Actions", [
        EditButton("Edit", (e) => !e.ended),
        {
          title: "End",
          visibility: (e) => {
            return e.ended ? false : true;
          },
          theme: "success",
          cb: (e, fw) => {
            fw.end?.(e, fw);
          },
        },
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {};
    },
  });
})();

const GenericCalibrationRangeListContainer = (function () {
  const C = HasEdition(
    HasAddition(
      HasRefresh(GenericListContent<FWLst, T>()),
      UnavailabilityAddEditModal,
      "Add a new unavailability",
      "plus",
      "primary"
    ),
    UnavailabilityAddEditModal
  );

  const D = HasStdTablePagination(C);

  return HasModalMethod(D, UnavailabilityEndModal, "end");
})();

export const DeviceUnavailabilityCard = (props: { device: NestorDevice }) => {
  return (
    <div className="m-2">
      <CardComponent header="Unavailabilities">
        <StandardTableFilterCtxProvider>
          <GenericCalibrationRangeListContainer
            //cardHeader="Gateway list"
            list={useUnavailabilityList(props.device.id)}
            fwProps={{ device: props.device }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
