import { useNestorAPI, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorEmployee } from "nestor/types/company/employee";
import { NestorOrder } from "nestor/types/purchasing/orders";
import assert from "assert";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { useForm } from "@csem/forms";
import { IOState } from "@csem/api";
import { useFieldSignees } from "../forms/bundles/Signees";
import { EmployeeBadgeComplete } from "../nestor/company/employees/EmployeeBadge";

export const ProposeSigneesModal = StandardFormAddEditModalWithFormFactory<NestorOrder, any, any>({
  title: () => "Automatic signee calculation",
  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    assert(el);

    const apiCall = useNestorAPI<{ left: NestorEmployee; right: NestorEmployee }>(
      NestorServerRoutes.order_suggest_signees(el.id),
      true
    );

    const { signee_left_id, signee_right_id } = useFieldSignees(
      apiCall.result ? apiCall.result.left.id : undefined,
      apiCall.result ? apiCall.result.right.id : undefined,
      false
    );

    const form = useForm({
      fields: {
        signee_left_id,
        signee_right_id,
      },

      fw: {},

      onSubmit: () => {
        apiResult.doQuery(undefined, {
          signee_left_id: signee_left_id.value,
          signee_right_id: signee_right_id.value,
        });
      },
    });

    const apiResult = useNestorAPIForms(NestorServerRoutes.order_update(el.id), false, undefined, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    const Content = (
      <>
        {!apiCall.result || !apiCall.state.success ? (
          <IOState source={apiCall.state} />
        ) : (
          <div className="row justify-content-center">
            <div className="col-4 ">
              <div className="vstack gap-2 ">
                <h5 className="text-center">Proposed signee left</h5>
                <EmployeeBadgeComplete employee={apiCall.result.left} />

                {signee_left_id.Content}
              </div>
            </div>

            <div className="col-4">
              <div className="vstack gap-2">
                <h5 className="text-center">Proposed signee right</h5>
                <EmployeeBadgeComplete employee={apiCall.result.right} />

                {signee_right_id.Content}
              </div>
            </div>
          </div>
        )}
      </>
    );

    return {
      Content: Content,
      out: apiResult.result,
      submit: () => {
        form.submit();
      },
      state: apiResult.state,
    };
  },
});
