import { useEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { locationValue } from "nestor/components/forms/fields/LocationField";
import { NestorUserContext } from "nestor/contexts/NestorUserContext";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useOrderArticleList } from "nestor/hooks/api/useOrderSingle";
import NestorServerRoutes from "nestor/server_routes";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorParcel, NestorParcelId } from "nestor/types/purchasing/parcel";
import { NestorContainerId } from "nestor/types/stock/container";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useFormDateField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { useForm } from "@csem/forms";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter, StandardTableFilter } from "@csem/lists";
import { IOState } from "@csem/api";
import { useAutoSearchTrigger } from "@csem/lists";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { StockEntryDevice } from "./StockEntryDevices";
import { StockEntrySku } from "./StockEntrySku";
import url from "url";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useFieldOptRequired } from "@csem/forms";

import { NestorRefreshContextProvider } from "nestor/contexts/NestorRefreshContext";
import { StockEntryPcbLayout } from "./StockEntryPcbLayout";
import { useFeedbackForm } from "../feedbacks/modals/FeedbackAddEdit";
import { DocumentListCard } from "../../../util/documents/Documents";
import { useFormCheckboxField } from "@csem/forms";

const StockEntryCreateCtxed = ({
  order,
  parcel_id,
}: // setStore,
{
  order: NestorOrder;
  parcel_id: NestorParcelId;
  // setStore: React.Dispatch<React.SetStateAction<EntryStore>>;
}) => {
  const apiArticles = useOrderArticleList(order.id);
  useAutoSearchTrigger(apiArticles);

  const [mode, setMode] = useState<"inventory" | "stock" | "pcb_layout">();

  return (
    <>
      {apiArticles.loadingIO}
      {apiArticles.list ? (
        <>
          {
            // We selecter stock entry
            mode === "stock" && (
              <StockEntrySku order={order} apiListArticles={apiArticles} parcel_id={parcel_id}></StockEntrySku>
            )
          }
          {
            // We selected inventory entry
            mode === "inventory" && (
              <StockEntryDevice order={order} apiListArticles={apiArticles} parcel_id={parcel_id}></StockEntryDevice>
            )
          }
          {
            // We selected PCB entry
            mode === "pcb_layout" && (
              <StockEntryPcbLayout
                order={order}
                apiListArticles={apiArticles}
                parcel_id={parcel_id}
              ></StockEntryPcbLayout>
            )
          }
          {
            // We didn't select anything
            mode === undefined && (
              <>
                <div className="hstack gap-4 mt-3 mb-5">
                  <div className="card w-100  flex-grow-1">
                    <div className="card-body text-center py-4" onClick={() => setMode("stock")}>
                      <button className="btn btn-link">
                        <h3>Stock items</h3>
                      </button>
                    </div>
                  </div>

                  <div className="vr"></div>
                  <div className="card w-100 flex-grow-1">
                    <div className="card-body text-center py-4 " onClick={() => setMode("inventory")}>
                      <button className="btn btn-link">
                        <h3>Inventory items</h3>
                      </button>
                    </div>
                  </div>
                  <div className="vr"></div>
                  <div className="card w-100 flex-grow-1">
                    <div className="card-body text-center py-4 " onClick={() => setMode("pcb_layout")}>
                      <button className="btn btn-link">
                        <h3>PCB Assembly</h3>
                      </button>
                    </div>
                  </div>
                </div>

                <DocumentListCard type="entry_inspection" id={parcel_id} />
              </>
            )
          }
        </>
      ) : null}
    </>
  );
};

const defaultFilter: StandardTableFilter = {
  ...cloneDeep(DefaultStandardTableFilter),
  take: 1000,
  sort: [{ field: "reference", dir: "asc" }],
};
export type EntryStore = {
  stock: Array<{
    container_id: NestorContainerId;
    article_id: number;
    qty_received: number;
    qa: "OK" | "NOK";
    comment: string;
  }>;
  devices: Array<{
    location: locationValue;
    article_id: number;
    key: number;

    serialnumber: string;
  }>;
};

export const StockEntryCreate = ({ order }: { order: NestorOrder }) => {
  const me = useContext(NestorUserContext);
  assert(me);

  const received_at = useFormDateField({
    label: "Arrival date of the components",
    defaultValue: moment().format("YYYY-MM-DD"),
    validationOnChange: useFieldOptRequired(true),
  });

  const intended_recipient_id = useEmployeeSearchField({
    label: "Intended recipient",
    originalValue: me.id,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const state = useFormSelectObj({
    label: "State of parcel",
    defaultValue: undefined,
    options: {
      "Good condition": "Good condition",
      Wet: "Wet",
      "Has been tilted": "Has been tilted",
      "Suffered physical damage": "Suffered physical damage",
      "Room temperature": "Temperature requirements not fullfilled",
    },
    validationOnChange: useFieldOptRequired(true),
  });

  const final_parcel = useFormCheckboxField({
    label: "This is the final parcel. I don't expect to receive anything else",
    defaultValue: false,
  });

  const apiCall = useNestorAPI<any>(NestorServerRoutes.package_create(order.id), false, undefined, NestorPOST);

  const apiCallGoodsReceived = useNestorAPI<any, boolean>(
    (received: boolean | undefined) => {
      if (received === undefined) {
        return false;
      }
      return NestorServerRoutes.order_goods_received(order.id, received);
    },
    false,
    undefined
  );
  const { state: apiState, result: apiResult } = apiCall;

  const route = useRouteMatch();
  const history = useHistory();

  // useFeedbackFields
  const feedbackForm = useFeedbackForm(undefined, order);

  useEffect(() => {
    if (apiState.success && apiResult && apiResult.response) {
      const redirectURL = url.resolve(route.url, apiResult.response.id + "/edit");
      history.push(redirectURL);
    }
  }, [route.url, apiState, apiResult, history]);

  const form = useForm({
    onSubmit: function () {
      apiCall.doQuery(undefined, {
        state: state.value,
        delivered_at: received_at.value,
        intended_recipient_id: intended_recipient_id.value,
      });

      if (final_parcel) {
        apiCallGoodsReceived.doQuery(true);
      }
    },

    fields: {
      state,
      received_at,
      intended_recipient_id,
    },
    fw: {},
  });

  return (
    <>
      <div className="vstack gap-2">
        <CardComponent header="Parcel information">
          <IOState source={apiCall.state} successMessage="Stock and inventory entry performed with success" />
          {received_at.Content}
          {intended_recipient_id.Content}
          {state.Content}

          <div className="row">
            <div className="col-auto"> {final_parcel.Content}</div>
            <div className="col-8">
              <div className="text-info csem-info">
                Since May 2022, as the initiator of the order, you are expected to inform Nestor that all your goods
                have been received. This can be achieved in the Order Detail page (by clicking on the "Goods Received"
                circle in the progress tracker) or right here by informing us that this is the only (or last) package
                that you expect. Thank you :)
              </div>
            </div>
          </div>
        </CardComponent>
        <CardComponent header="Evaluations on the supplier for this order">
          <div className="alert alert-info">
            <strong>What are the following fields for ?</strong>
            <br />
            This is the <strong>EP-610</strong> compliant supplier review scorecard. Please take the time to review your
            experience with the <strong>supplier</strong> for this order. Give stars to the various following categories
            that you are in position to judge, or zero stars otherwise.
          </div>

          {feedbackForm.Content}
          <div className="row">
            <div className="col-12 mb-2 d-flex ">
              <button
                type="button"
                className="btn btn-success btn-lg flex-grow-1"
                onClick={() => {
                  form.submit();
                  feedbackForm.submit();
                }}
              >
                Proceed with the reception of goods
              </button>
            </div>
          </div>
        </CardComponent>
      </div>
    </>
  );
};

export const StockEntryEdit = (props: { order: NestorOrder; parcel_id: NestorParcelId }) => {
  //const [store, setStore] = useState<EntryStore>({ stock: [], devices: [] });
  return (
    <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
      <NestorRefreshContextProvider>
        <StockEntryCreateCtxed order={props.order} parcel_id={props.parcel_id} />
      </NestorRefreshContextProvider>
    </StandardTableFilterCtxProvider>
  );
};
