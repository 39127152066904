import { DeviceMaintenanceAddEditModal } from "nestor/components/nestor/inventory/devices/maintenances/modals/AddEditDeviceMaintenance";
import { useMaintenanceListDevice } from "nestor/hooks/api/useMaintenanceList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceMaintenance } from "nestor/types/inventory/device_maintenance";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { ButtonsColumn, HtmlColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import NestorServerRoutes from "nestor/server_routes";
import { NestorMaintenance } from "nestor/types/inventory/maintenance";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";

type T = NestorDeviceMaintenance & {
  maintenance?: NestorMaintenance & {
    device_maintenances?: Array<NestorDeviceMaintenance>;
  };
};
type FWLst = { device: NestorDevice };
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      DateColumn("next", "Next maintenance", undefined, (e, fw) => {
        const ms = e.maintenance?.device_maintenances;
        if (!ms) return null;

        ms.sort((a, b) => {
          return a.created_at < b.created_at ? -1 : 1;
        });

        const lastMaintenance = ms[0];
        if( lastMaintenance != null ) {
          const date = new Date(lastMaintenance.created_at);
          date.setDate(date.getDate() + e.maintenance!.periodicity_days);
          return date.toISOString();
        }
        return ""
      }),
      SimpleColumn("name", "Name", (e, fw) => e.maintenance?.name),
      HtmlColumn("proc", "Procedure", (e) => e.maintenance?.description || ""),
      SimpleColumn("period", "Periodicity", (e) => e.maintenance?.periodicity_days + " days"),
      HtmlColumn("description", "Description", (e) => e.maintenance?.description || ""),
      SimpleColumn("comment", "Comment", (e) => e.comment),

      ButtonsColumn("actions", "Actions", [EditButton(), RemoveButton()]), // Add delegation and confirm buttons
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {};
    },
  });
})();

const Container = (function () {
  return HasStdTablePagination(
    HasDeletion(
      HasEdition(
        HasAddition(
          HasRefresh(GenericListContent<FWLst, T>()),
          DeviceMaintenanceAddEditModal,
          "Assign a new maintenance",
          "plus",
          "primary"
        ),
        DeviceMaintenanceAddEditModal
      ),

      "Confirm maintenance revocation",
      (v: T) => `Are you sure to revoke the maintenance "${v.maintenance?.name}" ?`,
      (props) => {
        return useNestorAPI<undefined, NestorDeviceMaintenance>(
          (el) => {
            assert(el !== undefined);
            return NestorServerRoutes.maintenance_dissociate(props.fwProps.device.id, el.maintenance_id);
          },
          false,
          undefined,
          { method: "DELETE" },
          props.list.refresh
        );
      }
    )
  );
})();

export const DeviceMaintenancesCard = (props: { device: NestorDevice }) => {
  return (
    <div className="my-2">
      <CardComponent header="Applicable maintenances">
        <StandardTableFilterCtxProvider>
          <Container
            //cardHeader="Gateway list"
            list={useMaintenanceListDevice(props.device.id)}
            fwProps={{ device: props.device }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
