import { useTaskTypeList } from "nestor/hooks/api/useTaskTypeList";
import { NestorTaskType } from "nestor/types/manufacturing/task_type";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";

type T = NestorTaskType;

export const TaskTypeRenderer = ({ task_type }: { task_type: NestorTaskType }) => {
  return <>{task_type.name}</>;
};

export const useTaskTypeSearchField = FormSearchHookFactory<T, any>({
  apiListFactory: useTaskTypeList,

  template: GenericListRenderer((e) => <TaskTypeRenderer task_type={e} />),
  filterKeys: {
    name: "contains",
  },
  traverser_display: (el) => {
    return el.name;
  },
  uniqueKeyField: "id",
});
