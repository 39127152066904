import { NestorLaboratory } from "nestor/types/company/laboratory";

export const LaboratoryRenderer = ({
  laboratory,
  fallbackText,
}: {
  laboratory?: NestorLaboratory | null;
  fallbackText?: string;
}) => {
  if (!laboratory) {
    return <div className="text-muted">{fallbackText || null}</div>;
  }
  return (
    <>
      <div className="fw-bold">
        <em className="bi bi-radioactive"></em> {laboratory.name}
      </div>
      {laboratory.room ? (
        <>
          <div className="text-muted">
            <em className="bi bi-door-open"></em> {laboratory.room.displayed_name}{" "}
          </div>
          {laboratory.room.floor?.building ? (
            <div className="text-muted">
              <div className="text-muted">
                <em className="bi bi-building"></em> {laboratory.room.floor.building.name} - Fl{" "}
                {laboratory.room.floor.floor}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};
