import { NestorOrderTransferingType } from "nestor/types/purchasing/orders";
import { useFormSelectObj } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

export const useTransferringHandlerField = (
  handler: NestorOrderTransferingType | undefined,
  required: boolean = false
) => {
  return useFormSelectObj({
    label: "Who transfers the order ?",
    defaultValue: undefined,
    originalValue: handler,

    options: {
      secretary: "The secretary",
      handler: "The handler (whoever initiated the order)",
      internal: "The order does not need sending to the supplier",
    },
    validationOnChange: useFieldOptRequired(required),
  });
};
