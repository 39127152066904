import { NestorDevice, NestorDeviceId } from "nestor/types/inventory/device";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

export const useDeviceSingle = (elId: number | string): NestorApiElement<NestorDevice, undefined> => {
  const apiCall = useNestorAPI<NestorDevice>(`inventory/devices/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    state: apiCall.state,
    loadingIO: <IOState success={null} loadingMessage="Loading device..." source={apiCall.state} />,
  };
};

type T = NestorOrder & { devices: Array<NestorDevice & { pivot: { comment: string; tag: string } }> };
export const useDeviceOrderList = (deviceId: NestorDeviceId): apiList<T> => {
  return useNestorListApiCall<T>(`list/inventory/devices/${deviceId}/orders`, "device");
};
