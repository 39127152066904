import { SupplierAccountsCard } from "nestor/components/nestor/purchasing/suppliers/Accounts";
import { SupplierListCard } from "nestor/components/nestor/purchasing/suppliers/List";

export function SupplierAccountList() {
  return (
    <div className="container-fluid p-3">
      <SupplierAccountsCard />
    </div>
  );
}
