import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { NestorManufacturerId } from "nestor/types/purchasing/manufacturer";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import "@csem/forms/dist/index.css";
import { useFormQuillField, useFormTextareaField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useDeviceCatTypeFields } from "../../../../forms/bundles/DeviceCategoryType";
import { useManufacturerSearchField } from "../../../../forms/fields/ManufacturerSearch";
import { useFormCheckboxField } from "@csem/forms";
import { useEffect } from "react";
import NestorServerRoutes from "nestor/server_routes";
/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */

export const useFieldsDeviceModel = (
  el: NestorDeviceModel | undefined,
  _manufacturer_id: NestorManufacturerId | undefined
) => {
  const { category_id, device_type_id } = useDeviceCatTypeFields(el?.type);
  const manufacturer_id = useManufacturerSearchField({
    label: "Manufacturer",
    originalValue: el?.device_manufacturer_id || _manufacturer_id,
    defaultValue: undefined,
    validationOnChange: { required: true },
  });

  const model = useFormTextField({
    label: "Model",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.model,
    validationOnChange: { required: true },
  });

  const characteristics = useFormQuillField({
    label: "Characteristic",
    defaultValue: "",
    originalValue: el?.characteristic,
  });

  const description = useFormTextareaField({
    label: "Description",
    defaultValue: "",
    originalValue: el?.description,
    // validationOnChange: { maxLength: 200 },
  });

  const is_system = useFormCheckboxField({
    label: "System",
    helpText: "If you don't what this is, don't check it",
    defaultValue: false,
    originalValue: !!el?.is_system,
  });

  return {
    category_id,
    device_type_id,
    model,
    manufacturer_id,
    characteristics,
    description,
    is_system,
  };
};

export const DeviceModelModal = StandardFormAddEditModalFactory<
  NestorDeviceModel,
  { manufacturer_id?: NestorManufacturerId } & any,
  any
>({
  title: (el) => (!el ? "Add a new device model" : `Edit the device model ${el.model}`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const { category_id, device_type_id, manufacturer_id, model, characteristics, description, is_system } =
      useFieldsDeviceModel(el, fw.manufacturer_id);

    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.inventory.device_models.forms.create()
        : NestorServerRoutes.inventory.device_models.forms.update(el.id),
      false,
      undefined,
      NestorPOST
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // * Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        category_id: category_id.value,
        device_type_id: device_type_id.value,
        device_manufacturer_id: manufacturer_id.value,
        model: model.value,
        description: description.value,
        characteristic: characteristics.value,
        is_system: is_system.value ? 1 : 0,
      });
    };

    return {
      fields: {
        manufacturer_id,
        model,
        description,
        category_id,
        device_type_id,
        characteristics,
        is_system,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
/*
    return (
        <Tpl
            show={true}
            el={props.el}
            onValidated={props.callback}
            handleClose={() => {}}
        />
    );
};
*/
