import React from "react";
import { LinkProps } from "react-router-dom";

export const ButtonLink: LinkProps<unknown>["component"] = React.forwardRef<
  HTMLAnchorElement | null,
  { navigate: () => void; text: string; children: React.ReactNode }
>(({ navigate, ...props }, ref) => {
  return (
    <a ref={ref} {...props} onClick={navigate}>
      {props.children}
    </a>
  );
});
