import { NestorTask, NestorTaskStatus } from "nestor/types/manufacturing/task";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorTask;
export const useTaskList = (mode: NestorTaskStatus, includeProgress: boolean = false): apiList<T> => {
  return useNestorListApiCall<T>(
    `manufacturing/tasks/list/${mode}`,
    "task",
    new URLSearchParams([["progress", includeProgress ? "1" : "0"]])
  );
};
