import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useMaintenanceActionListDevice } from "nestor/hooks/api/useMaintenanceList";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceMaintenanceAction } from "nestor/types/inventory/device_maintenance_actions";
import { CardComponent } from "@csem/shared-utils";
import { EditButton, HtmlColumn } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { EditMethod, GenericListContent, HasAddition, HasEdition, HasPagination, HasRefresh } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { DeviceMaintenanceActionAddEditModal } from "./modals/AddEditDeviceMaintenanceAction";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { DefaultStandardTableFilter } from "@csem/lists";

type T = NestorDeviceMaintenanceAction;
type FWLst = { device: NestorDevice };
type FW = EditMethod<T, FWLst>;

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("name", "Name", (e, fw) => e.maintenance?.name),
      HtmlColumn("range", "Range", (e) => e.maintenance?.description || ""),
      SimpleColumn("period", "Periodicity", (e) => e.maintenance?.periodicity_days + " days"),
      SimpleColumn("comment", "Comment", (e) => e.comment),
      DateColumn("date", "Performed on the", "updated_at", (e) => e.updated_at),
      EmployeeColumn("by", "By", "employee_id", (e) => e.employee),
      ButtonsColumn("actions", "Actions", [EditButton()]), // Add delegation and confirm buttons
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {};
    },
  });
})();

const Container = (function () {
  return HasStdTablePagination(
    HasEdition(
      HasAddition(
        HasRefresh(GenericListContent<FWLst, T>()),
        DeviceMaintenanceActionAddEditModal,
        "New maintenance action",
        "plus",
        "primary"
      ),
      DeviceMaintenanceActionAddEditModal
    )
  );
})();

export const DeviceMaintenancesActionCard = (props: { device: NestorDevice }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "updated_at",
      },
    ];

    return defaultFilter;
  }, []);

  return (
    <div className="my-2">
      <CardComponent header="Maintenance actions">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useMaintenanceActionListDevice(props.device.id)}
            fwProps={{ device: props.device }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
