import NestorServerRoutes from "nestor/server_routes";
import { NestorBackoffice } from "nestor/types/company/backoffice";
import { NestorSupplier, NestorSupplierBackofficePivot, NestorSupplierId } from "nestor/types/purchasing/supplier";
import { NestorSupplierAccount } from "nestor/types/purchasing/supplier_account";
import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

export const useSupplierSingle = (elId: number | string): NestorApiElement<NestorSupplier, undefined> => {
  const apiCall = useNestorAPI<NestorSupplier>(`purchasing/suppliers/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading order..." source={apiCall.state} success={null} />,
  };
};

export const useSupplierAccounts = (id: NestorSupplierId) => {
  return useNestorListApiCall<NestorSupplierAccount>(NestorServerRoutes.supplier_accounts(id), "accounts");
};

export const useAllAccounts = () => {
  return useNestorListApiCall<NestorSupplierAccount>(NestorServerRoutes.all_accounts(), "accounts");
};
