import { useFormSelectObj } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

const useMaintenancePeriodicityField = function (value: number | undefined, required: boolean = false) {
  return useFormSelectObj({
    label: "Periodicity",
    originalValue: value,
    options: {
      31: "Every month",
      62: "Every 2 months",
      124: "4x per year",
      182: "2x per year",
      365: "Every year",
      730: "Every other year",
      1460: "Every 4 years",
      1825: "Every 5 years",
    },
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(required),
  });
};

export default useMaintenancePeriodicityField;
