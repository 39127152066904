import { LocationColumn } from "nestor/components/tables/columns/LocationColumn";
import { useLoanList } from "nestor/hooks/api/useLoanList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDevice, NestorDeviceId } from "nestor/types/inventory/device";
import { NestorLoan } from "nestor/types/inventory/loan";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
// Contexts
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasDeletion, HasRefresh, RemoveMethod } from "@csem/lists";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useMemo } from "react";
//# Tables
import { DateColumn } from "../../../../tables/columns/DateColumn";
// Classes
// Components
import { EmployeeColumn } from "../../../../tables/columns/EmployeeColumn";
import { AddEditLoanModal } from "./modals/AddEditLoan";
import NestorServerRoutes from "nestor/server_routes";

type T = NestorLoan;

type FWLst = { device: NestorDevice };
type FW = RemoveMethod<T, FWLst>;

type HOOK = {};

const LoanTagRenderer = ({ loan }: { loan: NestorLoan }) => {
  const future = moment(loan.borrowed_at).isAfter(moment());

  return (
    <>
      {future ? (
        loan.returned_at != null ? (
          <div className="d-block badge bg-dark text-white">cancelled</div>
        ) : (
          <div className="d-block badge bg-primary text-white">future booking</div>
        )
      ) : loan.returned_at != null ? (
        <div className="d-block badge bg-info">returned</div>
      ) : (
        <div className="d-block badge bg-warning">active</div>
      )}
    </>
  );
};

const GenericLoanTable = (function () {
  return TableRenderer<T, FWLst, FW, HOOK, "id">({
    key: "id",
    columns: [
      DateColumn("borrowed_at", "Borrowing date", "borrowed_at", (e) => e.borrowed_at),

      DateColumn("expected_return_at", "Expected return date", "expected_return_at", (e) => e.expected_return_at),

      DateColumn("effective_return_at", "Effective return date", "expected_return_at", (e) => e.returned_at),
      EmployeeColumn<T>("borrower", "Borrower", "employee_id", (e) => e.employee),

      SimpleColumn("comment", "Comment", (e, fw, hk) => e.comment),

      SimpleColumn("status", "Loan status", (e, fw, hk) => <LoanTagRenderer loan={e} />),
      LocationColumn("location", "Location", (e) => e.location),

      ButtonsColumn("actions", "", [
        {
          title: "Return / Cancel",
          theme: "success",
          visibility: (e, fw) => {
            return !e.returned_at;
          },
          cb: (e, fw) => {
            fw.remove(e, fw);
          },
        },
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        borrower: "120px",
        borrowed_at: "130px",
        expected_return_at: "130px",
        effective_return_at: "130px",
        status: "120px",
        actions: "145px",
      };
    },
  });
})();

const GenericLoanListContainer = (function () {
  return HasDeletion(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
      AddEditLoanModal,
      "Add a new loan / booking",
      "plus",
      "primary"
    ),
    "Confirm return / revocation",
    (v: T) => `Are you sure to return the object or revoke this loan ?`,
    (props) => {
      return useNestorAPI<undefined, NestorLoan>(
        (loan) => {
          assert(loan !== undefined);
          return NestorServerRoutes.inventory.devices.loans.forms.return(loan.device_id, loan.id);
        },
        false,
        undefined,
        { method: "POST" },

        props.list.refresh
      );
    }
  );
})();

export const DeviceLoanListCard = ({ device }: { device: NestorDevice }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "borrowed_at",
      },
    ];

    return defaultFilter;
  }, []);

  return (
    <div className="mt-2">
      <CardComponent header="Loan list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericLoanListContainer
            //cardHeader="Gateway list"
            list={useLoanList(device.id)}
            fwProps={useMemo(() => {
              return { device: device };
            }, [device])}
            listTemplate={GenericLoanTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
