import { RemoveButton } from "@csem/lists";
import { ButtonsColumn, SimpleColumn } from "@csem/lists";

export const DeviceOrderLinkColumns = function <
  T extends {
    [K in R]: Array<{
      pivot: { comment: string; tag: string }; //  "service" | "repair" | "calibration" | "order" | "maintenance"
    }>;
  },
  R extends string
>(relationName: R) {
  const out = [
    SimpleColumn("tag", "Tag", (e: T) => {
      switch (e[relationName][0]?.pivot.tag) {
        case "service":
          return <div className="w-100 badge bg-info">service</div>;
        case "repair":
          return <div className="w-100 badge bg-warning">repair</div>;
        case "calibration":
          return <div className="w-100 badge bg-primary">calibration</div>;
        case "order":
          return <div className="w-100 badge bg-success">order</div>;
        case "maintenance":
          return <div className="w-100 badge bg-dark">maintenance</div>;
      }
      return "unknown";
    }),

    SimpleColumn("comment", "Comment", (e: T) => e[relationName][0]?.pivot.comment),

    ButtonsColumn("actions", "", [RemoveButton<T, { remove: (el: T, fw: any) => void }>("Revoke link")]),
  ];

  return out;
};
