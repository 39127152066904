import { NestorPOST, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useAddressFields } from "../../../../forms/bundles/AddressFields";
import { useContactFields } from "../../../../forms/bundles/ContactFields";
import { useCurrencyField } from "../../../../forms/fields/CurrencyList";

export const SupplierQuickAddEditModal = StandardFormAddEditModalFactory<NestorSupplier, {}, any>({
  title: (el) => (el ? `Edit the supplier ${el.name}` : `Add a new supplier (short form)`),
  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const { address, address2, postal_code, city, country } = useAddressFields(el, true, true, true, true);

    const url = useFormTextField({
      label: "Web page",
      originalValue: el?.url || "",
      type: FieldValueType.STRING,
      defaultValue: "",
    });

    const { email, phone } = useContactFields(el, false, false);

    const currency_id = useCurrencyField({
      label: "Currency",
      originalValue: el?.currency_id,
      defaultValue: "",
    });

    const apiResult = useNestorAPIForms(
      !el ? `purchasing/suppliers/forms/create` : `purchasing/suppliers/${el.id}/forms/update`,
      false,
      undefined,
      NestorPOST
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        address: address.value,
        address2: address2.value,
        postal_code: postal_code.value,
        url: url.value,
        city: city.value,
        country: country.value,
        phone: phone.value,
        email: email.value,
        currency_id: currency_id.value,
      });
    };

    return {
      fields: {
        name,
        address,
        address2,
        postal_code,
        city,
        country,
        phone,
        email,
        url,
        currency_id,
      },
      errors: apiResult.error,
      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
