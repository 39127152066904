import { ProjectListCard } from "nestor/components/nestor/company/projects/List";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ProjectDetails } from "./ProjectDetails";

export function ProjectsMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List all projects", url + "/list");

  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...list}>
          <ProjectListCard></ProjectListCard>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/:project`} key="new">
        <ProjectDetails></ProjectDetails>
      </Route>
      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
