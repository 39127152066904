import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorRoom } from "nestor/types/company/room";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useBuildingFloorFields } from "../forms/bundles/BuildingFloorFields";
/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */
export const AddEditRoomModal = StandardFormAddEditModalFactory<NestorRoom, any, any>({
  title: (el) => (!el ? "Add a new room" : `Edit the room`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const { building_id, floor_id } = useBuildingFloorFields(el);

    const name = useFormTextField({
      label: "Imported Name (Oracle)",
      originalValue: el?.name,
      defaultValue: "",
      type: FieldValueType.STRING,
      disabled: true,
    });

    const displayed_name = useFormTextField({
      label: "Name",
      originalValue: el?.displayed_name,
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const alternate_name = useFormTextField({
      label: "Alternate name",
      originalValue: el?.alternate_name,
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: useFieldOptRequired<string>(false),
    });

    const apiResult = useNestorAPI(
      !el ? `company/rooms/forms/create` : `company/rooms/forms/${el.id}/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    const cb = () => {
      apiResult.doQuery(undefined, {
        floor_id: floor_id.value,
        //name: name.value,
        displayed_name: displayed_name.value,
        alternate_name: alternate_name.value,
      });
    };

    return {
      fields: {
        building_id,
        floor_id,
        name,
        displayed_name,
        alternate_name,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
/*
    return (
        <Tpl
            show={true}
            el={props.el}
            onValidated={props.callback}
            handleClose={() => {}}
        />
    );
};
*/
