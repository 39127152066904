import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorManufacturerId } from "nestor/types/purchasing/manufacturer";
import { NestorPart } from "nestor/types/stock/part";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormCheckboxField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useManufacturerSearchField } from "../forms/fields/ManufacturerSearch";
import { usePartCategorySelectField } from "../forms/fields/PartCategory";
import { useSkuUnitsField } from "../forms/fields/SKUUnits";

export const usePartFields = (el: NestorPart | undefined, chunkRequired: boolean, chunkDisabled: boolean = false) => {
  const category_id = usePartCategorySelectField({
    label: "Category",
    originalValue: el?.category_id,
    defaultValue: undefined,
    disabled: chunkDisabled,
    validationOnChange: useFieldOptRequired(chunkRequired),
  });

  const description = useFormTextField({
    label: "Description",
    type: FieldValueType.STRING,
    defaultValue: "",
    disabled: chunkDisabled,
    originalValue: el?.description,
  });

  const extended_description = useFormTextField({
    label: "Description (extended)",
    type: FieldValueType.STRING,
    defaultValue: "",
    disabled: chunkDisabled,
    originalValue: el?.extendedDescription,
  });

  const datasheet = useFormTextField({
    label: "URL of the datasheet",
    defaultValue: "",
    type: FieldValueType.STRING,
    disabled: chunkDisabled,
    originalValue: el?.URLdatasheet,
  });

  const dividable = useFormCheckboxField({
    label: "Dividable quantity",
    defaultValue: false,
    disabled: chunkDisabled,
    originalValue: !!el?.dividable,
  });

  const dividable_unit = useSkuUnitsField("Dividable quantity", chunkDisabled || !dividable.value, el?.dividable_unit);

  const dividable_qty = useFormTextField({
    label: "Dividable quantity",
    type: FieldValueType.NUMBER,
    disabled: chunkDisabled || !dividable.value,
    defaultValue: 0,
    originalValue: el?.dividable_amount || 0,
  });

  return {
    category_id,
    description,
    extended_description,
    datasheet,
    dividable,
    dividable_unit,
    dividable_qty,
  };
};

export const PartModal = StandardFormAddEditModalFactory<NestorPart, { manufacturer_id?: NestorManufacturerId }, any>({
  title: (el) => (!el ? "Add a new part" : `Edit the part`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const manufacturer_id = useManufacturerSearchField({
      label: "Manufacturer",
      originalValue: el?.manufacturer_id || fw.manufacturer_id || undefined,
      defaultValue: undefined,
      validationOnChange: { required: true },
    });

    const manufacturer_identification = useFormTextField({
      label: "Manufacturer ref. number",
      type: FieldValueType.STRING,
      originalValue: el?.manufacturer_identification,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const { category_id, description, extended_description, datasheet, dividable, dividable_unit, dividable_qty } =
      usePartFields(el, true);

    const apiResult = useNestorAPI(
      !el ? `stock/parts/forms/create` : `stock/parts/${el.id}/forms/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        category_id: category_id.value,
        manufacturer_identification: manufacturer_identification.value,
        extended_description: extended_description.value,
        dividable_unit: dividable_unit.value,
        dividable_amount: dividable_qty.value,
        dividable: dividable.value,
        URLdatasheet: datasheet.value,
        manufacturer_id: manufacturer_id.value,
        description: description.value,
      });
    };

    return {
      fields: {
        manufacturer_id,
        manufacturer_identification,
        category_id,
        description,
        extended_description,
        datasheet,
        dividable,
        dividable_unit,
        dividable_qty,
      },
      out: apiResult.result,
      state: apiResult.state,
      submit: cb,
    };
  },
});
