import { ConversationTableCard } from "nestor/components/nestor/util/ConversationTable";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { usePcbSingle } from "nestor/hooks/api/usePcbSingle";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { PCBInfo } from "./PCBInfo";
import { PCBAttributeListCard } from "nestor/components/nestor/manufacturing/pcb/pcbs/AttributeList";

const PCBDetailInner = (props: { pcb: NestorPcb; refresh: Function }) => {
  const { url } = useRouteMatch<{ layout: string }>();

  useMenuElement("Information", url + "/details");
  useMenuElement("Documents", url + "/documents");
  useMenuElement("Attribute journal", url + "/journal");
  //   useMenuElement("Conversation", url + "/conversation");
  // useMenuElement("Actions", url + "/actions");

  return (
    <Switch>
      <Route path={`${url}/details`}>
        <div className="vstack gap-2">
          <PCBInfo refresh={props.refresh} pcb={props.pcb} />
          <PCBAttributeListCard
            head="Transitional changes"
            pcb={props.pcb}
            attributeName="change"
          ></PCBAttributeListCard>
          <ConversationTableCard type="pcb" id={props.pcb.id}></ConversationTableCard>
        </div>
      </Route>{" "}
      <Route path={`${url}/documents`}>
        <DocumentListCard type="pcb" id={props.pcb.id}></DocumentListCard>
      </Route>{" "}
      <Route path={`${url}/journal`}>
        <PCBAttributeListCard pcb={props.pcb}></PCBAttributeListCard>
      </Route>
      <Route>
        <Redirect to={`${url}/details`} />
      </Route>
    </Switch>
  );
};

export const PCBDetail: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ pcb: string }>();
  const pcb = usePcbSingle(route.params.pcb);
  const menu = useMenuElement(pcb.element ? `PCB: ${pcb.element?.nuid}` : "Loading...", route.url);

  return (
    <XemwayMenuProvider {...menu}>
      {pcb.loadingIO}
      <div className="container-fluid p-3">
        {pcb.element ? <PCBDetailInner pcb={pcb.element} refresh={pcb.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
