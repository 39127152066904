import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";

type T = NestorDeviceModel;

export const DeviceModelRenderer = ({ model }: { model: T }) => {
  return (
    <>
      <strong>{model.model}</strong>
      <br />
      <span className="text-muted">{model.characteristic}</span>
    </>
  );
};

export const useDeviceModelSearchField = FormSearchHookFactory<T, NestorDeviceModel["id"]>({
  template: GenericListRenderer((e) => <DeviceModelRenderer model={e} />),
  filterKeys: {
    model: "contains",
    device_manufacturer_id: "contains",
  },
  traverser_display: (el) => {
    return el.model + (el.description ? " (" + el.description + ")" : "");
  },
  uniqueKeyField: "id",
});
