import { NestorManufacturer } from "nestor/types/purchasing/manufacturer";

export const DeviceModelRenderer = ({
  manufacturer,
  model,
}: {
  manufacturer?: NestorManufacturer;
  model?: string | number;
}) => {
  if (!manufacturer) {
    return null;
  }

  return (
    <>
      <div className="fw-bold">{model}</div>
      <div className="text-muted">{manufacturer.name}</div>
    </>
  );
};
