import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceModelId } from "nestor/types/inventory/device_model";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import {
  NestorDeviceModelParameter,
  NestorDeviceModelParameterType,
} from "nestor/types/inventory/device_model_parameter";
import { useFieldOptRequired } from "@csem/forms";
import { useFormCheckboxField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import NestorServerRoutes from "nestor/server_routes";

export const useFieldsDeviceModelParameter = (el: NestorDeviceModelParameter | undefined) => {
  const name = useFormTextField({
    label: "Name",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.name,
    validationOnChange: useFieldOptRequired<string>(true),
  });

  const show_in_header = useFormCheckboxField({
    label: "Show in table",
    defaultValue: !!el?.show_in_header,
  });

  const type = useFormSelectObj({
    label: "Reference type",
    defaultValue: "string",
    originalValue: el?.type,
    options: {
      [NestorDeviceModelParameterType.PCB]: "PCB",
      [NestorDeviceModelParameterType.SKU]: "SKU",
      [NestorDeviceModelParameterType.INVENTORY]: "Inventorized item",
      [NestorDeviceModelParameterType.STRING]: "Text",
      [NestorDeviceModelParameterType.CHECKBOX]: "Checkbox",
      [NestorDeviceModelParameterType.DATE]: "Date",
    },
  });

  const group = useFormTextField({
    label: "Group",
    defaultValue: "",
    type: FieldValueType.STRING,
    originalValue: el?.group,
    validationOnChange: { maxLength: 200 },
  });

  const priority = useFormTextField({
    label: "Priority in group",
    defaultValue: 0,
    type: FieldValueType.NUMBER,
    originalValue: el?.priority,
  });

  return {
    name,
    show_in_header,
    type,
    group,
    priority,
  };
};

export const DeviceModelParameterModal = StandardFormAddEditModalFactory<
  NestorDeviceModelParameter,
  { device_model_id: NestorDeviceModelId } & any,
  any
>({
  title: (el) => (!el ? "Add a new device model parameter" : `Edit the device model ${el.name}`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const { name, show_in_header, type, priority, group } = useFieldsDeviceModelParameter(el);

    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.device_model_parameter_create(fw.device_model_id)
        : NestorServerRoutes.device_model_parameter_edit(el.device_model_id, el.id),

      false,
      undefined,
      NestorPOST
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // * Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        show_in_header: show_in_header.value,
        type: type.value === "null" ? null : type.value,
        priority: priority.value,
        group: group.value,
      });
    };

    return {
      fields: {
        name,
        show_in_header,
        type,
        group,
        priority,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
