import NestorServerRoutes from "nestor/server_routes";
import { NestorBackoffice } from "nestor/types/company/backoffice";
import { IOState } from "@csem/api";
import { useMemo } from "react";
import { apiList } from "@csem/api";
import { useNestorAPI } from "./useNestorAPI";

export const ShimArrayToApiList = function <T>(apiRoute: string, error: string): () => apiList<T> {
  function useMyHook() {
    const apiCall = useNestorAPI<T[]>(NestorServerRoutes.backoffice_list(), true);
    const { state, doQuery, setSearchParams } = apiCall;
    return {
      refresh: doQuery,
      setSearchParams: setSearchParams,
      list: apiCall.result,
      pagination: undefined,
      state: state,
      loadingIO: useMemo(() => {
        return (
          <IOState
            source={state}
            success={null}
            loading={null}
            successMessage={undefined}
            loadingMessage={undefined}
            errorMessage={`Error while loading the backoffice list`}
          />
        );
      }, [state]),
    };
  }

  return useMyHook;
};
type T = NestorBackoffice;
// TODO extract logic
export const useBackofficeList = ShimArrayToApiList<T>(
  NestorServerRoutes.backoffice_list(),
  "Error while loading the backoffice list"
);
