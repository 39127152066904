import { useScannedDocumentsList } from "nestor/hooks/api/useScannedDocuments";
import { NestorScan, NestorScanId } from "nestor/types/util/scan";
import { FormLabel } from "@csem/forms";
import { useFormFileField } from "@csem/forms";
import { useFieldLogic } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";
import type { Field } from "@csem/forms";
import { DivRenderer } from "@csem/lists";
import { TableFilterCtxProvider } from "@csem/lists";
import { GenericListContent, HasPagination, HasRefresh } from "@csem/lists";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { StandardTableFilterContext } from "@csem/lists";

const ScanList = HasRefresh(
  HasPagination(
    GenericListContent<{ selectScan: (el: NestorScan) => void }, NestorScan>(),
    undefined,
    StandardTableFilterContext
  )
);

const ScanElTemplate = (props: { el: NestorScan; selectScan: (el: NestorScan) => void }) => {
  return (
    <div className="border border-1 my-2 p-2 col-4 scan cursor-pointer" onClick={() => props.selectScan(props.el)}>
      <div className="d-flex">
        <img
          style={{ width: "100%", height: "200px", objectFit: "contain" }}
          src={props.el.blob?.previews[0]?.img}
          alt="Scan"
        />
        <div className="d-flex flex-column">
          <div className="mb-1">
            <div className="text-muted">Date:</div>
            <div className="fw-bold">{moment(props.el.created_at).format("YYYY-MM-DD HH:mm")}</div>
          </div>
          {props.el.employee ? (
            <div className="mb-1">
              <div className="text-muted">By</div>
              <div className="fw-bold">{props.el.employee ? props.el.employee.fullname : "N/A"}</div>
            </div>
          ) : null}

          <div className="mb-1">
            <div className="text-muted">Workstation</div>
            <div className="fw-bold">{props.el.workstation}</div>
          </div>
          {props.el.job ? (
            <div className="mb-1">
              <div className="text-muted">Job Id</div> <div className="fw-bold">{props.el.job}</div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const ScanTemplate = DivRenderer<NestorScan, { selectScan: (el: NestorScan) => void }, NestorScan["id"]>(
  ScanElTemplate,
  "id"
);

const ScanModalInner = (props: { show: boolean; handleClose: () => void; selectScan: (el: NestorScan) => void }) => {
  const api = useScannedDocumentsList();
  return (
    <>
      <div className="row">
        <ScanList list={api} fwProps={{ selectScan: props.selectScan }} listTemplate={ScanTemplate} />
      </div>
    </>
  );
};

const ScanModal = (props: { show: boolean; handleClose: () => void; selectScan: (el: NestorScan) => void }) => {
  return (
    <>
      <Modal size="xl" show={props.show} onHide={props.handleClose}>
        <Modal.Header>
          <Modal.Title>Scanned documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableFilterCtxProvider>
            <ScanModalInner {...props} />
          </TableFilterCtxProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => props.handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type T = NestorScanId | undefined | true;
export const useScanField = function (props: CommonFieldUI<T>): Field<T> {
  const { label, disabled, ...fieldLogic } = props;
  const { val, error, triggerOnDemandValidation, updateValue, setValidationError, clearValue } = useFieldLogic<T>(
    fieldLogic,
    props
  );

  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => {
    setShow(false);
  };

  const selectScan = (el: NestorScan) => {
    setShow(false);
    updateValue(el.id);
  };

  const Content = (
    <>
      <FormLabel label={label} error={error} />
      {show ? <ScanModal show={show} handleClose={handleClose} selectScan={selectScan} /> : null}

      <div className={props.noMargin === true ? "" : "mb-2"}>
        {val !== undefined ? (
          <>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => {
                setShow(true);
              }}
            >
              <em className="bi bi-document"></em> Select another scan
            </button>
            <small className="text-success">
              &nbsp;<em className="bi bi-check"></em> document already exists
            </small>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-info"
            onClick={() => {
              setShow(true);
            }}
          >
            <em className="bi bi-document"></em> View scans
          </button>
        )}
      </div>
    </>
  );

  return {
    validate: triggerOnDemandValidation,
    Content,
    value: val,
    error: error !== undefined,
    setValidationError,
    updateValue,
    clearValue,
  };
};
export const useScanFileField = (el: any) => {
  const scan = useScanField({
    label: "Scans",
    originalValue: !!el || undefined,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const file = useFormFileField({
    label: "File",
    originalValue: !!el || undefined,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const updateScanValue = scan.updateValue;
  const updateFileValue = file.updateValue;
  useEffect(() => {
    updateScanValue((v) => {
      if (v === undefined) {
        return !!file.value || undefined;
      }
      return v;
    });
  }, [file.value, updateScanValue]);

  useEffect(() => {
    updateFileValue((v) => {
      if (v === undefined) {
        return !!scan.value || undefined;
      }
      return v;
    });
  }, [scan.value, updateFileValue]);

  return { scan, file };
};
