import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import useSettings, { DerivedUseSettings } from "nestor/hooks/api/useSettings";
import { useMyId } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorEmployee } from "nestor/types/company/employee";
import { CardComponent } from "@csem/shared-utils";
import { useFormCheckboxField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { IOState } from "@csem/api";
import { apiState } from "@csem/api";
import { useCallback, useEffect, useState } from "react";

type R = DerivedUseSettings<"employee", NestorEmployee, { value: string }>;

type setV = (v: string) => void;
type setKV = (k: number, v: string) => void;

type EditParams = { setting: R; setV: setV };
const EditSettingAsString = ({ setting, setV }: EditParams) => {
  const input = useFormTextField({
    label: setting.label,
    originalValue: setting.employee?.[0]?.pivot.value,
    defaultValue: setting.default,
    type: FieldValueType.STRING,
  });

  useEffect(() => {
    setV(input.value);
  }, [setV, input.value]);

  return input.Content;
};

const EditSettingAsBoolean = ({ setting, setV }: EditParams) => {
  const input = useFormCheckboxField({
    label: setting.label,
    originalValue: setting.employee?.[0] ? setting.employee?.[0].pivot.value === "1" : undefined,
    defaultValue: !!setting.default,
  });

  useEffect(() => {
    setV(input.value ? "1" : "0");
  }, [setV, input.value]);

  return input.Content;
};

const EditSettingAsNumber = ({ setting, setV }: EditParams) => {
  const input = useFormTextField({
    label: setting.label,
    originalValue: setting.employee?.[0] ? parseFloat(setting.employee?.[0].pivot.value) : undefined,
    defaultValue: parseFloat(setting.default),
    type: FieldValueType.NUMBER,
  });

  useEffect(() => {
    setV(String(input.value));
  }, [setV, input.value]);

  return input.Content;
};

const EditSetting = ({ setting, setV }: { setting: R; setV: setKV }) => {
  const _setV = useCallback(
    (v: string) => {
      setV(setting.id, v);
    },
    [setV, setting]
  );

  if (setting.type === "string") {
    return <EditSettingAsString setting={setting} setV={_setV} />;
  } else if (setting.type === "boolean") {
    return <EditSettingAsBoolean setting={setting} setV={_setV} />;
  } else if (setting.type === "number") {
    return <EditSettingAsNumber setting={setting} setV={_setV} />;
  }

  throw new Error("Should be string, boolean or number");
};

const useIOState = () => {
  const [state, setState] = useState<apiState>({ loading: false, success: false, error: false });

  const start = () => {
    setState({ loading: true, success: false, error: false });
  };

  const success = () => {
    setState({ loading: false, success: true, error: false });
  };

  const fail = () => {
    setState({ loading: false, success: false, error: true });
  };

  return { state, start, fail, success };
};

const SettingsCard = function () {
  const settings = useSettings<"employee", NestorEmployee, { value: string }>("employee", useMyId());

  const [newSettings, setNewSettings] = useState<Record<number, string>>({});
  const setKV = useCallback((k: number, v: string) => {
    setNewSettings((s) => {
      return {
        ...s,
        [k]: v,
      };
    });
  }, []);

  const apiSave = useNestorAPI(NestorServerRoutes.settings("employee", useMyId()), undefined, undefined, NestorPOST);
  //const saveState = useIOState();

  const _doSave = apiSave.doQuery;
  const doSave = useCallback(async () => {
    //saveState.start();
    try {
      for (let i in newSettings) {
        await _doSave(undefined, {
          id: i,
          value: newSettings[i],
        });
      }

      //  saveState.success();
    } catch (e) {
      //   saveState.fail();
    }
  }, [newSettings, _doSave]);

  return (
    <CardComponent header="Settings">
      <IOState source={settings.state} success={null}></IOState>
      <IOState source={apiSave.state} loadingMessage="Saving..." successMessage="Saved !" />
      <button className="btn btn-primary mb-4" onClick={() => doSave()}>
        Save
      </button>

      {settings.result &&
        Object.entries(settings.result).map(([k, v]) => {
          if (v.length > 0) {
            return (
              <>
                <h5>{v[0].setting_group!.label}</h5>
                <ul className="list-group">
                  {v.map((r) => {
                    return (
                      <li className="list-group-item ">
                        <EditSetting setting={r} setV={setKV} />
                      </li>
                    );
                  })}
                </ul>
              </>
            );
          }
          return null;
        })}
    </CardComponent>
  );
};

export default SettingsCard;
