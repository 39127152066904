import { NestorDeviceType } from "nestor/types/inventory/device_type";
import { useDeviceCategorySelectField } from "../fields/DeviceCategory";
import { useDeviceTypeSelectField } from "../fields/DeviceType";

export const useDeviceCatTypeFields = (type?: NestorDeviceType) => {
  const category_id = useDeviceCategorySelectField({
    label: "Category",
    originalValue: type?.device_category_id,
    defaultValue: undefined,
  });

  const device_type_id = useDeviceTypeSelectField({
    label: "Device type",
    categoryId: category_id.value,
    originalValue: type?.id,
    defaultValue: undefined,
  });

  return {
    category_id,
    device_type_id,
  };
};
