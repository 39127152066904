import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AddressesMain } from "./addresses/Addresses";
import { EmployeesMain } from "./employees/Employees";
import { LaboratoriesMain } from "./laboratories/Laboratories";
import { ProjectsMain } from "./projects/Projects";
import { RoomsMain } from "./rooms/Rooms";

export function CompanyMain() {
  const { url } = useRouteMatch();
  const employees = useMenuElement("Employees", url + "/employees");
  const laboratories = useMenuElement("Laboratories", url + "/laboratories");
  const rooms = useMenuElement("Rooms", url + "/rooms");
  const addresses = useMenuElement("Addresses", url + "/addresses");
  const projects = useMenuElement("Projects", url + "/projects");

  return (
    <Switch>
      <Route path={`${url}/employees`} key="employees">
        <XemwayMenuProvider {...employees}>
          <EmployeesMain></EmployeesMain>
        </XemwayMenuProvider>
      </Route>{" "}
      <Route path={`${url}/laboratories`} key="laboratories">
        <XemwayMenuProvider {...laboratories}>
          <LaboratoriesMain></LaboratoriesMain>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/rooms`} key="rooms">
        <XemwayMenuProvider {...rooms}>
          <RoomsMain></RoomsMain>
        </XemwayMenuProvider>
      </Route>{" "}
      <Route path={`${url}/addresses`} key="addressed">
        <XemwayMenuProvider {...addresses}>
          <AddressesMain></AddressesMain>
        </XemwayMenuProvider>
      </Route>{" "}
      <Route path={`${url}/projects`} key="projects">
        <XemwayMenuProvider {...projects}>
          <ProjectsMain></ProjectsMain>
        </XemwayMenuProvider>
      </Route>
      <Route>
        <Redirect to={`${url}/employees`} />
      </Route>
    </Switch>
  );
}
