import { usePcbComponentList } from "nestor/hooks/api/usePcbAssembly";
import { NestorPcbAssembly, NestorPcbComponent } from "nestor/types/manufacturing/pcb_assembly";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import moment from "moment";
import { useCallback, useRef } from "react";
import { LabelPrinterButton } from "../../util/LabelPrinter";

const pad = (value: number) => {
  if (value < 10) {
    return "0" + String(value);
  } else {
    return value;
  }
};

const findCustomTray = (component: NestorPcbComponent, trays: { [x: string]: number }) => {
  const key = `${component.comment.replaceAll(";", "").replaceAll("/", "")}`;
  if (key in trays) {
    return String(trays[key]);
  }

  trays[key] = Math.max(0, ...Object.values(trays)) + 1;
  return String(trays[key]);
};

const SemiAutoPlacementFile = ({
  componentListAPI,
  assembly,
  layer,
}: {
  componentListAPI: ReturnType<typeof usePcbComponentList>;
  assembly: NestorPcbAssembly;
  layer: "top" | "bottom";
}) => {
  const rotations = {
    "0": 0,
    "90": 1,
    "180": 2,
    "270": 3,
    "360": 0,
  };

  const pre = useRef<HTMLPreElement | null>(null);

  const download = useCallback(() => {
    if (!pre.current) {
      return;
    }
    let blob = new Blob([pre.current.innerText], { type: "text/plain" });
    let url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  }, [pre]);

  if (!componentListAPI.list) {
    return null;
  }

  const trays: { [x: string]: number } = {};
  const excludes = ["nor", "noc", "tp", "et"];
  const components_semiauto = componentListAPI.list
    .filter((e) => e.mode === "semi") // Looks for components in the semiauto mode
    .filter((e) => e.enabled)
    .filter((e) => e.layer === layer) // Only select the correct layer
    .filter((e) => {
      // Remove all unplaceable components
      return excludes.reduce(
        (prev, current) =>
          prev && !e.designator.toLowerCase().startsWith(current) && !e.comment.toLowerCase().startsWith(current),
        true
      );
    });

  const maxX = Math.max(...components_semiauto.map((e) => e.mid_x));
  const minX = Math.min(...components_semiauto.map((e) => e.mid_x));
  const ref_pad_x =
    layer === "top" ? assembly.component_reference_top?.mid_x : assembly.component_reference_bottom?.mid_x;
  const ref_pad_y =
    layer === "top" ? assembly.component_reference_top?.mid_y : assembly.component_reference_bottom?.mid_y;

  return (
    <>
      <button className="btn btn-primary" onClick={() => download()}>
        Download PnP file
      </button>

      <div className="mt-3">
        <h5>PnP file</h5>

        <pre ref={pre} className="mt-2">
          @@ VERSION 7.00
          <br />
          @@ BEGIN
          <br />
          @@ END
          <br />
          {moment().format("d/m/Y")}
          <br />
          ;<br />
          ;<br />
          ;<br />
          ;<br />
          ;<br />
          ;<br />
          1,2,0,0,0,0,0,0,0,0,0,0,0,0,0,0
          <br />
          1,1,0,0,{ref_pad_x},{ref_pad_y},{assembly.size_x},{assembly.size_x},0,0,0,0,0,0,0,0
          <br />
          0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
          <br />
          0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
          <br />
          0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0
          <br />
          0,0
          <br />
          0,0
          <br />
          0,0
          <br />
          {components_semiauto.length}
          <br />
          {components_semiauto.map((comp) => {
            let tray: string = "";
            if (comp.feeder) {
              tray = "1" + pad(comp.feeder?.feeder_x) + pad(comp.feeder.feeder_y);
            } else {
              tray = findCustomTray(comp, trays);
            }
            return (
              <>
                {comp.footprint},{comp.comment},{tray},{(layer === "bottom" ? maxX - comp.mid_x : comp.mid_x) * 1000},
                {comp.mid_y * 1000},{rotations[comp.rotation.toFixed(0) as keyof typeof rotations]},{comp.designator}
                ,0,0,0
                <br />
              </>
            );
          })}
          ******************************************************
          <br />
          @@ MultiBoards
          <br />
          0,0
          <br />
          1<br />
          0,0,0,0,0, ,0,0,0,0,0,0
          <br />
        </pre>
      </div>

      <div className="vstack gap-2 mt-3">
        <h5>Custom labels:</h5>
        {Object.entries(trays).map(([k, v]) => {
          return (
            <LabelPrinterButton
              text={v + ". " + k}
              url={`company/labelPrinters/label/${v};${k}/pdf/print`}
              size={LabelPrinterSizes.SZ_9}
            />
          );
        })}
        {Object.keys(trays).length === 0 ? <span className="fw-bold">No custom labels to print</span> : null}
      </div>
    </>
  );
};

export default SemiAutoPlacementFile;
