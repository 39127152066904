import { DeviceEditCreateForm } from "nestor/components/nestor/inventory/devices/AddEdit";
import { DeviceCalibrations } from "nestor/components/nestor/inventory/devices/calibrations/CalibrationsMain";
import { DeviceInfos, DeviceOneLine } from "nestor/components/nestor/inventory/devices/Info";
import { DeviceLoanListCard } from "nestor/components/nestor/inventory/devices/loans/DeviceLoans";
import { DeviceMaintenancesActionCard } from "nestor/components/nestor/inventory/devices/maintenances/MaintenanceActions";
import { DeviceMaintenancesCard } from "nestor/components/nestor/inventory/devices/maintenances/List";
import { OrderDeviceListCard } from "nestor/components/nestor/inventory/devices/OrderList";
import { DeviceUnavailabilityCard } from "nestor/components/nestor/inventory/devices/unavailabilities/List";
import { DeviceAssociationList } from "nestor/components/nestor/inventory/devices/AssociationList";
import { ConversationCard } from "nestor/components/nestor/util/Conversation";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { NestorPermissionContextProvider, useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useDeviceSingle } from "nestor/hooks/api/useDeviceSingle";
import { useLatch } from "nestor/hooks/latch";
import { NestorDevice } from "nestor/types/inventory/device";
import assert from "assert";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { CalibrationDetails } from "./CalibrationDetails";
import { CardComponent } from "@csem/shared-utils";
import DeviceSystemComponentsCard from "nestor/components/nestor/inventory/devices/SystemComponents";
import { DeviceModelEditForm } from "nestor/components/nestor/inventory/device_models/Edit";
import { useEffect } from "react";

const DeviceDetailsInner: React.FunctionComponent<{
  device: NestorDevice;
  refresh: Function;
}> = (props) => {
  const can_edit = useHasPermission("edit");

  const route = useRouteMatch<{ device: string }>();
  useMenuElement("Details", route.url + "/details");
  useMenuElement("Edit information", route.url + "/edit", can_edit);
  useMenuElement(
    "Calibrations",
    route.url + "/calibrations",
    !!props.device.is_reference || !!props.device.is_calibrated
  );
  useMenuElement("Maintenances", route.url + "/maintenances");
  useMenuElement("Unavailabilities", route.url + "/unavailabilities");
  useMenuElement("Documents", route.url + "/documents");
  const conversations = useMenuElement("Conversation", route.url + "/conversation");
  useMenuElement("Loans", route.url + "/loans", !props.device.is_fixed);
  useMenuElement("Associated items", route.url + "/associations");
  useMenuElement("System components", route.url + "/system_components");

  useMenuElement("Order references", route.url + "/orders");

  return (
    <Switch>
      <Route path={`${route.url}/details`}>
        <DeviceInfos device={props.device} refresh={props.refresh}></DeviceInfos>
      </Route>
      <Route path={`${route.url}/edit`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <DeviceEditCreateForm device={props.device}></DeviceEditCreateForm>
        <hr />
        <NestorPermissionContextProvider ressource="device_model" id={props.device.model_id}>
          <div className="alert alert-secondary">
            Looking to edit this <strong>device's model</strong> instead ? Check out the form below. Be aware that it
            will affect all devices that are of the same model
          </div>
          <DeviceModelEditForm device_model={props.device.model}></DeviceModelEditForm>
        </NestorPermissionContextProvider>
      </Route>
      <Route path={`${route.url}/documents`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <CardComponent header="">
          <div className="text-info csem-info">
            Looking for calibration documents ? They shouldn't be here anymore. Documents are "per-calibration" and do
            not apply globally to the device itself. Therefore, go to the "Calibration" menu, select your appropriate
            calibration and go to the documents.
          </div>
        </CardComponent>
        <DocumentListCard type="equipment" id={props.device.id} title="Document list (device)"></DocumentListCard>
        <DocumentListCard
          type="device"
          id={props.device.model_id}
          title="Document list (device model)"
        ></DocumentListCard>
      </Route>
      <Route path={`${route.url}/calibrations/:calibration`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>

        <CalibrationDetails device={props.device}></CalibrationDetails>
      </Route>
      <Route path={`${route.url}/calibrations`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>

        <DeviceCalibrations device={props.device}></DeviceCalibrations>
      </Route>

      <Route path={`${route.url}/maintenances`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <DeviceMaintenancesCard device={props.device}></DeviceMaintenancesCard>
        <DeviceMaintenancesActionCard device={props.device}></DeviceMaintenancesActionCard>
      </Route>

      <Route path={`${route.url}/unavailabilities`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <DeviceUnavailabilityCard device={props.device}></DeviceUnavailabilityCard>
      </Route>

      <Route path={`${route.url}/conversation`} key="conversation">
        <XemwayMenuProvider {...conversations}>
          <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
          <ConversationCard type="equipment" id={props.device.id}></ConversationCard>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${route.url}/loans`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <DeviceLoanListCard device={props.device}></DeviceLoanListCard>
      </Route>

      <Route path={`${route.url}/associations`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <DeviceAssociationList device={props.device}></DeviceAssociationList>
      </Route>

      <Route path={`${route.url}/system_components`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <DeviceSystemComponentsCard device={props.device} refresh={props.refresh}></DeviceSystemComponentsCard>
      </Route>

      <Route path={`${route.url}/orders`}>
        <DeviceOneLine device={props.device} refresh={props.refresh}></DeviceOneLine>
        <OrderDeviceListCard device={props.device}></OrderDeviceListCard>
      </Route>
      <Route>
        <Redirect to={`${route.url}/details`} />
      </Route>
    </Switch>
  );
};

export const DeviceDetails: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ device: string }>();

  const device = useDeviceSingle(route.params.device);

  assert(device.state);
  // const _device = useLatch(device.element, device.state.error);

  const menu = useMenuElement(
    device.element ? "Device " + (device.element?.nuid || device.element?.NI) : "Loading...",
    `Device ${device.element?.nuid}`
  );

  return (
    <XemwayMenuProvider {...menu}>
      <NestorPermissionContextProvider ressource="equipment" id={route.params.device}>
        {device.loadingIO}
        <div className="container-fluid p-3">
          {device.element ? <DeviceDetailsInner device={device.element} refresh={device.refresh} /> : null}
        </div>
      </NestorPermissionContextProvider>
    </XemwayMenuProvider>
  );
};
