import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useDeviceSearchField } from "../forms/fields/DeviceSearch";
import { useOrderSearchField } from "../forms/fields/OrderSearch";
/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */
export const LinkDeviceOrderModal = StandardFormAddEditModalFactory<
  NestorOrder | NestorDevice,
  { order?: NestorOrder; device?: NestorDevice },
  any
>({
  title: () => "Link a device to an order",
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    let deviceValue, orderValue: number | undefined;
    if (fw.order) {
      orderValue = fw.order.id;
    } else if (fw.device) {
      deviceValue = fw.device.id;
    }

    const device = useDeviceSearchField({
      label: "Device",
      originalValue: deviceValue,
      disabled: !!deviceValue,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const order = useOrderSearchField({
      label: "Order",
      originalValue: orderValue,
      disabled: !!orderValue,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const tag = useFormSelectObj({
      label: "Tag",
      originalValue: undefined,
      defaultValue: undefined,
      options: {
        order: "Initial order",
        service: "Servicing",
        calibration: "Calibration",
        repair: "Repair",
        maintenance: "Maintenance",
      },
      validationOnChange: useFieldOptRequired(true),
    });

    const comment = useFormTextField({
      label: "Comment",
      originalValue: "",
      defaultValue: "",
      type: FieldValueType.STRING,
    });

    const apiResult = useNestorAPI(`inventory/devices/forms/linkOrderDevice`, false, undefined, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        device_id: device.value,
        order_id: order.value,
        tag: tag.value,
        comment: comment.value,
      });
    };

    return {
      fields: {
        device,
        order,
        tag,
        comment,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
/*
    return (
        <Tpl
            show={true}
            el={props.el}
            onValidated={props.callback}
            handleClose={() => {}}
        />
    );
};
*/
