import { useCalibrationRangeSearch } from '../../../../forms/fields/CalibrationRangeSearch';
import { useFormDateField } from '@csem/forms';
import { FieldValueType, useFormTextField } from '@csem/forms';
import { StandardFormAddEditModalFactory } from '@csem/shared-utils';
import moment from 'moment';
import { useCalibrationRangeList } from 'nestor/hooks/api/useCalibrationRangeList';
import { useNestorAPIForms } from 'nestor/hooks/api/useNestorAPI';
import { NestorCalibration } from 'nestor/types/inventory/calibration';
import { NestorDevice } from 'nestor/types/inventory/device';

export const CalibrationAddEditModal = StandardFormAddEditModalFactory<
  NestorCalibration,
  { device: NestorDevice },
  any
>({
  title: (el) => (el ? `Edit the calibration` : `Add a new calibration`),
  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const start = useFormDateField({
      label: "Starting date",
      originalValue: moment(el?.start).format("YYYY-MM-DD"),
      defaultValue: undefined,
      validationOnChange: {
        required: true,
      },
    });

    const end = useFormDateField({
      label: "Ending date",
      originalValue: moment(el?.end).format("YYYY-MM-DD"),
      defaultValue: undefined,
      validationOnChange: {
        required: true,
      },
    });

    const calibrator = useFormTextField({
      label: "Calibrator",
      originalValue: el?.calibrator,
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: {
        required: true,
      },
    });

    const comment = useFormTextField({
      label: "Comment",
      originalValue: el?.comment,
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: {
        required: true,
      },
    });
    const range_id = useCalibrationRangeSearch({
      apiListFactory: function useApiList() {
        return useCalibrationRangeList(fw.device.id);
      },

      label: "Calibration range",
      originalValue: el?.range_id,
      defaultValue: undefined,
      validationOnChange: {
        required: true,
      },
    });

    const apiResult = useNestorAPIForms(
      !el
        ? `inventory/devices/${fw.device.id}/forms/calibration/create`
        : `inventory/devices/${fw.device.id}/forms/calibration/${el.id}/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal

    const cb = () => {
      const content: any = {
        start: start.value,
        end: end.value,
        calibrator: calibrator.value,
        comment: comment.value,
        range_id: range_id.value,
      };
      if (fw.device) {
        content.device_id = fw.device.id;
      }
      if (el) {
        content.id = el.id;
      }

      apiResult.doQuery(undefined, content);
    };

    return {
      fields: {
        start,
        end,
        range_id,
        comment,
        calibrator,
      },
      errors: apiResult.error,
      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
