import { NestorManufacturerId } from "nestor/types/purchasing/manufacturer";
import { NestorPart } from "nestor/types/stock/part";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorPart;
export const usePartList = (manufacturer_id?: NestorManufacturerId | string): apiList<T> => {
  return useNestorListApiCall<T>(
    !manufacturer_id ? undefined : `list/company/manufacturers/${manufacturer_id}/parts`,
    "part"
  );
};
