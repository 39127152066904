import { NestorRoom } from "nestor/types/company/room";
import { FilterProps } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import React, { useState } from "react";
import { SearchByRoom } from "../../forms/fields/RoomSearch";
import { RoomRenderer } from "../../renderers/RoomRenderer";
import { SearchByBuilding } from "./LocationColumn";
/*
export const RoomColumn = <T,>(
  name: string,
  label: string,
  fields: string,
  renderer: (el: T) => NestorRoom | undefined | null
) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <RoomRenderer room={renderer(el) || undefined} />;
    }),

    SearchByRoom(),
    fields
  );
};*/

const SearchByRoomDom = SearchByRoom();

export const RoomColumn = <T,>(
  name: string,
  label: string,
  renderer: (el: T) => NestorRoom | undefined | null,
  room: boolean = true,
  building: boolean = true
) => {
  const wrapper = function Filter(props: FilterProps<string | number>) {
    const [type, setType] = useState<"room" | "building">("room");

    const RoomButton = room ? (
      <button
        className={`btn btn-${type === "room" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("room");
        }}
      >
        <em className="bi bi-door-open"></em> Room
      </button>
    ) : null;

    const BuildingButton = building ? (
      <button
        className={`btn btn-${type === "building" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("building");
        }}
      >
        <em className="bi bi-building"></em> Building
      </button>
    ) : null;

    return (
      <>
        <div className="btn-group">
          {RoomButton}
          {BuildingButton}
        </div>
        {type === "room" ? (
          <SearchByRoomDom
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "laboratory");
              props.onChange(undefined, "contains", "building");
              props.onChange(undefined, "contains", "container");
              props.onChange(v, o, "room");
            }}
          />
        ) : (
          <SearchByBuilding
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "room");
              props.onChange(undefined, "contains", "laboratory");
              props.onChange(undefined, "contains", "container");

              props.onChange(v, o, "building");
            }}
          />
        )}
      </>
    );
  };

  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <RoomRenderer room={renderer(el) || undefined} />;
    }),

    wrapper,
    {
      room: ["room_id"],
      building: ["room.floor.building_id"],
    }
  );
};
