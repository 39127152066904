import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { OrderFeedbackNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useOrderFeedbackList } from "nestor/hooks/api/useOrderFeedback";
import NestorServerRoutes from "nestor/server_routes";
import { NestorOrder, NestorOrderId } from "nestor/types/purchasing/orders";
import { NestorOrderFeedback } from "nestor/types/purchasing/order_feedback";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { OrderFeedbackAddEdit } from "./modals/FeedbackAddEdit";
import { NestorEmployeeId } from "nestor/types/company/employee";
import { useMe } from "nestor/hooks/me";

type T = NestorOrderFeedback;
type FWLst = { order: NestorOrder; me: NestorEmployeeId };
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      OrderFeedbackNavigationColumn,
      SimpleColumn("grade", "Grade", (e) => {
        return e.average_grade;
      }),
      SimpleColumn("grade", "Comment", (e) => {
        return e.comment;
      }),

      EmployeeColumn<T>("employee", "Initiator", "employee_id", (e) => e.employee),
      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("select", "Actions", [
        EditButton(undefined, (e, fw) => {
          return e.employee_id == fw.me;
        }),
        RemoveButton(undefined, (e, fw) => {
          return e.employee_id == fw.me;
        }),
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return { _nav: "0px", emply: "150px" };
    },
  });
})();

const ListContainer = (function () {
  return HasAddition(
    HasDeletion(
      HasRefresh(HasStdTablePagination(HasEdition(GenericListContent<FWLst, T>(), OrderFeedbackAddEdit))),

      "Confirm maintenance revocation",
      (v: T) => `Are you sure to revoke this evaluation ?`,
      (props) => {
        return useNestorAPI<undefined, T>(
          (el) => {
            assert(el !== undefined);
            return NestorServerRoutes.order_feedback_delete(el.id);
          },
          false,
          undefined,
          { method: "DELETE" },
          props.list.refresh
        );
      }
    ),
    OrderFeedbackAddEdit,
    "Add a new evaluation",
    "plus",
    "primary"
  );
})();

export const OrderFeedbackListCard = ({ order }: { order: NestorOrder }) => {
  const me = useMe();
  return (
    <div className="my-2">
      <CardComponent header={"Evaluation list"}>
        <StandardTableFilterCtxProvider>
          <ListContainer
            //cardHeader="Gateway list"
            list={useOrderFeedbackList(order.id)}
            fwProps={{ order: order, me: me.id }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
