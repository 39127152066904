import { NestorSkuId } from "../stock/sku";
import { NestorDeviceModel, NestorDeviceModelId } from "./device_model";

export type NestorDeviceModelParameterId = number;

export enum NestorDeviceModelParameterType {
  PCB = "pcb",
  SKU = "sku",
  INVENTORY = "equipment",
  STRING = "string",
  CHECKBOX = "checkbox",
  DATE = "date",
}

export type NestorDeviceModelParameterCore = {
  name: string;
  type: NestorDeviceModelParameterType;
  device_model_id: NestorDeviceModelId;
  show_in_header: 1 | 0;
  priority: number;
  group: string;
};

export type NestorDeviceModelParameter = {
  id: NestorDeviceModelParameterId;
} & NestorDeviceModelParameterCore & {
    device_model?: NestorDeviceModel;
  };
