import { useContactFields } from "nestor/components/forms/bundles/ContactFields";
import { useEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { useSupplierContactField } from "nestor/components/forms/fields/SupplierContactField";
import { useContactList } from "nestor/hooks/api/useContactList";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useMyId } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import {
  NestorSupplierQualification,
  NestorSupplierQualificationId,
} from "nestor/types/purchasing/supplier_qualification";
import { CardComponent, StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { useFormCheckboxField } from "@csem/forms";
import { useFormDateField } from "@csem/forms";
import { FieldValueType } from "@csem/forms";
import { useFormTextareaField } from "@csem/forms";
import { useFormTextField } from "@csem/forms";
import { useFormPasswordField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import { useHistory, useRouteMatch } from "react-router-dom";
import url from "url";

export const useSupplierQualificationAddEdit = (
  supplier: NestorSupplier,
  qualification?: NestorSupplierQualification
) => {
  const id = useMyId();
  const el = qualification;
  const contact_id = useSupplierContactField({
    apiListFactory: function useApiList() {
      return useContactList(supplier.id);
    },
    label: "Supplier contact",
    defaultValue: undefined,
    originalValue: el?.contact_id || undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const employee_id = useEmployeeSearchField({
    label: "Who did this qualification ?",
    originalValue: el?.employee_id || id,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const approver_id = useEmployeeSearchField({
    label: "Who is approving this qualification ?",
    originalValue: el?.approver_id || id,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const expires_at = useFormDateField({
    label: "Valid until",
    originalValue: el?.expires_at,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const reevaluate_at = useFormDateField({
    label: "Reevaluate on the",
    originalValue: el?.reevaluate_at,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const scope = useFormTextareaField({
    label: "Scope / Products supplied to CSEM",
    defaultValue: "",
    originalValue: el?.scope,
    validationOnChange: useFieldOptRequired(true),
  });

  const iso_13485 = useFormCheckboxField({
    label: "QMS ISO 13485",
    defaultValue: false,
    originalValue: !!el?.iso_13485,
  });

  const iso_13485_date = useFormDateField({
    label: "Valid until",
    originalValue: el?.iso_13485_date,
    defaultValue: undefined,
    disabled: !iso_13485.value,

    validationOnChange: useFieldOptRequired(iso_13485.value),
  });

  const iso_9001 = useFormCheckboxField({
    label: "QMS ISO 9001",
    defaultValue: false,

    originalValue: !!el?.iso_9001,
  });

  const iso_9001_date = useFormDateField({
    label: "Valid until",
    originalValue: el?.iso_9001_date,
    defaultValue: undefined,
    disabled: !iso_9001.value,
    validationOnChange: useFieldOptRequired(iso_9001.value),
  });

  const qms_certifications = useFormTextField({
    label: "Other QMS Certification",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.qms_certifications,
  });

  const ce_marking = useFormCheckboxField({
    label: "Products are CE marked",
    defaultValue: false,
    originalValue: !!el?.ce_marking,
  });

  const ce_marking_comment = useFormTextField({
    label: "Comment",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.ce_marking_comment,
  });

  const product_certificate = useFormCheckboxField({
    label: "Components delivered with certificate",
    defaultValue: false,
    originalValue: !!el?.product_certificate,
  });

  const product_certificate_type = useFormTextField({
    label: "Comment",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.product_certificate_type,
  });

  const other_qualification = useFormCheckboxField({
    label: "Other",
    defaultValue: false,
    originalValue: !!el?.other_qualification,
  });

  const other_qualification_type = useFormTextField({
    label: "Comment",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.other_qualification_type,
  });

  const remote_evaluation = useFormCheckboxField({
    label: "Remote evaluation",
    defaultValue: false,
    originalValue: !!el?.remote_evaluation,
  });

  const remote_evaluation_documents = useFormTextField({
    label: "Documents evaluated",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.remote_evaluation_documents,
  });

  const on_site = useFormCheckboxField({
    label: "On site qualification",
    defaultValue: false,
    originalValue: !!el?.on_site,
  });

  const on_site_date = useFormDateField({
    label: "Date of audit",
    originalValue: el?.on_site_date,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(on_site.value),
  });

  const supplier_agreement = useFormCheckboxField({
    label: "Supplier agreement",
    defaultValue: false,
    originalValue: !!el?.supplier_agreement,
  });

  const supplier_agreement_comment = useFormTextField({
    label: "Comment",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.supplier_agreement_comment,
  });

  const quality_agreement = useFormCheckboxField({
    label: "Quality agreement",
    defaultValue: false,
    originalValue: !!el?.quality_agreement,
  });

  const quality_agreement_comment = useFormTextField({
    label: "Comment",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.quality_agreement_comment,
  });

  const specific_agreement = useFormCheckboxField({
    label: "Specific agreement",
    defaultValue: false,
    originalValue: !!el?.specific_agreement,
  });

  const specific_agreement_type = useFormTextField({
    label: "Comment",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.specific_agreement_type,
  });

  const other_evaluation = useFormCheckboxField({
    label: "Other evaluation",
    defaultValue: false,
    originalValue: !!el?.other_evaluation,
  });

  const other_evaluation_type = useFormTextField({
    label: "Comment",
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue: el?.other_evaluation_type,
    validationOnChange: useFieldOptRequired<string>(other_evaluation.value),
  });

  const passed = useFormCheckboxField({
    label: "Qualification passed",
    defaultValue: false,
    originalValue: !!el?.passed,
  });

  const apiResult = useNestorAPI(
    el
      ? NestorServerRoutes.supplier_qualification_update(supplier.id, el.id)
      : NestorServerRoutes.supplier_qualification_create(supplier.id),
    false,
    undefined,
    NestorPOST
  );
  const route = useRouteMatch();
  const history = useHistory();
  const redirectURL = url.resolve(route.url, "./");

  const form = useForm({
    onSubmit: () => {
      apiResult
        .doQuery(undefined, {
          contact_id: contact_id.value,
          employee_id: employee_id.value,
          approver_id: approver_id.value,
          reevaluate_at: reevaluate_at.value,
          expires_at: expires_at.value,

          scope: scope.value,
          iso_13485: iso_13485.value,
          iso_13485_date: iso_13485_date.value,
          iso_9001: iso_9001.value,
          iso_9001_date: iso_9001_date.value,
          qms_certifications: qms_certifications.value,
          ce_marking: ce_marking.value,
          ce_marking_comment: ce_marking_comment.value,
          product_certificate: product_certificate.value,
          product_certificate_type: product_certificate_type.value,
          other_qualification: other_qualification.value,
          other_qualification_type: other_qualification_type.value,
          remote_evaluation: remote_evaluation.value,
          remote_evaluation_documents: remote_evaluation_documents.value,
          on_site: on_site.value,
          on_site_date: on_site_date.value,
          supplier_agreement: supplier_agreement.value,
          supplier_agreement_comment: supplier_agreement_comment.value,
          quality_agreement: quality_agreement.value,
          quality_agreement_comment: quality_agreement_comment.value,
          specific_agreement: specific_agreement.value,
          specific_agreement_type: specific_agreement_type.value,
          other_evaluation: other_evaluation.value,
          other_evaluation_type: other_evaluation_type.value,
          passed: passed.value ? 1 : 0,
        })
        .then(() => {
          if (!el) {
            history.push(redirectURL);
          }
        });
    },
    fields: {
      contact_id,
      scope,
      employee_id,
      approver_id,
      reevaluate_at,
      expires_at,
      iso_13485,
      iso_13485_date,
      iso_9001,
      iso_9001_date,
      qms_certifications,
      ce_marking,
      ce_marking_comment,
      product_certificate,
      product_certificate_type,
      other_qualification,
      other_qualification_type,
      remote_evaluation,
      remote_evaluation_documents,
      on_site,
      on_site_date,
      supplier_agreement,
      supplier_agreement_comment,
      quality_agreement,
      quality_agreement_comment,
      specific_agreement,
      specific_agreement_type,
      other_evaluation,
      other_evaluation_type,
      passed,
    },
    Template: FormQualification,
    fw: {},
  });

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

type FieldNames =
  | "contact_id"
  | "expires_at"
  | "reevaluate_at"
  | "employee_id"
  | "approver_id"
  | "scope"
  | "iso_13485"
  | "iso_13485_date"
  | "iso_9001"
  | "iso_9001_date"
  | "qms_certifications"
  | "ce_marking"
  | "ce_marking_comment"
  | "product_certificate"
  | "product_certificate_type"
  | "other_qualification"
  | "other_qualification_type"
  | "remote_evaluation"
  | "remote_evaluation_documents"
  | "on_site"
  | "on_site_date"
  | "supplier_agreement"
  | "supplier_agreement_comment"
  | "quality_agreement"
  | "quality_agreement_comment"
  | "specific_agreement"
  | "specific_agreement_type"
  | "other_evaluation"
  | "other_evaluation_type"
  | "passed";
export const FormQualification: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  submit: Function;
}> = (props) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th colSpan={2}>General information</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.fields.expires_at.Content}</td>
            <td>{props.fields.reevaluate_at.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.contact_id.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.employee_id.Content}</td>
            <td>{props.fields.approver_id.Content}</td>
          </tr>

          <tr>
            <td colSpan={2}>{props.fields.scope.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.passed.Content}</td>
          </tr>
        </tbody>

        <thead>
          <tr>
            <th colSpan={2}>Supplier existing qualifications / services</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.fields.iso_13485.Content}</td>
            <td>{props.fields.iso_13485_date.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.iso_9001.Content}</td>
            <td>{props.fields.iso_9001_date.Content}</td>
          </tr>
          <tr>
            <td colSpan={2}>{props.fields.qms_certifications.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.ce_marking.Content}</td>
            <td>{props.fields.ce_marking_comment.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.product_certificate.Content}</td>
            <td>{props.fields.product_certificate_type.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.other_qualification.Content}</td>
            <td>{props.fields.other_qualification_type.Content}</td>
          </tr>
          <tr>
            <td className="border-0" colSpan={2}></td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={2}>Specific requirements / evaluation process</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{props.fields.remote_evaluation.Content}</td>
            <td>{props.fields.remote_evaluation_documents.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.on_site.Content}</td>
            <td>{props.fields.on_site_date.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.supplier_agreement.Content}</td>
            <td>{props.fields.supplier_agreement_comment.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.quality_agreement.Content}</td>
            <td>{props.fields.quality_agreement_comment.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.specific_agreement.Content}</td>
            <td>{props.fields.specific_agreement_type.Content}</td>
          </tr>
          <tr>
            <td>{props.fields.other_evaluation.Content}</td>
            <td>{props.fields.other_evaluation_type.Content}</td>
          </tr>
          <tr>
            <td className="border-0" colSpan={2}></td>
          </tr>
        </tbody>
      </table>
      <div
        className="btn btn-success btn-lg w-100"
        onClick={() => {
          props.submit();
        }}
      >
        Save
      </div>
    </>
  );
};

export const SupplierQualificationAddEditForm: React.FunctionComponent<{
  supplier: NestorSupplier;
  qualification?: NestorSupplierQualification;
}> = (props) => {
  const form = useSupplierQualificationAddEdit(props.supplier, props.qualification);
  return (
    <>
      <IOState loadingMessage="Saving the qualification in progres..." source={form.state} />
      {form.Content}
    </>
  );
};

export const SupplierQualificationAddEditCard: React.FunctionComponent<{
  supplier: NestorSupplier;
  qualification?: NestorSupplierQualification;
}> = (props) => {
  return (
    <CardComponent header={props.qualification ? "Edit qualification" : "New qualification"}>
      <SupplierQualificationAddEditForm supplier={props.supplier} qualification={props.qualification} />
    </CardComponent>
  );
};
