// Hooks

// Classes

// Components

// Contexts
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { ProjectNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { useProjectList } from "nestor/hooks/api/useProject";
import { NestorProject } from "nestor/types/projects/projects";
import { CardComponent } from "@csem/shared-utils";
import { StandardTableFilterContext, StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
// ## Generic
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasPagination, HasRefresh, HasSearch } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorProject;
type FW = {};
type FWLst = FW;
const Table = (function () {
  return TableRenderer<T, FWLst, {}, {}, "id">({
    key: "id",
    columns: [
      ProjectNavigationColumn,
      SimpleColumn("code", "Code", (e) => e.code),
      SimpleColumn("name", "Name", (e) => e.name),
      DateColumn("started_at", "Starting date", "started_at", (e) => e.started_at),
      DateColumn("ended_at", "Ending date", "ended_at", (e) => e.ended_at),
      EmployeeColumn("manager", "Manager", "manager_id", (e) => e.manager),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const Container = HasSearch(
  HasRefresh(HasPagination(GenericListContent<FW, T>(), undefined, StandardTableFilterContext)),
  {
    name: "contains",
    code: "contains",
  },
  "or",
  "",
  TableSearch
);

export const ProjectListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    return defaultFilter;
  }, []);

  //  const isElevated = useAmIElevated();
  return (
    <div className="m-2">
      <CardComponent header="Project list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useProjectList()}
            fwProps={{}}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
