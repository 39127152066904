import { DeviceOrderListCard } from "nestor/components/nestor/purchasing/orders/DeviceList";
import { SkuOrderListCard } from "nestor/components/nestor/purchasing/orders/SKUList";
import { StockEntryEdit } from "nestor/components/nestor/purchasing/orders/stock_entry/StockEntryCreate";
import {
  OrderStockInventoryEntry,
  OrderStockInventoryEntryList,
} from "nestor/components/nestor/purchasing/orders/stock_entry/StockInventoryEntry";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

export function ParcelsMain(props: { order: NestorOrder }) {
  const { url } = useRouteMatch();

  const list = useMenuElement("Reception of goods", url + "/list");
  //useMenuElement("Receive new goods", url + "/create");

  useMenuElement("Resulting Stock SKUs", url + "/skus");
  useMenuElement("Resulting Inventory devices", url + "/devices");

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...list}>
          <OrderStockInventoryEntryList order={props.order}></OrderStockInventoryEntryList>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/:parcel/edit`} key="list">
        <ParcelEdit order={props.order}></ParcelEdit>
      </Route>

      <Route path={`${url}/create`} key="list">
        <XemwayMenuProvider {...list}>
          <OrderStockInventoryEntry order={props.order}></OrderStockInventoryEntry>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/skus`}>
        <SkuOrderListCard order={props.order}></SkuOrderListCard>
      </Route>
      <Route path={`${url}/devices`}>
        <DeviceOrderListCard order={props.order}></DeviceOrderListCard>
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}

export const ParcelEdit = (props: { order: NestorOrder }) => {
  const route = useRouteMatch<{ order: string; parcel: string }>();
  return <StockEntryEdit order={props.order} parcel_id={parseInt(route.params.parcel)}></StockEntryEdit>;
};
