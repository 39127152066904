import "assets/style/info_label.scss";

/**
 * Shows a fancy label with an icon and a text
 * @param props
 * @param props.icon Bootstrap icon (without the bi-)
 * @param props.theme Bootstrap theme (primary, default, success, ...)
 * @param children Content of the label (usually just a string)
 * @returns
 */
export const InfoBadge = (
  props: React.PropsWithChildren<{ icon: string | number; theme: string; fullWidth?: boolean }>
) => {
  let icon: JSX.Element;
  if (typeof props.icon == "number") {
    icon = <span className={` text-${props.theme} `}>{props.icon}</span>;
  } else {
    icon = <em className={`bi text-${props.theme} bi-${props.icon}`}></em>;
  }

  return (
    <div className={`csem-badge text-light bg-${props.theme} ${props.fullWidth ? "w-100" : ""}`}>
      <div>{icon}</div>{" "}
      <div className="h-100  d-flex flex-column justify-content-center align-middle">{props.children}</div>
    </div>
  );
};
