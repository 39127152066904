import { NestorSelectContext } from "nestor/contexts/NestorSelectContext";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorContainer } from "nestor/types/stock/container";
import assert from "assert";
import { useContext } from "react";

export const StoreContent = () => {
  const ctx = useContext(NestorSelectContext);
  assert(ctx);
  const { store } = ctx;

  return (
    <>
      {store.length === 0 ? (
        <div className="alert alert-info">
          No items selected yet. Select items in the tables below to add them to the transfer order
        </div>
      ) : null}
      <div className="row mb-2">
        {store.map(({ el, type }) => {
          let txt: string = "";
          let theme: string = "";
          let display: string | JSX.Element = "";
          switch (type) {
            case "equipment":
              txt = "Device";
              theme = "primary";
              display = (
                <>
                  <div>
                    {(el as NestorDevice).model?.manufacturer?.name || ""} {(el as NestorDevice).model?.model || ""}
                  </div>
                  <div>SN : {(el as NestorDevice).serialnumber || ""} </div>
                </>
              );

              break;

            case "pcb":
              txt = "PCB";
              theme = "warning";
              display = (el as NestorPcb).layout?.name || "";
              break;

            case "container":
              txt = "Container";
              theme = "info";
              display = (el as NestorContainer).name || "";

              break;
          }

          return (
            <div className={`col-2`}>
              <div className={`p-1 border border-${theme}`}>
                <div className={`w-100 badge bg-${theme}`}>
                  {txt} {el.nuid}
                </div>
                <div className="fw-bold text-truncate">{display}</div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
