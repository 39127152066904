import { useContainerFieldWithCreate } from "nestor/components/forms/fields/ContainerSearch";
import { useSkuSearchField } from "nestor/components/forms/fields/SkuSearch";
import { ManufacturerRenderer } from "nestor/components/renderers/Manufacturer";
import { useTriggerRefresh } from "nestor/contexts/NestorRefreshContext";
import { NestorPOST, useNestorAPI, useNestorRequest } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorArticle, NestorOrderable } from "nestor/types/purchasing/article";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorParcelId } from "nestor/types/purchasing/parcel";
import { NestorContainerId } from "nestor/types/stock/container";
import { NestorPart } from "nestor/types/stock/part";
import { NestorSkuId } from "nestor/types/stock/sku";
import { LabelPrinterSizes, NestorLabelPrinterId } from "nestor/types/util/label_printer";
import { CardComponent } from "@csem/shared-utils";
import { FormLabel } from "@csem/forms";
import { ButtonsColumn, HelpColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContentBasic } from "@csem/lists";
import { APIReturn, apiState } from "@csem/api";
import { isNull } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { apiList } from "@csem/api";
import { SkuContainerListCard } from "../../../stock/skus/ListWithContainers";
import { LabelPrinterButton } from "../../../util/LabelPrinter";
import { useLabelPrinterList } from "nestor/hooks/api/useLabelPrinterList";
import { useLabelPrinterField } from "nestor/components/forms/fields/LabelPrinterList";
import { useMe } from "nestor/hooks/me";

/*
- OK (par défaut )
- Not OK    ->    Non-conformity issued     
                        -> No (par défaut)  + champ pour commentaire
                        -> Yes  + Severity Minor ou Major + champ pour indiquer la référence (que la sévérité de la non- conformité soit minor ou major)
->    nombre de pas OK/nombre total reçus
*/

type T = NestorArticle;

type SKUEntry = {
  qty_received: number;
  qa: "OK" | "NOK";
  non_conformity: "NO" | "MINOR" | "MAJOR";
  non_conformity_ref: string;
  nok_number: number;
  comment: string;
};

const SKUEntryDefault: SKUEntry = {
  qty_received: 0,
  qa: "OK",
  non_conformity: "NO",
  non_conformity_ref: "",
  nok_number: 0,
  comment: "",
};

type FWLst = {
  // items: ItemsEntry;
  //  setItems: React.Dispatch<React.SetStateAction<ItemsEntry>>;
  parcel_id: NestorParcelId;
  order: NestorOrder;
  container_id?: NestorContainerId;
  labelPrinterApiCall?: APIReturn<unknown, number>;
};

type FW = FWLst;
type HOOK = {
  skuEntryState: SKUEntry;
  setSkuEntryState: React.Dispatch<React.SetStateAction<SKUEntry>>;

  apiCallState: apiState;
  apiCallDoQuery: () => void;
  apiCallResult: any;
  disabled: boolean;
  sku_from: JSX.Element;
  sku_from_id: NestorSkuId | undefined;
};

const ListContainer = GenericListContentBasic<FWLst, T>();

const SKUTable = (function () {
  return TableRenderer<T, FW, {}, HOOK, "id">({
    key: "id",
    options: {
      small: true,
    },
    columns: [
      HelpColumn(
        SimpleColumn("input_sku", "Input SKU", (e, fw, hk) => hk.sku_from),
        "Use this field if you are receiving an SKU back from a supplier, and you don't want to create a new one"
      ),
      SimpleColumn("ref", "Ref", (e) => e.reference),
      SimpleColumn("description", "Description", (e) => {
        const txt =
          e.description ||
          (e.orderable_type === NestorOrderable.PART ? (e.orderable as NestorPart | null)?.description : "");

        return (
          <div
            title={txt}
            style={{ width: "220px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {txt}
          </div>
        );
      }),
      /*
      SimpleColumn("manufacturer", "Manufacturer", (e) => {
        if (!e.orderable) {
          return <div className="text-muted">No part information</div>;
        }
        if (e.orderable_type === NestorOrderable.PART) {
          let _e = e.orderable as NestorPart;

          return <ManufacturerRenderer manufacturer={_e.manufacturer} id={_e.manufacturer_identification} />;
        }
      }),
*/
      SimpleColumn("qty_o", "# Ordered", (e) => {
        const orderable = e.orderable as NestorPart | undefined;
        if (orderable?.dividable && !isNull(orderable.dividable_amount)) {
          return (
            <>
              {orderable.dividable_amount * e.quantity_ordered} {orderable.dividable_unit}
            </>
          );
        }

        return e.quantity_ordered;
      }),
      SimpleColumn("qty_a", "# already received", (e) => {
        const orderable = e.orderable as NestorPart | undefined;
        if (orderable?.dividable && !isNull(orderable.dividable_amount)) {
          return (
            <>
              {e.quantity_accounted} {orderable.dividable_unit}
            </>
          );
        }

        return e.quantity_accounted;
      }),

      SimpleColumn("qa", "QA", (el, fw, hk) => {
        return (
          <>
            <select
              className="form-select"
              disabled={hk.disabled}
              value={hk.skuEntryState.qa}
              onChange={(e) => {
                hk.setSkuEntryState((s) => {
                  return { ...s, qa: e.target.value as "OK" | "NOK" };
                });
              }}
            >
              <option value="OK">Ok</option>
              <option value="NOK">Not ok</option>
            </select>
          </>
        );
      }),

      SimpleColumn("comment", "Comment", (el, fw, hk) => {
        return (
          <>
            <input
              type="text"
              className="form-control"
              value={hk.skuEntryState.comment}
              onChange={(e) => {
                hk.setSkuEntryState((s) => {
                  return { ...s, comment: e.target.value };
                });
              }}
            />
            {hk.skuEntryState.qa === "NOK" && (
              <div className="hstack gap-1">
                <div className="form-floating mt-1">
                  <select
                    className="form-select"
                    disabled={hk.disabled}
                    value={hk.skuEntryState.non_conformity}
                    onChange={(e) => {
                      hk.setSkuEntryState((s) => {
                        return { ...s, non_conformity: e.target.value as "NO" | "MINOR" | "MAJOR" };
                      });
                    }}
                  >
                    <option value="NO">None</option>
                    <option value="MINOR">Minor</option>
                    <option value="MAJOR">Major</option>
                  </select>
                  <FormLabel label="NC" />
                </div>

                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    disabled={hk.disabled}
                    value={hk.skuEntryState.non_conformity_ref}
                    onChange={(e) => {
                      hk.setSkuEntryState((s) => {
                        return { ...s, non_conformity_ref: e.target.value };
                      });
                    }}
                  />
                  <FormLabel label="Ref doc. (EF-616 form)" />
                </div>
              </div>
            )}
          </>
        );
      }),
      /*
      SimpleColumn("rec_all", "Set qty received to qty ordered", (e, fw, hk) => {
        if (hk.sku_from_id) {
          return null;
        }
        return (
          <button
            type="button"
            className="btn btn-primary pill"
            style={{ whiteSpace: "nowrap" }}
            disabled={hk.disabled}
            onClick={() => {
              hk.setSkuEntryState((s) => {
                return { ...s, qty_received: e.quantity_ordered - e.quantity_accounted };
              });
            }}
          >
            All
          </button>
        );
      }),
*/
      SimpleColumn("num", "Quantity received", (el, fw, hk) => {
        if (hk.sku_from_id) {
          return null;
        }

        let label = "Qty received";
        const orderable = el.orderable as NestorPart | undefined;

        if (orderable?.dividable && !isNull(orderable.dividable_amount)) {
          label += ` (${orderable.dividable_unit})`;
        }

        return (
          <>
            <div>
              <input
                type="number"
                step="1"
                min="0"
                className="form-control"
                disabled={hk.disabled}
                value={hk.skuEntryState.qty_received || 0}
                onChange={(e) => {
                  let v = parseFloat(e.target.value);
                  if (isNaN(v)) {
                    v = 0;
                  }

                  hk.setSkuEntryState((s) => {
                    return { ...s, qty_received: v };
                  });
                }}
              />
            </div>
            {hk.skuEntryState.qa === "NOK" && (
              <>
                <div className="form-floating mt-1">
                  <input
                    type="text"
                    className="form-control"
                    value={hk.skuEntryState.nok_number}
                    onChange={(e) => {
                      if (!isNaN(parseInt(e.target.value))) {
                        hk.setSkuEntryState((s) => {
                          return { ...s, nok_number: parseInt(e.target.value) };
                        });
                      }
                    }}
                  />
                  <FormLabel label="Qty NOK" />
                </div>
              </>
            )}
          </>
        );
      }),

      ButtonsColumn("actions", "Add to stock", [
        {
          title: "Add into stock",
          theme: "info",
          visibility: (e, fw, hk) => {
            return (
              !hk.disabled &&
              !hk.apiCallState.loading &&
              !hk.apiCallState.success &&
              (hk.skuEntryState.qty_received > 0 || hk.sku_from_id !== undefined) &&
              !(
                (hk.skuEntryState.qa === "NOK" && hk.skuEntryState.nok_number === 0 && hk.sku_from_id === undefined) ||
                (hk.skuEntryState.non_conformity !== "NO" && hk.skuEntryState.non_conformity_ref === "")
              )
            );
          },
          cb: (e, fw, hk) => {
            hk.apiCallDoQuery();
          },
        },
      ]),
      /*
      SimpleColumn("print", "Label", (el, fw, hk) => {
        if (!hk.apiCallState.success) {
          return null;
        }

        return (
          <LabelPrinterButton
            size={LabelPrinterSizes.SZ_12}
            url={NestorServerRoutes.sku_print_label(hk.apiCallResult[0].id)} // ${hk.apiCallResult.response.id}
          />
        );
      }),*/
    ],
    useColumnWidth: () => {
      return {
        rec_all: "0px",
        qa: "90px",
        qty_a: "90px",
        qty_o: "90px",
        num: "170px",
        input_sku: "190px",
      };
    },
    useLineParamInjection: (el, fw) => {
      const [lineState, setLineState] = useState<SKUEntry>({
        ...SKUEntryDefault,
        qty_received: el.quantity_ordered - el.quantity_accounted,
      });

      const apiCall = useNestorAPI<any>(
        NestorServerRoutes.package_add_stock(fw.order.id, fw.parcel_id),
        false,
        undefined,
        NestorPOST
      );

      const { container_id } = fw;
      const refresher = useTriggerRefresh();
      const { doQuery } = apiCall;

      const skuSource = useSkuSearchField({
        label: "Source SKU",
        floating: false,
        noMargin: true,
        defaultValue: undefined,
        originalValue: undefined,
      });

      return {
        skuEntryState: lineState,
        setSkuEntryState: setLineState,
        apiCallState: apiCall.state,
        sku_from: skuSource.Content,
        sku_from_id: skuSource.value,

        apiCallDoQuery: useCallback(() => {
          doQuery(undefined, {
            container_id: container_id,
            articles: [
              {
                sku_id: skuSource.value,
                id: el.id,
                inspection: lineState.qa,
                qty_received: lineState.qty_received,
                comment: lineState.comment,
                non_conformity: lineState.non_conformity,
                non_conformity_ref: lineState.non_conformity_ref,
                nok_number: lineState.nok_number,
              },
            ],
          }).then((out: any) => {
            if (fw.labelPrinterApiCall)
              return fw.labelPrinterApiCall.doQuery(out[0].id).then(() => {
                return refresher();
              });
            else return refresher();
            //NestorServerRoutes.sku_print_label(hk.apiCallResult[0].id);
          });
        }, [lineState, el.id, doQuery, container_id, refresher, fw.labelPrinterApiCall]),
        apiCallResult: apiCall.result,
        disabled: !fw.container_id || el.quantity_accounted >= el.quantity_ordered,
      };
    },

    useRowVisibility: (el) => {
      return el.orderable_type === NestorOrderable.PART || el.orderable_type === "";
    },
  });
})();

export const StockEntrySku: React.FunctionComponent<{
  order: NestorOrder;
  parcel_id: NestorParcelId;
  apiListArticles: apiList<NestorArticle>;
  //setStore: React.Dispatch<React.SetStateAction<EntryStore>>;
}> = (props) => {
  const me = useMe();

  const { Content: ContainerContent, container_id } = useContainerFieldWithCreate("Target container for the items");
  const { Content: LabelPrinterContent, selectedElement: selectedLabelPrinter } = useLabelPrinterField({
    label: "Label printer to print from",
    defaultValue: me.label_printer_id,
  });

  const labelPrinterApiCall = useNestorRequest(
    (el?: number) => (el && NestorServerRoutes.sku_print_label(el)) || false,
    false,
    undefined,
    undefined,
    undefined,
    "/api"
  );

  useEffect(() => {
    labelPrinterApiCall.setSearchParams(
      new URLSearchParams({
        labelPrinter: String(selectedLabelPrinter?.id),
      })
    );
  }, [selectedLabelPrinter?.id, labelPrinterApiCall.setSearchParams]);

  return (
    <>
      <div className="v-stack gap-3">
        <CardComponent header={"Stock items for order " + props.order.nuid}>
          <div className="alert alert-info">
            <strong>New modified procedure (04.03.2023). How to perform stock entries:</strong>
            <br />
            1. Select a target container
            <br /> 2. (Optional) Select a label printer you want to print on
            <br /> 3. Make sure the 12mm tape in in !
            <br /> 4. Adjust the received quantities if needed (by default, they match the number of ordered items){" "}
            <br />
            5. For each entry, click "Add to stock" and go get the printed label
            <br />
            <br />
            <em>
              If you need to reprint a label for an existing entry, you'll find a table below with all the SKUs already
              created for this order{" "}
            </em>
          </div>
          <div className="d-flex">{ContainerContent}</div>
          <div className="d-flex">{LabelPrinterContent}</div>
          <ListContainer
            fwProps={{
              order: props.order,
              container_id: container_id,
              labelPrinterApiCall: labelPrinterApiCall,
              parcel_id: props.parcel_id,
            }}
            listTemplate={SKUTable}
            list={props.apiListArticles}
          />
        </CardComponent>

        <SkuContainerListCard parcel_id={props.parcel_id} />
      </div>
    </>
  );
};
