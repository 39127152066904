import { NestorSelectContext } from "nestor/contexts/NestorSelectContext";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useForm } from "@csem/forms";
import { IOState } from "@csem/api";
import { useContext } from "react";
import { extendLocationPayload, useLocationFields } from "../../forms/fields/LocationField";
import { StoreContent } from "./StoreContent";

export const TransferCard = ({ showStore = true, title }: { title?: string; showStore?: boolean }) => {
  const ctx = useContext(NestorSelectContext);
  assert(ctx);
  const { store, toggle } = ctx;

  const location_f = useLocationFields(undefined, true);

  const form = useForm({
    onSubmit: () => {
      apiCall
        .doQuery(
          undefined,
          extendLocationPayload(
            {
              objects: store.map((s) => {
                return { type: s.type, id: s.el.id };
              }),
            },
            location_f
          )
        )
        .then(() => {
          ctx.empty();
        });
    },
    fields: {
      room_id: location_f.room_id,
      laboratory_id: location_f.laboratory_id,
      container_id: location_f.container_id,
      location: location_f.location_other,
    },

    fw: {},
  });

  const apiCall = useNestorAPI("locations/move", false, undefined, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return (
    <CardComponent additionalClasses="mb-2 h-100" header={title || "Transfer items to a new location"}>
      {showStore && <StoreContent />}

      {store.length > 0 ? (
        <>
          <h5 className="mt-3">New location</h5>
          <IOState source={apiCall.state} />
          {location_f.SwitchButtons}
          {location_f.Field}
          <button
            className="btn btn-success"
            onClick={() => {
              form.submit();
            }}
          >
            Validate
          </button>
        </>
      ) : null}
    </CardComponent>
  );
};
