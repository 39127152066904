// Hooks

// Classes

// Components

// Contexts
import { SupplierQuickAddEditModal } from "nestor/components/nestor/purchasing/suppliers/Modals/Add";
import { SupplierNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { useSupplierList, useSupplierListQuality } from "nestor/hooks/api/useSupplierList";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { CardComponent } from "@csem/shared-utils";
import {
  DefaultStandardTableFilter,
  FilterProps,
  HasTableFilterClear,
  StandardTableFilterCtxProvider,
} from "@csem/lists";
import { TableSearch } from "@csem/lists";
// ## Generic
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { FilterableColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasRefresh, HasSearch } from "@csem/lists";
//# Tables
//## Nestor
import { DateColumn } from "../../../tables/columns/DateColumn";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import moment from "moment";
import { SearchByProject } from "nestor/components/tables/columns/ProjectColumn";
import { NestorProject, NestorProjectId } from "nestor/types/projects/projects";
import { NestorSupplierQualification } from "nestor/types/purchasing/supplier_qualification";
import { NestorProjectSupplier } from "nestor/types/projects/project_suppliers";

type T = NestorSupplier;

const ProjectFilter = function Filter(props: FilterProps<string | number>) {
  return (
    <>
      <SearchByProject
        onChange={(v, o) => {
          props.onChange(v, o);
        }}
      />
    </>
  );
};

export const BadgeQualification = ({ criticality }: { criticality: NestorProjectSupplier }) => {
  const qualification = criticality.supplier_qualification;

  const hasAQual = !!qualification;
  const hasAnExpiredQual = moment(qualification?.expires_at).isBefore();
  const hasARevokedQual = !(qualification?.revoked_at == null);

  return (
    <>
      {!hasAQual ? (
        criticality.class == "C" ? (
          <span className="badge bg-dark">Not qualified</span>
        ) : (
          <span className="badge bg-danger">Not qualified</span>
        )
      ) : hasAnExpiredQual ? (
        <span className="badge bg-danger">Expired</span>
      ) : hasARevokedQual ? (
        <span className="badge bg-danger">Revoked</span>
      ) : (
        <span className="badge bg-success">Qualified</span>
      )}
    </>
  );
};
const GenericSupplierTable = (function () {
  return TableRenderer<T, {}, {}, {}, "id">({
    key: "id",
    columns: [
      SupplierNavigationColumn,
      NUIDColumn,

      FilterableColumn(
        SortableColumn(
          SimpleColumn("name", "Name", (e) => e.name),
          "name"
        ),
        TextFilterFactory({ label: "Name" })
      ),
      FilterableColumn(
        SimpleColumn("quals", "Qualification status and criticality for selected projects", (e) => {
          const criticalities = e.critical_projects;
          if (!criticalities) {
            return null;
          }
          return (
            <ul className="list-group">
              {criticalities.map((c) => {
                if (!c.project) {
                  return null;
                }

                return (
                  <li className="list-group-item">
                    <div>
                      <BadgeQualification criticality={c} />
                      &nbsp;
                      <strong>{c.class}</strong> for {c.project.code} <strong>{c.project.name}</strong>
                      {c.supplier_qualification ? (
                        <>
                          , from {moment(c.supplier_qualification?.created_at).format("YYYY-MM-DD")} to{" "}
                          {moment(c.supplier_qualification?.expires_at).format("YYYY-MM-DD")} (reeavalute on the{" "}
                          {moment(c.supplier_qualification?.reevaluate_at).format("YYYY-MM-DD")})
                        </>
                      ) : null}
                    </div>
                  </li>
                );
              })}
            </ul>
          );
        }),
        ProjectFilter,
        "qualifications.projects_pivot.project_id"
      ),
      /*FilterableColumn(
        SimpleColumn("critical", "Critical for", (e) => {
          const qualificationsByProject: Record<NestorProjectId, NestorSupplierQualification[]> = {};

          for (let q of e.qualifications || []) {
            if (!q.projects) {
              continue;
            }
            for (let p of q.projects) {
              if (!qualificationsByProject[p.id]) {
                qualificationsByProject[p.id] = [];
              }
              qualificationsByProject[p.id].push(q);
            }
          }

          const criticalities = e.critical_projects;
          if (!criticalities) {
            return null;
          }
          return (
            <ul className="list-group">
              {criticalities.map((q) => {
                if (!q.project) {
                  return null;
                }

                const hasAnActiveQual =
                  qualificationsByProject[q.project.id]?.filter(
                    (e) => e.revoked_at == null && moment(e.expires_at).isAfter()
                  ).length > 0 || false;
                const hasAnExpiredQual =
                  qualificationsByProject[q.project.id]?.filter((e) => e.revoked_at == null).length > 0 || false;
                const hasARevokedQual = !!qualificationsByProject[q.project.id];

                return (
                  <li className="list-group-item">
                    <div>
                      {hasAnActiveQual ? (
                        <span className="badge bg-success">active</span>
                      ) : hasAnExpiredQual ? (
                        <span className="badge bg-danger">expired</span>
                      ) : hasARevokedQual ? (
                        <span className="badge bg-danger">revoked</span>
                      ) : (
                        <span className="badge bg-dark">no qual</span>
                      )}
                      &nbsp;
                      <strong>{q.class}</strong> for {q.project.code} <strong>{q.project.name}</strong>
                    </div>
                  </li>
                );
              })}
            </ul>
          );
        }),
        ProjectFilter,
        "critical_projects.project_id"
      ),
*/
      //   DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      SimpleColumn("grade", "Rating", (supplier) => {
        const grade = supplier.grade;

        if (!grade) {
          return <div className="text-muted">no grade</div>;
        }

        return (
          <>
            {grade.map((e) => {
              return (
                <div className="row text-sm">
                  <div className="col-8">{e.name}</div>
                  <div className="col-4">
                    <strong>{e.avg}</strong>
                  </div>
                </div>
              );
            })}
          </>
        );
      }),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth() {
      return {
        _nav: "0px",
        nuid: "65px",
      };
    },
  });
})();

const GenericSupplierListContainer = HasTableFilterClear(
  HasSearch(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<{}, T>())),
      SupplierQuickAddEditModal,
      "Add a new supplier",
      "plus",
      "primary"
    ),

    {
      name: "contains",
      nuid: "eq",
      account: "contains",
      address: "contains",
      city: "contains",
      postal_code: "eq",
    },
    "or",
    undefined,
    TableSearch
  )
);

export const SupplierQualityListCard = () => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort[0] = {
      dir: "asc",
      field: "name",
    };
    return defaultFilter;
  }, []);

  return (
    <div className="m-2">
      <CardComponent header="Supplier list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericSupplierListContainer
            //cardHeader="Gateway list"
            list={useSupplierListQuality()}
            fwProps={{}}
            listTemplate={GenericSupplierTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
