import { useManufacturerList } from "nestor/hooks/api/useManufacturerList";
import { NestorManufacturer } from "nestor/types/purchasing/manufacturer";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";

export const useManufacturerSearchField = FormSearchHookFactory<NestorManufacturer, NestorManufacturer["id"]>({
  apiListFactory: useManufacturerList,
  template: GenericListRenderer((el) => <>{el.name}</>),
  filterKeys: { name: "contains" },
  traverser_display: (el) => {
    return el.name;
  },
  uniqueKeyField: "id",
});
