import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorLaboratory } from "nestor/types/company/laboratory";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useEmployeeSearchField } from "../../../../forms/fields/EmployeeSearch";
import { useRoomSearchField } from "../../../../forms/fields/RoomSearch";
import { useSectionField } from "../../../../forms/fields/SectorList";
/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */
export const LaboratoryAddEditModal = StandardFormAddEditModalFactory<NestorLaboratory, any, any>({
  title: (el) => (!el ? "Add a new laboratory" : `Edit the laboratory ${el.name}`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const manager1_id = useEmployeeSearchField({
      label: "Manager",
      originalValue: el?.manager1_id,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const manager2_id = useEmployeeSearchField({
      label: "Deputy Manager",
      originalValue: el?.manager2_id,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const metrological_manager_id = useEmployeeSearchField({
      label: "Metrological Manager",
      originalValue: el?.metrology_manager_id,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const room_id = useRoomSearchField({
      label: "Room",
      originalValue: el?.room_id,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const section_id = useSectionField({
      label: "Section",
      originalValue: el?.section_id,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const apiResult = useNestorAPI(
      !el ? NestorServerRoutes.laboratory_create() : NestorServerRoutes.laboratory_update(el.id),
      false,
      undefined,
      NestorPOST
    );

    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        manager1_id: manager1_id.value,
        manager2_id: manager2_id.value,
        metrological_manager_id: metrological_manager_id.value,
        room_id: room_id.value,
        section_id: section_id.value,
      });
    };

    return {
      fields: {
        name,
        manager1_id,
        manager2_id,
        metrological_manager_id,
        room_id,
        section_id,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
