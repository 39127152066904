import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import { NestorDateTimeString } from "../util/dates";
import { NestorDevice, NestorDeviceId } from "./device";

export enum NestorDeviceUnavailabilityCause {
    DAMAGED = 'damaged',
    REPARATION = 'reparation',
    CALIBRATION = 'calibration',
    SERVICE = 'service',
    OTHER = 'other'
}


export type NestorDeviceUnavailabilityId = number;

export type NestorDeviceUnavailability = {
    id: NestorDeviceUnavailabilityId,

    device_id: NestorDeviceId,
    device?: NestorDevice,

    cause: NestorDeviceUnavailabilityCause,

    comment: string,
    comment_end: string,

    started: NestorDateTimeString,
    ended: NestorDateTimeString,

    start_employee_id: NestorEmployeeId,
    end_employee_id: NestorEmployeeId,

    start_employee?: NestorEmployee,
    end_employee?: NestorEmployee,

    updated_at: NestorDateTimeString,
    created_at: NestorDateTimeString
}