import { NestorArticle } from "nestor/types/purchasing/article";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import _ from "lodash";
import { useState } from "react";
import { SelectableOrderArticlesList } from "./ArticleTable";
import { SelectableOrderListCard } from "./List";

/**
 * Definition for the re-order column. Shows a input group with a enterable value. Used by ArticleTable
 * @param props
 * @returns
 */
export const QtyReorder = <FWLst extends { select?: (el: NestorArticle) => void }>(props: {
  el: NestorArticle;
  fw: FWLst;
}) => {
  return (
    <div className="input-group">
      <button className="btn btn-outline-primary" type="button" onClick={() => props.fw.select?.(props.el)}>
        Import
      </button>
    </div>
  );
};

export const OrderArticleAddFromOrderInner = (props: {
  order: NestorOrder;
  importArticle: (el: Omit<NestorArticle, "id">) => void;
}) => {
  const [started, setStarted] = useState<boolean>(false);
  const [order, setOrder] = useState<NestorOrder>();

  if (!started) {
    return (
      <button className="w-100 d-block btn btn-primary" type="button" onClick={() => setStarted(true)}>
        Start
      </button>
    );
  }

  if (order === undefined) {
    return (
      <>
        <button className="w-100 btn btn-info mb-3" type="button" onClick={(e) => setStarted(false)}>
          Cancel
        </button>
        <SelectableOrderListCard supplierId={props.order.supplier_id} onSelected={(el) => setOrder(el)} />
      </>
    );
  } else {
    return (
      <>
        <button className="w-100 btn btn-info mb-3" type="button" onClick={(e) => setOrder(undefined)}>
          Select another other order
        </button>

        <SelectableOrderArticlesList order={order} onSelected={(e) => props.importArticle(_.omit(e, "id"))} />
      </>
    );
  }
};

export const OrderArticleAddFromOrder = (props: {
  order: NestorOrder;
  importArticle: (el: Omit<NestorArticle, "id">) => void;
}) => {
  return (
    <CardComponent header="Import an article from another order" additionalClasses="my-4">
      <OrderArticleAddFromOrderInner {...props} />
    </CardComponent>
  );
};
