import NestorServerRoutes from "nestor/server_routes";
import { NestorDivision } from "nestor/types/company/division";
import { NestorDevice } from "nestor/types/inventory/device";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorDevice;
export const useDeviceList = (props?: {
  //my_loans?: boolean;
  division?: NestorDivision;
  associations?: boolean;
}): apiList<T> => {
  const url = new URLSearchParams();
  let cache = true;
  if (props) {
    const { /*my_loans,*/ division, associations } = props;
    /*
    if (me) {
      url.set("me", "1");
    }
*/
    if (division) {
      url.set("division", division);
    }

    if (associations) {
      cache = false;
      url.set("associations", "1");
    }

    /* if (my_loans) {
      url.set("loan", "1");
      url.set("borrowed_me", "1");
    }*/
  }

  let urlPath: string;
  if (props?.division) {
    urlPath = NestorServerRoutes.device_list_by_bu(props!.division);
  } else {
    urlPath = NestorServerRoutes.device_list();
  }
  return useNestorListApiCall<T>(urlPath, "device", url, cache);
};

export const useNonConformCalibrationDeviceList = (): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.device_list_not_calibrated(), "device");
};

export const useNonConformMetrologicalCheckDeviceList = (): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.device_list_not_checked(), "device");
};

export const useNonConformMaintenanceDeviceList = (): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.device_list_not_maintained(), "device");
};
