import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import DownloadColumn from "nestor/components/tables/columns/DownloadColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { TaskNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { ProjectColumn } from "nestor/components/tables/columns/ProjectColumn";
import { useBTEmployeeList, useEmployeeList, useEmployeeMe } from "nestor/hooks/api/useEmployeeList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useTaskList } from "nestor/hooks/api/useTaskList";
import { useAmIBTAdmin } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorTask, NestorTaskStatus } from "nestor/types/manufacturing/task";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, HtmlColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasDeletion, HasRefresh, RemoveMethod } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { TaskPriorityBadge } from "./Info";

type T = NestorTask;
type FWLst = { mode: NestorTaskStatus; is_elevated: boolean };
type FW = RemoveMethod<T, FWLst>;

export const TaskStatusRenderer = ({ task }: { task: NestorTask }) => {
  // Assigned / Pending

  if (!task.granted) return <div className="w-100 badge bg-danger">not granted</div>;
  if (task.terminated_by_id) return <div className="w-100 badge bg-success">terminated</div>;
  if (task.state === "blocked") return <div className="w-100 badge bg-danger">blocked</div>;
  if (task.state === "stopped") return <div className="w-100 badge bg-warning">stopped</div>;
  if (task.state === "pending") return <div className="w-100 badge bg-warning">pending BT</div>;

  return <div className="w-100 badge bg-success">in progress</div>;
};

const TaskTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      TaskNavigationColumn,
      NUIDColumn,

      SimpleColumn("name", "Name", (e) => e.name),
      ProjectColumn(
        "project",
        "Project",
        "project_id",
        true,
        false,
        (e) => e.project || null,
        (e) => e.project_workpackage || null
      ),

      SimpleColumn("type", "Type", (e) => e.type?.name),
      HtmlColumn("comment", "Last comment", (e) => {
        if (e.conversations && e.conversations.length > 0) {
          return e.conversations[0].content;
        }
        return "";
      }),
      SimpleColumn("req_hours", "Requested hours", (e) => e.requested_hours),
      SimpleColumn("eff_hours", "Consumed hours", (e) => e.executed_hours),
      SimpleColumn("progress", "Task completion", (e) => e.last_percentage),
      SortableColumn(
        SimpleColumn("priority", "Priority", (e) => <TaskPriorityBadge task={e} />),
        "priority",
        false
      ),
      EmployeeColumn("requester", "Requester", "requested_by_id", (e) => e.requester),
      //@ts-ignore
      EmployeeColumn("assignees", "Assignees", "assignees.id", (e) => e.assignees, useEmployeeList, [
        {
          field: "role",
          operator: "eq",
          value: "bt",
        },
      ]),
      DateColumn("starting_date", "Earliest start date", "earliest_start_date", (e) => e.earliest_start_date),

      DateColumn("due_date", "Due date", "due_date", (e) => e.due_date),

      SimpleColumn("status", "Status", (e) => <TaskStatusRenderer task={e} />),

      ButtonsColumn("actions", "", [
        {
          title: "Revoke",
          theme: "danger",
          visibility: (e, fw) => {
            return fw.is_elevated;
          },
          cb: (e, fw) => {
            fw.remove(e, fw);
          },
        },
      ]),

      DownloadColumn((el) => NestorServerRoutes.task_download_ef952(el.id), "EF952"),
    ],
    useColumnWidth: () => {
      return {
        project: "150px",
      };
    },
    useColumnVisibility: (_, fw) => {
      const out: any = {};
      if (fw.mode === "pending") {
        out.download = false;
        out.priority = false;
        out.assignees = false;
        out.starting_date = true;
      } else {
        out.actions = false;
        out.starting_date = false;
      }

      if (fw.mode === "progress") {
        out.download = false;
      }

      return out;
    },

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const TaskListContainer = (function () {
  return HasDeletion(
    HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
    "Confirm task deletion",
    (v: T) => `Are you sure to delete the task "${v.name}" ?`,
    (props) => {
      return useNestorAPI<undefined, NestorTask>(
        (el) => {
          assert(el !== undefined);
          return `manufacturing/tasks/${el.id}`;
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();

export const TaskListCard = (props: { mode: NestorTaskStatus; header: string }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);

  const is_elevated = useAmIBTAdmin();

  return (
    <div className="m-2">
      <CardComponent header={props.header}>
        <StandardTableFilterCtxProvider
          defaultValue={defaultFilter}
          persistKey={"tasks_" + props.mode}
          persistWithURL={true}
        >
          <TaskListContainer
            //cardHeader="Gateway list"
            list={useTaskList(props.mode)}
            fwProps={{ is_elevated, mode: props.mode }}
            listTemplate={TaskTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[
              props.mode === "pending" ? (
                <a key="add" className="btn btn-primary" href="/manufacturing/tasks/create">
                  <em className="bi bi-plus"></em> New Task
                </a>
              ) : null,
            ]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
