import { DeviceColumn } from "nestor/components/tables/columns/DeviceColumn";
import { PcbColumn } from "nestor/components/tables/columns/PcbColumn";
import { SkuColumn } from "nestor/components/tables/columns/SkuColumn";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceAssociation } from "nestor/types/inventory/device_association";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import {
  NestorDeviceModelParameter,
  NestorDeviceModelParameterId,
  NestorDeviceModelParameterType,
} from "nestor/types/inventory/device_model_parameter";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorSku } from "nestor/types/stock/sku";
import { CardComponent } from "@csem/shared-utils";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import {
  CheckboxFilterColumn,
  columnOptions,
  groupedColumn,
  NavigationColumn,
  SimpleColumn,
  TableRenderer,
} from "@csem/lists";
import { GenericListContent, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useDeviceList } from "nestor/hooks/api/useDeviceList";
import assert from "assert";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { PCBRenderer } from "nestor/components/renderers/PCBRenderer";
import { SkuRenderer } from "nestor/components/renderers/SkuRenderer";
import { DeviceRenderer } from "nestor/components/forms/fields/DeviceSearch";

import DownloadColumn from "nestor/components/tables/columns/DownloadColumn";
import NestorServerRoutes from "nestor/server_routes";
import NestorFrontendRoutes from "nestor/frontend_routes";

type T = NestorDevice;
type U = NestorDeviceAssociation;
type HK = {
  associations: { [id: NestorDeviceModelParameterId]: U };
};

type FW = {};
type FWList = FW & {};

const sorter = (a: NestorDeviceModelParameter, b: NestorDeviceModelParameter) => {
  if (a.group === b.group) {
    return a.priority - b.priority;
  }

  return a.group < b.group ? 1 : -1;
};
const List = HasRefresh(HasStdTablePagination(GenericListContent<FW, T>()));

const SystemList = ({ model }: { model: NestorDeviceModel }) => {
  type V = columnOptions<T, FWList, {}>;
  const ParamTableRenderer = useMemo(() => {
    const parameters = model.parameters || [];
    parameters.sort(sorter);

    return TableRenderer<T, FWList, {}, HK, "id">({
      key: "id",
      useLineParamInjection: (el, forwarded) => {
        return {
          associations: Object.fromEntries(
            (function* () {
              assert(el.associations, "Device should have associations fetched");

              for (const association of el.associations) {
                yield [association.device_model_parameter_id, association];
              }
            })()
          ),
        };
      },

      useColumnWidth: () => {
        return { _nav: "0px", nuid: "0px", download: "0px", serial: "150px" };
      },
      headerGroups: true,

      columns: [
        NavigationColumn("_nav", "", (el: NestorDevice) => {
          return NestorFrontendRoutes.device_systems(el.id);
        }),
        NUIDColumn,
        SimpleColumn("serial", "S/N", (e) => {
          return e.serialnumber || "N/A";
        }),
        DownloadColumn((e) => {
          return NestorServerRoutes.system_sheet_download(e.id);
        }, "System sheet"),
        ...((parameters
          ?.map((parameter) => {
            if (!parameter.show_in_header) {
              return undefined;
            }
            let c: columnOptions<NestorDevice, FW, HK>;

            switch (parameter.type) {
              case NestorDeviceModelParameterType.PCB:
                c = SimpleColumn<NestorDevice, FW, HK, any>(parameter.name, parameter.name, (el, fw, hk) => {
                  if (!hk.associations[parameter.id] || hk.associations[parameter.id].associated_type !== "pcb") {
                    return undefined;
                  }

                  return (
                    <PCBRenderer
                      withNav={true}
                      pcb={(hk.associations[parameter.id]?.associated as NestorPcb | undefined) || null}
                    />
                  );
                });
                break;
              case NestorDeviceModelParameterType.SKU:
                c = SimpleColumn<NestorDevice, FW, HK, any>(parameter.name, parameter.name, (el, fw, hk) => {
                  if (!hk.associations[parameter.id] || hk.associations[parameter.id].associated_type !== "sku") {
                    return undefined;
                  }

                  return (
                    <SkuRenderer
                      withNav={true}
                      sku={hk.associations[parameter.id]?.associated as NestorSku | undefined}
                    />
                  );
                });
                break;

              case NestorDeviceModelParameterType.INVENTORY:
                c = SimpleColumn<NestorDevice, FW, HK, any>(parameter.name, parameter.name, (el, fw, hk) => {
                  if (!hk.associations[parameter.id] || hk.associations[parameter.id].associated_type !== "equipment") {
                    return undefined;
                  }

                  return (
                    <DeviceRenderer
                      withNav={true}
                      device={(hk.associations[parameter.id]?.associated as NestorDevice | undefined) || null}
                    />
                  );
                });
                break;

              case NestorDeviceModelParameterType.STRING:
                c = SimpleColumn<NestorDevice, any, any, any>(parameter.name, parameter.name, (e, fw, hk) => {
                  return hk.associations[parameter.id]?.comment || "";
                });
                break;

              case NestorDeviceModelParameterType.CHECKBOX:
                c = SimpleColumn<NestorDevice, any, any, any>(parameter.name, parameter.name, (e, fw, hk) => {
                  return <em className={!!hk.associations[parameter.id]?.comment ? "bi bi-check" : "bi bi-x"}></em>;
                });
                break;

              case NestorDeviceModelParameterType.DATE:
                c = SimpleColumn<NestorDevice, any, any, any>(parameter.name, parameter.name, (e, fw, hk) => {
                  return hk.associations[parameter.id]?.comment;
                  //return moment(hk.associations[parameter.id]?.comment).format("dd-mm-YY");
                });
                break;
            }
            if (!c) {
              return c;
            }
            return groupedColumn(c, parameter.group);
          })
          .filter((el) => el !== undefined) || []) as V[]),
      ],
    });
  }, [model.parameters]);
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.filter.filters[1].filters[1].filters.push({
      field: "model_id",
      value: model.id,
      operator: "eq",
    });
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, [model]);
  return (
    <div className="mt-2">
      <CardComponent header="Systems">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <List
            //cardHeader="Gateway list"
            list={useDeviceList({ associations: true })}
            fwProps={{}}
            listTemplate={ParamTableRenderer}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

export default SystemList;
