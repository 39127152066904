import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";

export const useDeviceModelSingle = (elId: number | string): NestorApiElement<NestorDeviceModel, undefined> => {
  const apiCall = useNestorAPI<NestorDeviceModel>(`inventory/device_models/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState success={null} loadingMessage="Loading model..." source={apiCall.state} />,
  };
};
