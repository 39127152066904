import assert from "assert";
import React, { useEffect, useState } from "react";
import { useLabelPrinterList } from "../hooks/api/useLabelPrinterList";
import { NestorLabelPrinter } from "../types/util/label_printer";

type NestorConfig = {
  printers: Array<NestorLabelPrinter>;
};

export const NestorContext = React.createContext<NestorConfig | undefined>(undefined);

export const NestorContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [state, setState] = useState<NestorConfig>({ printers: [] });

  let printers = useLabelPrinterList();

  useEffect(() => {
    if (!printers.element) {
      return;
    }

    setState((s) => {
      assert(printers.element);
      return { ...s, printers: printers.element };
    });
  }, [!!printers.element]);

  if (!printers.state?.success && !printers.element) {
    return printers.loadingIO;
  } else {
    return <NestorContext.Provider value={state}>{props.children}</NestorContext.Provider>;
  }
};
