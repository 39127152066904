import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import { NestorDevice } from "../inventory/device";
import { NestorPcb } from "../manufacturing/pcb";
import { NestorProject } from "../projects/projects";
import { NestorDateTimeString } from "../util/dates";
import { NestorContainer, NestorContainerId } from "./container";
import { NestorSku, NestorSkuId } from "./sku";

export enum NestorWithdrawTargetType {
    DEVICE = "equipment",
    PCB = "pcb",
    PROJECT = "project",
    NULL = ""
}

export type NestorSkuTransferId = number;

export type NestorSkuTransfer = {
    id: NestorSkuTransferId,

    sku_id: NestorSkuId,
    sku?: NestorSku,

    qty_change: number,
    qty_container_from: number,
    qty_container_to: number,
    comment: string,
    is_removable: 1 | 0,
    is_adjustment: 1 | 0,
    container_from_id: NestorContainerId,
    container_from?: NestorContainer,
    container_to_id: NestorContainerId,
    container_to?: NestorContainer,
    employee_id: NestorEmployeeId,
    employee?: NestorEmployee,

    withdraw_target_type: NestorWithdrawTargetType,
    withdraw_target_id: number,
    withdrawal_target?: NestorDevice | NestorPcb | NestorProject,

    created_at: NestorDateTimeString,

}