import { useOrderList } from "nestor/hooks/api/useOrderList";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import moment from "moment";
import { OrderRenderer } from "../../renderers/OrderRenderer";

type T = NestorOrder;

export const useOrderSearchField = FormSearchHookFactory<T, any>({
  apiListFactory: useOrderList,
  template: GenericListRenderer((el) => <OrderRenderer order={el} />),
  traverser_display: (el) => {
    return `${el.nuid} - Date: ${moment(el.created_at).format("YYYY-MM-DD")}. Suppl.: ${el.supplier?.name}. Price: ${
      el.total
    } ${el.currency?.name}. Sign: ${el.employee?.initials}/${el.signee_left?.initials || "-"}/${
      el.signee_right?.initials || "-"
    }`; // ${/*el.laboratory ? `(${el.laboratory.name})` : ""*/}
  },

  uniqueKeyField: "id",
  filterKeys: {
    nuid: "eq",
    sap_id: "eq",
    "supplier.name": "contains",
    "employee.initials": "eq",
    "employee.fullname": "contains",
  },
});
