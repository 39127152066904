import { LocationMigration } from "nestor/components/nestor/administration/LocationMigration";
import { MassEditIventory } from "nestor/components/nestor/administration/MassEditInventory";
import { SupplierMergeCard } from "nestor/components/nestor/administration/SupplierMerge";
import { useMenuElement } from "@csem/shared-utils";
import { Route, Switch, useRouteMatch } from "react-router-dom";

export function AdministrationMain() {
  const { url } = useRouteMatch();
  useMenuElement("Location migration", url + "/location_migration");
  useMenuElement("Mass inventory update", url + "/mass_edit_inventory");
  useMenuElement("Merge suppliers", url + "/merge_suppliers");

  return (
    <div className="container-fluid p-3">
      <Switch>
        <Route path={`${url}/location_migration`}>
          <LocationMigration></LocationMigration>
        </Route>
        <Route path={`${url}/mass_edit_inventory`}>
          <MassEditIventory></MassEditIventory>
        </Route>
        <Route path={`${url}/merge_suppliers`}>
          <SupplierMergeCard></SupplierMergeCard>
        </Route>
      </Switch>
    </div>
  );
}
