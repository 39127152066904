import { NestorMaintenance } from "nestor/types/inventory/maintenance";

export const MaintenanceRenderer = (props: { maintenance?: NestorMaintenance; fallbackText?: string }) => {
  if (!props.maintenance) {
    return <div className="text-muted">{props.fallbackText}</div>;
  }
  return (
    <>
      <div className="fw-bold">{props.maintenance.name}</div>
      <div className="text-muted">every {props.maintenance.periodicity_days} days</div>
    </>
  );
};
