import { useEmployeeList } from "nestor/hooks/api/useEmployeeList";
import { useMe } from "nestor/hooks/me";
import { NestorEmployee, NestorEmployeeId } from "nestor/types/company/employee";
import { GenericListRenderer } from "@csem/forms";
import { FilterChunkEl, ListFilter, SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { EmployeeRenderer } from "../../renderers/EmployeeInfos";

const EmployeeMeRenderer = (props: { onSelect: (el: NestorEmployee | null) => void }) => {
  const me = useMe();
  return (
    <div className="hstack gap-1">
      <button type="button" className="btn btn-secondary" onClick={() => props.onSelect(me)}>
        <em className="bi bi-person-hearts"></em> @{me.initials}
      </button>
      <button type="button" className="btn btn-secondary" onClick={() => props.onSelect(null)}>
        <em>null</em>
      </button>
    </div>
  );
};

export const EmployeeColumn = <T,>(
  name: string,
  label: string,
  field: { [K in keyof T]: T[K] extends NestorEmployeeId | null ? K : never }[keyof T] | undefined,
  renderer: (el: T) => NestorEmployee | NestorEmployee[] | undefined | null,
  apiListFactory: typeof useEmployeeList = useEmployeeList,
  initialFilter?: FilterChunkEl[]
) => {
  const _renderer = (el: T) => {
    const empl = renderer(el);

    if (Array.isArray(empl)) {
      return (
        <>
          {empl.map((e) => (
            <EmployeeRenderer includeName={false} employee={e || undefined} />
          ))}
        </>
      );
    } else {
      return <EmployeeRenderer includeName={false} employee={empl || undefined} />;
    }
  };

  if (!field) {
    return SimpleColumn(name, label, _renderer);
  }
  return FilterableColumn(
    SimpleColumn(name, label, _renderer),
    SearchElFilterFactory<NestorEmployee, NestorEmployee["id"]>({
      label: "Employee",
      initialFilter: initialFilter,
      apiListFactory: apiListFactory,
      traverser: (el) => el.id,
      filterKeys: {
        fullname: "contains",
        initials: "eq",
        section_id: "eq",
      },
      template: GenericListRenderer((e) => <EmployeeRenderer includeName={true} employee={e} />),
      key: "id",
      top: EmployeeMeRenderer,
    }),
    String(field)
  );
};
