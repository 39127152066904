import { usePcbLayoutList } from "nestor/hooks/api/usePcbLayoutList";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { PCBLayoutRenderer } from "../../renderers/PCBLayoutRenderer";

type T = NestorPcbLayout;

export const usePcbLayoutSearchField = FormSearchHookFactory<T, any>({
  apiListFactory: usePcbLayoutList,

  template: GenericListRenderer((el) => <PCBLayoutRenderer layout={el} fallbackText="" />),
  filterKeys: {
    nuid: "contains",
    name: "contains",
    "project.name": "contains",
    "project.code": "eq",
    "employee.initials": "eq",
    "employee.fullname": "contains",
  },
  traverser_display: (el) => {
    return el.nuid + " (" + el.name + ")";
  },
  uniqueKeyField: "id",
});
