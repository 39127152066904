import { SupplierListCard } from "nestor/components/nestor/purchasing/suppliers/List";
import { SupplierQualityListCard } from "nestor/components/nestor/purchasing/suppliers/ListQuality";

export function SupplierList() {
  return (
    <div className="container-fluid p-3">
      <SupplierListCard />
    </div>
  );
}

export function SupplierQualityList() {
  return (
    <div className="container-fluid p-3">
      <SupplierQualityListCard />
    </div>
  );
}
