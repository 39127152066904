import { PCBAssemblyFeederListCard } from "nestor/components/nestor/manufacturing/pcb_assembly/feeders/List";
import { usePcbAssemblyFeederSetSingle } from "nestor/hooks/api/usePcbAssembly";
import { NestorPcbAssemblyFeederSet } from "nestor/types/manufacturing/pcb_assembly";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { useRouteMatch } from "react-router";

const PcbAssemblyFeederSetDetailInner = (props: { feederset: NestorPcbAssemblyFeederSet; refresh: Function }) => {
  const { url } = useRouteMatch<{ layout: string }>();
  return (
    <>
      <PCBAssemblyFeederListCard feeder_set={props.feederset} />
    </>
  );
};

export const PCBAssemblyFeederSetDetail = () => {
  const route = useRouteMatch<{ feederset: string }>();
  const task = usePcbAssemblyFeederSetSingle(parseInt(route.params.feederset));
  const menu = useMenuElement(task.element ? `Feederset: ${task.element?.name}` : "Loading...", route.url);

  return (
    <XemwayMenuProvider {...menu}>
      {task.loadingIO}
      <div className="container-fluid p-3">
        {task.element ? <PcbAssemblyFeederSetDetailInner feederset={task.element} refresh={task.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
