import { useSingleBackofficeField } from "nestor/components/forms/fields/BackofficeList";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSupplier, NestorSupplierId } from "nestor/types/purchasing/supplier";
import { NestorSupplierAccount } from "nestor/types/purchasing/supplier_account";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormCheckboxField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */

type T = NestorSupplierAccount;

export const SupplierAccountModal = StandardFormAddEditModalFactory<T, { supplier_id: NestorSupplierId } & any, any>({
  title: (el) => (!el ? "Add a new account" : `Edit the room`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const backoffice = useSingleBackofficeField({
      label: "Backoffice",
      originalValue: el?.backoffice_id,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    const account = useFormTextField({
      label: "Account number",
      originalValue: el?.account_number,
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const use_in_order = useFormCheckboxField({
      label: "Use in PO",
      originalValue: !!el?.use_in_order,
      defaultValue: false,
    });

    const username = useFormTextField({
      label: "Username",
      originalValue: el?.username || "",
      defaultValue: "",
      type: FieldValueType.STRING,
    });

    const password = useFormTextField({
      label: "Password",
      originalValue: el?.password || "",
      defaultValue: "",
      type: FieldValueType.STRING,
    });

    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.supplier_account_create(fw.supplier_id)
        : NestorServerRoutes.supplier_account_update(fw.supplier_id, el.id),
      false,
      undefined,
      NestorPOST
    );

    const cb = () => {
      apiResult.doQuery(undefined, {
        backoffice_id: backoffice.value,
        account_number: account.value,
        use_in_order: use_in_order.value,
        username: username.value,
        password: password.value,
      });
    };

    return {
      fields: {
        backoffice,
        account,
        use_in_order,
        username,
        password,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
