import { useSkuCtnList } from "nestor/hooks/api/useSkuList";
import { NestorContainerSku } from "nestor/types/stock/sku_container";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { SkuContainerRenderer } from "../../renderers/SkuContainerRenderer";

export const useSkuContainerSearchField = FormSearchHookFactory<NestorContainerSku, NestorContainerSku["id"]>({
  apiListFactory: useSkuCtnList,
  template: GenericListRenderer((e) => <SkuContainerRenderer skuctn={e} />),
  traverser_display: (el) => {
    return `${el.sku?.nuid} ${el.container?.name} `; // ${/*el.laboratory ? `(${el.laboratory.name})` : ""*/}
  },

  uniqueKeyField: "id",
  filterKeys: {
    "sku.nuid": "eq",
    "container.name": "contains",
    "container.project.code": "eq",
    "container.project.name": "contains",
    "sku.article.description": "contains",
  },
});
