import { AddEditRoomModal } from "nestor/components/modals/AddEditRoom";
import { BuildingColumn } from "nestor/components/tables/columns/BuildingColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useRoomList } from "nestor/hooks/api/useRoomList";
import { useAmIElevated } from "nestor/hooks/me";
import { NestorRoom } from "nestor/types/company/room";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, HasSearch, RemoveButton, TableSearch } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

type T = NestorRoom;
type FW = { isElevated: boolean; history: ReturnType<typeof useHistory> };
type FWLst = EditMethod<T, FW> & RemoveMethod<T, FW>;

const Table = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      /*      NavigationColumn("_nav", "", el => {
                return `/nestor/purchasing/orders/${el.id}`;
            }),
*/
      SimpleColumn("tag", "", (e) => (e.revoked_at ? <div className="d-block badge bg-danger">obsolete</div> : null)),
      SimpleColumn("name", "Imported name (from Oracle)", (e) => e.name),

      FilterableColumn(
        SimpleColumn("name", "Nestor Name", (e) => e.displayed_name),
        TextFilterFactory({ label: "Room name" }),
        "displayed_name"
      ),

      FilterableColumn(
        SimpleColumn("alternate_name", "Altnernative name", (e) => e.alternate_name),
        TextFilterFactory({ label: "Alternate name" }),
        "alternate_name"
      ),

      SimpleColumn("floor", "Floor", (e) => e.floor?.floor),
      BuildingColumn("building", "Building", "floor.building_id", (e) => e.floor?.building),

      ButtonsColumn("actions", "", [
        EditButton("Edit", (e, fw) => fw.isElevated),
        RemoveButton("Revoke", (e, fw) => fw.isElevated),
        {
          title: "Audit",
          theme: "secondary",
          visibility: undefined,
          cb: (e: T, fw: FW & EditMethod<T, FW>) => {
            fw.history.push("/audit/room/" + e.id, "");
          },
        },
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        actions: "0px",
      };
    },

    useColumnVisibility: (cols, fw) => {
      if (fw.isElevated) {
        return { actions: true };
      } else {
        return { actions: false };
      }
    },
  });
})();

const Container = (function () {
  return HasSearch(
    HasDeletion(
      HasAddition(
        HasEdition(HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())), AddEditRoomModal),
        AddEditRoomModal,
        "Add a new room",
        "plus",
        "primary"
      ),
      "Confirm room deletion",
      (v: T) =>
        `Are you sure to delete room "${v.displayed_name}" ?\n${
          v.is_locked ? 'This room has been imported from Oracle. Deleting it will simply mark it as "obsolete"' : ""
        }`,
      (props) => {
        return useNestorAPI<undefined, NestorRoom>(
          (r) => {
            assert(r !== undefined);
            return `company/rooms/${r.id}`;
          },
          false,
          undefined,
          { method: "DELETE" },
          props.list.refresh
        );
      }
    ),
    {
      displayed_name: "contains",
      alternate_name: "contains",
      "floor.building.name": "contains",
    },
    "or",
    "",
    TableSearch
  );
})();

export const RoomListCard = () => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  const isElevated = useAmIElevated();

  return (
    <div className="m-2">
      <CardComponent header="Rooms">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useRoomList(true)}
            fwProps={{ isElevated, history: useHistory() }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
