import { PCBLayoutRenderer } from "nestor/components/renderers/PCBLayoutRenderer";
import { useNestorAPI, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { usePcbComponentList } from "nestor/hooks/api/usePcbAssembly";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssembly, NestorPcbComponent } from "nestor/types/manufacturing/pcb_assembly";
import { CardComponent } from "@csem/shared-utils";
import { useFormSelectRemoteField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useForm } from "@csem/forms";
import { FormTemplateDefault } from "@csem/forms";
import { IOState } from "@csem/api";
import { EmployeeBadgeComplete } from "../../company/employees/EmployeeBadge";
import { PCBComponentListCard } from "./ComponentList";
import SemiAutoPlacementFile from "./SemiAutoFile";
import { UploadFileCard } from "./UploadFile";

const PCBAssemblyEditCard = ({ assembly }: { assembly: NestorPcbAssembly }) => {
  const size_x = useFormTextField({
    label: "Size x (mm)",
    type: FieldValueType.NUMBER,
    originalValue: assembly.size_x,
    defaultValue: 0,
  });

  const size_y = useFormTextField({
    label: "Size y (mm)",
    type: FieldValueType.NUMBER,
    originalValue: assembly.size_y,
    defaultValue: 0,
  });

  const component_reference_top_id = useFormSelectRemoteField({
    useApiHook: useNestorAPI,
    url: NestorServerRoutes.pcb_assembly_component_list_flat(assembly.id),

    keyMap: (el: NestorPcbComponent) => el.id,
    valMap: (el: NestorPcbComponent) => el.designator,

    getterFromKey: (el: string | number | undefined, list?: NestorPcbComponent[]) => {
      if (el === undefined || list === undefined) {
        return;
      }
      return list.find((e) => e.id === el);
    },

    label: "Reference component top layer",
    originalValue: assembly.component_reference_top_id,
    defaultValue: undefined,
  });

  const component_reference_bottom_id = useFormSelectRemoteField({
    useApiHook: useNestorAPI,
    url: NestorServerRoutes.pcb_assembly_component_list_flat(assembly.id),

    keyMap: (el: NestorPcbComponent) => el.id,
    valMap: (el: NestorPcbComponent) => el.designator,

    getterFromKey: (el: string | number | undefined, list?: NestorPcbComponent[]) => {
      if (el === undefined || list === undefined) {
        return;
      }
      return list.find((e) => e.id === el);
    },

    label: "Reference component bottom layer",
    originalValue: assembly.component_reference_bottom_id,
    defaultValue: undefined,
  });

  const apiSave = useNestorAPIForms(NestorServerRoutes.pcb_assembly_update(assembly.id), false, undefined, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });

  const form = useForm({
    fields: {
      size_x,
      size_y,
      component_reference_top_id,
      component_reference_bottom_id,
    },

    Template: FormTemplateDefault,

    fw: {},

    onSubmit: function () {
      apiSave.doQuery(undefined, {
        size_x: size_x.value,
        size_y: size_y.value,
        component_reference_top_id: component_reference_top_id.value,
        component_reference_bottom_id: component_reference_bottom_id.value,
      });
    },
  });

  return (
    <CardComponent header="Edit PnP information">
      <IOState source={apiSave.state} />
      {form.Content}
      <button onClick={form.submit} type="button" className="btn btn-success w-100">
        Save
      </button>
    </CardComponent>
  );
};

const PCBAssemblyInformationCard = ({ assembly }: { assembly: NestorPcbAssembly }) => {
  return (
    <CardComponent header="PCB Assembly project information" additionalClasses="h-100">
      <div className="row align-items-middle mb-2">
        <div className="col-4">Name</div>
        <div className="col-8">
          <h5>{assembly.name}</h5>
        </div>
      </div>

      <div className="row align-items-middle mb-2">
        <div className="col-4">Variant</div>
        <div className="col-8">
          <h6>{assembly.variant}</h6>
        </div>
      </div>

      <div className="row align-items-middle mb-2">
        <div className="col-4">By whom</div>
        <div className="col-8">
          {assembly.employee ? (
            <EmployeeBadgeComplete employee={assembly.employee} />
          ) : (
            <span className="text-muted">No information</span>
          )}
        </div>
      </div>

      <div className="row align-items-middle mb-2">
        <div className="col-4">PCB Layout</div>
        <div className="col-8">
          <PCBLayoutRenderer layout={assembly.pcb_layout} fallbackText="No information" />
        </div>
      </div>

      <div className="row align-items-middle mb-2">
        <div className="col-4">Comments</div>
        <div className="col-8">
          <h5>{assembly.comment}</h5>
        </div>
      </div>
    </CardComponent>
  );
};

export const PcbAssemblyInfoAndComponents = (props: { assembly: NestorPcbAssembly; refresh: Function }) => {
  const apiCallListComponents = usePcbComponentList(props.assembly.id);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <PCBAssemblyInformationCard assembly={props.assembly}></PCBAssemblyInformationCard>
        </div>

        <div className="col-md-4">
          <PCBAssemblyEditCard assembly={props.assembly}></PCBAssemblyEditCard>
        </div>

        <div className="col-md-4">
          <UploadFileCard assembly={props.assembly} refresh={apiCallListComponents.refresh} />
        </div>
      </div>

      <PCBComponentListCard apiCall={apiCallListComponents} assembly={props.assembly} />
    </>
  );
};
