import { NestorBuilding } from "nestor/types/company/building";
import { NestorFloor } from "nestor/types/company/floor";

export const BuildingRenderer = ({
  building,
  floor,
  fallbackText,
}: {
  building?: NestorBuilding | null;
  floor?: NestorFloor;
  fallbackText?: string;
}) => {
  if (!building) {
    return <div className="text-muted">{fallbackText || null}</div>;
  }
  return (
    <>
      <div className="text-muted">
        <div className="text-muted">
          <em className="bi bi-building"></em> {building.name} {floor && <>- Fl {floor.floor}</>}
        </div>
      </div>
    </>
  );
};
