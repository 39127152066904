import { usePcbFeederSetSearch } from "nestor/components/forms/fields/PcbFeederSearch";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssembly } from "nestor/types/manufacturing/pcb_assembly";
import { CardComponent } from "@csem/shared-utils";
import { useFormCheckboxField } from "@csem/forms";
import { useFormFileField } from "@csem/forms";
import { IOState } from "@csem/api";
import { useCallback } from "react";

export const UploadFileCard = ({ assembly, refresh }: { assembly: NestorPcbAssembly; refresh: Function }) => {
  const fileUploadField = useFormFileField({
    label: "Altium BOM file",
    defaultValue: undefined,
  });

  const mode_auto = useFormCheckboxField({
    label: "Automatic pick and place",
    defaultValue: false,
  });

  const mode_semiauto = useFormCheckboxField({
    label: "Semi-Automatic pick and place",
    defaultValue: true,
  });

  const feederset_semi = usePcbFeederSetSearch({
    label: "Semi-Automatic feeder",
    defaultValue: undefined,
  });

  const apiLoadComponents = useNestorAPI(NestorServerRoutes.pcb_assembly_load_bom(assembly.id), false, undefined, {
    method: "POST",
  });

  const { doQuery, state } = apiLoadComponents;

  const formSubmit = useCallback(
    (
      file: typeof fileUploadField["value"],
      auto: typeof mode_auto["value"],
      semiauto: typeof mode_semiauto["value"],
      feederset_semi_v: typeof feederset_semi["value"]
    ) => {
      if (file === undefined || file === true) {
        return;
      }
      const fD = new FormData();
      fD.set("file", file);
      fD.set("auto", auto ? "1" : "0");
      fD.set("semiauto", semiauto ? "1" : "0");
      fD.set("semiauto_feeder", String(feederset_semi_v));

      doQuery(undefined, fD).then(() => {
        refresh();
      });
    },
    [doQuery, refresh]
  );

  return (
    <CardComponent additionalClasses="h-100" header="Upload an Altium BOM file">
      <IOState source={state} />
      {fileUploadField.Content}
      {mode_auto.Content}
      {mode_semiauto.Content}

      {mode_semiauto.value ? feederset_semi.Content : null}

      <button
        className="btn btn-primary"
        onClick={() => formSubmit(fileUploadField.value, mode_auto.value, mode_semiauto.value, feederset_semi.value)}
      >
        Validate
      </button>
    </CardComponent>
  );
};
