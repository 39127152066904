import { NestorLocation } from "nestor/types/system/location";
import { ContainerRenderer } from "./Container";
import { LaboratoryRenderer } from "./LaboratoryRenderer";
import { RoomRenderer } from "./RoomRenderer";

export const LocationRenderer = ({ location, fallbackText }: { location?: NestorLocation; fallbackText?: string }) => {
  if (location?.container_id) {
    if (!location.container)
      return <div className="text-muted">In a container, but no information could be obtained</div>;
    return <ContainerRenderer container={location.container} />;
  }

  if (location?.laboratory_id) {
    if (!location.laboratory)
      return <div className="text-muted">In a laboratory, but no information could be obtained</div>;
    return <LaboratoryRenderer laboratory={location.laboratory} />;
  }

  if (location?.room_id) {
    if (!location.room) return <div className="text-muted">In a room, but no information could be obtained</div>;
    return <RoomRenderer room={location.room} />;
  }

  if (location?.information != null && location.information.length > 0) {
    return <div>{location.information}</div>;
  }
  return <div className="text-muted">{fallbackText || ""}</div>;
};
