// Hooks
import { ContactQuickAddEditModal } from "nestor/components/nestor/purchasing/suppliers/Modals/AddContact";
import { AddressRenderer } from "nestor/components/renderers/AddressRenderer";
import { ContactRenderer } from "nestor/components/renderers/ContactRenderer";
import { useContactList } from "nestor/hooks/api/useContactList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorContact } from "nestor/types/purchasing/contact";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
// Classes
// Components
// Contexts
import { TableFilterCtxProvider } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
// ## Generic
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
//# Tables
//## Nestor
import { DateColumn } from "../../../tables/columns/DateColumn";

type T = NestorContact;
type FWLst = { supplier: NestorSupplier };
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;
type HOOK = {};
const GenericContactTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("title", "Title", (e) => e.title),

      FilterableColumn(
        SimpleColumn("name", "Name", (e) => e.firstname + " " + e.lastname),
        TextFilterFactory({ label: "Name" }),
        "firstname"
      ),

      SimpleColumn("address", "Address", (e) => <AddressRenderer address={e} />),
      SimpleColumn("context", "Contact", (e) => <ContactRenderer contact={e} />),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
      ButtonsColumn("actions", "", [
        {
          title: "Revoke",
          theme: "danger",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.remove(e, fw);
          },
        },

        {
          title: "Edit",
          theme: "info",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.edit(e, fw);
          },
        },
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const GenericContactListContainer = (function () {
  return HasDeletion(
    HasEdition(
      HasAddition(
        HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
        ContactQuickAddEditModal,
        "Add a new contact",
        "plus",
        "primary"
      ),
      ContactQuickAddEditModal
    ),
    "Confirm contact deletion",
    (v: T) => `Are you sure to revoke this contact ?`,
    (props) => {
      return useNestorAPI<undefined, NestorContact>(
        (contact) => {
          assert(contact !== undefined);
          return `purchasing/suppliers/${props.fwProps.supplier.id}/contact/${contact.id}`;
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();

export const ContactListCard = (props: { supplier: NestorSupplier }) => {
  return (
    <CardComponent header="Contact list">
      <StandardTableFilterCtxProvider>
        <GenericContactListContainer
          //cardHeader="Gateway list"
          list={useContactList(props.supplier.id)}
          fwProps={{ supplier: props.supplier }}
          listTemplate={GenericContactTable}
          additionalContent={[]}
          righthandContent={[]}
          lefthandContent={[]}
          bottomContent={[]}
        />
      </StandardTableFilterCtxProvider>
    </CardComponent>
  );
};
