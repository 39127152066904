import { useDeviceParkList } from "nestor/hooks/api/useDeviceParkList";
import { NestorDevicePark } from "nestor/types/inventory/device_park";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";

type T = NestorDevicePark;

export const DeviceParkRenderer = ({ device_park }: { device_park: NestorDevicePark }) => {
  return (
    <>
      <div className="fw-bold">{device_park.name}</div>
      <div className="text-muted">{device_park.responsible?.fullname}</div>
    </>
  );
};

export const useDeviceParkSearchField = FormSearchHookFactory<T, T["id"]>({
  apiListFactory: useDeviceParkList,
  template: GenericListRenderer((el) => <DeviceParkRenderer device_park={el} />),
  traverser_display: (el) => {
    return `${el.name}`;
  },

  uniqueKeyField: "id",
  filterKeys: {
    name: "contains",

    "responsible.fullname": "contains",
    "responsible.initials": "eq",
  },
});
