import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSkuShortcut, NestorSkuShortcutPosition } from "nestor/types/stock/shortcut";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import { useSkuContainerSearchField } from "../forms/fields/SkuContainerSearch";

export const SkuShortcutPositionAddEditModal = StandardFormAddEditModalFactory<
  NestorSkuShortcutPosition,
  { shortcut: NestorSkuShortcut },
  any
>({
  title: (el, fw) => (el ? `Edit the position ${el.name} ` : `Add a new position`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const position = useFormTextField({
      label: "Position",
      originalValue: el?.position,
      type: FieldValueType.STRING,
      defaultValue: "",
    });

    const handle = useFormTextField({
      label: "Handle",
      originalValue: el?.handle,
      type: FieldValueType.STRING,
      defaultValue: "",
    });

    const sku_ctn = useSkuContainerSearchField({
      label: "SKU",
      defaultValue: undefined,
      originalValue: el?.container_sku?.[0]?.id,
    });

    const apiResult = useNestorAPI(
      el
        ? NestorServerRoutes.sku_shortcut_update_position(fw.shortcut.id, el.id)
        : NestorServerRoutes.sku_shortcut_create_position(fw.shortcut.id),
      false,
      undefined,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        handle: handle.value,
        position: position.value,
        sku_ctn: sku_ctn.value,
      });
    };

    return {
      fields: {
        name,
        handle,
        sku_ctn,
        position,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
