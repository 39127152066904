import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorContainer } from "nestor/types/stock/container";
import { CardComponent } from "@csem/shared-utils";
import { useCallback, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import url from "url";

/**
 * Container action page (delete, vacate, etc...)
 * @param props
 * @param props.container A source container
 */
export const ContainerActions = ({ container }: { container: NestorContainer }) => {
  return (
    <div className="row">
      <div className="col-4">
        <ContainerDelete container={container} />
      </div>
    </div>
  );
};

/**
 * Container delete card, in red, with mandatory confirmation
 * @param props.container A container to delete
 */
const ContainerDelete = ({ container }: { container: NestorContainer }) => {
  const can_delete = useHasPermission("delete");
  const name = container.name;
  const [allow, setAllow] = useState<boolean>(false);
  const check = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value == name) {
        setAllow(true);
      } else {
        setAllow(false);
      }
    },
    [name]
  );

  const apiCall = useNestorAPI(`stock/containers/${container.id}`, false, undefined, { method: "DELETE" });
  const route = useRouteMatch();
  const history = useHistory();
  const redirectURL = url.resolve(route.url, "../../");

  return (
    <CardComponent header="Delete container" additionalClasses="text-white bg-danger">
      {can_delete ? (
        <>
          <div className="fw-bold">Are you sure that you want to delete this container ?.</div>
          All SKUs it contains will be set to 0 quantity.
          <p>
            To proceed, please write <span className="badge bg-secondary">{container.name}</span> in the field below
          </p>
          <div className="d-flex justify-content-center">
            <div className="flex-shrink-0">
              <p>
                <input className="form-control" type="text" onChange={check} />
              </p>
              {allow && (
                <button
                  type="button"
                  className="w-100 btn btn-light btn-outline-danger"
                  onClick={() =>
                    apiCall.doQuery().then(() => {
                      history.push(redirectURL);
                    })
                  }
                >
                  I am sure. Proceed
                </button>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          You can not remove this container. It may be because of the following reasons
          <ul>
            <li>Make sure the container contains no devices, PCBs nor any other containers.</li>
            <li>You must be the container owner or project manager in order to remove it</li>
          </ul>
        </>
      )}
    </CardComponent>
  );
};
