import { NestorLocation } from "nestor/types/system/location";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useState } from "react";
import { useContainerSearchField } from "./ContainerSearch";
import { useLaboratorySearchField } from "./LaboratorySearch";
import { useRoomSearchField } from "./RoomSearch";

export type location_fields_t = Pick<
  ReturnType<typeof useLocationFields>,
  "room_id" | "container_id" | "laboratory_id" | "location_other" | "type"
>;

export type locationValue = {
  room_id: ReturnType<typeof useLocationFields>["room_id"]["value"];
  container_id: ReturnType<typeof useLocationFields>["container_id"]["value"];
  laboratory_id: ReturnType<typeof useLocationFields>["laboratory_id"]["value"];
  location_other: ReturnType<typeof useLocationFields>["location_other"]["value"];
  locationType: ReturnType<typeof useLocationFields>["type"];
};

/**
 * Takes some form fields and extract relevant location informations
 * @param location_fields Form fields (or superset of fields) containing the typical location fields
 * @returns Typical Nestor payload to be send to the server, containing the type of location and the relevant ids
 */
export const getLocationValue = (location_fields: location_fields_t): locationValue => {
  return {
    locationType: location_fields.type,
    room_id: location_fields.room_id.value,
    laboratory_id: location_fields.laboratory_id.value,
    container_id: location_fields.container_id.value,
    location_other: location_fields.location_other.value,
  };
};
export const extendLocationPayload = (base: object, location_fields: location_fields_t) => {
  return {
    ...base,
    ...getLocationValue(location_fields),
  };
};

export const isLocationFilled = (location_fields: location_fields_t) => {
  const type = location_fields.type;

  switch (type) {
    case "container":
      return !location_fields.container_id.error;

    case "room":
      return !location_fields.room_id.error;

    case "laboratory":
      return !location_fields.laboratory_id.error;

    case "other":
      return !location_fields.location_other.error;
  }
};

export const useLocationFields = (location: NestorLocation | undefined, required: boolean) => {
  const initState = location?.room_id
    ? "room"
    : location?.laboratory_id
    ? "laboratory"
    : location?.container_id
    ? "container"
    : "other";

  const [locationType, setLocationType] = useState<"room" | "laboratory" | "container" | "other">(initState);

  const room_id = useRoomSearchField({
    label: "Room",
    defaultValue: undefined,
    originalValue: location?.room_id,
    validationOnChange: useFieldOptRequired(required && locationType === "room"),
  });

  const laboratory_id = useLaboratorySearchField({
    label: "Laboratory",
    defaultValue: undefined,
    originalValue: location?.laboratory_id,
    validationOnChange: useFieldOptRequired(required && locationType === "laboratory"),
  });

  const container_id = useContainerSearchField({
    label: "Container",
    defaultValue: undefined,
    originalValue: location?.container_id,
    validationOnChange: useFieldOptRequired(required && locationType === "container"),
  });

  const location_other = useFormTextField({
    label: "Other",
    defaultValue: "",
    type: FieldValueType.STRING,
    originalValue: location?.information,
    validationOnChange: useFieldOptRequired<string>(required && locationType === "other"),
  });

  const SwitchButtons = (
    <>
      <div className="d-flex">
        <button
          type="button"
          className={`btn ${locationType === "room" ? "active btn-primary" : "btn-secondary"}`}
          onClick={() => setLocationType("room")}
        >
          A room
        </button>
        <button
          type="button"
          className={`btn ${locationType === "laboratory" ? "active btn-primary" : "btn-secondary"}`}
          onClick={() => setLocationType("laboratory")}
        >
          A laboratory
        </button>
        <button
          type="button"
          className={`btn ${locationType === "container" ? "active btn-primary" : "btn-secondary"}`}
          onClick={() => setLocationType("container")}
        >
          A container
        </button>
        <button
          type="button"
          className={`btn ${locationType === "other" ? "active btn-primary" : "btn-secondary"}`}
          onClick={() => setLocationType("other")}
        >
          Other
        </button>
      </div>
    </>
  );
  const Field = (
    <>
      {locationType === "room" ? room_id.Content : null}
      {locationType === "laboratory" ? laboratory_id.Content : null}
      {locationType === "container" ? container_id.Content : null}
      {locationType === "other" ? location_other.Content : null}
    </>
  );

  return {
    type: locationType,

    room_id,
    laboratory_id,
    container_id,
    location_other,

    SwitchButtons,
    Field,

    fields: {
      room_id,
      laboratory_id,
      container_id,
      location_other,
    },
  };
};
