import { useProjectSingle } from "nestor/hooks/api/useProject";
import { NestorProject } from "nestor/types/projects/projects";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ProjectCriticalSupplierListCard } from "nestor/components/nestor/company/projects/SuppliersQuality";

const ProjectDetailInner = (props: { project: NestorProject }) => {
  const { url } = useRouteMatch<{}>();

  useMenuElement("Critical suppliers", url + "/suppliers");

  return (
    <Switch>
      <Route path={`${url}/suppliers`}>
        <ProjectCriticalSupplierListCard project={props.project} />
      </Route>
      <Route>
        <Redirect to={`${url}/suppliers`} />
      </Route>
    </Switch>
  );
};

export const ProjectDetails = () => {
  const route = useRouteMatch<{ project: string }>();
  const project = useProjectSingle(parseInt(route.params.project));
  const menu = useMenuElement(project.element ? `Project: ${project.element?.code}` : "Loading...", route.url);

  return (
    <XemwayMenuProvider {...menu}>
      {project.loadingIO}
      <div className="container-fluid p-3">
        {project.element ? <ProjectDetailInner project={project.element} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
