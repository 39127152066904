import { useNestorRequest } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { IOState } from "@csem/api";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

export const NestorSignature = () => {
  const { params } = useRouteMatch<{ key: string }>();

  const apiCall = useNestorRequest(NestorServerRoutes.signature_sign(params.key), false);
  const apiCall_check = useNestorRequest(NestorServerRoutes.signature_sign_check(params.key), true);

  const apiCall_check_error = apiCall_check.state.error;
  useEffect(() => {
    if (apiCall_check_error) {
      // Not signed yet
      apiCall.doQuery();
    }
  }, [apiCall_check_error]);

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-4 ">
          {apiCall_check.state.success && (
            <IOState
              source={apiCall_check.state}
              loadingMessage="Checking if you already signed..."
              successMessage="You have already signed this order"
            />
          )}

          <IOState
            source={apiCall.state}
            loadingMessage="Please wait a moment. We're registering your signature"
            successMessage="Done ! You can close this page"
          />
        </div>
      </div>
    </div>
  );
};
