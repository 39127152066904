import { NestorProjectCode } from "nestor/types/projects/projects";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { useEffect } from "react";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

export const useOrderList = (props?: {
  me?: boolean;
  unfinalized?: boolean;
  invoicing?: boolean;
}): apiList<NestorOrder> => {
  const api = useNestorListApiCall<NestorOrder>(
    "list/purchasing/orders",
    "order",
    calculateURLSearchParams(new URLSearchParams(), props?.unfinalized, props?.invoicing),
    true
  );

  const { setSearchParams } = api;

  useEffect(() => {
    setSearchParams((s) => {
      return calculateURLSearchParams(new URLSearchParams(s), props?.unfinalized, props?.invoicing);
    });
  }, [setSearchParams, props?.me, props?.unfinalized, props?.invoicing]);

  return api;
};

function calculateURLSearchParams(
  url: URLSearchParams,
  unfinalized: boolean | undefined,
  invoicing: boolean | undefined
): URLSearchParams {
  //const url = new URLSearchParams();
  /*
  if (me) {
    url.set("me", "1");
  } else {
    url.delete("me");
  }
*/
  /*
  if (unfinalized) {
    url.set("unfinalized", "1");
  } else {
    url.delete("unfinalized");
  }*/
  /*
  if (projectCode) {
    url.set("project_code", projectCode);
  } else {
    url.delete("project_code");
  }
*/
  /*
  if (invoicing) {
    url.set("invoicing", "1");
  } else {
    url.delete("invoicing");
  }
*/
  return url;
}
