import { NestorCalibrationRange, NestorCalibrationRangeId } from "nestor/types/inventory/calibration_range";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { CalibrationRangeRenderer } from "../../renderers/CalibrationRangeRenderer";

type T = NestorCalibrationRange;

/**
 * Hook that returns a calibration range search field
 */
export const useCalibrationRangeSearch = FormSearchHookFactory<T, NestorCalibrationRangeId>({
  template: GenericListRenderer((e) => <CalibrationRangeRenderer range={e} />),
  traverser_display: (el) => {
    return `${el.name}`;
  },

  uniqueKeyField: "id",
  filterKeys: {
    name: "contains",
    description: "contains",
  },
});
