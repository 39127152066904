import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import {
  NestorSkuShortcut,
  NestorSkuShortcutId,
  NestorSkuShortcutPosition,
  NestorSkuShortcutPositionId,
} from "../stock/shortcut";
import { NestorSku, NestorSkuId } from "../stock/sku";
import { NestorDateTimeString } from "../util/dates";
import { NestorPcbLayout, NestorPcbLayoutId } from "./pcb_layout";

export type NestorPcbAssemblyId = number;

export type NestorPcbAssembly = {
  id: NestorPcbAssemblyId;
  pcb_layout_id: NestorPcbLayoutId;
  pcb_layout?: NestorPcbLayout;

  name: string;
  variant: string;
  employee_id: NestorEmployeeId;
  employee?: NestorEmployee;

  comment: string;

  component_reference_top_id: NestorPcbComponentId;
  component_reference_top?: NestorPcbComponent;

  component_reference_bottom_id: NestorPcbComponentId;
  component_reference_bottom?: NestorPcbComponent;

  size_x: number;
  size_y: number;

  created_at: NestorDateTimeString;
  updated_at: NestorDateTimeString;
};

export enum NestorPcbComponentMountType {
  MANUAL = "manual",
  ERZA = "erza",
  SEMIAUTO = "semi",
  AUTO = "auto",
  TBD = "tbd",
}

export type NestorPcbComponentId = number;

export type NestorPcbComponent = {
  id: NestorPcbComponentId;
  pcb_assembly_id: NestorPcbAssemblyId;
  pcb_assembly?: NestorPcbAssembly;

  enabled: 1 | 0;
  mode: NestorPcbComponentMountType;

  designator: string;
  footprint: string;
  comment: string;
  description: string;
  mid_x: number;
  mid_y: number;
  ref_x: number;
  ref_y: number;
  pad_x: number;
  pad_y: number;
  rotation: number;
  layer: "top" | "bottom";
  faulty: 1 | 0;

  feeder_id: NestorPcbAssemblyFeederId | null | undefined;
  feeder?: NestorPcbAssemblyFeeder;

  updated_at: NestorDateTimeString;
  created_at: NestorDateTimeString;
};

export type NestorPcbAssemblyFeederSetId = number;

export type NestorPcbAssemblyFeederSet = {
  id: NestorPcbAssemblyFeederSetId;
  name: string;

  feeders_count?: number;

  //sku_shortcut_id: NestorSkuId;
  //sku_shortcut?: NestorSku;
  mode: "auto" | "semi";

  created_at: NestorDateTimeString;
};

export type NestorPcbAssemblyFeederId = number;

export type NestorPcbAssemblyFeeder = {
  id: NestorPcbAssemblyFeederId;
  feederset_id: NestorPcbAssemblyFeederSetId;
  feederset?: NestorPcbAssemblyFeederSet;

  enabled: 0 | 1;
  feeder_x: number;
  feeder_y: number;
  value: string;
  footprint: string;

  aliases?: Array<NestorPcbAssemblyFeederAlias>;

  sku_id: NestorSkuId;
  //sku_shortcut_position?: NestorSkuShortcutPosition;
};

export type NestorPcbAssemblyFeederAlias = {
  enabled: 1 | 0;
  result: string;
  feeder_id: NestorPcbAssemblyFeederId;
  feeder?: NestorPcbAssemblyFeeder;
};
