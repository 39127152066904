import { ProposeSigneesModal } from "nestor/components/modals/ProposeSignees";
import { useSupplierFeedbackList } from "nestor/hooks/api/useOrderFeedback";
import { NestorOrderFeedback } from "nestor/types/purchasing/order_feedback";
import { NestorSupplierId } from "nestor/types/purchasing/supplier";
import assert from "assert";
import { GenericListRenderer } from "@csem/forms";
import { TableFilterCtxProvider } from "@csem/lists";
import { ListRenderer, useAutoSearchTrigger } from "@csem/lists";
import moment from "moment";
import { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { FeedbackStars } from "./FeedbackAddEdit";
import { FeedbackDetails } from "../Details";

type T = NestorSupplierId;
type FW = any;
type V = any;

const FeedbackTplInner = GenericListRenderer<NestorOrderFeedback>((el) => (
  <div className="row justify-content-between">
    <FeedbackDetails feedback={el}></FeedbackDetails>
  </div>
));

const FeedbackTpl = (props: { el?: NestorOrderFeedback; active?: boolean }) => {
  if (!props.el) {
    return null;
  }
  return <FeedbackTplInner el={props.el} onSelect={() => {}}></FeedbackTplInner>;
};

const FeedbackList = ListRenderer<NestorOrderFeedback, {}, NestorOrderFeedback["id"]>(FeedbackTpl, "id");

export const SupplierFeedbackListInner = ({
  supplierId,
  maxHeight,
}: {
  supplierId: NestorSupplierId;
  maxHeight?: number;
}) => {
  const list = useSupplierFeedbackList(supplierId);
  useAutoSearchTrigger(list);
  return (
    <FeedbackList
      list={list}
      fwProps={useMemo(() => {
        return {
          maxHeight,
        };
      }, [maxHeight])}
    ></FeedbackList>
  );
};

export const SupplierFeedbackList = ({
  supplierId,
  maxHeight,
}: {
  supplierId: NestorSupplierId;
  maxHeight?: number;
}) => {
  return (
    <TableFilterCtxProvider>
      <SupplierFeedbackListInner supplierId={supplierId} maxHeight={maxHeight} />
    </TableFilterCtxProvider>
  );
};

export const ModalFeedbackList: React.FunctionComponent<{
  show: boolean;
  onValidated: (out?: V) => void;
  handleClose: () => void;
  el: T | undefined;
  fw: FW;
}> = ({ show, onValidated, handleClose, el, fw }) => {
  assert(el);
  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Feedback list</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SupplierFeedbackList supplierId={el} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
