import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import { NestorSector, NestorSectorId } from "../company/sector";
import { NestorDevice } from "../inventory/device";
import { NestorDeviceModel } from "../inventory/device_model";
import { NestorProject, NestorProjectId } from "../projects/projects";
import { NestorProjectWorkpackage, NestorProjectWorkpackageId } from "../projects/workpackage";
import { NestorPart } from "../stock/part";
import { NestorMonetaryValue } from "../util/currency";
import { NestorDateTimeString } from "../util/dates";
import { NestorCostElement, NestorCostElementId } from "./cost_element";
import { NestorOrder, NestorOrderId } from "./orders";

export type CriticalityClasses = "A" | "B" | "C";

export enum NestorOrderable {
  DEVICE = "device",
  SERVICE = "service",
  SERVICE_INVENTORY = "service_inventory",
  PART = "part",
  PCB_LAYOUT = "pcb_layout",
  NULL = "",
}

export type NestorArticleId = number;

export type NestorArticle = {
  comment: string;
  cost_element_id: NestorCostElementId;
  cost_element?: NestorCostElement;
  created_at: NestorDateTimeString;
  description: string;
  //devices: [{id: 8196, division_id: "", old_id: 0, model_id: 4096, package_id: 2051, serialnumber: "N/A",…}]
  discount: number;
  discount_percentage: number | null;
  employee_id: NestorEmployeeId;
  employee?: NestorEmployee;

  id: NestorArticleId;
  order_id: NestorOrderId;
  order?: NestorOrder;

  orderable?: NestorDeviceModel | NestorPart | any;
  //  orderable: {id: 4096, division_id: "", old_id: 0, device_type_id: 808, device_manufacturer_id: 2048,…}
  orderable_id: number;
  orderable_type: NestorOrderable;
  price: NestorMonetaryValue;
  total: NestorMonetaryValue;
  project: NestorProject | null;
  project_id: NestorProjectId | null;
  project_workpackage_id: NestorProjectWorkpackageId | null;
  workpackage?: NestorProjectWorkpackage;
  quantity_accounted: number;
  quantity_ordered: number;
  quantity_total: number | null;
  reference: string;
  section_id: NestorSectorId;
  sector: NestorSector;
  unit: string | null;
  updated_at: NestorDateTimeString;

  administrative?: 0 | 1;
  class: CriticalityClasses;

  devices?: NestorDevice[];
};
