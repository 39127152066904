import { NestorSelectContext } from "nestor/contexts/NestorSelectContext";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { IOState } from "@csem/api";
import { useContext, useState } from "react";
import { useDeviceParkSearchField } from "../../../forms/fields/DeviceParkSearch";
import { useEmployeeSearchField } from "../../../forms/fields/EmployeeSearch";
import { extendLocationPayload, useLocationFields } from "../../../forms/fields/LocationField";
import { StoreContent } from "../../util/StoreContent";
import { DeviceEditBorrowing, useDeviceBorrowingFields } from "./AddEdit";
import NestorServerRoutes from "nestor/server_routes";

export const MassEditCard = () => {
  const ctx = useContext(NestorSelectContext);
  assert(ctx);
  const { store, toggle } = ctx;

  const [changeLocation, setChangeLocation] = useState<boolean>(false);
  const [changeOwner, setChangeOwner] = useState<boolean>(false);
  const [changeDevicePark, setChangeDevicePark] = useState<boolean>(false);
  const [changeBorrowing, setChangeBorrowing] = useState<boolean>(false);

  const location_f = useLocationFields(undefined, changeLocation);
  const owner_f = useEmployeeSearchField({
    label: "New owner",
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(changeOwner),
  });
  const device_park_f = useDeviceParkSearchField({
    label: "New device park",
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(changeDevicePark),
  });

  const { is_fixed_f, borrow_max_duration_days_f } = useDeviceBorrowingFields();

  const form = useForm({
    onSubmit: () => {
      let payload: object = {
        objects: store.map((s) => {
          return { type: s.type, id: s.el.id };
        }),
      };

      if (changeLocation) {
        payload = extendLocationPayload(payload, location_f);
      }

      if (changeOwner) {
        payload = { ...payload, owner_id: owner_f.value };
      }

      if (changeDevicePark) {
        payload = { ...payload, device_park_id: device_park_f.value };
      }

      if (changeBorrowing) {
        payload = {
          ...payload,
          is_fixed: is_fixed_f.value ? 0 : 1,
          borrow_max_duration_days: !is_fixed_f.value ? 0 : borrow_max_duration_days_f.value,
        };
      }

      apiCall.doQuery(undefined, payload).then(() => {
        ctx.empty();
      });
    },

    fields: {
      room_id: location_f.room_id,
      laboratory_id: location_f.laboratory_id,
      container_id: location_f.container_id,
      location: location_f.location_other,
    },

    fw: {},
  });

  const apiCall = useNestorAPI(NestorServerRoutes.inventory.devices.mass_migrate(), false, undefined, NestorPOST);

  return (
    <>
      <CardComponent additionalClasses="mb-2" header="Transfer items to a new location">
        <StoreContent />
        <IOState source={apiCall.state} />

        {store.length > 0 ? (
          <>
            <h4>What do you want to change ?</h4>
            <div className="row">
              <label className="form-checkbox col-12">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={changeLocation}
                  onClick={() => setChangeLocation((l) => !l)}
                />{" "}
                Change location
              </label>
            </div>

            <div className="row">
              <label className="form-checkbox">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={changeDevicePark}
                  onClick={() => setChangeDevicePark((l) => !l)}
                />{" "}
                Change device park
              </label>
            </div>

            <div className="row">
              <label className="form-checkbox">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={changeOwner}
                  onClick={() => setChangeOwner((l) => !l)}
                />{" "}
                Change owner
              </label>
            </div>

            <div className="row">
              <label className="form-checkbox">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={changeBorrowing}
                  onClick={() => setChangeBorrowing((l) => !l)}
                />{" "}
                Borrowing
              </label>
            </div>

            <button
              className="btn btn-success"
              onClick={() => {
                form.submit();
              }}
            >
              Validate
            </button>
          </>
        ) : null}
      </CardComponent>
      <div className="row row-cols-3 mb-2">
        {changeLocation && (
          <div className="col">
            <CardComponent header="New location" additionalClasses="h-100">
              {location_f.SwitchButtons}
              {location_f.Field}
            </CardComponent>
          </div>
        )}

        {changeDevicePark && (
          <div className="col">
            <CardComponent header="New device park" additionalClasses="h-100">
              {device_park_f.Content}
            </CardComponent>
          </div>
        )}

        {changeOwner && (
          <div className="col">
            <CardComponent header="New owner" additionalClasses="h-100">
              {owner_f.Content}
            </CardComponent>
          </div>
        )}

        {changeBorrowing && (
          <div className="col">
            <DeviceEditBorrowing field_fixed={is_fixed_f} field_duration={borrow_max_duration_days_f} />
          </div>
        )}
      </div>
    </>
  );
};
