import assert from "assert";
import { StandardTableFilterContext } from "@csem/lists";
import { get } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { apiList } from "@csem/api";

/**
 * Displays a download button fetching at most 20 pages of the defined API call. Uses the StandardTableFilterContext for filtering
 * @param props
 * @param props.api an API call. Make sure that the call is not used anywhere else, as this component will fetch multiple pages
 * @param props.columns An array of column names to be exported in the csv file. See lodash.get for the formatting of the column names
 * @returns A button component handling the download
 */
const DownloadButton = <T,>({ api, columns }: { api: apiList<T, any, any>; columns: Array<string> }) => {
  const ctx = useContext(StandardTableFilterContext);
  assert(ctx);
  const { state } = ctx;
  const { list, setSearchParams } = api;

  const [page, setPage] = useState<number>();
  const [results, setResults] = useState<string[]>([]);
  const dl = useCallback(
    (page: number) => {
      setSearchParams((u) => {
        const url = new URLSearchParams(u);
        url.set("filtering", JSON.stringify({ ...state, take: 20, skip: page * 20 }));
        return url;
      });
    },
    [setSearchParams, state]
  );

  useEffect(() => {
    if (page !== undefined) {
      dl(page);
    }
  }, [page, dl]);

  // Concatenate results
  useEffect(() => {
    if (list !== undefined) {
      const filteredList = list.map((l) => {
        return columns
          .map((c) => {
            return get(l, c, "");
          })
          .join(",");
      });

      setResults((r) => [...r, ...filteredList]);
    }
  }, [list, columns]);

  useEffect(() => {
    if (page === undefined && results.length > 0) {
      let blob = new Blob([results.join("\r\n")], { type: "text/csv" });
      let url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
      setResults([]);
      //   setPage(undefined);
    }
  }, [page, results]);

  useEffect(() => {
    if (list) {
      if (list.length > 0) {
        setPage((p) => {
          if (p === undefined) {
            return undefined;
          } //assert(p !== undefined);

          if (p >= 19) {
            // Max 400 results
            return undefined;
          }
          return p + 1;
        });
      } else {
        setPage(undefined);
      }
    }
  }, [list]);

  return (
    <button
      className="btn btn-info"
      onClick={() => {
        setPage(0);
      }}
    >
      {page !== undefined ? (
        <>Fetching page {page} ...</>
      ) : (
        <>
          <em className="bi bi-download"></em> Download
        </>
      )}
    </button>
  );
};

export default DownloadButton;
