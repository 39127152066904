// Components

// Contexts
import { ManufacturerRenderer } from "nestor/components/renderers/Manufacturer";
import { QuantityRenderer } from "nestor/components/renderers/QuantityRenderer";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { SKUNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { OrderColumn } from "nestor/components/tables/columns/OrderColumn";
import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { NestorSkuTransferContext } from "nestor/contexts/NestorSkuTransferContext";
import { useSkuList } from "nestor/hooks/api/useSkuList";
import { NestorContainer } from "nestor/types/stock/container";
import { NestorPart } from "nestor/types/stock/part";
import { NestorSku } from "nestor/types/stock/sku";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { HasTableFilterClear, StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// ## Generic
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasRefresh, HasSearch } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useContext, useMemo } from "react";
//# Tables
//## Nestor
import { DateColumn } from "../../../tables/columns/DateColumn";
import { LabelPrinterButton } from "../../util/LabelPrinter";
import { SKUExpiracyTag } from "./ListWithContainers";
import { useZeroQties } from "./utils/ZeroQties";

type T = NestorSku;
type FW = {
  setTransferValue?: (container: NestorContainer, sku: NestorSku, amount: number) => void;
  container?: NestorContainer;
  expired?: boolean;
};

export const GenericSkuTable = (function () {
  return TableRenderer<T, FW, {}, {}, "id">({
    key: "id",
    columns: [
      SKUNavigationColumn,
      // NUIDColumn,
      SimpleColumn("nuid", "NUID", (e) => (
        <>
          <SKUExpiracyTag sku={e} />
          {e?.nuid}
        </>
      )),

      SimpleColumn("barcode", "Nestor1", (e) => e.barcode),

      SimpleColumn("qty_init", "Qty - initial", (e) => <QuantityRenderer qty={e.amount_init} unit={e.unit} />),
      SimpleColumn("qty_re", "Qty - all containers", (e) => <QuantityRenderer qty={e.amount} unit={e.unit} />),
      SimpleColumn("qty_ctn", "Qty - this container", (e, fw) => {
        assert(fw.container);
        const qty = e.containers?.find((c) => c.id === fw.container!.id)?.pivot.quantity ?? "N/A";
        return <QuantityRenderer qty={qty} unit={e.unit} />;
      }),

      SimpleColumn("article_ref", "Article ref", (e) => e.article?.reference),
      SimpleColumn("article_descr", "Article descr", (e) => e.article?.description),
      SimpleColumn("manufacturer", "Manufacturer", (e) => {
        const orderable = e.article?.orderable as NestorPart;
        if (!orderable) {
          return null;
        }
        return (
          <ManufacturerRenderer manufacturer={orderable.manufacturer} id={orderable.manufacturer_identification} />
        );
      }),

      // ProjectColumn("project", "Project", "project_id", e => e.project || null),
      OrderColumn("order", "Order", "order_id", (e) => e.article?.order || null),
      EmployeeColumn("employee", "Employee", "employee_id", (e) => e.employee),
      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
      DateColumn("expiration", "Expiration date", "due", (e) => e.due),

      SimpleColumn("print", "Label", (el, fw) => {
        return <LabelPrinterButton size={LabelPrinterSizes.SZ_12} url={`stock/skus/${el.id}/label/pdf/print`} />;
      }),

      SimpleColumn("transfer", "Movement", (el, fw) => {
        const orderable = el.article?.orderable as NestorPart;
        if (orderable && orderable.dividable) {
          return (
            <div className="input-group">
              <input
                type="number"
                defaultValue={0}
                className="form-control"
                onChange={(e) => {
                  let val = parseFloat(e.target.value);
                  if (isNaN(val)) {
                    val = 0;
                  }
                  fw.setTransferValue!(fw.container!, el, val);
                }}
              />
              <span className="input-group-text">{orderable.dividable_unit}</span>
            </div>
          );
        } else {
          return (
            <input
              className="form-control"
              type="number"
              defaultValue={0}
              onChange={(e) => {
                let val = parseInt(e.target.value);
                if (isNaN(val)) {
                  val = 0;
                }
                fw.setTransferValue!(fw.container!, el, val);
              }}
            />
          );
        }
      }),
    ],
    useColumnVisibility: (cols, fw) => {
      let _cols: any = {};
      if (!fw.setTransferValue || !fw.container) {
        _cols.transfer = false;
      }

      if (!fw.container) {
        _cols.qty_ctn = false;
      }

      if (!fw.expired) {
        _cols.expiration = false;
      }
      return _cols;
    },

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        _nav: "0px",
        barcode: "50px",
        nuid: "60px",
        employee: "100px",
        article_descr: "140px",
        article_ref: "120px",
        order: "180px",
        qty_re: "85px",
        qty_ctn: "85px",
        qty_init: "65px",
        transfer: "155px",
        print: "0px",
        nestor1: "50px",
        created_at: "120px",
      };
    },
  });
})();

export const GenericSkuList = (function () {
  return HasTableFilterClear(
    HasSearch(
      HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())),

      {
        nuid: "eq",
        barcode: "eq",
        "article.description": "contains",
        "article.orderable:part.description": "contains",
        "article.reference": "contains",
        "employee.initials": "eq",
      },
      undefined,
      undefined,
      TableSearch
    )
  );
})();

export const SkuListCard = (props: {
  container?: NestorContainer;
  derived_from_sku?: NestorSku;
  expired?: boolean;
  header: string;
}) => {
  const ctx = useContext(NestorSkuTransferContext);

  const { zeroQty, ZeroQtiesToggler } = useZeroQties();

  const filter = useMemo(() => {
    const filter = cloneDeep(DefaultStandardTableFilter);
    filter.sort = [{ field: "created_at", dir: "desc" }];

    if (!zeroQty) {
      filter.filter.filters[0].filters.push({
        field: "quantities.quantity",
        operator: "gt",
        value: 0,
      });
    }

    return filter;
  }, [zeroQty]);

  return (
    <div className="mb-2">
      <CardComponent header={props.header}>
        <StandardTableFilterCtxProvider defaultValue={filter}>
          <div className="row my-2">
            <div className="col-3">{ZeroQtiesToggler}</div>
          </div>
          <GenericSkuList
            //cardHeader="Gateway list"
            list={useSkuList({
              containerId: props.container?.id,
              derivedFromSkuId: props.derived_from_sku?.id,
              expired: props.expired,
            })}
            fwProps={{
              setTransferValue: useHasPermission("can_withdraw") ? ctx?.setTransferValue : undefined,
              container: props.container,
              expired: props.expired,
            }}
            listTemplate={GenericSkuTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
