import { NestorPOST, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { NestorSku } from "nestor/types/stock/sku";
import { CardComponent } from "@csem/shared-utils";
import { useFormDateField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { FormTemplateDefault } from "@csem/forms";
import { IOState } from "@csem/api";

export const SKUEditExpiration = (props: { sku: NestorSku }) => {
  const due = useFormDateField({
    label: "Expiration date",
    defaultValue: undefined,
    originalValue: props.sku.due || undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const apiCall = useNestorAPIForms(`stock/skus/${props.sku.id}/forms/update`, false, undefined, NestorPOST);

  const form = useForm({
    fields: {
      due,
    },

    Template: FormTemplateDefault,
    fw: {},
    onSubmit: function () {
      apiCall.doQuery(undefined, {
        due: due.value,
      });
    },
  });

  return (
    <CardComponent header="Edit expiracy date">
      <IOState source={apiCall.state} />
      {form.Content}
      <button type="button" className="btn btn-success w-100" onClick={form.submit}>
        Yep, looks good. Validate
      </button>
    </CardComponent>
  );
};
