import { NestorContactFragment } from "nestor/types/util/contact_fragment";

export const ContactRenderer = (props: { contact: NestorContactFragment }) => {
  return (
    <div>
      {props.contact.phone ? (
        <div className="">
          <em className="bi bi-phone"></em> {props.contact.phone}
        </div>
      ) : null}
      {props.contact.email ? (
        <div className="">
          <em className="bi bi-at"></em> {props.contact.email}
        </div>
      ) : null}
      {props.contact.fax ? (
        <div className="">
          <em className="bi bi-printer"></em> {props.contact.fax}
        </div>
      ) : null}
    </div>
  );
};
