import { useDeviceModelSearchField } from "nestor/components/forms/fields/DeviceModelSearch";
import { useManufacturerSearchField } from "nestor/components/forms/fields/ManufacturerSearch";
import { useOnePartSearchField } from "nestor/components/forms/fields/PartSearch";
import { usePcbLayoutSearchField } from "nestor/components/forms/fields/PcbLayoutSearch";
import { DeviceModelModal } from "nestor/components/nestor/inventory/device_models/modals/AddEditDeviceModel";
import { ManufacturerModal } from "nestor/components/modals/AddEditManufacturer";
import { PartModal, usePartFields } from "nestor/components/modals/AddEditPart";
import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useDeviceModelList } from "nestor/hooks/api/useDeviceModelList";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useOrderArticleList, useOrderDetails } from "nestor/hooks/api/useOrderSingle";
import { usePartList } from "nestor/hooks/api/usePartList";
import { useMyDivision } from "nestor/hooks/me";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { NestorArticle, NestorArticleId, NestorOrderable } from "nestor/types/purchasing/article";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorService } from "nestor/types/purchasing/service";
import { NestorPart, NestorPartId } from "nestor/types/stock/part";
import { CardComponent } from "@csem/shared-utils";
import { useFormCheckboxField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useMonetaryField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import React, { useCallback, useEffect, useState } from "react";
import { OrderArticleAddFromOrder } from "./AddArticleFromOrder";
import { OrderArticlesList } from "./ArticleTable";
import { ImputationForm, t_Imputation, useImputation } from "./Edit";
import { OrderArticleAddFromXlsx } from "./xlsx/AddArticleFromXls";
import { useGetSupplierQualificationClasses } from "../suppliers/QualificationsButton";
import { useCriticalityClass } from "nestor/components/forms/fields/ClassList";
import { useOnChange } from "@csem/shared-utils";
import { ProjectCriticalSupplierListPurchase } from "../../company/projects/SuppliersQuality";
type T = NestorArticle;
type FWLst = { order: NestorOrder };

//type HOOK = { total: number };

export type NestorImportableArticle = Omit<NestorArticle, "id"> & { id?: NestorArticleId };

const useUnitPriceWithDiscount = (el: NestorImportableArticle | undefined, order: NestorOrder) => {
  // const [numberV, setNumberV] = useState<number>(el?.quantity_ordered || 0);
  const [discountV, setDiscountV] = useState<number>(el?.discount || 0);
  const [total, setTotal] = useState<number>(0);
  const number = useFormTextField({
    label: "Number of units",
    originalValue: el?.quantity_ordered || 0,
    defaultValue: 0,
    validationOnChange: { required: true },
    type: FieldValueType.NUMBER,
  });

  const unit_price = useMonetaryField({
    label: "Unit price",
    originalValue: el?.price || 0,
    currency: order.currency?.name,

    defaultValue: 0,
    validationOnChange: { required: true },
  });

  const discount_percentage = useFormTextField({
    label: "Discount (%)",
    originalValue: el?.discount_percentage || 0,
    defaultValue: 0,
    type: FieldValueType.NUMBER,
  });

  const discount = useMonetaryField({
    label: "Total discount (" + order.currency?.name + ")",
    currency: order.currency?.name,
    originalValue: discountV,
    defaultValue: 0,
  });

  const total_price = useMonetaryField({
    label: "Total",
    currency: order.currency?.name,
    originalValue: total,
    defaultValue: 0,
    decimals: 2,
  });

  const recalculateDiscount = () => {
    setDiscountV(
      -(total_price.value || 0) +
        (unit_price.value || 0) * (1 - (discount_percentage.value || 0) / 100) * (number.value || 0)
    );
  };

  useEffect(() => {
    setTotal(
      Math.round(
        ((unit_price.value || 0) * (1 - (discount_percentage.value || 0) / 100) * (number.value || 0) -
          (discount.value || 0)) *
          100
      ) / 100
    );
  }, [number.value, unit_price.value, discount.value, discount_percentage.value]);

  useEffect(() => {
    if (total_price.value !== total) {
      recalculateDiscount();
    }
  }, [total_price.value]);

  return {
    number,
    unit_price,
    discount_percentage,
    discount,
    total_price,
  };
};

export const useFormAddEditArticle = (el: NestorImportableArticle | undefined, order: NestorOrder) => {
  const [defaultImputation, setDefaultImputation] = useState<boolean>(
    !el ? !order.grouped_order : !(el?.project_id || el?.section_id)
  );
  const [orderable, setOrderable] = useState<NestorOrderable | undefined>(el?.orderable_type ?? NestorOrderable.PART);

  // An incoming article should change the orderable type

  const [deviceModelId, setDeviceModelId] = useState<number>(
    el?.orderable_type === NestorOrderable.DEVICE ? el?.orderable?.id : undefined
  );

  const [partId, setPartId] = useState<number>(
    el?.orderable_type === NestorOrderable.PART ? el?.orderable?.id : undefined
  );
  const [morePartInfo, setMorePartInfo] = useState<boolean>(partId !== undefined);

  const [serviceDescription, setServiceDescription] = useState<string>("");

  const { imputation, setImputation, project_id, project_workpackage_id, sector_id, cost_element_id } = useImputation(
    Object.assign({}, order, el),
    false,
    true,
    defaultImputation
  );

  const { number, unit_price, discount_percentage, discount, total_price } = useUnitPriceWithDiscount(el, order);

  const comment = useFormTextField({
    label: "Comment (ONLY FOR INTERNAL PURPOSES. NOT SENT TO SUPPLIER)",
    originalValue: el?.comment,
    defaultValue: "",
    type: FieldValueType.STRING,
  });

  const reference = useFormTextField({
    label: "Reference",
    originalValue: el?.reference,
    defaultValue: "",
    type: FieldValueType.STRING,
    validationOnChange: useFieldOptRequired<string>(true, { maxLength: 60 }),
  });

  const article_part_description = useFormTextField({
    label: "Item Description",
    originalValue:
      el?.orderable_type === NestorOrderable.PART
        ? (el.orderable as NestorPart | null)
          ? (el.orderable as NestorPart).description
          : el?.description
        : el?.description,
    defaultValue: "",
    type: FieldValueType.STRING,
  });

  const [_manufacturer_id, setManufacturerId] = useState<number | undefined>(() => {
    if (el?.orderable && el?.orderable_type === NestorOrderable.PART && el !== undefined) {
      const _el = el.orderable as NestorPart;
      return _el.manufacturer_id;
    } else if (el?.orderable && el?.orderable_type === NestorOrderable.DEVICE && el !== undefined) {
      const _el = el.orderable as NestorDeviceModel;
      return _el.device_manufacturer_id;
    }
  });

  const manufacturer_id = useManufacturerSearchField({
    label: "Manufacturer",
    originalValue: _manufacturer_id,
    defaultValue: undefined,
    validationOnChange: {
      required: el?.orderable_type === NestorOrderable.DEVICE,
    },
  });

  const [_pcb_layout_id, setPcbLayoutId] = useState<number | undefined>(() => {
    if (el?.orderable && el?.orderable_type === NestorOrderable.PCB_LAYOUT && el !== undefined) {
      const _el = el.orderable as NestorPcbLayout;
      return _el.id;
    }
  });

  const pcb_layout_id_f = usePcbLayoutSearchField({
    label: "PCB layout",
    originalValue: _pcb_layout_id,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(orderable === NestorOrderable.PCB_LAYOUT),
  });

  useEffect(() => {
    if (el && el.orderable && el.orderable_type) {
      setOrderable(el.orderable_type);
      if (el.orderable_type === NestorOrderable.DEVICE) {
        setManufacturerId((el.orderable as NestorDeviceModel).device_manufacturer_id);
        setDeviceModelId((el.orderable as NestorDeviceModel).id);
      } else if (el.orderable_type === NestorOrderable.PART) {
        setManufacturerId((el.orderable as NestorPart).manufacturer_id);
        setDeviceModelId((el.orderable as NestorPart).id);
      } else if (el.orderable_type === NestorOrderable.SERVICE) {
        setServiceDescription((el.orderable as NestorService).description);
      } else if (el.orderable_type === NestorOrderable.PCB_LAYOUT) {
        setPcbLayoutId((el.orderable as NestorPcbLayout).id);
      }
    }
  }, [el]);

  const part_id = useOnePartSearchField({
    label: "Manufacturer part number",
    defaultValue: undefined,
    originalValue: partId,
    disabled: manufacturer_id.value === undefined,
    apiListFactory: function useApiList() {
      return usePartList(manufacturer_id.value);
    },
  });

  const device_model_id = useDeviceModelSearchField({
    label: "Device",
    defaultValue: undefined,
    originalValue: deviceModelId,
    apiListFactory: function useApiList() {
      return useDeviceModelList(manufacturer_id.value);
    },

    validationOnChange: {
      required: orderable === NestorOrderable.DEVICE,
    },
  });

  const service_description = useFormTextField({
    label: "Description of the service",
    defaultValue: "",
    originalValue: serviceDescription,
    type: FieldValueType.STRING,
    validationOnChange: {
      required: orderable === NestorOrderable.SERVICE,
    },
  });

  const administrative = useFormCheckboxField({
    label: "Administrative item",
    helpText:
      "Does not appear in the order PDF (not sent to the supplier) but available for stock / inventory management",
    defaultValue: false,
    originalValue: !!el?.administrative || false,
  });

  const partFields = usePartFields(
    part_id.selectedItem,
    !!part_id.searchValue,
    part_id.searchValue === undefined || part_id.searchValue === ""
  );

  const criticality = useCriticalityClass(el);

  const apiResult = useNestorAPI<any, NestorOrderable>(
    (orderable) => {
      let url = `purchasing/orders/${order.id}/article/form/`;

      if (el && el.id) {
        url += `update/`;
      } else {
        url += `create/`;
      }

      if (orderable === NestorOrderable.PART) {
        url += `part/`;
      } else if (orderable === NestorOrderable.DEVICE) {
        url += `device/`;
      } else if (orderable === NestorOrderable.SERVICE) {
        url += `service/`;
      } else if (orderable === NestorOrderable.PCB_LAYOUT) {
        url += `pcb_layout/`;
      } else {
        // Add nothing
        url += ``;
      }

      if (el && el.id) {
        url += el.id;
      }
      return url;
    },
    false,
    undefined,
    NestorPOST
  );

  const onPartAdded = useCallback((result: any) => {
    setManufacturerId(result.values.manufacturer_id);
    setPartId(result.response.id);
  }, []);

  const onDeviceModelAdded = useCallback((result: any) => {
    setManufacturerId(result.values.device_manufacturer_id);
    setDeviceModelId(result.response.id);
  }, []);

  const onManufacturerAdded = useCallback((result: any) => {
    setManufacturerId(result.values.id);
  }, []);

  const form = useForm({
    onSubmit: () => {
      let out = {
        "quantity-price-discount": {
          quantity: number.value,
          price: unit_price.value,
          discount: discount.value,
          discountPercentage: discount_percentage.value,
        },
        project_id: !defaultImputation ? project_id.value : null,
        project_workpackage_id: !defaultImputation ? project_workpackage_id.value : null,
        section_id: !defaultImputation ? sector_id.value : null,
        cost_element_id: cost_element_id.value,
        comment: comment.value,
        reference: reference.value,
        manufacturer_id: manufacturer_id.value,
        part_id: part_id.selectedItem?.id,
        pcb_layout_id: pcb_layout_id_f.value,
        device_model_id: device_model_id.value,
        service_description: service_description.value,
        description: article_part_description.value,
        _target: !defaultImputation ? imputation : "default", // Defined by Nestor

        ...(morePartInfo
          ? {
              manufacturer_identification: part_id.searchValue,
              extendedDescription: partFields.extended_description.value,
              URLdatasheet: partFields.datasheet.value,
              dividable: partFields.dividable.value,
              dividable_amount: partFields.dividable_qty.value,
              dividable_unit: partFields.dividable_unit.value,
              category_id: partFields.category_id.value,
            }
          : {}),

        administrative: administrative.value,

        class: criticality.value,
      };

      apiResult.doQuery(orderable, out);
    },
    fields: {
      project_id,
      project_workpackage_id,
      sector_id,
      cost_element_id,
      number,
      unit_price,
      discount_percentage,
      discount,
      total_price,
      comment,
      reference,
      manufacturer_id,
      part_id,
      pcb_layout_id_f,
      article_part_description,
      service_description,
      device_model_id,
      administrative,
      criticality,
      ...partFields,
    },
    Template: FormArticle,
    fw: {
      el,
      imputation,
      setImputation,
      defaultImputation,
      setDefaultImputation,
      orderable,
      setOrderable,
      onPartAdded,
      onDeviceModelAdded,
      onManufacturerAdded,
      part_id_value: partId,
      order: order,
      morePartInfo,
      setMorePartInfo,
    },
  });

  /**
   * When the API call has succeeded, clear the form
   */
  const success = apiResult.state.success;
  const formClear = form.clear;
  useOnChange(
    () => {
      if (success) {
        formClear();
      }
    },
    [success],
    [formClear]
  ); // formClear <== Adding this dependency causes the form to constantly reset

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

type FieldNames =
  | "project_id"
  | "project_workpackage_id"
  | "sector_id"
  | "cost_element_id"
  | "number"
  | "unit_price"
  | "discount_percentage"
  | "discount"
  | "total_price"
  | "comment"
  | "reference"
  | "part_id"
  | "device_model_id"
  | "manufacturer_id"
  | "service_description"
  | "article_part_description"
  | "administrative"
  | "pcb_layout_id_f"
  | "criticality"
  | keyof Omit<ReturnType<typeof usePartFields>, "description">;

const FormArticle: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  order: NestorOrder;

  orderable: NestorOrderable | undefined;
  setOrderable: React.Dispatch<React.SetStateAction<NestorOrderable | undefined>>;

  imputation: t_Imputation;
  setImputation: React.Dispatch<React.SetStateAction<t_Imputation>>;
  defaultImputation: boolean;
  setDefaultImputation: React.Dispatch<React.SetStateAction<boolean>>;
  part_id_value: NestorPartId;

  submit: Function;
  el?: NestorImportableArticle;
  onManufacturerAdded: (result: any) => void;
  onPartAdded: (result: any) => void;
  onDeviceModelAdded: (result: any) => void;

  morePartInfo: boolean;
  setMorePartInfo: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ part_id_value, ...props }) => {
  //const allowed_edit_device_model = true; //useAmIElevated();

  const division = useMyDivision();
  const part_id = props.fields.part_id as ReturnType<typeof useOnePartSearchField>;
  const device_model_id = props.fields.device_model_id as ReturnType<typeof useDeviceModelSearchField>;
  const [addPart, setAddPart] = useState<boolean>(false);
  const [addDeviceModel, setAddDeviceModel] = useState<boolean>(false);
  const [addManufacturer, setAddManufacturer] = useState<boolean>(false);
  /*
  const criticality_supplier = useGetSupplierQualificationClasses(
    props.order.supplier_id,
    props.order.project_id,
    false
  );

  const filtered_criticality_supplier =
    criticality_supplier.result?.data.filter((e) => e.class === props.fields.criticality.value) || [];
*/
  useEffect(() => {
    if (part_id_value) {
      props.setMorePartInfo(true);
    } else {
      props.setMorePartInfo(false);
    }
  }, [part_id_value]);
  return (
    <>
      {addDeviceModel ? (
        <DeviceModelModal
          show={true}
          handleClose={() => {
            setAddDeviceModel(false);
          }}
          onValidated={(out) => {
            props.onDeviceModelAdded(out);
            setAddDeviceModel(false);
          }}
          el={device_model_id.selectedItem}
          fw={{ manufacturer_id: props.fields.manufacturer_id.value }}
        />
      ) : null}

      {addManufacturer ? (
        <ManufacturerModal
          show={true}
          handleClose={() => {
            setAddManufacturer(false);
          }}
          onValidated={(out) => {
            props.onManufacturerAdded(out);
            setAddManufacturer(false);
          }}
          fw={{}}
        />
      ) : null}

      {addPart ? (
        <PartModal
          show={true}
          handleClose={() => {
            setAddPart(false);
          }}
          onValidated={(out) => {
            props.onPartAdded(out);
            setAddPart(false);
          }}
          el={part_id.selectedItem}
          fw={{
            manufacturer_id: props.fields.manufacturer_id.value,
          }}
        />
      ) : null}

      <div className="row align-items-center mb-3">
        <div className="col-12 ">
          <div className="row">
            <div className="col-4 mb2">What are you ordering ?</div>
            <div className="col-8 mb2">
              <button
                type="button"
                className={`btn btn-lg  ${
                  props.orderable === NestorOrderable.PART ? "btn-primary active" : "btn-secondary"
                } `}
                onClick={(e) => props.setOrderable(NestorOrderable.PART)}
              >
                A stockable part
              </button>
              <button
                type="button"
                className={`btn btn-lg  ${
                  props.orderable === NestorOrderable.DEVICE ? "btn-primary active" : "btn-secondary"
                } `}
                onClick={(e) => props.setOrderable(NestorOrderable.DEVICE)}
              >
                An inventorized item
              </button>
              <button
                type="button"
                className={`btn btn-lg btn-primary ${
                  props.orderable === NestorOrderable.SERVICE ? "btn-primary active" : "btn-secondary"
                } `}
                onClick={(e) => props.setOrderable(NestorOrderable.SERVICE)}
              >
                A service
              </button>
              <button
                type="button"
                className={`btn btn-lg btn-primary ${
                  props.orderable === undefined || props.orderable === null || props.orderable === ""
                    ? "btn-primary active"
                    : "btn-secondary"
                } `}
                onClick={(e) => props.setOrderable(undefined)}
              >
                Other
              </button>
              <button
                type="button"
                className={`btn btn-lg btn-primary ${
                  props.orderable === NestorOrderable.PCB_LAYOUT ? "btn-primary active" : "btn-secondary"
                } `}
                onClick={(e) => props.setOrderable(NestorOrderable.PCB_LAYOUT)}
              >
                A PCB Assembly
              </button>
            </div>
          </div>
        </div>
      </div>
      {props.fields.administrative.Content}
      {!props.fields.administrative.value ? (
        <>
          <div className="row align-items-center mb-3">
            <div className="col-4 mb2">Should we impute on the order default source ?</div>
            <div className="col-8 mb2">
              <button
                type="button"
                className={`btn btn-lg  ${props.defaultImputation ? "btn-primary active" : "btn-secondary"} `}
                onClick={(e) => props.setDefaultImputation(true)}
              >
                Yes
              </button>

              <button
                type="button"
                className={`btn btn-lg btn-primary ${
                  !props.defaultImputation ? "btn-primary active" : "btn-secondary"
                } `}
                onClick={(e) => props.setDefaultImputation(false)}
              >
                No
              </button>
            </div>
          </div>
          {!props.defaultImputation ? (
            <>
              <ImputationForm imputation={props.imputation} setImputation={props.setImputation} fields={props.fields} />

              {props.fields.cost_element_id.Content}
            </>
          ) : null}
        </>
      ) : null}
      <div className="row">
        <div className="col-6">{props.fields.reference.Content}</div>
        <div className="col-6">
          {props.orderable === NestorOrderable.PCB_LAYOUT && props.fields.pcb_layout_id_f.Content}
          {props.orderable === NestorOrderable.SERVICE && props.fields.service_description.Content}
          {props.orderable === NestorOrderable.PART ? props.fields.article_part_description.Content : null}
          {props.orderable === NestorOrderable.NULL || props.orderable === undefined
            ? props.fields.article_part_description.Content
            : null}
        </div>
      </div>
      {!props.fields.administrative.value ? (
        <div className="row">
          <div className="col-2">{props.fields.number.Content}</div>

          <div className="col-2">{props.fields.unit_price.Content}</div>
          <div className="col-2">{props.fields.discount_percentage.Content}</div>
          <div className="col-3">{props.fields.discount.Content}</div>
          <div className="col-3">{props.fields.total_price.Content}</div>
          <div className="col-12 mb-2  text-center">
            <small>
              <span className="text-danger border-bottom border-1 border-danger">
                <strong>Jean Luprano "kindly" reminds you that the total price has to be WITHOUT TAX.</strong>
              </span>
            </small>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-2">{props.fields.number.Content}</div>
        </div>
      )}

      {props.fields.comment.Content}

      {division === "E" && props.order.project_id && (
        <div className="row">
          <div className="col-3">{props.fields.criticality.Content}</div>
          {props.fields.criticality.value !== "C" && (
            <div className="col-9">
              <ProjectCriticalSupplierListPurchase project={props.order.project!} supplier={props.order.supplier!} />
              {/*

              {filtered_criticality_supplier.length === 0 ? (
                <div className="text text-danger fw-bold">
                  The supplier <em>{props.order.supplier!.name}</em> has not been validated as{" "}
                  {props.fields.criticality.value} by the Project Manager
                </div>
              ) : (
                <div className="text text-info">
                  <strong>Crticiality scopes for {props.fields.criticality.value}</strong>
                  <br />
                  {filtered_criticality_supplier.map((e) => e.scope).join(", ")}
                </div>
              )}

              */}
            </div>
          )}
        </div>
      )}
      {props.orderable === NestorOrderable.DEVICE ? (
        <>
          <h6>Device model</h6>
          <div className="row justify-content-between ">
            <div className="col-6">
              <div className="border border-1 p-2  h-100">
                <div className="text-muted mb-2">Search for existing device models</div>
                <div className="">{props.fields.manufacturer_id.Content}</div>
                <div className="">{props.fields.device_model_id.Content}</div>
              </div>
            </div>
            <div className="col-auto align-self-center">-- OR --</div>
            <div className="col-5">
              <div className=" border border-1 p-2 h-100">
                <div className="text-muted mb-2 ">Device model doesn't exist yet</div>
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-lg btn-primary mb-2"
                    onClick={(e) => {
                      setAddManufacturer(true);
                    }}
                  >
                    Add a new manufacturer
                  </button>
                  <br />

                  <button
                    type="button"
                    className="btn btn-primary btn-lg mb-2"
                    onClick={(e) => {
                      setAddDeviceModel(true);
                    }}
                  >
                    {device_model_id.value !== undefined ? "Edit the model" : "Add a new device model"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : props.orderable === NestorOrderable.PART ? (
        <>
          <button
            type="button"
            onClick={() => {
              if (props.morePartInfo) {
                part_id.updateValue(undefined);
              }
              props.setMorePartInfo((s) => !s);
            }}
            className="w-100 btn btn-primary mb-2"
          >
            {!props.morePartInfo ? (
              <>
                <em className="bi bi-plus"></em> Add more information
              </>
            ) : (
              <>
                <em className="bi bi-minus"></em> Less information
              </>
            )}
          </button>
          {props.morePartInfo ? (
            <>
              <div className="row">
                <div className="col-6">
                  <div className="d-flex">
                    <div className="flex-grow-1 me-1">{props.fields.manufacturer_id.Content}</div>
                    <button
                      type="button"
                      className="btn btn-primary mb-2"
                      onClick={(e) => {
                        setAddManufacturer(true);
                      }}
                    >
                      Add a new one
                    </button>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex">
                    <div className="flex-grow-1 me-1">{props.fields.part_id.Content}</div>
                  </div>
                </div>
              </div>

              {props.fields.category_id.Content}
              {props.fields.extended_description.Content}
              {props.fields.datasheet.Content}
              {props.fields.dividable.Content}
              {props.fields.dividable_unit.Content}
              {props.fields.dividable_qty.Content}
            </>
          ) : null}
        </>
      ) : null}

      {!props.el || !props.el.id ? (
        <div className="row mt-3">
          <div className="col-12 mb-2 d-flex ">
            <button className="btn btn-success btn-lg flex-grow-1" type="button" onClick={() => props.submit()}>
              Add article
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

const OrderArticleAdd: React.FunctionComponent<{
  order: NestorOrder;
  refresh: Function;
  article?: NestorImportableArticle;
}> = (props) => {
  const form = useFormAddEditArticle(props.article, props.order);

  const success = form.state.success;
  const refresh = props.refresh;
  useEffect(() => {
    if (success) {
      refresh();
    }
  }, [success, refresh]);

  return (
    <CardComponent header={"Add a new article"}>
      <IOState source={form.state} loadingMessage="Saving article..." />
      {form.Content}
    </CardComponent>
  );
};

export const OrderArticleListEdit: React.FunctionComponent<{
  orderId: number;
}> = (props) => {
  const allowed = useHasPermission("can_add_article");

  const apiCall = useOrderDetails(props.orderId);
  const apiListArticles = useOrderArticleList(props.orderId);

  const [articleImport, setArticleImport] = useState<Omit<NestorArticle, "id">>();

  const { doQuery } = apiCall;
  const refresh = useCallback(() => {
    doQuery();
  }, [doQuery]);

  return (
    <>
      <IOState source={apiCall.state} success={null} />
      {apiCall.result ? (
        <>
          <OrderArticlesList order={apiCall.result} apiListArticles={apiListArticles} />
          {allowed ? (
            <>
              <OrderArticleAdd order={apiCall.result} article={articleImport} refresh={apiListArticles.refresh} />
              <div className="row">
                <div className="col-12">
                  <OrderArticleAddFromOrder order={apiCall.result} importArticle={setArticleImport} />
                </div>
                <div className="col-12">
                  <OrderArticleAddFromXlsx order={apiCall.result} refresh={refresh} />
                </div>
              </div>
            </>
          ) : (
            <div className="alert alert-danger">
              {apiCall.result.is_locked
                ? "This order is locked because a signing request is in process. You may not add articles anymore until the signing is finished"
                : "You are not allowed to add articles"}
            </div>
          )}
        </>
      ) : null}
    </>
  );
};
