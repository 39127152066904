import { SKUDerive } from "nestor/components/nestor/stock/skus/Derive";
import { SKUEditExpiration } from "nestor/components/nestor/stock/skus/EditExpiration";
import { NestorSku } from "nestor/types/stock/sku";

export const SkuEditPage = (props: { sku: NestorSku }) => {
  return (
    <div className="row">
      <div className="col-sm-4">
        <SKUEditExpiration sku={props.sku} />
      </div>

      <div className="col-sm-4">
        <SKUDerive sku={props.sku} />
      </div>
    </div>
  );
};
