import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

export const useSupplierList = (): apiList<NestorSupplier> => {
  return useNestorListApiCall<NestorSupplier>("purchasing/suppliers", "supplier");
};
export const useSupplierListQuality = (): apiList<NestorSupplier> => {
  return useNestorListApiCall<NestorSupplier>("purchasing/suppliers/list_quality", "supplier");
};
