// Hooks
import { PcbActionAddModal } from "nestor/components/nestor/manufacturing/pcb/modals/AddActionPcb";
//# Tables
//## Nestor
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { usePcbActionList } from "nestor/hooks/api/usePcbActionList";
import { NestorPcb, NestorPcbAttributeValue } from "nestor/types/manufacturing/pcb";
import { NestorPcbActionPcb } from "nestor/types/manufacturing/pcb_action";
import { CardComponent } from "@csem/shared-utils";
// Classes
// Components
// Contexts
import {
  DefaultStandardTableFilter,
  FilterableColumn,
  FilterProps,
  StandardTableFilterCtxProvider,
  TableFilterCtxProvider,
  TextFilterFactory,
} from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasRefresh } from "@csem/lists";
import { usePcbAttributeList } from "nestor/hooks/api/usePcbAttributeList";
import { useEffect, useMemo } from "react";
import { cloneDeep } from "lodash";
import React from "react";
import { usePcbAttributeField } from "./Edit";

const AttributeFilter = React.forwardRef<HTMLInputElement, FilterProps<string>>((props, ref) => {
  const field = usePcbAttributeField();

  useEffect(() => {
    props.onChange(field.value ? String(field.value) : undefined);
  }, [field.value]);

  return field.Content;
});

type T = NestorPcbAttributeValue;
type FWLst = { pcb: NestorPcb; filtered: boolean };
type FW = FWLst;
type HOOK = {};
const GenericPCBAttributeTable = (function () {
  return TableRenderer<T, FW, {}, HOOK, "id">({
    key: "id",
    columns: [
      FilterableColumn(
        SimpleColumn("name", "Name", (e) => e.attribute?.name),
        AttributeFilter,
        "attribute.name"
      ),
      SimpleColumn("value", "Value", (e) => e.value),
      EmployeeColumn("employee", "Modified by", "employee_id", (e) => e.employee),

      SimpleColumn("variant_before", "Current variant", (e) => e.variant?.value),
      SimpleColumn("revision_before", "Current revision", (e) => e.revision?.value),

      DateColumn("date", "Set on the", "created_at", (e) => e.created_at),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnVisibility: (k, fw) => {
      let o: any = {};
      if (fw.filtered) {
        o.name = false;
      } else {
        o.name = true;
      }
      return o;
    },
  });
})();

const GenericPCBActionListContainer = (function () {
  return HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>()));
})();

export const PCBAttributeListCard = (props: { head?: string; pcb: NestorPcb; attributeName?: string }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    if (props.attributeName) {
      defaultFilter.filter.filters[0].filters[0] = {
        field: "attribute.shorthand",
        operator: "eq",
        value: props.attributeName,
      };
    }
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, [props.attributeName]);

  return (
    <div className="m-2">
      <CardComponent header={props.head || "PCB attribute history"}>
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericPCBActionListContainer
            //cardHeader="Gateway list"
            list={usePcbAttributeList(props.pcb.id)}
            fwProps={{ pcb: props.pcb, filtered: !!props.attributeName }}
            listTemplate={GenericPCBAttributeTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
