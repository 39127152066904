import { ListFilter } from "@csem/lists";
import { NestorLocationFilter } from "../types/_internal/locationFilter";

const extendFilterByLocation = (filter: ListFilter, locationFilter: NestorLocationFilter) => {
  if (locationFilter.room_id) {
    filter.filter.filters.push({
      field: "location.room_id",
      operator: "eq",
      value: locationFilter.room_id,
    });
  } else if (locationFilter.laboratory_id) {
    filter.filter.filters.push({
      field: "location.laboratory_id",
      operator: "eq",
      value: locationFilter.laboratory_id,
    });
  } else if (locationFilter.container_id) {
    filter.filter.filters.push({
      field: "location.container_id",
      operator: "eq",
      value: locationFilter.container_id,
    });
  } else if (locationFilter.location_information) {
    filter.filter.filters.push({
      field: "location.information",
      operator: "eq",
      value: locationFilter.location_information,
    });
  }
};

export default extendFilterByLocation;
