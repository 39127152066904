import NestorServerRoutes from "nestor/server_routes";
import { NestorCalibrationRange } from "nestor/types/inventory/calibration_range";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorCalibrationRange;

export const useCalibrationRangeList = (deviceId: number): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.device_calibration_range_list(deviceId), "calibration range");
};
