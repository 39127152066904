import { CardComponent } from "@csem/shared-utils";
import moment from "moment";
import { NestorOrderFeedback } from "nestor/types/purchasing/order_feedback";
import { FeedbackStars } from "./modals/FeedbackAddEdit";

export const FeedbackCard = (props: { feedback: NestorOrderFeedback }) => {
  return (
    <CardComponent header="Feedback">
      <FeedbackDetails feedback={props.feedback} />
    </CardComponent>
  );
};

export const FeedbackDetails = (props: { feedback: NestorOrderFeedback }) => {
  const el = props.feedback;
  return (
    <>
      <div className="col-4">
        {el.grades?.map((g) => {
          return (
            <div className="row">
              <div className="col-7 text-muted text-end ">{g.category?.name}</div>
              <div className="col-5 text-muted ">
                <FeedbackStars grade={g.grade} />
              </div>
            </div>
          );
        })}
      </div>
      <div className="col-8">
        {el.employee ? <>By {el.employee?.fullname}&nbsp;</> : null}
        on the {moment(el.created_at).format("DD.MM.YYYY")}
        <div className="my-2 p-3 bg-secondary bg-opacity-10 render_lines">
          <div>Comment:</div>
          {el.comment ? el.comment : <div className="text-muted">No comment</div>}
          {el.grades?.map((g) => {
            return g.comment !== null ? (
              <div className="">
                <strong>Comment on {g.category!.name}</strong>
                <br />
                <small>{g.comment}</small>
              </div>
            ) : null;
          })}
        </div>
      </div>
    </>
  );
};
