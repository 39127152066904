import { NestorDeviceId } from "nestor/types/inventory/device";
import { NestorDeviceMaintenance } from "nestor/types/inventory/device_maintenance";
import { NestorDeviceMaintenanceAction } from "nestor/types/inventory/device_maintenance_actions";
import { NestorMaintenance } from "nestor/types/inventory/maintenance";
import { DefaultTableFilter } from "@csem/lists";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorMaintenance;
export const useMaintenanceList = (): apiList<T> => {
  return useNestorListApiCall<T>(`list/inventory/maintenances`, "maintenance");
};

export const useMaintenanceListWithDevice = (deviceId: NestorDeviceId): apiList<T> => {
  return useNestorListApiCall<T>(`list/inventory/devices/${deviceId}/maintenances`, "maintenance");
};

export const useMaintenanceListDevice = (deviceId: NestorDeviceId): apiList<NestorDeviceMaintenance> => {
  const sp = new URLSearchParams();
  sp.set("filtering", JSON.stringify(DefaultTableFilter));
  return useNestorListApiCall<NestorDeviceMaintenance>(
    `list/inventory/devices/${deviceId}/device_maintenances`,
    "maintenance",
    sp
  );
};

export const useMaintenanceActionListDevice = (deviceId: NestorDeviceId): apiList<NestorDeviceMaintenanceAction> => {
  return useNestorListApiCall<NestorDeviceMaintenanceAction>(
    `list/inventory/devices/${deviceId}/maintenance_actions`,
    "maintenance"
  );
};
