/*

export type NestorContainer = {
    nuid: NUID,
    id: NestorContainerId,
   
    section_id: NestorSectorId,
    section?: NestorSector,

    parent_container_id: NestorContainerId | null,
    parent_container?: NestorContainer,

    employee_id: NestorEmployeeId,
    employee?: NestorEmployee,

    location_id: NestorLocationId,
    location?: NestorLocation,

    revoked_at: NestorDateTimeString,
    created_at: NestorDateTimeString,
    updated_at: NestorDateTimeString
}
*/

import { useEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { extendLocationPayload, useLocationFields } from "nestor/components/forms/fields/LocationField";
import { useProjectSearchField } from "nestor/components/forms/fields/ProjectSearch";
import { useSectionField } from "nestor/components/forms/fields/SectorList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { lang } from "nestor/lang";
import { NestorContainer, NestorContainerType } from "nestor/types/stock/container";
import { CardComponent } from "@csem/shared-utils";
import { useFormCheckboxField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import React, { useEffect } from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import url from "url";

type FieldNames =
  | "name"
  | "description"
  | "project_id"
  | "is_sink"
  | "accepts_withdrawals"
  | "type"
  | "section"
  | "expired_components"
  | "employee_id";

export const useContainerFields = (container?: NestorContainer) => {
  const name = useFormTextField({
    label: "Name",
    type: FieldValueType.STRING,
    originalValue: container?.name,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(true),
  });

  const description = useFormTextField({
    label: "Description",
    type: FieldValueType.STRING,
    originalValue: container?.description,
    defaultValue: "",
  });

  const project_id = useProjectSearchField({
    label: "Project",
    defaultValue: undefined,
    originalValue: container?.project_id,
  });

  const section = useSectionField({
    label: lang.sector,
    defaultValue: undefined,
    originalValue: container?.section_id,
    validationOnChange: { required: true },
  });

  const is_sink = useFormCheckboxField({
    label: "No quantity tracking",
    defaultValue: false,
    originalValue: container ? !!container.is_sink : false,
  });

  const employee_id = useEmployeeSearchField({
    label: "Employee responsible",
    defaultValue: undefined,
    originalValue: container?.employee_id,
  });

  const accepts_withdrawals = useFormCheckboxField({
    label: "Accepts withdrawals from co-workers",
    defaultValue: false,
    originalValue: container ? !!container.accept_withdrawals : true,
  });

  const expired_components = useFormCheckboxField({
    label: "End-of-life container",
    helpText: "Components placed there will be marked as expired",
    defaultValue: false,
    disabled: !!container,
    originalValue: container ? !!container.expired_components : false,
  });

  const location_f = useLocationFields(container?.location, true);

  const type = useFormSelectObj({
    label: "Container type",
    options: {
      [NestorContainerType.STANDARD]: "Standard",
      [NestorContainerType.MEDICAL]: "Medical",
    },
    defaultValue: NestorContainerType.STANDARD,
    originalValue: container?.type,
    validationOnChange: { required: true },
  });

  return {
    name,
    description,
    project_id,
    section,
    is_sink,
    accepts_withdrawals,
    location_f,
    type,
    expired_components,
    employee_id,
  };
};

/**
 * A container create form, with logic.
 * Creating a new container redirects the user to the container information page.
 * Editing the container doesn't perform any redirection
 * @param props.container An optional container to edit, or undefined to create a new one
 * @returns The edition form without a wrapping card.
 */
export const ContainerEditCreateForm: React.FunctionComponent<{
  container?: NestorContainer;
}> = (props) => {
  const form = useContainerForm(props.container);
  const { container } = props;

  const route = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    if (form.out && form.out.response.id && !container) {
      const redirectURL = url.resolve(route.url, form.out.response.id + "");

      history.push(redirectURL);
    }
  }, [form.out, route.url, history, container]);

  return (
    <>
      <IOState source={form.state} />

      <div className="row">
        <div className="col-12 mb-2 d-flex ">
          <button className="btn btn-success btn-lg flex-grow-1" type="button" onClick={() => form.submit()}>
            Submit
          </button>
        </div>
      </div>

      {form.Content}
    </>
  );
};

export const useContainerForm = (container?: NestorContainer) => {
  const {
    name,
    description,
    project_id,
    section,
    is_sink,
    accepts_withdrawals,
    location_f,
    type,
    expired_components,
    employee_id,
  } = useContainerFields(container);

  const apiCall = useNestorAPI<{ response: { id?: number } }>(
    container ? `stock/containers/${container.id}/forms/update` : `stock/containers/forms/create`,

    false,
    undefined,
    { method: "POST", headers: { "Content-Type": "application/json" } }
  );

  const form = useForm({
    fields: {
      name,
      description,
      project_id,
      employee_id,
      is_sink,
      accepts_withdrawals,
      type,
      expired_components,
      section,
    },

    Template: FormContainerTemplate,
    fw: {
      locationSwitch: location_f.SwitchButtons,
      locationContent: location_f.Field,
    },

    onSubmit() {
      const payload: any = extendLocationPayload(
        {
          name: name.value,
          description: description.value,
          project_id: project_id.value,
          is_sink: is_sink.value,
          employee_id: container ? employee_id.value : undefined,
          accept_withdrawals: accepts_withdrawals.value,
          expired_components: expired_components.value,
          type: type.value,
          section_id: section.value,
          is_hallway: false,
        },
        location_f
      );

      if (container) {
        payload.id = container.id;
      }

      apiCall.doQuery(undefined, payload);
    },
  });

  return {
    Content: form.Content,
    out: apiCall.result,
    submit: () => {
      form.submit();
    },
    state: apiCall.state,
  };
};

export const FormContainerTemplate: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  submit: Function;

  locationSwitch: JSX.Element;
  locationContent: JSX.Element;
}> = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-6 mb-2">
          <CardComponent header="General information">
            {props.fields.name.Content}
            {props.fields.description.Content}
            {props.fields.project_id.Content}
            {props.fields.employee_id.Content}
            {props.fields.section.Content}
            {props.fields.type.Content}
            <div className="mt-2">{props.fields.accepts_withdrawals.Content}</div>
            <div className="mt-2">{props.fields.is_sink.Content}</div>
            <div className="mt-2"> {props.fields.expired_components.Content}</div>
          </CardComponent>
        </div>

        <div className="col-6 mb-2">
          <CardComponent header="Location">
            {props.locationSwitch}
            {props.locationContent}
          </CardComponent>
        </div>
      </div>
    </>
  );
};
