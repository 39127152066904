import { useFieldsDeviceModel } from "nestor/components/nestor/inventory/device_models/modals/AddEditDeviceModel";
import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useNestorAPI, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { CardComponent } from "@csem/shared-utils";
import { useFormFileField, useFormHasError } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import { useEffect } from "react";

export const DeviceModelEditForm: React.FunctionComponent<{
  device_model?: NestorDeviceModel;
}> = (props) => {
  const allowed = useHasPermission("can_edit"); // Can always create a new one

  if (allowed) {
    return <DeviceModelEditFormInner {...props} />;
  } else {
    return <div className="alert alert-danger">You are not allowed to edit this model</div>;
  }
};

export const DeviceModelEditFormInner: React.FunctionComponent<{
  device_model?: NestorDeviceModel;
}> = (props) => {
  const { category_id, device_type_id, manufacturer_id, model, characteristics, description, is_system } =
    useFieldsDeviceModel(props.device_model, undefined);

  const picture = useFormFileField({
    label: "Picture",
    defaultValue: undefined,
    originalValue: !!props.device_model?.picture || undefined,
  });

  const apiCall = useNestorAPIForms<{ response: { id?: number } }>(
    props.device_model
      ? `inventory/device_models/${props.device_model.id}/forms/update`
      : `inventory/device_models/forms/create`,

    false,
    undefined,
    { method: "POST" }
  );

  const form = useForm({
    fields: {
      category_id,
      device_type_id,
      manufacturer_id,
      model,
      characteristics,
      description,
      picture,
      is_system,
    },
    errors: apiCall.error,

    Template: FormTemplate,
    fw: {},

    onSubmit() {
      let formData = new FormData();

      //formData.append("category_id", category_id.value!.toString());
      formData.append("device_type_id", String(device_type_id.value));
      formData.append("device_manufacturer_id", String(manufacturer_id.value));
      formData.append("model", model.value);
      formData.append("description", description.value || "");
      formData.append("characteristic", characteristics.value);
      formData.append("is_system", is_system.value ? "1" : "0");

      if (picture.value instanceof File) {
        // Could be a boolean
        formData.append("file", picture.value);
      }
      apiCall.doQuery(undefined, formData);
    },
  });

  return (
    <>
      <IOState source={apiCall.state} />
      {form.Content}
    </>
  );
};

type FieldNames =
  | "category_id"
  | "device_type_id"
  | "manufacturer_id"
  | "model"
  | "characteristics"
  | "description"
  | "picture"
  | "is_system";

const FormTemplate: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;

  submit: Function;
}> = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-12 mb-2 d-flex ">
          <button className="btn btn-success btn-lg flex-grow-1" type="button" onClick={() => props.submit()}>
            Save device model
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-6 mb-2">
          <CardComponent header="General information" additionalClasses="h-100">
            <h6>Type of the item</h6>
            {props.fields.category_id.Content}
            {props.fields.device_type_id.Content}

            <h6>Model information</h6>
            {props.fields.manufacturer_id.Content}
            {props.fields.model.Content}
            {props.fields.description.Content}
            {props.fields.is_system.Content}
          </CardComponent>
        </div>
        <div className="col-6 mb-2">
          <CardComponent header="Additional information" additionalClasses="h-100">
            {props.fields.picture.Content}
            {props.fields.characteristics.Content}
          </CardComponent>
        </div>
      </div>
    </>
  );
};
