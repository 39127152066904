import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { extendLocationPayload, useLocationFields } from "../forms/fields/LocationField";
import { usePcbLayoutSearchField } from "../forms/fields/PcbLayoutSearch";
import { useSkuSearchField } from "../forms/fields/SkuSearch";
import {
  FormPCBAddEdit,
  NestorEditPcbFieldNames,
  useFormEditPcbFields,
} from "../nestor/manufacturing/pcb/modals/EditPcb";

export const PcbMassAddModal = StandardFormAddEditModalWithFormFactory<any, { layout?: NestorPcbLayout }, any>({
  title: (el, fw) => (fw.layout ? `Add multiple PCBs for layout ${fw.layout.name}` : `Add multiple PCBs`),
  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    return useFormAddMassPcb(el, fw);
  },
});

const useFormAddMassPcb = <FW extends { layout?: NestorPcbLayout }>(el: undefined, fw: FW) => {
  const layout = usePcbLayoutSearchField({
    label: "Layout",
    originalValue: fw.layout?.id,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const sku_id = useSkuSearchField({
    label: "Bare PCB SKU",
    defaultValue: undefined,
  });

  const number = useFormTextField({
    label: "How many new PCAs are you adding ?",
    originalValue: "",
    type: FieldValueType.NUMBER,
    defaultValue: "",
    validationOnChange: { required: true },
  });

  const { location_f, variant_f, revision_f, functional_test_f, visual_inspection_f } = useFormEditPcbFields(undefined);
  const apiResult = useNestorAPI(NestorServerRoutes.pcb_add(layout.value as number), false, undefined, NestorPOST);

  const form = useForm({
    onSubmit: () => {
      apiResult.doQuery(
        undefined,
        extendLocationPayload(
          {
            number: number.value,
            variant: variant_f.value,
            revision: revision_f.value,
            functional_test: functional_test_f.value,
            visual_inspection_f: visual_inspection_f.value,
            sku_id: sku_id.value,
          },
          location_f
        )
      );
    },
    fields: {
      number,
      sku_id,
      functional_test: functional_test_f,
      visual_inspection: visual_inspection_f,
      variant: variant_f,
      revision: revision_f,
    },
    Template: FormPCBMassAdd,
    fw: {
      el,
      locationSwitchButtons: location_f.SwitchButtons,
      locationField: location_f.Field,
    },
  });

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

type FieldNames = "number" | NestorEditPcbFieldNames;

export const FormPCBMassAdd: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  el: undefined;

  locationSwitchButtons: JSX.Element;
  locationField: JSX.Element;
}> = (props) => {
  return (
    <>
      {props.fields.number.Content}
      <FormPCBAddEdit {...props} locationString="Where are those PCBs located ?" />
    </>
  );
};
