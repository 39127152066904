import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorProjectId } from "nestor/types/projects/projects";
import { NestorProjectSupplier } from "nestor/types/projects/project_suppliers";
import { NestorSupplierId } from "nestor/types/purchasing/supplier";
import { ListFilter } from "@csem/lists";
import { uniq } from "lodash";
import { useEffect, useMemo } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { apiList } from "@csem/api";

const themes = {
  A: "danger",
  B: "warning",
  C: "info",
};
const CriticalityInformation = ({ el, project }: { project?: boolean; el: NestorProjectSupplier }) => {
  return (
    <li className={`list-group-item list-group-item-${themes[el.class]} `}>
      <div className="fw-bold">
        <div>Class {el.class}</div>
        {project && el.project ? (
          <div>
            Project {el.project.code} {el.project.name}
          </div>
        ) : null}
      </div>
      <div className="text-muted">{el.scope}</div>
      <small>By {el.employee?.fullname}</small>
    </li>
  );
};

const _filtering = ["filtering"];
export const useGetSupplierQualificationClasses = function (
  supplier_id: NestorSupplierId,
  project_id: NestorProjectId | null,
  includeOtherClassA: boolean = false
) {
  const { setSearchParams, ...api } = useNestorAPI<{ data: Array<NestorProjectSupplier> }>(
    NestorServerRoutes.suppliers.criticalities.project_list(),
    _filtering
  );

  useEffect(() => {
    console.log(supplier_id, project_id);
    if (supplier_id && project_id) {
      const f: ListFilter = {
        sort: [],
        take: 1000,
        skip: 0,
        filter: {
          logic: "or",
          filters: [
            {
              logic: "and",
              filters: [
                { field: "supplier_qualification.supplier_id", operator: "eq", value: supplier_id },
                { field: "project_id", operator: "eq", value: project_id },
              ],
            },
          ],
        },
      };

      if (includeOtherClassA) {
        f.filter.filters.push({
          logic: "and",
          filters: [
            { field: "supplier_id", operator: "eq", value: supplier_id },
            { field: "class", operator: "eq", value: "A" },
          ],
        });
      }

      const urlSearch = new URLSearchParams();
      urlSearch.set("filtering", JSON.stringify(f));
      setSearchParams(urlSearch);
    }
  }, [supplier_id, project_id, includeOtherClassA, setSearchParams]);

  return api;
};

export const SupplierQualificationButton = ({
  supplier_id,
  project_id,
}: {
  supplier_id: NestorSupplierId;
  project_id: NestorProjectId;
}) => {
  const api = useGetSupplierQualificationClasses(supplier_id, project_id, true);

  let listClasses = api.result?.data.filter((e) => e.project_id === project_id).map((e) => e.class) || [];
  listClasses.sort();
  listClasses = uniq(listClasses);

  const otherProjects = api.result?.data?.filter((el) => el.project_id !== project_id) || [];
  const thisProject = api.result?.data?.filter((el) => el.project_id === project_id) || [];

  const tooltop = api.result ? (
    <Popover>
      <Popover.Header>Criticality for this project</Popover.Header>
      <Popover.Body>
        <ul className="list-group">
          {thisProject.map((el) => (
            <CriticalityInformation el={el} />
          ))}

          <li className="list-group-item">Class A for other projects:</li>
          {otherProjects.map((el) => (
            <CriticalityInformation el={el} project={true} />
          ))}
        </ul>
      </Popover.Body>
    </Popover>
  ) : (
    <></>
  );

  return api.result?.data && api.result.data.length > 0 ? (
    <>
      <OverlayTrigger placement="right" trigger="focus" overlay={tooltop}>
        <button type="button" className="btn btn-warning h-100">
          Class {listClasses.length === 0 ? "?" : listClasses.join("/")}
        </button>
      </OverlayTrigger>
    </>
  ) : null;
};
