import { NestorRoom } from "nestor/types/company/room";
import { useFieldOptRequired } from "@csem/forms";
import { useBuildingField } from "../fields/BuildingList";
import { useFloorField } from "../fields/FloorList";

export const useBuildingFloorFields = (room?: NestorRoom, required?: boolean) => {
  const building_id = useBuildingField({
    label: "Building",
    originalValue: room?.floor?.building_id,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(!!required),
  });

  const floor_id = useFloorField({
    label: "Floor",
    buildingId: building_id.value,
    originalValue: room?.floor_id,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(!!required),
  });

  return {
    building_id,
    floor_id,
  };
};
