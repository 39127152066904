import { useContainerList } from "nestor/hooks/api/useContainerList";
import { NestorContainer } from "nestor/types/stock/container";
import { GenericListRenderer } from "@csem/forms";
import { SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { ContainerRenderer } from "../../renderers/Container";

type U = NestorContainer;

export const FilterByContainer = SearchElFilterFactory<U, U["id"]>({
  label: "Container",
  apiListFactory: useContainerList,
  traverser: (el) => el.id,
  filterKeys: {
    name: "contains",
    "project.name": "contains",
    "project.code": "eq",
    "employee.fullname": "contains",
    "employee.initials": "eq",
  },
  template: GenericListRenderer((e) => <ContainerRenderer container={e} />),
  key: "id",
});

export const ContainerColumn = <T,>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T) => U | undefined,
  withNav?: boolean
) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <ContainerRenderer container={renderer(el)} withNav={withNav} withLocationResolver={true} />;
    }),
    FilterByContainer,
    field
  );
};
