import { useSupplierList } from "nestor/hooks/api/useSupplierList";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { GenericListRenderer } from "@csem/forms";
import { SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { SupplierRenderer } from "../../renderers/SupplierRenderer";

export const SupplierColumn = <T,>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T) => NestorSupplier | null | undefined
) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <SupplierRenderer supplier={renderer(el)} />;
    }),
    SearchElFilterFactory<NestorSupplier, NestorSupplier["id"]>({
      label: "Supplier",
      apiListFactory: useSupplierList,
      traverser: (el) => el.id,
      filterKeys: {
        name: "contains",
      },
      template: GenericListRenderer((e) => <SupplierRenderer supplier={e} />),
      key: "id",
    }),
    field
  );
};
