import { NestorContainer } from "nestor/types/stock/container";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import * as def from "@csem/shared-utils";
import { useContainerForm } from "../Edit";
export const ContainerQuickAddEditModal = StandardFormAddEditModalWithFormFactory<NestorContainer, any, any>({
  title: (el) => (!el ? "Add a new container" : `Edit the container ${el.name}`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    return useContainerForm(el);
  },
});
