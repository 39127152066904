import { useFormEditPcb } from "nestor/components/nestor/manufacturing/pcb/modals/EditPcb";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorPcbAttribute } from "nestor/types/manufacturing/pcb_attribute";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useFormSelectRemoteField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useForm } from "@csem/forms";
import { FormTemplateDefault } from "@csem/forms";
import { IOState } from "@csem/api";
import { useEffect } from "react";

export const PCBEditCard = ({ pcb, refresh }: { pcb: NestorPcb; refresh: Function }) => {
  assert(pcb.layout);
  const form = useFormEditPcb(pcb, { layout: pcb.layout });

  useEffect(() => {
    if (form.state.success) {
      refresh();
    }
  }, [form.state.success, refresh]);
  return (
    <CardComponent header="Edit PCA" additionalClasses="h-100">
      <IOState source={form.state} />
      {form.Content}
      <button type="button" className="btn btn-success w-100 my-2" onClick={() => form.submit()}>
        Submit
      </button>
    </CardComponent>
  );
};

export const usePcbAttributeField = function () {
  const attributeField = useFormSelectRemoteField<NestorPcbAttribute, any>({
    defaultValue: undefined,
    label: "Attribute",
    useApiHook: useNestorAPI,
    url: "manufacturing/pcb/attributes",
    keyMap: (el) => el.shorthand,
    valMap: (el) => el.name,
    getterFromKey: (k, listOf) => {
      return listOf?.find((e) => e.shorthand === k);
    },
  });

  return attributeField;
};

export const PCBAddAttributeCard = ({ pcb, refresh }: { pcb: NestorPcb; refresh: Function }) => {
  assert(pcb.layout);

  const value = useFormTextField({
    defaultValue: "",
    type: FieldValueType.STRING,
    label: "Value",
  });

  const attributeField = usePcbAttributeField();

  const api = useNestorAPI(`manufacturing/pcbs/${pcb.id}/forms/attribute`, false, undefined, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  const form = useForm({
    onSubmit: () => {
      api.doQuery(undefined, { attribute: attributeField.value, value: value.value });
    },
    fields: {
      attribute: attributeField,
      value,
    },
    fw: {},
    Template: FormTemplateDefault,
  });

  useEffect(() => {
    if (api.state.success) {
      refresh();
    }
  }, [api.state.success, refresh]);

  return (
    <CardComponent header="Set PCA attribute" additionalClasses="h-100">
      <IOState source={api.state} />
      <button type="button" className="btn btn-success w-100 my-2" onClick={() => form.submit()}>
        Submit
      </button>
      {form.Content}
    </CardComponent>
  );
};
