import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceType } from "nestor/types/inventory/device_type";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useDeviceCatTypeFields } from "../forms/bundles/DeviceCategoryType";

export const DeviceTypeMergeModal = StandardFormAddEditModalFactory<NestorDeviceType, any, any>({
  title: (el) => (!el ? "" : `Merge the type ${el.name} into another one`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const { category_id, device_type_id } = useDeviceCatTypeFields();

    const apiResult = useNestorAPI(!el ? undefined : `inventory/types/${el.id}/merge`, false, undefined, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    const cb = () => {
      apiResult.doQuery(undefined, {
        device_type_id: device_type_id.value,
      });
    };

    return {
      fields: {
        category_id,
        device_type_id,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
