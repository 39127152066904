import { useSkuSearchField } from "nestor/components/forms/fields/SkuSearch";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssemblyFeederSet, NestorPcbComponentMountType } from "nestor/types/manufacturing/pcb_assembly";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useSkuShortcutSearchField } from "../../../../forms/fields/SkuShortcutSearch";

export const PCBAssemblyFeederSetAddEditModal = StandardFormAddEditModalFactory<NestorPcbAssemblyFeederSet, any, any>({
  title: (el, fw) => (el ? `Edit the feeder set ${el.name} ` : `Add a new assembly`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const mode = useFormSelectObj({
      label: "Mode",
      options: {
        [NestorPcbComponentMountType.AUTO]: "Automatic PnP",
        [NestorPcbComponentMountType.SEMIAUTO]: "Semi-automatic PnP",
      },
      defaultValue: undefined,
      originalValue: el?.mode,
    });
    /*
    const sku_shortcut_id = useSkuSearchField({
      label: "SKU ",
      originalValue: el?.sku_id,
      defaultValue: undefined,
    });
*/
    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.pcb_assembly_feeder_set_create()
        : NestorServerRoutes.pcb_assembly_feeder_set_update(el.id),
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        mode: mode.value,
        //   sku_shortcut_id: sku_shortcut_id.value,
      });
    };

    return {
      fields: {
        name,
        mode,
        //     sku_shortcut_id,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
