import { NestorCalibrationRange } from "nestor/types/inventory/calibration_range";

/**
 * Shows a calibration range with its name and description
 * @param props
 * @param props.range The calibration range to display
 * @returns A interaction-less React fragment that shows the calibration range name and description
 */
export const CalibrationRangeRenderer = (props: { range: NestorCalibrationRange }) => {
  return (
    <>
      <div className="fw-bold">{props.range.name}</div> <div className="text-muted">{props.range.description}</div>
    </>
  );
};
