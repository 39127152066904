import { OrderListCard, orders_draft, orders_open } from "nestor/components/nestor/purchasing/orders/List";
import { useMe } from "nestor/hooks/me";
import { NestorProject } from "nestor/types/projects/projects";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { OrderFeedbackListCard } from "nestor/components/nestor/purchasing/orders/feedbacks/List";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { OrdersFeedbackDetails } from "./OrderFeedbackDetails";

function computeProjectUrl(url: string, project: NestorProject) {
  return url + "/project/" + project.code;
}

/*
const OrdersByProjects = ({ projects }: { projects: NestorProject[] }) => {
  const { url } = useRouteMatch();

  return (
    <>
      {projects.map((p) => {
        return <OrderByProject key={p.code} project={p} />;
      })}
      <Switch>
        <Route path={url + "/project/:code"}>
          <OrdersListByProject></OrdersListByProject>
        </Route>
      </Switch>
    </>
  );
};
*/
export function OrderFeedbacks(props: { order: NestorOrder }) {
  const { url } = useRouteMatch();
  const route = useRouteMatch<{ order: string }>();

  const list = useMenuElement("List", url + "/list");
  //const menu = useMenuElement(props.order. || "Loading...", `Order ${route.params.order}`);

  return (
    <>
      <Switch>
        <Route path={`${route.url}/list`}>
          <OrderFeedbackListCard order={props.order}></OrderFeedbackListCard>
        </Route>
        <Route path={`${route.url}/:feedback`}>
          <OrdersFeedbackDetails order={props.order}></OrdersFeedbackDetails>
        </Route>
        <Route>
          <Redirect to={`${route.url}/list`} />
        </Route>
      </Switch>
    </>
  );
}
