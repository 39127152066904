import { useFormSelectObj } from '@csem/forms';
import { FieldValueType, useFormTextField } from '@csem/forms';
import { useFieldOptRequired } from '@csem/forms';
import { useForm } from '@csem/forms';
import { Field } from '@csem/forms';
import { StandardFormAddEditModalWithFormFactory } from '@csem/shared-utils';
import assert from 'assert';
import { useCalibrationConfirmationSingle } from 'nestor/hooks/api/useCalibrationConfirmation';
import { postJSON, useNestorAPI } from 'nestor/hooks/api/useNestorAPI';
import { NestorCalibration } from 'nestor/types/inventory/calibration';
import { NestorDevice } from 'nestor/types/inventory/device';

export const CalibrationConfirmationModal = StandardFormAddEditModalWithFormFactory<
  NestorCalibration,
  { device: NestorDevice },
  any
>({
  title: (el) => `Confirm the calibration`,
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    return useFormCalibrationConfirmation(el);
  },
});

const useFormCalibrationConfirmation = (el: NestorCalibration | undefined) => {
  assert(el);
  // Load the current confirmation, if any
  const currentConfirmation = useCalibrationConfirmationSingle(el.id);

  // API call to revoke the current confirmation
  const apiRevokeCurrentConfirmation = useNestorAPI(
    currentConfirmation.element
      ? `inventory/calibrations/${el.id}/forms/metrological_record/${currentConfirmation.element.id}/revoke`
      : undefined,
    false,
    undefined,
    postJSON
  );

  const apiConfirmation = useNestorAPI(
    `inventory/calibrations/${el.id}/forms/metrological_record/create`,
    false,
    undefined,
    postJSON
  );

  const revoking_comment = useFormTextField({
    defaultValue: "",
    label: "Why are you revoking the current confirmation ?",
    type: FieldValueType.STRING,
  });

  const confirmation_range = useFormTextField({
    defaultValue: "",
    label: "Which range are you confirming ?",
    type: FieldValueType.STRING,
  });

  const limitations = useFormTextField({
    defaultValue: "",
    label: "Are there known limitations ?",
    type: FieldValueType.STRING,
  });

  const comment = useFormTextField({
    defaultValue: "",
    label: "Additional comment",
    type: FieldValueType.STRING,
  });

  const passed = useFormSelectObj({
    defaultValue: "",
    label: "Additional comment",
    options: {
      0: "Not passed",
      1: "Passed",
    },
    validationOnChange: useFieldOptRequired(true),
  });

  const form = useForm({
    onSubmit: async () => {
      // We need to revoke the current confirmation
      if (currentConfirmation.element) {
        // First revoke the current confirmation
        await apiRevokeCurrentConfirmation.doQuery(undefined, {
          revoked_comment: revoking_comment.value,
        });
      }

      // Because the field is required
      assert(typeof passed.value == "string");

      // ... then, create a new one
      let out = {
        confirmation_range: confirmation_range.value,
        limitations: limitations.value,
        comment: comment.value,
        passed: parseInt(passed.value),
      };

      apiConfirmation.doQuery(undefined, out);
    },
    fields: {
      revoking_comment,
      confirmation_range,
      limitations,
      comment,
      passed,
    },
    Template: FormConfirmation,
    fw: {
      needs_revocation: !!currentConfirmation.element,
    },
  });

  const Content = currentConfirmation.state?.success === false ? currentConfirmation.loadingIO : form.Content;
  return {
    Content: Content,
    out: apiConfirmation.result,
    submit: () => {
      form.submit();
    },
    state: apiConfirmation.state,
  };
};

type FieldNames = "revoking_comment" | "confirmation_range" | "limitations" | "comment" | "passed";

const FormConfirmation: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  needs_revocation: boolean;
}> = (props) => {
  return (
    <>
      {props.needs_revocation ? (
        <>
          <h6>Previous confirmation</h6>
          <div className="alert alert-info">
            This calibration already received a metrological confirmation. Submitting this form will revoke it. Please
            comment on why it needs revoking
          </div>
          {props.fields.revoking_comment.Content}
          <h6>New confirmation</h6>
        </>
      ) : null}

      {props.fields.passed.Content}
      {props.fields.confirmation_range.Content}
      {props.fields.comment.Content}
      {props.fields.limitations.Content}
    </>
  );
};
