import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";
import { NestorPcbAttribute } from "nestor/types/manufacturing/pcb_attribute";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAttributeValue, NestorPcbId } from "nestor/types/manufacturing/pcb";

type T = NestorPcbAttributeValue;
export const usePcbAttributeList = (pcbId: NestorPcbId): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.pcb_attributes(pcbId), "pcb layout");
};
