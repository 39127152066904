import { CalibrationInfos } from "nestor/components/nestor/inventory/devices/calibrations/Info";
import { MetrologicalChecksCard } from "nestor/components/nestor/inventory/devices/calibrations/MetrologicalChecks";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { useCalibrationSingle } from "nestor/hooks/api/useCalibrationList";
import { NestorCalibration } from "nestor/types/inventory/calibration";
import { NestorDevice } from "nestor/types/inventory/device";
import { useMenuElement } from "@csem/shared-utils";
import { Route, Switch, useRouteMatch } from "react-router";

const MetrologicalCheckDetails: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ metrological_check: string }>();
  return <DocumentListCard type="metrological_check" id={route.params.metrological_check} />;
};

const CalibrationDetailsInner: React.FunctionComponent<{
  device: NestorDevice;
  calibration: NestorCalibration;
  refresh: Function;
}> = (props) => {
  const route = useRouteMatch<{ device: string }>();

  return (
    <>
      <CalibrationInfos calibration={props.calibration} />
      <DocumentListCard type="calibration" id={props.calibration.id} />

      <h4 className="mt-4">Metrological checks for this calibration</h4>
      <Switch>
        <Route path={`${route.url}/metrological_check/:metrological_check`}>
          <MetrologicalCheckDetails />
        </Route>
        <Route>
          <div className="alert alert-info">
            To view the metrological check documents, click on the <em className="bi bi-search"></em> icon in the
            following table
          </div>
        </Route>
      </Switch>
      <MetrologicalChecksCard calibration={props.calibration} />
    </>
  );
};

export const CalibrationDetails: React.FunctionComponent<{ device: NestorDevice }> = (props) => {
  const route = useRouteMatch<{ calibration: string }>();
  const calibration = useCalibrationSingle(parseInt(route.params.calibration));
  useMenuElement(calibration.element ? "Calibration details" : "Loading...", route.url);

  return (
    <>
      {calibration.loadingIO}
      <div className="container-fluid p-3">
        {props.device && calibration.element ? (
          <CalibrationDetailsInner
            device={props.device}
            calibration={calibration.element}
            refresh={calibration.refresh}
          />
        ) : null}
      </div>
    </>
  );
};
