import { NestorCurrency, NestorCurrencyName } from "nestor/types/util/currency";
import assert from "assert";

new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" });

const currencyFormatters: Record<string, Intl.NumberFormat> = {};

for (let currency in NestorCurrencyName) {
  currencyFormatters[currency] = new Intl.NumberFormat("ch-EN", {
    style: "currency",
    currency,
  });
}
export const MonetaryAmountRenderer = (
  value: number,
  currency: NestorCurrency | null | undefined,
  fallbackMessage: string = "Unknown currency"
) => {
  if (!currency) {
    return fallbackMessage;
  }

  if (!currencyFormatters.hasOwnProperty(currency.name)) {
    return "Unable to format currency " + currency.name;
  }

  return currencyFormatters[currency.name].format(value);
};

export const MonetaryAmount: React.FunctionComponent<{
  value: number | null;
  currency?: NestorCurrency | null;
  fallback?: number | string;
}> = ({ fallback, ...props }) => {
  let value = props.value;
  if (!props.value) {
    if (typeof fallback == "string") {
      return <>{fallback}</>;
    } else if (typeof fallback == "number") {
      value = fallback;
    } else {
      return <></>;
    }
  }

  assert(value !== null);

  if (!props.currency) {
    return (
      <>
        {value} <span className="text-warning">(no currency)</span>
      </>
    );
  }
  return <>{MonetaryAmountRenderer(value, props.currency)}</>;
};
