import { PCBAddAttributeCard, PCBEditCard } from "nestor/components/nestor/manufacturing/pcb/pcbs/Edit";
import { PCBInfoCard } from "nestor/components/nestor/manufacturing/pcb/pcbs/Info";
import { NestorPcb } from "nestor/types/manufacturing/pcb";

export const PCBInfo = (props: { pcb: NestorPcb; refresh: Function }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <PCBInfoCard {...props} />
        </div>
        <div className="col-md-4">
          <PCBEditCard {...props} />
        </div>
        <div className="col-md-4">
          <PCBAddAttributeCard {...props} />
        </div>
      </div>
    </>
  );
};
