import { NestorDevice } from "nestor/types/inventory/device";
import { CalibrationRangesCard } from "./CalibrationRanges";
import { CalibrationCard } from "./Calibrations";

export const DeviceCalibrations = (props: { device: NestorDevice }) => {
  return (
    <>
      <CalibrationRangesCard device={props.device} />
      <CalibrationCard device={props.device} />
    </>
  );
};
