import React, { useCallback, useState } from "react";
import { NestorContainer } from "../types/stock/container";
import { NestorSku } from "../types/stock/sku";

type M = Map<string, { container: NestorContainer; sku: NestorSku; value: number }>;
export const NestorSkuTransferContext = React.createContext<
  | undefined
  | {
      empty: Function;
      store: M;

      toggleTransferValue: (container: NestorContainer, sku: NestorSku, value: number) => void;
      setTransferValue: (container: NestorContainer, sku: NestorSku, value: number) => void;
    }
>(undefined);

// Import to index by SKU id, because new objects are created across table refreshs

export const NestorSkuTransferContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [transferStore, updateTransferStore] = useState<M>(new Map());

  const empty = useCallback(() => {
    updateTransferStore(new Map());
  }, []);
  const setTransferValue = useCallback((container: NestorContainer, sku: NestorSku, value: number) => {
    updateTransferStore((w) => {
      let w2 = new Map(w);
      const id = `${container.id}_${sku.id}`;
      if (value === 0) {
        w2.delete(id);
      } else {
        w2.set(id, { container, sku, value });
      }

      return w2;
    });
  }, []);

  const toggleTransferValue = useCallback((container: NestorContainer, sku: NestorSku, value: number) => {
    updateTransferStore((w) => {
      let w2 = new Map(w);
      const id = `${container.id}_${sku.id}`;

      if (w2.has(id)) {
        w2.delete(id);
      } else {
        w2.set(id, { container, sku, value });
      }

      return w2;
    });
  }, []);

  return (
    <NestorSkuTransferContext.Provider value={{ empty, store: transferStore, setTransferValue, toggleTransferValue }}>
      {props.children}
    </NestorSkuTransferContext.Provider>
  );
};
