// Hooks

// Classes

// Components

// Contexts
import { useFormRoleField } from "nestor/components/forms/fields/RoleList";
import { EditEmployeeModal } from "nestor/components/nestor/company/employees/modals/Edit";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { useEmployeeList } from "nestor/hooks/api/useEmployeeList";
import { useAmIElevated } from "nestor/hooks/me";
import { NestorEmployee } from "nestor/types/company/employee";
import { CardComponent } from "@csem/shared-utils";
import { HasSearch, StandardTableFilterCtxProvider, TableSearch } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// ## Generic
import { ButtonsColumn, FilterableColumn, SimpleColumn, TableRenderer, useTableFilterChanged } from "@csem/lists";
import { EditMethod, GenericListContent, HasEdition, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useEffect, useMemo } from "react";
//# Tables
//## Nestor
import { DateColumn } from "../../../tables/columns/DateColumn";
import { EmployeeBadge } from "./EmployeeBadge";

type T = NestorEmployee;
type FW = { isElevated: boolean };
type FWLst = EditMethod<T, FW>;
const GenericEmployeeTable = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      NUIDColumn,
      FilterableColumn(
        SimpleColumn("fullname", "Name", (e) => e.fullname),
        TextFilterFactory({ label: "Name" }),
        "fullname"
      ),

      FilterableColumn(
        SimpleColumn("initials", "Initials", (e) => e.initials),
        TextFilterFactory({ label: "Initials" }),
        "initials"
      ),
      SimpleColumn("photo", "", (e) => <EmployeeBadge employee={e} />),

      SimpleColumn("section", "Section", (e) => e.section_id),
      SimpleColumn("phone", "Phone", (e) => e.phone),

      DateColumn("created_at", "Hired on the", "hired_at", (e) => e.hired_at),
      DateColumn("left_at", "Left on the", "quit_at", (e) => e.quit_at),

      ButtonsColumn("actions", "", [
        {
          title: "Edit",
          theme: "info",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.edit(e, fw);
          },
        },
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnVisibility: (cols, fw) => {
      if (fw.isElevated) {
        return { actions: true };
      } else {
        return { actions: false };
      }
    },
  });
})();

const GenericEmployeeListContainer = (function () {
  return HasSearch(
    HasEdition(HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())), EditEmployeeModal),
    {
      fullname: "contains",
      initials: "contains",
      firstname: "contains",
      lastname: "contains",
      "section.id": "eq",
    },
    "or",
    "",
    TableSearch
  );
})();

export const EmployeeListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "asc",
        field: "initials",
      },
    ];

    return defaultFilter;
  }, []);

  return (
    <div className="m-2">
      <CardComponent header="Employee list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <EmployeeListInner></EmployeeListInner>
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

const EmployeeListInner = () => {
  const isElevated = useAmIElevated();

  const filterByGrant = useFormRoleField();
  const { value: grantValue } = filterByGrant;
  const cb = useTableFilterChanged("roles.id");
  useEffect(() => {
    cb(grantValue, "eq");
  }, [grantValue, cb]);

  return (
    <>
      {filterByGrant.Content}
      <GenericEmployeeListContainer
        //cardHeader="Gateway list"
        list={useEmployeeList()}
        fwProps={{ isElevated }}
        listTemplate={GenericEmployeeTable}
        additionalContent={[]}
        righthandContent={[]}
        lefthandContent={[]}
        bottomContent={[]}
      />
    </>
  );
};
