import { EmployeeRenderer } from "nestor/components/renderers/EmployeeInfos";
import { useOrderDetails } from "nestor/hooks/api/useOrderSingle";
import { lang } from "nestor/lang";
import { NestorSector, NestorSectorId } from "nestor/types/company/sector";
import { NestorProject, NestorProjectId } from "nestor/types/projects/projects";
import { NestorArticle } from "nestor/types/purchasing/article";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { WithRelation } from "nestor/types/util/relations";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { IOState } from "@csem/api";
import { GenericListContent } from "@csem/lists";
import { MonetaryAmount, MonetaryAmountRenderer } from "../../util/MonetaryAmountRenderer";
import { showArticleDescription } from "./ArticleTable";

type T = NestorArticle;

const ListContainer = GenericListContent<{ order: NestorOrder }, T>();

const ArticleTable = (function () {
  return TableRenderer<T, { order: NestorOrder }, {}, { total: number }, "id">({
    key: "id",
    columns: [
      SimpleColumn("ref", "Ref", (e) => e.reference),
      SimpleColumn("comment", "Comment", (e) => e.comment),
      SimpleColumn("description", "Description", (e) => showArticleDescription(e)),
      SimpleColumn("class", "Class", (e) => e.class),
      SimpleColumn("employee", "Ordered by", (e) => <EmployeeRenderer employee={e.employee} />),
      SimpleColumn("wp", "Workpackage", (e, { order }) => {
        return `${e.workpackage?.workpackage} ${e.workpackage?.name}`;
      }),
      SimpleColumn("unit_price", "Unit price", (e, { order }) => {
        if (e.administrative) return <div className="d-block badge bg-danger">administrative</div>;
        return MonetaryAmountRenderer(e.price, order.currency, "No currency");
      }),
      SimpleColumn("discount", "Discount", (e, { order }) => {
        let txt: Array<string> = [];
        if (e.discount_percentage) {
          txt.push("-" + e.discount_percentage + "%");
        }

        if (e.discount > 0) {
          txt.push(MonetaryAmountRenderer(e.discount, order.currency, "??"));
        }

        return txt.join(", ");
      }),
      SimpleColumn("price_net", "Price without tax", (e, { order }, { total }) => {
        return MonetaryAmountRenderer(total, order.currency, "No currency");
      }),
      SimpleColumn("qty_o", "Qty ordered", (e) => e.quantity_ordered),
      SimpleColumn("qty_r", "Qty received", (e) => e.quantity_accounted),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {
        total: el.price * el.quantity_ordered * (1 - (el.discount_percentage || 0) / 100) - el.discount,
      };
    },
  });
})();

const FakeAPI = <T,>(els: T[]) => {
  return {
    list: els,
    pagination: {
      page: 1,
      pageSize: els.length,
      count: els.length,
    },

    loadingIO: <></>,
    refresh: () => {
      return Promise.resolve();
    },

    setSearchParams: () => {},
  };
};

const OrderArticles: React.FunctionComponent<{
  order: WithRelation<NestorOrder, { articles: T[] }>;
  showArticleTables?: boolean;
}> = (props) => {
  const order = props.order;
  const articles = order.articles;

  let articlesByProject: {
    [x: NestorProjectId]: {
      project: NestorProject;
      articles: T[];
      subtotal: number;
    };
  } = {};

  let articlesBySector: {
    [x: NestorSectorId]: {
      sector: NestorSector;
      subtotal: number;
      articles: T[];
    };
  } = {};

  let articlesLegacy: {
    articles: T[];
    subtotal: number;
  } = {
    articles: [],
    subtotal: 0,
  };

  let costs_projects: {
    [projCode: string]: {
      name: string;
      wps: {
        [wp: string]: {
          [cost_element: string]: number;
        };
      };
    };
  } = {};

  let costs_sectors: {
    [projCode: string]: {
      [cost_element: string]: number;
    };
  } = {};

  if (articles.length === 0) {
    return <div className="alert alert-info">No articles have been to this order yet</div>;
  }
  articles.forEach((article) => {
    const articlePrice =
      article.quantity_ordered * article.price * (1 - (article.discount_percentage || 0) / 100) -
      (article.discount || 0);
    let project, sector, wp, cost_element;

    // The article defaults to the order
    if (!article.project_id && !article.section_id) {
      // But it's a sector order
      if (order.section != null) {
        sector = order.section;
      } else {
        project = order.project;
        wp = order.workpackage;
      }
      cost_element = order.cost_element_id;
    } else if (article.project) {
      project = article.project;
      wp = article.workpackage;
      cost_element = article.cost_element_id || "N/A";
    } else {
      sector = article.sector;
      cost_element = article.cost_element_id || "N/A";
    }

    // Project / WP
    if (project) {
      articlesByProject[project.id] = articlesByProject[project.id] || {
        project,
        articles: [],
        subtotal: 0,
      };

      const projStore = articlesByProject[project.id];

      if (wp) {
        article.workpackage = wp;
      }
      projStore.articles.push(article);
      projStore.subtotal += articlePrice;

      costs_projects[project.code] = costs_projects[project.code] || {
        wps: {},
        name: project.name,
      };

      if (!wp) {
        wp = {
          workpackage: "N/A",
        };
      }

      costs_projects[project.code].wps[wp.workpackage] = costs_projects[project.code].wps[wp.workpackage] || {};
      costs_projects[project.code].wps[wp.workpackage][cost_element] =
        costs_projects[project.code].wps[wp.workpackage][cost_element] || 0;
      costs_projects[project.code].wps[wp.workpackage][cost_element] += articlePrice;
    } else if (sector) {
      // Sector articles
      articlesBySector[sector.id] = articlesBySector[sector.id] || {
        sector,
        articles: [],
        subtotal: 0,
      };
      articlesBySector[sector.id].articles.push(article);
      articlesBySector[sector.id].subtotal += articlePrice;

      costs_sectors[sector.id] = costs_sectors[sector.id] || {};
      costs_sectors[sector.id][cost_element] = costs_sectors[sector.id][cost_element] || 0;
      costs_sectors[sector.id][cost_element] += articlePrice;
    } else {
      // Legacy articles (not assigned to a sector, not assigned to a project)
      articlesLegacy.articles.push(article);
      articlesLegacy.subtotal += articlePrice;
    }
  });

  return (
    <>
      {props.showArticleTables ? (
        <>
          {Object.values(articlesByProject).map((v) => {
            return (
              <div className="mb-3">
                <CardComponent header={v.project.name + " " + v.project.code}>
                  <ListContainer fwProps={{ order: order }} listTemplate={ArticleTable} list={FakeAPI(v.articles)} />
                </CardComponent>
              </div>
            );
          })}

          {Object.values(articlesBySector).map((v) => {
            return (
              <div className="mb-3">
                <CardComponent header={v.sector.id + " " + v.sector.name}>
                  <ListContainer fwProps={{ order: order }} listTemplate={ArticleTable} list={FakeAPI(v.articles)} />
                </CardComponent>
              </div>
            );
          })}

          {articlesLegacy.articles.length > 0 ? (
            <CardComponent header="Legacy articles (Nestor 1)">
              <ListContainer
                fwProps={{ order: order }}
                listTemplate={ArticleTable}
                list={FakeAPI(articlesLegacy.articles)}
              />
            </CardComponent>
          ) : null}
        </>
      ) : null}

      <CardComponent header="Financials">
        <table className="table">
          <thead>
            <tr>
              <th>Project / {lang.cost_center}</th>
              <th></th>
              <th>WP</th>
              <th>{lang.cost_element}</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(costs_projects).map(([code, prjContent]) => {
              return Object.entries(prjContent.wps).map(([wp, content]) => {
                return Object.entries(content).map(([cost_element, total]) => (
                  <tr>
                    <td>{code}</td>
                    <td>{prjContent.name || ""}</td>
                    <td>{wp}</td>
                    <td>{cost_element || ""}</td>
                    <td>{MonetaryAmountRenderer(total, order.currency)}</td>
                  </tr>
                ));
              });
            })}

            {Object.entries(costs_sectors).map(([code, content]) => {
              return Object.entries(content).map(([cost_element, total]) => (
                <tr>
                  <td>{code}</td>
                  <td></td>
                  <td></td>
                  <td>{cost_element || ""}</td>
                  <td>{MonetaryAmountRenderer(total, order.currency)}</td>
                </tr>
              ));
            })}
          </tbody>

          <tbody>
            <tr>
              <th colSpan={4}>Subtotal</th>
              <th>
                <MonetaryAmount value={order.subtotal} currency={order.currency} />
              </th>
            </tr>
            <tr>
              <th colSpan={4}>Shipping costs</th>
              <th>
                <MonetaryAmount value={order.shipping_cost} fallback={0} currency={order.currency} />
              </th>
            </tr>
            <tr>
              <th colSpan={4}>Other costs</th>
              <th>
                <MonetaryAmount value={order.other_cost} fallback={0} currency={order.currency} />
              </th>
            </tr>
            <tr>
              <th colSpan={4}>Other discount</th>
              <th>
                <MonetaryAmount value={order.discount} fallback={0} currency={order.currency} />
              </th>
            </tr>
            <tr>
              <th colSpan={4}>Total</th>
              <th>
                <MonetaryAmount value={order.total} currency={order.currency} />
              </th>
            </tr>
          </tbody>
        </table>
      </CardComponent>
    </>
  );
};

export const OrderArticleListInfo: React.FunctionComponent<{
  orderId: number;
  showArticleTables: boolean;
}> = (props) => {
  const apiCall = useOrderDetails(props.orderId);

  return (
    <>
      <IOState source={apiCall.state} success={null} />
      {apiCall.result ? <OrderArticles showArticleTables={props.showArticleTables} order={apiCall.result} /> : null}
    </>
  );
};
