
export type NestorCurrencyId = number;
export type NestorMonetaryValue = number;

export enum NestorCurrencyName {
    CHF = "CHF",
    EUR = "EUR",
    GBP = "GBP",
    USD = "USD",
    CAD = "CAD",
    DKK = "DKK",
    RMB = "RMB",
    SEK = "SEK",
    DEM = "DEM",
    JPY = "JPY"
}

export type NestorCurrency = {
    id: NestorCurrencyId,
    name: NestorCurrencyName
}
