import { NestorEmployeeId, NestorEmployee } from "../company/employee";
import { NestorAttachment, NestorAttachmentId } from "../system/attachment";
import { NestorCurrency, NestorCurrencyId, NestorMonetaryValue } from "../util/currency";
import { NestorDateTimeString } from "../util/dates";
import { NestorOrder, NestorOrderId } from "./orders";

export enum NestorInvoiceType {
    PARTIAL = 'partial',
    FULL = 'full',
    FINAL = 'final'

}
export type NestorInvoiceId = number;

export type NestorInvoice = {
    id: NestorInvoiceId,
    order_id: NestorOrderId,
    order?: NestorOrder,

    currency_id: NestorCurrencyId,
    currency?: NestorCurrency,

    amount: NestorMonetaryValue,
    comment: string,
    type: NestorInvoiceType,
    employee_id: NestorEmployeeId,
    employee?: NestorEmployee,
    attachment_id: NestorAttachmentId,
    attachment?: NestorAttachment,

    revoked_at: NestorDateTimeString,
    updated_at: NestorDateTimeString,
    created_at: NestorDateTimeString

}