import NestorServerRoutes from "nestor/server_routes";
import { NestorContainer } from "nestor/types/stock/container";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorContainer;
export const useContainerList = (props?: { me?: boolean }): apiList<T> => {
  const url = new URLSearchParams();

  if (props) {
    const { me } = props;

    if (me) {
      url.set("me", "1");
    }
  }
  return useNestorListApiCall<T>(NestorServerRoutes.container_list(), "containers", url);
};

export const useContainerSingle = (elId: number | string): NestorApiElement<NestorContainer, undefined> => {
  const apiCall = useNestorAPI<NestorContainer>(`stock/containers/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading container..." source={apiCall.state} success={null} />,
  };
};
