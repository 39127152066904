import { NestorBuilding } from "nestor/types/company/building";
import { FilterProps } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { useEffect } from "react";
import { useBuildingField } from "../../forms/fields/BuildingList";
import { BuildingRenderer } from "../../renderers/BuildingRenderer";

export const BuildingColumn = <T,>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T) => NestorBuilding | undefined
) => {
  const SearchField: React.FunctionComponent<FilterProps<string | number>> = function ({ onChange }) {
    const bldg = useBuildingField({
      label: "Building",
      defaultValue: undefined,
    });

    const v = bldg.value;
    useEffect(() => {
      if (v === undefined) {
        return;
      }
      onChange(v, "eq");
    }, [v, onChange]);

    return <div className="hstack">{bldg.Content}</div>;
  };

  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <BuildingRenderer building={renderer(el)} />;
    }),
    SearchField,
    field
  );
};
