// Contexts
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useDeviceParkList } from "nestor/hooks/api/useDeviceParkList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useAmIElevated, useAmIInventoryAdmin } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDevicePark } from "nestor/types/inventory/device_park";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { DeviceParkAddEditModal } from "./modals/AddEdit";

type T = NestorDevicePark;
type FW = { isElevated: boolean };
type FWLst = EditMethod<T, FW> & RemoveMethod<T, FW>;

const Table = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("name", "Name", (e) => e.name),
      EmployeeColumn("responsible", "Responsible", "responsible_id", (e) => e.responsible),
      EmployeeColumn("responsible_c", "Responsible calibration", "responsible_id", (e) => e.calibration_manager),
      EmployeeColumn(
        "responsible_me",
        "Responsible metrological checks",
        "metrological_check_manager_id",
        (e) => e.metrological_check_manager
      ),
      EmployeeColumn("responsible_mai", "Reponsible maintenance", "responsible_id", (e) => e.maintenance_manager),
      ButtonsColumn("actions", "", [EditButton(), RemoveButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnVisibility: (cols, fw) => {
      if (fw.isElevated) {
        return { actions: true };
      } else {
        return { actions: false };
      }
    },

    useColumnWidth: () => {
      return { actions: "100px" };
    },
  });
})();

const Container = (function () {
  return HasDeletion(
    HasAddition(
      HasEdition(HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())), DeviceParkAddEditModal),
      DeviceParkAddEditModal,
      "Add a new park",
      "plus",
      "success"
    ),
    "Confirm park deletion",
    (v: T) => `Are you sure to revoke this device park ?`,
    (props) => {
      return useNestorAPI<undefined, NestorDevicePark>(
        (address) => {
          assert(address !== undefined);
          return NestorServerRoutes.device_park_delete(address.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();

export const DeviceParkListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "asc",
        field: "name",
      },
    ];
    return defaultFilter;
  }, []);

  const isElevated = useAmIInventoryAdmin();
  return (
    <div className="m-2">
      <CardComponent header="Listing of all device parks">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useDeviceParkList()}
            fwProps={{ isElevated }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
