import { NestorOrder } from "nestor/types/purchasing/orders";

import React from "react";
import { StockEntryCreate } from "./StockEntryCreate";

import { StockEntryListCard } from "./StockEntryList";

export const OrderStockInventoryEntryList = (props: React.PropsWithChildren<{ order: NestorOrder }>) => {
  return (
    <>
      <StockEntryListCard order={props.order} />
    </>
  );
};

export const OrderStockInventoryEntry = (props: React.PropsWithChildren<{ order: NestorOrder }>) => {
  return (
    <>
      <StockEntryCreate order={props.order}></StockEntryCreate>
    </>
  );
};
