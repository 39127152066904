import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceType } from "nestor/types/inventory/device_type";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useDeviceCategorySelectField } from "../forms/fields/DeviceCategory";

export const DeviceTypeModal = StandardFormAddEditModalFactory<NestorDeviceType, any, any>({
  title: (el) => (!el ? "Add a new device type" : `Edit the device type ${el.name}`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const device_category_id = useDeviceCategorySelectField({
      label: "Category",
      originalValue: el?.device_category_id,
      defaultValue: undefined,
    });

    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const apiResult = useNestorAPI(
      !el ? `inventory/types/forms/create` : `inventory/types/${el.id}/forms/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // * Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        device_category_id: device_category_id.value,
        name: name.value,
      });
    };

    return {
      fields: {
        device_category_id,
        name,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
