import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSkuShortcut } from "nestor/types/stock/shortcut";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";

export const SkuShortcutAddEditModal = StandardFormAddEditModalFactory<NestorSkuShortcut, any, any>({
  title: (el, fw) => (el ? `Edit the shortcut ${el.name} ` : `Add a new shortcut`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const handle = useFormTextField({
      label: "Handle",
      helpText: "Careful with this value. It is used for scripting. Do not edit if you don't know what you're doing",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const apiResult = useNestorAPI(
      el ? NestorServerRoutes.sku_shortcut_update(el.id) : NestorServerRoutes.sku_shortcut_create(),
      false,
      undefined,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        handle: handle.value,
      });
    };

    return {
      fields: {
        name,
        handle,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
