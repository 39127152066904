import { NestorSkuTransferContext } from "nestor/contexts/NestorSkuTransferContext";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useContext } from "react";
import { showArticleDescription } from "../../../purchasing/orders/ArticleTable";

export const WithdrawalContentCard = () => {
  return (
    <CardComponent header="Moving SKUs" additionalClasses="h-100">
      <WithdrawalContent />
    </CardComponent>
  );
};
export const WithdrawalContent = (props: {}) => {
  const ctx = useContext(NestorSkuTransferContext);
  assert(ctx);

  return (
    <>
      {ctx.store.size === 0 ? (
        <div className="alert alert-info">
          Nothing selected yet... Select elements in the table below to initiate a transfer / withdrawal
        </div>
      ) : null}
      <div className="row mb-2">
        {Array.from(ctx.store.entries()).map(([skuId, { container, sku, value }]) => {
          return (
            <div className="col-6 mb-3 ">
              <div className="p-2 border-1 border h-100 w-100 position-relative">
                <div>
                  <span className="fw-bold">{sku.nuid}</span> -{" "}
                  <span className="text-muted">{sku.article?.reference}</span>
                </div>
                {sku.article ? (
                  <div className="text-truncate" title={showArticleDescription(sku.article)}>
                    <span className="text-muted">{showArticleDescription(sku.article)}</span>
                  </div>
                ) : null}
                <div className="d-flex align-items-center">
                  <span>Qty: &nbsp;</span>
                  <span>
                    <input
                      className="form-control form-control-sm"
                      value={value}
                      onChange={(e) => {
                        ctx.setTransferValue(container, sku, parseFloat(e.target.value));
                      }}
                      type="number"
                    />
                  </span>
                </div>

                <span className="position-absolute top-0 start-100 translate-middle p-2 bg-warning border border-light rounded-circle">
                  <button
                    type="button"
                    className="btn-close btn-sm"
                    aria-label="Close"
                    onClick={(e) => ctx.setTransferValue(container, sku, 0)}
                  ></button>
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
