import { DeviceListCard } from "nestor/components/nestor/inventory/devices/List";
import { NestorDivision } from "nestor/types/company/division";
import { useRouteMatch } from "react-router-dom";

export function DevicesList() {
  const { params } = useRouteMatch<{ div: string }>();
  return (
    <div className="container-fluid p-3">
      <DeviceListCard division={params.div as NestorDivision} />
    </div>
  );
}
