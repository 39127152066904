import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceMaintenance } from "nestor/types/inventory/device_maintenance";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { useState } from "react";
import { useMaintenanceSearch } from "../../../../../forms/fields/MaintenanceSearch";
import { MaintenanceAddEditModal } from "../../../maintenances/modals/AddEditMaintenance";
import { NestorMaintenance } from "nestor/types/inventory/maintenance";

export const DeviceMaintenanceAddEditModal = StandardFormAddEditModalWithFormFactory<
  NestorDeviceMaintenance & { maintenance?: NestorMaintenance },
  { device: NestorDevice },
  any
>({
  title: (el, fw) => (el ? `Edit the assigned maintenance ${el.maintenance?.name}` : `Assign a new maintenance`),

  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    const comment = useFormTextField({
      label: "Comment",
      originalValue: el?.comment,
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const maintenance_id = useMaintenanceSearch({
      label: "Maintenance",
      originalValue: el?.maintenance_id,
      defaultValue: undefined,
      validationOnChange: useFieldOptRequired(true),
    });

    // const periodicity_days = useMaintenancePeriodicityField(el?.periodicity_days, true);

    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.maintenance_associate(fw.device.id)
        : NestorServerRoutes.maintenance_associate_update(fw.device.id),
      false,
      undefined,
      NestorPOST
    );

    const fields: any = { maintenance_id, comment /*, periodicity_days*/ };

    const form = useForm({
      onSubmit: () => {
        apiResult.doQuery(undefined, {
          comment: comment.value,
          //  periodicity_days: periodicity_days.value,
          maintenance_id: el ? el.maintenance_id : maintenance_id.value,
        });
      },
      fields,
      Template: FormTpl,
      fw: {
        el,
      },
    });

    return {
      Content: form.Content,
      out: apiResult.result,
      submit: () => {
        form.submit();
      },
      state: apiResult.state,
    };
  },
});

export type FieldNames = "maintenance_id" | "comment";

export const FormTpl: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  el?: NestorDeviceMaintenance;
}> = (props) => {
  const [addMaintenance, setAddMaintenance] = useState<boolean>(false);
  return (
    <>
      {addMaintenance ? (
        <MaintenanceAddEditModal
          el={(props.fields.maintenance_id as ReturnType<typeof useMaintenanceSearch>).selectedItem}
          show={true}
          handleClose={() => {
            setAddMaintenance(false);
          }}
          onValidated={(out) => {
            props.fields.maintenance_id.clearValue();
            props.fields.maintenance_id.updateValue(out.response.id);
            setAddMaintenance(false);
          }}
          fw={{}}
        />
      ) : null}

      <div className="d-flex">
        {props.fields.maintenance_id.Content}
        <button className="btn btn-primary mb-2" type="button" onClick={() => setAddMaintenance(true)}>
          {!props.el ? "Add a new one" : "Edit"}
        </button>
      </div>

      {props.fields.comment.Content}
    </>
  );
};
