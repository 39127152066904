import { NestorContactFragment } from "nestor/types/util/contact_fragment";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

export const useContactFields = (
  el: NestorContactFragment | undefined,
  emailRequired: boolean,
  phoneRequired: boolean,
  faxRequired?: boolean
) => {
  const email = useFormTextField({
    label: "E-mail",
    originalValue: el?.email || "",
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(emailRequired),
  });

  const phone = useFormTextField({
    label: "Phone",
    originalValue: el?.phone || "",
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(phoneRequired),
  });

  const fax = useFormTextField({
    label: "Fax",
    originalValue: el?.fax || "",
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(faxRequired || false),
  });

  return { email, phone, fax };
};
