import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";

type T = NestorPcb;

export const usePcbSingle = (elId: number | string): NestorApiElement<T, undefined> => {
  const apiCall = useNestorAPI<T>(`manufacturing/pcbs/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading pcb..." source={apiCall.state} success={null} />,
  };
};
