import { ManufacturerRenderer } from "nestor/components/renderers/Manufacturer";
import { usePartSingle } from "nestor/hooks/api/usePartSingle";
import { NestorPart, NestorPartId } from "nestor/types/stock/part";
import { CardComponent } from "@csem/shared-utils";

export const PartInformationCard = ({ part }: { part: NestorPart }) => {
  return (
    <CardComponent additionalClasses="h-100" header="Part information">
      <div className="row align-items-middle mb-3">
        <div className="col-4">Description</div>
        <div className="col-8">{part.description}</div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Project</div>
        <div className="col-8">
          <ManufacturerRenderer manufacturer={part.manufacturer} id={part.manufacturer_identification} />
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Extended description</div>
        <div className="col-8">{part.extendedDescription}</div>
      </div>

      <div className="row align-items-middle mb-3">
        <div className="col-4">Datasheet</div>
        <div className="col-8">
          {part.URLdatasheet ? (
            <a href={part.URLdatasheet} target="_blank">
              Open
            </a>
          ) : (
            <div className="text-muted">No datasheet provided</div>
          )}
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Category</div>
        <div className="col-8">{part.category?.description}</div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Dividable</div>
        <div className="col-8">
          {part.dividable ? <span className="badge bg-info">Yes</span> : <span className="badge bg-secondary">No</span>}
        </div>
      </div>
      {part.dividable ? (
        <>
          <div className="row align-items-middle mb-3">
            <div className="col-4">Division unit</div>
            <div className="col-8">{part.dividable_unit}</div>
          </div>{" "}
          <div className="row align-items-middle mb-3">
            <div className="col-4">Amount of units</div>
            <div className="col-8">{part.dividable_amount}</div>
          </div>
        </>
      ) : null}
    </CardComponent>
  );
};

export const PartInformationCardId = (props: { partId: NestorPartId }) => {
  const result = usePartSingle(props.partId);

  if (!result.element) {
    return result.loadingIO;
  }

  return <PartInformationCard part={result.element} />;
};
