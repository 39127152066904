import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { CardComponent } from "@csem/shared-utils";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { FormTemplateDefault } from "@csem/forms";
import { IOState } from "@csem/api";
import { useSupplierSearchField } from "../../forms/fields/SupplierSearch";

export const SupplierMergeCard = () => {
  const supplier_from = useSupplierSearchField({
    label: "Merge this supplier",
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const supplier_to = useSupplierSearchField({
    label: "Into this supplier",
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const apiCall = useNestorAPI(NestorServerRoutes.admin_merge_suppliers(supplier_from.value, supplier_to.value), false);

  const f = useForm({
    fields: {
      supplier_from,
      supplier_to,
    },

    Template: FormTemplateDefault,
    fw: {},
    onSubmit: () => {
      apiCall.doQuery();
    },
  });

  return (
    <CardComponent header="Supplier merging tool">
      <div className="alert alert-info">
        Select a supplier that you want to remove (1st supplier search field).
        <br />
        All orders passed with this supplier will be migrated to the second supplier (2nd supplier search field).
        <br />
        <div className="text-danger fw-bold">
          Make sure that the two suppliers are actually duplicates. And consider that other divisions might have used
          that supplier for their ordering !
        </div>
        <strong>With great power comes great responsability. Be careful not to mess up.</strong>
      </div>
      <IOState source={apiCall.state} />
      {f.Content}

      <button className="btn btn-danger" onClick={f.submit}>
        I understand what's going to happen. Let's do it
      </button>
    </CardComponent>
  );
};
