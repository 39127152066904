import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorTask } from "nestor/types/manufacturing/task";
import { NestorTaskProgress } from "nestor/types/manufacturing/task_progress";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormTextareaField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */

export const useTaskProgressHours = (el?: NestorTaskProgress) => {
  return useFormTextField({
    label: "Number of hours worked",
    originalValue: parseInt(el?.hours || "0"),
    defaultValue: 0 as number,
    type: FieldValueType.NUMBER,
    validationOnChange: useFieldOptRequired<number>(true),
  });
};

export const AddEditTaskProgressModal = StandardFormAddEditModalFactory<
  NestorTaskProgress,
  { task: NestorTask } & any,
  any
>({
  title: (el) => (!el ? "Register new progress" : `Edit the progress`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    const comment = useFormTextareaField({
      label: "Comment",
      originalValue: el?.comment,
      defaultValue: "",
      validationOnChange: useFieldOptRequired(false),
    });

    const hours = useTaskProgressHours(el);

    const newProgress = useFormTextField({
      label: "New progress (%)",
      originalValue: el?.progress,
      defaultValue: 0,
      type: FieldValueType.NUMBER,
      validationOnChange: useFieldOptRequired<number>(true),
    });

    const apiResult = useNestorAPI(
      !el
        ? `manufacturing/tasks/${fw.task.id}/forms/progress/create`
        : `manufacturing/tasks/${fw.task.id}/forms/progress/${el.id}/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        comment: comment.value,
        hours: hours.value,
        progress: newProgress.value,
      });
    };

    return {
      fields: {
        hours,
        newProgress,
        comment,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
/*
    return (
        <Tpl
            show={true}
            el={props.el}
            onValidated={props.callback}
            handleClose={() => {}}
        />
    );
};
*/
