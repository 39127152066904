import { IOState } from "@csem/api";
import { CardComponent } from "@csem/shared-utils";
import { DeviceRenderer } from "nestor/components/forms/fields/DeviceSearch";
import { useLocationFields } from "nestor/components/forms/fields/LocationField";
import { ProposeSigneesModal } from "nestor/components/modals/ProposeSignees";
import { ContainerRenderer } from "nestor/components/renderers/Container";
import { SkuRenderer } from "nestor/components/renderers/SkuRenderer";
import { NestorSelectContext, NestorSelectContextProvider } from "nestor/contexts/NestorSelectContext";
import { NestorSkuTransferContext, NestorSkuTransferContextProvider } from "nestor/contexts/NestorSkuTransferContext";
import { useCalibrationConfirmationSingle } from "nestor/hooks/api/useCalibrationConfirmation";
import { useContainerSingle } from "nestor/hooks/api/useContainerSingle";
import { useLaboratorySingle } from "nestor/hooks/api/useLaboratoryList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useRoomSingle } from "nestor/hooks/api/useRoomList";
import { useLocationStack } from "nestor/hooks/useLocationStack";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorContact } from "nestor/types/purchasing/contact";
import { NestorContainer, NestorContainerType } from "nestor/types/stock/container";
import { NestorSku } from "nestor/types/stock/sku";
import { NestorContainerSku } from "nestor/types/stock/sku_container";
import { NestorLocalisable, NestorLocation } from "nestor/types/system/location";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { render } from "react-dom";
import { LaboratoryOneLineWithFetch } from "../company/laboratories/Info";
import { RoomOneLineWithFetch } from "../company/rooms/Info";
import { ContainerOneLineWithFetch } from "../stock/containers/Info";
import { WithdrawalForm } from "../stock/skus/movements/WithdrawalForm";
import { TransferCard } from "../util/TransferCard";

export enum AuditableType {
  CONTAINER = "container",
  ROOM = "room",
  LABORATORY = "laboratory",
}

type LocalisableWithSku = Exclude<NestorLocalisable | "sku", "employee">;

const Columns = (props: {
  noLongerPresent: Array<React.ReactNode>;
  stillPresent: Array<React.ReactNode>;
  newEntries?: Array<React.ReactNode>;
}) => {
  return (
    <div className="row ">
      <div className=" col-4 p-3">
        <div className="list-group">
          <li className="list-group-item list-group-item-primary">No longer present</li>
          {props.noLongerPresent.length == 0 ? (
            <li className="list-group-item list-group-item-secondary disabled">Nothing here</li>
          ) : (
            props.noLongerPresent
          )}
        </div>
      </div>
      <div className=" col-4 p-3">
        <div className="list-group">
          <li className="list-group-item list-group-item-primary">Still present</li>
          {props.stillPresent.length == 0 ? (
            <li className="list-group-item list-group-item-secondary disabled">Nothing here</li>
          ) : (
            props.stillPresent
          )}
        </div>
      </div>
      {props.newEntries ? (
        <div className="col-4 p-3">
          <div className="list-group">
            <li className="list-group-item list-group-item-primary">New elements</li>
            {props.newEntries.length == 0 ? (
              <li className="list-group-item list-group-item-secondary disabled">Nothing here</li>
            ) : (
              props.newEntries
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
const AuditColumns = function <T extends NestorContainer | NestorDevice>(props: {
  sku?: boolean;
  newEls: T[];
  els: T[];
  render: (el: T) => React.ReactNode;
}) {
  const ctx = useContext(NestorSelectContext);
  const noLongerPresent = props.els.filter((el) => ctx?.store.find((e) => e.el === el)).map((el) => props.render(el));
  const stillPresent = props.els
    .filter((el) => ctx?.store.find((e) => e.el === el) === undefined)
    .map((el) => props.render(el));
  const newItems = props.newEls.map((e) => props.render(e));

  return <Columns noLongerPresent={noLongerPresent} stillPresent={stillPresent} newEntries={newItems} />;
};

const SkuAuditColumns = function (props: {
  sku?: boolean;
  newEls: NestorContainerSku[];
  els: NestorContainerSku[];
  render: (el: NestorContainerSku) => React.ReactNode;
}) {
  const skuCtx = useContext(NestorSkuTransferContext)!;

  let stillPresent: React.ReactNode[] = [];
  let noLongerPresent: React.ReactNode[] = [];
  for (let el of props.els) {
    if (!skuCtx.store.has(`${el.container_id}_${el.sku_id}`)) {
      stillPresent.push(props.render(el));
    } else {
      noLongerPresent.push(props.render(el));
    }
  }
  const newItems = undefined;
  return <Columns noLongerPresent={noLongerPresent} stillPresent={stillPresent} newEntries={newItems} />;
};

const EnterNewEls = (props: {
  type: AuditableType;
  id: number;
  newContainers: NestorContainer[];
  newDevices: NestorDevice[];
}) => {
  const apiCall = useNestorAPI("locations/move", false, undefined, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });

  return (
    <CardComponent additionalClasses={"h-100"} header="Move new elements into here">
      <p>
        <strong>{props.newContainers.length}</strong> new containers to move in
      </p>
      <p>
        <strong>{props.newDevices.length}</strong> new devices to move in
      </p>
      <IOState source={apiCall.state} />
      <button
        className="btn btn-success"
        onClick={() => {
          let locationKey: string;
          if (props.type == AuditableType.CONTAINER) {
            locationKey = "container_id";
          } else if (props.type == AuditableType.LABORATORY) {
            locationKey = "laboratory_id";
          } else if (props.type == AuditableType.ROOM) {
            locationKey = "room_id";
          } else {
            throw new Error("Auditable type not in the list");
          }

          apiCall.doQuery(undefined, {
            objects: [
              ...props.newContainers.map((s) => {
                return { type: "container", id: s.id };
              }),
              ...props.newDevices.map((s) => {
                return { type: "equipment", id: s.id };
              }),
            ],
            locationType: props.type,
            [locationKey]: props.id,
          });
        }}
      >
        Validate
      </button>
    </CardComponent>
  );
};
const SelectableElement = (props: {
  type: LocalisableWithSku;
  el: NestorContainer | NestorContainerSku | NestorDevice;
  children: React.ReactNode;
}) => {
  const ctx = useContext(NestorSelectContext);
  const skuCtx = useContext(NestorSkuTransferContext);
  /*
  let selected: boolean = false;

  if (props.type === "sku") {
    const _el = props.el as NestorContainerSku;
    selected = skuCtx?.store.has(`${_el.container_id}_${_el.sku_id}`) || false;
  } else {
    selected = ctx?.store.find((e) => {
      return e.type === props.type && e.el.id === props.el.id;
    })
      ? true
      : false;
  }*/
  return (
    <a
      onClick={() => {
        if (props.type === "sku") {
          const _el = props.el as NestorContainerSku;

          skuCtx?.toggleTransferValue(_el.container!, _el.sku!, _el.quantity!);
        } else {
          ctx?.toggle(props.el as NestorPcb | NestorContainer | NestorDevice, props.type);
        }
      }}
      className={"list-group-item list-group-item-action p-1 "}
    >
      {props.children}
    </a>
  );
};

const useNuidListener = function (found: (nuid: string) => void) {
  let text = "";
  let dates: Array<number> = [];
  let reg = /([A-Z]{3})\.([0-9]{3})(\.[0-9]{2,3})?/gu;
  let timeout: NodeJS.Timeout;

  let analyse = () => {
    const matches = text.matchAll(reg);
    for (let i of matches) {
      const match = i[0];
      const lengthOfMatch = match.length;

      if (i.index === undefined) {
        continue;
      }

      const indexOfMatch = i.index;
      text = text.slice(indexOfMatch + lengthOfMatch);
      dates.splice(0, lengthOfMatch);

      found(i[0]);
      break;
    }

    const cutoffDate = Date.now() - 2000;
    let cutoffIndex = 0;
    for (let date of dates) {
      if (date < cutoffDate) {
        ++cutoffIndex;
      } else {
        break;
      }
    }

    text = text.slice(cutoffIndex);
    dates = dates.slice(cutoffIndex);
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key.length == 1) {
        text += e.key;
        dates.push(Date.now());
      }

      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(analyse, 200);
    };

    const event = "keydown";
    document.addEventListener(event, listener);

    return () => {
      document.removeEventListener(event, listener);
    };
  }, [found]);
};

export const AuditContentInner = (params: { type: AuditableType; id: number }) => {
  const newNuidApiCall = useNestorAPI<
    { type: LocalisableWithSku; item: NestorContainer | NestorDevice | NestorPcb },
    string
  >((id) => (id ? "nuid/" + id : false), false, undefined, undefined, undefined, undefined);

  const ctx = useContext(NestorSelectContext);
  const skuCtx = useContext(NestorSkuTransferContext);

  const contentList = useNestorAPI<Array<NestorLocation | NestorContainerSku>>(
    NestorServerRoutes.location_content(params.type, params.id),
    true
  );

  const [listIndexedByNuid, setContent] = useState<{
    sku: { newEls: Array<NestorSku>; list: Array<NestorContainerSku>; index: Record<string, NestorContainerSku> };
    equipment: { newEls: Array<NestorDevice>; list: Array<NestorDevice>; index: Record<string, NestorDevice> };
    container: {
      newEls: Array<NestorContainer>;
      list: Array<NestorContainer>;
      index: Record<string, NestorContainer>;
    };
  }>();

  useEffect(() => {
    if (contentList.result) {
      let skus: Array<NestorContainerSku> = [];
      let containers: Array<NestorContainer> = [];
      let devices: Array<NestorDevice> = [];

      contentList.result?.forEach((el) => {
        if ("sku_id" in el) {
          if (el.quantity && el.quantity > 0) {
            skus.push(el as NestorContainerSku);

            skuCtx?.toggleTransferValue(el.container!, el.sku!, el.quantity!);
          }
        } else {
          const e = el as NestorLocation;

          if (!e.localisable) {
            console.warn(e);
            return;
          }
          if (e.localisable_type == "container") {
            containers.push(el.localisable as NestorContainer);
            ctx?.toggle(e.localisable!, "container");
          } else {
            devices.push(el.localisable as NestorDevice);
            ctx?.toggle(e.localisable!, "equipment");
          }
        }
      });

      const remap = <T,>(container: Array<T>, getNuid: (el: T) => string) => {
        return Object.fromEntries(
          container?.map((e) => {
            return [getNuid(e), e];
          }) || []
        );
      };

      const skusByNuid = remap(skus, (e) => e.sku!.nuid);
      const containerByNuid = remap(containers, (e) => e.nuid);
      const devicesByNuid = remap(devices, (e) => e.nuid);

      const content = {
        sku: {
          newEls: [],
          list: skus,
          index: skusByNuid,
        },

        container: {
          newEls: [],
          list: containers,
          index: containerByNuid,
        },

        equipment: {
          newEls: [],
          list: devices,
          index: devicesByNuid,
        },
      };

      setContent(content);
    }
  }, [contentList.result]);

  useNuidListener(
    useCallback(
      (foundNuid: string) => {
        if (!listIndexedByNuid) {
          return;
        }

        if (listIndexedByNuid.container && foundNuid in listIndexedByNuid.container.index) {
          return ctx?.toggle(listIndexedByNuid.container.index[foundNuid], "container");
        }
        if (listIndexedByNuid.sku && foundNuid in listIndexedByNuid.sku.index) {
          return skuCtx?.toggleTransferValue(
            listIndexedByNuid.sku.index[foundNuid].container!,
            listIndexedByNuid.sku.index[foundNuid].sku!,
            listIndexedByNuid.sku.index[foundNuid].quantity!
          );

          // ?.toggle(listIndexedByNuid.skus.index[foundNuid], "sku");
        }
        if (listIndexedByNuid.equipment && foundNuid in listIndexedByNuid.equipment.index) {
          return ctx?.toggle(listIndexedByNuid.equipment.index[foundNuid], "equipment");
        }
        newNuidApiCall.doQuery(foundNuid).then((v) => {
          if (!v) {
            return;
          }

          switch (v.type) {
            case "equipment":
              setContent((c) => {
                return {
                  ...c!,
                  equipment: {
                    ...c!.equipment,
                    newEls: [...(c!.equipment?.newEls || []), v.item as NestorDevice],
                  },
                };
              });
              break;

            case "container":
              setContent((c) => {
                return {
                  ...c!,
                  container: {
                    ...c!.container,
                    newEls: [...(c!.container?.newEls || []), v.item as NestorContainer],
                  },
                };
              });
              break;
          }
        });
      },
      [listIndexedByNuid, newNuidApiCall.doQuery]
    )
  );

  if (!listIndexedByNuid) {
    return <IOState source={contentList.state} />;
  }

  const DoNotAudit = (props: { type: LocalisableWithSku }) => {
    return (
      <button
        className="btn btn-secondary"
        onClick={() => {
          setContent((e) => {
            return {
              ...e!,
              [props.type]: {
                index: {},
                list: [],
                newEls: [],
              },
            };
          });
        }}
      >
        Do not audit
      </button>
    );
  };

  return (
    <div className="vstack gap-2">
      <div className="row">
        <div className="col-4">
          <TransferCard showStore={false} title="Move no longer present Containers/PCBs/Devices to new location" />
        </div>
        <div className="col-4">
          <EnterNewEls
            type={params.type}
            id={params.id}
            newContainers={listIndexedByNuid.container.newEls}
            newDevices={listIndexedByNuid.equipment.newEls}
          />
        </div>
        {params.type === "container" && (
          <div className="col-4">
            <WithdrawalForm title="Move no longer present SKUs"></WithdrawalForm>
          </div>
        )}
      </div>
      {/*
      <button
        className="btn btn-warning"
        onClick={() => {
          listIndexedByNuid.container.list.forEach((e) => {
            ctx?.toggle(e, "container");
          });

          listIndexedByNuid.equipment.list.forEach((e) => {
            ctx?.toggle(e, "equipment");
          });

          listIndexedByNuid.sku.list.forEach((e) => {
            skuCtx?.toggleTransferValue(e.container!, e.sku!, e.quantity!);
          });
        }}
      >
        Flip selection
      </button>
    */}
      {params.type === "container" && (
        <CardComponent header="SKUs">
          <div className="d-flex justify-content-between">
            <p>
              <strong>{listIndexedByNuid.sku.list.length}</strong> SKUs found in there
            </p>
            <DoNotAudit type="sku"></DoNotAudit>
          </div>

          <SkuAuditColumns
            newEls={[]}
            els={listIndexedByNuid.sku.list}
            render={(e: NestorContainerSku) => {
              return (
                <SelectableElement type="sku" el={e}>
                  <SkuRenderer sku={e.sku}></SkuRenderer>
                </SelectableElement>
              );
            }}
          />
        </CardComponent>
      )}
      <CardComponent header="Containers">
        <div className="d-flex justify-content-between">
          <p>
            <strong>{listIndexedByNuid.container.list.length}</strong> containers found in there
          </p>
          <DoNotAudit type="container"></DoNotAudit>
        </div>
        <AuditColumns
          newEls={listIndexedByNuid.container.newEls}
          els={listIndexedByNuid.container.list}
          render={(e: NestorContainer) => {
            return (
              <SelectableElement type="container" el={e}>
                <ContainerRenderer container={e} />
              </SelectableElement>
            );
          }}
        />
      </CardComponent>

      <CardComponent header="Devices">
        <div className="d-flex justify-content-between">
          <p>
            <strong>{listIndexedByNuid.equipment.list.length}</strong> devices found in there
          </p>
          <DoNotAudit type="equipment"></DoNotAudit>
        </div>
        <AuditColumns
          newEls={listIndexedByNuid.equipment.newEls}
          els={listIndexedByNuid.equipment.list}
          render={(e: NestorDevice) => {
            return (
              <SelectableElement type="equipment" el={e}>
                <DeviceRenderer device={e} />
              </SelectableElement>
            );
          }}
        />
      </CardComponent>
    </div>
  );
};

export const AuditContent = (params: { type: AuditableType; id: number }) => {
  let el: React.ReactNode;
  if (params.type === AuditableType.CONTAINER) {
    el = <ContainerOneLineWithFetch containerid={params.id} />;
  } else if (params.type === AuditableType.LABORATORY) {
    el = <LaboratoryOneLineWithFetch labid={params.id} />;
  } else {
    el = <RoomOneLineWithFetch roomid={params.id} />;
  }
  return (
    <NestorSelectContextProvider>
      <NestorSkuTransferContextProvider>
        {el}
        <AuditContentInner {...params}></AuditContentInner>
      </NestorSkuTransferContextProvider>
    </NestorSelectContextProvider>
  );
};
