import NestorServerRoutes from "nestor/server_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { useNestorAPI } from "./useNestorAPI";

export const useDeviceWrite = () => {
  return useNestorAPI<any, NestorDevice>(
    (el) => {
      if (!el) {
        return NestorServerRoutes.device_create();
      } else {
        return NestorServerRoutes.device_update(el.id);
      }
    },
    false,
    undefined,
    {
      method: "POST",
      //       headers: { "Content-Type": "application/json" }
    }
  );
};
