import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorLaboratory } from "nestor/types/company/laboratory";
import assert from "assert";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useLaboratorySearchField } from "../../../../forms/fields/LaboratorySearch";
/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */
export const LaboratoryRevokeModal = StandardFormAddEditModalFactory<NestorLaboratory, any, any>({
  title: (el) => (!el ? "" : `Revoke the laboratory ${el.name}`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    assert(el);
    const laboratory_id = useLaboratorySearchField({
      label: "Move current items into...",
      defaultValue: undefined,
    });

    const apiResult = useNestorAPI(NestorServerRoutes.laboratory_revoke(el.id), false, undefined, NestorPOST);

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        laboratory_id: laboratory_id.value,
      });
    };

    return {
      fields: {
        laboratory_id,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
