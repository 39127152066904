import { NestorSupplier } from "nestor/types/purchasing/supplier";

export const SupplierRenderer = ({ supplier }: { supplier?: NestorSupplier | null }) => {
  if (supplier === null || !supplier) {
    return null;
  }

  return (
    <>
      {supplier.name}
      <br />
      <span className="text-muted">
        {supplier.postal_code} {supplier.city} - {supplier.country}
      </span>
    </>
  );
};
