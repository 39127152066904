import NestorServerRoutes from "nestor/server_routes";
import { NestorSetting } from "nestor/types/company/setting";
import { useNestorAPI } from "./useNestorAPI";

export type DerivedUseSettings<modelName extends string, Model, Pivot> = NestorSetting & {
  [K in modelName]?: [(Model & { pivot: Pivot }) | undefined];
};

const useSettings = function <modelName extends string, Model, Pivot>(type: string, id: number) {
  return useNestorAPI<{ [setting_group_id: number]: Array<DerivedUseSettings<modelName, Model, Pivot>> }>(
    NestorServerRoutes.settings_list(type, id),
    true
  );
};

export default useSettings;
