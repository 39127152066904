import { ToggleCheck } from "@csem/shared-utils";
import { useState } from "react";

export const useZeroQties = () => {
  const [settings, setSettings] = useState<{ zeroQties: boolean }>({ zeroQties: false });

  const ZeroQtiesToggler = (
    <ToggleCheck
      text="Show zeroed quantities"
      checked={!!settings.zeroQties}
      onChange={(state) =>
        setSettings((s) => {
          return { ...s, zeroQties: state };
        })
      }
    />
  );

  return {
    zeroQty: settings.zeroQties,
    ZeroQtiesToggler: ZeroQtiesToggler,
  };
};
