import { useAddressFields } from "nestor/components/forms/bundles/AddressFields";
import { useContactFields } from "nestor/components/forms/bundles/ContactFields";
import { useCurrencyField } from "nestor/components/forms/fields/CurrencyList";
import { useTransferringHandlerField } from "nestor/components/forms/fields/OrderHandler";
import { useFieldPaymentMode } from "nestor/components/forms/fields/PaymentMode";
import { useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { CardComponent } from "@csem/shared-utils";
import { useFormTextareaField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import React from "react";

type FieldNames =
  | "name"
  | "address"
  | "address2"
  | "postal_code"
  | "city"
  | "country"
  | "email"
  | "phone"
  | "fax"
  | "url"
  | "currency_id"
  | "transferring"
  | "payment_mode"
  | "description";

export const SupplierEditCreateForm: React.FunctionComponent<{
  supplier?: NestorSupplier;
}> = (props) => {
  const name = useFormTextField({
    label: "Name",
    originalValue: props.supplier?.name,
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(true),
  });

  const { address, address2, postal_code, city, country } = useAddressFields(props.supplier, true, true, false, true);
  const { email, phone, fax } = useContactFields(props.supplier, false, false, false);

  const url = useFormTextField({
    label: "Web page",
    originalValue: props.supplier?.url || "",
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const currency_id = useCurrencyField({
    label: "Currency",
    originalValue: props.supplier?.currency_id,
    defaultValue: "",
    validationOnChange: useFieldOptRequired(true),
  });

  const description = useFormTextareaField({
    label: "Description",
    originalValue: props.supplier?.description || "",
    defaultValue: "",
  });

  const transferring = useTransferringHandlerField(props.supplier?.transferring, false);
  const payment_mode = useFieldPaymentMode(props.supplier?.payment_mode);

  const apiCall = useNestorAPIForms<{ response: { id?: number } }>(
    props.supplier ? `purchasing/suppliers/${props.supplier.id}/forms/update` : `purchasing/orders/forms/create`,

    false,
    undefined,
    { method: "POST", headers: { "Content-Type": "application/json" } }
  );

  const form = useForm({
    fields: {
      name,
      address,
      address2,
      postal_code,
      city,
      country,
      email,
      phone,
      fax,
      url,
      currency_id,
      transferring,
      payment_mode,
      description,
    },

    Template: FormTemplate,
    fw: {},
    errors: apiCall.error,

    onSubmit() {
      const payload: any = {
        name: name.value,
        address: address.value,
        address2: address2.value,
        postal_code: postal_code.value,
        city: city.value,
        country: country.value,
        email: email.value,
        phone: phone.value,
        fax: fax.value,
        url: url.value,
        currency_id: currency_id.value,
        transferring: transferring.value,
        payment_mode: payment_mode.value,
        description: description.value,
      };

      if (props.supplier) {
        payload.id = props.supplier.id;
      }

      apiCall.doQuery(undefined, payload);
    },
  });

  return (
    <>
      <IOState source={apiCall.state} />
      {form.Content}
    </>
  );
};

const FormTemplate: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  submit: Function;
}> = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-12 mb-2 d-flex ">
          <button className="btn btn-success btn-lg flex-grow-1" type="button" onClick={() => props.submit()}>
            Submit
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-2">
          <CardComponent header="Supplier general" additionalClasses="h-100">
            {props.fields.name.Content}

            <h6>Order defaults: </h6>
            {props.fields.currency_id.Content}
            {props.fields.transferring.Content}
            {props.fields.payment_mode.Content}
          </CardComponent>
        </div>

        <div className="col-md-4 mb-2">
          <CardComponent header="Address" additionalClasses="h-100">
            {props.fields.address.Content}
            {props.fields.address2.Content}
            <div className="row">
              <div className="col-4">{props.fields.postal_code.Content}</div>
              <div className="col-8">{props.fields.city.Content}</div>
            </div>
            {props.fields.country.Content}
          </CardComponent>
        </div>

        <div className="col-md-4 mb-2">
          <CardComponent header="Contact" additionalClasses="h-100">
            {props.fields.phone.Content}
            {props.fields.email.Content}
            {props.fields.fax.Content}
            {props.fields.url.Content}
          </CardComponent>
        </div>

        <div className="col-12 mb-2">
          <CardComponent header="Description" additionalClasses="h-100">
            {props.fields.description.Content}
          </CardComponent>
        </div>
      </div>
    </>
  );
};
