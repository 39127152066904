import { useEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { NestorPOST, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { useMyId } from "nestor/hooks/me";
import { NestorDevice } from "nestor/types/inventory/device";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { useFormDateField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import assert from "assert";
import moment from "moment";
import { Field } from "@csem/forms";
import { extendLocationPayload, useLocationFields } from "nestor/components/forms/fields/LocationField";
import { useForm } from "@csem/forms";
import { useEffect, useState } from "react";
import NestorServerRoutes from "nestor/server_routes";

export const AddEditLoanModalDefinition = (device: NestorDevice) => {
  const employee_id = useEmployeeSearchField({
    label: "Who is borrowing the instrument ?",
    defaultValue: useMyId(),
    validationOnChange: useFieldOptRequired(true),
  });

  const [endDate, setEndDate] = useState<Date>();

  const borrowed_at = useFormDateField({
    label: "Borrowing date",
    originalValue: moment().format("YYYY-MM-DD"),
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const { borrow_max_duration_days } = device;
  useEffect(() => {
    if (borrowed_at.value) {
      setEndDate(moment(borrowed_at.value).add(borrow_max_duration_days, "d").toDate());
    }
  }, [borrowed_at.value, device, borrow_max_duration_days]);

  const expected_return_at = useFormDateField({
    label: "Expected return date",
    originalValue: moment().format("YYYY-MM-DD"),
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
    endDate: endDate,
  });

  const comment = useFormTextField({
    label: "Comment",
    defaultValue: "",
    originalValue: "",
    type: FieldValueType.STRING,
  });

  const location_f = useLocationFields(undefined, false);

  const apiResult = useNestorAPIForms(NestorServerRoutes.device_loan_create(device.id), false, undefined, NestorPOST);

  const form = useForm({
    fields: {
      employee_id,
      borrowed_at,
      expected_return_at,
      comment,
    },
    errors: apiResult.error,

    Template: Template,

    fw: {
      locationSwitch: location_f.SwitchButtons,
      locationContent: location_f.Field,
      device: device,
    },

    onSubmit() {
      const payload: any = extendLocationPayload(
        {
          borrowed_at: borrowed_at.value,
          expected_return_at: expected_return_at.value,
          comment: comment.value,
          employee_id: employee_id.value,
        },
        location_f
      );

      apiResult.doQuery(undefined, payload);
    },
  });

  return {
    Content: form.Content,

    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

type FieldNames = "employee_id" | "borrowed_at" | "expected_return_at" | "comment";

export const Template: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;

  locationSwitch: JSX.Element;
  locationContent: JSX.Element;
  device: NestorDevice;

  submit: Function;
}> = (props) => {
  return (
    <>
      <div className="alert alert-info">Maximum duration: {props.device.borrow_max_duration_days} days</div>
      {props.fields.employee_id.Content}
      {props.fields.borrowed_at.Content}
      {props.fields.expected_return_at.Content}
      {props.fields.comment.Content}

      {props.locationSwitch}
      {props.locationContent}
    </>
  );
};

export const AddEditLoanModal = StandardFormAddEditModalWithFormFactory<any, { device: NestorDevice }, any>({
  title: () => `Add a new loan`,
  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    return AddEditLoanModalDefinition(fw.device);
  },
});

export const AddEditLoanModalFromDeviceList = StandardFormAddEditModalWithFormFactory<NestorDevice, any, any>({
  title: () => `Add a new loan`,
  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    assert(el);
    return AddEditLoanModalDefinition(el);
  },
});
