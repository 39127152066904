import NestorServerRoutes from "nestor/server_routes";
import { NestorBuilding } from "nestor/types/company/building";
import { IOState } from "@csem/api";
import { useMemo } from "react";
import { apiList } from "@csem/api";
import { useNestorAPI } from "./useNestorAPI";

type T = NestorBuilding;
export const useBuildingList = (): apiList<T> => {
  const apiCall = useNestorAPI<T[]>(NestorServerRoutes.building_list(), true);

  /*
  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading container..." source={apiCall.state} success={null} />,
  };
*/

  const { state, doQuery, setSearchParams } = apiCall;
  return {
    refresh: doQuery,
    setSearchParams: setSearchParams,
    list: apiCall.result,
    pagination: undefined,
    state: state,
    loadingIO: useMemo(() => {
      return (
        <IOState
          source={state}
          success={null}
          loading={null}
          successMessage={undefined}
          loadingMessage={undefined}
          errorMessage={`Error while loading the building list`}
        />
      );
    }, [state]),
  };
};
