import { NestorPOST, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { NestorCalibrationRange } from "nestor/types/inventory/calibration_range";
import { NestorDevice } from "nestor/types/inventory/device";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

export const CalibrationRangeAddEditModal = StandardFormAddEditModalFactory<
  NestorCalibrationRange,
  { device: NestorDevice },
  any
>({
  title: (el) => (el ? `Edit the range ${el.name}` : `Add a new calibration range`),
  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const description = useFormTextField({
      label: "Description",
      originalValue: el?.description,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: useFieldOptRequired<string>(false),
    });
    const interval_days = useFormSelectObj({
      label: "Calibration interval",
      defaultValue: 365,
      originalValue: el?.interval_days,
      options: {
        "-1": "On demand",
        31: "monthly",
        180: "6 months",
        365: "1 year",
        730: "2 years",
        1095: "3 years",
        1460: "4 years",
        1825: "5 years",
        3650: "10 years",
        7300: "20 years",
      },
    });

    const metrological_check_interval_days = useFormSelectObj({
      label: "Metrological check interval",
      defaultValue: 365,
      originalValue: el?.metrological_check_interval_days,
      options: {
        "-1": "On demand",
        31: "monthly",
        180: "6 months",
        365: "1 year",
        730: "2 years",
        1095: "3 years",
        1460: "4 years",
        1825: "5 years",
        3650: "10 years",
        7300: "20 years",
      },
    });
    const apiResult = useNestorAPIForms(
      !el ? `inventory/calibration_ranges/device/${fw.device.id}` : `inventory/calibration_ranges`,
      false,
      undefined,
      NestorPOST
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal

    const cb = () => {
      const content: any = {
        name: name.value,
        description: description.value,
        interval_days: interval_days.value,
        metrological_check_interval_days: metrological_check_interval_days.value,
      };
      if (fw.device) {
        content.device_id = fw.device.id;
      }
      if (el) {
        content.id = el.id;
      }

      apiResult.doQuery(undefined, content);
    };

    return {
      fields: {
        name,
        description,
        interval_days,
        metrological_check_interval_days,
      },
      errors: apiResult.error,
      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
