import { CardComponent } from "@csem/shared-utils";
import moment from "moment";
import { EmployeeRenderer } from "nestor/components/renderers/EmployeeInfos";
import { DateRenderer } from "nestor/components/tables/columns/DateColumn";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { NestorSupplierQualification } from "nestor/types/purchasing/supplier_qualification";
import settings from "settings";

export const SupplierQualificationInfoLine = ({
  qualification,
  supplier,
}: {
  supplier: NestorSupplier;
  qualification: NestorSupplierQualification;
}) => {
  return (
    <CardComponent header="Qualification you're looking at" additionalClasses={"mb-2"}>
      <div className="row">
        <div className="col-xl-1 col-md-4 col-6   mb-2">
          <strong>Supplier:</strong>
          <p>{supplier.name}</p>
        </div>

        <div className="col-xl-1 col-md-4 col-6  mb-2">
          <strong>Scope:</strong>
          <p>{qualification.scope}</p>
        </div>
        <div className="col-xl-2 col-md-4 col-6 mb-2">
          <div>
            <strong>Created by:</strong>
            <EmployeeRenderer employee={qualification.employee || undefined} />
          </div>
          <div>
            <strong>Approved by:</strong>
            <EmployeeRenderer employee={qualification.approver || undefined} />
          </div>
        </div>

        <div className="col-xl-2 col-md-4 col-6 mb-2">
          <div>
            <div>
              <strong>Creation date: </strong>
            </div>
            <DateRenderer el={qualification.created_at} />
          </div>
          <div>
            <div>
              <strong>Reevaluation date: </strong>
            </div>

            <DateRenderer el={qualification.reevaluate_at} />
          </div>
        </div>

        <div className="col-xl-2 col-md-4 col-6  mb-2">
          <div>
            <div>
              <strong>Expiration date: </strong>
            </div>
            <DateRenderer el={qualification.expires_at} />
          </div>
          <div>
            <div>
              {" "}
              <strong>Revocation: </strong>
            </div>

            {qualification.revoked_at == null ? (
              "Not revoked"
            ) : (
              <div>
                {" "}
                <DateRenderer el={qualification.expires_at} />
              </div>
            )}
          </div>
        </div>

        <div className="col-xl-1 col-md-4 col-6  mb-2">
          <div>
            <strong>ISO 9001: </strong> {qualification.iso_9001 ? "Yes" : "No"}
          </div>
          <div>
            <strong>ISO 13485: </strong> {qualification.iso_13485 ? "Yes" : "No"}
          </div>
        </div>
        <div className="col-xl-1 col-md-4 col-6  mb-2">
          <div
            className="btn btn-primary"
            onClick={() => {
              window.open(
                settings.NESTOR_URL + NestorServerRoutes.supplier_qualification_ef611(qualification.id),
                "_blank"
              );
            }}
          >
            Template EF611
          </div>
        </div>
      </div>
    </CardComponent>
  );
};
