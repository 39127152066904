import { useManufacturerList } from "nestor/hooks/api/useManufacturerList";
import { NestorManufacturer } from "nestor/types/purchasing/manufacturer";
import { GenericListRenderer } from "@csem/forms";
import { SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { DeviceModelRenderer } from "../../renderers/DeviceModel";

export const DeviceModelColumn = <T,>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T) => NestorManufacturer | undefined,
  renderer_model: (el: T) => string | undefined
) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <DeviceModelRenderer manufacturer={renderer(el)} model={renderer_model(el)} />;
    }),
    SearchElFilterFactory<NestorManufacturer, NestorManufacturer["id"]>({
      label: "Manufacturer",
      apiListFactory: useManufacturerList,
      traverser: (el) => el.id,
      filterKeys: {
        name: "contains",
      },
      template: GenericListRenderer((e) => <DeviceModelRenderer manufacturer={e} />),
      key: "id",
    }),
    field
  );
};
