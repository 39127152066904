import { useNestorConversation } from "nestor/hooks/api/useConversation";
import moment from "moment";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const PopoverConversation = ({ id, type }: { id: string | number; type: string }) => {
  const list = useNestorConversation(type, id);

  const tooltop = list.list ? (
    <Popover style={{ width: 800 }}>
      <Popover.Header>Comments</Popover.Header>
      <Popover.Body>
        <ul className="list-group">
          {list.list.map((el) => {
            return (
              <li className="list-group-item">
                <strong>
                  {el.employee?.fullname}
                  <br /> {moment(el.created_at).format("YYYY-MM-DD [at] HH:mm")}
                  <br />
                </strong>
                {el.content}
              </li>
            );
          })}
        </ul>
      </Popover.Body>
    </Popover>
  ) : (
    <></>
  );

  return list.list?.length > 0 ? (
    <>
      <OverlayTrigger placement="right" trigger="focus" overlay={tooltop}>
        <button type="button" className="btn btn-success h-100">
          <em className="bi bi-chat-left-text"></em> ({list.list.length})
        </button>
      </OverlayTrigger>
    </>
  ) : null;
};
