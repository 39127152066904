// Hooks

// Classes

// Components
//## Nestor
import { DeviceModelModal } from "nestor/components/nestor/inventory/device_models/modals/AddEditDeviceModel";
import { DeviceModelColumn } from "nestor/components/tables/columns/DeviceModelColumn";
import { DeviceTypeColumn } from "nestor/components/tables/columns/DeviceTypeColumn";
import { DeviceModelNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import NestorFrontendRoutes from "nestor/frontend_routes";
import { useDeviceModelList } from "nestor/hooks/api/useDeviceModelList";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { CardComponent } from "@csem/shared-utils";
import { EditButton, HasTableFilterClear } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { NavigationColumn } from "@csem/lists";

// Contexts
// ## Generic
import { ButtonsColumn, HtmlColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasEdition,
  HasModalMethod,
  HasRefresh,
  HasSearch,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { ManufacturerColumn } from "../../../tables/columns/ManufacturerColumn";
import { DeviceListModal, InspectDevices } from "../devices/ModalList";
type T = NestorDeviceModel;
type FW = { systems: boolean };
type FWList = EditMethod<T, FW> & Partial<InspectDevices<T, FW>>;

export const GenericDeviceModelTable = (function () {
  return TableRenderer<T, FW, FWList, {}, "id">({
    key: "id",
    columns: [
      //   DeviceNavigationColumn,

      // NUIDColumn,
      DeviceModelNavigationColumn,

      NavigationColumn("_nav_system", "", (el: NestorDeviceModel) => {
        return NestorFrontendRoutes.devicemodel_systems(el.id);
      }),

      SimpleColumn("division", "Division", (e, fw, hk) => e.division_id),

      DeviceTypeColumn("type", "Device type", "device_type_id", "type.device_category_id", (e) => e.type),
      //SimpleColumn("device", "Device type", (e, fw, hk) => <DeviceTypeCategoryRenderer type={e.type} />),

      DeviceModelColumn(
        "manufacturer",
        "Device model",
        "device_manufacturer_id",
        (e) => e.manufacturer || undefined,
        (e) => e.model || undefined
      ),
      SimpleColumn("description", "Description", (e, fw, hk) => e.description),

      HtmlColumn("characteristics", "Characteristics", (e, fw, hk) => e.characteristic, 150),

      SimpleColumn("devices", "View devices", (e, fw, hk) => {
        return (
          <button type="button" onClick={(_) => fw.inspect_devices?.(e, fw)} className="btn btn-secondary">
            <em className="bi bi-search"></em>
          </button>
        );
      }),

      ButtonsColumn("actions", "Actions", [EditButton("Edit", (e) => !!e.permissions?.can_edit)]),
      //  DateColumn("created_at", "Created", "created_at", e => e.created_at)
    ],

    useColumnVisibility: (columns, fw) => {
      let nav: { [x: string]: boolean };
      if (fw.systems) {
        nav = { _nav: false };
      } else {
        nav = { _nav_system: false };
      }
      return nav;
    },

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        _nav: "0px",
      };
    },
  });
})();

const GenericDeviceModelListContainer = (function () {
  return HasTableFilterClear(
    HasModalMethod(
      HasEdition(
        HasAddition(
          HasRefresh(
            HasSearch(
              HasStdTablePagination(GenericListContent<FW, T>()),
              {
                model: "contains",
                "manufacturer.name": "contains",
              },
              undefined,
              undefined,
              TableSearch
            )
          ),
          DeviceModelModal,
          "Add a new device model",
          "plus",
          "primary"
        ),
        DeviceModelModal
      ),

      DeviceListModal("model_id"),
      "inspect_devices"
    )
  );
})();

export const DeviceModelListCard = ({
  header = "Device model list",
  systems = false,
}: {
  header?: string;
  systems?: boolean;
}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    if (systems) {
      defaultFilter.filter.filters[0].filters.push({
        field: "is_system",
        operator: "eq",
        value: 1,
      });
    }

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, [systems]);
  return (
    <div className="m-2">
      <CardComponent header={header}>
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericDeviceModelListContainer
            //cardHeader="Gateway list"
            list={useDeviceModelList()}
            fwProps={useMemo(() => {
              return { systems: !!systems };
            }, [systems])}
            listTemplate={GenericDeviceModelTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
