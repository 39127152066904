import { DeviceListCard } from "nestor/components/nestor/inventory/devices/List";
import { OrderListCard, orders_unfinalized } from "nestor/components/nestor/purchasing/orders/List";
import { ContainerListCard } from "nestor/components/nestor/stock/containers/List";
import { useMyDivision } from "nestor/hooks/me";
import { useMenuElement } from "@csem/shared-utils";
import { useMemo } from "react";
import { useRouteMatch } from "react-router";
import { Route, Switch } from "react-router-dom";
import settings from "settings";
import { Profile } from "./profile/Profile";

export function HomeMain() {
  const { url } = useRouteMatch();
  const division = useMyDivision();

  const profile = useMenuElement("Profile", url + "/profile");

  const menus = useMemo(() => {
    const menus: Array<{ title: string; icon: string; content: Array<{ path: string; title: string }> }> = [];
    if (settings.division_capabilities[division].includes("purchasing")) {
      menus.push({
        title: "Purchasing",
        icon: "cart-fill",
        content: [
          {
            path: settings.navigation.purchasing("orders/list"),
            title: "List of orders",
          },
          {
            path: settings.navigation.purchasing("orders/create"),
            title: "Create a new order",
          },
          {
            path: settings.navigation.purchasing("suppliers"),
            title: "List of suppliers",
          },
        ],
      });
    }

    if (settings.division_capabilities[division].includes("stock")) {
      menus.push({
        title: "Stock",
        icon: "boxes",
        content: [
          {
            path: settings.navigation.stock("containers/list"),
            title: "List of containers",
          },
          {
            path: settings.navigation.stock("containers/create"),
            title: "Create a new container",
          },
          {
            path: settings.navigation.stock("stock/skus"),
            title: "List of SKUs",
          },
        ],
      });
    }

    if (settings.division_capabilities[division].includes("inventory")) {
      menus.push({
        title: "Inventory",
        icon: "boombox",
        content: [
          {
            path: settings.navigation.inventory("devices/list"),
            title: "List of devices",
          },
          {
            path: settings.navigation.inventory("device_models/list"),
            title: "List of device models",
          },
          {
            path: settings.navigation.inventory("mainenances/list"),
            title: "List of maintenances",
          },
          {
            path: settings.navigation.inventory("alerts/"),
            title: "Alerts & Warnings",
          },
        ],
      });
    }

    if (settings.division_capabilities[division].includes("manufacturing")) {
      menus.push({
        title: "Manufacturing",
        icon: "wrench",
        content: [
          {
            path: settings.navigation.manufacturing("pcbs/layout_list"),
            title: "List of PCBs",
          },
          {
            path: settings.navigation.manufacturing("tasks/list"),
            title: "List of BT tasks",
          },
          {
            path: settings.navigation.manufacturing("tasks/create"),
            title: "Create a new task",
          },
        ],
      });
    }

    return menus;
  }, [division]);

  const col = Math.floor(12 / menus.length);

  return (
    <Switch>
      <Route path={`${url}/profile`} key="profile">
        <Profile></Profile>
      </Route>
      <Route>
        <div className="container-fluid p-3">
          <div className="row mb-3">
            {menus.map((m) => {
              return (
                <div className={`col-${col}`} key={m.title}>
                  <div className="card h-100 border-primary">
                    <div className="card-body">
                      <div className="vstack ">
                        <h3 className="text-center">
                          <em className={`bi bi-${m.icon}`}></em>
                        </h3>
                        <h4 className="text-center mb-3">{m.title}</h4>

                        {m.content
                          .map((c) => {
                            return (
                              <div className="ps-3" key={c.path}>
                                <a className="text-decoration-none" href={c.path}>
                                  {c.title}
                                </a>
                              </div>
                            );
                          })
                          .reduce<Array<JSX.Element>>((res, child, index) => {
                            if (index < m.content.length - 1) {
                              return res.concat([child, <hr key={index} />]);
                            }

                            return res.concat(child);
                          }, [])}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <OrderListCard me={true} state={orders_unfinalized} header="My unfinalized orders" />
          <ContainerListCard me={true} header="My containers" />
          <DeviceListCard me={true} header="My devices" />
          <DeviceListCard my_loans={true} header="Devices I borrowed" />
        </div>
      </Route>
    </Switch>
  );
}
