import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { SupplierAccountList } from "./SupplierAccountList";
import { SupplierDetail } from "./SupplierDetail";
import { SupplierList, SupplierQualityList } from "./SupplierList";

export function SuppliersMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("All suppliers", url + "/list");
  const accounts = useMenuElement("All supplier accounts", url + "/accounts");
  const quality = useMenuElement("All suppliers criticality", url + "/quality");
  //  const create = useMenuElement("Create a new supplier", url + "/create");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="supplierlist">
        <XemwayMenuProvider {...list}>
          <SupplierList></SupplierList>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/accounts`} key="supplieraccounts">
        <XemwayMenuProvider {...accounts}>
          <SupplierAccountList></SupplierAccountList>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/quality`} key="supplierquality">
        <XemwayMenuProvider {...quality}>
          <SupplierQualityList></SupplierQualityList>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/:supplier`} key="supplierdetails">
        <SupplierDetail />
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
