import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssemblyFeeder, NestorPcbAssemblyFeederSet } from "nestor/types/manufacturing/pcb_assembly";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormMultipleTextField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useMemo } from "react";
import { useSkuSearchField } from "nestor/components/forms/fields/SkuSearch";

export const PCBAssemblyFeederAddEditModal = StandardFormAddEditModalFactory<
  NestorPcbAssemblyFeeder,
  { feeder_set: NestorPcbAssemblyFeederSet },
  any
>({
  title: (el, fw) => (el ? `Edit the feeder ${el.value} ${el.footprint} ` : `Add a new feeder`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const value = useFormTextField({
      label: "Value",
      originalValue: el?.value,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const footprint = useFormTextField({
      label: "Value",
      originalValue: el?.footprint,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const feeder_x = useFormTextField({
      label: "Feeder X",
      originalValue: el?.feeder_x,
      type: FieldValueType.NUMBER,
      defaultValue: 0,
    });

    const feeder_y = useFormTextField({
      label: "Feeder Y",
      originalValue: el?.feeder_y,
      type: FieldValueType.NUMBER,
      defaultValue: 0,
    });

    const aliases = useFormMultipleTextField({
      label: "Matching altium values",
      originalValue: useMemo(() => {
        return el?.aliases?.map((a) => a.result) || [];
      }, [el]),
      defaultValue: [],
    });

    const sku_id = useSkuSearchField({
      label: "SKU id",
      originalValue: el?.sku_id,
      defaultValue: undefined,
    });

    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.pcb_assembly_feeder_create(fw.feeder_set.id)
        : NestorServerRoutes.pcb_assembly_feeder_update(fw.feeder_set.id, el.id),
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        footprint: footprint.value,
        value: value.value,
        feeder_x: feeder_x.value,
        feeder_y: feeder_y.value,
        aliases: aliases.value,
        sku_id: sku_id.value,
      });
    };

    return {
      fields: {
        footprint,
        value,
        feeder_x,
        feeder_y,
        aliases,
        sku_id,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
