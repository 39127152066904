import { SkuListCard } from "nestor/components/nestor/stock/skus/List";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { SKUDetails } from "./SKUDetails";
import { SKUsList } from "./SKUList";
import { SKUShortcuts } from "./SKUShortcuts";

export function SKUsMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List of SKUs", url + "/list");
  const expired = useMenuElement("Expired SKUs", url + "/expired");
  const shortcuts = useMenuElement("SKU shortcuts", url + "/shortcuts");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="skulist">
        <XemwayMenuProvider {...list}>
          <SKUsList></SKUsList>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/shortcuts`} key="shortcutlist">
        <XemwayMenuProvider {...shortcuts}>
          <SKUShortcuts />
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/expired`} key="expired">
        <SkuListCard header="Expired SKUs" expired={true}></SkuListCard>
      </Route>
      <Route path={`${url}/:sku`} key="skudetails">
        <SKUDetails></SKUDetails>
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
