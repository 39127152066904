// External dependencies
import { useCurrencyField } from "nestor/components/forms/fields/CurrencyList";
import { useScanFileField } from "nestor/components/forms/fields/ScanField";
import DownloadColumn from "nestor/components/tables/columns/DownloadColumn";
import { useInvoiceList } from "nestor/hooks/api/useInvoiceList";
// Hooks
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useAmIElevated } from "nestor/hooks/me";
import { NestorInvoice, NestorInvoiceType } from "nestor/types/purchasing/invoice";
import { NestorOrderId } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useMonetaryField } from "@csem/forms";
// Classes
// Components
// Contexts
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { DateColumn } from "../../../tables/columns/DateColumn";
//# Tables
//## Nestor
import { EmployeeColumn } from "../../../tables/columns/EmployeeColumn";
import { MonetaryAmountRenderer } from "../../util/MonetaryAmountRenderer";

type T = NestorInvoice;
type FWLst = { orderId: NestorOrderId };
type FW = RemoveMethod<T, FWLst> & EditMethod<T, FWLst>;

type HOOK = {};

const InvoiceTypeRenderer = (status: NestorInvoiceType) => {
  let type: string;
  let text: string;
  if (status === NestorInvoiceType.FULL) {
    text = "full";
    type = "success";
  } else if (status === NestorInvoiceType.PARTIAL) {
    text = "partial";
    type = "warning";
  } else if (status === NestorInvoiceType.FINAL) {
    text = "final";
    type = "info";
  } else {
    text = "Unknown";
    type = "danger";
  }

  return <div className={`badge bg-${type}`}>{text}</div>;
};

const AddEditModal = StandardFormAddEditModalFactory<T, FWLst, any>({
  title: (el) => (el ? "Edit the invoice" : "Add a new invoice"),
  buttonValidate: (el?) => "Validate",
  useDefinition: (el, fw) => {
    const comment = useFormTextField({
      label: "Comment",
      originalValue: el?.comment,
      defaultValue: "",
      type: FieldValueType.STRING,
    });

    const currency_id = useCurrencyField({
      label: "Currency",
      defaultValue: undefined,
      originalValue: el?.currency_id || undefined,
      validationOnChange: { required: true },
    });

    const amount = useMonetaryField({
      label: "Value",
      originalValue: el?.amount || 0,
      currency: currency_id.selectedElement?.name,
      defaultValue: 0,
      validationOnChange: { required: true },
    });

    const type = useFormSelectObj({
      label: "Type",
      defaultValue: NestorInvoiceType.FINAL,
      originalValue: el?.type || undefined,

      options: {
        [NestorInvoiceType.FINAL]: "Final",
        [NestorInvoiceType.FULL]: "Full",
        [NestorInvoiceType.PARTIAL]: "Partial",
      },
      validationOnChange: { required: true },
    });

    const { scan, file } = useScanFileField(el?.attachment);

    const apiResult = useNestorAPI(
      !fw ? undefined : !el ? `purchasing/orders/${fw.orderId}/forms/createInvoice` : `purchasing/invoices`,
      false,
      undefined,
      {
        method: "POST",
        headers: {},
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      let formData = new FormData();

      formData.append("comment", comment.value);
      formData.append("amount", amount.value!.toString());
      formData.append("currency_id", currency_id.value!.toString());
      formData.append("type", type.value?.toString() || "");
      if (typeof scan.value == "number") {
        formData.append("scan_id", scan.value.toString());
      }
      if (file.value instanceof File) {
        // Could be a boolean
        formData.append("file", file.value);
      }

      if (el) {
        formData.append("id", el.id.toString());
      }

      apiResult.doQuery(undefined, formData);
    };

    return {
      fields: {
        type,

        currency_id,
        amount,
        comment,
        file,
        scan,
      },
      out: apiResult.result,
      state: apiResult.state,
      submit: cb,
    };
  },
});

const GenericInvoiceTable = (function () {
  return TableRenderer<T, FWLst, FW, HOOK, "id">({
    key: "id",
    columns: [
      DownloadColumn(
        (e) => {
          assert(e.attachment);
          return `/download/attachment/${e.attachment.id}/${e.attachment.name}`;
        },
        "Download",
        (e) => !!e.attachment
      ),

      SimpleColumn("amount", "Amount", (e) => {
        return MonetaryAmountRenderer(e.amount, e.currency, "Unknown currency");
      }),

      SimpleColumn("type", "Type", (e) => InvoiceTypeRenderer(e.type)),

      SimpleColumn("comment", "Comment", (e) => e.comment),

      EmployeeColumn<T>("employee", "Employee", "employee_id", (e) => e.employee),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("actions", "", [
        {
          title: "Revoke",
          theme: "danger",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.remove(e, fw);
          },
        },

        {
          title: "Edit",
          theme: "info",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.edit(e, fw);
          },
        },
      ]),
    ],

    useColumnWidth: () => {
      return {
        download: "0px",
        buttons: "0px",
      };
    },

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const GenericInvoiceListContainer = (function () {
  return HasStdTablePagination(
    HasRefresh(
      HasAddition(
        HasEdition(
          HasDeletion(
            GenericListContent<FWLst, T>(),

            "Confirm invoice deletion",
            (v: T) => `Are you sure to revoke this invoice ?`,
            (props) => {
              return useNestorAPI<undefined, NestorInvoice>(
                (invoice) => {
                  assert(invoice !== undefined);
                  return `purchasing/invoices/${invoice.id}`;
                },
                false,
                undefined,
                { method: "DELETE" },
                props.list.refresh
              );
            }
          ),
          AddEditModal
        ),
        AddEditModal,
        "Add a new invoice",
        "plus",
        "success",
        useAmIElevated
      )
    )
  );
})();

export const OrderInvoiceListCard = (props: { orderId: NestorOrderId }) => {
  const filter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);

  const elevated = useAmIElevated();

  return (
    <div className="m-2">
      <CardComponent header="Invoice list">
        {!elevated && (
          <div className="alert alert-info">
            Dear visitor. If you want to enter a <strong>new invoice</strong>, please be reminded that{" "}
            <strong>you must send your invoice to your administrative staff</strong> and not upload them directly to
            Nestor. They'll gladly take care of that once your order has been signed. Thanks you and enjoy your visit
          </div>
        )}
        <StandardTableFilterCtxProvider defaultValue={filter}>
          <GenericInvoiceListContainer
            //cardHeader="Gateway list"
            list={useInvoiceList(props.orderId)}
            fwProps={{ orderId: props.orderId }}
            listTemplate={GenericInvoiceTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
