import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useOrderFeedbackCategoriesList } from "nestor/hooks/api/useOrderFeedback";
import NestorServerRoutes from "nestor/server_routes";
import { NestorOrder } from "nestor/types/purchasing/orders";
import {
  NestorOrderFeedback,
  NestorOrderFeedbackCategory,
  NestorOrderFeedbackId,
} from "nestor/types/purchasing/order_feedback";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { useFormTextareaField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { APIReturn } from "@csem/api";
import React, { useState } from "react";
import { NestorEmployeeId } from "nestor/types/company/employee";

type CatT = { [x: string]: number | string };

export const useFeedbackFields = function (el?: NestorOrderFeedback) {
  let or: CatT = {};
  el?.grades?.forEach((el) => {
    or["cat_" + el.category_id] = el.grade;
    or["comment_cat_" + el.category_id] = el.comment;
  });

  const [catValues, setCatValues] = useState<CatT>(or);
  const categories = useOrderFeedbackCategoriesList();

  const comment = useFormTextareaField({
    label: "Comment",
    defaultValue: "",
    height: 100,
    originalValue: el?.comment || "",
  });

  return { comment, catValues, setCatValues, categories };
};

export const OrderFeedbackAddEdit = StandardFormAddEditModalWithFormFactory<
  NestorOrderFeedback,
  { order: NestorOrder; me: NestorEmployeeId },
  any
>({
  title: (el, fw) => (fw.order ? `Add evaluation for order ${fw.order.nuid}` : `Edit evaluation`),
  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    return useFeedbackForm(el, fw.order);
  },
});

export const useFeedbackForm = (el: NestorOrderFeedback | undefined, order: NestorOrder) => {
  const { comment, catValues, setCatValues, categories } = useFeedbackFields(el);

  const apiResult = useNestorAPI(
    el ? NestorServerRoutes.order_feedback_update(order.id, el.id) : NestorServerRoutes.order_feedback_create(order.id),
    false,
    undefined,
    NestorPOST
  );

  const form = useForm({
    onSubmit: () => {
      apiResult.doQuery(undefined, {
        comment: comment.value,
        ...catValues,
      });
    },
    fields: {
      comment,
    },
    Template: FormOrderFeedback,
    fw: {
      catValues,
      setCatValues,
      categories,
    },
  });

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

export const FeedbackStars = ({ grade, onChange }: { grade: number | string; onChange?: (grade: number) => void }) => {
  return (
    <div className="d-flex justify-content-between">
      {new Array(5).fill(null).map((_, index) => {
        const c = grade > index ? "star-fill" : "star";
        return (
          <h5>
            <em
              className={`bi bi-${c} text-primary`}
              role="button"
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (onChange) {
                  onChange(index + 1);
                }
              }}
            ></em>
          </h5>
        );
      })}
    </div>
  );
};
export const FormOrderFeedback: React.FunctionComponent<{
  fields: Record<"comment", Field<any>>;

  categories: APIReturn<Array<NestorOrderFeedbackCategory>, undefined>;
  catValues: CatT;
  setCatValues: React.Dispatch<React.SetStateAction<CatT>>;
}> = (props) => {
  return (
    <>
      {props.fields.comment.Content}

      <div className="row my-2 align-items-center">
        <div className="col-3 offset-2 d-flex justify-content-between">
          <div>Very dissatisfied</div>
          <div>Very satisfied</div>
        </div>
      </div>
      {props.categories.result
        ? props.categories.result.map((v) => {
            const grade = props.catValues["cat_" + v.id] || 0;
            const enabled = grade > 0;
            return (
              <div className="row my-2 align-items-center">
                <div className="col-2">
                  <label>
                    <input
                      type="checkbox"
                      disabled={!enabled}
                      checked={enabled}
                      onClick={() =>
                        props.setCatValues((x) => {
                          return { ...x, [`cat_${v.id}`]: 0 };
                        })
                      }
                    />{" "}
                    <span className={!enabled ? "text-muted" : ""}>{v.name}</span>
                  </label>
                </div>
                <div className="col-3">
                  <FeedbackStars
                    grade={grade}
                    onChange={(grade) => {
                      props.setCatValues((x) => {
                        return { ...x, [`cat_${v.id}`]: grade };
                      });
                    }}
                  ></FeedbackStars>
                </div>
                <div className="col-7">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      type="text"
                      value={props.catValues[`comment_cat_${v.id}`] || ""}
                      onChange={(e) => {
                        props.setCatValues((x) => {
                          return { ...x, [`comment_cat_${v.id}`]: e.target.value };
                        });
                      }}
                    />
                    <label>Comment</label>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </>
  );
};
