import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ContainersMain } from "./containers/Containers";
import { SKUsMain } from "./sku/SKUs";

export function StockMain() {
  const { url } = useRouteMatch();
  const containers = useMenuElement("Containers", url + "/containers");
  const skus = useMenuElement("SKUs", url + "/skus");
  return (
    <Switch>
      <Route path={`${url}/containers`} key="containerlist">
        <XemwayMenuProvider {...containers}>
          <ContainersMain></ContainersMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/skus`} key="skulist">
        <XemwayMenuProvider {...skus}>
          <SKUsMain></SKUsMain>
        </XemwayMenuProvider>
      </Route>

      <Route>
        <Redirect to={`${url}/containers`} />
      </Route>
    </Switch>
  );
}
