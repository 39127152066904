import { NestorEmployee } from "nestor/types/company/employee";
import { EmployeeBadge, EmployeeBadgeComplete } from "../nestor/company/employees/EmployeeBadge";

export const EmployeeRenderer = (props: { employee?: NestorEmployee; includeName?: boolean }) => {
  if (!props.employee) {
    return null;
  }
  return <EmployeeBadge includeName={props.includeName} employee={props.employee} />;
};

export const EmployeeRendererList = (props: { employee?: NestorEmployee; fallbackText?: string }) => {
  if (!props.employee) {
    if (props.fallbackText) {
      return <div className="text-muted">{props.fallbackText}</div>;
    }
    return null;
  }
  return (
    <div className="d-flex">
      <div className="ms-2">
        <strong>{props.employee.fullname}</strong>
        {
          <>
            &nbsp; (<span className="text-muted">{props.employee.section_id}</span>) <br />
          </>
        }

        {props.employee.email}
      </div>
    </div>
  );
};

export const EmployeeInfo: React.FunctionComponent<{
  text: string;
  employee?: NestorEmployee | null;
}> = (props) => {
  return (
    <div className="row align-items-center mb-3">
      <div className="col-4">{props.text}</div>
      <div className="col-8">
        {props.employee ? (
          <EmployeeBadgeComplete employee={props.employee} />
        ) : (
          <span className="text-muted">No information</span>
        )}
      </div>
    </div>
  );
};
