// External dependencies
// Hooks
// Classes
// Components
// Contexts
//# Tables
import { useNestorConversationList } from "nestor/hooks/api/useConversation";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorConversation } from "nestor/types/util/conversation";
import { CardComponent } from "@csem/shared-utils";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { IOState } from "@csem/api";
import { GenericListContent, HasPagination, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import { DateColumn } from "../../tables/columns/DateColumn";
import { EmployeeColumn } from "../../tables/columns/EmployeeColumn";

type T = NestorConversation;
type FWLst = {};
type FW = FWLst;
type HOOK = {};
const Table = (function () {
  return TableRenderer<T, FW, {}, HOOK, "id">({
    key: "id",
    columns: [
      SimpleColumn("content", "Content", (e) => <div className="render_lines">{e.content}</div>),

      EmployeeColumn("employee", "Written by", "employee_id", (e) => e.employee),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
    ],
    useColumnWidth: () => {
      return {
        created_at: "120px",
        employee: "120px",
      };
    },
    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const Ctn = (function () {
  return HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>()));
})();

export const ConversationTableCard = ({ type, id }: { type: string; id: string | number }) => {
  const list = useNestorConversationList(type, id);

  //const [newComment, updateNewComment] = useState<string>("");
  const newCommentRef = useRef<string>("");

  const apiCall = useNestorAPI(`conversations/${type}/${id}`, false, undefined, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
  const { doQuery } = apiCall;
  const post = useCallback(() => {
    doQuery(undefined, { content: newCommentRef.current }).then(() => list.refresh());
  }, [newCommentRef, doQuery]);

  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);

  return (
    <CardComponent header="Conversation">
      <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
        <Ctn
          list={list}
          fwProps={{}}
          listTemplate={Table}
          additionalContent={[]}
          righthandContent={[]}
          lefthandContent={[]}
          bottomContent={[]}
        />
      </StandardTableFilterCtxProvider>

      <div className={`row flex-row-reverse`}>
        {/*<EmployeeAvatar employee={item.employee} />*/}
        <div className={`border border-success border-1 bg-opacity-10 p-3 rounded rounded-3 bg-info`}>
          <IOState source={apiCall.state} success={null} />
          <div className="form-floating mb-2">
            <textarea
              className="form-control"
              style={{ width: "100%", height: "150px" }}
              onChange={(e) => {
                newCommentRef.current = e.target.value || "";
              }}
            ></textarea>
            <label>Comment</label>
          </div>
          <button type="button" onClick={post} className="btn btn-success">
            Post
          </button>
        </div>
      </div>
    </CardComponent>
  );
};
