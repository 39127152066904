// Components

// Contexts
import { ContainerQuickAddEditModal } from "nestor/components/nestor/stock/containers/modals/AddEditContainer";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { LocationColumn } from "nestor/components/tables/columns/LocationColumn";
import { ContainerNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { ProjectColumn } from "nestor/components/tables/columns/ProjectColumn";
import { NestorSelectContext } from "nestor/contexts/NestorSelectContext";
import { useContainerList } from "nestor/hooks/api/useContainerList";
import { NestorContainer } from "nestor/types/stock/container";
import { NestorLocationFilter } from "nestor/types/_internal/locationFilter";
import extendFilterByLocation from "nestor/util/ExtendFilterWithLocation";
import { CardComponent } from "@csem/shared-utils";
import { HasTableFilterClear, SelectButton } from "@csem/lists";
// ## Generic
import { TextFilterFactory } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasRefresh, HasSearch, IsSelectable } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
//# Tables
//## Nestor
import { DateColumn } from "../../../tables/columns/DateColumn";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { useHistory } from "react-router-dom";

const ContainerTypeTag = (props: { type: "medical" | "standard" }) => {
  return (
    <>
      {props.type === "medical" ? (
        <div className="d-block badge bg-danger">medical</div>
      ) : (
        <div className="d-block badge bg-info">standard</div>
      )}
    </>
  );
};

const ContainerTag = (props: { is_sink: 1 | 0; accepts_withdrawals: 1 | 0; expired_components: 1 | 0 }) => {
  return (
    <>
      {!!props.is_sink ? (
        <div className="d-block badge bg-warning" title="SKUs in this container are not accounted">
          untracked
        </div>
      ) : null}{" "}
      {!!!props.accepts_withdrawals ? (
        <div className="d-block badge bg-dark" title="Withdrawing by coworkers is forbidden">
          locked
        </div>
      ) : null}
      {!!props.expired_components ? (
        <div className="d-block badge bg-danger" title="Only for expired components">
          end-of-life
        </div>
      ) : null}
    </>
  );
};

type T = NestorContainer;

const GenericContainerTable = (function () {
  return TableRenderer<T, { actionable?: boolean; history: ReturnType<typeof useHistory> }, {}, {}, "id">({
    key: "id",
    columns: [
      ContainerNavigationColumn,
      NUIDColumn,

      FilterableColumn(
        SortableColumn(
          SimpleColumn("name", "Name & Description", (e) => (
            <>
              <div className="fw-bold">{e.name}</div>
              <div className="text-muted">{e.description}</div>
            </>
          )),
          "name"
        ),
        TextFilterFactory({ label: "Container name" })
      ),

      FilterableColumn(
        SortableColumn(
          SimpleColumn("barcode", "Nestor 1", (e) => e.barcode),
          "barcode"
        ),
        TextFilterFactory({ label: "Bar code" })
      ),
      ProjectColumn("project", "Project", "project_id", true, false, (e) => e.project || null),

      SimpleColumn("medical", "Medical", (e) => <ContainerTypeTag type={e.type} />),
      SimpleColumn("tags", "Tags", (e) => (
        <ContainerTag
          is_sink={e.is_sink}
          accepts_withdrawals={e.accept_withdrawals}
          expired_components={e.expired_components}
        />
      )),
      SimpleColumn("section", "Section", (e) => e.section_id),

      EmployeeColumn("employee", "Person responsible", "employee_id", (e) => e.employee),
      LocationColumn("location", "Room", (e) => e.location),
      SimpleColumn("sku_count", "# items", (e) => e.skus_count),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("audit", "", [
        {
          title: "Audit",
          theme: "secondary",
          visibility: undefined,
          cb: (e: T, fw) => {
            fw.history.push("/audit/container/" + e.id, "");
          },
        },
      ]),

      ButtonsColumn("actions", "", [SelectButton()]),
    ],

    useColumnWidth: () => {
      return {
        nuid: "60px",
        tags: "120px",
        medical: "100px",
        employee: "100px",
      };
    },

    useColumnVisibility: (c, fw) => {
      const vis: any = {};
      if (!fw.actionable) {
        vis.actions = false;
      }
      return vis;
    },
    useLineParamInjection: (el, fw) => {
      return {};
    },
  });
})();

const GenericContainerListContainer = (function () {
  return HasTableFilterClear(
    HasSearch(
      HasAddition(
        HasRefresh(
          HasStdTablePagination(
            IsSelectable(
              GenericListContent<{ history: ReturnType<typeof useHistory> }, T>(),
              NestorSelectContext,
              "container" as const
            )
          )
        ),
        ContainerQuickAddEditModal,
        "Add a new container",
        "plus",
        "primary"
      ),
      {
        "employee.name": "contains",
        "employee.initials": "eq",
        name: "contains",
        barcode: "eq",
        "project.code": "eq",
        "project.name": "contains",
      },
      undefined,
      undefined,
      TableSearch
    )
  );
})();

export const ContainerListCard = ({ ...props }: { me?: boolean; header?: string } & NestorLocationFilter) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    //defaultFilter.filter.logic = "and";

    extendFilterByLocation(defaultFilter, props);
    return defaultFilter;
  }, [props.container_id, props.room_id, props.laboratory_id, props.location_information]);

  return (
    <div className="mb-2">
      <CardComponent header={props.header || "Container list"}>
        <StandardTableFilterCtxProvider defaultValue={defaultFilter} persistKey="tasks" persistWithURL={true}>
          <GenericContainerListContainer
            //cardHeader="Gateway list"
            list={useContainerList({ me: props.me })}
            fwProps={{ history: useHistory() }}
            listTemplate={GenericContainerTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
