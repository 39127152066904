import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";

type NestorRefreshContext_t = {
  addCallback: (c: Function) => void;
  removeCallback: (c: Function) => void;
  callbacks: Array<Function>;
};

export const NestorRefreshContext = React.createContext<NestorRefreshContext_t | undefined>(undefined);

export const useRefreshCall = function (refresh: Function) {
  const ctx = useContext(NestorRefreshContext);

  const addCallback = ctx?.addCallback;
  const removeCallback = ctx?.removeCallback;

  useEffect(() => {
    if (!addCallback || !removeCallback) {
      return;
    }

    const cb = () => {
      refresh();
    };

    addCallback(cb);
    return () => {
      removeCallback(cb);
    };
  }, [addCallback, removeCallback, refresh]);
};

export const useTriggerRefresh = function () {
  const ctx = useContext(NestorRefreshContext);
  return useCallback(() => {
    if (!ctx) {
      return;
    }

    ctx.callbacks.forEach((cb) => cb());
  }, [ctx]);
};

export const NestorRefreshContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [cbs, setCbs] = useState<Array<Function>>([]);

  const addCallback = useCallback((cb: Function) => {
    setCbs((s) => [...s, cb]);
  }, []);

  const removeCallback = useCallback((cb: Function) => {
    setCbs((s) => [...s.filter((c) => c !== cb)]);
  }, []);

  return (
    <NestorRefreshContext.Provider value={{ callbacks: cbs, addCallback, removeCallback }}>
      {props.children}
    </NestorRefreshContext.Provider>
  );
};
