import { useProjectList } from "nestor/hooks/api/useProject";
import { NestorProject } from "nestor/types/projects/projects";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory, FormSearchMultHookFactory } from "@csem/lists";
import { ProjectRenderer } from "../../renderers/ProjectRenderer";

export const useProjectSearchField = FormSearchHookFactory<NestorProject, NestorProject["id"]>({
  apiListFactory: useProjectList,
  template: GenericListRenderer((e) => <ProjectRenderer project={e} />),
  filterKeys: { id: "eq", code: "contains", name: "contains" },
  traverser_display: (el) => {
    return el.code + " " + el.name;
  },
  uniqueKeyField: "id",
});

export const useOpenedProjectSearchField = FormSearchHookFactory<NestorProject, NestorProject["id"]>({
  apiListFactory: useProjectList,
  template: GenericListRenderer((e) => <ProjectRenderer project={e} />),
  filterKeys: { id: "eq", code: "contains", name: "contains" },
  traverser_display: (el) => {
    return el.code + " " + el.name;
  },
  uniqueKeyField: "id",
  initialFilter: [
    {
      field: "status",
      operator: "eq",
      value: "open",
    },
  ],
});



export const useProjectMultSearchField = FormSearchMultHookFactory({
  apiListFactory: useProjectList,
  template: GenericListRenderer((e) => <ProjectRenderer project={e} />),
  filterKeys: { id: "eq", code: "contains", name: "contains" },
  traverser_display: (el) => {
    return el.code + " " + el.name;
  },
  uniqueKeyField: "id",
});
