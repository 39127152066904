import * as Sentry from "@sentry/react";
import assert from "assert";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { MainView } from "./MainView";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { EmployeeBadgeComplete } from "../components/nestor/company/employees/EmployeeBadge";
import { useAmIElevated, useMe } from "../hooks/me";
import settings from "settings";
import { AdministrationMain } from "./administration/Administration";
import { CompanyMain } from "./company/Company";
import { HomeMain } from "./home/Home";
import { InventoryMain } from "./inventory/Inventory";
import { ManufacturingMain } from "./manufacturing/Manufacturing";
import { PurchasingMain } from "./purchasing/Purchasing";
import { StockMain } from "./stock/Stock";
import { AuditMain } from "./audit/AuditMain";

export function NestorMainContent() {
  const { url } = useRouteMatch();
  const me = useMe();
  const amIElevated = useAmIElevated();
  Sentry.setContext("user", {
    initials: me.initials,
    roles: me.roles.map((r) => r.name),
  });

  const division = me.section?.division_id;
  assert(division);

  const home = useMenuElement("Home", "/home", true, "house-door");

  const purchasing = useMenuElement(
    "Purchasing",
    "/purchasing",
    settings.division_capabilities[division].includes("purchasing"),
    "cart"
  );
  const inventory = useMenuElement(
    "Inventory",
    "/inventory",
    settings.division_capabilities[division].includes("inventory"),
    "boombox"
  );
  const stock = useMenuElement("Stock", "/stock", settings.division_capabilities[division].includes("stock"), "boxes");
  const company = useMenuElement("Company", "/company", true, "building");
  const manufacturing = useMenuElement("Manufacturing", "/manufacturing", true, "wrench");
  const administration = useMenuElement("Administration", "/administration", amIElevated, "gear");

  //const ctx = useContext(XemwayMenuContext);
  const badge = (
    <div className="m-2 d-flex justify-content-center">
      <EmployeeBadgeComplete employee={me} showSection={false} />
    </div>
  );
  return (
    <MainView menuTop={badge}>
      <Sentry.ErrorBoundary
        fallback={<div className="alert alert-warning">An error has occured and has been transmitted to us</div>}
        beforeCapture={(scope) => {
          scope.setTag("application", "nestor");
        }}
      >
        <Switch>
          <Route path={`/home`} key="home">
            <XemwayMenuProvider {...home}>
              <HomeMain></HomeMain>
            </XemwayMenuProvider>
          </Route>{" "}
          <Route path={`/purchasing`} key="purch">
            <XemwayMenuProvider {...purchasing}>
              <PurchasingMain></PurchasingMain>
            </XemwayMenuProvider>
          </Route>{" "}
          <Route path={`/inventory`} key="inv">
            <XemwayMenuProvider {...inventory}>
              <InventoryMain></InventoryMain>
            </XemwayMenuProvider>
          </Route>
          <Route path={`/stock`}>
            <XemwayMenuProvider {...stock} key="stock">
              <StockMain></StockMain>
            </XemwayMenuProvider>
          </Route>
          <Route path={`/company`}>
            <XemwayMenuProvider {...company} key="company">
              <CompanyMain></CompanyMain>
            </XemwayMenuProvider>
          </Route>
          <Route path={`/manufacturing`}>
            <XemwayMenuProvider {...manufacturing} key="man">
              <ManufacturingMain></ManufacturingMain>
            </XemwayMenuProvider>
          </Route>{" "}
          <Route path={`/administration`} key="adm">
            <XemwayMenuProvider {...administration}>
              <AdministrationMain></AdministrationMain>
            </XemwayMenuProvider>
          </Route>
          <Route path={`/audit/:type/:id`} key="audit">
            <AuditMain />
          </Route>
          <Route>
            <Redirect to={`home`} />
          </Route>
        </Switch>
      </Sentry.ErrorBoundary>
    </MainView>
  );
}
