import { NestorDeviceType } from "nestor/types/inventory/device_type";

export const DeviceTypeCategoryRenderer = (props: { type?: NestorDeviceType }) => {
  if (!props.type) {
    return null;
  }
  return (
    <>
      <div className="fw-bold">{props.type.category.name}</div>
      <div className="text-muted">{props.type.name}</div>
    </>
  );
};
