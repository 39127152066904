import { ManufacturerModal } from '../../../../modals/AddEditManufacturer';
import { DeviceModelModal } from '../../device_models/modals/AddEditDeviceModel';
import { useQuickEditFieldsDevice } from '../AddEditQuick';
import { Field } from '@csem/forms';
import { useForm } from '@csem/forms';
import { StandardFormAddEditModalWithFormFactory } from '@csem/shared-utils';
import _ from 'lodash';
import { useDeviceWrite } from 'nestor/hooks/api/useDeviceWrite';
import { NestorDevice } from 'nestor/types/inventory/device';
import React, { useState } from 'react';


export const DeviceQuickAddEditModal = StandardFormAddEditModalWithFormFactory<NestorDevice, any, any>({
  title: (el) => (el ? `Edit the device ${el.nuid}` : `Add a new device (short form)`),
  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    return useFormAddEditQuickDevice(el);
  },
});

const useFormAddEditQuickDevice = (el: NestorDevice | undefined) => {
  const {
    manufacturer_id_f,
    model_id_f,
    owner_id_f,
    //    room_id_f,
    serialnumber_f,
    onManufacturerAdded,
    onDeviceModelAdded,
    location_f,
  } = useQuickEditFieldsDevice(el);

  const apiResult = useDeviceWrite();
  const form = useForm({
    onSubmit: () => {
      let fd = new FormData();
      fd.set("model_id", String(model_id_f.value));
      fd.set("owner_id", String(owner_id_f.value));
      fd.set("locationType", location_f.type);
      fd.set("room_id", String(location_f.room_id.value));
      fd.set("laboratory_id", String(location_f.laboratory_id.value));
      fd.set("container_id", String(location_f.container_id.value));
      fd.set("location_other", location_f.location_other.value);
      fd.set("serialnumber", serialnumber_f.value);
      apiResult.doQuery(el, fd);
    },
    fields: {
      manufacturer_id_f,
      model_id_f,
      owner_id_f,
      //  room_id_f,
      serialnumber_f,
    },
    Template: FormDeviceQuick,
    fw: {
      el,
      onManufacturerAdded,
      onDeviceModelAdded,
      locationSwitchButtons: location_f.SwitchButtons,
      locationField: location_f.Field,
    },
  });

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

type FieldNames = "manufacturer_id_f" | "model_id_f" | "owner_id_f" | "serialnumber_f";

export const FormDeviceQuick: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  el?: NestorDevice;
  onManufacturerAdded: (result: any) => void;
  onDeviceModelAdded: (result: any) => void;

  locationSwitchButtons: JSX.Element;
  locationField: JSX.Element;
}> = (props) => {
  const [addManufacturer, setAddManufacturer] = useState<boolean>(false);
  const [addDeviceModel, setAddDeviceModel] = useState<boolean>(false);

  return (
    <>
      {addManufacturer ? (
        <ManufacturerModal
          show={true}
          handleClose={() => {
            setAddManufacturer(false);
          }}
          onValidated={(out) => {
            props.onManufacturerAdded(out);
            setAddManufacturer(false);
          }}
          fw={{}}
        />
      ) : null}

      {addDeviceModel ? (
        <DeviceModelModal
          show={true}
          handleClose={() => {
            setAddDeviceModel(false);
          }}
          onValidated={(out) => {
            props.onDeviceModelAdded(out);
            setAddDeviceModel(false);
          }}
          fw={{
            manufacturer_id: props.fields.manufacturer_id_f.value,
          }}
        />
      ) : null}

      <div className="d-flex">
        <div className="flex-grow-1 me-1">{props.fields.manufacturer_id_f.Content}</div>
        <button
          className="btn btn-primary mb-2"
          type="button"
          onClick={(e) => {
            setAddManufacturer(true);
          }}
        >
          Add a new one
        </button>
      </div>
      <div className="d-flex">
        <div className="flex-grow-1 me-1">{props.fields.model_id_f.Content}</div>
        <button
          className="btn btn-primary mb-2"
          type="button"
          onClick={(e) => {
            setAddDeviceModel(true);
          }}
        >
          Add a new one
        </button>
      </div>

      {props.fields.owner_id_f.Content}

      {props.fields.serialnumber_f.Content}

      <h6>Where is this device located ?</h6>
      <div className="mb-1">{props.locationSwitchButtons}</div>
      {props.locationField}
    </>
  );
};
