import { NestorEmployee } from "nestor/types/company/employee";
import md5 from "crypto-js/md5";
import settings from "settings";

export const EmployeeAvatar: React.FunctionComponent<{
  employee: NestorEmployee;
  size?: number;
}> = ({ size = 26, ...props }) => {
  if (props.employee.email) {
    let hash = md5(props.employee.email);
    const wrapperDim = `${size + 4}px`;
    return (
      <div className="csem-avatar-wrapper" style={{ width: wrapperDim, height: wrapperDim }}>
        <img
          alt={props.employee.initials}
          width={size}
          height={size}
          src={`${settings.AVATAR_URL}${hash}?s=${size}&d=retro`}
          className="rounded-circle csem-avatar"
        />
      </div>
    );
  }
  return null;
};

export const EmployeeBadge: React.FunctionComponent<{
  employee: NestorEmployee;
  includeName?: boolean;
}> = (props) => {
  return (
    <div className="d-flex align-items-center">
      <EmployeeAvatar {...props} />

      <div className="ms-2">
        {props.includeName ? (
          <div style={{ width: 120 }} title={props.employee.fullname} className="text-truncate">
            <small>{props.employee.fullname}</small>
          </div>
        ) : null}
        <div>
          <span className="badge bg-secondary rounded-1">
            <small>{props.employee.initials}</small>
          </span>

          {props.employee.section ? (
            <>
              &nbsp;
              <span className="badge bg-secondary rounded-1">
                {props.employee.section?.division_id} {props.employee.section?.id}
              </span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const EmployeeBadgeComplete: React.FunctionComponent<{
  employee: NestorEmployee;
  showSection?: boolean;
}> = (props) => {
  return (
    <div className="d-flex">
      <EmployeeAvatar {...props} size={36} />
      <div className="ms-2">
        <strong>{props.employee.fullname}</strong>
        {props.showSection !== false && (
          <>
            &nbsp; (<span className="text-muted">{props.employee.section_id}</span>)
          </>
        )}
        <br />
        {props.employee.email}
      </div>
    </div>
  );
};
