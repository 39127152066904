import { usePcbLayoutList } from "nestor/hooks/api/usePcbLayoutList";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { GenericListRenderer } from "@csem/forms";
import { SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { PCBLayoutRenderer } from "../../renderers/PCBLayoutRenderer";

export const PcbLayoutColumn = <T,>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T) => NestorPcbLayout | undefined
) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <PCBLayoutRenderer layout={renderer(el)} fallbackText="No information" />;
    }),
    SearchElFilterFactory<NestorPcbLayout, NestorPcbLayout["id"]>({
      label: "PCB Layout",
      apiListFactory: usePcbLayoutList,
      traverser: (el) => el.id,
      filterKeys: {
        name: "contains",
      },
      template: GenericListRenderer((e) => <PCBLayoutRenderer layout={e} fallbackText="No information" />),
      key: "id",
    }),
    field
  );
};
