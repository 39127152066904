import { IOState } from "@csem/api";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useNestorAPI } from "../hooks/api/useNestorAPI";

export const useHasPermission = (permission: string) => {
  const ctx = useContext(NestorPermissionContext);
  if (!ctx) {
    return false;
  }
  return !!ctx?.permissions[permission];
};

export const usePermissionRefresh = () => {
  const ctx = useContext(NestorPermissionContext);
  if (!ctx) {
    return undefined;
  }
  return ctx?.refresh;
};

type NestorPermissions = { [x: string]: boolean };
export const NestorPermissionContext = React.createContext<
  { permissions: NestorPermissions; refresh: Function } | undefined
>(undefined);

export const NestorPermissionContextProvider = (
  props: React.PropsWithChildren<{ ressource: string; id: string | number }>
) => {
  const apiCall = useNestorAPI<NestorPermissions>(`permissions/${props.ressource}/${props.id}`, true);
  const { doQuery, result } = apiCall;

  const shim = useCallback(() => {}, []);
  const val = useMemo(() => {
    if (!result) {
      return;
    }

    return {
      permissions: result || {},
      refresh: doQuery,
    };
  }, [result, doQuery]);

  if (apiCall.state.success || apiCall.state.error) {
    return <NestorPermissionContext.Provider value={val}>{props.children}</NestorPermissionContext.Provider>;
  } else {
    return <IOState loadingMessage="Loading your permissions..." source={apiCall.state} />;
  }
};
