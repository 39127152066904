// Hooks

// Classes

// Components

// Contexts
import { useAddressList } from "nestor/hooks/api/useAddressList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useAmIElevated } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorAddress } from "nestor/types/company/address";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterContext, StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { AddressAddEditModal } from "./modals/AddEdit";

type T = NestorAddress;
type FW = { isElevated: boolean };
type FWLst = EditMethod<T, FW> & RemoveMethod<T, FW>;
const Table = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("name", "Name", (e) => e.name),
      SimpleColumn("address", "Address", (e) => e.address),
      SimpleColumn("address2", "Address (2)", (e) => e.address2),
      SimpleColumn("city", "City", (e) => e.postal_code + " " + e.city),
      ButtonsColumn("actions", "", [EditButton(), RemoveButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnVisibility: (cols, fw) => {
      if (fw.isElevated) {
        return { actions: true };
      } else {
        return { actions: false };
      }
    },
  });
})();

const Container = (function () {
  return HasDeletion(
    HasAddition(
      HasEdition(
        HasRefresh(HasPagination(GenericListContent<FW, T>(), undefined, StandardTableFilterContext)),
        AddressAddEditModal
      ),
      AddressAddEditModal,
      "Add a new address",
      "plus",
      "success"
    ),
    "Confirm address deletion",
    (v: T) => `Are you sure to revoke this address ?`,
    (props) => {
      return useNestorAPI<undefined, NestorAddress>(
        (address) => {
          assert(address !== undefined, "Address should exist");
          return NestorServerRoutes.address_delete(address.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();

export const AddressListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    return defaultFilter;
  }, []);

  const isElevated = useAmIElevated();
  return (
    <div className="m-2">
      <CardComponent header="Address list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useAddressList()}
            fwProps={{ isElevated }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
