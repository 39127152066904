import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorProjectId } from "nestor/types/projects/projects";
import { NestorCostElement, NestorCostElementAlias } from "nestor/types/purchasing/cost_element";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";

export const useCostElementsField = (
  props: CommonFieldUI<string | number | undefined> & {
    projectId: NestorProjectId | undefined;
    type: "project" | "sector";
  }
) => {
  return useFormSelectRemoteField<NestorCostElement, any>({
    ...props,
    useApiHook: useNestorAPI,

    url:
      props.type === "project"
        ? props.projectId
          ? `company/projects/${props.projectId}/cost-elements`
          : undefined
        : "purchasing/cost-elements-section",
    keyMap: (el: NestorCostElement) => el.id,
    valMap: (el: NestorCostElement) => el.id + " - " + el.description,
  });
};

export const useCostElementAliasesField = (
  props: CommonFieldUI<string | number | undefined> & {
    projectId: NestorProjectId | undefined;
  }
) => {
  return useFormSelectRemoteField<NestorCostElementAlias, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: props.projectId ? `company/projects/${props.projectId}/costElementAliases` : undefined,
    keyMap: (el: NestorCostElementAlias) => el.cost_element_id,
    valMap: (el: NestorCostElementAlias) => el.category.name,
  });
};

