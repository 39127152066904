import { GrantTaskModal } from "nestor/components/modals/GrantTask";
import { TerminateTaskModal } from "nestor/components/nestor/manufacturing/tasks/modals/TerminateTask";
import { PCBLayoutRenderer } from "nestor/components/renderers/PCBLayoutRenderer";
import { ProjectRenderer } from "nestor/components/renderers/ProjectRenderer";
import { DateRenderer } from "nestor/components/tables/columns/DateColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useAmIBT, useAmIBTAdmin } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorTask } from "nestor/types/manufacturing/task";
import { CardComponent } from "@csem/shared-utils";
import { useCallback, useState } from "react";
import settings from "settings";
import { EmployeeBadgeComplete } from "../../company/employees/EmployeeBadge";
import { TaskStatusRenderer } from "./List";

export const TaskInfoFromRequester = ({ task }: { task: NestorTask }) => {
  return (
    <CardComponent header="Information from the requester">
      <h6>Instructions</h6>
      <div className="render_lines">{task.instructions || <div className="text-muted">No information</div>}</div>
    </CardComponent>
  );
};

const TerminateTask = ({ task, onValidated }: { task: NestorTask; onValidated: (p: any) => any }) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const Modal = TerminateTaskModal({
    show: show,
    handleClose: handleClose,
    el: task,
    fw: {},
    onValidated,
  });

  return (
    <>
      {Modal}
      <button
        className="btn btn-warning w-100"
        onClick={() => {
          setShow(true);
        }}
      >
        Terminate the task
      </button>
    </>
  );
};

const GrantTask = ({ task, onValidated }: { task: NestorTask; onValidated: (p: any) => any }) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const Modal = GrantTaskModal({
    show: show,
    handleClose: handleClose,
    el: task,
    fw: {},
    onValidated,
  });

  return (
    <>
      {Modal}
      <button
        className="btn btn-success"
        onClick={() => {
          setShow(true);
        }}
      >
        Grant the task
      </button>
    </>
  );
};

export const TaskEditBT = ({ task, refresh }: { task: NestorTask; refresh: Function }) => {
  const isBT = useAmIBT();
  const isBTAdmin = useAmIBTAdmin();

  const apiGrant = useNestorAPI(`manufacturing/tasks/${task.id}/grant`, false);
  const apiUngrant = useNestorAPI(`manufacturing/tasks/${task.id}/ungrant`, false);
  const apiReopen = useNestorAPI(`manufacturing/tasks/${task.id}/reopen`, false);

  return (
    <CardComponent header="Task control" additionalClasses="">
      <div className="vstack gap-2">
        {isBTAdmin && (
          <div>
            <h6>Grant / Ungrant this task</h6>
            {task.granted ? (
              <button className="btn btn-warning w-100" onClick={() => apiUngrant.doQuery().then(() => refresh())}>
                Ungrant the task
              </button>
            ) : (
              <GrantTask task={task} onValidated={() => refresh()} />
            )}
          </div>
        )}
        {isBT && (
          <div>
            <h6>Terminate / Re-open this task</h6>
            {task.ended_at == null ? (
              <TerminateTask task={task} onValidated={() => refresh()} />
            ) : (
              <button className="btn btn-success w-100" onClick={() => apiReopen.doQuery().then(() => refresh())}>
                Re-open the task
              </button>
            )}
          </div>
        )}
      </div>
    </CardComponent>
  );
};

export const TaskInfoFromBT = ({ task }: { task: NestorTask }) => {
  return (
    <CardComponent header="Information from the BT">
      {/*<div className="mb-4">
        <h6>Changes made</h6>
        <div className="render_lines">
          {task.description_changes || <div className="text-muted">No information</div>}
        </div>
  </div>{" "}*/}
      <div className="mb-4">
        <h6>If the deadline was exceed, the reason</h6>
        <div className="render_lines">
          {task.description_deadline || <div className="text-muted">No information</div>}
        </div>
      </div>{" "}
      <div className="mb-4">
        <h6>Deliverables</h6>
        <div className="render_lines">
          {task.description_deliverables || <div className="text-muted">No information</div>}
        </div>
      </div>
    </CardComponent>
  );
};

export const TaskProgressCard = ({ task }: { task: NestorTask }) => {
  return (
    <CardComponent header="Information from the BT" additionalClasses="">
      <div className="row  mb-3">
        <div className="col-4">Numbers of hours requested</div>
        <div className="col-8">
          <h6>{task.requested_hours} hours</h6>
        </div>
      </div>
      <div className="row  mb-3">
        <div className="col-4">Numbers of hours consumed</div>
        <div className="col-8">
          <h6>{task.executed_hours} hours</h6>
        </div>
      </div>
      <div className="row r mb-3">
        <div className="col-4">Task completion</div>
        <div className="col-8">
          <h6>{task.last_percentage}</h6>
        </div>
      </div>
    </CardComponent>
  );
};

export const TaskDownloadEF952 = ({ task }: { task: NestorTask }) => {
  return (
    <CardComponent header="EF-952">
      <button
        type="button"
        className="btn btn-info w-100"
        onClick={() => {
          document.location.href = settings.NESTOR_URL + NestorServerRoutes.task_download_ef952(task.id);
        }}
      >
        Download EF-952
      </button>
    </CardComponent>
  );
};

export const TaskPriorityBadge = ({ task }: { task: NestorTask }) => {
  if (!task.granted) {
    return null;
  }

  return <>{task.priority}</>;
  /*
    let priority_class: string = "";
    if (task.priority > 800) {
        priority_class = "bg-danger";
    } else if (task.priority > 500) {
        priority_class = "bg-warning";
    } else if (task.priority > 200) {
        priority_class = "bg-primary";
    } else {
        priority_class = "bg-success";
    }
*/
  //  return <div className={`badge bg-secondary`}>{task.priority}</div>;
};

export const TaskInfoCard = ({ task, refresh }: { task: NestorTask; refresh: Function }) => {
  return (
    <CardComponent header="Task information">
      {task.granted ? null : (
        <div className="alert alert-warning">The BT administrator has not yet approved this task</div>
      )}

      <div className="row align-items-center mb-3">
        <div className="col-4">NUID</div>
        <div className="col-8">
          <h4>{task.nuid}</h4>
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Type</div>
        <div className="col-8">
          <h4>{task.type?.name}</h4>
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Name</div>
        <div className="col-8">
          <h4>{task.name}</h4>
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Priority</div>
        <div className="col-8">
          <TaskPriorityBadge task={task} />
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Project</div>
        <div className="col-8">
          <ProjectRenderer project={task.project || null} fallbackText="No project information" />
        </div>
      </div>

      <div className="row  mb-3">
        <div className="col-4">Workpackage</div>
        <div className="col-8">
          <h6>
            {task.project_workpackage ? (
              <>
                {task.project_workpackage.workpackage}{" "}
                <small className="text-muted">({task.project_workpackage.name}) </small>
              </>
            ) : (
              "Unknown"
            )}
          </h6>
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Requested by</div>
        <div className="col-8">
          {task.requester ? (
            <EmployeeBadgeComplete employee={task.requester} />
          ) : (
            <div className="text-muted">No information</div>
          )}
        </div>
      </div>

      {task.assignees && task.assignees.length > 0 ? (
        <div className="row align-items-center mb-3">
          <div className="col-4">Assignees</div>
          <div className="col-8">
            {task.assignees?.map((assignee) => (
              <EmployeeBadgeComplete employee={assignee} />
            ))}
          </div>
        </div>
      ) : null}

      {task.terminator ? (
        <div className="row align-items-center mb-3">
          <div className="col-4">Terminated by</div>
          <div className="col-8">
            <EmployeeBadgeComplete employee={task.terminator} />
          </div>
        </div>
      ) : null}

      {task.type?.has_pcbid ? (
        <div className="row align-items-center mb-3">
          <div className="col-4">PCB layout</div>
          <div className="col-8">
            <PCBLayoutRenderer layout={task.pcblayout} fallbackText="No information" />
          </div>
        </div>
      ) : null}

      {task.type?.has_count ? (
        <div className="row align-items-center mb-3">
          <div className="col-4">Number of items</div>
          <div className="col-8">{task.num_items}</div>
        </div>
      ) : null}

      <div className="row  mb-3">
        <div className="col-4">Number of hours requested</div>
        <div className="col-8">
          <h6>{task.requested_hours}</h6>
        </div>
      </div>

      <div className="row  mb-3">
        <div className="col-4">Server path</div>
        <div className="col-8">{task.server_path}</div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">State</div>
        <div className="col-8">
          <TaskStatusRenderer task={task} />
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Created at</div>
        <div className="col-8">
          <DateRenderer el={task.created_at} />
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Earliest starting date</div>
        <div className="col-8">
          <DateRenderer el={task.earliest_start_date} />
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Due date</div>
        <div className="col-8">
          <DateRenderer el={task.due_date} />
        </div>
      </div>

      <div className="row align-items-center mb-3">
        <div className="col-4">Started at</div>
        <div className="col-8">
          <DateRenderer el={task.started_at} />
        </div>
      </div>
    </CardComponent>
  );
};
