import { AuditableType, AuditContent } from "nestor/components/nestor/audit/AuditContent";
import { useRouteMatch } from "react-router-dom";

export const AuditMain = () => {
  return <div className="p-3">{<AuditMainInner />}</div>;
};

const AuditMainInner = () => {
  const route = useRouteMatch<{ type: string; id: string }>();
  const acceptableTypes = ["container", "laboratory", "room"];
  const type = route.params.type;
  if (!(type == AuditableType.ROOM || type === AuditableType.LABORATORY || type === AuditableType.CONTAINER)) {
    return <div className="alert alert-danger">Not allowed to audit this type of object</div>;
  }

  const id = parseInt(route.params.id);
  if (isNaN(id)) {
    return <div className="alert alert-danger">Cannot parse this identifier</div>;
  }

  return <AuditContent type={type} id={id} />;
};
