import { NestorAddressId } from "./types/company/address";
import { NestorBackofficeId } from "./types/company/backoffice";
import { NestorDivision } from "./types/company/division";
import { NestorLaboratoryId } from "./types/company/laboratory";
import { NestorCalibrationId } from "./types/inventory/calibration";
import { NestorCalibrationRangeId } from "./types/inventory/calibration_range";
import { NestorDeviceId } from "./types/inventory/device";
import { NestorDeviceAssociation, NestorDeviceAssociationId } from "./types/inventory/device_association";
import { NestorDeviceMaintenanceActionId } from "./types/inventory/device_maintenance_actions";
import { NestorDeviceModelId } from "./types/inventory/device_model";
import { NestorDeviceModelParameterId } from "./types/inventory/device_model_parameter";
import { NestorLoanId } from "./types/inventory/loan";
import { NestorMaintenanceId } from "./types/inventory/maintenance";
import { NestorPcbId } from "./types/manufacturing/pcb";
import {
  NestorPcbAssemblyFeederId,
  NestorPcbAssemblyFeederSetId,
  NestorPcbAssemblyId,
} from "./types/manufacturing/pcb_assembly";
import { NestorPcbLayoutId } from "./types/manufacturing/pcb_layout";
import { NestorTaskId } from "./types/manufacturing/task";
import { NestorProjectId } from "./types/projects/projects";
import { NestorProjectSupplierId } from "./types/projects/project_suppliers";
import { NestorArticleId } from "./types/purchasing/article";
import { NestorOrderId } from "./types/purchasing/orders";
import { NestorOrderFeedbackId } from "./types/purchasing/order_feedback";
import { NestorParcelId } from "./types/purchasing/parcel";
import { NestorSupplierId } from "./types/purchasing/supplier";
import { NestorSupplierQualificationId } from "./types/purchasing/supplier_qualification";
import { NestorContainerId } from "./types/stock/container";
import { NestorSkuShortcutId, NestorSkuShortcutPositionId } from "./types/stock/shortcut";
import { NestorSkuId } from "./types/stock/sku";
import { NestorLocationId } from "./types/system/location";

const NestorServerRoutes = {

  inventory: {
    device_models: {
      forms: {
        create: () => `inventory/device_models/forms/create`,
        update: (id: NestorDeviceModelId) => `inventory/device_models/${id}/forms/update`
      }
    },

    devices: {
      associations: {
        delete: (id: NestorDeviceId, articleId: NestorArticleId) => `inventory/devices/${id}/associations/${articleId}`
      },

      orderLinks: {
        delete: (id: NestorDeviceId, orderId: NestorOrderId) => `inventory/devices/${id}/linkOrder/${orderId}`
      },

      maintenances: {
        actions: {
          forms: {
            'create': (id: NestorDeviceId) => `inventory/devices/${id}/forms/maintenance_actions/create`,
            update: (id: NestorDeviceId, actionId: NestorDeviceMaintenanceActionId) => `inventory/devices/${id}/forms/maintenance_actions/${actionId}/update`,
          }
        }
      },

      loans: {
        forms: {
          return: (id: NestorDeviceId, loanId: NestorLoanId) => `inventory/devices/${id}/forms/loan/${loanId}/return`
        }
      },

      calibrations: {
        ranges: {
          delete: (id: NestorCalibrationRangeId) => `inventory/calibration_ranges/${id}`
        },

        forms: {
          revoke: (id: NestorCalibrationId) => `inventory/calibrations/${id}/forms/revoke`
        }
      },

      mass_migrate: () => "inventory/devices/mass_migrate"

    },
  },

  suppliers: {

    criticalities: {
      project_list: () => `purchasing/suppliers/criticalities/list`,
      project_revoke: (id: NestorProjectSupplierId) =>
        `purchasing/suppliers/criticalities/${id}`,
      project_create: () =>
        `purchasing/suppliers/criticalities/forms/create`,
      project_update: (id: NestorProjectSupplierId) =>
        `purchasing/suppliers/criticalities/${id}/forms/update`,

    },


  },

  address_list: () => `company/addresses/list`,
  address_all: () => `company/addresses/all`,
  address_create: () => `company/addresses/forms/create`,
  address_update: (id: NestorAddressId) => `company/addresses/${id}/forms/update`,
  address_delete: (id: NestorAddressId) => `company/addresses/${id}`,

  contact_list: (id: NestorSupplierId) => `purchasing/suppliers/${id}/contacts`,
  container_list: () => `list/stock/containers`,

  laboratory_create: () => `company/laboratories/forms/create`,
  laboratory_update: (id: NestorLaboratoryId) => `company/laboratories/${id}/forms/update`,
  laboratory_revoke: (id: NestorLaboratoryId) => `company/laboratories/${id}/forms/revoke`,
  location_content: (locationType: string, id: NestorLocationId) => `company/locations/${locationType}/${id}`,

  order_suggest_signees: (id: NestorOrderId) => `purchasing/orders/${id}/suggest_signees`,


  order_update: (id: NestorOrderId) => `purchasing/orders/${id}/forms/update`,
  order_goods_received: (id: NestorOrderId, received: boolean) =>
    `purchasing/orders/${id}/goods_received/${received ? 1 : 0}`,
  order_subtotal: (id: NestorOrderId) => `purchasing/orders/${id}/subtotal`,
  order_details: (id: NestorOrderId) => `purchasing/orders/${id}`,
  order_change_state: (id: NestorOrderId, state: number) => `purchasing/orders/${id}/changestate/${state}`,
  order_articles: (id: NestorOrderId) => `list/purchasing/orders/${id}/articles`,
  order_skus: (id: NestorOrderId) => `list/purchasing/orders/${id}/skus`,
  order_devices: (id: NestorOrderId) => `list/purchasing/orders/${id}/devices`,
  order_create_snapshot: (id: NestorOrderId) => `purchasing/orders/${id}/create_snapshot`,

  all_accounts: () => `purchasing/suppliers/accounts`,
  supplier_accounts: (id: NestorSupplierId) => `purchasing/suppliers/${id}/accounts`,
  supplier_account_create: (id: NestorSupplierId) => `purchasing/suppliers/${id}/accounts/forms/create`,
  supplier_account_update: (id: NestorSupplierId, bid: NestorBackofficeId) =>
    `purchasing/suppliers/${id}/accounts/${bid}/forms/update`,
  supplier_account_delete: (id: NestorSupplierId, bid: NestorBackofficeId) =>
    `purchasing/suppliers/${id}/accounts/${bid}`,

  device_create: () => `inventory/devices/forms/create`,
  device_update: (id: NestorDeviceId) => `inventory/devices/${id}/forms/update`,

  device_metrological_confirmation_single: (id: NestorCalibrationId) =>
    `inventory/calibrations/${id}/metrological_record`,
  device_metrological_confirmation_list: (id: NestorCalibrationId) =>
    `list/inventory/calibrations/${id}/metrological_records`,

  device_list: () => `list/inventory/devices`,
  device_list_by_bu: (bu: NestorDivision) => `list/inventory/devices`,
  device_list_not_calibrated: () => `inventory/calibration/not-calibrated`,
  device_list_not_checked: () => `inventory/calibration/not-checked`,
  device_list_not_maintained: () => `inventory/calibration/not-maintained`,

  device_associated_to: (id: NestorDeviceId) => `list/inventory/devices/${id}/associated_to`,
  device_calibration_list: (id: NestorDeviceId) => `list/inventory/devices/${id}/calibrations`,
  device_calibration_range_list: (id: NestorDeviceId) => `list/inventory/devices/${id}/calibration_ranges`,
  device_calibration_single: (id: NestorDeviceId) => `inventory/calibrations/${id}`,

  device_loan_create: (id: NestorDeviceId) => `inventory/devices/${id}/forms/loan/create`,
  device_park_list: () => `inventory/device_parks/list`,
  device_park_create: () => `inventory/device_parks/forms/create`,
  device_park_update: (id: NestorAddressId) => `inventory/device_parks/${id}/forms/update`,
  device_park_delete: (id: NestorAddressId) => `inventory/device_parks/${id}`,

  maintenance_delete: (id: NestorMaintenanceId) => `inventory/maintenances/${id}`,
  maintenance_associate: (id: NestorDeviceId) => `inventory/devices/${id}/forms/maintenance/associate/create`,
  maintenance_dissociate: (id: NestorDeviceId, maintenance_id: NestorMaintenanceId) =>
    `inventory/devices/${id}/maintenance/${maintenance_id}`,
  maintenance_associate_update: (id: NestorDeviceId) => `inventory/devices/${id}/forms/maintenance/associate/update`,

  sku_list_container: (id: NestorContainerId) => `list/stock/containers/${id}/skus`,
  sku_list_expired: () => `list/stock/skus/expired`,
  sku_list: () => `list/stock/skus`,
  sku_derived_list: (id: NestorSkuId) => `stock/skus/${id}/derived/list`,
  sku_derived_create: (id: NestorSkuId) => `stock/skus/${id}/derived/form`,

  sku_shortcut_list: () => `stock/sku_shortcuts/list`,
  sku_shortcut_skus: (id: NestorSkuShortcutId) => `stock/sku_shortcuts/${id}/skus/list`,
  sku_shortcut_single: (id: NestorSkuShortcutId) => `stock/sku_shortcuts/${id}`,
  sku_shortcut_position_list: (id: NestorSkuShortcutId) => `stock/sku_shortcuts/${id}/positions/list`,
  sku_shortcut_position_list_flat: (id: NestorSkuShortcutId) => `stock/sku_shortcuts/${id}/positions/list_flat`,
  sku_shortcut_update_position: (id: NestorSkuShortcutId, posId: NestorSkuShortcutPositionId) =>
    `stock/sku_shortcuts/${id}/positions/${posId}/forms/update`,
  sku_shortcut_create_position: (id: NestorSkuShortcutId) => `stock/sku_shortcuts/${id}/positions/forms/create`,

  sku_shortcut_create: () => `stock/sku_shortcuts/forms/create`,
  sku_shortcut_update: (id: NestorSkuShortcutId) => `stock/sku_shortcuts/${id}/forms/update`,
  sku_print_label: (id: NestorSkuId) => `stock/skus/${id}/label/pdf/print`,
  task_create: () => `manufacturing/tasks/forms/create`,
  task_update: (id: NestorTaskId) => `manufacturing/tasks/${id}/forms/update`,
  task_terminate: (id: NestorTaskId) => `manufacturing/tasks/${id}/terminate`,

  pcb_assembly_list: () => `manufacturing/pcb_assembly/list`,
  pcb_assembly_single: (id: NestorPcbAssemblyId) => `manufacturing/pcb_assembly/${id}`,
  pcb_assembly_load_bom: (id: NestorPcbAssemblyId) => `manufacturing/pcb_assembly/${id}/load`,
  pcb_assembly_save_bom: (id: NestorPcbAssemblyId) => `manufacturing/pcb_assembly/${id}/save`,
  pcb_assembly_create: () => `manufacturing/pcb_assembly/forms/create`,
  pcb_assembly_update: (id: NestorPcbAssemblyId) => `manufacturing/pcb_assembly/${id}/forms/update`,
  pcb_assembly_revoke: (id: NestorPcbAssemblyId) => `manufacturing/pcb_assembly/${id}`,

  pcb_assembly_component_list: (id: NestorPcbAssemblyId) => `manufacturing/pcb_assembly/${id}/components/list`,
  pcb_assembly_component_list_flat: (id: NestorPcbAssemblyId) => `manufacturing/pcb_assembly/${id}/components/flat`,
  pcb_assembly_component_label: () => `manufacturing/pcb_assembly/component_label`,

  // Feeders and feeder sets
  pcb_assembly_feeder_set_list: () => "manufacturing/pcb_assembly/feeder_sets/list",
  pcb_assembly_feeder_set_single: (id: NestorPcbAssemblyFeederSetId) => `manufacturing/pcb_assembly/feeder_sets/${id}`,
  pcb_assembly_feeder_set_revoke: (id: NestorPcbAssemblyFeederSetId) => `manufacturing/pcb_assembly/feeder_sets/${id}`,
  pcb_assembly_feeder_set_create: () => `manufactuing/pcb_assembly/feeder_sets/forms/create`,
  pcb_assembly_feeder_set_update: (id: NestorPcbAssemblyFeederSetId) =>
    `manufacturing/pcb_assembly/feeder_sets/${id}/forms/update`,

  pcb_assembly_feeder_list: (id: NestorPcbAssemblyFeederSetId) =>
    `manufacturing/pcb_assembly/feeder_sets/${id}/feeders/list`,
  pcb_assembly_feeder_list_search: (mode: string) => `manufacturing/pcb_assembly/feeders/list/${mode}`,
  pcb_assembly_feeder_revoke: (id: NestorPcbAssemblyFeederSetId, feederId: NestorPcbAssemblyFeederId) =>
    `manufacturing/pcb_assembly/feeder_sets/${id}/feeders/${feederId}`,
  pcb_assembly_feeder_create: (id: NestorPcbAssemblyFeederSetId) =>
    `manufactuing/pcb_assembly/feeder_sets/${id}/feeders/forms/create`,
  pcb_assembly_feeder_update: (id: NestorPcbAssemblyFeederSetId, feederId: NestorPcbAssemblyFeederId) =>
    `manufacturing/pcb_assembly/feeder_sets/${id}/feeders/${feederId}/forms/update`,
  pcb_assembly_feeder_add_alias: (id: NestorPcbAssemblyFeederSetId, feederId: NestorPcbAssemblyFeederId) =>
    `manufacturing/pcb_assembly/feeder_sets/${id}/feeders/${feederId}/add_alias`,

  task_download_ef952: (id: NestorTaskId) => `/download/tasks/${id}/ef952`,
  building_list: () => `company/buildings`,

  backoffice_list: () => `user/backoffices`,
  managed_project_list: () => `user/managed_projects`,

  admin_merge_suppliers: (from: NestorSupplierId | undefined, to: NestorSupplierId | undefined) =>
    `purchasing/suppliers/merge/${from}/${to}`,
  signature_sign: (key: string) => `signature/${key}/sign`,
  signature_sign_check: (key: string) => `signature/${key}/sign_check`,
  signature_remind: (order_id: NestorOrderId) => `purchasing/orders/${order_id}/signing_reminder`,
  signature_cancel: (order_id: NestorOrderId) => `purchasing/orders/${order_id}/signing_process`,
  signature_get_csem_path_win: (order_id: NestorOrderId) => `purchasing/orders/${order_id}/signing_get_server_path`,
  resolve_location: (id: NestorLocationId, type: string) => `company/resolveLocation/${type}/${id}`,
  package_create: (id: NestorOrderId) => `purchasing/orders/${id}/packages/forms/create`,
  package_edit: (order_id: NestorOrderId, parcel_id: NestorParcelId) =>
    `purchasing/orders/${order_id}/packages/${parcel_id}/forms/update`,
  package_add_stock: (order_id: NestorOrderId, parcel_id: NestorParcelId) =>
    `purchasing/orders/${order_id}/package/${parcel_id}/stock/entry`,
  package_add_inventory: (order_id: NestorOrderId, parcel_id: NestorParcelId) =>
    `purchasing/orders/${order_id}/package/${parcel_id}/inventory/entry`,
  package_add_pcb: (order_id: NestorOrderId, parcel_id: NestorParcelId) =>
    `purchasing/orders/${order_id}/package/${parcel_id}/pcb/entry`,
  package_ef612: (id: NestorParcelId) => `/download/sku_entries/${id}/ef612`,

  pcb_add: (layout_id: NestorPcbLayoutId) => `manufacturing/pcblayouts/${layout_id}/pcbs/forms/create`,
  pcb_attributes: (pcbid: NestorPcbId) => `list/manufacturing/pcbs/${pcbid}/attributes`,



  supplier_feedback_list: (supplier_id: NestorSupplierId) => `purchasing/orders/feedbacks/supplier/${supplier_id}`,
  supplier_feedback_average: (supplier_id: NestorSupplierId) =>
    `purchasing/orders/feedbacks/supplier/${supplier_id}/average`,

  order_feedback_list: (order_id: NestorOrderId) => `purchasing/orders/feedbacks/order/${order_id}`,
  order_feedback_delete: (feedback_id: NestorOrderFeedbackId) => `purchasing/orders/feedbacks/${feedback_id}`,
  order_feedback_create: (order_id: NestorOrderId) => `purchasing/orders/feedbacks/order/${order_id}/forms/create`,
  order_feedback_update: (order_id: NestorOrderId, feedback_id: NestorOrderFeedbackId) =>
    `purchasing/orders/feedbacks/order/${order_id}/forms/${feedback_id}/update`,

  order_feedback_categories: () => `purchasing/orders/feedbacks/categories`,

  supplier_qualification_list: (supplier_id: NestorSupplierId) =>
    `purchasing/suppliers/qualifications/supplier/${supplier_id}/list`,
  supplier_qualification_delete: (qualification_id: NestorSupplierQualificationId) =>
    `purchasing/suppliers/qualifications/${qualification_id}`,
  supplier_qualification_revoke: (qualification_id: NestorSupplierQualificationId) =>
    `purchasing/suppliers/qualifications/${qualification_id}/revoke`,
  supplier_qualification_get: (qualification_id: NestorSupplierQualificationId) =>
    `purchasing/suppliers/qualifications/${qualification_id}`,
  supplier_qualification_toggle_project: (
    qualification_id: NestorSupplierQualificationId
  ) => `purchasing/suppliers/qualifications/${qualification_id}/projects`,
  supplier_qualification_create: (supplier_id: NestorSupplierId) =>
    `purchasing/suppliers/qualifications/supplier/${supplier_id}/forms/create`,
  supplier_qualification_update: (supplier_id: NestorSupplierId, qual_id: NestorSupplierQualificationId) =>
    `purchasing/suppliers/qualifications/supplier/${supplier_id}/forms/${qual_id}/update`,
  supplier_qualification_ef611: (id: NestorSupplierQualificationId) => `/download/qualifications/${id}/ef611`,

  project_get: (project_id: NestorProjectId) => `company/projects/${project_id}`,
  project_critical_supplier_list: () =>
    `company/projects/criticalities/list`,

  device_model_parameter_list: (id: NestorDeviceModelId) => `inventory/device_models/${id}/parameters/table`,
  device_model_parameter_list_flat: (id: NestorDeviceModelId) => `inventory/device_models/${id}/parameters/list`,

  device_model_parameter_edit: (modelId: NestorDeviceModelId, id: NestorDeviceModelParameterId) => {
    return `inventory/device_models/${modelId}/parameters/${id}/update`;
  },

  device_model_parameter_create: (modelId: NestorDeviceModelId) => {
    return `inventory/device_models/${modelId}/parameters/create`;
  },

  device_association_create: (id: NestorDeviceId) => {
    return `inventory/devices/${id}/forms/association/create`;
  },

  device_association_update: (id: NestorDeviceId, association_id: NestorDeviceAssociationId) => {
    return `inventory/devices/${id}/forms/association/${association_id}/update`;
  },

  device_association_update_via_param: (id: NestorDeviceId, parameter_id: NestorDeviceModelParameterId) => {
    return `inventory/devices/${id}/forms/association/param/${parameter_id}/update`;
  },

  system_sheet_download: (id: NestorDeviceId) => {
    return `/download/inventory/devices/${id}/ef9XX`;
  },

  settings_list(type: string, id: number) {
    return `settings/${type}/${id}/list`;
  },
  settings(type: string, id: number) {
    return `settings/${type}/${id}`;
  },
};

export default NestorServerRoutes;
