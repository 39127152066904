import { useSupplierList } from "nestor/hooks/api/useSupplierList";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { SupplierRenderer } from "../../renderers/SupplierRenderer";

export const useSupplierSearchField = FormSearchHookFactory<NestorSupplier, NestorSupplier["id"]>({
  apiListFactory: useSupplierList,
  template: GenericListRenderer((el) => {
    return <SupplierRenderer supplier={el} />;
  }),
  filterKeys: {
    id: "eq",
    name: "contains",
    nuid: "contains",
  },
  traverser_display: (el) => el.name,
  uniqueKeyField: "id",
});
