import { useContainerList } from "nestor/hooks/api/useContainerList";
import { NestorContainer } from "nestor/types/stock/container";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { useState } from "react";
import { ContainerQuickAddEditModal } from "../../nestor/stock/containers/modals/AddEditContainer";
import { ContainerRenderer } from "../../renderers/Container";

type T = NestorContainer;

export const useContainerSearchField = FormSearchHookFactory<T, T["id"]>({
  apiListFactory: useContainerList,
  template: GenericListRenderer((el) => <ContainerRenderer container={el} />),
  traverser_display: (el) => {
    return `${el.name}`;
  },

  uniqueKeyField: "id",
  filterKeys: {
    nuid: "contains",

    "employee.fullname": "contains",
    "employee.initials": "eq",
    "project.code": "contains",
    "project.name": "contains",
    name: "contains",
  },
});

export function useContainerFieldWithCreate(label: string = "Container") {
  const [containerId, setContainerId] = useState<number>();
  const container_id = useContainerSearchField({
    label: label,
    defaultValue: undefined,
    validationOnChange: { required: true },
    originalValue: containerId,
  });

  const [show, setShow] = useState<boolean>(false);
  const Modal = (
    <ContainerQuickAddEditModal
      show={show}
      handleClose={() => {
        setShow(false);
      }}
      onValidated={(out) => {
        setContainerId(out.response.id);
        setShow(false);
      }}
      fw={{}}
    />
  );

  return {
    container_id: container_id.value,
    Content: (
      <>
        {Modal}
        {container_id.Content}
        <button className="btn btn-primary mb-2 ms-3" onClick={() => setShow(true)}>
          <em className="bi bi-plus"></em> Add a new container
        </button>
      </>
    ),
  };
}
