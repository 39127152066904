import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { ContainerCreate } from "./ContainerCreate";
import { ContainerDetail } from "./ContainerDetails";
import { ContainerList } from "./ContainerList";

export function ContainersMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List of containers", url + "/list");
  const create = useMenuElement("Create a new container", url + "/create");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="containerlist">
        <XemwayMenuProvider {...list}>
          <ContainerList></ContainerList>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/create`} key="containernew">
        <XemwayMenuProvider {...create}>
          <ContainerCreate></ContainerCreate>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/:container`} key="containerdetails">
        <ContainerDetail></ContainerDetail>
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
