import { useSkuList } from "nestor/hooks/api/useSkuList";
import { NestorSku } from "nestor/types/stock/sku";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { SkuRenderer } from "../../renderers/SkuRenderer";

export const useSkuSearchField = FormSearchHookFactory<NestorSku, NestorSku["id"]>({
  apiListFactory: useSkuList,
  template: GenericListRenderer((e) => <SkuRenderer sku={e} />),
  traverser_display: (el) => {
    return `${el.nuid}`; // ${/*el.laboratory ? `(${el.laboratory.name})` : ""*/}
  },

  uniqueKeyField: "id",
  filterKeys: {
    nuid: "eq",
    "article.description": "contains",
  },
});
