import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorCurrency } from "nestor/types/util/currency";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";

export const useCurrencyField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorCurrency, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: `purchasing/currencies`,

    keyMap: (el: NestorCurrency) => el.id,
    valMap: (el: NestorCurrency) => el.name,

    getterFromKey: (el: string | number | undefined, list?: NestorCurrency[]) => {
      if (el === undefined || list === undefined) {
        return;
      }
      return list.find((e) => e.id === el);
    },
  });
};
