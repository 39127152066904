import { IOState } from "@csem/api";
import { APIReturn, autoTrigger, t_endPoint } from "@csem/api";
import { useMemo } from "react";

export type NestorApiResult<T> = {
  data: T[];
  meta: {
    pager: {
      take: number;
      skip?: number;
    };
  };
  total: number;
};
const _autoTrigger = ["filtering", "invoicing"];

export function apiCallHookFactory<U>(
  useApiHook: <V, U>(
    endpoint: t_endPoint<U>,
    autoTrigger?: autoTrigger,
    searchParams?: URLSearchParams,
    fetchOptions?: RequestInit,
    callback?: (p: V) => Promise<any> | void,
    cache?: boolean
  ) => APIReturn<V, U>
) {
  function useHook<T>(
    apiEndpoint: string | undefined,
    name: string = "object",
    searchParams?: URLSearchParams,
    cache?: boolean
  ) {
    const {
      state,
      result,
      doQuery: refresh,
      setSearchParams,
    } = useApiHook<NestorApiResult<T>, U>(apiEndpoint, _autoTrigger, searchParams, undefined, undefined, cache);

    const loadingIO = useMemo(() => {
      return (
        <IOState
          source={state}
          success={null}
          loading={null}
          successMessage={undefined}
          loadingMessage={undefined}
          errorMessage={`Error while loading the ${name} list`}
        />
      );
    }, [name, state]);

    return {
      refresh,
      setSearchParams,
      list: result?.data,
      pagination: result?.data
        ? {
            page: (result?.meta.pager.skip || 0) / (result?.meta.pager.take || 1) + 1,
            pageSize: result?.meta.pager.take || 10,
            count: result?.total || 1,
          }
        : undefined,
      state,
      loadingIO,
    };
  }

  return useHook;
}
