import NestorServerRoutes from "nestor/server_routes";
import {
  NestorPcbAssembly,
  NestorPcbAssemblyFeeder,
  NestorPcbAssemblyFeederSet,
  NestorPcbAssemblyId,
  NestorPcbComponent,
} from "nestor/types/manufacturing/pcb_assembly";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorPcbAssembly;

export const usePcbAssemblyList = (): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.pcb_assembly_list(), "assembly");
};

export const usePcbAssemblySingle = (id: NestorPcbAssemblyId): NestorApiElement<NestorPcbAssembly, undefined> => {
  const apiCall = useNestorAPI<NestorPcbAssembly>(NestorServerRoutes.pcb_assembly_single(id), true);
  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading pcb assembly..." source={apiCall.state} success={null} />,
  };
};

export const usePcbComponentList = (pcb_assembly_id: T["id"]): apiList<NestorPcbComponent> => {
  return useNestorListApiCall<NestorPcbComponent>(
    NestorServerRoutes.pcb_assembly_component_list(pcb_assembly_id),
    "component"
  );
};

type U = NestorPcbAssemblyFeederSet;
type V = NestorPcbAssemblyFeeder;

export const usePcbAssemblyFeederSetList = () => {
  return useNestorListApiCall<U>(NestorServerRoutes.pcb_assembly_feeder_set_list(), "feeder set");
};

export const usePcbAssemblyFeederSetSingle = (id: U["id"]): NestorApiElement<U, undefined> => {
  const apiCall = useNestorAPI<U>(NestorServerRoutes.pcb_assembly_feeder_set_single(id), true);
  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading pcb feeder set..." source={apiCall.state} success={null} />,
  };
};

export const usePcbAssemblyFeederList = (pcb_assembly_set_id: U["id"]) => {
  return useNestorListApiCall<V>(NestorServerRoutes.pcb_assembly_feeder_list(pcb_assembly_set_id), "feeder", undefined);
};

export const usePcbAssemblyFeederAutoListSearch = () => {
  return useNestorListApiCall<V>(NestorServerRoutes.pcb_assembly_feeder_list_search("auto"), "feeder");
};

export const usePcbAssemblyFeederSemiListSearch = () => {
  return useNestorListApiCall<V>(NestorServerRoutes.pcb_assembly_feeder_list_search("semi"), "feeder", undefined, true);
};
