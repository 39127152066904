import { usePcbList } from "nestor/hooks/api/usePcbList";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { PCBRenderer } from "../../renderers/PCBRenderer";

type T = NestorPcb;
export const usePcbSearchField = FormSearchHookFactory<T, any>({
  apiListFactory: usePcbList,

  template: GenericListRenderer((e) => <PCBRenderer pcb={e} />),
  filterKeys: {
    nuid: "contains",

    "layout.name": "contains",
    "layout.project.name": "contains",
    "layout.project.code": "eq",
    "employee.initials": "eq",
    "employee.fullname": "contains",
  },
  traverser_display: (el) => {
    return el.nuid + " (" + el.layout?.name + ")";
  },
  uniqueKeyField: "id",
});
