import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PCBLayoutDetail } from "./PCBLayout";
import { PCBLayoutList } from "./PCBLayoutList";

export function PCBsMain() {
  const { url } = useRouteMatch();

  const layout_list = useMenuElement("List of PCB layouts", url + "/layout_list");

  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/layout_list`} key="layoutlist">
        <XemwayMenuProvider {...layout_list}>
          <PCBLayoutList></PCBLayoutList>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/layout/:layout`}>
        <PCBLayoutDetail></PCBLayoutDetail>
      </Route>

      {/*
            <Route path={`${url}/list`}>
                <XemwayMenuProvider {...list}>
                    <PCBList></PCBList>
                </XemwayMenuProvider>
            </Route>
            */}
      <Route>
        <Redirect to={`${url}/layout_list`} />
      </Route>
    </Switch>
  );
}
