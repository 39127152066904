import { useDeviceList } from "nestor/hooks/api/useDeviceList";
import { useSkuList } from "nestor/hooks/api/useSkuList";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorSku } from "nestor/types/stock/sku";
import { GenericListRenderer } from "@csem/forms";
import { SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { SkuRenderer } from "../../renderers/SkuRenderer";

type X = NestorSku;
export const SkuColumn = <T, U, V>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T, fw: U, hk: V) => X | undefined,
  withNav: boolean = false
) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el: T, fw: U, hk: V) => {
      return <SkuRenderer withNav={withNav} sku={renderer(el, fw, hk)} />;
    }),
    SearchElFilterFactory<X, X["id"]>({
      label: "SKU",
      apiListFactory: useSkuList,
      traverser: (el) => el.id,
      filterKeys: {
        name: "contains",
      },
      template: GenericListRenderer((e) => <SkuRenderer sku={e} />),
      key: "id",
    }),
    field
  );
};
