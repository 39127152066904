import { CalibrationAddEditModal } from "nestor/components/nestor/inventory/devices/modals/AddEditCalibration";
import { CalibrationRevokeModal } from "nestor/components/nestor/inventory/devices/calibrations/modals/RevokeCalibration";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useCalibrationList } from "nestor/hooks/api/useCalibrationList";
import { NestorCalibration } from "nestor/types/inventory/calibration";
import { NestorCalibrationConfirmation } from "nestor/types/inventory/calibration_confirmation";
import { NestorDevice } from "nestor/types/inventory/device";
import { CardComponent } from "@csem/shared-utils";
import { EditButton } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, NavigationColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { EditMethod, GenericListContent, HasAddition, HasEdition, HasModalMethod, HasRefresh } from "@csem/lists";
import moment from "moment";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { CalibrationConfirmationModal } from "../modals/AddEditCalibrationConfirmation";
type T = NestorCalibration;
type FWLst = { device: NestorDevice };

type FW = EditMethod<T, FWLst> & { confirm?: (el: T, fw: FWLst) => void; revoke?: (el: T, fw: FWLst) => void }; // & RemoveMethod<T, FWLst>;

const CalibrationConfirmationRenderer = (props: { confirmation?: NestorCalibrationConfirmation }) => {
  if (!props.confirmation) {
    return null;
  }

  const textClass = props.confirmation.passed ? "text-success" : "text-danger";

  return (
    <div className={textClass}>
      <div>
        <strong>Range: </strong>
        {props.confirmation.confirmation_range}
      </div>
      {props.confirmation.limitations ? (
        <div>
          <strong>Limitations: </strong>
          {props.confirmation.limitations}
        </div>
      ) : (
        ""
      )}

      {props.confirmation.comment ? (
        <div>
          <strong>Comment: </strong>
          {props.confirmation.comment}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const CalibrationTagRenderer = (props: { calibration: NestorCalibration; device?: NestorDevice }) => {
  return (
    <>
      {props.calibration.revoked_at ? (
        <div className="d-block badge bg-secondary">revoked</div>
      ) : moment(props.calibration.end).isBefore() ? (
        <div className="d-block badge bg-warning">obsolete</div>
      ) : (
        <>
          <div className="d-block badge bg-success">active</div>

          {props.calibration.metrological_records.length === 0 ? (
            <div className="d-block badge bg-warning">unconfirmed</div>
          ) : props.calibration.metrological_records[0].passed === 0 ? (
            <div className="d-block badge bg-danger">metrological check failed</div>
          ) : (
            <div className="d-block badge bg-success">metrological check ok</div>
          )}
        </>
      )}
    </>
  );
};

const GenericCalibrationTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      NavigationColumn("go", "", (el) => "./calibrations/" + el.id),
      SimpleColumn("status", "Status", (e, fw) => <CalibrationTagRenderer calibration={e} device={fw.device} />),
      SimpleColumn("range", "Range", (e) => e.calibration_range?.name),

      DateColumn("start", "Start", "start", (e) => e.start),
      DateColumn("end", "End", "end", (e) => e.end),

      FilterableColumn(
        SimpleColumn("calibrator", "Calibrator", (e) => e.calibrator),
        TextFilterFactory({ label: "Calibrator" }),
        "calibrator"
      ),
      EmployeeColumn("created_by", "Created by", "employee_id", (e) => e.employee),

      SimpleColumn("confirmation", "Confirmation", (e) => (
        <CalibrationConfirmationRenderer confirmation={e.metrological_records[0]} />
      )),
      EmployeeColumn("confirmed_by", "Confirmed by", undefined, (e) => e.metrological_records[0]?.employee),

      ButtonsColumn("actions", "Actions", [
        EditButton(),
        {
          title: "Confirmation",
          theme: "success",
          cb: (e, fw) => {
            fw.confirm!(e, fw);
          },
        },
        {
          title: "Revoke",
          visibility: (e) => {
            return e.revoked_at ? false : true;
          },
          theme: "danger",
          cb: (e, fw) => {
            fw.revoke!(e, fw);
          },
        },
      ]), // Add delegation and confirm buttons
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        status: "100px",
        actions: "250px",
        confirmation: "350px",
        created_by: "100px",
        confirmed_by: "100px",
        start: "100px",
        end: "100px",
      };
    },
  });
})();

const GenericCalibrationRangeListContainer = (function () {
  return HasStdTablePagination(
    HasModalMethod(
      HasModalMethod(
        HasEdition(
          HasAddition(
            HasRefresh(GenericListContent<FWLst, T>()),
            CalibrationAddEditModal,
            "Add a new calibration",
            "plus",
            "primary"
          ),
          CalibrationAddEditModal
        ),
        CalibrationConfirmationModal,
        "confirm"
      ),
      CalibrationRevokeModal,
      "revoke"
    )
  );
})();

export const CalibrationCard = (props: { device: NestorDevice }) => {
  return (
    <div className="m-2">
      <CardComponent header="Calibrations">
        <StandardTableFilterCtxProvider>
          <GenericCalibrationRangeListContainer
            //cardHeader="Gateway list"
            list={useCalibrationList(props.device.id)}
            fwProps={{ device: props.device }}
            listTemplate={GenericCalibrationTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
