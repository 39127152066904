import { usePcbComponentList } from "nestor/hooks/api/usePcbAssembly";
import NestorServerRoutes from "nestor/server_routes";
import {
  NestorPcbAssembly,
  NestorPcbComponent,
  NestorPcbComponentMountType,
} from "nestor/types/manufacturing/pcb_assembly";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { EmptyRow } from "@csem/lists";
import { IOState } from "@csem/api";
import { useAutoSearchTrigger } from "@csem/lists";
import _, { cloneDeep } from "lodash";
import { LabelPrinterButton } from "../../util/LabelPrinter";

const LayerName = {
  top: "Top",
  bottom: "Bottom",
};

const ModeName = {
  [NestorPcbComponentMountType.MANUAL]: "Manual",
  [NestorPcbComponentMountType.AUTO]: "Automatic PnP",
  [NestorPcbComponentMountType.SEMIAUTO]: "Semiautomatic PnP",
  [NestorPcbComponentMountType.ERZA]: "Erza",
  [NestorPcbComponentMountType.TBD]: "To be defined",
};

const ComponentList = ({
  layer,
  mode,
  components,
}: {
  layer: "top" | "bottom";
  mode: NestorPcbComponentMountType;
  components: Array<NestorPcbComponent>;
}) => {
  const [inFeeder, notInFeeder] = _.partition(components, (e) => e.feeder_id != null);

  const componentsInFeeder = _.groupBy(inFeeder, (e) => e.feeder_id);
  const componentsNotInFeeder = _.groupBy(notInFeeder, (e) => e.footprint + "_" + e.comment);

  return (
    <CardComponent
      additionalClasses="mb-2"
      header={"Components for mounting mode " + ModeName[mode] + " and layer " + LayerName[layer]}
    >
      <table className="table  csem-table">
        <tbody>
          <tr>
            <th colSpan={6}>Components in feeders</th>
          </tr>{" "}
          <tr>
            <th>#</th>
            <th>Designators</th>
            <th>Value (feeder)</th>
            <th>Footprint (feeder)</th>
            <th>Description (1st)</th>
            <th></th>
          </tr>
          {Object.entries(componentsInFeeder).map(([k, cs]) => {
            return (
              <tr>
                <td>{cs.length}</td>

                <td>{cs.map((c) => c.designator).join(", ")}</td>

                <td>{cs[0].feeder?.value}</td>
                <td>{cs[0].feeder?.footprint}</td>
                <td>{cs[0].description}</td>
                <td></td>
              </tr>
            );
          })}
          <EmptyRow span={6} when={Object.keys(componentsInFeeder).length == 0} />
          <tr>
            <th colSpan={6}>Components not in feeders</th>
          </tr>{" "}
          <tr>
            <th style={{ width: "50px" }}>#</th>
            <th style={{ width: "300px" }}>Designators</th>
            <th style={{ width: "200px" }}>Comment (1st)</th>
            <th style={{ width: "200px" }}>Footprint (1st)</th>
            <th>Description</th>
            <th style={{ width: "50px" }}>Print label</th>
          </tr>
          {Object.entries(componentsNotInFeeder).map(([k, cs]) => {
            return (
              <tr>
                <td>{cs.length}</td>
                <td>{cs.map((c) => c.designator).join(", ")}</td>

                <td>{cs[0].comment}</td>
                <td>{cs[0].footprint}</td>
                <td>{cs[0].description}</td>

                <td>
                  <LabelPrinterButton
                    size={LabelPrinterSizes.SZ_12}
                    url={NestorServerRoutes.pcb_assembly_component_label()}
                    additionalQueryParameters={{
                      layer,
                      description: cs[0].description,
                      footprint: cs[0].footprint,
                    }}
                  />
                </td>
              </tr>
            );
          })}
          <EmptyRow span={6} when={Object.keys(componentsNotInFeeder).length == 0} />
        </tbody>
      </table>
    </CardComponent>
  );
};

const defaultFilter = cloneDeep(DefaultStandardTableFilter);
defaultFilter.sort = [
  {
    dir: "desc",
    field: "designator",
  },
];
defaultFilter.take = 100000;

export const PcbAssemblyReportCardsInner = (props: { assembly: NestorPcbAssembly; refresh: Function }) => {
  const apiCallListComponents = usePcbComponentList(props.assembly.id);
  useAutoSearchTrigger(apiCallListComponents);
  if (!apiCallListComponents.list) {
    if (apiCallListComponents.state) {
      return <IOState source={apiCallListComponents.state} />;
    }
    return <></>;
  }
  assert(apiCallListComponents.list);
  const listByMode = _.groupBy(
    apiCallListComponents.list.filter((e) => !!e.enabled),
    (e) => e.mode
  );

  return (
    <>
      {Object.entries(listByMode).map(([k, v]) => {
        const listByFace = _.groupBy(v, (e) => e.layer);

        return (
          <>
            {listByFace.top && (
              <ComponentList
                layer="top"
                mode={k as NestorPcbComponentMountType}
                components={listByFace.top}
              ></ComponentList>
            )}
            {listByFace.bottom && (
              <ComponentList
                layer="bottom"
                mode={k as NestorPcbComponentMountType}
                components={listByFace.bottom}
              ></ComponentList>
            )}
          </>
        );
      })}
    </>
  );
};

export const PcbAssemblyReportCards = (props: { assembly: NestorPcbAssembly; refresh: Function }) => {
  return (
    <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
      <PcbAssemblyReportCardsInner {...props} />
    </StandardTableFilterCtxProvider>
  );
};
