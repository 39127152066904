import { SupplierFeedbackListCard } from "nestor/components/nestor/purchasing/suppliers/FeedbackList";
import { SupplierQualificationAddEditCard } from "nestor/components/nestor/purchasing/suppliers/qualifications/AddEdit";
import { SupplierQualificationListCard } from "nestor/components/nestor/purchasing/suppliers/qualifications/List";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { SupplierQualificationDetails } from "./SupplierQualificationDetails";

export const SupplierQualifications: React.FunctionComponent<{
  supplier: NestorSupplier;
}> = (props) => {
  const route = useRouteMatch<{}>();
  // useMenuElement("Details", route.url + "/list");
  //useMenuElement("Edit information", route.url + "/edit");
  //useMenuElement("Documents", route.url + "/documents");
  //const qualifications = useMenuElement("Qualifications", route.url + "/qualifications");
  //const conversations = useMenuElement("Conversation", route.url + "/conversation");

  // useMenuElement("List of qualifications", route.url + "/");

  return (
    <Switch>
      <Route path={`${route.url}/list`}>
        <SupplierQualificationListCard supplier={props.supplier}></SupplierQualificationListCard>
        <SupplierFeedbackListCard supplier={props.supplier} />
      </Route>

      <Route path={`${route.url}/create`} key="supplierdetails">
        <SupplierQualificationAddEditCard supplier={props.supplier} />
      </Route>

      <Route path={`${route.url}/:qualification`} key="supplierdetails">
        <SupplierQualificationDetails supplier={props.supplier} />
      </Route>

      <Route>
        <Redirect to={`${route.url}/list`} />
      </Route>
    </Switch>
  );
};
