import { OrderListCard, orders_draft, orders_open } from "nestor/components/nestor/purchasing/orders/List";
import { useMe } from "nestor/hooks/me";
import { NestorProject } from "nestor/types/projects/projects";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { OrderCreate } from "./OrderCreate";
import { OrderDetail } from "./OrderDetail";
import { OrdersList } from "./OrderList";

function computeProjectUrl(url: string, project: NestorProject) {
  return url + "/project/" + project.code;
}
/*
const OrdersListByProject = () => {
  const { params } = useRouteMatch<{ code: string }>();

  return (
    <div className="container-fluid p-3">
      <OrderListCard projectCode={params.code} />
    </div>
  );
};
*/
const OrderByProject = ({ project }: { project: NestorProject }) => {
  const { url } = useRouteMatch();
  useMenuElement(project.name, computeProjectUrl(url, project));

  return null;
};
/*
const OrdersByProjects = ({ projects }: { projects: NestorProject[] }) => {
  const { url } = useRouteMatch();

  return (
    <>
      {projects.map((p) => {
        return <OrderByProject key={p.code} project={p} />;
      })}
      <Switch>
        <Route path={url + "/project/:code"}>
          <OrdersListByProject></OrdersListByProject>
        </Route>
      </Switch>
    </>
  );
};
*/
export function OrdersMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List of all orders", url + "/list");
  const list_drafts = useMenuElement("List of draft orders", url + "/drafts");
  const list_open = useMenuElement("List of open orders", url + "/open");
  const create = useMenuElement("Create a new order", url + "/create");
  //const m_projects = useMenuElement("My projects", url + "/projects");

  const me = useMe();
  const projects = me.projects || [];
  return (
    <>
      <Switch>
        <Route path={`${url}/list`} key="list">
          <XemwayMenuProvider {...list}>
            <OrdersList></OrdersList>
          </XemwayMenuProvider>
        </Route>

        <Route path={`${url}/drafts`} key="draftorders">
          <XemwayMenuProvider {...list_drafts}>
            <OrdersList state={orders_draft}></OrdersList>
          </XemwayMenuProvider>
        </Route>

        <Route path={`${url}/open`} key="openorders">
          <XemwayMenuProvider {...list_open}>
            <OrdersList state={orders_open}></OrdersList>
          </XemwayMenuProvider>
        </Route>

        <Route path={`${url}/create`}>
          <OrderCreate />
        </Route>
        {/*
        <Route path={`${url}/projects`} key="projects">
          <XemwayMenuProvider {...m_projects}>
            <OrdersByProjects projects={projects} />
          </XemwayMenuProvider>
        </Route>
  */}
        <Route path={`${url}/:order`}>
          <OrderDetail />
        </Route>

        <Route>
          <Redirect to={`${url}/list`} />
        </Route>
      </Switch>
    </>
  );
}
