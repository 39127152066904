const lang = {

    sector: "Group",
    division: "BU",
    cost_center: "Cost center",
    cost_element: "Cost element",
    imputing: "Account"
}

export { lang }
