import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorPartCategory } from "nestor/types/stock/part_category";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";
type T = NestorPartCategory;

export const usePartCategorySelectField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorPartCategory, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: `stock/categories`,
    keyMap: (el: T) => el.id.toString(),
    valMap: (el: T) => el.description,
  });
};
