import { NestorAddressFragment } from "nestor/types/util/address_fragment";

export const AddressRenderer = (props: { address: NestorAddressFragment }) => {
  return (
    <div>
      <div className="text-muted">{props.address.address}</div>
      {props.address.address2 != null ? <div className="text-muted">{props.address.address2}</div> : null}
      {props.address.postal_code != null || props.address.city !== null ? (
        <div className="fw-bold">
          {props.address.postal_code || null} {props.address.city}
        </div>
      ) : null}

      <h6>{props.address.country}</h6>
    </div>
  );
};
