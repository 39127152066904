import { NestorPcbFunctionalTest } from "nestor/types/manufacturing/pcb";
import { useFormSelectObj } from "@csem/forms";

export const usePcbFunctionalTestField = function (
  originalValue: NestorPcbFunctionalTest | undefined,
  defaultValue: NestorPcbFunctionalTest | undefined
) {
  return useFormSelectObj({
    label: "Functional test",
    originalValue: originalValue,
    defaultValue: defaultValue,
    options: {
      not_tested: NestorPcbFunctionalTest.not_tested,
      functional: NestorPcbFunctionalTest.functional,
      non_functional: NestorPcbFunctionalTest.non_functional,
      partially_functional: NestorPcbFunctionalTest.partially_functional,
    },
  });
};
