import { PCBLayoutQuickAddEditModal } from "nestor/components/nestor/manufacturing/pcb/modals/AddEditPcbLayout";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { PCBLayoutNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { ProjectColumn } from "nestor/components/tables/columns/ProjectColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { usePcbLayoutList } from "nestor/hooks/api/usePcbLayoutList";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";

import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  HasSearch,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorPcbLayout;
type FWLst = {};
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;
type HOOK = {};
const GenericPCBLayoutTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      PCBLayoutNavigationColumn,
      NUIDColumn,

      FilterableColumn(
        SortableColumn(
          SimpleColumn("name", "Name", (e) => e.name),
          "name"
        ),
        TextFilterFactory({ label: "Name" })
      ),

      SimpleColumn("num_pcb", "# PCA", (e) => (e.pcbs_count !== undefined ? e.pcbs_count : "N/A")),

      ProjectColumn("project", "Project", "project_id", true, false, (e) => e.project || null),

      FilterableColumn(
        SortableColumn(
          SimpleColumn("old_ni", "Old Identificator", (e) => e.identificator),
          "identificator"
        ),
        TextFilterFactory({ label: "Old identificator" })
      ),

      SimpleColumn("old_proj", "Old Project name", (e) => e.projectName),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
      ButtonsColumn<T, FW, HOOK>("actions", "", [
        {
          title: "Revoke",
          theme: "danger",
          visibility: (e, fw) => {
            return e.pcbs_count === 0;
          },
          cb: (e, fw) => {
            fw.remove(e, fw);
          },
        },

        {
          title: "Edit",
          theme: "info",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.edit(e, fw);
          },
        },
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const GenericPcbLayoutListContainer = (function () {
  return HasSearch(
    HasDeletion(
      HasEdition(
        HasAddition(
          HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
          PCBLayoutQuickAddEditModal,
          "Add a new layout",
          "plus",
          "primary"
        ),
        PCBLayoutQuickAddEditModal
      ),
      "Confirm layout deletion",
      (v: T) => `Are you sure to revoke the PCB "${v.name}" ?`,
      (props) => {
        return useNestorAPI<undefined, NestorPcbLayout>(
          (el) => {
            assert(el !== undefined);
            return `manufacturing/pcblayouts/${el.id}`;
          },
          false,
          undefined,
          { method: "DELETE" },
          props.list.refresh
        );
      }
    ),
    {
      nuid: "contains",
      name: "contains",
      identificator: "eq",
      "project.name": "contains",
      "project.code": "eq",
    },
    "or",
    "",
    TableSearch
  );
})();

export const PCBLayoutListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  return (
    <div className="m-2">
      <CardComponent header="List of PCB layouts">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericPcbLayoutListContainer
            //cardHeader="Gateway list"
            list={usePcbLayoutList()}
            fwProps={{}}
            listTemplate={GenericPCBLayoutTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
