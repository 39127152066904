import { ConversationCard } from "nestor/components/nestor/util/Conversation";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
//import { NestorSupplierQualification } from "nestor/types/purchasing/supplier_qualification";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useSupplierQualificationSingle } from "nestor/hooks/api/useSupplierQualification";
import { NestorSupplierQualification } from "nestor/types/purchasing/supplier_qualification";
import { SupplierQualificationProjectCard } from "nestor/components/nestor/purchasing/suppliers/qualifications/Projects";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { SupplierQualificationAddEditCard } from "nestor/components/nestor/purchasing/suppliers/qualifications/AddEdit";
import { SupplierQualificationInfoLine } from "nestor/components/nestor/purchasing/suppliers/qualifications/InfoLine";

export const SupplierQualificationDetails: React.FunctionComponent<{ supplier: NestorSupplier }> = (props) => {
  //const route = useRouteMatch<{ supplier: string }>();

  const route = useRouteMatch<{ supplier: string; qualification: string }>();
  const qualificationId = parseInt(route.params.qualification);

  const apiQ = useSupplierQualificationSingle(qualificationId);

  //const menu = useMenuElement("Qualification details", route.url);

  if (!apiQ.element || !apiQ.state?.success) {
    return null;
  }

  return <SupplierQualificationDetailsInner supplier={props.supplier} qualification={apiQ.element} />;
  /*
      <XemwayMenuProvider {...menu}>
    </XemwayMenuProvider>
  );*/
};

const SupplierQualificationDetailsInner: React.FunctionComponent<{
  supplier: NestorSupplier;
  qualification: NestorSupplierQualification;
}> = (props) => {
  const route = useRouteMatch<{}>();
  useMenuElement("Edit", route.url + "/edit");
  useMenuElement("Documents", route.url + "/documents");
  useMenuElement("Projects", route.url + "/projects");
  const conv = useMenuElement("Conversation", route.url + "/conversation");

  return (
    <>
      <SupplierQualificationInfoLine qualification={props.qualification} supplier={props.supplier} />
      <Switch>
        <Route path={`${route.url}/edit`}>
          <SupplierQualificationAddEditCard supplier={props.supplier} qualification={props.qualification} />
        </Route>

        <Route path={`${route.url}/documents`} key="documents">
          <DocumentListCard
            type="qualification"
            id={props.qualification.id}
            readonly={props.qualification.deleted_at != null}
          ></DocumentListCard>
        </Route>

        <Route path={`${route.url}/conversation`} key="conversation">
          <XemwayMenuProvider {...conv}>
            <ConversationCard type="qualification" id={props.qualification.id}></ConversationCard>
          </XemwayMenuProvider>
        </Route>

        <Route path={`${route.url}/projects`} key="projects">
          <XemwayMenuProvider {...conv}>
            <SupplierQualificationProjectCard
              supplier={props.supplier}
              qualification={props.qualification}
            ></SupplierQualificationProjectCard>
          </XemwayMenuProvider>
        </Route>

        <Route>
          <Redirect to={`${route.url}/projects`}></Redirect>
        </Route>
      </Switch>
    </>
  );
};
