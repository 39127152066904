import { NestorEmployeeId } from "nestor/types/company/employee";
import { useFieldOptRequired } from "@csem/forms";
import { useEmployeeSearchField } from "../fields/EmployeeSearch";

export const useFieldSignees = (
  left: NestorEmployeeId | undefined,
  right: NestorEmployeeId | undefined,
  required: boolean
) => {
  const signee_left_id = useEmployeeSearchField({
    label: "Left signee",
    originalValue: left,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(required), // Required at secretary level
  });

  const signee_right_id = useEmployeeSearchField({
    label: "Right signee",
    originalValue: right,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(required), // Required at secretary level
  });

  return {
    signee_left_id,
    signee_right_id,
  };
};
