import { useDeviceList } from "nestor/hooks/api/useDeviceList";
import assert from "assert";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { Button, Modal } from "react-bootstrap";
import { GenericDeviceTable, ReadonlyDeviceListContainer } from "./List";

export type InspectDevices<T, FW> = { inspect_devices: (el: T, fw: FW) => void };
export const DeviceListModal = (field: string) => {
  function DeviceListModal<T extends { id: any }, FW, V>({
    show,
    onValidated,
    handleClose,
    el,
    fw,
  }: {
    show: boolean;
    onValidated: (out?: V) => void;
    handleClose: () => void;
    el?: T;
    fw: FW;
  }) {
    assert(el);
    const filter = cloneDeep(DefaultStandardTableFilter);
    filter.filter.filters[0].filters = [{ field: field, operator: "eq", value: el.id }];

    const Table = useMemo(() => {
      return GenericDeviceTable();
    }, []);

    return (
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Device list</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StandardTableFilterCtxProvider defaultValue={filter}>
            <ReadonlyDeviceListContainer
              //cardHeader="Gateway list"
              list={useDeviceList()}
              fwProps={{ mode: "standard", settings: {}, employee_id: undefined }}
              listTemplate={Table}
              additionalContent={[]}
              righthandContent={[]}
              lefthandContent={[]}
              bottomContent={[]}
            />
          </StandardTableFilterCtxProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={(e) => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return DeviceListModal;
};
