import { useMenuElement } from "@csem/shared-utils";
import { FeedbackCard } from "nestor/components/nestor/purchasing/orders/feedbacks/Details";
import { useFeedbackSingle } from "nestor/hooks/api/useFeedbackSingle.tsx";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { useRouteMatch } from "react-router-dom";

export const OrdersFeedbackDetails: React.FunctionComponent<{ order: NestorOrder }> = (props) => {
  const route = useRouteMatch<{ order: string; feedback: string }>();
  const menu = useMenuElement("Details", route.url);

  const feedback = useFeedbackSingle(route.params.feedback);

  return (
    <div className="container-fluid p-3">
      {feedback.element ? <FeedbackCard feedback={feedback.element} /> : feedback.loadingIO}
    </div>
  );
};
