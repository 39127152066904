import { useLocationFields } from "nestor/components/forms/fields/LocationField";
import { useDeviceModelList } from "nestor/hooks/api/useDeviceModelList";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceModelId } from "nestor/types/inventory/device_model";
import { NestorManufacturerId } from "nestor/types/purchasing/manufacturer";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useCallback, useState } from "react";
import { useDeviceModelSearchField } from "../../../forms/fields/DeviceModelSearch";
import { useEmployeeSearchField } from "../../../forms/fields/EmployeeSearch";
import { useManufacturerSearchField } from "../../../forms/fields/ManufacturerSearch";

export const useQuickEditFieldsDevice = (el?: NestorDevice) => {
  const [manufacturerId, setManufacturerId] = useState<NestorManufacturerId | undefined>(
    el?.model?.device_manufacturer_id
  );
  const [modelId, setModelId] = useState<NestorDeviceModelId | undefined>(el?.model?.id);

  const onManufacturerAdded = useCallback((result: any) => {
    setManufacturerId(result.response.id);
    setModelId(undefined);
  }, []);

  const onDeviceModelAdded = useCallback((result: any) => {
    setManufacturerId(result.values.device_manufacturer_id);
    setModelId(result.response.id);
  }, []);

  const manufacturer_id_f = useManufacturerSearchField({
    label: "Manufacturer",
    originalValue: manufacturerId,
    defaultValue: undefined,
    validationOnChange: {
      required: true,
    },
  });

  const model_id_f = useDeviceModelSearchField({
    label: "Model",
    defaultValue: undefined,
    originalValue: modelId,
    apiListFactory: function useApiList() {
      return useDeviceModelList(manufacturer_id_f.value);
    },

    validationOnChange: {
      required: true,
    },
  });

  const owner_id_f = useEmployeeSearchField({
    label: "Employee responsible",
    defaultValue: undefined,
    originalValue: el?.owner_id,
    validationOnChange: {
      required: true,
    },
  });

  const location_f = useLocationFields(el?.location, true);
  /*

    const room_id_f = useRoomSearchField({
        label: "Location",
        defaultValue: undefined,
        originalValue: el?.location?.room_id,
        validationOnChange: {
            required: true
        }
    });
*/
  const serialnumber_f = useFormTextField({
    label: "Serial number",
    defaultValue: "",
    originalValue: el?.serialnumber || "",
    type: FieldValueType.STRING,
  });

  return {
    manufacturerId,
    setManufacturerId,
    modelId,
    setModelId,
    manufacturer_id_f,
    model_id_f,
    owner_id_f,
    location_f,
    serialnumber_f,

    onManufacturerAdded,
    onDeviceModelAdded,
  };
};
