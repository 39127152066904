import {
  useBTEmployeeList,
  useEmployeeList,
  useMyDivisionEmployeeList,
  useSecretaryEmployeeList,
} from "nestor/hooks/api/useEmployeeList";
import { NestorEmployee } from "nestor/types/company/employee";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory, FormSearchMultHookFactory, SearchFactoryProps } from "@csem/lists";
import { EmployeeRendererList } from "../../renderers/EmployeeInfos";
import * as def from "@csem/forms";

const EmployeeSearchDefinition: SearchFactoryProps<NestorEmployee, NestorEmployee["id"]> = {
  apiListFactory: useEmployeeList,
  template: GenericListRenderer((e) => <EmployeeRendererList employee={e} />),
  traverser_display: (el: NestorEmployee) => {
    return el.fullname + " (" + el.initials + ")";
  },
  uniqueKeyField: "id",
  filterKeys: {
    nuid: "contains",

    initials: "contains",
    firstname: "contains",
    lastname: "contains",
    section_id: "eq",
  },
};

export const useEmployeeSearchField = FormSearchHookFactory<NestorEmployee, NestorEmployee["id"]>(
  EmployeeSearchDefinition
);

export const useMyDivisionEmployeeSearchField = FormSearchHookFactory<NestorEmployee, NestorEmployee["id"]>({
  ...EmployeeSearchDefinition,
  apiListFactory: useMyDivisionEmployeeList,
});

export const useSecretaryEmployeeSearchField = FormSearchHookFactory<NestorEmployee, NestorEmployee["id"]>({
  ...EmployeeSearchDefinition,
  apiListFactory: useEmployeeList,
  initialFilter: [
    {
      field: "role",
      operator: "eq",
      value: "secretary",
    },
  ],
});

export const useEmployeeSearchMultField = FormSearchMultHookFactory<NestorEmployee, NestorEmployee["id"]>(
  EmployeeSearchDefinition
);
/*
export const useEmployeeBTMult = FormSearchMultHookFactory<NestorEmployee, NestorEmployee["id"]>({
  ...EmployeeSearchDefinition,
  apiListFactory: useEmployeeList,
  initialFilter: {
    sort: [],
    take: 20,
    skip: 0,
    filter: {
      logic: "and",
      filters: [
        {
          field: "role",
          operator: "eq",
          value: "bt",
        },
      ],
    },
  },
});

*/

export const useEmployeeBTMult = FormSearchMultHookFactory<NestorEmployee, NestorEmployee["id"]>({
  ...EmployeeSearchDefinition,
  apiListFactory: useBTEmployeeList,
});
