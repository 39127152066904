import { EmployeeRenderer } from "nestor/components/renderers/EmployeeInfos";
import { LocationRenderer } from "nestor/components/renderers/LocationRenderer";
import { ProjectRenderer } from "nestor/components/renderers/ProjectRenderer";
import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { NestorSkuTransferContextProvider } from "nestor/contexts/NestorSkuTransferContext";
import { NestorContainer, NestorContainerId, NestorContainerType } from "nestor/types/stock/container";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import { CardComponent } from "@csem/shared-utils";
import { LabelPrinterButton } from "../../util/LabelPrinter";
import { SkuListCard } from "../skus/List";
import { WithdrawalForm } from "../skus/movements/WithdrawalForm";
import { useContainerSingle } from "nestor/hooks/api/useContainerList";

export const ContainerOneLineWithFetch: React.FunctionComponent<{ containerid: NestorContainerId }> = (props) => {
  const container = useContainerSingle(props.containerid);
  if (!container.element) {
    return null;
  }

  return <ContainerOneLine container={container.element} refresh={container.refresh} />;
};

const ContainerInformationCard = (props: { container: NestorContainer }) => {
  return (
    <CardComponent additionalClasses="h-100" header="Container information">
      {props.container.expired_components ? (
        <div className="alert alert-danger">Components placed into this container will be expired</div>
      ) : null}
      <div className="row align-items-middle mb-3">
        <div className="col-4"></div>
        <div className="col-8">
          <h4> {props.container.nuid}</h4>
        </div>
      </div>

      <div className="row align-items-middle mb-3">
        <div className="col-4">Name</div>
        <div className="col-8">
          <h5> {props.container.name}</h5>
        </div>
      </div>

      <div className="row align-items-middle mb-3">
        <div className="col-4">Nestor 1 ID</div>
        <div className="col-8">
          <h5> {props.container.barcode}</h5>
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Description</div>
        <div className="col-8">
          <div> {props.container.description}</div>
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Localisation</div>
        <div className="col-8">
          <LocationRenderer location={props.container.location} fallbackText="Unknown location" />
        </div>
      </div>

      <div className="row align-items-middle mb-3">
        <div className="col-4">Project</div>
        <div className="col-8">
          <ProjectRenderer project={props.container.project || null} fallbackText="No project information" />
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Person responsible</div>
        <div className="col-8">
          <EmployeeRenderer employee={props.container.employee} />
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Container type</div>
        <div className="col-8">
          {props.container.type === NestorContainerType.MEDICAL ? (
            <span className="badge  bg-danger">Medical</span>
          ) : null}

          {props.container.type === NestorContainerType.STANDARD ? (
            <span className="badge  bg-secondary">Standard</span>
          ) : null}
        </div>
      </div>

      {props.container.is_sink ? (
        <div className="row align-items-middle mb-3">
          <div className="col-4">Content quantity tracking</div>
          <div className="col-8">
            <span className="badge  bg-danger">No quantity tracking</span>
          </div>
        </div>
      ) : null}

      {!props.container.accept_withdrawals ? (
        <div className="row align-items-middle mb-3">
          <div className="col-4">Withdrawals from co-workers</div>
          <div className="col-8">
            <span className="badge  bg-danger">Locked</span>
          </div>
        </div>
      ) : null}

      <div className="row align-items-middle mb-2">
        <div className="col-4">Labels</div>
        <div className="col-8">
          <div className="vstack gap-2">
            <LabelPrinterButton
              text=""
              size={LabelPrinterSizes.SZ_36}
              url={`stock/containers/${props.container.id}/label/pdf/print`}
            />
          </div>
        </div>
      </div>
    </CardComponent>
  );
};

export const ContainerInfos = (props: { container: NestorContainer; refresh: Function }) => {
  return (
    <NestorSkuTransferContextProvider>
      <div className="row">
        <div className="col-md-4 mb-2">
          <ContainerInformationCard container={props.container} />
        </div>

        <div className="col-md-8 mb-2">
          {useHasPermission("can_withdraw") ? (
            <WithdrawalForm showWithdrawalContent={true} />
          ) : (
            <div className="alert alert-warning">You are not allowed to withdraw / move items from this container</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <SkuListCard header="SKUs in this container" container={props.container} />{" "}
        </div>
      </div>
    </NestorSkuTransferContextProvider>
  );
};

export const ContainerOneLine: React.FunctionComponent<{
  container: NestorContainer;
  refresh: Function;
}> = ({ container, refresh }) => {
  return (
    <CardComponent header="Container you're looking at" additionalClasses={"mb-2"}>
      <div className="row">
        <div className="col-xl-1 col-md-4 col-6 align-self-center  mb-2">
          <h4> {container.nuid}</h4>
        </div>
        <div className="col-xl-2 col-md-4 col-6 align-self-center  mb-2">
          <h4> {container.name}</h4>
          <div className="text-muted">{container.description}</div>
        </div>
        <div className="col-xl-2 col-md-4 col-6 align-self-center  mb-2">
          <LocationRenderer location={container.location} fallbackText="Unknown location" />
        </div>{" "}
        <div className="col-xl-2 col-md-4 col-6 align-self-center  mb-2">
          <ProjectRenderer project={container.project || null} fallbackText="No project information" />
        </div>{" "}
        <div className="col-xl-2 col-md-4 col-6 align-self-center  mb-2">
          <EmployeeRenderer employee={container.employee} />
        </div>
      </div>
    </CardComponent>
  );
};
