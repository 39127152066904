import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorBuilding } from "nestor/types/company/building";
import { useFormSelectRemoteField } from "@csem/forms"
import { CommonFieldUI } from "@csem/forms"

export const useBuildingField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorBuilding, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: NestorServerRoutes.building_list(),

    keyMap: (el: NestorBuilding) => el.id,
    valMap: (el: NestorBuilding) => el.name,

    getterFromKey: (el: string | number | undefined, list?: NestorBuilding[]) => {
      if (el === undefined || list === undefined) {
        return;
      }
      return list.find((e) => e.id === el);
    },
  });
};
