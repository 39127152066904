// Hooks
//# Tables
//## Nestor
import { SkuShortcutAddEditModal } from "nestor/components/modals/AddEditSkuShortcut";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { SkuShortcutNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { useSkuShortcutList } from "nestor/hooks/api/useSkuShortcutList";
import { NestorSkuShortcut } from "nestor/types/stock/shortcut";
import { CardComponent } from "@csem/shared-utils";
import { EditButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// Classes
// Components
// Contexts
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { EditMethod, GenericListContent, HasAddition, HasEdition, HasPagination, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorSkuShortcut;
type FWLst = {};
type FW = EditMethod<T, FWLst>;
type HOOK = {};
const GenericPCBMachineTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      SkuShortcutNavigationColumn,

      SimpleColumn("name", "Name", (e) => e.name),
      SimpleColumn("num_positions", "# Positions", (e) => e.positions?.length),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("actions", "", [EditButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const GenericListContainer = (function () {
  return HasAddition(
    HasEdition(HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())), SkuShortcutAddEditModal),
    SkuShortcutAddEditModal,
    "Add a new shortcut",
    "plus",
    "primary"
  );
})();

export const SkuShortcutListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);

  return (
    <div className="m-2">
      <CardComponent header="PCB Machine list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericListContainer
            //cardHeader="Gateway list"
            list={useSkuShortcutList()}
            fwProps={{}}
            listTemplate={GenericPCBMachineTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
