import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import DownloadColumn from "nestor/components/tables/columns/DownloadColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { ParcelNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { useStockEntryList } from "nestor/hooks/api/useStockEntryList";
import NestorServerRoutes from "nestor/server_routes";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorParcel } from "nestor/types/purchasing/parcel";
import { CardComponent } from "@csem/shared-utils";
import { TableFilterCtxProvider } from "@csem/lists";
import { SimpleColumn, TableRenderer } from "@csem/lists";
//import React from "react";
import { GenericListContent, HasRefresh } from "@csem/lists";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { ProposeSigneesModal } from "nestor/components/modals/ProposeSignees";
import { ButtonLink } from "nestor/components/nestor/util/ButtonLink";

type T = NestorParcel;
//type FWList = { order: NestorOrder };

const GenericStockEntry = (function () {
  return TableRenderer<T, {}, {}, {}, "id">({
    key: "id",
    columns: [
      ParcelNavigationColumn,

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      DateColumn("delivered_at", "Delivered at", "delivered_at", (e) => e.delivered_at),

      EmployeeColumn<T>("created_by", "Created by", "creator_id", (e) => e.creator),

      EmployeeColumn<T>("intended_recipient", "Intended recipient", "intended_recipient_id", (e) => e.creator),

      SimpleColumn("state", "State", (e) => e.state),

      SimpleColumn("comment", "Comment", (e) => e.comment),

      SimpleColumn("num_sku", "# Stock items", (e) => e.sku_count),

      SimpleColumn("num_devices", "# Devices", (e) => e.devices_count),

      DownloadColumn((el) => NestorServerRoutes.package_ef612(el.id), "EF612"),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        _nav: "0px",
        created_at: "120px",
        created_by: "120px",
        intended_recipient: "120px",
        state: "150px",

        num_sku: "120px",
        num_devices: "120px",
      };
    },
  });
})();

const GenericStockEntryListContainer = (function () {
  return HasRefresh(GenericListContent<{}, T>());
})();
/*
<a key="add" className="btn btn-success" href={}>
<em className="bi bi-plus"></em> New entry
</a>,
*/

//     <a ref={ref} {...props} onClick={handleClick}>💅 {props.children}</a>

export const StockEntryListCard = ({ order }: { order: NestorOrder }) => {
  return (
    <div className="mb-2">
      <CardComponent header="Stock and inventory entries">
        <TableFilterCtxProvider>
          <GenericStockEntryListContainer
            //cardHeader="Gateway list"
            list={useStockEntryList(order.id)}
            fwProps={{}}
            listTemplate={GenericStockEntry}
            additionalContent={[]}
            righthandContent={[
              <Link
                to={`/purchasing/orders/${order.id}/shipments/create`}
                component={ButtonLink}
                className="btn btn-success"
                key="add"
              >
                <em className="bi bi-plus"></em> New entry
              </Link>,
            ]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </TableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
