import assert from "assert";
import { useContext } from "react";
import { NestorUserContext } from "../contexts/NestorUserContext";
import { NestorSector } from "../types/company/sector";

enum NestorGrants {
  ADMIN = "admin",
  BT = "bt",
  ADMIN_BT = "bt-manager",
  SECRETARY = "secretary",
  ADMIN_INVENTORY = "admin-inventory",
  QC_MANAGER = "qc_manager"
}

export { NestorGrants };
/*
const has_any_role = (employee, roles) => {

    for (let role of employee.roles) {
        if (roles.indexOf(role.name) > -1) {
            return true;
        }
    }

    return false;
}

const is_section_head = (employee, section) => {
    return employee.id == section.head_id;
}

export { has_any_role, is_section_head };
*/

export const useHaveIGrantOneOf = (...args: string[]) => {
  const ctx = useContext(NestorUserContext);
  assert(ctx);
  for (let role of ctx.roles) {
    if (args.includes(role.name)) {
      return true;
    }
  }
  return false;
  //return _.intersection( ctx.roles.map( r => r.name ), args ).length > 0
};

export const useAmISectorHead = (sector: NestorSector) => {
  const ctx = useContext(NestorUserContext);
  assert(ctx);
  return sector.head_id === ctx.id;
};

export const useMyDivision = () => {
  const ctx = useContext(NestorUserContext);
  assert(ctx && ctx.section);
  return ctx.section.division_id;
};

export const useMyId = () => {
  const ctx = useContext(NestorUserContext);
  assert(ctx);
  return ctx.id;
};

export const useMe = () => {
  const ctx = useContext(NestorUserContext);
  assert(ctx);
  return ctx;
};

export const useAmISecretary = () => {
  return useHaveIGrantOneOf(NestorGrants.SECRETARY, NestorGrants.ADMIN);
};

/**
 * @returns A boolean indincating if you're a secretary or the admin
 */
export const useAmIElevated = () => {
  return useHaveIGrantOneOf(NestorGrants.SECRETARY, NestorGrants.ADMIN);
};

export const useAmIBT = () => {
  return useHaveIGrantOneOf(NestorGrants.BT, NestorGrants.ADMIN_BT, NestorGrants.ADMIN);
};

export const useAmIBTAdmin = () => {
  return useHaveIGrantOneOf(NestorGrants.ADMIN_BT, NestorGrants.ADMIN);
};

export const useAmIInventoryAdmin = () => {
  return useHaveIGrantOneOf(NestorGrants.ADMIN_INVENTORY, NestorGrants.ADMIN, NestorGrants.SECRETARY);
};


export const useAmIQCManager = () => {
  return useHaveIGrantOneOf(NestorGrants.QC_MANAGER, NestorGrants.ADMIN, NestorGrants.SECRETARY);
};
