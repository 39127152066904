import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useAllAccounts, useSupplierAccounts } from "nestor/hooks/api/useSupplierSingle";
import { useAmIElevated, useMyDivision } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorBackoffice } from "nestor/types/company/backoffice";
import { NestorDivision } from "nestor/types/company/division";
import { NestorSupplier, NestorSupplierId } from "nestor/types/purchasing/supplier";
import { NestorSupplierAccount } from "nestor/types/purchasing/supplier_account";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { useMemo } from "react";
import { SupplierAccountModal } from "./Modals/SupplierAccountModal";

type T = NestorSupplierAccount;

type FW = {
  bu: NestorDivision;
  isElevated: boolean;
  supplier_id?: NestorSupplierId;
};

type FWL = EditMethod<T, FW> & RemoveMethod<T, FW>;
const Table = (function () {
  return TableRenderer<T, FW, FWL, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("supplier", "Supplier", (e) => e.supplier?.name),
      SimpleColumn("backoffice", "Backoffice", (e) => e.backoffice?.name),

      SimpleColumn("account", "Account number", (e) => e.account_number),
      SimpleColumn("use_in_order", "Use in PO", (e) => (!!e.use_in_order ? "Yes" : "No")),

      SimpleColumn("username", "Username", (e) => e.username),
      SimpleColumn("password", "Password", (e) => e.password),
      EmployeeColumn("employee", "Person of contact", "employee_id", (e) => e.employee),
      ButtonsColumn("actions", "", [
        EditButton(undefined, (e, fw) => {
          return !!fw.supplier_id && !!e.permissions?.edit;
        }),
        RemoveButton(undefined, (e, fw) => {
          return !!fw.supplier_id && !!e.permissions?.edit;
        }),
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth() {
      return {
        _nav: "0px",
        nuid: "65px",
      };
    },

    useColumnVisibility(cols, fw) {
      const out: any = {};
      if (fw.supplier_id) {
        out.supplier = false;
      } else {
        out.supplier = true;
      }

      return out;
    },
  });
})();

const Ctn = (function () {
  return HasDeletion(
    HasEdition(
      HasAddition(
        HasRefresh(GenericListContent<FW, T>()),
        SupplierAccountModal,
        "Add another account",
        "plus",
        "primary"
      ),
      SupplierAccountModal
    ),

    "Confirm account deletion",
    (v: T) => `Are you sure to remove this account ?`,
    (props) => {
      return useNestorAPI<undefined, NestorSupplierAccount>(
        (e) => {
          assert(e !== undefined);
          return NestorServerRoutes.supplier_account_delete(props.fwProps.supplier_id!, e.id);
        },
        false,
        undefined,
        { method: "DELETE" },

        props.list.refresh
      );
    }
  );
})();

export const SupplierAccountsCard = (props: { supplier?: NestorSupplier }) => {
  const bu = useMyDivision();
  const secretary = useAmIElevated();
  const supplier_id = props.supplier?.id;

  let useHook;
  if (supplier_id) {
    useHook = useSupplierAccounts;
  } else {
    useHook = useAllAccounts;
  }
  return (
    <CardComponent header="Accounts">
      <StandardTableFilterCtxProvider>
        <Ctn
          // @ts-ignore
          list={useHook(props.supplier?.id)}
          fwProps={useMemo(() => {
            return {
              bu,
              isElevated: secretary,
              supplier_id: supplier_id,
            };
          }, [bu, secretary, supplier_id])}
          listTemplate={Table}
          additionalContent={[]}
          righthandContent={[]}
          lefthandContent={[]}
          bottomContent={[]}
        />
      </StandardTableFilterCtxProvider>
    </CardComponent>
  );
};
