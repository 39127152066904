import { NestorOrder } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import React, { Suspense, useCallback, useState } from "react";
import { Modal } from "react-bootstrap";

const XLSXImporter = React.lazy(() => import("./XLSXImporter"));

const XLSXImporterModal = ({
  show,
  handleClose,
  order,
  callback,
}: {
  show: boolean;
  handleClose: () => void;
  order: NestorOrder;
  callback: Function;
}) => {
  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Import stockable articles from a spreadsheet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Suspense fallback={<div className="alert alert-info">Loading XLSX importer...</div>}>
          <XLSXImporter callback={callback} order={order} />
        </Suspense>
      </Modal.Body>
    </Modal>
  );
};

export const OrderArticleAddFromXlsx = (props: { refresh: Function; order: NestorOrder }) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <CardComponent header="Import from a spreadsheet" additionalClasses="my-4">
      {show ? (
        <XLSXImporterModal callback={props.refresh} show={show} handleClose={handleClose} order={props.order} />
      ) : null}
      <button className="w-100 btn btn-primary" onClick={() => setShow(true)}>
        Import stock articles from a spreadsheet
      </button>
    </CardComponent>
  );
};
