import { useContactList } from "nestor/hooks/api/useContactList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorBuildingId } from "nestor/types/company/building";
import { NestorFloor } from "nestor/types/company/floor";
import { NestorContact } from "nestor/types/purchasing/contact";
import { NestorSupplierId } from "nestor/types/purchasing/supplier";
import { GenericListRenderer } from "@csem/forms";
import { useFormSelectRemoteField } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { CommonFieldUI } from "@csem/forms";
import { useMemo } from "react";
type T = NestorContact;

export const useSupplierContactField = FormSearchHookFactory<T, T["id"]>({
  template: GenericListRenderer((el) => {
    return <>{el.fullname}</>;
  }),
  filterKeys: {
    id: "eq",
    fullname: "contains",
    nuid: "contains",
  },
  traverser_display: (el) => el.fullname,
  uniqueKeyField: "id",
});
