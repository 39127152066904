import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceUnavailability, NestorDeviceUnavailabilityCause } from "nestor/types/inventory/unavailability";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormDateField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import moment from "moment";

export const UnavailabilityAddEditModal = StandardFormAddEditModalFactory<
  NestorDeviceUnavailability,
  { device: NestorDevice },
  any
>({
  title: (el, fw) =>
    el
      ? `Edit the unavailability for device ${fw?.device.nuid}`
      : `Add a new unavailability for device ${fw?.device.nuid}`,

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const cause = useFormSelectObj({
      label: "Cause",
      originalValue: el?.cause,
      options: {
        [NestorDeviceUnavailabilityCause.CALIBRATION]: "Calibration",
        [NestorDeviceUnavailabilityCause.DAMAGED]: "Damaged",
        [NestorDeviceUnavailabilityCause.SERVICE]: "Service",
        [NestorDeviceUnavailabilityCause.REPARATION]: "Repair",
        [NestorDeviceUnavailabilityCause.OTHER]: "Other",
      },
      defaultValue: undefined,
      validationOnChange: { required: true },
    });

    const started = useFormDateField({
      label: "Starting date",
      originalValue: el?.started,
      defaultValue: moment().format("YYYY-MM-DD"),
      validationOnChange: {
        required: true,
      },
    });

    const comment = useFormTextField({
      label: "Comment",
      originalValue: el?.comment,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const apiResult = useNestorAPI(
      !el
        ? `inventory/devices/${fw.device.id}/forms/unavailability/start/create`
        : `inventory/devices/${fw.device.id}/forms/unavailability/start/${el.id}/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        comment: comment.value,
        cause: cause.value,
        started: started.value,
      });
    };

    return {
      fields: {
        started,
        cause,
        comment,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
