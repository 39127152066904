import { SkuShortcutListCard } from "nestor/components/nestor/stock/sku_shortcuts/List";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { SKUShortcutDetails } from "./SKUShortDetails";

export function SKUShortcuts() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List of shortcuts", url + "/list");
  //const shortcuts = useMenuElement("SKU shortcuts", url + "/shortcuts");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...list}>
          <SkuShortcutListCard></SkuShortcutListCard>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/:shortcut`} key="details">
        <SKUShortcutDetails />
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
