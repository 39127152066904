import { LinkDeviceOrderModal } from "nestor/components/modals/LinkDeviceOrder";
import { DeviceTypeCategoryRenderer } from "nestor/components/renderers/DeviceType";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { DeviceModelColumn } from "nestor/components/tables/columns/DeviceModelColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { LocationColumn } from "nestor/components/tables/columns/LocationColumn";
import { DeviceNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useOrderDeviceList } from "nestor/hooks/api/useOrderSingle";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasDeletion, HasRefresh, RemoveMethod } from "@csem/lists";
import { DeviceOrderLinkColumns } from "../../util/DeviceOrderLinkColumns";

/**
 * Shows devices associated to the order
 */

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

type T = ArrayElement<NonNullable<ReturnType<typeof useOrderDeviceList>["list"]>>;
type FW = { order?: NestorOrder };
type FWLst = RemoveMethod<T, FW>;
type order = "orders";
export const DeviceTable = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      DeviceNavigationColumn,
      NUIDColumn,
      SimpleColumn("ni", "Old NI", (e) => e.NI),

      SimpleColumn("device", "Device type", (e, fw, hk) => <DeviceTypeCategoryRenderer type={e.model?.type} />),

      DeviceModelColumn(
        "manufacturer",
        "Manufacturer",
        "model.device_manufacturer_id",
        (e) => e.model?.manufacturer || undefined,
        (e) => e.model?.model || undefined
      ),

      EmployeeColumn<T>("owner", "Owner", "owner_id", (e) => e.owner),

      SimpleColumn("serial", "Serial number", (e, fw, hk) => e.serialnumber),

      LocationColumn("location", "Location", (el) => {
        return el.location;
      }),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ...DeviceOrderLinkColumns<T, order>("orders"),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

export const DeviceOrderListCard = (props: { order: NestorOrder }) => {
  return (
    <div className="my-2">
      <CardComponent header="Inventory list resulting from the purchased articles">
        <StandardTableFilterCtxProvider>
          <LinkableToOrderDeviceListContainer
            //cardHeader="Gateway list"
            list={useOrderDeviceList(props.order.id)}
            fwProps={{ order: props.order }}
            listTemplate={DeviceTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

const LinkableToOrderDeviceListContainer = (function () {
  return HasDeletion(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<{}, T>())),
      LinkDeviceOrderModal,
      "Link to an existing device",
      "link",
      "primary"
    ),
    "Confirm link revocation",
    (v: T) => `Are you sure to revoke this device - order link ?`,
    (props) => {
      return useNestorAPI<undefined, NestorDevice>(
        (el) => {
          assert(el !== undefined);
          return `inventory/devices/${el.id}/linkOrder/${props.fwProps.order!.id}`;
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
  //   return ReadonlyDeviceListContainer;
})();
