import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";

export const PCBLayoutRenderer = ({
  layout,
  fallbackText = "No layout information",
}: {
  layout?: NestorPcbLayout;
  fallbackText: string;
}) => {
  if (!layout) {
    return <div className="text-muted">{fallbackText}</div>;
  }
  return (
    <>
      <div className="fw-bold">
        {layout.nuid} {layout.name}
      </div>
      <div className="text-muted">{layout.project ? `${layout.project.code} - ${layout.project.name}` : null}</div>
    </>
  );
};
