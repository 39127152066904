import NestorFrontendRoutes from "nestor/frontend_routes";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { Link } from "react-router-dom";

export const PCBRenderer = ({
  pcb,
  withNav,
  withAttributes,
}: {
  pcb: NestorPcb | null;
  withNav?: boolean;
  withAttributes?: boolean;
}) => {
  if (pcb === null) {
    return null;
  }

  return (
    <>
      <div className="fw-bold">
        <em className="bi bi-motherboard"></em>{" "}
        {withNav ? <Link to={NestorFrontendRoutes.pcb_details(pcb.layout_id, pcb.id)}>{pcb.nuid}</Link> : pcb.nuid}
      </div>
      {pcb.layout?.name} - {pcb.layout?.project?.code}
      <br />
      {withAttributes ? (
        <div className="hstack gap-2">
          <div>Rev. {pcb.revision?.details?.value || "??"}</div>
          <div>Var. {pcb.variant?.details?.value || "??"}</div>
        </div>
      ) : null}
      <div className="d-flex  text-muted">
        {pcb.employee ? (
          <div>
            <em className="bi bi-person"></em> {pcb.employee.initials}
          </div>
        ) : null}
      </div>
    </>
  );
};
