import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import { useProjectSearchField } from "../../../../forms/fields/ProjectSearch";

export const PCBLayoutQuickAddEditModal = StandardFormAddEditModalFactory<NestorPcbLayout, {}, any>({
  title: (el, fw) => (el ? `Edit the PCB ${el.name} ` : `Add a new PCB`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const project = useProjectSearchField({
      label: "Project",
      originalValue: el?.project_id,
      defaultValue: undefined,
    });

    const description = useFormTextField({
      label: "Description",
      originalValue: el?.description,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });
    const path = useFormTextField({
      label: "Path",
      originalValue: el?.path,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const apiResult = useNestorAPI(
      !el ? `manufacturing/pcblayouts/forms/create` : `manufacturing/pcblayouts/${el.id}/forms/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        project_id: project.value,
        description: description.value,
        path: path.value,
      });
    };

    return {
      fields: {
        name,
        project,
        description,
        path,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
