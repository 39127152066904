import { LocationRenderer } from "nestor/components/renderers/LocationRenderer";
import { PCBLayoutRenderer } from "nestor/components/renderers/PCBLayoutRenderer";
import { NestorPcb, NestorPcbFunctionalTest } from "nestor/types/manufacturing/pcb";
import { CardComponent } from "@csem/shared-utils";

export const PCBInfoCard = ({ pcb }: { pcb: NestorPcb }) => {
  return (
    <CardComponent header="PCA information" additionalClasses="h-100">
      <div className="row align-items-center mb-3">
        <div className="col-4">NUID</div>
        <div className="col-8">
          <h4>{pcb.nuid}</h4>
        </div>
      </div>
      {/*
            <div className="row align-items-center mb-3">
                <div className="col-4">Index</div>
                <div className="col-8">
                    <h6>{pcb.number}</h6>
                </div>
            </div>
*/}
      <div className="row align-items-center mb-3">
        <div className="col-4">Layout</div>
        <div className="col-8">
          <PCBLayoutRenderer layout={pcb.layout} fallbackText="No layout information" />
        </div>
      </div>
      {/*
            <div className="row align-items-center mb-3">
                <div className="col-4">Created by</div>
                <div className="col-8">
                    {pcb.employee ? (
                        <EmployeeBadgeComplete employee={pcb.employee} />
                    ) : (
                        <div className="text-muted">No employee information</div>
                    )}
                </div>
            </div>
                    */}
      <div className="row align-items-center mb-3">
        <div className="col-4">Current location</div>
        <div className="col-8">
          <LocationRenderer location={pcb.location} />
        </div>
      </div>

      {pcb.current_attributes?.map((ca) => {
        let d = ca.details.value;
        if (ca.shorthand === "functional_test") {
          d = NestorPcbFunctionalTest[ca.details.value as keyof typeof NestorPcbFunctionalTest];
        }
        return (
          <div className="row align-items-center mb-3">
            <div className="col-4">{ca.name}</div>
            <div className="col-8">{d} </div>
          </div>
        );
      })}
    </CardComponent>
  );
};
