// Hooks

// Classes

// Components
//## Nestor
import { DeviceQuickAddEditModal } from "nestor/components/nestor/inventory/devices/modals/AddDevice";
import { DeviceParkColumn } from "nestor/components/tables/columns/DeviceParkColumn";
import { DeviceTypeColumn } from "nestor/components/tables/columns/DeviceTypeColumn";
import { DeviceNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { NestorSelectContext } from "nestor/contexts/NestorSelectContext";
import { useDeviceList } from "nestor/hooks/api/useDeviceList";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceModelId } from "nestor/types/inventory/device_model";
import { NestorLocationFilter } from "nestor/types/_internal/locationFilter";
import extendFilterByLocation from "nestor/util/ExtendFilterWithLocation";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { default as nestor_settings } from "settings";

import { SelectButton } from "@csem/lists";
// Contexts
import { TextFilterFactory } from "@csem/lists";
// ## Generic
import {
  ButtonsColumn,
  CheckboxFilterColumn,
  FilterableColumn,
  SimpleColumn,
  SortableColumn,
  TableRenderer,
} from "@csem/lists";
import {
  GenericListContent,
  HasAddition,
  HasModalMethod,
  HasRefresh,
  HasSearch,
  HasTableFilterClear,
} from "@csem/lists";
import { ToggleCheck } from "@csem/shared-utils";
import { usePersist } from "@csem/shared-utils";
import { cloneDeep } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useRouteMatch } from "react-router";
import { DateColumn } from "../../../tables/columns/DateColumn";
import { EmployeeColumn } from "../../../tables/columns/EmployeeColumn";
import { LocationColumn } from "../../../tables/columns/LocationColumn";
import { ManufacturerColumn } from "../../../tables/columns/ManufacturerColumn";
import { DeviceImage } from "./Info";
import { DefaultStandardTableFilter } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { TableSearch } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { AddEditLoanModalFromDeviceList } from "./loans/modals/AddEditLoan";
import { NestorParcelId } from "nestor/types/purchasing/parcel";
import { useRefreshCall, useTriggerRefresh } from "nestor/contexts/NestorRefreshContext";
import { NestorDivision } from "nestor/types/company/division";
import DownloadButton from "../../util/DownloadButton";
import { DeviceModelColumn } from "nestor/components/tables/columns/DeviceModelColumn";
import { LoanButton } from "nestor/components/tables/components/LoanButton";
import { useMe } from "nestor/hooks/me";
import { nestorApiCall, NestorPOST } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";

type T = NestorDevice;

const DeviceStatusTagRenderer = (e: NestorDevice) => {
  const [draft, unavailable, obsolete, loaned] = [!!e.to_update, !!e.is_unavailable, !!e.is_obsolete, !!e.active_loan];

  if (draft) {
    return <div className="d-block badge bg-danger">draft</div>;
  }
  if (unavailable) {
    return <div className="d-block badge bg-danger">not available</div>;
  }
  if (obsolete) {
    return <div className="d-block badge bg-danger">obsolete</div>;
  }
  if (loaned) {
    return <div className="d-block badge bg-warning">loaned</div>;
  }
  return <></>;
};

export const ReferenceLabel = <div className="d-block badge bg-warning">reference</div>;

const TagRenderer = (e: NestorDevice) => {
  return (
    <>
      {e.is_reference ? ReferenceLabel : null}
      {e.is_obsolete ? <div className="d-block badge bg-dark">obsolete</div> : null}
      {e.is_calibrated && !e.is_reference ? <div className="d-block badge bg-info">calibration</div> : null}
    </>
  );
};

type settings_t = {
  photos?: boolean;
  loaned_by?: boolean;
  device_park?: boolean;
  next_calibration?: boolean;
};

type FW = {
  employee_id: number | undefined;
  actionable?: boolean;
  onSelect?: (el: NestorDevice) => void;
  selectContent?: Array<NestorDevice>;
  mode: string;
  settings: settings_t;
  refresh?: (...args: any) => any;
};

type FWTable = {
  onLoan?: (el: T, fw: FW) => void;
};

export const GenericDeviceTable = function <_FW extends FW>() {
  return TableRenderer<T, _FW, FWTable, {}, "id">({
    key: "id",
    columns: [
      DeviceNavigationColumn,

      NUIDColumn,

      FilterableColumn(
        SortableColumn(
          SimpleColumn("ni", "Old NI", (e) => e.NI),
          "NI"
        ),
        TextFilterFactory({ label: "Nestor 1 NI", operator: "eq" }),
        "NI"
      ),

      CheckboxFilterColumn<NestorDevice>(
        "name",
        "Tags",
        {
          reference: "is_reference",
          obsolete: "is_obsolete",
          calibrated: "is_calibrated",
        },
        TagRenderer,
        [
          {
            on: 1,
            off: undefined,
            filterKey: "reference",
            label: "Reference only",
          },

          {
            on: 1,
            off: 0,
            filterKey: "obsolete",
            label: "Obsolete",
          },

          {
            on: 1,
            off: undefined,
            filterKey: "calibrated",
            label: "Calibrated only",
          },
        ]
      ),

      SimpleColumn("photo", "Photo", (e) => {
        return <DeviceImage device={e} style={{ maxHeight: "100px" }} />;
      }),

      DeviceTypeColumn(
        "type",
        "Device type",
        "model.device_type_id",
        "model.type.device_category_id",
        (e) => e.model?.type
      ),

      DeviceModelColumn(
        "manufacturer",
        "Device model",
        "model.device_manufacturer_id",
        (e) => e.model?.manufacturer || undefined,
        (e) => e.model?.model || undefined
      ),

      EmployeeColumn<T>("owner", "Owner", "owner_id", (e) => e.owner),

      FilterableColumn(
        SimpleColumn("serial", "Serial number", (e, fw, hk) => e.serialnumber),
        TextFilterFactory({ label: "Search by serial number" }),
        "serial_number"
      ),

      DeviceParkColumn("device_park", "Device park", "device_park_id", (e) => e.device_park),

      LocationColumn("location", "Location", (el) => {
        return el.location;
      }),

      CheckboxFilterColumn<NestorDevice>(
        "status",
        "Status",
        {
          draft: "to_update",
          loaned: "is_loaned",
          unavailable: "is_unavailable",
        },
        DeviceStatusTagRenderer,
        [
          {
            on: 1,
            off: undefined,
            filterKey: "loaned",
            label: "Active loan",
          },

          {
            on: 1,
            off: undefined,
            filterKey: "draft",
            label: "Drafts",
          },

          {
            on: 1,
            off: undefined,
            filterKey: "unavailable",
            label: "Unavailable",
          },
        ]
      ),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("actions", "", [SelectButton()]),
      //@ts-ignore
      EmployeeColumn<T>("loaned_by", "Borrowed by", "active_loan.employee_id", (e) => e.active_loan?.employee),
      ButtonsColumn("loan", "", [
        LoanButton(),
        {
          title: "Return",
          theme: "secondary",
          visibility: (e: T, fw: FW) => {
            return e.active_loan?.employee_id == fw.employee_id;
          },

          cb: async (e: T, fw: FW) => {
            await nestorApiCall(NestorServerRoutes.inventory.devices.loans.forms.return(e.id, e.active_loan!.id), {
              body: JSON.stringify({ comment: "" }),
              ...NestorPOST,
            });

            if (fw.refresh) {
              await fw.refresh();
            }
          },
        },
      ]),

      DateColumn(
        "loaned_expected_return",
        "Expected return",
        undefined,
        (e) => e.active_loan?.expected_return_at || null
      ),

      DateColumn("next_cal", "Next calibration", "next_calibration", (e) => e.next_calibration),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnVisibility: (columns, fw) => {
      const vis: any = {};

      if (!fw.actionable) {
        vis.actions = false;
      }

      if (fw.mode !== "loan") {
        vis.loaned_by = false;
        vis.loan = false;
        vis.loaned_expected_return = false;
      } else {
        vis.actions = false;
        vis.created_at = false;
      }

      if (fw.settings.photos !== true) {
        vis.photo = false;
      }

      if (fw.settings.device_park !== true) {
        vis.device_park = false;
      }

      if (fw.settings.loaned_by === true) {
        vis.loaned_by = true;
        vis.loan = true;
      }

      if (fw.settings.next_calibration === true) {
        vis.next_cal = true;
      } else {
        vis.next_cal = false;
      }

      if (fw.settings) return vis;
    },

    useColumnWidth: () => {
      return {
        _nav: "0px",
        nuid: "65px",
        ni: "110px",
      };
    },
  });
};

export const ReadonlyDeviceListContainer = (function () {
  return HasSearch(
    HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())),
    {
      "owner.initials": "eq",
      "owner.fullname": "contains",
      serialnumber: "contains",
      hostname: "contains",
      NI: "eq",
      nuid: "contains",
      "model.model": "contains",
      "model.characteristic": "ft",
      "model.description": "ft",
      "model.manufacturer.name": "contains",
      "model.type.name": "contains",
      "#model.type.category.name": "contains",
      "#model.characteristic": "contains",
      "#location.building": "contains",
      "#location.room_name": "contains",
      "#location.room_disp": "contains",
      "#location.room_alt": "contains",
      "#location.information": "contains",
    },
    undefined,
    undefined,
    TableSearch
  );
})();

const GenericDeviceListContainer = (function () {
  return HasTableFilterClear(
    HasModalMethod(
      HasAddition(ReadonlyDeviceListContainer, DeviceQuickAddEditModal, "Add a new device", "plus", "primary"),
      AddEditLoanModalFromDeviceList,
      "onLoan"
    )
  );
})();

export const DeviceListCard = ({
  usePersistance = true,
  model_id,
  parcel_id,
  my_loans,
  division,
  ...props
}: {
  usePersistance?: boolean;
  model_id?: NestorDeviceModelId;
  parcel_id?: NestorParcelId; // Listing all devices received in the parcel
  me?: boolean;
  my_loans?: boolean;
  division?: NestorDivision;
  header?: string;
} & NestorLocationFilter) => {
  const me = useMe();

  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    defaultFilter.filter.logic = "and";

    if (props.me) {
      defaultFilter.filter.filters[0].filters.push({
        field: "owner.id",
        operator: "eq",
        value: me.id,
      });
    }

    if (my_loans) {
      defaultFilter.filter.filters[0].filters.push({
        field: "active_loan.employee_id",
        operator: "eq",
        value: me.id,
      });
    }

    if (model_id !== undefined) {
      defaultFilter.filter.filters[0].filters.push({
        field: "model_id",
        operator: "eq",
        value: model_id,
      });
    }

    if (parcel_id !== undefined) {
      defaultFilter.filter.filters[0].filters.push({
        field: "package_id",
        operator: "eq",
        value: parcel_id,
      });
    }

    if (division !== undefined) {
      defaultFilter.filter.filters[0].filters.push({
        field: "owner.section.division_id",
        operator: "eq",
        value: division,
      });
    }

    defaultFilter.filter.filters[1].filters[1].filters.push({
      field: "is_obsolete",
      operator: "eq",
      value: 0,
    });

    extendFilterByLocation(defaultFilter, {
      container_id: props.container_id,
      room_id: props.room_id,
      laboratory_id: props.laboratory_id,
      location_information: props.location_information,
    });

    return defaultFilter;
  }, [
    model_id,
    division,
    props.container_id,
    props.room_id,
    props.laboratory_id,
    props.location_information,
    parcel_id,
    me,
    my_loans,
  ]);

  const ctx = useContext(NestorSelectContext);

  const onSelect = useCallback(
    (device: NestorDevice) => {
      if (ctx?.toggle) ctx!.toggle(device, "equipment");
    },
    [ctx?.toggle]
  );

  const selectContent = useMemo(() => {
    return ctx?.store
      .map((s) => (s.type === "equipment" ? (s.el as NestorDevice) : undefined))
      .filter((e) => e !== undefined) as Array<NestorDevice>;
  }, [ctx?.store]);

  const { url } = useRouteMatch();

  const [settings, setSettings] = useState<settings_t>({ photos: false, loaned_by: false, device_park: false });
  const persist = usePersist<settings_t>(settings, "settings", url, "devices");
  useEffect(() => {
    if (persist) {
      setSettings(persist);
    }
  }, [persist]);

  const deviceApiCall = useDeviceList({ division });
  const deviceApiCall2 = useDeviceList({ division });

  const refresh = deviceApiCall.refresh;
  useRefreshCall(refresh);

  const refresher = useTriggerRefresh();
  const fw = useMemo(() => {
    return {
      actionable: ctx !== undefined,
      onSelect,
      selectContent,
      mode: my_loans ? "loan" : "std",
      settings,
      employee_id: me.id,
      refresh: refresher,
    };
  }, [onSelect, selectContent, my_loans, settings, refresher]);

  const Table = useMemo(() => {
    return GenericDeviceTable<FW>();
  }, []);

  return (
    <div className="mb-2">
      <CardComponent header={props.header || "Device list"}>
        <div className="row my-2">
          <div className="col-3">
            <ToggleCheck
              text="Show pictures"
              checked={!!settings.photos}
              onChange={(state) =>
                setSettings((s) => {
                  return { ...s, photos: state };
                })
              }
            />
          </div>
          <div className="col-3">
            <ToggleCheck
              text="Borrow / Loan mode"
              checked={!!settings.loaned_by}
              onChange={(state) =>
                setSettings((s) => {
                  return { ...s, loaned_by: state };
                })
              }
            />
          </div>
          <div className="col-3">
            <ToggleCheck
              text="Device park"
              checked={!!settings.device_park}
              onChange={(state) =>
                setSettings((s) => {
                  return { ...s, device_park: state };
                })
              }
            />
          </div>

          <div className="col-3">
            <ToggleCheck
              text="Next calibrations"
              checked={!!settings.next_calibration}
              onChange={(state) =>
                setSettings((s) => {
                  return { ...s, next_calibration: state };
                })
              }
            />
          </div>
        </div>
        <StandardTableFilterCtxProvider
          defaultValue={defaultFilter}
          persistKey={"devices"}
          enablePersistance={usePersistance}
          persistWithURL={true}
        >
          <GenericDeviceListContainer
            //cardHeader="Gateway list"
            list={deviceApiCall}
            fwProps={fw}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[
              <DownloadButton
                key="_dl"
                columns={nestor_settings.download_xlsx_columns.inventory}
                api={deviceApiCall2}
              />,
            ]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

export const DeviceListModalByMaintenance = <T extends { id: any }, _FW, V>({
  show,
  onValidated,
  handleClose,
  el,
  fw,
}: {
  show: boolean;
  onValidated: (out?: V) => void;
  handleClose: () => void;
  el?: T;
  fw: _FW;
}) => {
  assert(el);

  const me = useMe();
  const filter = cloneDeep(DefaultStandardTableFilter);
  filter.filter.filters[0].filters = [{ field: "device_maintenances.maintenance_id", operator: "eq", value: el.id }];

  const Table = useMemo(() => {
    return GenericDeviceTable<FW>();
  }, []);

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Device list</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StandardTableFilterCtxProvider defaultValue={filter}>
          <ReadonlyDeviceListContainer
            //cardHeader="Gateway list"
            list={useDeviceList()}
            fwProps={{ mode: "standard", settings: {}, employee_id: me.id }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="secondary" onClick={(e) => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
