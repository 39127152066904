import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { DeviceTypesListCard } from "nestor/components/nestor/inventory/device_types/List";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

/**
 * Device Models Router and Menu handler
 * @returns
 */
export function DeviceTypesMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List of device types", url + "/list");
  // const create = useMenuElement("Create a new device", url + "/create");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...list}>
          <DeviceTypesListCard />;
        </XemwayMenuProvider>
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
