import "./index.css";
import App from "./nestor/pages/Main";
import reportWebVitals from "./reportWebVitals";
import settings from "settings";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap";
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
Sentry.init({
  dsn: `https://${settings.sentry.public}@${settings.sentry.url}/${settings.sentry.project}`,
  integrations: [], //[new Integrations.BrowserTracing()],
  release: `xemway@${process.env.REACT_APP_NPM_VERSION}`,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
