import { useMaintenanceSearch } from "nestor/components/forms/fields/MaintenanceSearch";
import { useMaintenanceListWithDevice } from "nestor/hooks/api/useMaintenanceList";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceMaintenanceAction } from "nestor/types/inventory/device_maintenance_actions";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import NestorServerRoutes from "nestor/server_routes";

export const DeviceMaintenanceActionAddEditModal = StandardFormAddEditModalFactory<
  NestorDeviceMaintenanceAction,
  { device: NestorDevice },
  any
>({
  title: (el, fw) => (el ? `Edit the maintenance ${el.maintenance?.name}` : `Add a new maintenance`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const comment = useFormTextField({
      label: "Comment",
      originalValue: el?.comment,
      defaultValue: "",
      type: FieldValueType.STRING,
    });

    const maintenance_id = useMaintenanceSearch({
      label: "Maintenance",
      apiListFactory: function useApi() {
        return useMaintenanceListWithDevice(fw.device.id);
      },
      originalValue: el?.maintenance_id,
      defaultValue: undefined,
      validationOnChange: { required: true },
    });

    const apiResult = useNestorAPI(
      !el
        ? NestorServerRoutes.inventory.devices.maintenances.actions.forms.create(fw.device.id)
        : NestorServerRoutes.inventory.devices.maintenances.actions.forms.update(fw.device.id, el.id),

      false,
      undefined,
      NestorPOST
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        comment: comment.value,
        maintenance_id: maintenance_id.value,
      });
    };

    return {
      fields: { maintenance_id, comment },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
