import { NestorDivision } from "nestor/types/company/division";
import { NestorOrderTransferingType } from "nestor/types/purchasing/orders";

const basePath = "/";

type DivisionSettings = {
  goods_received_responsability: NestorOrderTransferingType.HANDLER | NestorOrderTransferingType.SECRETARY;
};

type Settings = {
  BACKEND_URL: string;
  NESTOR_URL: string;
  NESTOR_CACHE_URL: string;
  AVATAR_URL: string;

  navigation: {
    base: string;
    purchasing: (path: string) => string;
    inventory: (path: string) => string;
    stock: (path: string) => string;
    company: (path: string) => string;
    manufacturing: (path: string) => string;
    administration: (path: string) => string;
  };
  division_capabilities: {
    [K in NestorDivision]: Array<"purchasing" | "stock" | "inventory" | "manufacturing">;
  };

  division_settings: {
    [K in NestorDivision]: Partial<DivisionSettings>;
  };
  download_xlsx_columns: any;
} & { [x: string]: any }; // Generalize the type

const settings: Settings = {
  BACKEND_URL: "https://api.xemway.ch",
  NESTOR_URL: process.env.REACT_APP_NESTOR_BACKEND_URL as string,
  NESTOR_CACHE_URL: process.env.REACT_APP_NESTOR_CACHE_URL as string,
  AVATAR_URL: "https://avatar.bue.csem.local/",
  sentry: {
    public: "dca5c670f38e46649621bb6da2551409",
    url: "o1066188.ingest.sentry.io",
    project: "6058748",
  },
  navigation: {
    base: basePath,
    purchasing: (v) => basePath + "purchasing/" + v,
    inventory: (v) => basePath + "inventory/" + v,
    stock: (v) => basePath + "stock/" + v,
    company: (v) => basePath + "company/" + v,
    manufacturing: (v) => basePath + "manufacturing/" + v,
    administration: (v) => basePath + "administration/" + v,
  },

  division_capabilities: {
    I: ["purchasing", "stock", "inventory", "manufacturing"],
    D: ["purchasing", "stock", "inventory", "manufacturing"],
    E: ["purchasing", "stock", "inventory", "manufacturing"],
    M: ["inventory"],
    R: ["purchasing", "inventory"],
    W: ["purchasing", "inventory"],
    T: ["purchasing", "stock", "inventory"],
    V: ["purchasing", "stock", "inventory"],
    S: ["purchasing"],
  },

  division_settings: {
    I: {},
    D: {},
    E: {},
    R: {},
    M: {},
    W: {},
    V: {
      goods_received_responsability: NestorOrderTransferingType.SECRETARY,
    },
    T: {
      goods_received_responsability: NestorOrderTransferingType.SECRETARY,
    },
    S: {}
  },

  download_xlsx_columns: {
    orders: [
      "nuid",
      "sap_id",
      "supplier.name",
      "project.code",
      "project.name",
      "employee.fullname",
      "num_articles",
      "total",
      "currency.name",
    ],

    inventory: [
      "nuid",
      "NI",
      "division_id",
      "model.model",
      "model.manufacturer.name",
      "model.description",
      "model.type.category.name",
      "model.type.name",
      "serialnumber",
      "owner.initials",
      "mac_address",
      "hostname",
    ],
  },
};

export default settings;
