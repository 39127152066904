//import { Auth } from "@csem/shared-utils";
import { autoTrigger, t_endPoint, _useRemoteCall } from "@csem/api";
import { useEffect, useMemo, useState } from "react";
import settings from "settings";
import { Auth } from "@csem/shared-utils";

export const postJSON = { method: "POST", headers: { "Content-Type": "application/json" } };

export function useNestorRequest<T, U = undefined>(
  endpoint: t_endPoint<U>,
  autoTrigger?: autoTrigger,
  searchParams?: URLSearchParams,
  fetchOptions?: RequestInit,
  callback?: (p: T) => Promise<any> | void,
  prefix: string = "",
  cache?: boolean
) {
  const tk = Auth.getAuthToken();

  let _fetchOptions = useMemo(() => {
    let o = { ...fetchOptions };
    if (!o.headers) {
      o.headers = {};
    }
    if (tk.length > 0) {
      //@ts-ignore
      o.headers["X-Authorization"] = `Bearer ${tk}`;
    }
    return o;
  }, [fetchOptions /*, tk*/]);



  return _useRemoteCall(
    cache ? settings.NESTOR_CACHE_URL + prefix : settings.NESTOR_URL + prefix,
    endpoint,
    autoTrigger,
    searchParams,
    _fetchOptions,
    callback
  );
}
export const nestorApiCall = async (url: URL | RequestInfo, init?: RequestInit) => {

  init = init || {};
  init.headers = init.headers || {};
  const tk = Auth.getAuthToken();
  // @ts-ignore
  init.headers["X-Authorization"] = `Bearer ${tk}`;

  return fetch(settings.NESTOR_URL + "/api" + url, init);
}

export function useNestorAPI<T, U = undefined>(
  endpoint: t_endPoint<U>,
  autoTrigger?: autoTrigger,
  searchParams?: URLSearchParams,
  fetchOptions?: RequestInit,
  callback?: (p: T) => Promise<any> | void,
  cache?: boolean
) {
  return useNestorRequest<T, U>(endpoint, autoTrigger, searchParams, fetchOptions, callback, "/api", cache);
}

export function useNestorAPIForms<T, U = undefined>(
  endpoint: t_endPoint<U>,
  autoTrigger?: autoTrigger,
  searchParams?: URLSearchParams,
  fetchOptions?: RequestInit,
  callback?: (p: T) => Promise<any> | void
) {
  const api = useNestorAPI(endpoint, autoTrigger, searchParams, fetchOptions, callback);
  type error_t = { [x: string]: Array<string> };

  // TODO: We can discuss of the relevance to use state management and therby trigger a re-render when the errors have changed
  // or simply process the errors in-line...
  let [error, setError] = useState<error_t>();
  useEffect(() => {
    if (api.error) {
      const error = api.error.errors as error_t;

      for (let f in error) {
        for (let u in error[f]) {
          if (error[f][u] === "validation.unique") {
            error[f][u] = "Server error. Value already exists in the database";
          }
          if (error[f][u] === "validation.max.string") {
            error[f][u] = "Text exceeds maximum length";
          }

          if (error[f][u] === "validation.email") {
            error[f][u] = "Server error. Email format is erroneous";
          }

          if (error[f][u] === "validation.url") {
            error[f][u] = "Server error. URL format is erroneous (use http://)";
          }

          if (error[f][u] === "validation.filled") {
            error[f][u] = "Server error. Field must be filled";
          }
        }
      }

      setError(error);
    }
  }, [api.error]);

  return { ...api, error };
}

export const NestorPOST = Object.freeze({
  method: "POST",
  headers: { "Content-Type": "application/json" },
});
