import { LaboratoryAddEditModal } from "nestor/components/nestor/company/laboratories/modals/AddEditLaboratory";
import { LaboratoryRevokeModal } from "nestor/components/nestor/company/laboratories/modals/RevokeLaboratory";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { RoomColumn } from "nestor/components/tables/columns/RoomColumn";
import { useLaboratoryList } from "nestor/hooks/api/useLaboratoryList";
import { useAmIElevated } from "nestor/hooks/me";
import { NestorLaboratory } from "nestor/types/company/laboratory";
import { CardComponent } from "@csem/shared-utils";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasEdition,
  HasModalMethod,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";

type T = NestorLaboratory;
type FW = { isElevated: boolean; history: ReturnType<typeof useHistory> };
type FWLst = EditMethod<T, FW> & Partial<RemoveMethod<T, FW>>;

const TagRenderer = ({ laboratory }: { laboratory: NestorLaboratory }) => {
  return !laboratory.revoked_at ? (
    <div className="d-block badge bg-success">active</div>
  ) : (
    <div className="d-block badge bg-danger">revoked</div>
  );
};

const Table = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      /*      NavigationColumn("_nav", "", el => {
                return `/nestor/purchasing/orders/${el.id}`;
            }),
*/
      SimpleColumn("status", "Status", (e) => <TagRenderer laboratory={e} />),
      SimpleColumn("division", "Division", (e) => e.division_id),

      FilterableColumn(
        SimpleColumn("name", "Name", (e) => e.name),
        TextFilterFactory({ label: "Name" }),
        "name"
      ),
      EmployeeColumn("manager_1", "Manager", "manager1_id", (e) => e.manager1),
      EmployeeColumn("manager_2", "Suppleant Manager", "manager1_id", (e) => e.manager2),
      EmployeeColumn("metrology_manager", "Metrology manager", "metrology_manager_id", (e) => e.metrology_manager),
      RoomColumn("room", "Room", (e) => e.room),

      ButtonsColumn("actions", "", [
        EditButton("Edit", (e, fw) => fw.isElevated),
        RemoveButton("Revoke", (e, fw) => fw.isElevated && e.revoked_at == null),
        {
          title: "Audit",
          theme: "secondary",
          visibility: undefined,
          cb: (e: T, fw: FW & EditMethod<T, FW>) => {
            fw.history.push("/audit/laboratory/" + e.id, "");
          },
        },
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        manager_1: "120px",
        manager_2: "120px",
        metrology_manager: "120px",
      };
    },

    useColumnVisibility: (cols, fw) => {
      if (fw.isElevated) {
        return { actions: true };
      } else {
        return { actions: false };
      }
    },
  });
})();

const Container = (function () {
  return HasModalMethod(
    HasAddition(
      HasEdition(HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())), LaboratoryAddEditModal),
      LaboratoryAddEditModal,
      "Add a new laboratory",
      "plus",
      "primary"
    ),
    LaboratoryRevokeModal,
    "remove"
  );
})();

export const LaboratoryListCard = () => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "asc",
        field: "name",
      },
    ];

    return defaultFilter;
  }, []);

  const isElevated = useAmIElevated();
  const history = useHistory();
  return (
    <div className="m-2">
      <CardComponent header="Laboratories">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useLaboratoryList()}
            fwProps={{ isElevated, history }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
