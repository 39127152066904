import { NestorDevice } from "nestor/types/inventory/device";

export const InventoryNumberSpan = ({ device, text }: { device: NestorDevice; text: string }) => {
  return text !== null ? (
    <span
      className="p-2"
      style={{
        backgroundColor:
          "#" +
          (device?.is_reference
            ? device.model?.type?.category?.color_reference || "e0e0e0"
            : device.model?.type?.category?.color_non_reference || "e0e0e0"),
      }}
    >
      {text}
    </span>
  ) : null;
};
