import NestorServerRoutes from "nestor/server_routes";
import { NestorSkuShortcut, NestorSkuShortcutId, NestorSkuShortcutPosition } from "nestor/types/stock/shortcut";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorSkuShortcut;
export const useSkuShortcutList = (): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.sku_shortcut_list(), "shortcut");
};

export const useSkuShortcutSingle = (elId: NestorSkuShortcutId): NestorApiElement<NestorSkuShortcut, undefined> => {
  const apiCall = useNestorAPI<NestorSkuShortcut>(NestorServerRoutes.sku_shortcut_single(elId), true);
  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading sku shortcut..." source={apiCall.state} success={null} />,
  };
};

type U = NestorSkuShortcutPosition;
export const useSkuShortcutPositionList = (elId: NestorSkuShortcutId): apiList<U> => {
  let url = NestorServerRoutes.sku_shortcut_position_list(elId);
  return useNestorListApiCall<U>(url, "positions");
};
