import { StringIterator } from "lodash";
import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import { NestorDevice } from "../inventory/device";
import { NestorSku, NestorSkuId } from "../stock/sku";
import { NestorLocation } from "../system/location";
import { NestorDateTimeString } from "../util/dates";
import { NUID } from "../util/nuid";
import { NestorPcbActionPcb } from "./pcb_action";
import { NestorPcbAttribute, NestorPcbAttributeId } from "./pcb_attribute";
import { NestorPcbLayout, NestorPcbLayoutId } from "./pcb_layout";

export type NestorPcbId = number;

export enum NestorPcbFunctionalTest {
  not_tested = "Not tested",
  functional = "Functional",
  non_functional = "Non-Functional",
  partially_functional = "Partially functional",
}

export enum NestorPcbInspectionTest {
  done = "Done",
  not_done = "Not done",
}

export type NestorPcbAttributeValue = {
  id: number;
  pcb_id: NestorPcbId;
  pcb?: NestorPcb;
  attribute_id: NestorPcbAttributeId;
  attribute?: NestorPcbAttribute;

  value: string;
  revoked: NestorDateTimeString | null;
  revoked_employee_id: NestorEmployeeId;
  revoked_employee?: NestorEmployee;

  employee_id: NestorEmployeeId;
  employee?: NestorEmployee;

  updated_at: NestorDateTimeString;
  created_at: NestorDateTimeString;

  variant?: NestorPcbAttributeValue,
  revision?: NestorPcbAttributeValue
};

export type NestorPcb = {
  id: NestorPcbId;

  nuid: NUID;

  layout_id: NestorPcbLayoutId;
  layout?: NestorPcbLayout;
  sku_id: NestorSkuId;

  number: number;
  employee_id: NestorEmployeeId;
  employee?: NestorEmployee;

  created_at: NestorDateTimeString;
  updated_at: NestorDateTimeString;

  current_attributes?: Array<NestorPcbAttribute & { details: NestorPcbAttributeValue }>;
  actions?: Array<NestorPcbActionPcb>;

  location?: NestorLocation;
  sku?: NestorSku;

  revision?: NestorPcbAttribute & { details: NestorPcbAttributeValue };
  variant?: NestorPcbAttribute & { details: NestorPcbAttributeValue };

  device?: NestorDevice[];

  permissions?: any;
};
