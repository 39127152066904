import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorProjectWorkpackage } from "nestor/types/projects/workpackage";
import { CommonFieldUI } from "@csem/forms";
import { useFormSelectRemoteField } from "@csem/forms";

export const useWorkpackageField = (
  props: CommonFieldUI<string | number | undefined> & {
    projectId: number | string | undefined;
  }
) => {
  return useFormSelectRemoteField<NestorProjectWorkpackage, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: props.projectId ? `company/projects/${props.projectId}/wp` : undefined,
    keyMap: (el: NestorProjectWorkpackage) => el.id,
    valMap: (el: NestorProjectWorkpackage) => el.workpackage + " " + el.name,
  });
};
