import { NestorSelectContextProvider } from "nestor/contexts/NestorSelectContext";
import { NestorContainer } from "nestor/types/stock/container";
import { DeviceListCard } from "../../inventory/devices/List";
import { PCBListCard } from "../../manufacturing/pcb/layouts/PCBList";
import { TransferCard } from "../../util/TransferCard";
import { ContainerListCard } from "./List";

export const ContainerContent = ({ container }: { container: NestorContainer }) => {
  return (
    <NestorSelectContextProvider>
      <TransferCard />
      <DeviceListCard container_id={container.id} />
      <PCBListCard container_id={container.id} />
      <ContainerListCard container_id={container.id} />
    </NestorSelectContextProvider>
  );
};
