import { TaskReportMain } from "nestor/components/nestor/manufacturing/tasks/Report";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { TaskCreate } from "./TaskCreate";
import { TaskDetails } from "./TaskDetails";
import { TaskList } from "./TaskList";

export function TasksMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List all tasks", url + "/list");
  const create = useMenuElement("Create a new task", url + "/create");
  useMenuElement("Report", url + "/report");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...list}>
          <TaskList></TaskList>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/create`} key="new">
        <XemwayMenuProvider {...create}>
          <TaskCreate></TaskCreate>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/report`}>
        {" "}
        <div className="p-2">
          <TaskReportMain></TaskReportMain>{" "}
        </div>
      </Route>

      <Route path={`${url}/:task`}>
        <TaskDetails />
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
