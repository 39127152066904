import { useNestorConversation } from "nestor/hooks/api/useConversation";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useMe } from "nestor/hooks/me";
import { NestorConversation } from "nestor/types/util/conversation";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { XemwayMenuContext } from "@csem/shared-utils";
import { IOState } from "@csem/api";
import { useOnChange } from "@csem/shared-utils";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { EmployeeAvatar } from "../company/employees/EmployeeBadge";

const NestorConversationItem: React.FunctionComponent<{
  item: NestorConversation;
}> = ({ item }) => {
  // const ctx = useContext(LoggedInUserContext);
  const me = useMe();

  assert(me && item.employee, "Employee should exist");

  const isMe = item.employee?.initials === me.initials;
  //  style={{ "--bs-bg-opacity": 0.5 } as React.CSSProperties}
  return (
    <div className={`d-flex ${isMe ? "flex-row-reverse" : ""}`}>
      <div className={`d-flex flex-column  m-3 ${isMe ? "align-items-start" : "align-items-end"}`}>
        <EmployeeAvatar employee={item.employee} />
        {item.employee.fullname}
      </div>
      <div
        className={`flex-grow-1 border border-${
          isMe ? "info" : "secondary"
        } border-1 bg-opacity-10 p-3 pt-2  rounded-3 mb-3 bg-${isMe ? "info" : "secondary"}`}
      >
        <div className="" style={{ whiteSpace: "pre-wrap" }}>
          <p className="text-muted">
            Written by {item.employee.fullname} on the {moment(item.created_at).format("YYYY-MM-DD [at] HH:mm")}
          </p>
          {item.content}
        </div>
      </div>
    </div>
  );
};

export const ConversationCard = ({ type, id }: { type: string; id: string | number }) => {
  const list = useNestorConversation(type, id);
  //const [newComment, updateNewComment] = useState<string>("");
  const newCommentRef = useRef<string>("");
  const apiCall = useNestorAPI(`conversations/${type}/${id}`, false, undefined, NestorPOST);
  const { doQuery } = apiCall;
  const { refresh, list: conversations } = list;
  const post = useCallback(() => {
    doQuery(undefined, { content: newCommentRef.current }).then(() => refresh());
  }, [newCommentRef, doQuery, refresh]);

  const ctx = useContext(XemwayMenuContext);
  const setMenu = ctx?.setMenu;

  useOnChange(
    () => {
      if (setMenu) {
        setMenu({
          hasNumber: conversations.length,
        });
      }
    },
    [conversations],
    [setMenu]
  );
  return (
    <div className="m-2">
      <CardComponent header="Conversation">
        {list.loadingIO}
        {conversations?.map((v) => (
          <NestorConversationItem item={v} />
        ))}

        <div className={`row flex-row-reverse`}>
          {/*<EmployeeAvatar employee={item.employee} />*/}
          <div className={`border border-success border-1 bg-opacity-10 p-3 rounded rounded-3 bg-info`}>
            <IOState source={apiCall.state} success={null} />
            <div className="form-floating mb-2">
              <textarea
                className="form-control"
                style={{ width: "100%", height: "150px" }}
                onChange={(e) => {
                  newCommentRef.current = e.target.value || "";
                }}
              ></textarea>
              <label>Comment</label>
            </div>
            <button type="button" onClick={post} className="btn btn-success">
              Post
            </button>
          </div>
        </div>
      </CardComponent>
    </div>
  );
};
