import { RoomListCard } from "nestor/components/nestor/company/rooms/List";

export function RoomsMain() {
  //    const { url } = useRouteMatch();

  //  const list = useMenuElement("List of laboratories", url + "/list");
  // const create = useMenuElement("Create a new device", url + "/create");
  //const ctx = useContext(XemwayMenuContext);

  return <RoomListCard></RoomListCard>;
}
