import { NestorBackoffice } from "nestor/types/company/backoffice";
import { FilterProps } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { useOnChange } from "@csem/shared-utils";
import _ from "lodash";
import { useMemo } from "react";
import { useBackofficeField } from "../../forms/fields/BackofficeList";

const empty: Array<NestorBackoffice> = [];

export const BackofficeColumn = <T,>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T) => NestorBackoffice[] | undefined
) => {
  const SearchField: React.FunctionComponent<FilterProps<number[]>> = function ({ onChange, originalValue }) {
    const oV = useMemo(() => {
      if (originalValue) {
        const _oV = Object.values(originalValue)[0];
        if (typeof _oV == "string") {
          return [parseInt(_oV)];
        } else {
          if (typeof _oV == "number") {
            return [_oV];
          } else if (Array.isArray(_oV)) {
            return _oV;
          }
        }
      }

      return [];
    }, [originalValue]);

    const backoffice = useBackofficeField({
      label: "Backoffice",
      originalValue: oV,
      defaultValue: empty,
    });

    const { value } = backoffice;

    useOnChange(
      () => {
        if (value === undefined) {
          return;
        }
        const vId = value.map((e) => e.id);

        if (vId.length !== oV.length || _.intersection(vId, oV).length !== vId.length) {
          if (value.length === 0) {
            onChange(undefined, "in");
          } else {
            onChange(vId, "in");
          }
        }
      },
      [value],
      [onChange, oV]
    );

    return <div className="hstack">{backoffice.Content}</div>;
  };

  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      const b = renderer(el);
      if (b === undefined) {
        return undefined;
      }
      return (
        <div className="vstack">
          {b.map((b) => (
            <div className={`badge bg-${b.theme}`}>{b.name}</div>
          ))}
        </div>
      );
    }),
    SearchField,
    field
  );
};
