import { DeviceRenderer, useDeviceSearchField } from "nestor/components/forms/fields/DeviceSearch";
import { usePcbSearchField } from "nestor/components/forms/fields/PcbSearch";
import { useSkuSearchField } from "nestor/components/forms/fields/SkuSearch";
import { PCBRenderer } from "nestor/components/renderers/PCBRenderer";
import { SkuRenderer } from "nestor/components/renderers/SkuRenderer";
import { postJSON, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import settings from "settings";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceAssociation } from "nestor/types/inventory/device_association";
import {
  NestorDeviceModelParameter,
  NestorDeviceModelParameterType,
} from "nestor/types/inventory/device_model_parameter";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorSku } from "nestor/types/stock/sku";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useFormCheckboxField } from "@csem/forms";
import { useFormDateField } from "@csem/forms";
import { FieldValueType } from "@csem/forms";
import { useFormTextField } from "@csem/forms";
import _ from "lodash";
import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import NestorFrontendRoutes from "nestor/frontend_routes";
import { useQuickEditFieldsDevice } from "./AddEditQuick";
import { useForm } from "@csem/forms";
import { FormTemplateDefault } from "@csem/forms";
import { useDeviceWrite } from "nestor/hooks/api/useDeviceWrite";

const AssociationEdit = ({
  done,
  device,
  association,
  parameter,
}: {
  done: Function;
  device: NestorDevice;
  association: NestorDeviceAssociation | undefined;
  parameter: NestorDeviceModelParameter;
}) => {
  assert(parameter);
  const apiAssociation = useNestorAPI(
    association
      ? NestorServerRoutes.device_association_update(device.id, association.id)
      : NestorServerRoutes.device_association_create(device.id),
    false,
    undefined,
    postJSON
  );

  if (apiAssociation.state.success) {
    done();
  }

  const pcb = usePcbSearchField({
    label: parameter.name,
    defaultValue: undefined,
    originalValue:
      association?.associated_type === NestorDeviceModelParameterType.PCB ? association.associated_id : undefined,
  });

  const sku = useSkuSearchField({
    label: parameter.name,
    defaultValue: undefined,
    originalValue:
      association?.associated_type === NestorDeviceModelParameterType.SKU ? association.associated_id : undefined,
  });

  const device_f = useDeviceSearchField({
    label: parameter.name,
    defaultValue: undefined,
    originalValue:
      association?.associated_type === NestorDeviceModelParameterType.INVENTORY ? association.associated_id : undefined,
  });

  const text = useFormTextField({
    label: parameter.name,
    type: FieldValueType.STRING,
    defaultValue: "",
    originalValue:
      association?.associated_type === NestorDeviceModelParameterType.STRING ? association.comment : undefined,
  });

  const checkbox = useFormCheckboxField({
    label: parameter.name,
    defaultValue: false,
    originalValue: !!association?.comment,
  });

  const date = useFormDateField({
    label: parameter.name,
    defaultValue: "",
    originalValue: association?.associated_type === null ? association.comment : undefined,
  });

  return (
    <div className="row">
      {apiAssociation.state.error ? (
        <div className="col-12">
          <div className="text text-danger">
            Could not save this component. Perhaps the compenent you're trying to associate is already associated to
            another system ?
          </div>
        </div>
      ) : null}
      <div className="col">
        {parameter.type === NestorDeviceModelParameterType.CHECKBOX
          ? checkbox.Content
          : parameter.type === NestorDeviceModelParameterType.DATE
          ? date.Content
          : parameter.type === NestorDeviceModelParameterType.STRING
          ? text.Content
          : parameter.type === NestorDeviceModelParameterType.PCB
          ? pcb.Content
          : parameter.type === NestorDeviceModelParameterType.SKU
          ? sku.Content
          : parameter.type === NestorDeviceModelParameterType.INVENTORY
          ? device_f.Content
          : null}
      </div>
      <div className="col-auto  mb-2">
        {apiAssociation.state.loading ? (
          <div className="text-primary h-100 d-flex align-items-center">
            <em className="bi bi-hourglass-split"></em>
          </div>
        ) : (
          <>
            <button
              className="btn btn-primary h-100"
              onClick={() => {
                let comment: string | undefined = "";
                switch (parameter.type) {
                  case NestorDeviceModelParameterType.CHECKBOX:
                    comment = checkbox.value ? "1" : "0";
                    break;
                  case NestorDeviceModelParameterType.DATE:
                    comment = date.value;
                    break;
                  case NestorDeviceModelParameterType.STRING:
                    comment = text.value;
                }
                apiAssociation.doQuery(undefined, {
                  comment,
                  associated_type: [
                    NestorDeviceModelParameterType.PCB,
                    NestorDeviceModelParameterType.SKU,
                    NestorDeviceModelParameterType.INVENTORY,
                  ].includes(parameter.type)
                    ? parameter.type
                    : null,
                  device_model_parameter_id: parameter.id,
                  pcb_id: pcb.value,
                  sku_id: sku.value,
                  device_id: device_f.value,
                });
              }}
            >
              <em className="bi bi-check"></em>
            </button>

            <button
              className="btn btn-danger h-100"
              onClick={() => {
                done();
              }}
            >
              <em className="bi bi-x"></em>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
const AssociationContent = ({
  device,
  association,
  parameter,
  refresh,
}: {
  refresh: Function;
  device: NestorDevice;
  parameter: NestorDeviceModelParameter;
  association?: NestorDeviceAssociation;
}) => {
  const [edition, setEdition] = useState<boolean>(false);
  const done = useCallback(() => {
    setEdition(false);
    refresh();
  }, [refresh]);

  let content: JSX.Element = <></>;

  if (edition) {
    content = <AssociationEdit done={done} device={device} parameter={parameter} association={association} />;
  } else {
    if (association) {
      switch (association.associated_type) {
        case NestorDeviceModelParameterType.PCB:
          if (parameter.type === association.associated_type && association.associated) {
            content = <PCBRenderer withNav={true} withAttributes={true} pcb={association.associated as NestorPcb} />;
          }
          break;

        case NestorDeviceModelParameterType.INVENTORY:
          if (parameter.type === association.associated_type && association.associated) {
            content = <DeviceRenderer withNav={true} device={association.associated as NestorDevice} />;
          }
          break;

        case NestorDeviceModelParameterType.SKU:
          if (parameter.type === association.associated_type && association.associated) {
            content = <SkuRenderer withNav={true} sku={association.associated as NestorSku} />;
          }
          break;

        default:
          content = <>{association.comment}</>;
          break;
      }
    }
  }

  return (
    <div className="row h-100 align-items-center">
      <div className="col">{content}</div>
      <div className="col-auto mb-2">
        <button className="btn btn-outline-primary h-100" onClick={() => setEdition(true)}>
          <em className="bi bi-pen"></em>
        </button>
      </div>
    </div>
  );
};

const DeviceSystemComponentsCard = ({ device, refresh }: { device: NestorDevice; refresh: Function }) => {
  const { serialnumber_f } = useQuickEditFieldsDevice(device);
  const apiResult = useDeviceWrite();

  const form = useForm({
    fields: {
      serialnumber_f,
    },
    onSubmit: () => {
      const fd = new FormData();
      fd.set("serialnumber", serialnumber_f.value);
      apiResult.doQuery(device, fd).then(() => {
        refresh();
      });
    },
    fw: {},
    Template: FormTemplateDefault,
  });

  const keyedAssocations = Object.fromEntries(
    (function* () {
      if (device.associations) {
        for (let association of device.associations) {
          yield [association.device_model_parameter_id, association];
        }
      }
    })()
  );

  if (!device.model) {
    return <div className="alert alert-danger">Model not fetched.</div>;
  }

  const _groupedParameters = _.groupBy(device.model.parameters, "group");

  return (
    <CardComponent header="Associated system items">
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-primary"
          onClick={() => {
            window.open(settings.NESTOR_URL + NestorServerRoutes.system_sheet_download(device.id), "_blank");
          }}
        >
          Download summary sheet
        </button>
        <NavLink to={NestorFrontendRoutes.devicemodel_systems(device.model_id)}>View all systems of same model</NavLink>
      </div>

      <div className="row justify-content-end">
        <div className="col-4 d-flex justify-content-end">
          <div>{form.Content}</div>

          <div className="mb-2">
            {apiResult.state.loading ? (
              <button className="btn btn-secondary h-100 ">
                <em className="bi bi-hourglass"></em>
              </button>
            ) : apiResult.state.error ? (
              <button className="btn btn-danger h-100 ">
                <em className="bi bi-x"></em>
              </button>
            ) : (
              <button
                className="btn btn-primary h-100 "
                onClick={() => {
                  form.submit();
                }}
              >
                <em className="bi bi-save"></em>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="vstack gap-3">
        <table className="table">
          {Object.entries(_groupedParameters).map(([k, parameters]) => {
            parameters.sort((pA, pB) => {
              return pA.priority - pB.priority;
            });
            return (
              <>
                <thead>
                  <th colSpan={2} className="">
                    <h5 className="pb-0 ps-0 pt-3">{k}</h5>
                  </th>
                </thead>
                <tbody>
                  {parameters?.map((parameter) => {
                    return (
                      <tr>
                        <th className="text-end align-middle bg-secondary bg-opacity-10">{parameter.name}</th>
                        <td>
                          <AssociationContent
                            refresh={refresh}
                            device={device}
                            parameter={parameter}
                            association={keyedAssocations[parameter.id]}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            );
          })}
        </table>
      </div>
    </CardComponent>
  );
};

export default DeviceSystemComponentsCard;
