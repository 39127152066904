import { PCBLayoutCard } from "nestor/components/nestor/manufacturing/pcb/layouts/Info";
import { PCBListCard } from "nestor/components/nestor/manufacturing/pcb/layouts/PCBList";
import { ConversationCard } from "nestor/components/nestor/util/Conversation";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { NestorSelectContextProvider } from "nestor/contexts/NestorSelectContext";
import { usePcbLayoutSingle } from "nestor/hooks/api/usePcbLayoutSingle";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { PCBDetail } from "./PCB";

const PCBLayoutDetailInner = (props: { layout: NestorPcbLayout; refresh: Function }) => {
  const { url } = useRouteMatch<{ layout: string }>();

  // useMenuElement("Details", route.url + "/details");
  useMenuElement("List of PCBs", url + "/pcbs");
  useMenuElement("Documents", url + "/documents");
  const conversations = useMenuElement("Conversation", url + "/conversation");

  return (
    <Switch>
      <Route path={`${url}/pcbs`}>
        <NestorSelectContextProvider>
          <div className="vstack gap-2">
            <PCBLayoutCard layout={props.layout} />
            <PCBListCard layout={props.layout} />
          </div>
        </NestorSelectContextProvider>
      </Route>
      <Route path={`${url}/documents`}>
        <DocumentListCard type="pcb_layout" id={props.layout.id}></DocumentListCard>
      </Route>{" "}
      <Route path={`${url}/conversation`}>
        <XemwayMenuProvider {...conversations}>
          <ConversationCard type="pcb_layout" id={props.layout.id}></ConversationCard>
        </XemwayMenuProvider>
      </Route>{" "}
      <Route path={`${url}/pcb/:pcb`}>
        <PCBDetail></PCBDetail>
      </Route>
      <Route>
        <Redirect to={`${url}/pcbs`}></Redirect>
      </Route>
    </Switch>
  );
};

export const PCBLayoutDetail: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ layout: string }>();
  const layout = usePcbLayoutSingle(route.params.layout);
  const menu = useMenuElement(layout.element ? `PCB Layout: ${layout.element?.nuid}` : "Loading...", route.url);

  return (
    <XemwayMenuProvider {...menu}>
      {layout.loadingIO}
      <div className="container-fluid p-3">
        {layout.element ? <PCBLayoutDetailInner layout={layout.element} refresh={layout.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
