import NestorServerRoutes from "nestor/server_routes";
import { NestorProject, NestorProjectId } from "nestor/types/projects/projects";
import { NestorProjectSupplier } from "nestor/types/projects/project_suppliers";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorProject;
export const useProjectList = (): apiList<T> => {
  return useNestorListApiCall<T>("list/company/projects", "project");
};

export const useProjectSingle = (elId: T["id"]): NestorApiElement<T, undefined> => {
  const apiCall = useNestorAPI<T>(NestorServerRoutes.project_get(elId), true);
  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading project..." source={apiCall.state} success={null} />,
  };
};

export const useProjectCriticalSupplierList = (): apiList<NestorProjectSupplier> => {
  return useNestorListApiCall<NestorProjectSupplier>(
    NestorServerRoutes.suppliers.criticalities.project_list(),
    "project"
  );
};
