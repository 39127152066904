import { NestorAddressFragment } from "nestor/types/util/address_fragment";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

export const useAddressFields = (
  el: NestorAddressFragment | undefined,
  addressRequired: boolean,
  postal_code_required: boolean,
  city_required: boolean,
  country_required: boolean
) => {
  const address = useFormTextField({
    label: "Address",
    originalValue: el?.address,
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(addressRequired),
  });

  const address2 = useFormTextField({
    label: "Additional address",
    originalValue: el?.address2 || "",
    type: FieldValueType.STRING,
    defaultValue: "",
  });
  const address3 = useFormTextField({
    label: "Additional address (2)",
    originalValue: el?.address3 || "",
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const postal_code = useFormTextField({
    label: "Postal code",
    originalValue: el?.postal_code || "",
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(postal_code_required),
  });

  const city = useFormTextField({
    label: "City",
    originalValue: el?.city || "",
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(city_required),
  });

  const country = useFormTextField({
    label: "Country code (2 letters)",
    originalValue: el?.country || "",
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: { required: country_required, maxLength: 2 },
  });

  return {
    address,
    address2,
    address3,
    postal_code,
    city,
    country,
  };
};
