import { ManufacturerRenderer } from "nestor/components/renderers/Manufacturer";
import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useOrderArticleList } from "nestor/hooks/api/useOrderSingle";
import { lang } from "nestor/lang";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { NestorArticle, NestorOrderable } from "nestor/types/purchasing/article";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorService } from "nestor/types/purchasing/service";
import { NestorPart } from "nestor/types/stock/part";
import { CardComponent, useConfirmationModal } from "@csem/shared-utils";
import assert from "assert";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { EditMethod, GenericListContent, HasDeletion, HasEdition, RemoveMethod } from "@csem/lists";
import React, { useCallback, useEffect } from "react";
import { apiList } from "@csem/api";
import { MonetaryAmountRenderer } from "../../util/MonetaryAmountRenderer";
import { QtyReorder } from "./AddArticleFromOrder";
import { useFormAddEditArticle } from "./ArticlesEdit";

type T = NestorArticle;
type FWLst = {
  order: NestorOrder;
  select?: (el: T) => void;
} & {
  useColumnVisibility?: (c: Array<string>) => Record<string, boolean>;
};
type FW = RemoveMethod<T, FWLst> & EditMethod<T, FWLst>;
type HOOK = { total: number };

export const ArticleModal = StandardFormAddEditModalWithFormFactory<NestorArticle, { order: NestorOrder }, any>({
  title: (el) => `Edit the article`,
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    return useFormAddEditArticle(el, fw?.order);
  },
});
export const showArticleDescription = (e: NestorArticle) => {
  return e.description
    ? e.description
    : e.orderable_type === NestorOrderable.PART ||
      e.orderable_type === NestorOrderable.SERVICE ||
      e.orderable_type === NestorOrderable.DEVICE
    ? (e.orderable as NestorPart | NestorService | NestorDeviceModel | null)?.description
    : "";
};

const ArticleTable = (function () {
  return TableRenderer<T, FWLst, FW, HOOK, "id">({
    key: "id",
    columns: [
      SimpleColumn("ref", "Ref", (e) => e.reference),
      SimpleColumn("type", "", (e) => {
        if (e.orderable_type === NestorOrderable.PART) {
          return <div className="badge bg-dark">stock item</div>;
        } else if (e.orderable_type === NestorOrderable.DEVICE) {
          return <div className="badge bg-success">inventory item</div>;
        } else if (e.orderable_type === NestorOrderable.SERVICE) {
          return <div className="badge bg-info">service</div>;
        } else if (e.orderable_type === NestorOrderable.PCB_LAYOUT) {
          return <div className="badge bg-warning">PCB</div>;
        } else {
          return <div className="badge bg-info">other</div>;
        }

        return null;
      }),
      SimpleColumn("comment", "Comment", (e) => e.comment),
      SimpleColumn("description", "Description", (e) => showArticleDescription(e)),
      SimpleColumn("class", "Class", (e) => e.class),

      SimpleColumn("manufacturer", "Manufacturer", (e) => {
        if (!e.orderable) {
          return null;
        }
        if (e.orderable_type === NestorOrderable.PART) {
          let _e = e.orderable as NestorPart;
          return <ManufacturerRenderer manufacturer={_e.manufacturer} id={_e.manufacturer_identification} />;
        } else if (e.orderable_type === NestorOrderable.DEVICE) {
          let _e = e.orderable as NestorDeviceModel;
          return <ManufacturerRenderer manufacturer={_e.manufacturer} id={_e.model} />;
        }
      }),
      /* SimpleColumn("unit_price", "Unit price", (e, { order }) => {
                return MonetaryAmountRenderer(
                    e.price,
                    order.currency,
                    "No currency"
                );
            }),
            SimpleColumn("discount", "Discount", (e, { order }) => {
                let txt: Array<string> = [];
                if (e.discount_percentage) {
                    txt.push("-" + e.discount_percentage + "%");
                }

                if (e.discount > 0) {
                    txt.push(
                        MonetaryAmountRenderer(e.discount, order.currency, "??")
                    );
                }

                return txt.join(", ");
            }),*/
      SimpleColumn("net_price", "Price without taxes", (e, { order }, hk) => {
        if (e.administrative) return <div className="d-block badge bg-danger">administrative</div>;
        return MonetaryAmountRenderer(hk.total, order.currency, "No currency");
      }),

      SimpleColumn("qty_o", "Qty ordered", (e) => {
        if (e.orderable_type === NestorOrderable.PART) {
          const part = e.orderable as NestorPart | null;
          if (part?.dividable) {
            return (
              <span>
                {e.quantity_ordered}x {part.dividable_amount} {part.dividable_unit}
              </span>
            );
          }
          return <span>{e.quantity_ordered}</span>;
        }
        return <span>{e.quantity_ordered}</span>;
      }),

      SimpleColumn("imputing", lang.imputing, (e, fw) => {
        let f =
          e.project_id || e.section_id
            ? {
                project_id: e.project_id,
                project: e.project,
                workpackage: e.workpackage,
                section_id: e.section_id,
                cost_element_id: e.cost_element_id,
              }
            : {
                project_id: fw.order.project_id,
                section_id: fw.order.section_id,
                project: fw.order.project,
                workpackage: fw.order.workpackage,
                cost_element_id: fw.order.cost_element_id,
              };

        let c: JSX.Element;
        if (f.project_id) {
          c = (
            <>
              <span className="fw-bold">
                {f.project?.code}, WP &nbsp;
                {f.workpackage ? f.workpackage.workpackage : "Unknown"}
              </span>
              <br />
              <span className="text-muted">
                {f.cost_element_id || fw.order.cost_element_id || "No cost element defined"}
              </span>
            </>
          );
        } else if (f.section_id) {
          c = (
            <>
              <span className="fw-bold">
                {lang.sector} {f.section_id}
              </span>
              <br />
              <span className="text-muted">{f.cost_element_id}</span>
            </>
          );
        } else {
          // Imputing is missing
          return null;
        }

        if (!(e.project_id && !e.section_id)) {
          // Source of info is the order
          return <span className="text-muted">{c}</span>;
        } else {
          return c;
        }
      }),

      ButtonsColumn("actions", "", [
        {
          title: "Remove",
          theme: "danger",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.remove(e, fw);
          },
        },

        {
          title: "Edit",
          theme: "info",
          visibility: (e, fw) => {
            return true;
          },
          cb: (e, fw) => {
            fw.edit(e, fw);
          },
        },
      ]),

      SimpleColumn("select", "", (e, fw) => <QtyReorder el={e} fw={fw} />),
    ],

    useColumnWidth: () => {
      return { actions: "150px" };
    },

    useColumnVisibility: () => {
      const allowed = useHasPermission("can_add_article");
      let out: Record<string, boolean> = {};
      out.select = false;
      if (allowed) {
        out.actions = true;
      } else {
        out.actions = false;
      }
      return out;
    },

    useLineParamInjection: (el, forwarded) => {
      return {
        total:
          (el.price || 0) * (el.quantity_ordered || 0) * (1 - (el.discount_percentage || 0) / 100) - (el.discount || 0),
      };
    },
  });
})();

const ListContainer = HasDeletion(
  HasEdition(HasStdTablePagination(GenericListContent<FWLst, T>()), ArticleModal),
  "Confirm article deletion",
  (v: T) => `Are you sure to revoke this article ?`,
  (props) => {
    return useNestorAPI<undefined, NestorArticle>(
      (art) => {
        assert(art !== undefined);
        return `purchasing/article/${art.id}`;
      },
      false,
      undefined,
      { method: "DELETE" },
      props.list.refresh
    );
  }
);

export const OrderArticlesList: React.FunctionComponent<{
  order: NestorOrder;
  apiListArticles: apiList<NestorArticle>;
}> = (props) => {
  const allowed = useHasPermission("can_add_article");
  const apiWipeArticles = useNestorAPI(`purchasing/orders/${props.order.id}/articles/remove_all`, false, undefined);
  const { doQuery: apiWipeArticlesQuery } = apiWipeArticles;
  const refresh = props.apiListArticles.refresh;

  const wipeAllArticles = useConfirmationModal(
    "Delete all articles ?",
    () => "Are you sure that you want to delete all articles ?",
    useCallback(() => {
      apiWipeArticlesQuery().then(() => {
        refresh();
      });
    }, [apiWipeArticlesQuery, refresh]),
    "Could not remove the articles",
    apiWipeArticles
  );

  const subTotalApiCall = useNestorAPI<{ subtotal: number }>(NestorServerRoutes.order_subtotal(props.order.id), false);
  const { doQuery: refreshSubtotal } = subTotalApiCall;
  useEffect(() => {
    refreshSubtotal();
  }, [props.apiListArticles.list, refreshSubtotal]);

  const additionalCosts =
    (props.order.shipping_cost || 0) + (props.order.other_cost || 0) - (props.order.discount || 0);

  return (
    <div className="mb-3">
      <CardComponent header={"Articles for order " + props.order.nuid}>
        {wipeAllArticles.Modal}

        <StandardTableFilterCtxProvider>
          <ListContainer
            fwProps={{ order: props.order }}
            listTemplate={ArticleTable}
            list={props.apiListArticles}
            righthandContent={[
              allowed ? (
                <button className="btn btn-danger" onClick={() => wipeAllArticles.invoke(props.order)}>
                  Remove all articles
                </button>
              ) : (
                <></>
              ),
            ]}
          />

          <div className="row pe-3">
            <div className="col-md-2 offset-md-8 border-top text-end fw-bold">Subtotal :</div>
            <div className="col-md-2 border-top text-end">
              {MonetaryAmountRenderer(subTotalApiCall.result?.subtotal || 0, props.order.currency)}
            </div>

            <div className="col-md-2 offset-md-8 border-top text-end fw-bold">Additional costs :</div>
            <div className="col-md-2 border-top text-end">
              {MonetaryAmountRenderer(additionalCosts, props.order.currency)}
            </div>

            <div className="col-md-2 offset-md-8 border-top text-end fw-bold">Total :</div>
            <div className="col-md-2 border-top text-end">
              {MonetaryAmountRenderer(additionalCosts + (subTotalApiCall.result?.subtotal || 0), props.order.currency)}
            </div>
          </div>
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

export const SelectableOrderArticlesList: React.FunctionComponent<{
  order: NestorOrder;
  onSelected: (el: T) => void;
}> = (props) => {
  const apiListArticles = useOrderArticleList(props.order.id);
  const columnVis = useCallback(() => {
    return {
      actions: false,
      select: true,
      imputing: false,
      qty_o: false,
    };
  }, []);

  return (
    <StandardTableFilterCtxProvider>
      <ListContainer
        fwProps={{ order: props.order, useColumnVisibility: columnVis, select: props.onSelected }}
        listTemplate={ArticleTable}
        list={apiListArticles}
      />
    </StandardTableFilterCtxProvider>
  );
};
