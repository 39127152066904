import { NestorDateString } from "nestor/types/util/dates";
import { SimpleColumn, SortableColumn } from "@csem/lists";
import moment from "moment";

export const DateRenderer = ({ el }: { el: NestorDateString | null }) => {
  if (!el) {
    return null;
  }

  const date = moment(el);
  const dateStr = date.format("YYYY-MM-DD");
  const diff = date.fromNow();

  return (
    <>
      {dateStr}
      <br />
      <span className="text-muted">{diff}</span>
    </>
  );
};

export const DateColumn = <T, FW>(
  name: string,
  label: string,
  field: string | undefined,
  renderer: (el: T, fw: FW) => NestorDateString | null
) => {
  const col = SimpleColumn(name, label, (el: T, fw: FW) => {
    return <DateRenderer el={renderer(el, fw)} />;
  });

  if (field) return SortableColumn(col, field);
  else return col;
};
