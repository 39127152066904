import { SkuContainerListCard } from "nestor/components/nestor/stock/skus/ListWithContainers";
import { WithdrawalForm } from "nestor/components/nestor/stock/skus/movements/WithdrawalForm";
import { SkuShortcutPositionListCard } from "nestor/components/nestor/stock/sku_shortcuts/PositionList";
import { NestorSkuTransferContextProvider } from "nestor/contexts/NestorSkuTransferContext";
import { useSkuShortcutSingle } from "nestor/hooks/api/useSkuShortcutList";
import { NestorSkuShortcut } from "nestor/types/stock/shortcut";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

const SkuShortcutDetailsInner: React.FunctionComponent<{
  shortcut: NestorSkuShortcut;
  refresh: Function;
}> = (props) => {
  const route = useRouteMatch<{ Container: string }>();
  useMenuElement("Positions", route.url + "/positions");
  useMenuElement("Create movement", route.url + "/movement");

  return (
    <Switch>
      <Route path={`${route.url}/positions`}>
        <SkuShortcutPositionListCard shortcut={props.shortcut} />
      </Route>

      <Route path={`${route.url}/movement`}>
        <NestorSkuTransferContextProvider>
          <div className="col-md-12">
            <WithdrawalForm showWithdrawalContent={true} />
          </div>

          <SkuContainerListCard sku_shortcut={props.shortcut} />
        </NestorSkuTransferContextProvider>
      </Route>
      <Route>
        <Redirect to={`${route.url}/movement`} />
      </Route>
    </Switch>
  );
};

export const SKUShortcutDetails: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ shortcut: string }>();
  const shortcut = useSkuShortcutSingle(parseInt(route.params.shortcut));
  const menu = useMenuElement(
    shortcut.element ? `Shortcut ${shortcut.element.handle || " -- no handle"}` : "Loading...",
    `${route.url}`
  );

  return (
    <XemwayMenuProvider {...menu}>
      {shortcut.loadingIO}
      <div className="container-fluid p-3">
        {shortcut.element ? <SkuShortcutDetailsInner shortcut={shortcut.element} refresh={shortcut.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
