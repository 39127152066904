import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useCalibrationConfirmations } from "nestor/hooks/api/useCalibrationConfirmation";
import { NestorCalibration } from "nestor/types/inventory/calibration";
import { NestorCalibrationConfirmation } from "nestor/types/inventory/calibration_confirmation";
import { CardComponent } from "@csem/shared-utils";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { NavigationColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasRefresh } from "@csem/lists";
import { useRouteMatch } from "react-router";

type T = NestorCalibrationConfirmation;
type FWLst = { calibration: NestorCalibration; url: string };

type FW = FWLst; // & EditMethod<T, FWLst> & { confirm: (el: T, fw: FWLst) => void; revoke: (el: T, fw: FWLst) => void }; // & RemoveMethod<T, FWLst>;

const Table = (function () {
  return TableRenderer<T, FW, {}, {}, "id">({
    key: "id",
    columns: [
      NavigationColumn("go", "", (el, fw) => fw!.url + "/metrological_check/" + el.id),
      //   SimpleColumn("status", "Status", (e, fw) => <CalibrationTagRenderer calibration={e} device={fw.device} />),
      SimpleColumn("passed", "Pass/Fail", (e) =>
        e.passed ? (
          <div className="d-block badge bg-success">passed</div>
        ) : (
          <div className="d-block badge bg-danger">failed</div>
        )
      ),

      SimpleColumn("comment", "Comment", (e) => e.comment),
      SimpleColumn("conf", "Confirmed range", (e) => e.confirmation_range),
      SimpleColumn("lim", "Limitations", (e) => e.limitations),
      EmployeeColumn("employee", "By", "employee_id", (e) => e.employee),

      DateColumn("start", "Created at", "start", (e) => e.created_at),
      DateColumn("end", "Revoked at", "end", (e) => e.revoked_at),
      /*
            ButtonsColumn("actions", "Actions", [
                EditButton<T, FW>(),
                {
                    title: "Confirmation",
                    theme: "success",
                    cb: (e, fw) => {
                        fw.confirm(e, fw);
                    }
                },
                {
                    title: "Revoke",
                    visibility: e => {
                        return e.revoked_at ? false : true;
                    },
                    theme: "danger",
                    cb: (e, fw) => {
                        fw.revoke(e, fw);
                    }
                }
            ]) */ // Add delegation and confirm buttons
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        status: "100px",
        actions: "250px",
        confirmation: "350px",
        created_by: "100px",
        confirmed_by: "100px",
        start: "100px",
        end: "100px",
      };
    },
  });
})();

const Container = (function () {
  return HasStdTablePagination(HasRefresh(GenericListContent<FWLst, T>()));
})();

export const MetrologicalChecksCard = (props: { calibration: NestorCalibration }) => {
  const { url } = useRouteMatch();
  return (
    <CardComponent header="Metrological checks">
      <StandardTableFilterCtxProvider>
        <Container
          //cardHeader="Gateway list"
          list={useCalibrationConfirmations(props.calibration.id)}
          fwProps={{ calibration: props.calibration, url }}
          listTemplate={Table}
          additionalContent={[]}
          righthandContent={[]}
          lefthandContent={[]}
          bottomContent={[]}
        />
      </StandardTableFilterCtxProvider>
    </CardComponent>
  );
};
