import _ from "lodash";
import { NestorSku } from "nestor/types/stock/sku";
import React, { useCallback, useState } from "react";
import { NestorDevice } from "../types/inventory/device";
import { NestorPcb } from "../types/manufacturing/pcb";
import { NestorContainer } from "../types/stock/container";

type Content = {
  equipment?: NestorDevice;
  pcb?: NestorPcb;
  container?: NestorContainer;
  sku?: NestorSku;
};
type Vals = {
  [K in keyof Content as `el`]-?: NonNullable<Content[K]>;
};

type Keys = {
  [K in keyof Content as `type`]-?: NonNullable<K>;
};

type M = Array<Vals & Keys & { tags?: Record<string, string> }>;

type CtxType = {
  [K in keyof Content as `toggle`]-?: (el: NonNullable<Content[K]>, key: K) => void;
} & {
  [K in keyof Content as `set`]-?: (
    el: NonNullable<Content[K]> | NonNullable<Content[K]>[],
    key: K,
    onOff: boolean
  ) => void;
} & { store: M; empty: Function };

export const NestorSelectContext = React.createContext<undefined | CtxType>(undefined);

// Import to index by SKU id, because new objects are created across table refreshs

export const NestorSelectContextProvider = (props: React.PropsWithChildren<{}>) => {
  const [selectedStore, updateSelectedStore] = useState<M>([]);

  const toggle = useCallback((el: Vals["el"], k: Keys["type"], tags?: Record<string, string>) => {
    updateSelectedStore((w) => {
      const found = w.find((w) => w.el == el);
      if (found) {
        return _.without(w, found);
      } else {
        return [...w, { el, type: k, tags }];
      }
    });
  }, []);

  const set = useCallback(
    (el: Vals["el"] | Vals["el"][], k: Keys["type"], onOff: boolean, tags?: Record<string, string>) => {
      updateSelectedStore((w) => {
        let ids: Array<number>;
        let els;
        if (Array.isArray(el)) {
          els = el;
        } else {
          els = [el];
        }

        ids = els.map((e) => e.id);

        let w2 = w.filter((w2) => {
          if (w2.type == k && ids.includes(w2.el.id)) {
            return false;
          }
        });

        if (onOff) {
          w2 = [
            ...w,
            ...els.map((el) => {
              return { el, type: k, tags: { ...tags } };
            }),
          ];
        }

        return w2;
      });
    },
    []
  );

  const empty = useCallback(() => {
    updateSelectedStore(() => {
      return [];
    });
  }, []);

  return (
    <NestorSelectContext.Provider
      value={{
        store: selectedStore,
        toggle,
        empty,
        set,
      }}
    >
      {props.children}
    </NestorSelectContext.Provider>
  );
};
