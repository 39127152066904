import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PCBAssemblyDetail } from "./PCBAssemblyDetail";
import { PCBAssemblyFeederSetDetail } from "./PCBAssemblyFeederSetDetail";
import { PCBAssemblyFeederSetList } from "./PCBAssemblyFeederSetList";
import { PCBAssemblyList } from "./PCBAssemblyList";

export function PCBAssemblyMain() {
  const { url } = useRouteMatch();
  const layout_list = useMenuElement("List of projects", url + "/list");
  const feeder_list = useMenuElement("List of feeders", url + "/feeders/list");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...layout_list}>
          <PCBAssemblyList></PCBAssemblyList>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/feeders/list`} key="feederlist">
        <XemwayMenuProvider {...feeder_list}>
          <PCBAssemblyFeederSetList></PCBAssemblyFeederSetList>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/feeders/:feederset`}>
        <PCBAssemblyFeederSetDetail></PCBAssemblyFeederSetDetail>
      </Route>

      <Route path={`${url}/:assembly`}>
        <PCBAssemblyDetail></PCBAssemblyDetail>
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
