import NestorServerRoutes from "nestor/server_routes";
import { NestorDeviceModelId } from "nestor/types/inventory/device_model";
import { NestorDeviceModelParameter } from "nestor/types/inventory/device_model_parameter";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorDeviceModelParameter;
export const useDeviceModelParameterList = (device_model_id: NestorDeviceModelId): apiList<T> => {
  return useNestorListApiCall<T>(
    NestorServerRoutes.device_model_parameter_list(device_model_id),
    "device model parameter"
  );
};
