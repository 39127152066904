import { BackofficeColumn } from "nestor/components/tables/columns/BackofficeColumn";
import { OrderNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { useOrderList } from "nestor/hooks/api/useOrderList";
import settings from "settings";
import { NestorProject } from "nestor/types/projects/projects";
import {
  NestorOrder,
  NestorOrderState,
  NestorOrderStateTexts,
  NestorOrderTransferingType,
} from "nestor/types/purchasing/orders";
import { NestorSupplierId } from "nestor/types/purchasing/supplier";
import { NestorCurrency, NestorCurrencyId } from "nestor/types/util/currency";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import {
  FilterProps,
  HasSearch,
  HasStdTablePagination,
  HasTableFilterClear,
  HasRefresh,
  TableSearch,
} from "@csem/lists";
import { FilterOperator } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import {
  ButtonsColumn,
  CheckboxFilterColumn,
  FilterableColumn,
  SimpleColumn,
  SortableColumn,
  TableRenderer,
} from "@csem/lists";
import { GenericListContent } from "@csem/lists";
import { ToggleCheck } from "@csem/shared-utils";
import { useOnChange } from "@csem/shared-utils";
import _, { cloneDeep } from "lodash";
import { useMemo, useState } from "react";
import { DateColumn } from "../../../tables/columns/DateColumn";
import { EmployeeColumn } from "../../../tables/columns/EmployeeColumn";
import { ProjectColumn } from "../../../tables/columns/ProjectColumn";
import { SupplierColumn } from "../../../tables/columns/SupplierColumn";
import DownloadButton from "../../util/DownloadButton";
import { MonetaryAmount, MonetaryAmountRenderer } from "../../util/MonetaryAmountRenderer";
import { Link } from "react-router-dom";
import { ButtonLink } from "../../util/ButtonLink";
import { useMe } from "nestor/hooks/me";
import { NestorMe } from "nestor/hooks/api/useEmployeeList";

type T = NestorOrder;

function StateFilter(props: FilterProps<number[]>) {
  const [sel, setSel] = useState<Array<number>>([]);
  const onChange = props.onChange;
  useOnChange(
    () => {
      props.onChange(sel, "in");
    },
    [sel],
    [onChange]
  );
  return (
    <>
      {Object.values(NestorOrderState).map((k) => {
        if (typeof k === "string") {
          return null;
        }
        return (
          <div className="mb-2" key={k}>
            <label>
              <input
                className="checkbox"
                type="checkbox"
                checked={(props.originalValue?.__default as Array<number> | undefined)?.includes(k)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSel((s) => _.uniq<number>([...s, k as NestorOrderState]));
                  } else {
                    setSel((s) => _.without(s, k as NestorOrderState));
                  }
                }}
              />{" "}
              {NestorOrderStateTexts[k as unknown as NestorOrderState]?.tag}
            </label>
          </div>
        );
      })}
    </>
  );
}

const OrderTagRenderer = (el: NestorOrder) => {
  return (
    <>
      {el.urgent ? <div className="d-block badge bg-danger">urgent</div> : null}
      {el.grouped_order ? <div className="d-block badge bg-info">grouped</div> : null}
      {el.is_administrative ? <div className="d-block badge bg-dark">admin.</div> : null}
      {el.type === "restricted" ? <div className="d-block badge bg-warning">restricted</div> : null}
      {el.has_chemicals ? <div className="d-block badge bg-danger">chemicals</div> : null}

      {el.transferring === NestorOrderTransferingType.INTERNAL ? (
        <div className="d-block badge bg-info">
          <em className="bi bi-arrow-left-right"></em> internal
        </div>
      ) : null}
    </>
  );
};

const OrderStatusRenderer = (status: NestorOrderState) => {
  let type: string;
  let text: string;
  text = NestorOrderStateTexts[status].tag;
  // DO NOT PUT === HERE !!!
  if (status === NestorOrderState.EDIT) {
    type = "danger";
  } else if (status === NestorOrderState.PROCESSING) {
    type = "warning";
    /*} else if (status === 20) {
    text = "3. signing in progress";
    type = "primary";*/
  } else if (status === NestorOrderState.WAITING_SEND) {
    type = "primary";
  } else if (status === NestorOrderState.WAITING_CLOSE) {
    type = "info";
  } else if (status === NestorOrderState.FINALIZED) {
    type = "success";
  } else if (status === NestorOrderState.CANCELLED) {
    type = "dark";
  } else {
    text = "Unknown";
    type = "danger";
  }

  return <div className={`badge bg-${type}`}>{text}</div>;
};

type FW = {
  selected?: Function;
  invoicing?: boolean;
};

type FWLst = FW;

export const SAPColumn = FilterableColumn(
  SortableColumn(
    SimpleColumn("sap", "SAP #", (e: T) => e.sap_id),
    "sap_id"
  ),
  TextFilterFactory({ label: "SAP number", operator: "eq" })
);

export const OrderProjectColumn = ProjectColumn(
  "project",
  "Project",
  "project_id",
  true,
  true,
  (e: NestorOrder) => e.project,
  (e) => e.workpackage,
  (e) => e.section
);
export const OrderSupplierColumn = SupplierColumn(
  "supplier",
  "Supplier",
  "supplier_id",
  (e: NestorOrder) => e.supplier
);

const GenericOrderTable = (function () {
  return TableRenderer<T, FWLst, {}, {}, "id">({
    key: "id",
    columns: [
      OrderNavigationColumn,
      NUIDColumn,

      CheckboxFilterColumn<NestorOrder>(
        "tags",
        "Tags",
        {
          admin: "is_administrative",
          group: "grouped_order",
          urgent: "urgent",
          restricted: "type",
          chemicals: "has_chemicals",
        },
        OrderTagRenderer,
        [
          {
            on: 1,
            off: undefined,
            filterKey: "admin",
            label: "Administrative",
          },

          {
            on: 1,
            off: undefined,
            filterKey: "group",
            label: "Grouped order",
          },
          {
            on: 1,
            off: undefined,
            filterKey: "urgent",
            label: "Urgent",
          },
          {
            on: "restricted",
            off: undefined,
            filterKey: "restricted",
            label: "Restricted",
          },
          {
            on: 1,
            off: 0,
            filterKey: "chemicals",
            label: "Chemicals",
          },
        ]
      ),

      BackofficeColumn("backoffice", "Backoffice", "backoffices.id", (e) => e.backoffices),
      SAPColumn,

      OrderProjectColumn,
      OrderSupplierColumn,

      SimpleColumn("num_articles", "# Art.", (e) => e.articles_count),

      SimpleColumn("amount", "Amount", (e) => {
        return MonetaryAmountRenderer(e.total, e.currency, "Unknown currency");
      }),

      FilterableColumn(
        SimpleColumn("status", "Status", (e) => OrderStatusRenderer(e.state)),
        StateFilter,
        "state"
      ),

      SimpleColumn("invoicing", "Invoicing", (e) => {
        const p = e.invoices?.reduce((p, i) => {
          assert(i.currency, "Currency is not defined");
          p.set(i.currency.id, [i.currency, (p.has(i.currency.id) ? p.get(i.currency.id)![1] : 0) + i.amount]);
          return p;
        }, new Map<NestorCurrencyId, [NestorCurrency, number]>());
        if (!p) {
          return;
        }
        return (
          <>
            {Array.from(p.entries()).map(([c, v]) => (
              <div>
                <MonetaryAmount currency={v[0]} value={v[1]} />
              </div>
            ))}
          </>
        );
      }),

      //EmployeeColumn<T>("user", "By", "employee.id", e => e.employee),
      EmployeeColumn<T>("emply", "Initiator", "employee_id", (e) => e.employee),
      EmployeeColumn<T>("left", "Left", "signee_left_id", (e) => e.signee_left),
      EmployeeColumn<T>("right", "Right", "signee_right_id", (e) => e.signee_right),
      EmployeeColumn<T>("secretary", "Secretary", "secretary_id", (e) => e.secretary),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("select", "Actions", [
        {
          title: "Select",
          theme: "primary",
          cb: (el, fw) => {
            fw.selected?.(el);
          },
        },
      ]),
    ],

    useColumnVisibility: (e, fw) => {
      return { select: false, invoicing: fw.invoicing === true };
    },

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      const userWidth = "100px";

      return {
        _nav: "0px",
        supplier: "250px",
        backoffice: "100px",
        tags: "80px",
        nuid: "80px",
        sap: "110px",
        num_articles: "60px",
        emply: userWidth,
        left: userWidth,
        right: userWidth,
        secretary: userWidth,
        created_at: "120px",
        status: "70px",
      };
    },
  });
})();

const GenericOrderListContainer = (function () {
  return HasTableFilterClear(
    HasSearch(
      HasRefresh(
        HasStdTablePagination(
          GenericListContent<FWLst & { useColumnVisibility?: (c: Array<string>) => Record<string, boolean> }, T>()
        )
      ),
      {
        nuid: "eq",
        "employee.initials": "eq",
        "project.name": "contains",
        "project.code": "contains",
        "supplier.name": "contains",
        sap_id: "eq",
        _search: "contains",
      },
      "or",
      "",
      TableSearch
    )
  );
})();

const useDefaultOrderFilter = function useDefaultOrderFilter(
  supplierId?: NestorSupplierId,
  state?: t_OrderState,
  me?: NestorMe,
  mine?: boolean
) {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    assert(me);
    // Pre-filter per supplier
    if (supplierId) {
      defaultFilter.filter.logic = "and";
      defaultFilter.filter.filters[0].filters.push({ field: "supplier_id", operator: "eq", value: supplierId });
    }

    if (mine) {
      defaultFilter.filter.filters[0].filters.push({
        logic: "or",
        filters: [
          {
            field: "employee_id",
            operator: "eq",
            value: me.id,
          },
          {
            field: "signee_left_id",
            operator: "eq",
            value: me.id,
          },
          {
            field: "post_handler_id",
            operator: "eq",
            value: me.id,
          },
          {
            field: "secretary_id",
            operator: "eq",
            value: me.id,
          },
          {
            field: "signee_right_id",
            operator: "eq",
            value: me.id,
          },
        ],
      });
    } else if (me) {
      assert(me.section, "Section should exist");

      defaultFilter.filter.filters[0].filters.push({
        logic: "or",
        filters: [
          {
            field: "backoffices.id",
            operator: "in",
            value: me.merged_backoffice.map((e) => e.id),
          },
          {
            field: "division_id",
            operator: "eq",
            value: me.section?.division_id,
          },
        ],
      });
    }

    if (state) {
      defaultFilter.filter.filters[0].filters = defaultFilter.filter.filters[0].filters.concat(
        state.map((s) => {
          return { ...s, field: "state" };
        })
      );
    }

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, [supplierId, state]);

  return defaultFilter;
};

export type t_OrderState = Array<{
  value: string;
  operator: FilterOperator;
}>;

export const orders_draft: t_OrderState = [
  {
    value: "0",
    operator: "eq",
  },
];

export const orders_unfinalized: t_OrderState = [
  {
    value: "100",
    operator: "lt",
  },
];

export const orders_open: t_OrderState = [
  {
    value: "100",
    operator: "lt",
  },
  {
    value: "0",
    operator: "gt",
  },
];

const emptyArr: JSX.Element[] = [];

export const OrderListCard = ({
  supplierId,
  //projectCode,
  // invoicing,
  ...props
}: {
  supplierId?: number;
  // projectCode?: NestorProject["code"];
  me?: boolean;
  //  invoicing?: boolean;
  state?: t_OrderState;
  header?: string;
}) => {
  type settings_t = { invoicing: boolean };
  const [togglable_settings, setSettings] = useState<settings_t>({ invoicing: false });

  const me = useMe();
  const defaultFilter = useDefaultOrderFilter(supplierId, props.state, me, props.me);

  const list2 = useOrderList({});
  const list1 = useOrderList({});

  return (
    <div className="my-2">
      <CardComponent header={props.header || "Order list"}>
        <div className="row my-2">
          <div className="col-3">
            <ToggleCheck
              text="Show invoiced amounts"
              checked={!!togglable_settings.invoicing}
              onChange={(state) =>
                setSettings((s) => {
                  return { ...s, invoicing: state };
                })
              }
            />
          </div>
        </div>
        <StandardTableFilterCtxProvider defaultValue={defaultFilter} persistKey="orders" persistWithURL={true}>
          <GenericOrderListContainer
            list={list1}
            fwProps={useMemo(() => {
              return {
                invoicing: !!togglable_settings.invoicing,
              };
            }, [togglable_settings])}
            listTemplate={GenericOrderTable}
            additionalContent={emptyArr}
            righthandContent={[
              <Link to={`/purchasing/orders/create`} component={ButtonLink} className="btn btn-success" key="add">
                <em className="bi bi-plus"></em> New Order
              </Link>,
              <DownloadButton key="_dl" columns={settings.download_xlsx_columns.orders} api={list2} />,
            ]}
            lefthandContent={emptyArr}
            bottomContent={emptyArr}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

export const SelectableOrderListCard = ({
  supplierId,
  ...props
}: {
  supplierId?: number;
  onSelected: (el: NestorOrder) => void;
}) => {
  const defaultFilter = useDefaultOrderFilter(supplierId);

  return (
    <StandardTableFilterCtxProvider defaultValue={defaultFilter} persistKey="orders" persistWithURL={true}>
      <GenericOrderListContainer
        //cardHeader="Gateway list"
        list={useOrderList()}
        fwProps={{
          selected: props.onSelected,
          useColumnVisibility: () => {
            return { _nav: false, select: true };
          },
        }}
        listTemplate={GenericOrderTable}
        additionalContent={[]}
        righthandContent={[]}
        lefthandContent={[]}
        bottomContent={[]}
      />
    </StandardTableFilterCtxProvider>
  );
};
