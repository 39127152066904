import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorContact } from "nestor/types/purchasing/contact";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useAddressFields } from "../../../../forms/bundles/AddressFields";
import { useContactFields } from "../../../../forms/bundles/ContactFields";

export const ContactQuickAddEditModal = StandardFormAddEditModalFactory<
  NestorContact,
  { supplier: NestorSupplier },
  any
>({
  title: (el, fw) =>
    el
      ? `Edit the contact ${el.fullname} for supplier ${fw?.supplier.name}`
      : `Add a new contact for supplier ${fw?.supplier.name}`,

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const title = useFormSelectObj({
      label: "Name",
      originalValue: el?.title,
      options: {
        Mr: "Mr",
        Ms: "Ms",
        Mrs: "Mrs",
        Dr: "Dr",
        Prof: "Prof",
      },
      defaultValue: "Mr",
      validationOnChange: useFieldOptRequired(true),
    });

    const first_name = useFormTextField({
      label: "First name",
      originalValue: el?.firstname,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: useFieldOptRequired<string>(true),
    });
    const last_name = useFormTextField({
      label: "Last name",
      originalValue: el?.lastname,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: useFieldOptRequired<string>(true),
    });

    const position = useFormTextField({
      label: "Position",
      originalValue: el?.position,
      type: FieldValueType.STRING,
      defaultValue: "",
    });

    const { address, address2, postal_code, city, country } = useAddressFields(el, true, true, true, true);
    const { email, phone } = useContactFields(el, true, true);

    const apiResult = useNestorAPI(
      !el
        ? `purchasing/suppliers/${fw.supplier.id}/contacts/forms/create`
        : `purchasing/suppliers/${fw.supplier.id}/contacts/${el.id}/forms/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        firstname: first_name.value,
        lastname: last_name.value,
        title: title.value,
        position: position.value,
        address: address.value,
        address2: address2.value,
        postal_code: postal_code.value,
        city: city.value,
        country: country.value,
        phone: phone.value,
        email: email.value,

        supplier_id: fw.supplier.id,
      });
    };

    return {
      fields: {
        title,
        first_name,
        last_name,
        position,
        address,
        address2,
        postal_code,
        city,
        country,
        phone,
        email,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
