import { MaintenanceAddEditModal } from "nestor/components/nestor/inventory/maintenances/modals/AddEditMaintenance";
import { useMaintenanceList } from "nestor/hooks/api/useMaintenanceList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useMyDivision } from "nestor/hooks/me";
import { lang } from "nestor/lang";
import { NestorDivision } from "nestor/types/company/division";
import { NestorMaintenance } from "nestor/types/inventory/maintenance";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { ButtonsColumn, FilterableColumn, HtmlColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasModalMethod,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { DeviceListModal, InspectDevices } from "../devices/ModalList";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import NestorServerRoutes from "nestor/server_routes";

type T = NestorMaintenance;
type FWLst = { myDivision: NestorDivision };
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst> & Partial<InspectDevices<T, FWLst>>;

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      FilterableColumn(
        SortableColumn(
          SimpleColumn("name", "Name", (e) => e.name),
          "name"
        ),
        TextFilterFactory({ label: "Name" })
      ),

      HtmlColumn("description", "Description", (e) => e.description),
      SimpleColumn("periodicity", "Periodicity", (e) => e.periodicity_days + " days"),
      SimpleColumn("devices", "Number of devices affected", (e, fw) => (
        <div className="d-flex justify-content-between">
          <div>
            <div>Total (CSEM): {e.devices ? e.devices.length : "N/A"}</div>
            <div>
              Total ({lang.division}):{" "}
              {e.devices ? e.devices.filter((d) => d.section?.division_id === fw.myDivision).length : "N/A"}
            </div>
          </div>
          <button type="button" onClick={(_) => fw.inspect_devices!(e, fw)} className="btn btn-secondary">
            <em className="bi bi-search"></em>
          </button>
        </div>
      )),
      ButtonsColumn("actions", "Actions", [EditButton(), RemoveButton("Revoke", (e) => e.devices?.length === 0)]),
    ],
    useColumnWidth: () => {
      return { description: "400px" };
    },
    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const Container = HasModalMethod(
  HasDeletion(
    HasEdition(
      HasAddition(
        HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
        MaintenanceAddEditModal,
        "Add a new maintenance",
        "plus",
        "primary"
      ),
      MaintenanceAddEditModal
    ),
    "Confirm maintenance revocation",
    (v: T) => `Are you sure to revoke the maintenance "${v.name}" ?`,
    (props) => {
      return useNestorAPI<undefined, NestorMaintenance>(
        (el) => {
          assert(el !== undefined);
          return NestorServerRoutes.maintenance_delete(el.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  ),
  DeviceListModal("device_maintenances.maintenance_id"),
  "inspect_devices"
);

export const MaintenanceListCard = () => {
  const myDivision = useMyDivision();
  assert(myDivision);

  return (
    <div className="m-2">
      <CardComponent header="Maintenance list">
        <StandardTableFilterCtxProvider>
          <Container
            //cardHeader="Gateway list"
            list={useMaintenanceList()}
            fwProps={{ myDivision }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
