import NestorFrontendRoutes from "nestor/frontend_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorPcbAssembly, NestorPcbAssemblyFeederSet } from "nestor/types/manufacturing/pcb_assembly";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { NestorTask } from "nestor/types/manufacturing/task";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorOrderFeedback, NestorOrderFeedbackId } from "nestor/types/purchasing/order_feedback";
import { NestorParcel, NestorParcelId } from "nestor/types/purchasing/parcel";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { NestorSupplierQualification } from "nestor/types/purchasing/supplier_qualification";
import { NestorContainer } from "nestor/types/stock/container";
import { NestorSkuShortcut } from "nestor/types/stock/shortcut";
import { NestorSku } from "nestor/types/stock/sku";
import { NavigationColumn } from "@csem/lists";

const columnName = "_nav";

export const ProjectNavigationColumn = NavigationColumn(columnName, "", (el: NestorProject) => {
  return NestorFrontendRoutes.project_details(el.id);
});

export const DeviceNavigationColumn = NavigationColumn(columnName, "", (el: NestorDevice) => {
  return NestorFrontendRoutes.device_details(el.id);
});

export const DeviceModelNavigationColumn = NavigationColumn(columnName, "", (el: NestorDeviceModel) => {
  return NestorFrontendRoutes.devicemodel_details(el.id);
});

export const SKUNavigationColumn = NavigationColumn(columnName, "", (el: NestorSku) => {
  return NestorFrontendRoutes.sku_details(el.id);
});

export const OrderNavigationColumn = NavigationColumn(columnName, "", (el: NestorOrder) => {
  return NestorFrontendRoutes.order_details(el.id);
});

export const OrderFeedbackNavigationColumn = NavigationColumn(columnName, "", (el: NestorOrderFeedback) => {
  return NestorFrontendRoutes.order_feedback_details(el.order_id, el.id);
});

export const ContainerNavigationColumn = NavigationColumn(columnName, "", (el: NestorContainer) => {
  return NestorFrontendRoutes.container_details(el.id);
});

export const PCBLayoutNavigationColumn = NavigationColumn(columnName, "", (el: NestorPcbLayout) => {
  return NestorFrontendRoutes.pcblayout_details(el.id);
});

export const PCBNavigationColumn = NavigationColumn(columnName, "", (el: NestorPcb) => {
  return NestorFrontendRoutes.pcb_details(el.layout_id, el.id);
});

export const PCBAssemblyNavigationColumn = NavigationColumn(columnName, "", (el: NestorPcbAssembly) => {
  return NestorFrontendRoutes.pcbassembly_details(el.id);
});

export const PCBAssemblyFeederSetNavigationColumn = NavigationColumn(
  columnName,
  "",
  (el: NestorPcbAssemblyFeederSet) => {
    return NestorFrontendRoutes.pcbassemblyfeederset_details(el.id);
  }
);

export const SkuShortcutNavigationColumn = NavigationColumn(columnName, "", (el: NestorSkuShortcut) => {
  return NestorFrontendRoutes.skushortcut_details(el.id);
});

export const SupplierNavigationColumn = NavigationColumn(columnName, "", (el: NestorSupplier) => {
  return NestorFrontendRoutes.supplier_details(el.id);
});

export const SupplierQualificationNavigationColumn = NavigationColumn(
  columnName,
  "",
  (el: NestorSupplierQualification) => {
    return NestorFrontendRoutes.supplier_qualification_details(el.supplier_id, el.id);
  }
);

export const TaskNavigationColumn = NavigationColumn(columnName, "", (el: NestorTask) => {
  return NestorFrontendRoutes.task_details(el.id);
});

export const ParcelNavigationColumn = NavigationColumn(columnName, "", (el: NestorParcel) => {
  return NestorFrontendRoutes.parcel_details(el.order_id, el.id);
});
