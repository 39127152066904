import { NestorLabelPrinter } from "nestor/types/util/label_printer";
import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";

type T = NestorLabelPrinter[];

type LocalStorageEl<T> = {
  data: T;
  expiracy: number;
};

export const useLabelPrinterList = (): NestorApiElement<T, undefined> => {
  const key = "printers;";
  const storage = localStorage.getItem(key);
  let expired: boolean = true;
  let localData: T | undefined = undefined;
  if (storage) {
    let _ = JSON.parse(storage) as LocalStorageEl<T>;
    localData = _.data;
    expired = _.expiracy < Date.now();
  }

  const apiCall = useNestorAPI<T>(`company/labelPrinters/list`, expired);

  if (expired && apiCall.result) {
    localStorage.setItem(
      key,

      JSON.stringify({
        expiracy: Date.now() + 3600 * 24 * 1000,
        data: apiCall.result,
      })
    );
  } else if (!expired && localData) {
    apiCall.result = localData;
  }

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading label printers..." source={apiCall.state} success={null} />,
    state: apiCall.state,
  };
};
