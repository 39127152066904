import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { NestorOrderFeedback } from "nestor/types/purchasing/order_feedback";

export const useFeedbackSingle = (elId: number | string): NestorApiElement<NestorOrderFeedback, undefined> => {
  const apiCall = useNestorAPI<NestorOrderFeedback>(`purchasing/orders/feedbacks/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading feedback..." source={apiCall.state} success={null} />,
  };
};
