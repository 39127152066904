import { NestorEmployee } from "nestor/types/company/employee";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorRole } from "nestor/types/system/role";
import { apiList } from "@csem/api";
import { useMyDivision } from "../me";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";
import { NestorBackoffice } from "nestor/types/company/backoffice";

type T = NestorEmployee;
export const useEmployeeList = (): apiList<T> => {
  return useNestorListApiCall<T>("list/company/employees", "employee", undefined, true);
};

export const useBTEmployeeList = (): apiList<T> => {
  return useNestorListApiCall<T>("list/company/employees", "employee", new URLSearchParams({ bt: "1" }));
};

export const useSecretaryEmployeeList = (): apiList<T> => {
  return useNestorListApiCall<T>("list/company/employees", "employee", new URLSearchParams({ secretary: "1" }));
};

export const useMyDivisionEmployeeList = (): apiList<T> => {
  const myDivision = useMyDivision();
  return useNestorListApiCall<T>("list/company/employees", "employee", new URLSearchParams({ division: myDivision }));
};

export type NestorMe = NestorEmployee & {
  roles: Array<NestorRole>;
  projects?: Array<NestorProject>;
  merged_backoffice: Array<NestorBackoffice>;
};

export const useEmployeeMe = () => {
  return useNestorAPI<NestorMe>("company/employees/current", true);
};
