import { AddEditTaskProgressModal } from "nestor/components/modals/AddEditTaskProgress";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useTaskProgressList } from "nestor/hooks/api/useTaskProgressList";
import { useAmIBT } from "nestor/hooks/me";
import { NestorTask } from "nestor/types/manufacturing/task";
import { NestorTaskProgress } from "nestor/types/manufacturing/task_progress";
import { CardComponent } from "@csem/shared-utils";
import { EditButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { EditMethod, GenericListContent, HasAddition, HasEdition, HasPagination, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorTaskProgress;
type FWLst = { task: NestorTask; is_elevated: boolean };
type FW = EditMethod<T, FWLst>;
type HOOK = {};

const TaskTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("hours", "Number of hours", (e) => e.hours + " hours"),
      SimpleColumn("progress", "New progress", (e) => e.progress + "%"),

      SimpleColumn("comment", "Comment", (e) => e.comment),
      EmployeeColumn("emply", "Employee", "employee_id", (e) => e.employee),

      DateColumn("date", "Date", "date", (e) => e.created_at),

      ButtonsColumn("actions", "", [
        /*{
                    title: "Revoke",
                    theme: "danger",
                    visibility: (e, fw) => {
                        return fw.is_elevated;
                    },
                    cb: (e, fw) => {
                        fw.remove(e, fw);
                    }
                }*/

        EditButton("Edit", (el, fw) => fw.is_elevated),
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const TaskListContainer = (function () {
  return HasEdition(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
      AddEditTaskProgressModal,
      "Register new progress",
      "plus",
      "success",
      useAmIBT
    ),
    AddEditTaskProgressModal
  );
})();

export const TaskProgressListCard = (props: { task: NestorTask }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);

  const is_elevated = useAmIBT();

  return (
    <div className="m-2">
      <CardComponent header="Progress list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <TaskListContainer
            //cardHeader="Gateway list"
            list={useTaskProgressList(props.task.id)}
            fwProps={{ is_elevated, task: props.task }}
            listTemplate={TaskTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
