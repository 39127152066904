import { IOState } from "@csem/api";
import React from "react";
import { NestorMe, useEmployeeMe } from "../hooks/api/useEmployeeList";

export const NestorUserContext = React.createContext<NestorMe | undefined>(undefined);

export const NestorUserContextProvider = (props: React.PropsWithChildren<{}>) => {
  const api = useEmployeeMe();

  if (api.state.success) {
    return <NestorUserContext.Provider value={api.result}>{props.children}</NestorUserContext.Provider>;
  } else {
    return <IOState loadingMessage="Loading your profile..." source={api.state} />;
  }
};
