import { NestorContainer } from "nestor/types/stock/container";
import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";

export const useContainerSingle = (elId: number | string): NestorApiElement<NestorContainer, undefined> => {
  const apiCall = useNestorAPI<NestorContainer>(`stock/containers/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading container..." source={apiCall.state} success={null} />,
  };
};
