import { useFormRoleMultField } from "nestor/components/forms/fields/RoleList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorEmployee } from "nestor/types/company/employee";
import { NestorRole } from "nestor/types/system/role";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormDateField } from "@csem/forms";
import { useFormMultipleSelect } from "@csem/forms";
import { IOState } from "@csem/api";
import moment from "moment";
import { useMemo } from "react";
import { apiList } from "@csem/api";
import { useAllSectionsField } from "../../../../forms/fields/SectorList";

export const useRolesList = (): apiList<NestorRole> => {
  const {
    state,
    result,
    setSearchParams,
    doQuery: refresh,
  } = useNestorAPI<Array<NestorRole>>("company/roles/list", true);

  return {
    refresh,
    setSearchParams,
    list: result,
    loadingIO: (
      <IOState
        source={state}
        success={null}
        loading={null}
        successMessage={undefined}
        loadingMessage={undefined}
        errorMessage="Error while loading the grant list"
      />
    ),
  };
};

export const EditEmployeeModal = StandardFormAddEditModalFactory<NestorEmployee, any, any>({
  title: (el) => (el ? `Edit the employee ${el.fullname}` : ""),
  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const role_id = useFormRoleMultField(el?.roles);

    /**
     * Allows a secretary to change the section of user temporarily
     * The section is reset in the sync:sections command
     */
    const section_id = useAllSectionsField({
      label: "Section",
      defaultValue: undefined,
      originalValue: el?.section_id,
    });

    const section_override_until = useFormDateField({
      label: "Override section until",
      originalValue: el?.section_override_until,
      defaultValue: moment().format("YYYY-MM-DD"),
      disabled: section_id.value === el?.import_section_id,
    });

    const apiResult = useNestorAPI(!el ? `` : `company/employees/${el.id}/forms/update`, false, undefined, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    const cb = () => {
      apiResult.doQuery(undefined, {
        role_id: role_id.value.map((e) => e.id),
        section_id: section_id.value,
        section_override_until: section_override_until.value,
      });
    };

    return {
      fields: {
        role_id,
        section_id,
        section_override_until,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
