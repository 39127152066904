import { NestorLaboratory } from "nestor/types/company/laboratory";
import { apiList, IOState } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";
import { useNestorAPI } from "./useNestorAPI";
import { NestorApiElement } from "./types";

type T = NestorLaboratory;
export const useLaboratoryList = (): apiList<T> => {
  return useNestorListApiCall<T>(`list/company/laboratories`, "laboratories");
};

export const useLaboratorySingle = (elId: number | string): NestorApiElement<NestorLaboratory, undefined> => {
  const apiCall = useNestorAPI<NestorLaboratory>(`company/laboratories/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading laboratory..." source={apiCall.state} success={null} />,
  };
};
