import { NestorAttachmentTag } from "nestor/types/system/attachment";

/**
 * Shows a document tag
 * @param status One of the available nestor tag
 * @returns JSX.Element
 */
export const AttachmentTagRenderer = (status: NestorAttachmentTag) => {
  let icon: string;
  let text: string;
  if (status === NestorAttachmentTag.DELIVERY_NOTE) {
    text = "Delivery note";
    icon = "truck";
  } else if (status === NestorAttachmentTag.DELIVERY) {
    text = "Delivery document";
    icon = "clipboard-check";
  } else if (status === NestorAttachmentTag.INCOMING_INSPECTION) {
    text = "Incoming inspection";
    icon = "file-earmark-check";
  } else if (status === NestorAttachmentTag.INSPECTION) {
    text = "Inspection";
    icon = "file-earmark-check";
  } else if (status === NestorAttachmentTag.METROLOGICAL_RECORD) {
    text = "Metrological record";
    icon = "file-earmark-binary";
  } else if (status === NestorAttachmentTag.METROLOGICAL_PROCEDURE) {
    text = "Metrological procedure";
    icon = "file-earmark-easel";
  } else if (status === NestorAttachmentTag.PICTURE) {
    text = "Picture";
    icon = "file-image";
  } else if (status === NestorAttachmentTag.INVOICE) {
    text = "Invoice";
    icon = "currency-dollar";
  } else if (status === NestorAttachmentTag.MSDS) {
    text = "MSDS";
    icon = "radioactive";
  } else if (status === NestorAttachmentTag.USER_MANUAL) {
    text = "User manual";
    icon = "book";
  } else if (status === NestorAttachmentTag.MANUAL) {
    text = "Manual";
    icon = "book-fill";
  } else if (status === NestorAttachmentTag.REPORT) {
    text = "Report";
    icon = "card-checklist";
  } else if (status === NestorAttachmentTag.PO) {
    text = "Purchase order";
    icon = "send";
  } else if (status === NestorAttachmentTag.ORDER_CONFIRMATION) {
    text = "Order confirmation";
    icon = "send-check";
  } else if (status === NestorAttachmentTag.OFFER) {
    text = "Offer";
    icon = "basket";
  } else if (status === NestorAttachmentTag.CERTIFICATE_CONFORIMITY) {
    text = "Certificate of Conformity";
    icon = "award";
  } else if (status === NestorAttachmentTag.OTHER) {
    text = "Other";
    icon = "question";
  } else if (status === NestorAttachmentTag.QUALIFICATION_REPORT) {
    text = "Qualification report";
    icon = "clipboard-check";
  } else if (status === NestorAttachmentTag.QUALITY_AGREEMENT_PLAN) {
    text = "Quality agreement / plan";
    icon = "clipboard-data";
  } else if (status === NestorAttachmentTag.CALIBRATION_CERTIFICATE) {
    text = "Calibration certificate";
    icon = "award";
  } else if (status === NestorAttachmentTag.CERTIFICATE) {
    text = "Other certificate";
    icon = "award";
  } else if (status === NestorAttachmentTag.MINUTES_OF_MEETING) {
    text = "Minutes of meeting";
    icon = "pencil";
  } else if (status === NestorAttachmentTag.EF611) {
    text = "EF611";
    icon = "certificate";
  } else {
    return null;
  }

  return (
    <>
      <em className={`bi bi-${icon}`}></em>
      &nbsp;{text}
    </>
  );
};
