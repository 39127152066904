import { useDeviceModelParameterList } from "nestor/hooks/api/useDeviceModelParameterList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceModel, NestorDeviceModelId } from "nestor/types/inventory/device_model";
import { NestorDeviceModelParameter } from "nestor/types/inventory/device_model_parameter";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";

import { ButtonsColumn, CheckboxFilterColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  HasSearch,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { DeviceModelParameterModal } from "./modals/AddEditDeviceModelParameter";
type T = NestorDeviceModelParameter;
type FW = { device_model_id: NestorDeviceModelId };
type FWList = EditMethod<T, FW> & RemoveMethod<T, FW>;

export const GenericDeviceTable = (function () {
  return TableRenderer<T, FW, FWList, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("name", "Name", (e, fw, hk) => e.name),
      SimpleColumn("type", "Type of field", (e, fw, hk) => e.type),

      CheckboxFilterColumn(
        "checkbox",
        "Show in table",
        "show_in_header",
        (e) => (
          <>
            <em className={e.show_in_header ? "bi bi-check" : "bi bi-x"}></em>{" "}
          </>
        ),
        [{ label: "Show in table", on: 1, off: 0, filterKey: "" }]
      ),
      SimpleColumn("type", "Device type", (e) => e.type),
      SimpleColumn("group", "Group", (e) => e.group),
      SimpleColumn("priority", "Piority", (e) => e.priority),
      ButtonsColumn("actions", "Actions", [EditButton("Edit"), RemoveButton("Remove")]),
    ],
    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const GenericDeviceModelListContainer = HasEdition(
  HasAddition(
    HasRefresh(
      HasSearch(
        HasDeletion(
          HasStdTablePagination(GenericListContent<FW, T>()),
          "Confirm parameter deletion",
          (v: T) => `Are you sure that you want to delete this parameter ?`,
          (props) => {
            return useNestorAPI<undefined, NestorDeviceModelParameter>(
              (el) => {
                assert(el !== undefined);
                return `inventory/device_models/parameters/${el.id}`;
              },
              false,
              undefined,
              { method: "DELETE" },

              props.list.refresh
            );
          }
        ),
        {
          name: "contains",
        },
        undefined,
        undefined,
        TableSearch
      )
    ),
    DeviceModelParameterModal,
    "Add a new parameter",
    "plus",
    "primary"
  ),
  DeviceModelParameterModal
);

export const DeviceModelParameterListCard = ({ model }: { model: NestorDeviceModel }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  return (
    <div className="mt-2">
      <CardComponent header="Device model parameter list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericDeviceModelListContainer
            //cardHeader="Gateway list"
            list={useDeviceModelParameterList(model.id)}
            fwProps={useMemo(() => {
              return {
                device_model_id: model.id,
              };
            }, [model])}
            listTemplate={GenericDeviceTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
