import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PCBsMain } from "./PCB/PCBs";
import { PCBAssemblyMain } from "./PCB_Assembly/PCBAssembly";
import { SystemModelList } from "./Systems/SystemModelList";
import { TasksMain } from "./Tasks/Tasks";

export function ManufacturingMain() {
  const { url } = useRouteMatch();
  const pcbs = useMenuElement("PCB layouts", url + "/pcb");
  const pcbassembly = useMenuElement("PCB assembly tools", url + "/pcb_assembly");
  const tasks = useMenuElement("BT Tasks", url + "/tasks");
  useMenuElement("Systems", url + "/systems");

  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/pcbs`} key="pcb">
        <XemwayMenuProvider {...pcbs}>
          <PCBsMain></PCBsMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/pcb_assembly`} key="pcba">
        <XemwayMenuProvider {...pcbassembly}>
          <PCBAssemblyMain></PCBAssemblyMain>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/tasks`} key="tasks">
        <XemwayMenuProvider {...tasks}>
          <TasksMain></TasksMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/systems`} key="tasks">
        <SystemModelList />
      </Route>
      <Route>
        <Redirect to={`${url}/pcbs`} />
      </Route>
    </Switch>
  );
}
