import { PcbAssemblyInfoAndComponents } from "nestor/components/nestor/manufacturing/pcb_assembly/Detail";
import { PcbAssemblyReportCards } from "nestor/components/nestor/manufacturing/pcb_assembly/ReportCards";
import { usePcbAssemblySingle } from "nestor/hooks/api/usePcbAssembly";
import { NestorPcbAssembly } from "nestor/types/manufacturing/pcb_assembly";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

const PcbAssemblyDetailInner = ({ assembly, refresh }: { assembly: NestorPcbAssembly; refresh: Function }) => {
  const { url } = useRouteMatch<{ layout: string }>();

  // useMenuElement("Details", route.url + "/details");
  useMenuElement("Information & Components", url + "/info");
  useMenuElement("Report cards", url + "/cards");

  return (
    <Switch>
      <Route path={`${url}/info`}>
        <PcbAssemblyInfoAndComponents refresh={refresh} assembly={assembly} />
      </Route>
      <Route path={`${url}/cards`}>
        <PcbAssemblyReportCards refresh={refresh} assembly={assembly} />
      </Route>

      <Route>
        <Redirect to={`${url}/info`} />
      </Route>
    </Switch>
  );
};

export const PCBAssemblyDetail = () => {
  const route = useRouteMatch<{ assembly: string }>();
  const task = usePcbAssemblySingle(parseInt(route.params.assembly));
  const menu = useMenuElement(task.element ? `Assembly: ${task.element?.name}` : "Loading...", route.url);

  return (
    <XemwayMenuProvider {...menu}>
      {task.loadingIO}
      <div className="container-fluid p-3">
        {task.element ? <PcbAssemblyDetailInner assembly={task.element} refresh={task.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
