// Components

// Contexts
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useSkuJournal } from "nestor/hooks/api/useSkuJournal";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorContainer } from "nestor/types/stock/container";
import { NestorSku } from "nestor/types/stock/sku";
import { NestorSkuTransfer, NestorWithdrawTargetType } from "nestor/types/stock/sku_transfer";
import { CardComponent } from "@csem/shared-utils";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
//# Tables
//## Nestor
// ## Generic
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasPagination, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { DeviceRenderer } from "../../forms/fields/DeviceSearch";
import { PCBRenderer } from "../../renderers/PCBRenderer";
import { ProjectRenderer } from "../../renderers/ProjectRenderer";
import { DateColumn } from "../../tables/columns/DateColumn";
import { SkuColumn } from "nestor/components/tables/columns/SkuColumn";

type T = NestorSkuTransfer;
type FW = {};
type Mode_t = "adjust" | "withdraw" | "transfer";
type HOOK = {
  mode: Mode_t;
};

const formatter = Intl.NumberFormat("ch-FR");
const format = (val: number) => formatter.format(val);

const QtyRenderer = (props: { movement: NestorSkuTransfer; mode: Mode_t }) => {
  const unit = props.movement.sku?.article?.orderable?.dividable
    ? ` ${props.movement.sku?.article.orderable.dividable_unit}`
    : "u.";

  switch (props.mode) {
    case "transfer":
      return (
        <>
          <small>transfer</small> <strong>{props.movement.qty_change}</strong> {unit}
        </>
      );
    case "withdraw":
      return (
        <>
          <small>withdraw</small> <strong>{props.movement.qty_change}</strong> {unit}
        </>
      );

    // break;
    case "adjust":
      return (
        <>
          <small>set to</small> <strong>{props.movement.qty_change}</strong> {unit}
        </>
      );
    //     break;
  }

  return null;
};

const SourceCtnRenderer = (props: { movement: NestorSkuTransfer; mode: Mode_t }) => {
  const unit = props.movement.sku?.article?.orderable?.dividable
    ? ` ${props.movement.sku?.article.orderable.dividable_unit}`
    : "";

  switch (props.mode) {
    case "withdraw":
    case "transfer":
      return (
        <>
          <div className="fw-bold">{props.movement.container_from?.name}</div>
          <small>
            <em>
              {format(props.movement.qty_container_from + props.movement.qty_change)} {unit} -{" "}
              {format(props.movement.qty_change)} = {format(props.movement.qty_container_from)}
            </em>
          </small>
        </>
      );

    //  break;
    case "adjust":
      return (
        <>
          <div className="fw-bold">{props.movement.container_from?.name}</div>
          <small>
            <em>
              New qty: {format(props.movement.qty_container_from)} {unit}
            </em>
          </small>
        </>
      );

    //   break;
  }

  //  return null;
};

const TargetCtnRenderer = (props: { movement: NestorSkuTransfer; mode: Mode_t }) => {
  const unit = props.movement.sku?.article?.orderable?.dividable
    ? ` ${props.movement.sku?.article.orderable.dividable_unit}`
    : "";

  switch (props.mode) {
    case "transfer":
      return (
        <>
          <div className="fw-bold">{props.movement.container_to?.name}</div>
          <small>
            <em>
              {format(props.movement.qty_container_to - props.movement.qty_change)} {unit} +{" "}
              {format(props.movement.qty_change)} = {format(props.movement.qty_container_to)}
            </em>
          </small>
        </>
      );

    //     break;

    case "withdraw":
    case "adjust":
      return null;

    //        break;
  }
  //   return null;
};

const GenericJournalTable = (function () {
  return TableRenderer<T, FW, {}, HOOK, "id">({
    key: "id",
    columns: [
      DateColumn("created_at", "Movement date", "created_at", (e) => e.created_at),
      SkuColumn("sku_nuid", "SKU", "sku_id", (e) => e.sku, true),
      SimpleColumn("qty_change", "Quantity change", (e, _, hk) => <QtyRenderer movement={e} mode={hk.mode} />),
      SimpleColumn("ctn_src", "Source container", (e, _, hk) => <SourceCtnRenderer movement={e} mode={hk.mode} />),
      SimpleColumn("ctn_trgt", "Target container", (e, _, hk) => <TargetCtnRenderer movement={e} mode={hk.mode} />),
      SimpleColumn("comment", "Comment", (e) => e.comment),
      SimpleColumn("tpe", "Type", (e, fw, hk) =>
        hk.mode === "transfer"
          ? "Transer between containers"
          : hk.mode === "withdraw"
          ? "Withdrawal from a container"
          : "Quantity adjustment"
      ),

      SimpleColumn("target", "Target", (e) => {
        switch (e.withdraw_target_type) {
          case NestorWithdrawTargetType.DEVICE:
            return <DeviceRenderer device={e.withdrawal_target as NestorDevice | null} />;

            break;
          case NestorWithdrawTargetType.PCB:
            return <PCBRenderer pcb={e.withdrawal_target as NestorPcb | null} />;

            break;
          case NestorWithdrawTargetType.PROJECT:
            return <ProjectRenderer project={e.withdrawal_target as NestorProject | null} />;

            break;
        }
        return null;
      }),

      EmployeeColumn("contact", "Person responsible", "employee_id", (e) => e.employee),
    ],

    useColumnVisibility: (cols, fw) => {
      return {};
    },

    useLineParamInjection: (el, forwarded) => {
      const mode = el.container_to?.name ? "transfer" : el.is_adjustment ? "adjust" : "withdraw";

      return { mode };
    },
  });
})();

const GenericJournalListContainer = (function () {
  return HasRefresh(HasStdTablePagination(GenericListContent<FW, T>()));
})();

export const SkuJournalCard = (props: { container?: NestorContainer; sku?: NestorSku }) => {
  const filter = useMemo(() => {
    const filter = cloneDeep(DefaultStandardTableFilter);
    filter.sort = [{ field: "created_at", dir: "desc" }];

    return filter;
  }, []);

  return (
    <div className="mb-2">
      <CardComponent header="Journal">
        <StandardTableFilterCtxProvider defaultValue={filter}>
          <GenericJournalListContainer
            //cardHeader="Gateway list"
            list={useSkuJournal({ containerId: props.container?.id, skuId: props.sku?.id })}
            fwProps={{}}
            listTemplate={GenericJournalTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
