import { CardComponent } from "@csem/shared-utils";
import { EmployeeRenderer } from "nestor/components/renderers/EmployeeInfos";
import { RoomRenderer } from "nestor/components/renderers/RoomRenderer";
import { useLaboratorySingle } from "nestor/hooks/api/useLaboratoryList";
import { NestorLaboratory, NestorLaboratoryId } from "nestor/types/company/laboratory";

export const LaboratoryOneLineWithFetch: React.FunctionComponent<{ labid: NestorLaboratoryId }> = (props) => {
  const laboratory = useLaboratorySingle(props.labid);
  if (!laboratory.element) {
    return null;
  }

  return <LaboratoryOneLine laboratory={laboratory.element} refresh={laboratory.refresh} />;
};

export const LaboratoryOneLine: React.FunctionComponent<{
  laboratory: NestorLaboratory;
  refresh: Function;
}> = ({ laboratory, refresh }) => {
  return (
    <CardComponent header="Laboratory you're looking at" additionalClasses={"mb-2"}>
      <div className="row">
        <div className="col-xl-1 col-md-4 col-6 align-self-center  mb-2">{laboratory.name}</div>

        <div className="col-xl-1  col-md-4 col-6 mb-2">
          <strong>Location:</strong>
          <br />
          <RoomRenderer room={laboratory.room} />
        </div>

        <div className="col-xl-1  col-md-4 col-6  mb-2">
          <strong>Manager:</strong>
          <br />
          <EmployeeRenderer employee={laboratory.manager1} />
        </div>

        <div className="col-xl-1  col-md-4 col-6  mb-2">
          <strong>2nd Manager:</strong>
          <br />
          <EmployeeRenderer employee={laboratory.manager2} />
        </div>
      </div>
    </CardComponent>
  );
};
