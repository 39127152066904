import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssembly } from "nestor/types/manufacturing/pcb_assembly";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormTextareaField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { usePcbLayoutSearchField } from "../../../../forms/fields/PcbLayoutSearch";

export const usePcbAssemblyBasicFields = (el?: NestorPcbAssembly) => {
  const name = useFormTextField({
    label: "Name",
    originalValue: el?.name,
    type: FieldValueType.STRING,
    defaultValue: "",
    validationOnChange: useFieldOptRequired<string>(true),
  });

  const pcb_layout_id = usePcbLayoutSearchField({
    label: "PCB layout",
    originalValue: el?.pcb_layout_id,
    validationOnChange: useFieldOptRequired(true),
    defaultValue: undefined,
  });

  const variant = useFormTextField({
    label: "Variant",
    originalValue: el?.variant,
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const comment = useFormTextareaField({
    label: "Comment",
    originalValue: el?.comment,
    defaultValue: "",
  });

  return { name, pcb_layout_id, variant, comment };
};

export const PCBAssemblyQuickAddEditModal = StandardFormAddEditModalFactory<NestorPcbAssembly, {}, any>({
  title: (el, fw) => (el ? `Edit the assembly ${el.name} ` : `Add a new assembly`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const { name, pcb_layout_id, variant, comment } = usePcbAssemblyBasicFields(el);

    const apiResult = useNestorAPI(
      !el ? NestorServerRoutes.pcb_assembly_create() : NestorServerRoutes.pcb_assembly_update(el.id),
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        pcb_layout_id: pcb_layout_id.value,
        variant: variant.value,
        comment: comment.value,
      });
    };

    return {
      fields: {
        name,
        pcb_layout_id,
        variant,
        comment,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
