import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSupplierId } from "nestor/types/purchasing/supplier";
import { useModalOfT } from "@csem/shared-utils";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { FeedbackStars } from "../orders/feedbacks/modals/FeedbackAddEdit";
import { ModalFeedbackList } from "../orders/feedbacks/modals/FeedbackList";
import { NestorOrderFeedbackAvg } from "nestor/types/purchasing/order_feedback";

type T = Array<NestorOrderFeedbackAvg>;

export const SupplierFeedbackGrade = ({ supplier_id }: { supplier_id: NestorSupplierId }) => {
  const api = useNestorAPI<T>(NestorServerRoutes.supplier_feedback_average(supplier_id), true);
  const { Modal: FeedbackModal, ...feedbacks } = useModalOfT(ModalFeedbackList);

  const tooltop = api.result ? (
    <Popover>
      <Popover.Header>Score card</Popover.Header>
      <Popover.Body>
        <button
          className="btn btn-info mb-2 "
          type="button"
          disabled={!supplier_id}
          onClick={() => feedbacks.showModal(supplier_id, {})}
        >
          Show all evaluations
        </button>

        {api.result.map((el) => (
          <div>
            {el.name}
            <FeedbackStars grade={el.avg} />
          </div>
        ))}
      </Popover.Body>
    </Popover>
  ) : (
    <></>
  );

  let average: string = "";
  let weight = 0;
  if (api.result) {
    let total = 0;

    api.result.forEach((v) => {
      total += v.weight * parseFloat(v.avg);
      weight += v.weight;
    });

    average = `${(total / weight).toFixed(1)}/5`;
  }

  return api.result && weight > 0 ? (
    <>
      {FeedbackModal}

      <OverlayTrigger placement="right" trigger="focus" overlay={tooltop}>
        <button type="button" className="btn btn-info h-100">
          <span>
            <em className="bi bi-star"></em>&nbsp;
            {average}
          </span>
        </button>
      </OverlayTrigger>
    </>
  ) : null;
};
