import { postJSON, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { NestorCalibration } from "nestor/types/inventory/calibration";
import { NestorDevice } from "nestor/types/inventory/device";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import NestorServerRoutes from "nestor/server_routes";

export const CalibrationRevokeModal = StandardFormAddEditModalFactory<NestorCalibration, { device: NestorDevice }, any>(
  {
    title: (el) => (el ? `Revoke the calibration` : ``),
    buttonValidate: (el) => "Revoke",
    modalSize: "md",
    useDefinition: (el, fw) => {
      const revoked_comment = useFormTextField({
        label: "Why are you revoking this calibration ?",
        defaultValue: "",
        type: FieldValueType.STRING,
        validationOnChange: {
          required: true,
        },
      });

      const apiResult = useNestorAPIForms(
        !el ? undefined : NestorServerRoutes.inventory.devices.calibrations.forms.revoke(el.id),
        false,
        undefined,
        postJSON
      );

      // TODO: This is re-evaluated at every render. Everytime a field changes value.
      // Very sub-optimal

      const cb = () => {
        const content: any = {
          revoked_comment: revoked_comment.value,
        };

        apiResult.doQuery(undefined, content);
      };

      return {
        fields: {
          revoked_comment,
        },
        errors: apiResult.error,
        state: apiResult.state,
        out: apiResult.result,
        submit: cb,
      };
    },
  }
);
