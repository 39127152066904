import { ProjectRenderer } from "nestor/components/renderers/ProjectRenderer";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { CardComponent } from "@csem/shared-utils";

export const PCBLayoutCard = ({ layout }: { layout: NestorPcbLayout }) => {
  return (
    <CardComponent header="PCB information" additionalClasses="h-100">
      <div className="row align-items-center mb-3">
        <div className="col-4">NUID</div>
        <div className="col-8">
          <h4>{layout.nuid}</h4>
        </div>
      </div>

      {layout.name ? (
        <div className="row align-items-center mb-3">
          <div className="col-4">Name</div>
          <div className="col-8">{layout.name}</div>
        </div>
      ) : null}

      <div className="row align-items-center mb-3">
        <div className="col-4">Project</div>
        <div className="col-8">
          <ProjectRenderer project={layout.project || null} fallbackText="No PCB information" />
        </div>
      </div>
      {layout.identificator ? (
        <div className="row align-items-center mb-3">
          <div className="col-4">Nestor 1 ID</div>
          <div className="col-8">{layout.identificator}</div>
        </div>
      ) : null}

      {layout.path ? (
        <div className="row align-items-center mb-3">
          <div className="col-4">Server path</div>
          <div className="col-8">{layout.path}</div>
        </div>
      ) : null}
    </CardComponent>
  );
};
