import { TaskProgressListCard } from "nestor/components/nestor/manufacturing/tasks/ProgressList";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { useTaskSingle } from "nestor/hooks/api/useTaskSingle";
import { NestorTask } from "nestor/types/manufacturing/task";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { useRouteMatch } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { TaskEdit } from "./TaskEdit";
import { TaskInfo } from "./TaskInfo";

const TaskDetailInner = (props: { task: NestorTask; refresh: Function }) => {
  const { url } = useRouteMatch<{ layout: string }>();

  // useMenuElement("Details", route.url + "/details");
  useMenuElement("Information", url + "/info");
  useMenuElement("Edit", url + "/edit");
  useMenuElement("Progress", url + "/progress");
  useMenuElement("Documents", url + "/documents");
  // useMenuElement("Conversation", url + "/conversation");

  return (
    <Switch>
      <Route path={`${url}/info`}>
        <TaskInfo refresh={props.refresh} task={props.task} />
      </Route>
      <Route path={`${url}/edit`}>
        <TaskEdit task={props.task} />
      </Route>

      <Route path={`${url}/progress`}>
        <TaskProgressListCard task={props.task} />
      </Route>
      <Route path={`${url}/documents`}>
        <DocumentListCard type="task" id={props.task.id}></DocumentListCard>
      </Route>
      <Route path={`${url}/conversation`}></Route>
      <Route>
        <Redirect to={`${url}/info`} />
      </Route>
    </Switch>
  );
};

export const TaskDetails = () => {
  const route = useRouteMatch<{ task: string }>();
  const task = useTaskSingle(route.params.task);
  const menu = useMenuElement(task.element ? `Task: ${task.element?.nuid}` : "Loading...", route.url);

  return (
    <XemwayMenuProvider {...menu}>
      {task.loadingIO}
      <div className="container-fluid p-3">
        {task.element ? <TaskDetailInner task={task.element} refresh={task.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
