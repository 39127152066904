import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorAddress } from "nestor/types/company/address";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";

export const useAddressListField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorAddress, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: `company/addresses/all`,
    keyMap: (el) => el.id,
    valMap: (el) => el.name + " " + el.address + " " + (el.address2 ? el.address2 : ""),

    getterFromKey: (k, l) => l?.find((_l) => _l.id == k),
  });
};
