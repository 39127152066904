import { useEffect, useState } from "react";

export function useLatch<T>(el: T | undefined, reset: boolean) {
  const [s, setS] = useState<T | undefined>(el);
  useEffect(() => {
    if (reset) {
      setS(undefined);
    } else if (el !== undefined) {
      setS(el);
    }
  }, [el, reset]);

  return s;
}
