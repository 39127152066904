import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { useSnapshotList } from "nestor/hooks/api/useSnapshotList";
import { NestorSnapshot } from "nestor/types/system/snapshot";
import { CardComponent } from "@csem/shared-utils";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorSnapshot;
type FW = {};
type FWLst = FW;
type HOOK = {};

const GenericSnapshotTable = (function () {
  return TableRenderer<T, FW, {}, HOOK, "id">({
    key: "id",
    columns: [
      SimpleColumn("comment", "Comment", (e) => e.comment),
      SimpleColumn("digest", "Digest", (e) => e.digest),
      EmployeeColumn<T>("employee", "Employee", "created_by", (e) => e.employee),
      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
    ],

    useLineParamInjection: () => {
      return {};
    },

    useColumnWidth: () => {
      return {
        download: "0px",
        actions: "0px",
      };
    },
  });
})();

const GenericDocumentListContainer = (function () {
  return HasStdTablePagination(HasRefresh(GenericListContent<FWLst, T>()));
})();

export const SnapshotListCard = ({
  type,
  id,
  title = "Snapshot list",
}: {
  type: string;
  id: string | number;
  title?: string;
}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);

  return (
    <div className="mb-2">
      <CardComponent header={title}>
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericDocumentListContainer
            //cardHeader="Gateway list"
            list={useSnapshotList(type, id)}
            fwProps={{ type, id }}
            listTemplate={GenericSnapshotTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
