import NestorFrontendRoutes from "nestor/frontend_routes";
import { NestorOrderable } from "nestor/types/purchasing/article";
import { NestorPart } from "nestor/types/stock/part";
import { NestorSku } from "nestor/types/stock/sku";
import { Link } from "react-router-dom";

export const SkuRenderer = ({
  sku,
  fallbackText,
  withNav,
}: {
  sku?: NestorSku;
  fallbackText?: string;
  withNav?: boolean;
}) => {
  if (!sku) {
    return <div className="text-muted">{fallbackText || null}</div>;
  }

  return (
    <>
      <div className="fw-bold">
        <em className="bi bi-cpu"></em>{" "}
        {withNav ? <Link to={NestorFrontendRoutes.sku_details(sku.id)}>{sku.nuid}</Link> : sku.nuid}
      </div>
      <div>
        {sku.article?.description ||
          (sku.article?.orderable_type === NestorOrderable.PART
            ? (sku.article?.orderable as NestorPart | undefined)?.description
            : "")}
      </div>

      <div className="d-flex  text-muted">
        {sku.employee ? (
          <div>
            <em className="bi bi-person"></em> {sku.employee.initials}
          </div>
        ) : null}
      </div>
    </>
  );
};
