import { NestorSelectContextProvider } from "nestor/contexts/NestorSelectContext";
import { NestorLocationFilter } from "nestor/types/_internal/locationFilter";
import { CardComponent } from "@csem/shared-utils";
import { useForm } from "@csem/forms";
import React, { useState } from "react";
import { useLocationFields } from "../../forms/fields/LocationField";
import { DeviceListCard } from "../inventory/devices/List";
import { PCBListCard } from "../manufacturing/pcb/layouts/PCBList";
import { ContainerListCard } from "../stock/containers/List";
import { TransferCard } from "../util/TransferCard";

const _empty = {};

const LocationForm = ({
  setLocationFilter,
}: {
  setLocationFilter: React.Dispatch<React.SetStateAction<NestorLocationFilter | undefined>>;
}) => {
  const location_f = useLocationFields(undefined, true);

  const form = useForm({
    fields: {
      ...location_f.fields,
    },
    fw: _empty,
    onSubmit: () => {
      switch (location_f.type) {
        case "container":
          setLocationFilter({ container_id: location_f.container_id.value });
          break;
        case "room":
          setLocationFilter({ room_id: location_f.room_id.value });

          break;
        case "laboratory":
          setLocationFilter({ laboratory_id: location_f.laboratory_id.value });

          break;
        case "other":
          setLocationFilter({ location_information: location_f.location_other.value });

          break;
      }
    },
  });

  return (
    <CardComponent header="Select location to transfer elements from" additionalClasses="mb-2">
      {location_f.SwitchButtons}
      {location_f.Field}
      <button className="w-100 mb-2 btn btn-primary" type="button" onClick={form.submit}>
        Search items
      </button>
    </CardComponent>
  );
};
export const LocationMigration = () => {
  const [locFilter, setLocFilter] = useState<NestorLocationFilter>();

  return (
    <NestorSelectContextProvider>
      <LocationForm setLocationFilter={setLocFilter} />
      {locFilter ? (
        <>
          <TransferCard />
          <DeviceListCard usePersistance={false} {...locFilter} />
          <PCBListCard {...locFilter} />
          <ContainerListCard {...locFilter} />
        </>
      ) : null}
    </NestorSelectContextProvider>
  );
};
