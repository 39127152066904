import { useDeviceSearchField } from "nestor/components/forms/fields/DeviceSearch";
import { usePcbSearchField } from "nestor/components/forms/fields/PcbSearch";
import { ManufacturerRenderer } from "nestor/components/renderers/Manufacturer";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import NestorFrontendRoutes from "nestor/frontend_routes";
import { useDeviceAssociationList } from "nestor/hooks/api/useDeviceAssociationList";
import { postJSON, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceAssociation } from "nestor/types/inventory/device_association";
import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorArticle } from "nestor/types/purchasing/article";
import { NestorPart } from "nestor/types/stock/part";
import { NestorSku } from "nestor/types/stock/sku";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, NavigationLookup, SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasDeletion, HasRefresh, RemoveMethod } from "@csem/lists";
import React, { useState } from "react";

/**
 * Shows devices associated to the order
 */

type T = NestorDeviceAssociation;
type FWLst = { device: NestorDevice };

type FW = RemoveMethod<T, FWLst>;
type HOOK = {};

const useFormAssociation = (e: NestorDeviceAssociation | undefined, device: NestorDevice) => {
  // Is this element linking a child PCB, or a child Device ?
  const [target, setTarget] = useState<"pcb" | "equipment">("equipment");

  const assoc_device_id = useDeviceSearchField({
    label: "Device",
    defaultValue: undefined,
    originalValue: e?.associated_type === "equipment" ? e.associated_id : undefined,
    validationOnChange: useFieldOptRequired(target === "equipment"),
  });

  const assoc_pcb_id = usePcbSearchField({
    label: "PCB",
    defaultValue: undefined,
    originalValue: e?.associated_type === "pcb" ? e?.associated_id : undefined,
    validationOnChange: useFieldOptRequired(target === "pcb"),
  });

  const name = useFormTextField({
    label: "What are you associating ?",
    originalValue: e?.name,
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const comment = useFormTextField({
    label: "A comment ?",
    originalValue: e?.comment,
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const apiAssociation = useNestorAPI(
    NestorServerRoutes.device_association_create(device.id),
    false,
    undefined,
    postJSON
  );

  const form = useForm({
    onSubmit: async () => {
      // ... then, create a new one
      let out = {
        target: target,
        associated_type: target,
        device_id: assoc_device_id.value,
        pcb_id: assoc_pcb_id.value,
        name: name.value,
        comment: comment.value,
      };

      apiAssociation.doQuery(undefined, out);
    },

    fields: {
      device_id: assoc_device_id,
      pcb_id: assoc_pcb_id,
      name,
      comment,
    },
    Template: FormAssociation,
    fw: {
      target,
      setTarget,
    },
  });

  const Content = form.Content;
  return {
    Content: Content,
    out: apiAssociation.result,
    submit: () => {
      form.submit();
    },
    state: apiAssociation.state,
  };
};

type FieldNames = "name" | "comment" | "device_id" | "pcb_id";

const FormAssociation: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  target: "pcb" | "equipment";
  setTarget: React.Dispatch<React.SetStateAction<"pcb" | "equipment">>;
}> = (props) => {
  return (
    <>
      <div className="row align-items-center mb-3">
        <div className="col-4 mb2">What would you like to associate ?</div>
        <div className="col-8 mb2">
          <button
            type="button"
            className={`btn btn-lg  ${props.target === "equipment" ? "btn-primary active" : "btn-secondary"} `}
            onClick={(e) => props.setTarget("equipment")}
          >
            An inventorized item
          </button>

          <button
            type="button"
            className={`btn btn-lg btn-primary ${props.target === "pcb" ? "btn-primary active" : "btn-secondary"} `}
            onClick={(e) => props.setTarget("pcb")}
          >
            A PCB
          </button>
        </div>
      </div>

      {props.target === "pcb" && props.fields.pcb_id.Content}
      {props.target === "equipment" && props.fields.device_id.Content}
      {props.fields.name.Content}

      {props.fields.comment.Content}
    </>
  );
};

export const AddAssociationModal = StandardFormAddEditModalWithFormFactory<
  NestorDeviceAssociation,
  { device: NestorDevice },
  any
>({
  title: (e, fw) => `Add an association for device ${fw.device.nuid}`,
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    return useFormAssociation(el, fw?.device);
  },
});

const Table = (function () {
  return TableRenderer<T, FWLst, FW, HOOK, "id">({
    key: "id",
    columns: [
      SimpleColumn("_nav", "", (e) => {
        if (e.associated_type === "pcb") {
          return null;
        } else if (e.associated_type === "equipment") {
          return <NavigationLookup path={NestorFrontendRoutes.device_details(e.associated_id)} />;
        } else if (e.associated_type === "sku") {
          return <NavigationLookup path={NestorFrontendRoutes.sku_details(e.associated_id)} />;
        } else {
          return null;
        }
      }),

      SimpleColumn("type", "Type", (e) => {
        if (e.associated_type === "pcb") {
          return <div className="badge bg-info">PCB</div>;
        } else if (e.associated_type === "equipment") {
          return <div className="badge bg-success">inventory item</div>;
        } else if (e.associated_type === "sku") {
          return <div className="badge bg-dark">stock item</div>;
        } else {
          return <div className="badge bg-danger">unknown</div>;
        }
      }),
      EmployeeColumn("employee", "Employee", undefined, (e) => {
        if (e.associated_type === "pcb") {
          return (e.associated as NestorPcb | null)?.employee;
        } else if (e.associated_type === "equipment") {
          return (e.associated as NestorDevice | null)?.owner;
        } else if (e.associated_type === "sku") {
          return (e.associated as NestorSku | null)?.employee;
        }
      }),

      SimpleColumn("name", "Name", (e) => {
        if (e.associated_type === "pcb") {
          return (e.associated as NestorPcb | null)?.layout?.name;
        } else if (e.associated_type === "equipment") {
          return (e.associated as NestorDevice | null)?.model?.model;
        } else if (e.associated_type === "sku") {
          return ((e.associated as NestorSku | null)?.article?.orderable as NestorPart | null)?.description;
        }
      }),

      SimpleColumn("manufacturer", "Manufacturer", (e) => {
        if (e.associated_type === "pcb") {
          return null;
        } else if (e.associated_type === "equipment") {
          if (!e.associated) {
            return null;
          }
          return (
            <ManufacturerRenderer
              manufacturer={(e.associated as NestorDevice).model?.manufacturer}
              id={(e.associated as NestorDevice).model?.model}
            />
          );
        } else if (e.associated_type === "sku") {
          if (!e.associated) {
            return null;
          }
          return (
            <ManufacturerRenderer
              manufacturer={((e.associated as NestorSku).article?.orderable as NestorPart).manufacturer}
              id={((e.associated as NestorSku).article?.orderable as NestorPart).manufacturer_identification}
            />
          );
        }
      }),

      ButtonsColumn("actions", "", [
        {
          title: "Revoke",
          theme: "danger",
          visibility: (e, fw) => {
            return e.associated_type !== "sku";
          },
          cb: (e, fw) => {
            fw.remove(e, fw);
          },
        },
      ]),
    ],

    useColumnWidth: () => {
      return { actions: "150px", _nav: "0px" };
    },

    useColumnVisibility: () => {
      return {};
    },

    useRowVisibility: (el) => {
      if (!el.associated) {
        return false;
      }

      return true;
    },

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const ListContainer = HasAddition(
  HasRefresh(
    HasDeletion(
      HasStdTablePagination(GenericListContent<FWLst, T>()),
      "Confirm association revocation",
      (v: T) => `Are you sure to revoke this association ?`,
      (props) => {
        return useNestorAPI<undefined, NestorArticle>(
          (assoc) => {
            assert(assoc !== undefined);
            return NestorServerRoutes.inventory.devices.associations.delete(props.fwProps.device.id, assoc.id);
          },
          false,
          new URLSearchParams("id"),
          { method: "DELETE" },
          props.list.refresh
        );
      }
    )
  ),
  AddAssociationModal,
  "New association",
  "plus",
  "success"
);

export const DeviceAssociationList: React.FunctionComponent<{
  device: NestorDevice;
}> = (props) => {
  return (
    <div className="mb-3">
      <CardComponent header={"Associations for device " + props.device.nuid}>
        <div className="text-info csem-info col-4">
          Below you can see all items associated to the item you are currently viewing. This association makes your
          current item the "parent" and the associated items in the list the "children". Please note that a "child"
          (i.e., any item) can be associated to multiple "parents" (i.e., any other item).
        </div>
        <StandardTableFilterCtxProvider>
          <ListContainer
            fwProps={{ device: props.device }}
            listTemplate={Table}
            list={useDeviceAssociationList(props.device.id)}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
