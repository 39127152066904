import { TextFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn, SortableColumn } from "@csem/lists";

export const NUIDColumn = SortableColumn(
  FilterableColumn(
    SimpleColumn("nuid", "NUID", (e: { nuid: string }) => e.nuid),
    TextFilterFactory({ label: "NUID" }),
    "nuid"
  )
);
