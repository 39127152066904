import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { CardComponent, useConfirmationModal } from "@csem/shared-utils";
import sanitizeHtml from "sanitize-html";
import settings from "settings";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useCallback } from "react";

export const DeviceModelInfos: React.FunctionComponent<{
  model: NestorDeviceModel;
  refresh: Function;
}> = ({ model, refresh }) => {
  const apiDeletePhoto = useNestorAPI<any, NestorDeviceModel>(
    `inventory/device_models/${model.id}/photo`,
    false,
    undefined,
    {
      method: "DELETE",
    }
  );
  const apiDeletePhotoDoQuery = apiDeletePhoto.doQuery;
  const invalidate = useConfirmationModal<NestorDeviceModel>(
    "Are you sure ?",
    (param) => {
      return "Please confirm that you want to delete this photo";
    },
    useCallback(() => {
      apiDeletePhotoDoQuery().then(() => {
        refresh();
      });
    }, [apiDeletePhotoDoQuery]),
    "Could not delete the photo",
    apiDeletePhoto
  );

  return (
    <>
      {invalidate.Modal}
      <div className="row">
        <div className="col-md-6  mb-2">
          <CardComponent header="General information">
            {/*  <div className="row align-items-middle mb-3">
                            <div className="col-4">Nestor NUID</div>
                            <div className="col-4">
                                <h5> {device.nuid}</h5>
                            </div>

    </div>*/}
            <div className="row align-items-middle mb-2">
              <div className="col-4">Manufacturer</div>
              <div className="col-8">
                <h5> {model.manufacturer?.name}</h5>
              </div>
            </div>
            <div className="row align-items-middle mb-2">
              <div className="col-4">Model</div>
              <div className="col-8">
                <h5> {model.model}</h5>
              </div>
            </div>
            <div className="row align-items-middle mb-2">
              <div className="col-4">Type</div>
              <div className="col-8">
                <h6> {model?.type?.name}</h6>
              </div>
            </div>
            <div className="row align-items-middle mb-3">
              <div className="col-4">Description</div>
              <div className="col-8">
                <h6> {model.description}</h6>
              </div>
            </div>
            <div className="row align-items-middle mb-2">
              <div className="col-4">Characteristics</div>
              <div className="col-8">
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(model.characteristic) }} />
              </div>
            </div>
          </CardComponent>
        </div>
        <div className="col-md-6  mb-2">
          <CardComponent header="Image">
            {model?.picture ? (
              <img alt={model.model} style={{ maxWidth: "100%" }} src={settings.NESTOR_URL + model.picture.blob?.url} />
            ) : null}

            <div className="d-flex justify-content-center gap-3 mt-4">
              {model.picture ? (
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    invalidate.invoke(model);
                  }}
                >
                  <em className="bi bi-x"></em>
                  &nbsp;Delete photo
                </button>
              ) : null}
            </div>
          </CardComponent>
        </div>
      </div>
    </>
  );
};
