import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorManufacturer } from "nestor/types/purchasing/manufacturer";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";

/*
(props: {
    callback: (out: any) => void;
    el?: NestorDeviceModel;
}) => {
    const Tpl =
    */
export const ManufacturerModal = StandardFormAddEditModalFactory<NestorManufacturer, {}, any>({
  title: (el) => (!el ? "Add a new manufacturer" : `Edit  ${el.name}`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el) => {
    const name = useFormTextField({
      label: "Name",
      type: FieldValueType.STRING,
      defaultValue: "",
      originalValue: el?.name,
      validationOnChange: { required: true },
    });

    const apiResult = useNestorAPI(
      !el ? `company/manufacturers/forms/create` : `company/manufacturers/${el.id}/forms/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
      });
    };

    return {
      fields: {
        name,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
