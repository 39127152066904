import { useBuildingList } from "nestor/hooks/api/useBuildingList";
import { useLaboratoryList } from "nestor/hooks/api/useLaboratoryList";
import { NestorBuilding } from "nestor/types/company/building";
import { NestorLaboratory } from "nestor/types/company/laboratory";
import { NestorLocation } from "nestor/types/system/location";
import { GenericListRenderer } from "@csem/forms";
import { FilterProps } from "@csem/lists";
import { SearchElFilterFactory } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import React, { useState } from "react";
import { SearchByRoom } from "../../forms/fields/RoomSearch";
import { BuildingRenderer } from "../../renderers/BuildingRenderer";
import { LaboratoryRenderer } from "../../renderers/LaboratoryRenderer";
import { LocationRenderer } from "../../renderers/LocationRenderer";
import { FilterByContainer } from "./ContainerColumn";

const SearchByRoomDom = SearchByRoom();

export const SearchByLaboratory = SearchElFilterFactory<NestorLaboratory, NestorLaboratory["id"]>({
  label: "Laboratory",
  apiListFactory: useLaboratoryList,
  traverser: (el) => el.id,
  filterKeys: {
    name: "contains",
  },
  key: "id",

  filterFieldKey: "laboratory",
  template: GenericListRenderer((e) => <LaboratoryRenderer laboratory={e} />),
});

export const SearchByBuilding = SearchElFilterFactory<NestorBuilding, NestorBuilding["id"]>({
  label: "Building",
  apiListFactory: useBuildingList,
  traverser: (el) => el.id,
  filterKeys: {
    name: "contains",
  },
  key: "id",

  filterFieldKey: "building",
  template: GenericListRenderer((e) => <BuildingRenderer building={e} />),
});

const SearchByContainer = FilterByContainer;

export const SearchByOther = TextFilterFactory({
  label: "Other location",
});

export const LocationColumn = <T,>(
  name: string,
  label: string,
  renderer: (el: T) => NestorLocation | undefined | null,
  container: boolean = true,
  room: boolean = true,
  laboratory: boolean = true,
  building: boolean = true
) => {
  const wrapper = function Filter(props: FilterProps<string | number>) {
    const [type, setType] = useState<"room" | "laboratory" | "building" | "container" | "other">("room");

    const ContainerButton = container ? (
      <button
        className={`btn btn-${type === "container" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("container");
        }}
      >
        <em className="bi bi-stack"></em> Container
      </button>
    ) : null;

    const LaboratoryButton = laboratory ? (
      <button
        className={`btn btn-${type === "laboratory" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("laboratory");
        }}
      >
        <em className="bi bi-radioactive"></em> Laboratory
      </button>
    ) : null;

    const RoomButton = room ? (
      <button
        className={`btn btn-${type === "room" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("room");
        }}
      >
        <em className="bi bi-door-open"></em> Room
      </button>
    ) : null;

    const BuildingButton = building ? (
      <button
        className={`btn btn-${type === "building" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("building");
        }}
      >
        <em className="bi bi-building"></em> Building
      </button>
    ) : null;

    const OtherButton = building ? (
      <button
        className={`btn btn-${type === "other" ? "primary active" : "secondary"}`}
        onClick={(e) => {
          e.stopPropagation();
          setType("other");
        }}
      >
        <em className="bi bi-question"></em> Other
      </button>
    ) : null;

    return (
      <>
        <div className="btn-group">
          {ContainerButton}
          {LaboratoryButton}
          {RoomButton}
          {BuildingButton}
          {OtherButton}
        </div>
        {type === "room" ? (
          <SearchByRoomDom
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "laboratory");
              props.onChange(undefined, "contains", "building");
              props.onChange(undefined, "contains", "container");
              props.onChange(undefined, "contains", "other");

              props.onChange(v, o, "room");
            }}
          />
        ) : type === "laboratory" ? (
          <SearchByLaboratory
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "room");
              props.onChange(undefined, "contains", "building");
              props.onChange(undefined, "contains", "container");
              props.onChange(undefined, "contains", "other");

              props.onChange(v, o, "laboratory");
            }}
          />
        ) : type === "building" ? (
          <SearchByBuilding
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "room");
              props.onChange(undefined, "contains", "laboratory");
              props.onChange(undefined, "contains", "container");
              props.onChange(undefined, "contains", "other");

              props.onChange(v, o, "building");
            }}
          />
        ) : type === "other" ? (
          <SearchByOther
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "room");
              props.onChange(undefined, "contains", "laboratory");
              props.onChange(undefined, "contains", "container");
              props.onChange(undefined, "contains", "building");

              props.onChange(v, o, "other");
            }}
          />
        ) : (
          <SearchByContainer
            onChange={(v, o) => {
              props.onChange(undefined, "contains", "room");
              props.onChange(undefined, "contains", "laboratory");
              props.onChange(undefined, "contains", "building");
              props.onChange(undefined, "contains", "other");

              props.onChange(v, o, "container");
            }}
          />
        )}
      </>
    );
  };

  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <LocationRenderer location={renderer(el) || undefined} />;
    }),

    wrapper,
    {
      room: ["location.room_id", "location.laboratory.room_id"],
      laboratory: ["location.laboratory_id"],
      building: [
        "#location.building_id",
        "location.room.floor.building_id",
        "location.laboratory.room.floor.building_id",
      ],
      container: ["location.container_id"],
      other: ["location.information"],
    }
  );
};
