import NestorFrontendRoutes from "nestor/frontend_routes";
import { useDeviceList } from "nestor/hooks/api/useDeviceList";
import { NestorDevice } from "nestor/types/inventory/device";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { Link } from "react-router-dom";

type T = NestorDevice;

export const DeviceRenderer = ({ device, withNav }: { device?: T | null; withNav?: boolean }) => {
  if (device === null || device === undefined) {
    return null;
  }
  return (
    <>
      <div className="fw-bold">
        <em className="bi bi-headphones"></em>{" "}
        {withNav ? <Link to={NestorFrontendRoutes.device_details(device.id)}>{device.nuid}</Link> : device.nuid}
      </div>
      <strong>{device.model?.manufacturer?.name} </strong> Model: {device.model?.model}
      <br />
      <div className="d-flex  text-muted">
        {device.owner ? (
          <div>
            <em className="bi bi-person"></em> {device.owner.initials}
          </div>
        ) : null}
      </div>
      {device.serialnumber && device.serialnumber.length > 0 ? (
        <>
          <span className="text-muted">Serial: {device.serialnumber}</span>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export const useDeviceSearchField = FormSearchHookFactory<T, NestorDevice["id"]>({
  apiListFactory: useDeviceList,

  template: GenericListRenderer((el) => <DeviceRenderer device={el} />),
  filterKeys: {
    nuid: "contains",
    "model.model": "contains",
    serialnumber: "contains",
    "owner.initials": "eq",
    "owner.fullname": "contains",
    "model.manufacturer.name": "contains",
  },
  traverser_display: (el) => {
    return el.nuid + " (" + el.model?.model + ")";
  },
  uniqueKeyField: "id",
});
