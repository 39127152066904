import { useSupplierList } from "nestor/hooks/api/useSupplierList";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { SupplierRenderer } from "../../renderers/SupplierRenderer";
import {
  NestorSupplierQualification,
  NestorSupplierQualificationId,
} from "nestor/types/purchasing/supplier_qualification";
import moment from "moment";
import { useSupplierQualificationList } from "nestor/hooks/api/useSupplierQualification";

export const SupplierQualificationRenderer = ({ qualification }: { qualification: NestorSupplierQualification }) => {
  return (
    <>
      {qualification.scope}
      <br />
      Approver: {qualification.approver?.fullname} <br />
      Valid until: {moment(qualification.expires_at).format("YYYY-MM-DD")}
    </>
  );
};

export const useSupplierQualificationSearchField = FormSearchHookFactory<
  NestorSupplierQualification,
  NestorSupplierQualificationId
>({
  template: GenericListRenderer((el) => {
    return <SupplierQualificationRenderer qualification={el} />;
  }),
  filterKeys: {
    id: "eq",
    name: "contains",
    nuid: "contains",
  },
  traverser_display: (el) => el.scope,
  uniqueKeyField: "id",
});
