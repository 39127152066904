import { useFieldSignees } from "nestor/components/forms/bundles/Signees";
import { useAddressListField } from "nestor/components/forms/fields/AddressList";
import { useBackofficeField } from "nestor/components/forms/fields/BackofficeList";
import { useCostElementAliasesField, useCostElementsField } from "nestor/components/forms/fields/CostElementList";
import { useCurrencyField } from "nestor/components/forms/fields/CurrencyList";
import { useEmployeeSearchField, useSecretaryEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { useTransferringHandlerField } from "nestor/components/forms/fields/OrderHandler";
import { useFieldPaymentMode } from "nestor/components/forms/fields/PaymentMode";
import { useOpenedProjectSearchField } from "nestor/components/forms/fields/ProjectSearch";
import { useSectionField } from "nestor/components/forms/fields/SectorList";
import { useSupplierSearchField } from "nestor/components/forms/fields/SupplierSearch";
import { useWorkpackageField } from "nestor/components/forms/fields/WorkpackageList";
import { SupplierQuickAddEditModal } from "nestor/components/nestor/purchasing/suppliers/Modals/Add";
import { useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { NestorPOST, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { useAmIElevated, useMyId } from "nestor/hooks/me";
import { lang } from "nestor/lang";
import { NestorAddress } from "nestor/types/company/address";
import { NestorSectorId } from "nestor/types/company/sector";
import { NestorProjectId } from "nestor/types/projects/projects";
import { NestorProjectWorkpackageId } from "nestor/types/projects/workpackage";
import { NestorCostElementId } from "nestor/types/purchasing/cost_element";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import { useFormCheckboxField } from "@csem/forms";
import { useFormDateField } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { useFormTextareaField } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useMonetaryField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import url from "url";
import { PopoverConversation } from "../../util/PopoverConversation";
import { SupplierFeedbackGrade } from "../suppliers/FeedbackGrade";
import { SupplierQualificationButton } from "../suppliers/QualificationsButton";

export type t_Imputation = "project" | "sector";

type FieldNames =
  | "project_id"
  | "project_workpackage_id"
  | "cost_element_id"
  | "cost_element_alias"
  | "currency_id"
  | "shipping_cost"
  | "shipping_cost_comment"
  | "other_cost"
  | "other_cost_comment"
  | "discount"
  | "discount_comment"
  | "employee_id"
  | "signee_left_id"
  | "signee_right_id"
  | "signee_left_po_id"
  | "signee_right_po_id"
  | "secretary_id"
  | "sap_id"
  | "ship_at"
  | "is_administrative"
  | "grouped_order"
  | "has_chemicals"
  | "urgent"
  | "restricted"
  | "transferring"
  | "payment_mode"
  | "sector_id"
  | "supplier_id"
  | "ship_to"
  | "bill_to"
  | "comment"
  | "comment_order"
  | "backoffices_id"
  | "contact"
  | "message_signees";

export const useProjectWPField = <
  T extends {
    project_id: NestorProjectId | null;
    project_workpackage_id: NestorProjectWorkpackageId | null;
  }
>(
  el: T | undefined,
  required: boolean
) => {
  const project_id = useOpenedProjectSearchField({
    label: "Project",
    originalValue: el?.project_id || undefined,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(required),
  });

  const project_workpackage_id = useWorkpackageField({
    label: "Workpackage",
    projectId: project_id.value,
    originalValue: el?.project_workpackage_id || undefined,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(required),
  });
  return { project_id, project_workpackage_id };
};

export const useImputation = <
  T extends {
    project_id: NestorProjectId | null;
    project_workpackage_id: NestorProjectWorkpackageId | null;
    cost_element_id: NestorCostElementId | null;
    section_id: NestorSectorId | null;
  }
>(
  order: T | undefined,
  is_administrative: boolean,
  cost_center_required: boolean,
  optional: boolean
) => {
  const [imputation, setImputation] = useState<t_Imputation>(order?.section_id ? "sector" : "project");
  const { project_id, project_workpackage_id } = useProjectWPField(
    order,
    !optional && imputation === "project" && !is_administrative
  );

  const sector_id = useSectionField({
    label: lang.sector,
    defaultValue: undefined,
    originalValue: order?.section_id || undefined,
    validationOnChange: useFieldOptRequired(!optional && imputation === "sector" && !is_administrative),
  });

  const cost_element_id = useCostElementsField({
    label: "Cost element",
    type: imputation,
    projectId: project_id.value,
    originalValue: order?.cost_element_id || undefined,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(!optional && cost_center_required), // Cost element is required at secretary stage
  });

  const cost_element_alias = useCostElementAliasesField({
    label: "What is your purchase ?",
    projectId: project_id.value,
    defaultValue: undefined,
    disabled: imputation !== "project",
  });

  useEffect(() => {
    if (cost_element_alias.value) cost_element_id.updateValue(cost_element_alias.value);
  }, [cost_element_alias.value, cost_element_id.updateValue]);

  return {
    imputation,
    setImputation,
    project_id,
    project_workpackage_id,
    sector_id,
    cost_element_id,
    cost_element_alias,
  };
};

export const OrderEditCreateForm: React.FunctionComponent<{
  order?: NestorOrder;
}> = (props) => {
  const allowed = useHasPermission("can_edit") || !props.order; // Can always create a new one

  if (allowed) {
    return <OrderEditCreateFormInner {...props} />;
  } else {
    return (
      <div className="alert alert-danger">
        {props.order?.is_locked
          ? "This order is locked because a signing is in progress. It will be unlocked when the signing is done or cancelled."
          : "You are not allowed to edit this order"}
      </div>
    );
  }
};

export const OrderEditCreateFormInner: React.FunctionComponent<{
  order?: NestorOrder;
}> = (props) => {
  const myId = useMyId();
  const amIElevated = useAmIElevated();
  const orderState = props.order?.state || 0;

  const is_administrative = useFormCheckboxField({
    label: "Only for stock management",
    defaultValue: false,
    originalValue: props.order ? !!props.order.is_administrative : false,
    helpText: '"Fake" order with no signing, no project, only used to handle stock management',
  });

  const {
    imputation,
    setImputation,
    project_id,
    project_workpackage_id,
    sector_id,
    cost_element_id,
    cost_element_alias,
  } = useImputation(props.order, is_administrative.value, orderState > 0, false);

  const [supplierId, setSupplierId] = useState<number | undefined>(props.order?.supplier_id || undefined);
  const supplier_id = useSupplierSearchField({
    label: "Supplier",
    originalValue: supplierId,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(true),
  });

  const currency_id = useCurrencyField({
    label: "Currency",
    defaultValue: undefined,
    originalValue: props.order?.currency_id || supplier_id.selectedItem?.currency_id || undefined,

    validationOnChange: useFieldOptRequired(!is_administrative.value), // An administrative order does not require a currency
  });

  const shipping_cost = useMonetaryField({
    label: "Shipping cost",
    originalValue: props.order?.shipping_cost || 0,
    currency: currency_id.selectedElement?.name,
    defaultValue: 0,
  });

  const other_cost = useMonetaryField({
    label: "Other cost",
    originalValue: props.order?.other_cost || 0,
    currency: currency_id.selectedElement?.name,
    defaultValue: 0,
  });

  const discount = useMonetaryField({
    label: "Discount",
    originalValue: props.order?.discount || 0,
    currency: currency_id.selectedElement?.name,
    defaultValue: 0,
    helpText: "A positive number indicates a discount",
  });

  const discount_comment = useFormTextField({
    label: "Comment on the discount",
    originalValue: props.order?.discount_comment || "",
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const other_cost_comment = useFormTextField({
    label: "Comment on the other costs",
    originalValue: props.order?.other_cost_description || "",
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const shipping_cost_comment = useFormTextField({
    label: "Comment on the shipping cost",
    originalValue: props.order?.shipping_cost_comment || "",
    type: FieldValueType.STRING,
    defaultValue: "",
  });

  const sap_id = useFormTextField({
    label: "SAP number",
    originalValue: props.order?.sap_id ? props.order.sap_id.toString() : "",
    type: FieldValueType.STRING,
    defaultValue: "",
    //validationOnChange: false // BU-R List ID 0. Previously: useFieldOptRequired<string>(orderState > 10), // Required at secretary level
  });

  const grouped_order = useFormCheckboxField({
    label: "Grouped order",
    originalValue: props.order ? !!props.order.grouped_order : false,
    defaultValue: false,
    helpText: "Indicates to your co-workers that they may freely add items to this order.",
  });

  const urgent = useFormCheckboxField({
    label: "Urgent",
    originalValue: props.order ? !!props.order.urgent : false,

    defaultValue: false,
    helpText: "For fast processing",
  });

  const restricted = useFormCheckboxField({
    label: "Restricted",
    originalValue: grouped_order.value ? false : props.order ? props.order.type === "restricted" : false,
    defaultValue: false,
    helpText: "No one else but you may add articles to this order",
    disabled: grouped_order.value,
  });

  const has_chemicals = useFormCheckboxField({
    label: "Chemicals are ordered",
    originalValue: !!(props.order?.has_chemicals || false),
    defaultValue: false,
    helpText: "Check this field if you are ordering chemicals",
    disabled: grouped_order.value,
  });

  const { updateValue: setRestricted } = restricted;
  const __grouped = grouped_order.value;
  useEffect(() => {
    if (__grouped) {
      setRestricted(false);
    }
  }, [setRestricted, __grouped]);

  const employee_id = useEmployeeSearchField({
    label: "Initiator",
    originalValue: props.order?.employee_id || myId,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(orderState > 0), // Required at secretary level
  });

  const { signee_left_id, signee_right_id } = useFieldSignees(
    props.order?.signee_left_id || undefined,
    props.order?.signee_right_id || undefined,
    orderState > 0
  );

  const { signee_left_id: signee_left_po_id, signee_right_id: signee_right_po_id } = useFieldSignees(
    props.order?.signee_left_po_id || undefined,
    props.order?.signee_right_po_id || undefined,
    false
  );

  const secretary_id = useSecretaryEmployeeSearchField({
    label: "Secretary",
    originalValue: props.order?.secretary_id || undefined,
    defaultValue: undefined,
    validationOnChange: useFieldOptRequired(false),
  });

  const transferring = useTransferringHandlerField(
    props.order?.transferring || supplier_id.selectedItem?.transferring || undefined,
    !is_administrative.value
  );

  const backoffices_id = useBackofficeField({
    label: "Backoffice",
    defaultValue: [],
    originalValue: props.order?.backoffices,
    validationOnChange: useFieldOptRequired(!is_administrative.value),
  });

  const ship_at = useFormDateField({
    label: "Expected shipping date",
    defaultValue: undefined,
    originalValue: props.order?.ship_at || undefined,
  });

  const payment_mode = useFieldPaymentMode(props.order?.payment_mode || supplier_id.selectedItem?.payment_mode);

  const contact = useFormSelectObj({
    label: "Point of contact",
    originalValue: props.order?.contact || "initiator",
    defaultValue: "initiator",
    helpText:
      "This person's email will appear on the PO sent to the supplier and he/she will be responsible for receiving the goods",
    options: {
      initiator: "Initiator",
      manager: "Manager",
      backoffice: "Backoffice",
    },
  });

  const ship_to = useFormTextareaField({
    label: "Ship to (leave blank to use default shipping address)",
    originalValue: props.order?.ship_to || "",
    defaultValue: "",
    height: 150,
  });

  const bill_to = useFormTextareaField({
    label: "Bill to (leave blank to use default shipping address)",
    originalValue: props.order?.bill_to || "",
    defaultValue: "",
    height: 150,
  });

  const comment = useFormTextField({
    label: "Comment (top of the page)",
    originalValue: props.order?.comment || "",
    type: FieldValueType.STRING,
    defaultValue: "",
    helpText: "Fill this field if you would like to reference for ex. an offer number",
  });

  const comment_order = useFormTextareaField({
    label: "Comment (bottom of the page)",
    originalValue: props.order?.comment_order || "",
    defaultValue: "",
  });

  const message_signees = useFormTextareaField({
    label: "Message for the signees (appears in the signing email)",
    originalValue: props.order?.message_signees || "",
    defaultValue: "",
    height: 90,
  });

  const apiCall = useNestorAPIForms<{ response: { id?: number } }>(
    props.order ? `purchasing/orders/${props.order.id}/forms/update` : `purchasing/orders/forms/create`,

    false,
    undefined,
    NestorPOST
  );

  const route = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    if (apiCall.result && apiCall.result.response.id && !props.order) {
      const redirectURL = url.resolve(route.url, apiCall.result.response.id + "/edit_articles");

      history.push(redirectURL);
    }
  }, [apiCall.result, route.url, history, props.order]);

  const onSupplierAdded = useCallback((result: any) => {
    setSupplierId(result.response.id);
  }, []);

  const form = useForm({
    fields: {
      project_id,
      project_workpackage_id,
      cost_element_id,
      cost_element_alias,
      currency_id,
      shipping_cost,
      shipping_cost_comment,
      other_cost,
      other_cost_comment,
      discount,
      discount_comment,
      employee_id,
      message_signees,
      signee_left_id,
      signee_right_id,
      signee_left_po_id,
      signee_right_po_id,
      secretary_id,
      sap_id,
      ship_at,
      is_administrative,
      grouped_order,
      has_chemicals,
      urgent,
      restricted,
      transferring,
      payment_mode,
      sector_id,
      supplier_id,
      backoffices_id,
      ship_to,
      bill_to,
      comment,
      comment_order,
      contact,
    },

    Template: FormTemplate,
    fw: {
      el: props.order,
      onSupplierAdded,
      imputation,
      amIElevated,
      setImputation,
    },

    onSubmit() {
      const payload: any = {
        is_cost_center: imputation === "sector" ? 1 : 0,
        project_id: project_id.value,
        project_workpackage_id: project_workpackage_id.value,
        cost_element_id: cost_element_id.value,
        currency_id: currency_id.value,
        shipping_cost: shipping_cost.value || 0,
        shipping_cost_comment: shipping_cost_comment.value,
        other_cost: other_cost.value || 0,
        other_cost_description: other_cost_comment.value,
        discount: discount.value || 0,
        discount_comment: discount_comment.value,
        employee_id: employee_id.value,
        signee_left_id: signee_left_id.value,
        signee_right_id: signee_right_id.value,
        signee_left_po_id: signee_left_po_id.value || null,
        signee_right_po_id: signee_right_po_id.value || null,
        secretary_id: secretary_id.value,
        message_signees: message_signees.value,
        sap_id: sap_id.value,
        ship_at: ship_at.value,
        is_administrative: is_administrative.value,
        grouped_order: grouped_order.value,
        has_chemicals: has_chemicals.value,
        urgent: urgent.value,
        type: restricted.value ? "restricted" : "open",
        transferring: transferring.value,
        payment_mode: payment_mode.value,
        section_id: sector_id.value,
        supplier_id: supplier_id.value,
        contact: contact.value,

        ship_to: ship_to.value,
        bill_to: bill_to.value,
        comment: comment.value,
        comment_order: comment_order.value,

        backoffices: backoffices_id.value.map((e) => e.id).filter((e) => !!e),
      };

      if (props.order) {
        payload.id = props.order.id;
      }

      apiCall.doQuery(undefined, payload);
    },
  });

  const { error } = apiCall;
  const { setErrors } = form;
  useEffect(() => {
    if (setErrors) {
      setErrors(error);
    }
  }, [error, setErrors]);

  return (
    <>
      <IOState source={apiCall.state} />
      {form.Content}
    </>
  );
};

const FormTemplate: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  imputation: t_Imputation;
  setImputation: React.Dispatch<React.SetStateAction<t_Imputation>>;
  onSupplierAdded: (result: any) => void;
  amIElevated: boolean;
  submit: Function;
  el?: NestorOrder;
}> = (props) => {
  const [addSupplier, setAddSupplier] = useState<boolean>(false);

  return (
    <>
      {addSupplier ? (
        <SupplierQuickAddEditModal
          show={true}
          handleClose={() => {
            setAddSupplier(false);
          }}
          onValidated={(out) => {
            props.onSupplierAdded(out);
            setAddSupplier(false);
          }}
          fw={{}}
        />
      ) : null}

      <div className="row">
        <div className="col-12 mb-2 d-flex ">
          <button className="btn btn-success btn-lg flex-grow-1" type="button" onClick={() => props.submit()}>
            Save the order and add articles
          </button>
        </div>
      </div>

      <div className="row">
        <div className="col-6 mb-2">
          <CardComponent header="Imputing" additionalClasses="h-100">
            <ImputationForm imputation={props.imputation} setImputation={props.setImputation} fields={props.fields} />
            <div className="row">
              <div className="col-xl-6 col-12">{props.fields["supplier_id"].Content}</div>
              <div className="col-xl-6 col-12 mb-2 ">
                <div className="d-flex h-100">
                  <button className="btn btn-primary h-100 " type="button" onClick={() => setAddSupplier(true)}>
                    New supplier
                  </button>
                  <SupplierFeedbackGrade supplier_id={props.fields.supplier_id.value} />
                  <PopoverConversation type="supplier" id={props.fields.supplier_id.value} />
                  {props.imputation === "project" && (
                    <SupplierQualificationButton
                      project_id={props.fields.project_id.value}
                      supplier_id={props.fields.supplier_id.value}
                    />
                  )}
                </div>
              </div>
            </div>
          </CardComponent>
        </div>
        <div className="col-6 mb-2">
          <FormOrderType {...props}></FormOrderType>
        </div>
      </div>

      <div className="row">
        {!props.fields["is_administrative"]!.value ? (
          <>
            {props.amIElevated ? (
              <div className="mb-2 col-lg-4 col-md-6">
                <FormSecretaries {...props} />
              </div>
            ) : null}
            <div className="mb-2 col-lg-4 col-md-6 flex-grow">
              <FormSignees {...props} />
            </div>
            <div className="mb-2 col-lg-4 col-md-6 flex-grow">
              <FormReminderSigningRights />
            </div>
            <div className="mb-2 col-lg-4 col-md-6">
              <FormTemplateFinancial {...props} />
            </div>{" "}
            <div className="mb-2 col-lg-4 col-md-6">
              <FormOrderComments {...props} />
            </div>{" "}
            <div className="mb-2 col-lg-4 col-md-6">
              <FormOrderAddresses {...props} />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

const FormOrderType: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
}> = (props) => {
  return (
    <CardComponent header="Handling" additionalClasses="h-100">
      {props.fields.is_administrative.Content}
      {props.fields.grouped_order.Content}
      {props.fields.urgent.Content}
      {props.fields.restricted.Content}
      {props.fields.has_chemicals.Content}
    </CardComponent>
  );
};

const computeAddress = (el: NestorAddress) => {
  /*  let name = el.name;
    const reg = new RegExp(/\{([^\}]*)\}/, "ug");
    const match = name.matchAll(reg);

    for (let matchEl of match) {
        name = name.replace(match[0], _.get(el, match[1].toLowerCase()));
    }*/

  return [el.name, el.address, el.address2, el.country + "-" + el.postal_code + " " + el.city]
    .filter((el) => el !== null && el.length > 0)
    .join("\n");
};

const FormOrderComments: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  el?: NestorOrder;
}> = (props) => {
  return (
    <CardComponent header="PO specific details" additionalClasses="h-100">
      {props.fields.contact.Content}
      {props.fields.comment.Content}
      {props.fields.comment_order.Content}
    </CardComponent>
  );
};

const FormOrderAddresses: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  el?: NestorOrder;
}> = (props) => {
  /**
   * Non participating fields
   */
  const _addresses_ship_to = useAddressListField({
    label: "Ship to address",
    defaultValue: undefined,
  });
  const _addresses_bill_to = useAddressListField({
    label: "Bill to address",
    defaultValue: undefined,
  });

  const { selectedElement: ship_to_v, updateValue: set_ship_to_v } = _addresses_ship_to;
  const { selectedElement: bill_to_v, updateValue: set_bill_to_v } = _addresses_bill_to;
  const { updateValue: set_ship_to } = props.fields.ship_to;
  const { updateValue: set_bill_to } = props.fields.bill_to;

  useEffect(() => {
    if (ship_to_v) {
      set_ship_to(computeAddress(ship_to_v));
      set_ship_to_v(undefined);
    }
  }, [ship_to_v, set_ship_to, set_ship_to_v]);

  useEffect(() => {
    if (bill_to_v) {
      set_bill_to(computeAddress(bill_to_v));
      set_bill_to_v(undefined);
    }
  }, [bill_to_v, set_bill_to, set_bill_to_v]);

  return (
    <CardComponent header="PDF Addresses" additionalClasses="h-100">
      <div className="row">
        <div className="col-xl-8 col-12">
          <div className="text-info csem-info">Only write information here to overwrite the default addresses.</div>
        </div>
      </div>
      <h6>Shipping address</h6>
      {_addresses_ship_to.Content}
      <div className="row">
        <div className="col-6">{props.fields.ship_to.Content}</div>
        <div className="col-6">
          <div>
            <strong className="text-muted">Default address</strong>
          </div>
          {props.el ? (
            <>
              CSEM SA
              <br />
              c/o {props.el.employee?.fullname} ({props.el.employee?.building})
              <br />
              {props.el.employee?.address}
              <br />
              CH-{props.el.employee?.postal_code} {props.el.employee?.city},
            </>
          ) : (
            "Impossible to determine during creation"
          )}
        </div>
      </div>

      <h6>Billing address</h6>
      {_addresses_bill_to.Content}

      <div className="row">
        <div className="col-6"> {props.fields.bill_to.Content}</div>
        <div className="col-6">
          <div>
            <strong className="text-muted">Default address</strong>
          </div>
          {props.el ? (
            props.el.secretary ? (
              <>
                CSEM SA
                <br />
                c/o {props.el.secretary?.fullname}
                <br />
                {props.el.secretary?.address}
                <br />
                CH-{props.el.secretary?.postal_code} {props.el.secretary?.city},
              </>
            ) : (
              "Can't determine yet: no secretary is processing this order yet"
            )
          ) : (
            "Impossible to determine during creation"
          )}
        </div>
      </div>
    </CardComponent>
  );
};

const FormTemplateFinancial: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
}> = (props) => {
  return (
    <CardComponent header="Financial" additionalClasses="h-100">
      <div className="row">
        <div className="col-6">{props.fields.cost_element_id.Content}</div>
        <div className="col-6">{props.fields.cost_element_alias.Content}</div>
      </div>

      {props.fields.currency_id.Content}
      {props.fields.payment_mode.Content}

      <h6>Additional costs</h6>

      <div className="row">
        <div className="col-xl-6 col-12">{props.fields["shipping_cost"]!.Content}</div>
        <div className="col-xl-6 col-12">{props.fields["shipping_cost_comment"]!.Content}</div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-12">{props.fields["other_cost"]!.Content}</div>
        <div className="col-xl-6 col-12">{props.fields["other_cost_comment"]!.Content}</div>
      </div>
      <div className="row">
        <div className="col-xl-6 col-12">{props.fields["discount"]!.Content}</div>
        <div className="col-xl-6 col-12">{props.fields["discount_comment"]!.Content}</div>
      </div>
    </CardComponent>
  );
};

const FormSignees: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
}> = (props) => {
  return (
    <CardComponent header="Signing and processing" additionalClasses="h-100">
      {props.fields["employee_id"]!.Content}
      {props.fields["signee_left_id"]!.Content}
      {props.fields["signee_right_id"]!.Content}
      {props.fields.message_signees.Content}
      {props.fields.backoffices_id.Content}
      {props.fields["transferring"]!.Content}
      {props.fields["ship_at"]!.Content}
    </CardComponent>
  );
};

const FormReminderSigningRights: React.FunctionComponent<{}> = (props) => {
  return (
    <CardComponent header="Reminder: signing rights" additionalClasses="h-100 bg-info bg-opacity-25">
      <table className="table">
        <thead>
          <tr>
            <th colSpan={3} className="text-center">
              Signing rights
            </th>
          </tr>
          <tr>
            <th>Total amount</th>
            <th>Left signee</th>
            <th>Right signee</th>
          </tr>
          <tr>
            <th colSpan={3}>Projects imputation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> &lt; 1kCHF </td>
            <td>Project leader</td>
            <td>Initiator</td>
          </tr>
          <tr>
            <td> &lt; 10kCHF </td>
            <td>{lang.sector} head</td>
            <td>Project leader</td>
          </tr>
          <tr>
            <td> &gt; 10kCHF </td>
            <td>{lang.division} head</td>
            <td>Project leader</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={3}>Group imputation</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> &lt; 10kCHF </td>
            <td>{lang.sector} head</td>
            <td>Initiator</td>
          </tr>
          <tr>
            <td> &gt; 10kCHF </td>
            <td>{lang.division} head</td>
            <td>{lang.sector} head</td>
          </tr>
        </tbody>
      </table>
    </CardComponent>
  );
};

const FormSecretaries: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
}> = (props) => {
  return (
    <CardComponent header="For secretaries only" additionalClasses="h-100">
      {props.fields["sap_id"]!.Content}
      {props.fields["secretary_id"]!.Content}

      <h6>Signing in PO</h6>
      {props.fields.signee_left_po_id.Content}
      {props.fields.signee_right_po_id.Content}
    </CardComponent>
  );
};

export const ImputationForm: React.FunctionComponent<{
  imputation: t_Imputation;
  setImputation: React.Dispatch<React.SetStateAction<t_Imputation>>;
  fields: Record<"project_id" | "project_workpackage_id" | "sector_id", Field<any>>;
}> = (props) => {
  return (
    <>
      <div className="row align-items-center mb-3">
        <div className="col-4 mb2">Should we impute on a sector or a project ?</div>
        <div className="col-8 mb2">
          <button
            type="button"
            className={`btn btn-lg  ${props.imputation === "project" ? "btn-primary active" : "btn-secondary"} `}
            onClick={(e) => props.setImputation("project")}
          >
            A Project
          </button>

          <button
            type="button"
            className={`btn btn-lg btn-primary ${
              props.imputation === "sector" ? "btn-primary active" : "btn-secondary"
            } `}
            onClick={(e) => props.setImputation("sector")}
          >
            A {lang.sector}
          </button>
        </div>
      </div>

      {props.imputation === "project" ? (
        <>
          {props.fields["project_id"].Content}
          {props.fields["project_workpackage_id"]!.Content}
        </>
      ) : (
        <>{props.fields["sector_id"].Content}</>
      )}
    </>
  );
};
