import { useContainerSearchField } from "nestor/components/forms/fields/ContainerSearch";
import { useSkuUnitsField } from "nestor/components/forms/fields/SKUUnits";
import { NestorPOST, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSku } from "nestor/types/stock/sku";
import { CardComponent } from "@csem/shared-utils";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { FormTemplateDefault } from "@csem/forms";
import { IOState } from "@csem/api";

export const SKUDerive = (props: { sku: NestorSku }) => {
  const number = useFormTextField({
    type: FieldValueType.NUMBER,
    label: "Number of derived SKUs",
    defaultValue: 1,
  });

  const container_id = useContainerSearchField({
    label: "Place in container",
    defaultValue: props.sku.containers?.[0]?.id,
    validationOnChange: useFieldOptRequired(true),
  });

  const quantity = useFormTextField({
    type: FieldValueType.NUMBER,
    label: "Quantity per SKU",
    defaultValue: 0 as number,
    validationOnChange: useFieldOptRequired<number>(true),
  });

  const unit = useSkuUnitsField("Unit", false, "unit");

  const apiCall = useNestorAPIForms(NestorServerRoutes.sku_derived_create(props.sku.id), false, undefined, NestorPOST);

  const form = useForm({
    fields: {
      number,
      container_id,
      quantity,
      unit,
    },

    Template: FormTemplateDefault,
    fw: {},
    onSubmit: function () {
      apiCall.doQuery(undefined, {
        number: number.value,
        container_id: container_id.value,
        quantity: quantity.value,
        unit: unit.value,
      });
    },
  });

  return (
    <CardComponent header="Derive new SKUs">
      <IOState source={apiCall.state} />
      {form.Content}
      <button type="button" className="btn btn-success w-100" onClick={form.submit}>
        Let's make those SKUs !
      </button>
    </CardComponent>
  );
};
