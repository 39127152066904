import { SkuContainerListCard } from "nestor/components/nestor/stock/skus/ListWithContainers";
import { WithdrawalContentCard } from "nestor/components/nestor/stock/skus/movements/WithdrawalContent";
import { WithdrawalForm } from "nestor/components/nestor/stock/skus/movements/WithdrawalForm";
import { NestorSkuTransferContextProvider } from "nestor/contexts/NestorSkuTransferContext";

export function SKUsList() {
  return (
    <div className="container-fluid p-3">
      <NestorSkuTransferContextProvider>
        <div className="row">
          <div className="col-md-6 mb-2">
            <WithdrawalForm />
          </div>
          <div className="col-md-6 mb-2">
            <WithdrawalContentCard />
          </div>
          <div className="col-md-12 mb-2">
            <SkuContainerListCard />
          </div>
        </div>
      </NestorSkuTransferContextProvider>
    </div>
  );
}
