import { NestorBackoffice } from "../company/backoffice";
import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import { NestorSector, NestorSectorId } from "../company/sector";
import { NestorSignature } from "../company/signature";
import { NestorProject } from "../projects/projects";
import { NestorCurrency, NestorMonetaryValue } from "../util/currency";
import { NestorDateString, NestorDateTimeString } from "../util/dates";
import { NUID } from "../util/nuid";
import { NestorArticle } from "./article";
import { NestorInvoice } from "./invoice";
import { NestorSupplier } from "./supplier";

export type NestorOrderId = number;

// TODO: Link this with the form
export enum NestorOrderPaymentMode {
  CREDIT_CARD = "credit",
  PROFORMA = "proforma",
  CREDIT_NODE = "credit_note",
}

export enum NestorOrderTransferingType {
  INTERNAL = "internal",
  SECRETARY = "secretary",
  HANDLER = "handler",
}

export enum NestorOrderState {
  EDIT = 0,
  PROCESSING = 10,
  WAITING_SEND = 30,
  WAITING_CLOSE = 40,
  FINALIZED = 100,
  CANCELLED = 200,
}

export const NestorOrderStateTexts = {
  0: {
    tag: "1. editing",
  },
  10: {
    tag: "2. processing",
  },
  20: {
    tag: "unused",
  },
  30: {
    tag: "4. waiting to be sent",
  },
  40: {
    tag: "5. sent",
  },
  100: {
    tag: "6. finalized",
  },
  200: {
    tag: "cancelled",
  },
};

type NestorWorkPackage = null;

export type NestorOrder = {
  id: NestorOrderId;

  articles_count: number;
  cost_element_id: number;

  created_at: NestorDateTimeString;
  finalized_at: NestorDateTimeString | null;
  processed_at: NestorDateTimeString | null;
  ready_at: NestorDateTimeString | null;
  revoked_at: NestorDateTimeString | null;
  ship_at: NestorDateTimeString | null;
  updated_at: NestorDateTimeString | null;
  validated_at: NestorDateTimeString | null;
  transferred_at: NestorDateTimeString | null;
  signing_notified_at: NestorDateTimeString | null;

  currency?: NestorCurrency;
  currency_id: number | null;

  goods_received: 1 | 0;
  division_id: string;
  for_inventory: number;

  is_cost_center: number;
  nuid: NUID;
  payment_mode: NestorOrderPaymentMode;

  employee_id: NestorEmployeeId;
  signee_left_id: NestorEmployeeId | null;
  signee_right_id: NestorEmployeeId | null;
  signee_left_po_id: NestorEmployeeId | null;
  signee_right_po_id: NestorEmployeeId | null;
  post_handler_id: NestorEmployeeId | null;
  secretary_id: NestorEmployeeId | null;

  employee: NestorEmployee | null;
  signee_right?: NestorEmployee | null;
  signee_left?: NestorEmployee | null;
  signee_right_po?: NestorEmployee | null;
  signee_left_po?: NestorEmployee | null;
  message_signees: string | null;

  post_handler: NestorEmployee | null;
  secretary: NestorEmployee | null;

  project?: NestorProject | null;
  project_id: number | null;
  workpackage: NestorWorkPackage;
  project_workpackage_id: number;

  sap_id: number | null;

  section_id: NestorSectorId | null;
  section: NestorSector | null;

  ship_to: NestorDateString;
  bill_to: string;
  contact: "initiator" | "manager" | "backoffice";

  comment: string;
  comment_order: string;

  shipping_cost: number | null;
  shipping_cost_comment: string | null;

  other_cost: number | null;
  other_cost_description: string | null;

  discount: number | null;
  discount_comment: string | null;
  discount_percentage: number | null;

  state: NestorOrderState;

  subtotal: NestorMonetaryValue;
  total: NestorMonetaryValue;

  supplier_id: number;
  supplier: NestorSupplier | null;

  total_articles: number;
  total_invoice: number;
  transferring: NestorOrderTransferingType;

  type: "open" | "restricted";

  //restricted: 1 | 0 | null;
  is_administrative: 1 | 0;
  is_locked: 1 | 0;
  grouped_order: 1 | null;
  has_chemicals: 1 | 0 | null;
  urgent: 1 | null;

  articles?: NestorArticle[];
  invoices?: NestorInvoice[];

  signatures?: NestorSignature[];
  backoffices?: NestorBackoffice[];
};
