import { NestorEmployee, NestorEmployeeId } from "../company/employee";
import { NestorSector, NestorSectorId } from "../company/sector";
import { NestorProject, NestorProjectId } from "../projects/projects";
import { NestorLocation, NestorLocationId } from "../system/location";
import { NestorDateTimeString } from "../util/dates";
import { NUID } from "../util/nuid";

export enum NestorContainerType {
  STANDARD = "standard",
  MEDICAL = "medical",
}

export type NestorContainerId = number;

export type NestorContainer = {
  nuid: NUID;
  id: NestorContainerId;
  name: string;
  description: string;
  project_id: NestorProjectId;
  project?: NestorProject;
  barcode: string;
  type: NestorContainerType;

  accept_withdrawals: 1 | 0;
  is_sink: 1 | 0;
  expired_components: 1 | 0;

  section_id: NestorSectorId;
  section?: NestorSector;

  parent_container_id: NestorContainerId | null;
  parent_container?: NestorContainer;

  employee_id: NestorEmployeeId;
  employee?: NestorEmployee;

  location_id: NestorLocationId;
  location?: NestorLocation;

  revoked_at: NestorDateTimeString;
  created_at: NestorDateTimeString;
  updated_at: NestorDateTimeString;

  // Additional attributes
  skus_count?: number;
  permissions?: any;
};
