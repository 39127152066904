export const TextWithFallback: React.FunctionComponent<{
    text: string | number | null;
    fallback?: string;
}> = ({ fallback = "No information", ...props }) => {
    if (props.text == null) {
        return <span className="text-muted">No information</span>;
    } else {
        return <span>{props.text}</span>;
    }
};
