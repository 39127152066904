import NestorServerRoutes from "nestor/server_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorProjectWorkpackage } from "nestor/types/projects/workpackage";
import { NestorArticle } from "nestor/types/purchasing/article";
import { NestorOrder, NestorOrderId } from "nestor/types/purchasing/orders";
import { NestorSku } from "nestor/types/stock/sku";
import { WithRelation } from "nestor/types/util/relations";
import { DefaultTableFilter } from "@csem/lists";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { NestorPOST, useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

export const useOrderSingle = (elId: NestorOrderId): NestorApiElement<NestorOrder, undefined> => {
  const apiCall = useNestorAPI<NestorOrder>(NestorServerRoutes.order_details(elId), true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    state: apiCall.state,
    loadingIO: <IOState loadingMessage="Loading order..." source={apiCall.state} success={null} />,
  };
};

export const useOrderDetails = (elId: NestorOrderId) => {
  type V = NestorOrder & { articles: NestorArticle[] };
  return useNestorAPI<V>(NestorServerRoutes.order_details(elId), true);
};

export const useOrderChangeState = (elId: NestorOrderId) => {
  return useNestorAPI<any, number>(
    (state) => {
      return state !== undefined ? NestorServerRoutes.order_change_state(elId, state) : false;
    },
    false,
    undefined
  );
};

export const useOrderArticleList = (orderId: NestorOrderId): apiList<NestorArticle> => {
  return useNestorListApiCall<NestorArticle>(NestorServerRoutes.order_articles(orderId), "article");
};

export const useOrderAllArticleList = (orderId: NestorOrderId): apiList<NestorArticle> => {
  return useNestorListApiCall<NestorArticle>(
    NestorServerRoutes.order_articles(orderId),
    "article",
    new URLSearchParams({
      filtering: JSON.stringify(Object.assign({}, DefaultTableFilter, { take: 1000 })),
    })
  );
};

export const useOrderSkuList = (orderId: NestorOrderId): apiList<NestorSku> => {
  return useNestorListApiCall<NestorSku>(NestorServerRoutes.order_skus(orderId), "sku");
};

type T = NestorDevice & { orders: Array<NestorOrder & { pivot: { comment: string; tag: string } }> };
export const useOrderDeviceList = (orderId: NestorOrderId): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.order_devices(orderId), "device");
};
