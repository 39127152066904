import NestorFrontendRoutes from "nestor/frontend_routes";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { CardComponent } from "@csem/shared-utils";
import { NavigationLookup } from "@csem/lists";
import { TextWithFallback } from "../../util/TextOrNoInfo";

export const SupplierInformationCard: React.FunctionComponent<{
  supplier?: NestorSupplier | null;
}> = (props) => {
  return (
    <CardComponent
      footer={
        props.supplier ? (
          <NavigationLookup icon="pencil-square" path={NestorFrontendRoutes.supplier_edit(props.supplier.id)} />
        ) : (
          <></>
        )
      }
      header="Supplier information"
      additionalClasses="h-100"
    >
      {!props.supplier ? (
        <div className="alert alert-warning">No supplier information was found</div>
      ) : (
        <>
          <div className="row align-items-middle mb-3">
            <div className="col-4"></div>
            <div className="col-8">
              <h5> {props.supplier.name}</h5>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-4">Address </div>
            <div className="col-8">
              {props.supplier.address ? (
                <>
                  {props.supplier.address}
                  <br />
                </>
              ) : null}
              {props.supplier.address2 ? (
                <>
                  {props.supplier.address2}
                  <br />
                </>
              ) : null}
              {props.supplier.postal_code}&nbsp;
              {props.supplier.city}
              <br />
              {props.supplier.country}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-4">Phone </div>
            <div className="col-8">
              <TextWithFallback text={props.supplier.phone} />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-4">E-mail </div>
            <div className="col-8">
              <TextWithFallback text={props.supplier.email} />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-4">Fax </div>
            <div className="col-8">
              <TextWithFallback text={props.supplier.fax} />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-4">URL </div>
            <div className="col-8">
              <TextWithFallback text={props.supplier.url} />
            </div>
          </div>

          {/*  <div className="row mb-3">
            <div className="col-4">Account number </div>
            <div className="col-8">{props.supplier.account}</div>
          </div>
              */}
          {props.supplier.description && props.supplier.description.length > 0 ? (
            <div className="row mb-3">
              <div className="col-12">Description</div>

              <div className="col-12">{props.supplier.description}</div>
            </div>
          ) : null}
        </>
      )}
    </CardComponent>
  );
};
