import { usePcbFunctionalTestField } from "nestor/components/forms/fields/PcbFunctionTest";
import { useSkuSearchField } from "nestor/components/forms/fields/SkuSearch";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorPcb, NestorPcbFunctionalTest, NestorPcbInspectionTest } from "nestor/types/manufacturing/pcb";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import assert from "assert";
import { StandardFormAddEditModalWithFormFactory } from "@csem/shared-utils";
import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { useLocationFields } from "../../../../forms/fields/LocationField";
import { usePcbVisualInspectionTest } from "nestor/components/forms/fields/PcbVisualInspection";

export const PcbEditModal = StandardFormAddEditModalWithFormFactory<NestorPcb, { layout?: NestorPcbLayout }, any>({
  title: (el, fw) => `Edit PCA ${el?.nuid}`, // for layout ${fw.layout.name}`,
  buttonValidate: (el) => "Validate",
  useDefinition: (el, fw) => {
    return useFormEditPcb(el, fw);
  },
});

export const useFormEditPcbFields = (el: NestorPcb | undefined) => {
  const sku_id_f = useSkuSearchField({
    label: "Bare PCB SKU",
    defaultValue: undefined,
    originalValue: el?.sku_id,
  });

  const location_f = useLocationFields(el?.location, false);

  const variant_f = useFormTextField({
    label: "Variant",
    originalValue: el?.current_attributes?.find((a) => a.shorthand === "variant")?.details.value,
    defaultValue: "",
    type: FieldValueType.STRING,
  });

  const revision_f = useFormTextField({
    label: "Revision",
    originalValue: el?.current_attributes?.find((a) => a.shorthand === "revision")?.details.value,
    defaultValue: "",
    type: FieldValueType.STRING,
  });

  const functional_test_f = usePcbFunctionalTestField(
    el?.current_attributes?.find((a) => a.shorthand === "functional_test")?.details.value as NestorPcbFunctionalTest,
    NestorPcbFunctionalTest.not_tested
  );
  const visual_inspection_f = usePcbVisualInspectionTest(
    el?.current_attributes?.find((a) => a.shorthand === "visual_inspection")?.details.value as NestorPcbInspectionTest,
    NestorPcbInspectionTest.not_done
  );

  return { location_f, variant_f, revision_f, functional_test_f, sku_id_f, visual_inspection_f };
};

export const useFormEditPcb = <FW extends { layout?: NestorPcbLayout }>(el: NestorPcb | undefined, fw: FW) => {
  assert(el);
  const { location_f, variant_f, revision_f, functional_test_f, sku_id_f, visual_inspection_f } =
    useFormEditPcbFields(el);

  const apiResult = useNestorAPI(
    `manufacturing/pcblayouts/${el.layout_id}/pcbs/${el.id}/forms/update`,
    false,
    undefined,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }
  );

  const form = useForm({
    onSubmit: () => {
      apiResult.doQuery(undefined, {
        room_id: location_f.room_id.value,
        container_id: location_f.container_id.value,
        location_other: location_f.location_other.value,
        laboratory_id: location_f.laboratory_id.value,
        locationType: location_f.type,
        sku_id: sku_id_f.value,
        variant: variant_f.value,
        revision: revision_f.value,
        functional_test: functional_test_f.value,
        visual_inspection: visual_inspection_f.value,
      });
    },
    fields: {
      sku_id: sku_id_f,
      variant: variant_f,
      revision: revision_f,
      functional_test: functional_test_f,
      visual_inspection: visual_inspection_f,
    },
    Template: FormPCBEdit,
    fw: {
      el,
      locationSwitchButtons: location_f.SwitchButtons,
      locationField: location_f.Field,
    },
  });

  return {
    Content: form.Content,
    out: apiResult.result,
    submit: () => {
      form.submit();
    },
    state: apiResult.state,
  };
};

export type NestorEditPcbFieldNames = "variant" | "revision" | "functional_test" | "sku_id" | "visual_inspection";

export const FormPCBEdit: React.FunctionComponent<{
  fields: Record<NestorEditPcbFieldNames, Field<any>>;

  locationSwitchButtons: JSX.Element;
  locationField: JSX.Element;
}> = (props) => {
  return (
    <>
      <div className="alert alert-info">To modify other PCB attributes, go to the details of the PCB</div>
      <FormPCBAddEdit {...props} locationString="Where is this PCB located ?"></FormPCBAddEdit>
    </>
  );
};

export const FormPCBAddEdit: React.FunctionComponent<{
  fields: Record<NestorEditPcbFieldNames, Field<any>>;

  locationSwitchButtons: JSX.Element;
  locationField: JSX.Element;

  locationString?: string;
}> = (props) => {
  return (
    <>
      {props.fields.sku_id.Content}
      {props.fields.variant.Content}
      {props.fields.revision.Content}
      {props.fields.functional_test.Content}
      {props.fields.visual_inspection.Content}

      <h6>{props.locationString}</h6>
      <div className="mb-1">{props.locationSwitchButtons}</div>
      {props.locationField}
    </>
  );
};
