import { DeviceListCard } from "nestor/components/nestor/inventory/devices/List";
import { DeviceModelEditForm } from "nestor/components/nestor/inventory/device_models/Edit";
import { DeviceModelInfos } from "nestor/components/nestor/inventory/device_models/Info";
import SystemList from "nestor/components/nestor/inventory/device_models/SystemList";
import { DeviceModelParameterListCard } from "nestor/components/nestor/inventory/device_model_parameters/List";
import { ConversationCard } from "nestor/components/nestor/util/Conversation";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { NestorPermissionContextProvider } from "nestor/contexts/NestorPermissionContext";
import { useDeviceModelSingle } from "nestor/hooks/api/useDeviceModelSingle";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

const DeviceModelDetailsInner: React.FunctionComponent<{
  model: NestorDeviceModel;
  refresh: Function;
}> = (props) => {
  const route = useRouteMatch<{ device_model: string }>();
  useMenuElement("Details", route.url + "/details");
  useMenuElement("Edit information", route.url + "/edit");
  useMenuElement("Systems", route.url + "/systems");
  useMenuElement("System parameters", route.url + "/system_parameters");
  useMenuElement("Documents", route.url + "/documents");
  const conversations = useMenuElement("Conversation", route.url + "/conversation");

  return (
    <NestorPermissionContextProvider ressource="device_model" id={route.params.device_model}>
      <Switch>
        <Route path={`${route.url}/details`}>
          <DeviceModelInfos model={props.model} refresh={props.refresh}></DeviceModelInfos>
          <DeviceListCard model_id={props.model.id} />
        </Route>
        <Route path={`${route.url}/edit`}>
          <DeviceModelEditForm device_model={props.model} />
        </Route>

        <Route path={`${route.url}/systems`}>
          <SystemList model={props.model} />
        </Route>

        <Route path={`${route.url}/system_parameters`}>
          <DeviceModelParameterListCard model={props.model} />
        </Route>

        <Route path={`${route.url}/documents`}>
          <DocumentListCard type="device" id={props.model.id} title="Document list"></DocumentListCard>
        </Route>

        <Route path={`${route.url}/conversation`} key="conversation">
          <XemwayMenuProvider {...conversations}>
            <ConversationCard type="device" id={props.model.id}></ConversationCard>
          </XemwayMenuProvider>
        </Route>

        <Route>
          <Redirect to={`${route.url}/details`} />
        </Route>
      </Switch>
    </NestorPermissionContextProvider>
  );
};

export const DeviceModelDetails: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ device_model: string }>();

  const model = useDeviceModelSingle(route.params.device_model);
  const menu = useMenuElement(model.element ? "Model " + model.element.model : "Loading...", route.url);

  return (
    <XemwayMenuProvider {...menu}>
      {model.loadingIO}
      <div className="container-fluid p-3">
        {model.element ? <DeviceModelDetailsInner model={model.element} refresh={model.refresh} /> : null}
      </div>
    </XemwayMenuProvider>
  );
};
