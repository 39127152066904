// Hooks

// Classes

// Components
import { DeviceModelColumn } from "nestor/components/tables/columns/DeviceModelColumn";
import { DeviceParkColumn } from "nestor/components/tables/columns/DeviceParkColumn";
import { DeviceNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import {
  useNonConformCalibrationDeviceList,
  useNonConformMaintenanceDeviceList,
  useNonConformMetrologicalCheckDeviceList,
} from "nestor/hooks/api/useDeviceList";
import { NestorDevice } from "nestor/types/inventory/device";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// Contexts
// ## Generic
import { columnOptions, SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasPagination, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useMemo } from "react";
import { apiList } from "@csem/api";
import { DeviceTypeCategoryRenderer } from "../../../renderers/DeviceType";
//# Tables
import { DateColumn } from "../../../tables/columns/DateColumn";
import { EmployeeColumn } from "../../../tables/columns/EmployeeColumn";
//## Nestor
import { ManufacturerColumn } from "../../../tables/columns/ManufacturerColumn";
import { deflateSync } from "zlib";
import { main } from "@popperjs/core";
import { ReferenceLabel } from "./List";

type T = NestorDevice;

type MODES = "calibrations" | "metrological_checks" | "maintenances";
type FW = {
  tagRenderer: React.FunctionComponent<{ el: NestorDevice }>;
  mode: MODES;
};

const CalibrationTagRenderer = ({ el }: { el: NestorDevice }) => {
  assert(el.calibration_ranges);
  const mult_active_cal =
    el.calibration_ranges.filter((ac) => ac.active_calibrations && ac.active_calibrations.length > 1).length > 0;
  const missing_calibration = el.calibration_ranges.filter((ac) => ac.latest_calibration === null).length > 0;
  const overdue_calibration =
    el.calibration_ranges.filter(
      (ac) =>
        ac.latest_calibration &&
        (moment(ac.latest_calibration.end).isBefore() ||
          moment(ac.latest_calibration.start).add(ac.interval_days, "days").isBefore())
    ).length > 0;

  const calibrated = !!el.is_calibrated || !!el.is_reference;

  if (mult_active_cal) {
    return <div className="d-block badge bg-secondary">&gt; 1 active calib.</div>;
  }
  if (!calibrated) {
    return <div className="d-block badge bg-secondary">not calibrated</div>;
  }

  if (el.is_calibrated && el.calibration_ranges.length === 0) {
    return <div className="d-block badge bg-danger">no calibration ranges</div>;
  }

  return (
    <>
      {missing_calibration ? (
        <div className="d-block badge bg-danger">no active calibration</div>
      ) : overdue_calibration ? (
        <div className="d-block badge bg-dark">calibration overdue</div>
      ) : (
        <div className="d-block badge bg-warning">unknown issue</div>
      )}
    </>
  );
};

const MetrologicalCheckTagRenderer = ({ el }: { el: NestorDevice }) => {
  assert(el.calibration_ranges);

  // Look in calranges                       // where the latest calibration     // has no active check
  const missing_check =
    el.calibration_ranges.filter((calrange) => calrange.latest_calibration?.active_metrological_check === null).length >
    0;

  const overdue_check =
    el.calibration_ranges.filter(
      (ac) =>
        // When there is a calibration
        ac.latest_calibration &&
        // When it has an active metrological check
        ac.latest_calibration.active_metrological_check &&
        // When it has been done
        moment(ac.latest_calibration.active_metrological_check.created_at)
          // Plus the check interval
          .add(ac.metrological_check_interval_days, "days")
          // Is in the past
          .isBefore()
    ).length > 0; // One of the overdue check is enough

  return (
    <>
      {missing_check ? (
        <div className="d-block badge bg-danger">missing confirmation</div>
      ) : overdue_check ? (
        <div className="d-block badge bg-dark">overdue confirmation</div>
      ) : (
        <div className="d-block badge bg-warning" title="There may be more than one active calibration">
          unknown issue
        </div>
      )}
    </>
  );
};

const MaintenanceTagRenderer = ({ el }: { el: NestorDevice }) => {
  return <div className="d-block badge bg-danger">overdue maintenance</div>;
};

const NonConfirmingDeviceTableFactory = function (device_park_employee_col: columnOptions<T, any, any>) {
  return TableRenderer<T, FW, {}, {}, "id">({
    key: "id",
    columns: [
      DeviceNavigationColumn,
      NUIDColumn,

      SimpleColumn("ni", "Old NI", (e) => e.NI),

      SimpleColumn("nonconformity", "Non-conformity", (e, fw, hk) => {
        const R = fw.tagRenderer;
        return (
          <>
            <R el={e} />
            {e.is_reference ? ReferenceLabel : null}
          </>
        );
      }),

      SimpleColumn("device", "Device type", (e, fw, hk) => <DeviceTypeCategoryRenderer type={e.model?.type} />),

      DeviceModelColumn(
        "manufacturer",
        "Manufacturer",
        "model.device_manufacturer_id",
        (e) => e.model?.manufacturer || undefined,
        (e) => e.model?.model || undefined
      ),

      EmployeeColumn<T>("owner", "Owner", "owner_id", (e) => e.owner),
      DeviceParkColumn("park", "Device park", "device_park_id", (e) => e.device_park),
      device_park_employee_col,

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
      DateColumn("expired_at", "Expired at", undefined, (e, fw) => {
        if (fw.mode == "calibrations") {
          const dates = e.calibration_ranges
            ?.map((calRange) => {
              if (calRange.active_calibrations && calRange.active_calibrations.length > 1) {
                return null;
              }
              if (calRange.latest_calibration && calRange.latest_calibration) {
                const activeCal = calRange.latest_calibration;

                const startedAt = new Date(activeCal.start);
                startedAt.setDate(startedAt.getDate() + calRange.interval_days);

                return startedAt;
              }
            })
            .filter((e) => e !== undefined)[0];

          if (dates) {
            return dates.toISOString();
          }
        } else if (fw.mode == "metrological_checks") {
          const dates = e.calibration_ranges
            ?.map((calRange) => {
              if (calRange.active_calibrations && calRange.active_calibrations.length > 1) {
                return null;
              }
              if (calRange.latest_calibration?.active_metrological_check) {
                const activeCheck = calRange.latest_calibration?.active_metrological_check;

                const startedAt = new Date(activeCheck.created_at);
                startedAt.setDate(startedAt.getDate() + calRange.metrological_check_interval_days);

                return startedAt;
              }
            })
            .filter((e) => e !== undefined)[0];

          if (dates) {
            return dates.toISOString();
          }
        } else if (fw.mode == "maintenances") {
          const out = e.maintenances
            ?.map((maintenance) => {
              // @ts-ignore
              const actionUpdatedAt = maintenance.action_updated_at as string | undefined;
              if (!actionUpdatedAt) {
                return undefined;
              }

              const date = new Date(actionUpdatedAt);
              date.setDate(date.getDate() + maintenance.periodicity_days);

              return date.toISOString();
            })
            .filter((e) => e !== undefined);

          if (out && out.length > 0) {
            return out[0] as string;
          }

          return "";
        }
        return "";
      }),
    ],

    useLineParamInjection: (el, forwarded) => {
      //  assert(el.calibration_ranges);

      return {};
    },
  });
};

const GenericNonConfirmListContainer = (function () {
  return HasRefresh(HasStdTablePagination(GenericListContent<FW, T>()));
})();

export const DeviceNonConformCard = (props: {
  header: string;
  hook: () => apiList<NestorDevice, any, any>;
  tagRenderer: React.FunctionComponent<{ el: NestorDevice }>;
  tableRenderer: ReturnType<typeof NonConfirmingDeviceTableFactory>;
  mode: MODES;
}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  const useHook = props.hook;
  const list = useHook();

  return (
    <div className="m-2">
      <CardComponent header={props.header}>
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericNonConfirmListContainer
            //cardHeader="Gateway list"
            list={list}
            fwProps={{ tagRenderer: props.tagRenderer, mode: props.mode }}
            listTemplate={props.tableRenderer}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

const NonConfirmingDeviceTableCalibration = NonConfirmingDeviceTableFactory(
  EmployeeColumn<T>(
    "device_park_resp_id",
    "Reponsible",
    // @ts-ignore
    "device_park.calibration_manager_id",
    (e) => e.device_park?.calibration_manager
  )
);

export const NonConformCalibrationDeviceListCard = () => {
  return (
    <DeviceNonConformCard
      header="Overdue or missing calibrations"
      hook={useNonConformCalibrationDeviceList}
      tagRenderer={CalibrationTagRenderer}
      tableRenderer={NonConfirmingDeviceTableCalibration}
      mode="calibrations"
    />
  );
};

const NonConfirmingDeviceTableMetrologicalCheck = NonConfirmingDeviceTableFactory(
  EmployeeColumn<T>(
    "device_park_resp_id",
    "Reponsible",
    // @ts-ignore
    "device_park.metrological_check_manager_id",
    (e) => e.device_park?.metrological_check_manager
  )
);

export const NonConformMetrologicalCheckDeviceListCard = () => {
  return (
    <DeviceNonConformCard
      header="Overdue or missing metrological checks"
      hook={useNonConformMetrologicalCheckDeviceList}
      tagRenderer={MetrologicalCheckTagRenderer}
      tableRenderer={NonConfirmingDeviceTableMetrologicalCheck}
      mode="metrological_checks"
    />
  );
};

const NonConfirmingDeviceTableMaintenance = NonConfirmingDeviceTableFactory(
  EmployeeColumn<T>(
    "device_park_resp_id",
    "Reponsible",
    // @ts-ignore
    "device_park.maintenance_manager_id",
    (e) => e.device_park?.maintenance_manager
  )
);

export const NonConformMaintenanceDeviceListCard = () => {
  return (
    <DeviceNonConformCard
      header="Overdue or missing maintenances"
      hook={useNonConformMaintenanceDeviceList}
      tagRenderer={MaintenanceTagRenderer}
      tableRenderer={NonConfirmingDeviceTableMaintenance}
      mode="maintenances"
    />
  );
};
