import { ProposeSigneesModal } from "nestor/components/modals/ProposeSignees";
import { useHasPermission, usePermissionRefresh } from "nestor/contexts/NestorPermissionContext";
import { NestorPOST, useNestorAPI, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { useOrderChangeState } from "nestor/hooks/api/useOrderSingle";
import NestorServerRoutes from "nestor/server_routes";
import settings from "settings";
import { NestorDivision } from "nestor/types/company/division";
import { NestorInvoiceType } from "nestor/types/purchasing/invoice";
import {
  NestorOrder,
  NestorOrderId,
  NestorOrderState,
  NestorOrderTransferingType,
} from "nestor/types/purchasing/orders";
import { NestorDateTimeString } from "nestor/types/util/dates";
import assert from "assert";
import { useConfirmationModal } from "@csem/shared-utils";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";

const NumberBadge: React.FunctionComponent<{
  number: string;
  backgroundColor: string;
  done: boolean;
  active: boolean;
  clickable: boolean;
}> = (props) => {
  return (
    <div
      style={{ fontSize: "40px", width: "65px", height: "65px", cursor: props.clickable ? "pointer" : "default" }}
      className={`rounded-circle ${
        props.active
          ? `bg-${props.backgroundColor} text-white`
          : props.done
          ? `bg-${props.backgroundColor}-25 text-black`
          : `border border-${props.backgroundColor} bg-${props.backgroundColor}-10 text-black`
      } text-center fw-bold ${props.clickable ? `border border-${props.backgroundColor} border-2` : ""} ${
        props.clickable || props.active ? "" : "text-muted"
      }`}
    >
      {props.number}
    </div>
  );
};

const NumberBadgeWithDate: React.FunctionComponent<{
  number: string;
  hover?: string;
  text: string;
  active: boolean;
  date: NestorDateTimeString | null;
  done: boolean;
  theme: string;
  back?: boolean;
  backCallback?: Function;

  forward?: boolean;
  forwardCallback?: Function;
  canClick?: boolean;
  clickCallback?: Function;
}> = (props) => {
  const [mouseIn, setMouseIn] = useState<boolean>(false);

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="mx-4 text-center">
        <div
          className="d-flex flex-column align-items-center"
          onMouseEnter={() => setMouseIn(!!props.canClick)}
          onMouseLeave={() => setMouseIn(false)}
          onClick={() => props.clickCallback?.()}
        >
          <NumberBadge
            number={mouseIn ? (props.hover ? props.hover : "✓") : props.number}
            clickable={!!props.canClick}
            backgroundColor={props.theme}
            active={props.active}
            done={props.done}
          />
          <p className="mt-1">
            {props.active ? (
              <strong>{props.text}</strong>
            ) : props.done ? (
              <span>{props.text}</span>
            ) : (
              <span className="text-muted">{props.text}</span>
            )}
            <br />
            <span className="text-muted">
              <small>{props.date == null ? " " : moment(props.date).format("YYYY-MM-DD")}&nbsp;</small>
            </span>
          </p>
        </div>
      </div>

      <div className="">
        {/*props.forward ? (
          <button
            onClick={() => props.forwardCallback?.()}
            className="btn border border-success text-success bg-success bg-opacity-10"
          >
            <em className="bi bi-caret-right-fill"></em>
          </button>
        ) : null*/}
      </div>
    </div>
  );
};

export const useOrderChangeStateCb = function useOrderChangeStateCb(orderId: NestorOrderId, refresh: Function) {
  const permissionRefresh = usePermissionRefresh();
  assert(permissionRefresh);

  const useChangeState = useOrderChangeState(orderId);
  const changeStateDoQuery = useChangeState.doQuery;

  const changeState = useCallback(
    (stage: number) => {
      changeStateDoQuery(stage).then(() => {
        permissionRefresh?.();
        refresh();
      });
    },
    [changeStateDoQuery, refresh, permissionRefresh]
  );

  return changeState;
};

enum ProgressTheme {
  ENGINEER = "primary",
  BACKOFFICE = "warning",
  OTHER = "secondary",
}

const autoTrigger = ["goods_received"];

const NestorOrderProgress = ({ order, refresh }: { order: NestorOrder; refresh: Function }) => {
  const permissionRefresh = usePermissionRefresh();
  assert(permissionRefresh);

  useEffect(() => {
    //    permissionRefresh();
  }, [permissionRefresh]);

  const changeState = useOrderChangeStateCb(order.id, refresh);

  const apiCallGoodsReceived = useNestorAPI<any, boolean>(
    (received: boolean | undefined) => {
      if (received === undefined) {
        return false;
      }
      return NestorServerRoutes.order_goods_received(order.id, received);
    },
    autoTrigger,
    undefined
  );

  const sapMissingModal = useConfirmationModal<number>(
    "SAP number missing",
    <>
      <div className="text-danger">
        <strong>WARNING !</strong> The SAP number is missing. You may proceed, but make sure the ordered amount is
        within what is allowed by your BU for orders without an SAP number. <br />
        <strong>Are you sure you want to proceed ?</strong>
      </div>
    </>,
    (v) => changeState(v),
    "Could not change the order state"
  );

  const send_to_processing = useConfirmationModal<NestorOrder>(
    "Are you sure ?",
    (param) => {
      return "This will send your order over to the backoffice for processing. This means you have entered all articles and all other costs associated with your purchasing. Do you confirm ?";
    },
    useCallback(() => {
      changeState(NestorOrderState.PROCESSING);
    }, [changeState]),
    "Could not change the order status"
  );

  // Go to stage 2
  const canReject = useHasPermission("can_return_edit");

  // Go to stage 3
  const canSendSecretary = useHasPermission("can_send_secretary");
  const canReturnToProcessing = useHasPermission("can_return_processed");

  // Go to stage 5
  const canValidate = useHasPermission("can_validate");
  const canReturnToProcessed = useHasPermission("can_return_transferring");

  // Go to stage 6
  const canTransfer = useHasPermission("can_transfer");

  // Go to stage 7
  const canFinalize = useHasPermission("can_finalize");

  const bu = order.division_id;

  const goods_received_resp =
    settings.division_settings[bu as NestorDivision].goods_received_responsability ||
    (order.contact === "backoffice" ? NestorOrderTransferingType.SECRETARY : NestorOrderTransferingType.HANDLER);
  const hasFinalInvoice = !!order.invoices?.find(
    (e) => e.type === NestorInvoiceType.FINAL || e.type === NestorInvoiceType.FULL
  );

  return (
    <div className="csem-progress">
      <div className="row csem-progress-vbar">
        <div className={`col ${order.state > -1 ? "active" : ""}`}></div>
        <div className={`col ${order.state > -1 ? "active" : ""}`}></div>
        <div className={`col ${order.state > -1 ? "active" : ""}`}></div>
        <div className={`col ${order.state > 0 ? "active" : ""}`}></div>
        <div className={`col ${order.state > 0 ? "active" : ""}`}></div>
        <div className={`col ${order.state > 10 ? "active" : ""}`}></div>
        <div className={`col ${order.state > 10 ? "active" : ""}`}></div>
        <div className={`col ${order.state > 30 ? "active" : ""}`}></div>
        <div className={`col ${order.goods_received ? "active" : ""}`}></div>
        <div className={`col ${order.goods_received ? "active" : ""}`}></div>
        <div className={`col ${hasFinalInvoice ? "active" : ""}`}></div>
        <div className={`col ${hasFinalInvoice ? "active" : ""}`}></div>
        <div className={`col ${order.state > 40 ? "active" : ""}`}></div>
        <div className={`col ${order.state > 40 ? "active" : ""}`}></div>
      </div>
      <div className="row csem-progress-steps">
        <div className="col-md-12 mb-4 px-4 ">
          {sapMissingModal.Modal}
          {send_to_processing.Modal}
          <div className="row">
            <div className="col">
              <NumberBadgeWithDate
                number="✓"
                text="Created"
                theme={ProgressTheme.ENGINEER}
                date={order.created_at}
                active={false}
                done={true}
              />
            </div>
            <div className="col">
              <NumberBadgeWithDate
                number={order.state > 0 ? "✓" : "2"}
                text={order.state === 0 ? "Editing" : "Information complete"}
                hover={!canSendSecretary ? "⮦" : "✓"}
                date={null}
                done={order.state > NestorOrderState.EDIT}
                canClick={canReject || canSendSecretary}
                forward={canSendSecretary}
                theme={ProgressTheme.ENGINEER}
                //buttonText="Send to secretaries"
                //buttonIcon="arrow-right"
                active={order.state === NestorOrderState.EDIT}
                clickCallback={() => {
                  if (!canSendSecretary) {
                    changeState(NestorOrderState.EDIT);
                  }

                  send_to_processing.invoke(order);
                }}
              />{" "}
            </div>

            {!order.is_administrative ? (
              <>
                <div className="col">
                  <NumberBadgeWithDate
                    number={order.state > 10 ? "✓" : "3"}
                    text={
                      order.state === 0
                        ? "No yet processed"
                        : order.state === 10
                        ? "Processing by backoffice"
                        : "Processed by backoffice"
                    }
                    hover={!canValidate ? "⮦" : "✓"}
                    theme={ProgressTheme.BACKOFFICE}
                    date={order.ready_at}
                    done={order.state > NestorOrderState.PROCESSING}
                    active={order.state === NestorOrderState.PROCESSING}
                    back={canReject}
                    canClick={canValidate || canReturnToProcessing}
                    //  buttonText={"Reject. Back to engineer"}
                    //  buttonIcon={"arrow-90deg-left"}
                    forward={canValidate}
                    clickCallback={() =>
                      changeState(canValidate ? NestorOrderState.WAITING_SEND : NestorOrderState.PROCESSING)
                    }
                    //forwardCallback={() => changeState(30)}
                  />
                </div>
                {/*}  <div className="col">
              {" "}
              <NumberBadgeWithDate
                number="4"
                text="Signing / Validating"
                theme={ProgressTheme.BACKOFFICE}
                date={order.validated_at}
                done={order.state > 10}
                active={order.state === NestorOrderState.WAITING_SEND}
                //back={canReturnToProcessing}
                //backCallback={() => changeState(10)}
                //forward={canValidate}

                canClick={canValidate || canReturnToProcessed}
                clickCallback={() => changeState( canValidate ? NestorOrderState.WAITING_SEND : NestorOrderState.WAITING_SEND )}
                // button={canValidate || canReturnToProcessing}
                // buttonText={canValidate ? "Processed. Ready for transfer" : "Undo"}
                // buttonIcon={canValidate ? "arrow-right" : "arrow-90deg-left"}
              />
            </div>
        */}
                <div className="col">
                  <NumberBadgeWithDate
                    number={order.state > 30 ? "✓" : "4"}
                    text={order.state === 30 ? "To be sent to supplier" : "Sent to supplier"}
                    hover={!canTransfer ? "⮦" : "✓"}
                    theme={
                      order.transferring === NestorOrderTransferingType.HANDLER
                        ? ProgressTheme.ENGINEER
                        : ProgressTheme.BACKOFFICE
                    }
                    date={order.transferred_at}
                    active={order.state === NestorOrderState.WAITING_SEND}
                    done={order.state > NestorOrderState.WAITING_SEND}
                    canClick={canTransfer || canReturnToProcessed}
                    //buttonText={canTransfer ? "Transferred" : "Undo"}
                    //buttonIcon={canTransfer ? "arrow-right" : "arrow-90deg-left"}
                    clickCallback={() =>
                      changeState(canTransfer ? NestorOrderState.WAITING_CLOSE : NestorOrderState.WAITING_SEND)
                    }
                  />
                </div>
              </>
            ) : null}
            <div className="col">
              <NumberBadgeWithDate
                number={!!order.goods_received ? "✓" : "✕"}
                hover={!!order.goods_received ? "✕" : "✓"}
                text="Goods received"
                theme={
                  goods_received_resp === NestorOrderTransferingType.SECRETARY
                    ? ProgressTheme.BACKOFFICE
                    : ProgressTheme.ENGINEER
                }
                date={order.finalized_at}
                done={order.state > 30}
                canClick={order.state > 20}
                active={!!order.goods_received}
                //forward={canFinalize}
                //buttonText="Goods are received. Terminate"
                //buttonIcon="arrow-right"
                clickCallback={() => {
                  apiCallGoodsReceived.doQuery(!order.goods_received).then(() => {
                    refresh();
                  });
                }}
              />
            </div>
            {/*
ALTER TABLE `stock`.`orders` 
ADD COLUMN `goods_received` TINYINT(1) NULL AFTER `old_type`;

        */}
            <div className="col">
              <NumberBadgeWithDate
                number={hasFinalInvoice ? "✓" : "✕"}
                active={false}
                text="Invoices entered"
                theme={ProgressTheme.BACKOFFICE}
                date={order.finalized_at}
                done={order.state > 30}
                //forward={canFinalize}
                //buttonText="Goods are received. Terminate"
                //buttonIcon="arrow-right"
                //forwardCallback={() => changeState(100)}
              />
            </div>
            <div className="col">
              <NumberBadgeWithDate
                number={order.state > 40 ? "✓" : "5"}
                text={order.state > 40 ? "Terminated" : "Terminate"}
                theme={
                  goods_received_resp === NestorOrderTransferingType.HANDLER
                    ? "dbl-" + ProgressTheme.ENGINEER + "-" + ProgressTheme.BACKOFFICE
                    : ProgressTheme.BACKOFFICE
                }
                date={order.finalized_at}
                done={order.state > 40}
                canClick={canFinalize}
                active={order.state === NestorOrderState.FINALIZED}
                //buttonText="Goods are received. Terminate"
                //buttonIcon="arrow-right"
                clickCallback={() => changeState(NestorOrderState.FINALIZED)}
              />
            </div>
          </div>

          <div className="d-flex">
            <div
              className={`border-${ProgressTheme.BACKOFFICE} bg-${ProgressTheme.BACKOFFICE} bg-opacity-50 me-1`}
              style={{ width: "20px", height: "20px" }}
            ></div>{" "}
            <div>Backoffice</div>
            <div
              className={`border-${ProgressTheme.ENGINEER} bg-${ProgressTheme.ENGINEER} bg-opacity-50 ms-3 me-1`}
              style={{ width: "20px", height: "20px" }}
            ></div>{" "}
            <div>Engineer / Initiator</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NestorOrderProgress;
