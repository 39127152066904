import { useMaintenanceList } from "nestor/hooks/api/useMaintenanceList";
import { NestorDeviceMaintenance } from "nestor/types/inventory/device_maintenance";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { MaintenanceRenderer } from "../../renderers/Maintenance";
import { NestorMaintenance } from "nestor/types/inventory/maintenance";

export const useMaintenanceSearch = FormSearchHookFactory<NestorMaintenance, any>({
  apiListFactory: useMaintenanceList,
  template: GenericListRenderer((el) => <MaintenanceRenderer maintenance={el} />),
  traverser_display: (el) => {
    return el.name + " (" + el.periodicity_days + " days)";
  },
  uniqueKeyField: "id",
  filterKeys: {
    name: "contains",
  },
});

export const useDeviceMaintenanceSearch = FormSearchHookFactory<NestorDeviceMaintenance, NestorDeviceMaintenance["id"]>(
  {
    template: GenericListRenderer((el) => (
      <MaintenanceRenderer maintenance={el.maintenance} fallbackText="No maintenance information" />
    )),
    traverser_display: (el) => {
      return el.maintenance?.name + " (" + el.maintenance?.periodicity_days + " days)";
    },
    uniqueKeyField: "id",
    filterKeys: {
      "maintenance.name": "contains",
    },
  }
);
