import { NestorPart } from "nestor/types/stock/part";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { FormSearchOneHookFactory } from "@csem/lists";

export const PartRenderer = ({ part }: { part: NestorPart }) => {
  return (
    <>
      <strong>{part.manufacturer_identification}</strong>
      <br />
      <span className="text-muted">{part.description}</span>
    </>
  );
};

export const usePartSearchField = FormSearchHookFactory<NestorPart, NestorPart["id"]>({
  template: GenericListRenderer((el) => <PartRenderer part={el} />),
  filterKeys: {
    description: "contains",
    manufacturer_identification: "contains",
  },
  traverser_display: (el) => {
    return el.manufacturer_identification || el.description;
  },
  uniqueKeyField: "id",
});

export const useOnePartSearchField = FormSearchOneHookFactory<NestorPart, NestorPart["id"]>({
  uniqueKeyField: "id",
  searchKeyField: "manufacturer_identification",
});
