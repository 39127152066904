import { NestorRoom, NestorRoomId } from "../company/room";
import { NestorDateTimeString } from "./dates";

export type NestorLabelPrinterId = number;

export enum LabelPrinterSizes {
    SZ_9 = "9mm",
    SZ_12 = "12mm",
    SZ_24 = "24mm",
    SZ_36 = "36mm"
}

export type NestorLabelPrinter = {

    id: NestorLabelPrinterId,
    name: string,
    cups_name: string,
    room_id: NestorRoomId,
    room?: NestorRoom,

    comment: string,
    created_at: NestorDateTimeString,
    updated_at: NestorDateTimeString
}