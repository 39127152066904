export const QuantityRenderer = ({
  qty,
  unit,
  fixed,
}: {
  qty: number | string | null;
  unit?: string;
  fixed?: number;
}) => {
  let out: string;
  if (qty === null) {
    out = "??";
  } else {
    if (unit === "unit") {
      unit = "u.";
    }

    if (fixed && typeof qty == "number") {
      qty = qty.toFixed(fixed);
    }
    out = `${qty} ${unit && unit.length > 0 ? unit : "?"}`;
  }
  return <span>{out}</span>;
};
