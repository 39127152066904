import { useOrderList } from "nestor/hooks/api/useOrderList";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { GenericListRenderer } from "@csem/forms";
import { SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { OrderRenderer } from "../../renderers/OrderRenderer";

export const OrderColumn = <T,>(
  name: string,
  label: string,
  field: string,
  renderer: (el: T) => NestorOrder | null
) => {
  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <OrderRenderer order={renderer(el)} />;
    }),
    SearchElFilterFactory<NestorOrder, NestorOrder["id"]>({
      label: "Order",
      apiListFactory: useOrderList,
      traverser: (el) => el.id,
      filterKeys: {
        code: "contains",
        name: "contains",
      },
      template: GenericListRenderer((e) => <OrderRenderer order={e} />),
      key: "id",
    }),
    field
  );
};
