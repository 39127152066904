import { NestorRoom } from "nestor/types/company/room";
import { apiList, IOState } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";

type T = NestorRoom;
export const useRoomList = (all: boolean = false): apiList<T> => {
  let searchParams: URLSearchParams | undefined;
  if (all) {
    searchParams = new URLSearchParams([["all", "1"]]);
  }
  return useNestorListApiCall<T>(`list/company/rooms`, "rooms", searchParams);
};

export const useRoomSingle = (elId: number | string): NestorApiElement<NestorRoom, undefined> => {
  const apiCall = useNestorAPI<NestorRoom>(`company/rooms/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading room..." source={apiCall.state} success={null} />,
  };
};
