import { NestorRole } from "nestor/types/system/role";
import { useFormSelect } from "@csem/forms";
import { useFormMultipleSelect } from "@csem/forms";
import { useRolesList } from "../../nestor/company/employees/modals/Edit";

export function useFormRoleMultField(roles: Array<NestorRole> | undefined) {
  const rolesCall = useRolesList();

  const role_id = useFormMultipleSelect({
    label: "Roles",
    defaultValue: [],
    originalValue: roles,
    serverKey: "id",
    serverValue: "description",
    serverList: rolesCall,
  });

  return role_id;
}

export function useFormRoleField(role?: NestorRole) {
  const rolesCall = useRolesList();

  const role_id = useFormSelect<string | number | undefined, NestorRole>({
    label: "Roles",
    defaultValue: undefined,
    originalValue: role?.id,
    serverKey: "id",
    serverValue: "description",
    list: rolesCall.list,
  });

  return role_id;
}
