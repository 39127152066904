import { NestorPcbInspectionTest } from "nestor/types/manufacturing/pcb";
import { useFormSelectObj } from "@csem/forms";

export const usePcbVisualInspectionTest = function (
  originalValue: NestorPcbInspectionTest | undefined,
  defaultValue: NestorPcbInspectionTest | undefined
) {
  return useFormSelectObj({
    label: "Visual inspection",
    originalValue: originalValue,
    defaultValue: defaultValue,
    options: {
      done: NestorPcbInspectionTest.done,
      not_done: NestorPcbInspectionTest.not_done,
    },
  });
};
