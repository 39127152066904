import { useEmployeeSearchField } from "nestor/components/forms/fields/EmployeeSearch";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDevicePark } from "nestor/types/inventory/device_park";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

export const DeviceParkAddEditModal = StandardFormAddEditModalFactory<NestorDevicePark, any, any>({
  title: (el, fw) => (el ? `Edit the device park` : `Add a new park`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const responsible_id = useEmployeeSearchField({
      label: "Responsible",
      originalValue: el?.responsible_id,
      validationOnChange: useFieldOptRequired(true),
      defaultValue: undefined,
    });

    const calibration_manager_id = useEmployeeSearchField({
      label: "Reponsible for calibration",
      originalValue: el?.calibration_manager_id,
      validationOnChange: useFieldOptRequired(true),
      defaultValue: undefined,
    });

    const metrological_check_manager_id = useEmployeeSearchField({
      label: "Responsible for metrological checks",
      originalValue: el?.metrological_check_manager_id,
      validationOnChange: useFieldOptRequired(true),
      defaultValue: undefined,
    });

    const maintenance_manager_id = useEmployeeSearchField({
      label: "Responsible for maintenances",
      originalValue: el?.maintenance_manager_id,
      validationOnChange: useFieldOptRequired(true),
      defaultValue: undefined,
    });

    const apiResult = useNestorAPI(
      !el ? NestorServerRoutes.device_park_create() : NestorServerRoutes.device_park_update(el.id),
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,
        responsible_id: responsible_id.value,
        calibration_manager_id: calibration_manager_id.value,
        metrological_check_manager_id: metrological_check_manager_id.value,
        maintenance_manager_id: maintenance_manager_id.value,
      });
    };

    return {
      fields: {
        name,
        responsible_id,
        calibration_manager_id,
        metrological_check_manager_id,
        maintenance_manager_id,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
