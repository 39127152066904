// Components

// Contexts
import { ManufacturerRenderer } from "nestor/components/renderers/Manufacturer";
import { QuantityRenderer } from "nestor/components/renderers/QuantityRenderer";
import { ContainerColumn } from "nestor/components/tables/columns/ContainerColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { OrderColumn } from "nestor/components/tables/columns/OrderColumn";
import { useRefreshCall } from "nestor/contexts/NestorRefreshContext";
import { NestorSkuTransferContext } from "nestor/contexts/NestorSkuTransferContext";
import { useSkuCtnList } from "nestor/hooks/api/useSkuList";
import { NestorParcelId } from "nestor/types/purchasing/parcel";
import { NestorContainer } from "nestor/types/stock/container";
import { NestorPart } from "nestor/types/stock/part";
import { NestorSkuShortcut } from "nestor/types/stock/shortcut";
import { NestorSku } from "nestor/types/stock/sku";
import { NestorContainerSku } from "nestor/types/stock/sku_container";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { FilterOperator, HasTableFilterClear } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { NavigationColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { CollectionComponentProps, GenericListContent, HasRefresh, HasSearch } from "@csem/lists";
import { ToggleCheck, useOnChange } from "@csem/shared-utils";

import { cloneDeep } from "lodash";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
//# Tables
//## Nestor
import { DateColumn } from "../../../tables/columns/DateColumn";
import { LabelPrinterButton } from "../../util/LabelPrinter";
import { useZeroQties } from "./utils/ZeroQties";

type T = NestorContainerSku;
type FW = {
  setTransferValue?: (container: NestorContainer, sku: NestorSku, amount: number) => void;
};

type HOOK = {
  can_withdraw: boolean;
};

export const SKUExpiracyTag = (props: { sku?: NestorSku }) => {
  if (props.sku?.due != null && moment(props.sku.due).isBefore()) {
    return (
      <div className="d-block badge bg-danger" title={"Expired on the " + props.sku.due}>
        expired
      </div>
    );
  }
  return null;
};
export const GenericSkuContainerTable = (function () {
  return TableRenderer<T, FW, {}, HOOK, "id">({
    key: "id",
    columns: [
      NavigationColumn("_nav", "", (el) => {
        return `/stock/skus/${el.sku?.id}`;
      }),
      SimpleColumn("nuid", "NUID", (e) => (
        <>
          <SKUExpiracyTag sku={e.sku} />
          {e.sku?.nuid}
        </>
      )),
      SimpleColumn("barcode", "Nestor1", (e) => e.sku?.barcode),
      SimpleColumn("qty_ctn", "Qty in the container", (e, fw) => {
        return (
          <>
            <QuantityRenderer qty={e.quantity !== null ? e.quantity : "N/A"} unit={e.sku?.unit} />
          </>
        );
      }),
      SimpleColumn("article_ref", "Article reference", (e) => e.sku?.article?.reference),
      SimpleColumn("article_descr", "Article description", (e) => e.sku?.article?.description),
      SimpleColumn("manufacturer", "Manufacturer", (e) => {
        const orderable = e.sku?.article?.orderable as NestorPart;
        if (!orderable) {
          return null;
        }
        return (
          <ManufacturerRenderer manufacturer={orderable.manufacturer} id={orderable.manufacturer_identification} />
        );
      }),

      //  ProjectColumn("project", "Project", "sku.project_id", e => e.sku?.project || null),
      OrderColumn("order", "Order", "order_id", (e) => e.sku?.article?.order || null),

      ContainerColumn("container", "Container", "container_id", (e) => e.container, true),

      EmployeeColumn(
        "contact",
        "Employee",
        // @ts-ignore
        "sku.employee_id",
        (e) => e.sku?.employee
      ),
      DateColumn("created_at", "Created", "created_at", (e) => e.created_at || null),

      SimpleColumn("print", "Label", (el, fw) => {
        return <LabelPrinterButton size={LabelPrinterSizes.SZ_12} url={`stock/skus/${el.sku?.id}/label/pdf/print`} />;
      }),

      SimpleColumn("movement", "Movement", (el, fw, hk) => {
        const orderable = el.sku?.article?.orderable as NestorPart;
        let dividable: boolean;
        if (!el.container || !el.sku || !hk.can_withdraw) {
          return null;
        }

        if (!orderable) {
          // Backwards compat with Nestor 1
          dividable = false;
        } else {
          dividable = !!orderable.dividable;
        }
        if (dividable) {
          return (
            <div className="input-group">
              <input
                type="number"
                className="form-control"
                onChange={(e) => {
                  assert(el.sku && el.container);
                  let val = parseFloat(e.target.value);
                  if (isNaN(val)) {
                    val = 0;
                  }
                  fw.setTransferValue!(el.container!, el.sku!, val);
                }}
              />
              <span className="input-group-text">{orderable.dividable_unit}</span>
            </div>
          );
        } else {
          return (
            <input
              className="form-control"
              type="number"
              onChange={(e) => {
                let val = parseInt(e.target.value);
                if (isNaN(val)) {
                  val = 0;
                }
                assert(el.container && el.sku);
                fw.setTransferValue!(el.container, el.sku, val);
              }}
            />
          );
        }
      }),
    ],

    useColumnWidth: () => {
      return {
        barcode: "50px",
        qty_ctn: "90px",
        contact: "110px",
        movement: "60px",
        container: "180px",
        article_ref: "120px",
        order: "170px",
        created_at: "120px",
      };
    },

    useColumnVisibility: (cols, fw) => {
      let _cols: any = {};

      if (!fw.setTransferValue) {
        _cols.movement = false;
      }
      return _cols;
    },

    useLineParamInjection: (el, forwarded) => {
      return { can_withdraw: el.permissions?.can_withdraw || el.container?.permissions?.can_withdraw };
    },
  });
})();

export function HasScanSearch<FWCnt extends {}, FWTpl extends {}, TPL extends {}, T, V extends object = {}>(
  CollectionComponent: React.FunctionComponent<V & CollectionComponentProps<T, FWCnt, TPL, FWTpl>>,
  filterKeys: Record<string, FilterOperator>
) {
  const Comp = function (props: React.PropsWithChildren<V & CollectionComponentProps<T, FWCnt, TPL, FWTpl>>) {
    return (
      <CollectionComponent
        {...props}
        righthandContent={[
          ...(props.righthandContent || []),
          <div className="col-2" key="__search">
            <TableSearch
              label="Scanner"
              icon="qr-code-scan"
              filterKeys={filterKeys}
              filterLogic="or"
              clear={false}
              resetOnWrite={true}
            />
          </div>,
        ]}
      />
    );
  };

  return Comp;
}

export const GenericSkuListContainer = (function () {
  return HasTableFilterClear(
    HasSearch(
      HasScanSearch(HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())), {
        "sku.barcode": "eq",
        "sku.nuid": "eq",
        // "sku.article.description": "contains",
        // "sku.article.orderable:part.description": "contains",
        // "container.name": "contains",
        // "container.project.name": "contains",
        // "sku.article.reference": "contains",
        // "sku.employee.initials": "eq",
      }),
      {
        "sku.barcode": "eq",
        "sku.nuid": "eq",
        "sku.article.description": "contains",
        "sku.article.orderable:part.description": "contains",
        "container.name": "contains",
        "container.project.name": "contains",
        "sku.article.reference": "contains",
        "sku.employee.initials": "eq",
      },
      "or",
      "Full search",
      TableSearch
    )
  );
})();

export const SkuContainerListCard = ({
  parcel_id,
  ...props
}: {
  parcel_id?: NestorParcelId;
  sku?: NestorSku;
  sku_shortcut?: NestorSkuShortcut;
}) => {
  const ctx = useContext(NestorSkuTransferContext);
  const { sku } = props;

  const { zeroQty, ZeroQtiesToggler } = useZeroQties();

  // Preparing base filter based on the props
  const filter = useMemo(() => {
    const filter = cloneDeep(DefaultStandardTableFilter);

    // Looking for the spread of a particular SKU in its containers
    if (sku) {
      filter.filter.filters[0].filters.push({ field: "sku_id", operator: "eq", value: sku?.id });
    }

    // Listing all SKUs in the parcel
    if (parcel_id) {
      // This is a bit of a hack
      // Looking for the .package.id relationship results in an ambiguous column id
      filter.filter.filters[0].filters.push({
        field: "sku.sku_entry_inspection.package_id",
        operator: "eq",
        value: parcel_id,
      });
    }

    if (!zeroQty) {
      filter.filter.filters[0].filters.push({
        field: "quantity",
        operator: "gt",
        value: 0,
      });
    }

    filter.sort = [{ field: "created_at", dir: "desc" }];
    return filter;
  }, [sku, parcel_id, zeroQty]);

  const [autoSelect, setAutoSelected] = useState<boolean>(false);

  const apiList = useSkuCtnList({
    sku_shortcut: props.sku_shortcut?.id,
  });
  const { list } = apiList;
  const setTransferValue = ctx?.setTransferValue;

  useOnChange(
    () => {
      if (autoSelect && setTransferValue && list && list.length < 10) {
        list.forEach((l) => {
          if (l.container && l.sku && l.quantity !== null) {
            setTransferValue(l.container, l.sku, l.quantity);
          }
        });
      }
    },
    [list],
    [autoSelect, setTransferValue]
  );

  useRefreshCall(apiList.refresh);

  return (
    <div className="my-2">
      <CardComponent header="SKU list">
        <div className="row my-2">
          <div className="col-3">{ZeroQtiesToggler}</div>
        </div>
        <StandardTableFilterCtxProvider defaultValue={filter}>
          <GenericSkuListContainer
            //cardHeader="Gateway list"
            list={apiList}
            fwProps={{ setTransferValue: ctx?.setTransferValue }}
            listTemplate={GenericSkuContainerTable}
            additionalContent={[]}
            righthandContent={[
              ctx && setTransferValue ? (
                <>
                  <button
                    type="button"
                    className={`btn btn-${!autoSelect ? "outline-" : ""}primary`}
                    onClick={() => {
                      setAutoSelected(!autoSelect);
                    }}
                  >
                    Auto-select all components
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      apiList.list?.forEach((l) => {
                        if (l.container && l.sku && l.quantity !== null) {
                          setTransferValue?.(l.container, l.sku, l.quantity);
                        }
                      });
                    }}
                    disabled={!apiList.list || apiList.list.length > 10}
                  >
                    Select all components
                  </button>
                </>
              ) : null,
            ]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
