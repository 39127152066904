import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { NestorManufacturerId } from "nestor/types/purchasing/manufacturer";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorDeviceModel;
export const useDeviceModelList = (manufacturer_id?: NestorManufacturerId | string): apiList<T> => {
  return useNestorListApiCall<T>(
    !manufacturer_id ? `list/inventory/device_models` : `list/company/manufacturers/${manufacturer_id}/device_models`,
    "device model"
  );
};
