import {
  extendLocationPayload,
  isLocationFilled,
  useLocationFields,
} from "nestor/components/forms/fields/LocationField";
import { DeviceTypeCategoryRenderer } from "nestor/components/renderers/DeviceType";
import { ManufacturerRenderer } from "nestor/components/renderers/Manufacturer";
import { useTriggerRefresh } from "nestor/contexts/NestorRefreshContext";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { NestorArticle, NestorOrderable } from "nestor/types/purchasing/article";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorParcelId } from "nestor/types/purchasing/parcel";
import { CardComponent } from "@csem/shared-utils";
import { FieldValueType } from "@csem/forms";
import { useFormTextField } from "@csem/forms";
import { TableFilterCtxProvider } from "@csem/lists";
import { EmptyRow } from "@csem/lists";
import { GenericListContentBasic, ListRendererProps } from "@csem/lists";
import React from "react";
import { apiList } from "@csem/api";
import { DeviceListCard } from "../../../inventory/devices/List";

type T = NestorArticle;
/*
type ValidationCancellation = {
  onValidated: (article_id: number, index: number, serialnumber: string) => void;
  onCanceled: (article_id: number, index: number) => void;
};
*/
type FWLst = {
  order: NestorOrder;
  parcel_id: NestorParcelId;
  location: any;
  disabled: boolean;
};

const ListContainer = GenericListContentBasic<FWLst, T>();
/*
const DeviceRowArticleEntry: React.FunctionComponent<
  {
    article: NestorArticle;
    index: number;
  } & ValidationCancellation
> = (props) => {
  const [validated, setValidated] = useState<boolean>(false);

  const serial = useFormTextField({
    label: "Serial number",
    disabled: validated,
    defaultValue: "",
    type: FieldValueType.STRING,
  });

  return (
    <tr>
      <td colSpan={5}>
        <div className="row align-items-middle">
          <div className="col-lg-3 col-6">{serial.Content}</div>
          <div className="col-2">
            <button
              className="h-100 btn btn-success mb-2"
              type="button"
              onClick={() => {
                if (!validated) {
                  props.onValidated(
                    props.article.id,
                    props.index,

                    serial.value
                  );
                } else {
                  props.onCanceled(props.article.id, props.index);
                }
                setValidated((v) => !v);
              }}
            >
              {!validated ? "Validate" : "Cancel"}
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};*/

const DeviceRowArticle: React.FunctionComponent<{
  disabled: boolean;
  order: NestorOrder;
  parcel_id: NestorParcelId;
  article: NestorArticle;
  location: any;
}> = (props) => {
  const model = props.article.orderable as NestorDeviceModel;

  const refresher = useTriggerRefresh();
  const serial = useFormTextField({
    label: "Serial number",
    disabled: props.disabled,
    defaultValue: "",
    type: FieldValueType.STRING,
  });

  const apiCall = useNestorAPI<any>(
    NestorServerRoutes.package_add_inventory(props.order.id, props.parcel_id),
    false,
    undefined,
    NestorPOST
  );

  return (
    <tr>
      <td>{props.article.reference}</td>
      <td>{props.article.description}</td>
      <td>
        <ManufacturerRenderer manufacturer={model.manufacturer} id={model.model} />
      </td>
      <td>
        <DeviceTypeCategoryRenderer type={model.type}></DeviceTypeCategoryRenderer>
      </td>
      <td>{serial.Content}</td>
      <td>
        <button
          disabled={props.disabled}
          className="w-100 btn btn-primary"
          type="button"
          onClick={() => {
            apiCall
              .doQuery(undefined, {
                article_id: props.article.id,
                location: props.location,
                serialnumber: serial.value,
              })
              .then(function () {
                refresher();
              });
          }}
        >
          Add
        </button>
      </td>
    </tr>
  );
};

const DeviceTable: React.FunctionComponent<ListRendererProps<T, FWLst, {}>> = (props) => {
  return (
    <table className="csem-table table table-striped">
      <thead>
        <tr>
          <th>Reference</th>
          <th>Description</th>
          <th>Manufacturer</th>
          <th>Type</th>
          <th>Serial number</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {props.list.list?.map((e) => {
          if (e.orderable_type !== NestorOrderable.DEVICE) {
            return null;
          }

          return (
            <DeviceRowArticle
              order={props.fwProps.order}
              parcel_id={props.fwProps.parcel_id}
              key={e.id}
              article={e}
              disabled={props.fwProps.disabled}
              location={props.fwProps.location}
            />
          );
        })}

        <EmptyRow span={5} when={props.list.list && props.list.list.length === 0} />
      </tbody>
    </table>
  );
};

export const StockEntryDevice: React.FunctionComponent<{
  order: NestorOrder;
  parcel_id: NestorParcelId;
  apiListArticles: apiList<NestorArticle>;
  // setStore: React.Dispatch<React.SetStateAction<EntryStore>>;
}> = (props) => {
  const location_f = useLocationFields(undefined, true);
  // const setStore = props.setStore;
  /*const onValidated = useCallback(
    (article_id: number, index: number, serialnumber: string) => {
      setStore((s) => {
        let devices = s.devices.filter((d) => {
          // Remove that particular article from that particular index from the device store
          return d.article_id !== article_id || d.key !== index;
        });
        devices = [
          ...devices,
          // And add it again here
          { location: getLocationValue(location_f), article_id, key: index, serialnumber },
        ];
        return { ...s, devices };
      });
    },
    [setStore, location_f]
  );
*/

  /*
  const onCanceled = useCallback(
    (article_id: number, index: number) => {
      setStore((s) => {
        let devices = s.devices.filter((d) => {
          return d.article_id !== article_id && d.key !== index;
        });
        return { ...s, devices };
      });
    },
    [setStore]
  );
*/
  if (
    !props.apiListArticles.list ||
    !props.apiListArticles.list.find((e) => {
      if (e.orderable_type === NestorOrderable.DEVICE) {
        return e;
      }
      return undefined;
    })
  ) {
    return null;
  }

  return (
    <>
      <DeviceListCard parcel_id={props.parcel_id}></DeviceListCard>

      <div className="mb-3">
        <CardComponent header={"Inventory items for order " + props.order.nuid}>
          <p>Place the received items at the following location</p>
          {location_f.SwitchButtons}
          {location_f.Field}

          <TableFilterCtxProvider>
            <ListContainer
              fwProps={{
                order: props.order,
                parcel_id: props.parcel_id,
                location: extendLocationPayload({}, location_f),
                /*   onValidated,
              onCanceled,*/
                disabled: !isLocationFilled(location_f),
              }}
              listTemplate={DeviceTable}
              list={props.apiListArticles}
            />
          </TableFilterCtxProvider>
        </CardComponent>
      </div>
    </>
  );
};
