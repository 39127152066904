import NestorServerRoutes from "nestor/server_routes";
import { NestorContainerId } from "nestor/types/stock/container";
import { NestorSkuShortcutId } from "nestor/types/stock/shortcut";
import { NestorSku, NestorSkuId } from "nestor/types/stock/sku";
import { NestorContainerSku } from "nestor/types/stock/sku_container";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorSku;

export const useSkuList = (props?: {
  containerId?: NestorContainerId;
  derivedFromSkuId?: NestorSkuId;
  expired?: boolean;
}): apiList<T> => {
  let url: string;
  if (props?.containerId) {
    url = NestorServerRoutes.sku_list_container(props.containerId);
  } else if (props?.derivedFromSkuId) {
    url = NestorServerRoutes.sku_derived_list(props.derivedFromSkuId);
  } else if (props?.expired) {
    url = NestorServerRoutes.sku_list_expired();
  } else {
    url = NestorServerRoutes.sku_list();
  }
  return useNestorListApiCall<T>(url, "sku");
};

export const useSkuCtnList = (props?: { sku_shortcut?: NestorSkuShortcutId }): apiList<NestorContainerSku> => {
  return useNestorListApiCall<NestorContainerSku>(
    props?.sku_shortcut ? NestorServerRoutes.sku_shortcut_skus(props?.sku_shortcut) : `list/stock/skus_ctn`,
    "sku"
  );
};
