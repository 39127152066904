import useMaintenancePeriodicityField from "nestor/components/forms/fields/MaintenancePeriodicity";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorMaintenance } from "nestor/types/inventory/maintenance";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";

import "@csem/forms/dist/index.css";
import { useFormQuillField } from "@csem/forms";

export const MaintenanceAddEditModal = StandardFormAddEditModalFactory<NestorMaintenance, any, any>({
  title: (el, fw) => (el ? `Edit the maintenance ${el.name}` : `Add a new maintenance`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const periodicity_days = useMaintenancePeriodicityField(el?.periodicity_days, true);

    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const description = useFormQuillField({
      label: "Description",
      originalValue: el?.description,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const apiResult = useNestorAPI(
      !el ? `inventory/maintenances/forms/create` : `inventory/maintenances/forms/${el.id}/update`,
      false,
      undefined,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        periodicity_days: periodicity_days.value,
        name: name.value,
        description: description.value,
      });
    };

    return {
      fields: {
        name,
        description,
        periodicity_days,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
