import { TaskListCard } from "nestor/components/nestor/manufacturing/tasks/List";

export function TaskList() {
  return (
    <div className="container-fluid p-3">
      <TaskListCard mode="progress" header="Tasks in progress" />

      <TaskListCard mode="pending" header="Pending tasks" />
      <TaskListCard mode="completed" header="Completed tasks" />
    </div>
  );
}
