import { NestorDevice } from "nestor/types/inventory/device";

export const LoanButton = <T extends NestorDevice, FW>(label = "Select") => {
  return {
    title: "Loan",
    theme: "info",
    visibility: (e: T, fw: any) => {
      return !e.is_fixed && !e.active_loan && e.borrow_max_duration_days != null && e.borrow_max_duration_days > 0;
    },

    cb: (e: T, fw: FW & { onLoan?: (e: T, fw: FW) => void }) => {
      fw.onLoan?.(e, fw);
    },
  };
};
