// External dependencies
import { useDocumentList } from "nestor/hooks/api/useDocumentList";
// Hooks
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorAttachment, NestorAttachmentTag } from "nestor/types/system/attachment";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { EditButton, RemoveButton } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";

// Classes
// Components
// Contexts
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { useScanFileField } from "../../../forms/fields/ScanField";
import { DateColumn } from "../../../tables/columns/DateColumn";
import DownloadColumn, { DownloadAttachmentColumn } from "../../../tables/columns/DownloadColumn";
//# Tables
//## Nestor
import { EmployeeColumn } from "../../../tables/columns/EmployeeColumn";
import { AttachmentTagRenderer } from "./AttachmentTag";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { read } from "fs";

type T = NestorAttachment;
type FWLst = {
  type: string;
  id: string | number;
  readonly?: boolean;
};
type FW = RemoveMethod<T, FWLst> & EditMethod<T, FWLst>;
type HOOK = {};

const AddEditModal = StandardFormAddEditModalFactory<T, FWLst, any>({
  title: (el) => (el ? "Edit the document" : "Add a new document"),
  modalSize: "md",
  buttonValidate: (el?) => "Validate",
  useDefinition: (el, fw) => {
    const tag = useFormSelectObj({
      label: "Tag",
      defaultValue: undefined,
      originalValue: el?.tag || undefined,

      options: {
        [NestorAttachmentTag.DELIVERY_NOTE]: "Delivery note",
        [NestorAttachmentTag.DELIVERY]: "Delivery document",
        [NestorAttachmentTag.INCOMING_INSPECTION]: "Incoming inspection",
        [NestorAttachmentTag.METROLOGICAL_RECORD]: "Metrological record",
        [NestorAttachmentTag.METROLOGICAL_PROCEDURE]: "Metrological procedure",
        [NestorAttachmentTag.PICTURE]: "Picture",
        [NestorAttachmentTag.USER_MANUAL]: "User Manual",
        [NestorAttachmentTag.INVOICE]: "Invoice",

        [NestorAttachmentTag.PHOTO]: "Photo",
        [NestorAttachmentTag.INSPECTION]: "Inspection",
        [NestorAttachmentTag.REPORT]: "Report",
        [NestorAttachmentTag.MANUAL]: "Manual",
        [NestorAttachmentTag.PO]: "Purchase order",
        [NestorAttachmentTag.ORDER_CONFIRMATION]: "Order confirmation",
        [NestorAttachmentTag.CERTIFICATE_CONFORIMITY]: "Certificate of conformity",
        [NestorAttachmentTag.OFFER]: "Offer",
        [NestorAttachmentTag.OTHER]: "Other",

        [NestorAttachmentTag.QUALIFICATION_REPORT]: "Qualification report",
        [NestorAttachmentTag.QUALITY_AGREEMENT_PLAN]: "Quality agreement",
        [NestorAttachmentTag.CERTIFICATE]: "Other certificate",
        [NestorAttachmentTag.CALIBRATION_CERTIFICATE]: "Calibration certificate",
        [NestorAttachmentTag.MINUTES_OF_MEETING]: "Minutes of meeting",
        [NestorAttachmentTag.MSDS]: "MSDS",

        [NestorAttachmentTag.EF611]: "EF611",
      },
      validationOnChange: { required: true },
    });

    const comment = useFormTextField({
      label: "Comment",
      originalValue: el?.comment || "",
      defaultValue: "",
      type: FieldValueType.STRING,
    });

    const { scan, file } = useScanFileField(el);

    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name || (file.value instanceof File ? file.value.name : ""),
      defaultValue: "",
      type: FieldValueType.STRING,
      validationOnChange: { required: true },
    });

    const apiResult = useNestorAPI(
      !fw
        ? undefined
        : el
        ? `company/documents/${el.id}/forms/update`
        : `company/documents/${fw.type}/${fw.id}/forms/create`,
      false,
      undefined,
      {
        method: "POST",
        headers: {},
      }
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      let formData = new FormData();

      formData.append("comment", comment.value);
      formData.append("name", name.value);
      formData.append("tag", tag.value?.toString() || "");
      if (typeof scan.value === "number") {
        formData.append("scan_id", scan.value.toString());
      }
      if (file.value instanceof File) {
        // Could be a boolean
        formData.append("file", file.value);
      }

      if (el) {
        formData.append("id", el.id.toString());
      }

      apiResult.doQuery(undefined, formData);
    };

    return {
      fields: {
        file,
        scan,
        name,
        tag,
        comment,
      },

      out: apiResult.result,
      state: apiResult.state,
      submit: cb,
    };
  },
});

const GenericDocumentTable = (function () {
  return TableRenderer<T, FWLst, FW, HOOK, "id">({
    key: "id",
    columns: [
      DownloadAttachmentColumn((el) => el),

      SimpleColumn("tag", "Tag", (e) => AttachmentTagRenderer(e.tag)),

      SimpleColumn("name", "Name", (e) => e.name),

      SimpleColumn("comment", "Comment", (e) => e.comment),

      EmployeeColumn<T>("employee", "Employee", "employee_id", (e) => e.employee),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("actions", "", [
        EditButton(undefined, (e, fw) => {
          return fw?.readonly ? false : true;
        }),
        RemoveButton(undefined, (e, fw) => {
          return fw?.readonly ? false : true;
        }),
      ]),
    ],

    useLineParamInjection: () => {
      return {};
    },

    useColumnWidth: () => {
      return {
        download: "0px",
        actions: "0px",
      };
    },
  });
})();

const GenericDocumentListContainer = (function () {
  return HasStdTablePagination(
    HasRefresh(
      HasAddition(
        HasEdition(
          HasDeletion(
            GenericListContent<FWLst, T>(),

            "Confirm document deletion",
            (v: T) => `Are you sure to revoke this document ?`,
            (props) => {
              return useNestorAPI<undefined, NestorAttachment>(
                (d) => {
                  assert(d !== undefined);
                  return `company/documents/${d.id}`;
                },
                false,
                undefined,
                { method: "DELETE" },
                props.list.refresh
              );
            }
          ),
          AddEditModal
        ),
        AddEditModal,
        "Add a new document",
        "plus",
        "success",
        (fw) => {
          if (fw?.readonly) {
            return false;
          }
          return true;
        }
      )
    )
  );
})();

export const DocumentListCard = ({
  type,
  id,
  title = "Document list",
  readonly,
}: {
  type: string;
  id: string | number;
  title?: string;
  readonly?: boolean;
}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);

  return (
    <div className="mb-2">
      <CardComponent header={title}>
        {readonly && <div className="alert alert-warning">Readonly-mode ! You can't edit or add any document</div>}
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericDocumentListContainer
            //cardHeader="Gateway list"
            list={useDocumentList(type, id)}
            fwProps={{ type, id, readonly }}
            listTemplate={GenericDocumentTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
