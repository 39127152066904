import { useCallback } from "react";
import { LocationRenderer } from "../components/renderers/LocationRenderer";
import NestorServerRoutes from "../server_routes";
import { NestorLocation } from "../types/system/location";
import { useNestorAPI } from "./api/useNestorAPI";

export function useLocationStack(type: string, id: number) {
  const { doQuery, state, result } = useNestorAPI<Array<NestorLocation>>(
    NestorServerRoutes.resolve_location(id, type),
    false
  );
  const showLocation = useCallback(() => {
    doQuery();
  }, [doQuery]);

  const ShowLocationButton = (
    <button onClick={showLocation} className="btn btn-outline-secondary">
      <em className="bi bi-pin-map"></em>
    </button>
  );

  const LocationStack = state.success ? (
    <div className="text-small">
      <small>
        Location stack:
        <br />
        {result?.map((r) => (
          <div className="my-1 d-flex ">
            <div className="me-2">
              <em className="bi bi-arrow-return-right"> </em>
            </div>
            <div>
              <LocationRenderer location={r} fallbackText="No information"></LocationRenderer>
            </div>
          </div>
        ))}
      </small>
    </div>
  ) : null;

  return { ShowLocationButton, LocationStack };
}
