import { MaintenanceListCard } from "nestor/components/nestor/inventory/maintenances/List";
import { useMenuElement } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";

export function MaintenancesMain() {
  const { url } = useRouteMatch();

  useMenuElement("List of maintenances", url + "/list");

  return (
    <Switch>
      <Route path={`${url}/list`}>
        <MaintenanceListCard></MaintenanceListCard>
      </Route>
      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
