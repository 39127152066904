import { DateRenderer } from "nestor/components/tables/columns/DateColumn";
import { useMaintenanceListDevice } from "nestor/hooks/api/useMaintenanceList";
import { NestorDevice } from "nestor/types/inventory/device";
import { CardComponent } from "@csem/shared-utils";

export const MaintenanceListCard = (props: { device: NestorDevice }) => {
  const apiCall = useMaintenanceListDevice(props.device.id);

  return (
    <CardComponent header="Maintenances">
      {apiCall.loadingIO}

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Periodicity</th>
            <th>Comment</th>
            <th>From</th>
          </tr>
        </thead>

        <tbody>
          {apiCall.list?.map((el) => {
            return (
              <tr key={el.id}>
                <td>{el.maintenance?.name}</td>
                <td>{el.maintenance?.description}</td>
                <td>{el.maintenance?.periodicity_days} days</td>
                <td>{el.comment}</td>

                <td>{el.created_at ? <DateRenderer el={el.created_at} /> : null}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </CardComponent>
  );
};
