import { NestorOrder } from "nestor/types/purchasing/orders";
import moment from "moment";
import { Link } from "react-router-dom";
import { MonetaryAmount } from "../nestor/util/MonetaryAmountRenderer";

export const OrderRenderer = ({ order, fallbackText }: { order?: NestorOrder | null; fallbackText?: string }) => {
  if (!order) {
    return <div className="text-muted">{fallbackText || null}</div>;
  }
  return (
    <>
      <Link to={`/nestor/purchasing/orders/${order.id}`}>{order.nuid}</Link> (
      {moment(order.created_at).format("YYYY-MM-DD")})<div className="text-muted">{order.supplier?.name}</div>
      <div className="text-muted">
        SAP {order.sap_id} - <MonetaryAmount value={order.total} currency={order.currency} />
      </div>
    </>
  );
};
