import {
  ButtonsColumn,
  DefaultStandardTableFilter,
  EditButton,
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  HasSearch,
  RemoveButton,
  RemoveMethod,
  RevokeMethod,
  SimpleColumn,
  StandardTableFilterContext,
  StandardTableFilterCtxProvider,
  TableRenderer,
  TableSearch,
} from "@csem/lists";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { cloneDeep } from "lodash";
import { CriticalSupplierProjectAddEditModal } from "nestor/components/nestor/company/projects/modals/AddEditCriticalSupplier";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { ProjectColumn } from "nestor/components/tables/columns/ProjectColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useSupplierCriticalProjects } from "nestor/hooks/api/useSupplierQualification";
import { useAmIQCManager, useMe } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorEmployeeId } from "nestor/types/company/employee";
import { NestorProjectSupplier } from "nestor/types/projects/project_suppliers";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import {
  NestorSupplierQualification,
  NestorSupplierQualificationId,
} from "nestor/types/purchasing/supplier_qualification";
import { useMemo } from "react";

type T = NestorProjectSupplier;
type FW = {
  employee_id: NestorEmployeeId;
  qc_manager: boolean;
  supplier_qualification: NestorSupplierQualification;
  supplier: NestorSupplier | undefined;
};
type FWList = RemoveMethod<T, FW> & EditMethod<T, FW>;
const Table = (function () {
  return TableRenderer<T, FW, FWList, {}, "id">({
    key: "id",
    columns: [
      /* SupplierColumn("supplier", "Supplier", "supplier_id", (e) => e.supplier),*/
      ProjectColumn("project", "Project", "project_id", true, false, (e) => e.project),
      SimpleColumn("class", "Class", (e) => e.class),

      SimpleColumn("scope", "Scope", (e) => e.scope),
      EmployeeColumn("employee", "Employee", "employee_id", (e) => e.employee),
      ButtonsColumn("actions", "Actions", [
        EditButton("Edit", (e, fw) => e.project?.manager_id === fw.employee_id || fw.qc_manager),
        RemoveButton("Remove", (e, fw) => e.project?.manager_id === fw.employee_id || fw.qc_manager),
      ]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const Container = HasDeletion(
  HasAddition(
    HasEdition(
      HasSearch(
        HasRefresh(HasPagination(GenericListContent<FW, T>(), undefined, StandardTableFilterContext)),
        {
          "project.name": "contains",
          "project.code": "contains",
        },
        "or",
        "",
        TableSearch
      ),
      CriticalSupplierProjectAddEditModal
    ),
    CriticalSupplierProjectAddEditModal,
    "Link",
    "plus",
    "success",
    function (fw) {
      return true;
    }
  ),
  "Revoke",
  (v: T) => `Are you sure to revoke this criticality entry ?`,
  (props) => {
    return useNestorAPI<undefined, NestorProjectSupplier>(
      (el) => {
        assert(el !== undefined);
        return NestorServerRoutes.suppliers.criticalities.project_revoke(el.id);
      },
      false,
      undefined,
      { method: "DELETE" },
      props.list.refresh
    );
  }
);

export const SupplierQualificationProjectCard = (props: {
  supplier: NestorSupplier;
  qualification: NestorSupplierQualification;
}) => {
  const superuser = useAmIQCManager();
  const me = useMe();

  const defaultFilter = useMemo(() => {
    const cl = cloneDeep(DefaultStandardTableFilter);
    cl.filter.filters[0].filters.push({
      field: "supplier_qualification_id",
      operator: "eq",
      value: props.qualification.id,
    });

    return cl;
  }, [props.qualification.id]);

  return (
    <div className="mb-2">
      <CardComponent header="Qualification list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useSupplierCriticalProjects()}
            fwProps={useMemo(() => {
              return {
                supplier: props.supplier,
                qc_manager: superuser,
                employee_id: me.id,
                supplier_qualification: props.qualification,
              };
            }, [me, superuser, props.qualification, props.supplier])}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
