import { NestorConversation } from "nestor/types/util/conversation";
import { IOState } from "@csem/api";
import { useMemo } from "react";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorConversation;
const empty: Array<T> = [];
export const useNestorConversation = (type: string, id: string | number) => {
  const { state, result, doQuery: refresh } = useNestorAPI<T[]>(`conversations/${type}/${id}`, true);

  return {
    refresh,
    list: result || empty,
    loadingIO: useMemo(() => {
      return (
        <IOState
          source={state}
          success={null}
          successMessage={undefined}
          loadingMessage={"Loading conversation..."}
          errorMessage={`Error while loading the conversation`}
        />
      );
    }, [state]),
  };
};

export const useNestorConversationList = (type: string, id: string | number) => {
  return useNestorListApiCall<T>(`conversations/${type}/${id}/list`);
};
