import NestorServerRoutes from "nestor/server_routes";
import { NestorCalibration, NestorCalibrationId } from "nestor/types/inventory/calibration";
import { NestorDeviceId } from "nestor/types/inventory/device";
import { IOState } from "@csem/api";
import { apiList } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorCalibration;
export const useCalibrationList = (deviceId: NestorDeviceId): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.device_calibration_list(deviceId), "calibration");
};

export const useCalibrationSingle = (calibrationId: NestorCalibrationId): NestorApiElement<T, undefined> => {
  const apiCall = useNestorAPI<T>(NestorServerRoutes.device_calibration_single(calibrationId), true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading calibration..." source={apiCall.state} success={null} />,
  };
};
