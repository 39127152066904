import { useAddressFields } from "nestor/components/forms/bundles/AddressFields";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorAddress } from "nestor/types/company/address";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { FieldValueType, useFormTextField } from "@csem/forms";

export const AddressAddEditModal = StandardFormAddEditModalFactory<NestorAddress, any, any>({
  title: (el, fw) => (el ? `Edit the address` : `Add a new address`),

  buttonValidate: (el) => "Validate",
  modalSize: "md",
  useDefinition: (el, fw) => {
    const name = useFormTextField({
      label: "Name",
      originalValue: el?.name,
      type: FieldValueType.STRING,
      defaultValue: "",
      validationOnChange: { required: true },
    });

    const { address, address2, postal_code, city, country } = useAddressFields(el, true, true, true, true);

    const apiResult = useNestorAPI(
      !el ? NestorServerRoutes.address_create() : NestorServerRoutes.address_update(el.id),
      false,
      undefined,
      NestorPOST
    );

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        name: name.value,

        address: address.value,
        address2: address2.value,
        //        address3: address3.value,
        postal_code: postal_code.value,
        city: city.value,
        country: country.value,
      });
    };

    return {
      fields: {
        name,
        address,
        address2,
        //   address3,
        postal_code,
        city,
        country,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
