import { useTaskList } from "nestor/hooks/api/useTaskList";
import { NestorEmployee, NestorEmployeeId } from "nestor/types/company/employee";
import { NestorTask } from "nestor/types/manufacturing/task";
import { useAutoSearchTrigger } from "@csem/lists";
import assert from "assert";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { QuantityRenderer } from "nestor/components/renderers/QuantityRenderer";
import { EmployeeRenderer } from "nestor/components/renderers/EmployeeInfos";
import { ProjectRenderer } from "nestor/components/renderers/ProjectRenderer";
import { DateRenderer } from "nestor/components/tables/columns/DateColumn";
import { CardComponent } from "@csem/shared-utils";
import { DefaultStandardTableFilter } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";

export const TaskReport = () => {
  const tasks_in_progress = useTaskList("progress", true);
  const tasks_pending = useTaskList("pending", true);
  useAutoSearchTrigger(tasks_in_progress);
  useAutoSearchTrigger(tasks_pending);

  if (tasks_in_progress.state?.success && tasks_pending.state?.success) {
    const tasksByEmployees: Map<
      NestorEmployeeId,
      {
        employee: NestorEmployee;
        remaining_hours_total: number;
        remaining_hours_unblocked: number;

        tasks: Array<{
          task: NestorTask;
          remaining_hours: number;
        }>;
      }
    > = new Map();

    assert(tasks_in_progress.list);

    let workload_current = 0;

    tasks_in_progress.list.forEach((task) => {
      assert(task.progresses);

      //const total_executed_hours = task.progresses.reduce((p, t) => p + parseFloat(t.hours), 0);
      const remaining_hours = task.requested_hours * (1 - parseFloat(task.last_percentage.replace("%", "")) / 100);
      const remaining_hours_per_employee = remaining_hours / task.assignees.length;
      workload_current += remaining_hours;

      task.assignees.forEach((assignee) => {
        // If not assigned yet, assign it
        if (!tasksByEmployees.has(assignee.id)) {
          tasksByEmployees.set(assignee.id, {
            employee: assignee,
            remaining_hours_total: 0,
            remaining_hours_unblocked: 0,
            tasks: [],
          });
        }

        // Get that task element for future editing
        const taskEl = tasksByEmployees.get(assignee.id);

        // We know it's there, because in the control flow, we just assigned if it didn't exist
        assert(taskEl);

        taskEl.tasks.push({
          task,
          remaining_hours: remaining_hours_per_employee,
        });

        taskEl.remaining_hours_total += remaining_hours_per_employee;
        if (task.state === "progress" || task.state === "pending") {
          taskEl.remaining_hours_unblocked += remaining_hours_per_employee;
        }
      });
    });

    const workload_forecast = tasks_pending.list!.reduce((p, t) => p + t.requested_hours, 0);
    const num_employees = 3.7;
    const work_per_week = 38;

    const taskListByEmployees = Array.from(tasksByEmployees.entries());
    taskListByEmployees.sort((e1, e2) => (e1[1].employee.initials > e2[1].employee.initials ? 1 : -1));

    return (
      <div className="vstack gap-2">
        <div className="col-4">
          <CardComponent header="Summary">
            <div className="row">
              <div className="col-6">
                <h5>Current workload</h5>
              </div>
              <div className="col-6">
                <h5>
                  <QuantityRenderer qty={workload_current} unit="h" fixed={2} />
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <h5>Forecasted workload</h5>
              </div>
              <div className="col-6">
                <h5>
                  <QuantityRenderer qty={workload_forecast} unit="h" fixed={2} />
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <h5>Normalized forecast</h5>
              </div>
              <div className="col-6">
                <div>
                  <h5>
                    <QuantityRenderer
                      qty={(workload_current + workload_forecast) / (num_employees * work_per_week)}
                      fixed={2}
                      unit="Weeks"
                    />
                  </h5>
                </div>
                <div>
                  <small>
                    Based on {num_employees} employees at {work_per_week} h/week
                  </small>
                </div>
              </div>
            </div>
          </CardComponent>
        </div>
        <CardComponent header="Summary">
          <table className="table csem-table">
            <thead>
              <tr>
                <th>Remaining hours (unblocked)</th>
                <th>Remaining hours (total)</th>
                <th>Task ID</th>
                <th>Task name</th>
                <th>Project</th>
                <th>Duration (h)</th>
                <th>Priority</th>
                <th>Progress (%)</th>
              </tr>
            </thead>
            {taskListByEmployees.map(([k, v]) => {
              return (
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <EmployeeRenderer employee={v.employee} />
                    </td>
                  </tr>

                  {v.tasks.map((t) => {
                    return (
                      <tr>
                        <td>
                          <QuantityRenderer
                            qty={t.task.state === "progress" || t.task.state === "pending" ? t.remaining_hours : 0}
                            unit="h"
                            fixed={2}
                          />
                        </td>
                        <td>
                          <QuantityRenderer qty={t.remaining_hours} unit="h" fixed={2} />
                        </td>
                        <td>{t.task.nuid}</td>
                        <td>{t.task.name}</td>
                        <td>
                          <ProjectRenderer project={t.task.project || null} />
                        </td>
                        <td>
                          <QuantityRenderer qty={t.task.requested_hours} unit="h" />
                        </td>
                        <td>{t.task.priority}</td>
                        <td>{t.task.last_percentage}</td>
                      </tr>
                    );
                  })}

                  <tr className="bg-secondary bg-opacity-25">
                    <td>
                      <QuantityRenderer qty={v.remaining_hours_unblocked} fixed={2} unit="h" />
                    </td>
                    <td>
                      <QuantityRenderer qty={v.remaining_hours_total} fixed={2} unit="h" />
                    </td>
                    <td colSpan={6}></td>
                  </tr>
                </tbody>
              );
            })}
            <tbody>
              <tr>
                <th colSpan={8}>Pending tasks</th>
              </tr>
              {tasks_pending.list!.map((t) => {
                return (
                  <tr>
                    <td colSpan={2}>
                      <QuantityRenderer qty={t.requested_hours} unit="h" fixed={2} />
                    </td>
                    <td>{t.nuid}</td>
                    <td>{t.name}</td>
                    <td>
                      <ProjectRenderer project={t.project || null} />
                    </td>
                    <td>
                      <QuantityRenderer qty={t.requested_hours} unit="h" />
                    </td>
                    <td colSpan={2}>
                      <DateRenderer el={t.earliest_start_date} />
                    </td>
                  </tr>
                );
              })}

              <tr className="bg-secondary bg-opacity-25">
                <td colSpan={2}>
                  <QuantityRenderer qty={workload_forecast} unit="h" />
                </td>
                <td colSpan={6}></td>
              </tr>
            </tbody>
          </table>
        </CardComponent>
      </div>
    );
  }

  return null;
};

export const TaskReportMain = () => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);
    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    defaultFilter.take = 10000;
    return defaultFilter;
  }, []);

  return (
    <StandardTableFilterCtxProvider defaultValue={defaultFilter} enablePersistance={false}>
      <TaskReport></TaskReport>
    </StandardTableFilterCtxProvider>
  );
};
