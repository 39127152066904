import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorTask } from "nestor/types/manufacturing/task";
import assert from "assert";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useBTAdminFormFragment } from "../nestor/manufacturing/tasks/AddEdit";

export const GrantTaskModal = StandardFormAddEditModalFactory<NestorTask, any, any>({
  title: (el) => (!el ? "" : `Terminate the task`),
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    assert(el);

    const { priority, assignees } = useBTAdminFormFragment(el);

    const apiResult = useNestorAPI(`manufacturing/tasks/${el.id}/grant`, false, undefined, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    // TODO: This is re-evaluated at every render. Everytime a field changes value.
    // Very sub-optimal
    const cb = () => {
      apiResult.doQuery(undefined, {
        priority: priority.value,
        assignees: assignees.value,
      });
    };

    return {
      fields: {
        priority,
        assignees,
      },

      state: apiResult.state,
      out: apiResult.result,
      submit: cb,
    };
  },
});
