import { useOrderSkuList } from "nestor/hooks/api/useOrderSingle";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { GenericSkuList, GenericSkuTable } from "../../stock/skus/List";

export const SkuOrderListCard = (props: { order: NestorOrder }) => {
  return (
    <div className="my-2">
      <CardComponent header="SKU list (physical stock items) resulting from the purchased articles">
        <StandardTableFilterCtxProvider>
          <GenericSkuList
            //cardHeader="Gateway list"
            list={useOrderSkuList(props.order.id)}
            fwProps={{}}
            listTemplate={GenericSkuTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
