import { useContainerFieldWithCreate } from "nestor/components/forms/fields/ContainerSearch";

import { PCBLayoutRenderer } from "nestor/components/renderers/PCBLayoutRenderer";
import { useTriggerRefresh } from "nestor/contexts/NestorRefreshContext";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbLayout } from "nestor/types/manufacturing/pcb_layout";
import { NestorArticle, NestorArticleId, NestorOrderable } from "nestor/types/purchasing/article";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { NestorParcelId } from "nestor/types/purchasing/parcel";
import { NestorContainerId } from "nestor/types/stock/container";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContentBasic } from "@csem/lists";
import { apiState } from "@csem/api";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { apiList } from "@csem/api";
import { PCBListCard } from "../../../manufacturing/pcb/layouts/PCBList";

type T = NestorArticle;

type PCBEntry = {
  number: number;
  qa: "OK" | "NOK";
  variant: string;
  revision: string;
  parcel_id: number;
};

type FWLst = {
  // items: ItemsEntry;
  //  setItems: React.Dispatch<React.SetStateAction<ItemsEntry>>;
  parcel_id: NestorParcelId;
  order: NestorOrder;
  container_id?: NestorContainerId;
};

type FW = FWLst;
type HOOK = {
  entryState: PCBEntry;
  setEntryState: React.Dispatch<React.SetStateAction<PCBEntry>>;

  apiCallState: apiState;
  apiCallDoQuery: () => void;
  apiCallResult: any;
  disabled: boolean;
};

const ListContainer = GenericListContentBasic<FWLst, T>();

const PCBTable = (function () {
  return TableRenderer<T, FW, {}, HOOK, "id">({
    key: "id",
    columns: [
      SimpleColumn("ref", "Ref", (e) => e.reference),
      SimpleColumn("layout", "Layout", (e) => {
        return (
          <PCBLayoutRenderer layout={e.orderable as NestorPcbLayout} fallbackText="No information"></PCBLayoutRenderer>
        );
      }),

      SimpleColumn("qty_o", "Qty ordered", (e) => e.quantity_ordered),
      SimpleColumn("qa", "QA", (el, fw, hk) => {
        return (
          <select
            className="form-select"
            disabled={hk.disabled}
            value={hk.entryState.qa}
            onChange={(e) => {
              hk.setEntryState((s) => {
                return { ...s, qa: e.target.value as "OK" | "NOK" };
              });
            }}
          >
            <option value="OK">Ok</option>
            <option value="NOK">Not ok</option>
          </select>
        );
      }),

      SimpleColumn("variant", "Variant", (el, fw, hk) => {
        return (
          <input
            className="form-control"
            disabled={hk.disabled}
            value={hk.entryState.variant}
            onChange={(e) => {
              hk.setEntryState((s) => {
                return { ...s, variant: e.target.value };
              });
            }}
          />
        );
      }),

      SimpleColumn("revision", "Revision", (el, fw, hk) => {
        return (
          <input
            className="form-control"
            disabled={hk.disabled}
            value={hk.entryState.revision}
            onChange={(e) => {
              hk.setEntryState((s) => {
                return { ...s, revision: e.target.value };
              });
            }}
          />
        );
      }),

      SimpleColumn("num", "Quantity received", (el, fw, hk) => {
        return (
          <div className="input-group">
            <input
              type="number"
              step="1"
              min="0"
              className="form-control"
              disabled={hk.disabled}
              value={hk.entryState.number || 0}
              onChange={(e) => {
                let v = parseFloat(e.target.value);
                if (isNaN(v)) {
                  v = 0;
                }

                hk.setEntryState((s) => {
                  return { ...s, number: v };
                });
              }}
            />
          </div>
        );
      }),

      ButtonsColumn("actions", "", [
        {
          title: "Add",
          theme: "info",
          visibility: (e, fw, hk) => {
            return !hk.disabled && !hk.apiCallState.loading && hk.entryState.number > 0;
          },
          cb: (e, fw, hk) => {
            hk.apiCallDoQuery();
          },
        },
      ]),
    ],

    useLineParamInjection: (el, fw) => {
      const [lineState, setLineState] = useState<PCBEntry>({
        number: 0,
        qa: "OK",
        revision: "",
        variant: "",
        parcel_id: fw.parcel_id,
      });

      const apiCall = useNestorAPI<any>(NestorServerRoutes.pcb_add(el.orderable_id), false, undefined, NestorPOST);

      const refresher = useTriggerRefresh();

      const { doQuery } = apiCall;
      const { container_id } = fw;

      return {
        entryState: lineState,
        setEntryState: setLineState,
        apiCallState: apiCall.state,
        apiCallDoQuery: useCallback(() => {
          doQuery(undefined, {
            container_id: container_id,
            parcel_id: lineState.parcel_id,
            revision: lineState.revision,
            variant: lineState.variant,
            id: el.id,
            qa: lineState.qa,
            number: lineState.number,
            article_id: el.id,
            comment: "",
          }).then(() => {
            refresher();
          });
        }, [lineState, el.id, doQuery, container_id, refresher]),
        apiCallResult: apiCall.result,
        disabled: !fw.container_id || el.quantity_accounted >= el.quantity_ordered,
      };
    },

    useRowVisibility: (el) => {
      return el.orderable_type === NestorOrderable.PCB_LAYOUT;
    },
  });
})();

export const StockEntryPcbLayout: React.FunctionComponent<{
  order: NestorOrder;
  parcel_id: NestorParcelId;
  apiListArticles: apiList<NestorArticle>;
  //setStore: React.Dispatch<React.SetStateAction<EntryStore>>;
}> = (props) => {
  // const setStore = props.setStore;
  const { Content: ContainerContent, container_id } = useContainerFieldWithCreate();

  const apiArticles = props.apiListArticles;

  return (
    <>
      <PCBListCard parcel_id={props.parcel_id} />

      <div className="mb-3">
        <CardComponent header={"PCB assemblies for order " + props.order.nuid}>
          <div className="d-flex">{ContainerContent}</div>
          <ListContainer
            fwProps={{ order: props.order, container_id: container_id, parcel_id: props.parcel_id }}
            listTemplate={PCBTable}
            list={apiArticles}
          />
        </CardComponent>
      </div>
    </>
  );
};
