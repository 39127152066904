import { useAmIElevated } from "nestor/hooks/me";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { DevicesMain } from "./devices/Devices";
import { DevicesModelsMain } from "./device_models/DeviceModels";
import { DeviceParksMain } from "./device_parks/DeviceParks";
import { DeviceTypesMain } from "./device_types/DeviceTypes";
import { MaintenancesMain } from "./maintenances/Maintenances";
import { NonConformitiesMain } from "./non_conformities/NonConformities";
import { useEffect } from "react";

/**
 * Nestor Inventory router and menu handler
 */
export function InventoryMain() {
  const { url } = useRouteMatch();
  const devices = useMenuElement("Devices", url + "/devices");
  const device_types = useMenuElement("Types & Categories", url + "/device_types", useAmIElevated());
  const device_models = useMenuElement("Device models", url + "/device_models");
  const maintenances = useMenuElement("Maintenances", url + "/maintenances");

  const non_conformities = useMenuElement("Alerts", url + "/alerts");
  const device_park = useMenuElement("Device parks", url + "/device_parks", useAmIElevated());

  return (
    <Switch>
      <Route path={`${url}/devices`} key="devices">
        <XemwayMenuProvider {...devices}>
          <DevicesMain></DevicesMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/device_models`} key="devicemodels">
        <XemwayMenuProvider {...device_models}>
          <DevicesModelsMain></DevicesModelsMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/device_types`} key="devicetypes">
        <XemwayMenuProvider {...device_types}>
          <DeviceTypesMain></DeviceTypesMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/alerts`} key="nonconformities">
        <XemwayMenuProvider {...non_conformities}>
          <NonConformitiesMain></NonConformitiesMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/maintenances`} key="maintenances">
        <XemwayMenuProvider {...maintenances}>
          <MaintenancesMain></MaintenancesMain>
        </XemwayMenuProvider>
      </Route>

      <Route path={`${url}/device_parks`} key="deviceparks">
        <XemwayMenuProvider {...device_park}>
          <DeviceParksMain></DeviceParksMain>
        </XemwayMenuProvider>
      </Route>

      <Route>
        <Redirect to={`${url}/devices`} />
      </Route>
    </Switch>
  );
}
