// Hooks
import { PCBAssemblyQuickAddEditModal } from "./modals/AddEditPcbAssembly";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { PCBAssemblyNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { PcbLayoutColumn } from "nestor/components/tables/columns/PcbLayoutColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { usePcbAssemblyList } from "nestor/hooks/api/usePcbAssembly";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssembly } from "nestor/types/manufacturing/pcb_assembly";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// Classes
// Components
// Contexts
// ## Generic
import { ButtonsColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasDeletion, HasPagination, HasRefresh, RemoveMethod } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorPcbAssembly;
type FWLst = {};
type FW = RemoveMethod<T, FWLst>;
type HOOK = {};

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      PCBAssemblyNavigationColumn,
      //            NUIDColumn,
      SortableColumn(
        SimpleColumn("name", "Name", (e) => e.name),
        "name"
      ),

      PcbLayoutColumn("pcblayout", "Layout", "pcb_layout_id", (e) => e.pcb_layout),

      SimpleColumn("variant", "Variant", (e) => e.variant),

      EmployeeColumn("employee", "Employee", "employee_id", (e) => e.employee),

      ButtonsColumn<T, FW, HOOK>("actions", "", [RemoveButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        actions: "0px",
        employee: "120px",
      };
    },
  });
})();

const GenericContainer = (function () {
  return HasDeletion(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
      PCBAssemblyQuickAddEditModal,
      "Add a new assembly project",
      "plus",
      "primary"
    ),

    "Confirm project deletion",
    (v: T) => `Are you sure to revoke the PCB assembly project "${v.name}" ?`,
    (props) => {
      return useNestorAPI<undefined, NestorPcbAssembly>(
        (el) => {
          assert(el !== undefined);
          return NestorServerRoutes.pcb_assembly_revoke(el.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();

export const PCBAssemblyListCard = (props: {}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  return (
    <div className="m-2">
      <CardComponent header="PCB Assembly list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericContainer
            //cardHeader="Gateway list"
            list={usePcbAssemblyList()}
            fwProps={{}}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
