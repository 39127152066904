import { useContainerSearchField } from "nestor/components/forms/fields/ContainerSearch";
import { useDeviceModelParameterList } from "nestor/components/forms/fields/DeviceModelParameterList";
import { useDeviceSearchField } from "nestor/components/forms/fields/DeviceSearch";
import { usePcbSearchField } from "nestor/components/forms/fields/PcbSearch";
import { useProjectSearchField } from "nestor/components/forms/fields/ProjectSearch";
import { NestorSkuTransferContext } from "nestor/contexts/NestorSkuTransferContext";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceModelParameterType } from "nestor/types/inventory/device_model_parameter";
import { NestorProjectId } from "nestor/types/projects/projects";
import { NestorContainerId } from "nestor/types/stock/container";
import { NestorSkuId } from "nestor/types/stock/sku";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useFormSelectObj } from "@csem/forms";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useForm } from "@csem/forms";
import { Field } from "@csem/forms";
import { IOState } from "@csem/api";
import React, { useContext, useEffect, useState } from "react";
import { WithdrawalContent } from "./WithdrawalContent";

export const WithdrawalForm = (props: { showWithdrawalContent?: boolean; title?: string }) => {
  const ctx = useContext(NestorSkuTransferContext);
  assert(ctx);

  const qties: Array<{ sku_id: NestorSkuId; container_id: NestorContainerId; value: number }> = [];
  let projId: NestorProjectId | undefined;
  for (let [, num] of ctx.store) {
    const ctnProjId = num.container.project_id;
    if (projId === undefined) {
      projId = ctnProjId;
    } else if (projId !== ctnProjId) {
      projId = 0;
    }

    qties.push({ container_id: num.container.id, sku_id: num.sku.id, value: num.value });
  }

  const target = useFormSelectObj({
    label: "What are you using those components for ?",
    defaultValue: "project",
    options: {
      project: "A project",
      pcb: "A PCB",
      equipment: "A system / inventory",
      other: "Other",
    },
  });

  const [action, setAction] = useState<"withdraw" | "transfer" | "adjust">("withdraw");

  const project_id = useProjectSearchField({
    label: "Project",
    originalValue: projId || undefined,
    defaultValue: undefined,
    validationOnChange: { required: action === "withdraw" && target.value === "project" },
  });

  const { clearValue } = project_id;
  useEffect(() => {
    if (!projId) {
      clearValue();
    }
  }, [projId, clearValue]);

  const device_id = useDeviceSearchField({
    label: "System / Inventory",
    defaultValue: undefined,
    validationOnChange: { required: action === "withdraw" && target.value === "equipment" },
  });

  const system_target = useDeviceModelParameterList({
    label: "System parameter" + (qties.length > 1 ? " -- Disabled because you selected more than 1 component" : ""),
    defaultValue: undefined,
    disabled: qties.length > 1,
    modelId: device_id.selectedItem?.model_id,
    filter: NestorDeviceModelParameterType.SKU,
  });

  const pcb_id = usePcbSearchField({
    label: "PCB",
    defaultValue: undefined,
    validationOnChange: { required: action === "withdraw" && target.value === "pcb" },
  });

  const container_id = useContainerSearchField({
    label: "Target container",
    defaultValue: undefined,
    validationOnChange: { required: action === "transfer" },
  });

  const comment = useFormTextField({
    label: "Comment",
    defaultValue: "",
    type: FieldValueType.STRING,
  });

  const apiCall = useNestorAPI(`stock/skus/forms/movement`, false, undefined, NestorPOST);

  const formWithdrawal = useForm({
    onSubmit: () => {
      apiCall
        .doQuery(undefined, {
          comment: comment.value,
          target: target.value,
          project_id: project_id.value,
          device_id: device_id.value,
          pcb_id: pcb_id.value,
          system_target: system_target.value,
          //     container_id: props.container.id,
          target_container_id: container_id.value,
          mode: action,
          quantities: qties,
        })
        .then(() => {
          ctx.empty();
        });
    },

    fields: {
      target,
      project_id,
      device_id,
      pcb_id,
      comment,
      container_id,
      system_target,
    },
    fw: {
      action,
      setAction,
    },
    Template: MovementTemplate,
  });
  return (
    <>
      <CardComponent header={props.title || "Withdrawal from this container"}>
        {props.showWithdrawalContent ? <WithdrawalContent /> : null}
        <IOState
          source={apiCall.state}
          errorMessage={
            <>
              <div className="fw-bold">
                Some errors have occured. Make sure you are allowed to move/transfer those components
              </div>
              {apiCall.state.error && apiCall.error
                ? apiCall.error.errors?._?.map((v: string) => <div>{v}</div>)
                : null}
            </>
          }
          loadingMessage="Processing..."
        />
        {formWithdrawal.Content}
      </CardComponent>
    </>
  );
};

type FieldNames = "target" | "project_id" | "device_id" | "comment" | "container_id" | "system_target" | "pcb_id";

const MovementTemplate: React.FunctionComponent<{
  fields: Record<FieldNames, Field<any>>;
  action: "withdraw" | "transfer" | "adjust";
  setAction: React.Dispatch<React.SetStateAction<"withdraw" | "transfer" | "adjust">>;
  submit: Function;
}> = (props) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <label>What do you want to do with the items ?</label>
        <div className="d-flex justify-content-between ">
          <button
            type="button"
            className={`btn btn-lg  ${props.action === "withdraw" ? "btn-primary active" : "btn-secondary"} `}
            onClick={(e) => props.setAction("withdraw")}
          >
            Withdraw
          </button>{" "}
          <button
            type="button"
            className={`btn btn-lg  ${props.action === "transfer" ? "btn-primary active" : "btn-secondary"} `}
            onClick={(e) => props.setAction("transfer")}
          >
            Transfer container
          </button>
          <button
            type="button"
            className={`btn btn-lg  ${props.action === "adjust" ? "btn-primary active" : "btn-secondary"} `}
            onClick={(e) => props.setAction("adjust")}
          >
            Adjust to value
          </button>
        </div>
      </div>
      {props.action === "withdraw" ? (
        <>
          {props.fields.target.Content}
          {props.fields.target.value === "project" ? <>{props.fields.project_id.Content}</> : null}
          {props.fields.target.value === "equipment" ? (
            <>
              {props.fields.device_id.Content}
              {props.fields.system_target.Content}
            </>
          ) : null}
          {props.fields.target.value === "pcb" ? <>{props.fields.pcb_id.Content}</> : null}
        </>
      ) : null}

      {props.action === "transfer" ? <>{props.fields.container_id.Content}</> : null}

      {props.fields.comment.Content}

      <div className="row">
        <div className="col-12 mb-2 d-flex ">
          <button className="btn btn-success btn-lg flex-grow-1" type="button" onClick={() => props.submit()}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};
