import { useRoomList } from "nestor/hooks/api/useRoomList";
import { NestorRoom } from "nestor/types/company/room";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";
import { SearchElFilterFactory } from "@csem/lists";
import { RoomRenderer } from "../../renderers/RoomRenderer";

export const useRoomSearchField = FormSearchHookFactory<NestorRoom, NestorRoom["id"]>({
  apiListFactory: useRoomList,
  template: GenericListRenderer((e) => <RoomRenderer room={e} />),
  traverser_display: (el) => {
    return `${el.displayed_name} ${el.alternate_name !== null ? `(${el.alternate_name}) ` : ""} ${
      el.floor?.building ? `Fl ${el.floor.floor}, ${el.floor.building.name}` : ""
    }`; // ${/*el.laboratory ? `(${el.laboratory.name})` : ""*/}
  },

  uniqueKeyField: "id",
  filterKeys: {
    "floor.building.name": "contains",
    name: "contains",
    alternate_name: "contains",
    displayed_name: "contains",
  },
});

export const SearchByRoom = () => {
  return SearchElFilterFactory<NestorRoom, NestorRoom["id"]>({
    label: "Room",
    apiListFactory: useRoomList,
    traverser: (el) => el.id,
    key: "id",
    filterKeys: {
      "floor.building.name": "contains",
      alternate_name: "contains",
      name: "contains",
      displayed_name: "contains",
    },
    filterFieldKey: "room",
    template: GenericListRenderer((e) => <RoomRenderer room={e} />),
  });
};
