import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { OrdersMain } from "./orders/Orders";
import { SuppliersMain } from "./suppliers/Suppliers";

export function PurchasingMain() {
  const { url } = useRouteMatch();
  const orders = useMenuElement("Orders", url + "/orders");
  const suppliers = useMenuElement("Suppliers", url + "/suppliers");

  //const ctx = useContext(XemwayMenuContext);
  return (
    <Switch>
      <Route path={`${url}/orders`} key="orders">
        <XemwayMenuProvider {...orders}>
          <OrdersMain></OrdersMain>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/suppliers`} key="suppliers">
        <XemwayMenuProvider {...suppliers}>
          <SuppliersMain></SuppliersMain>
        </XemwayMenuProvider>
      </Route>
      <Route>
        <Redirect to={`${url}/orders`} />
      </Route>
    </Switch>
  );
}
