import { EmployeeRenderer } from "nestor/components/renderers/EmployeeInfos";
import { ProjectRenderer } from "nestor/components/renderers/ProjectRenderer";
import { DateRenderer } from "nestor/components/tables/columns/DateColumn";
import { NestorSkuTransferContextProvider } from "nestor/contexts/NestorSkuTransferContext";
import { NestorSku } from "nestor/types/stock/sku";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { slice } from "lodash";
import moment from "moment";
import { LabelPrinterButton } from "../../util/LabelPrinter";
import { PartInformationCardId } from "../parts/InfoCard";
import { SkuContainerListCard } from "./ListWithContainers";
import { WithdrawalForm } from "./movements/WithdrawalForm";

const SkuInformationCard = ({ sku }: { sku: NestorSku }) => {
  const expired = sku.due !== null && moment(sku.due).isBefore();

  return (
    <CardComponent additionalClasses="h-100" header="SKU information">
      {expired && <div className="alert alert-danger">This component has expired !</div>}
      <div className="row align-items-middle mb-3">
        <div className="col-4">NUID</div>
        <div className="col-4">
          <h4> {sku.nuid}</h4>
        </div>
        <div className="col-4">
          <LabelPrinterButton size={LabelPrinterSizes.SZ_12} url={`stock/skus/${sku.id}/label/pdf/print`} />
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Project</div>
        <div className="col-8">
          <ProjectRenderer project={sku.project || null} fallbackText="No project information" />
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Employee responsible</div>
        <div className="col-8">
          <EmployeeRenderer employee={sku.employee} />
        </div>
      </div>
      <div className="row align-items-middle mb-3">
        <div className="col-4">Initial quantity</div>
        <div className="col-8">
          {sku.amount_init ? sku.amount_init + " " + sku.unit : <div className="text-muted">No information</div>}
        </div>
      </div>{" "}
      <div className="row align-items-middle mb-3">
        <div className="col-4">Remaining quantity</div>
        <div className="col-8">{sku.amount + " " + sku.unit}</div>
      </div>
      {sku.due && (
        <div className="row align-items-middle mb-3">
          <div className="col-4">Expiracy date</div>
          <div className="col-8">
            <div className={`text text-${expired ? "danger" : "primary"}`}>
              <DateRenderer el={sku.due} />
            </div>
          </div>
        </div>
      )}
    </CardComponent>
  );
};

export const SkuInfos = (props: { sku: NestorSku; refresh: Function }) => {
  assert(props.sku.article);
  return (
    <div className="row">
      <NestorSkuTransferContextProvider>
        <div className="col-md-4">
          <SkuInformationCard sku={props.sku} />
        </div>
        <div className="col-md-4">
          <PartInformationCardId partId={props.sku.article.orderable_id} />
        </div>

        <div className="col-md-4">
          <WithdrawalForm showWithdrawalContent={true} />
        </div>
        <div className="col-md-12">
          <SkuContainerListCard sku={props.sku} />{" "}
        </div>
      </NestorSkuTransferContextProvider>
    </div>
  );
};
