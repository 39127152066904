import NestorFrontendRoutes from "nestor/frontend_routes";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useLocationStack } from "nestor/hooks/useLocationStack";
import NestorServerRoutes from "nestor/server_routes";
import { NestorContainer } from "nestor/types/stock/container";
import { NestorLocation } from "nestor/types/system/location";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { LocationRenderer } from "./LocationRenderer";

export const ContainerRenderer = ({
  container,
  withNav,
  withLocationResolver,
}: {
  container?: NestorContainer;
  withNav?: boolean;
  withLocationResolver?: boolean;
}) => {
  const { ShowLocationButton, LocationStack } = useLocationStack("container", container?.id || 0);

  if (!container) {
    return null;
  }

  if (container.revoked_at) {
    withNav = false;
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className={container.expired_components ? "text-danger" : ""}>
          <em className="bi bi-stack" title={container.expired_components ? "end-of-life" : ""}></em>
          &nbsp;
          {withNav ? (
            <Link to={NestorFrontendRoutes.container_details(container.id)}>{container.name}</Link>
          ) : (
            container.name
          )}
        </div>
        {withLocationResolver ? <div>{ShowLocationButton}</div> : null}
      </div>

      {container.project ? (
        <>
          <div className="text-muted">
            {container.project.code} {container.project.name}
          </div>
        </>
      ) : null}
      {container.employee ? (
        <>
          <div className="text-muted">{container.employee.fullname}</div>
        </>
      ) : null}
      {/*container.expired_components ? <div className="d-block badge bg-danger">exp</div> : null*/}

      {LocationStack}
    </div>
  );
};
