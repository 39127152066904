import { NestorDeviceId } from "./types/inventory/device";
import { NestorDeviceModelId } from "./types/inventory/device_model";
import { NestorPcbId } from "./types/manufacturing/pcb";
import { NestorPcbAssemblyFeederSetId, NestorPcbAssemblyId } from "./types/manufacturing/pcb_assembly";
import { NestorPcbLayoutId } from "./types/manufacturing/pcb_layout";
import { NestorTaskId } from "./types/manufacturing/task";
import { NestorProjectId } from "./types/projects/projects";
import { NestorOrderId } from "./types/purchasing/orders";
import { NestorOrderFeedbackId } from "./types/purchasing/order_feedback";
import { NestorParcelId } from "./types/purchasing/parcel";
import type { NestorSupplierId } from "./types/purchasing/supplier";
import { NestorSupplierQualification, NestorSupplierQualificationId } from "./types/purchasing/supplier_qualification";
import { NestorContainerId } from "./types/stock/container";
import { NestorSkuShortcutId } from "./types/stock/shortcut";
import { NestorSkuId } from "./types/stock/sku";

const NestorFrontendRoutes = {
  supplier_edit: (id: NestorSupplierId) => `/purchasing/suppliers/${id}/edit`,
  supplier_qualification_details: (supplier_id: NestorSupplierId, id: NestorSupplierQualificationId) =>
    `/purchasing/suppliers/${supplier_id}/qualifications/${id}`,
  container_details: (id: NestorContainerId) => `/stock/containers/${id}`,
  devicemodel_details: (id: NestorDeviceModelId) => `/inventory/device_models/${id}`,
  devicemodel_systems: (id: NestorDeviceModelId) => `/inventory/device_models/${id}/systems`,
  device_details: (id: NestorDeviceId) => `/inventory/devices/${id}`,
  device_systems: (id: NestorDeviceId) => `/inventory/devices/${id}/system_components`,

  project_details: (id: NestorProjectId) => `/company/projects/${id}`,
  sku_details: (id: NestorSkuId) => `/stock/skus/${id}`,
  order_details: (id: NestorOrderId) => `/purchasing/orders/${id}`,
  order_feedback_details: (order_id: NestorOrderId, id: NestorOrderFeedbackId) => `/purchasing/orders/${order_id}/feedbacks/${id}`,
  pcblayout_details: (id: NestorPcbLayoutId) => `/manufacturing/pcbs/layout/${id}`,
  pcb_details: (layout_id: NestorPcbLayoutId, id: NestorPcbId) => `/manufacturing/pcbs/layout/${layout_id}/pcb/${id}`,
  pcbassembly_details: (id: NestorPcbAssemblyId) => `/manufacturing/pcb_assembly/${id}`,
  pcbassemblyfeederset_details: (id: NestorPcbAssemblyFeederSetId) => `/manufacturing/pcb_assembly/feeders/${id}`,
  skushortcut_details: (id: NestorSkuShortcutId) => `/stock/skus/shortcuts/${id}`,

  supplier_details: (id: NestorSupplierId) => `/purchasing/suppliers/${id}`,
  task_details: (id: NestorTaskId) => `/manufacturing/tasks/${id}`,
  parcel_details: (order_id: NestorOrderId, id: NestorParcelId) =>
    `/purchasing/orders/${order_id}/shipments/${id}/edit`,
};

export default NestorFrontendRoutes;
