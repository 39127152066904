import NestorServerRoutes from "nestor/server_routes";
import { NestorOrderId } from "nestor/types/purchasing/orders";
import { NestorOrderFeedback, NestorOrderFeedbackCategory } from "nestor/types/purchasing/order_feedback";
import { NestorSupplierId } from "nestor/types/purchasing/supplier";
import { apiList } from "@csem/api";
import { useNestorAPI } from "./useNestorAPI";
import { useNestorListApiCall } from "./useNestorListApi";

export const useOrderFeedbackList = (orderId: NestorOrderId): apiList<NestorOrderFeedback> => {
  const url = new URLSearchParams();
  return useNestorListApiCall<NestorOrderFeedback>(NestorServerRoutes.order_feedback_list(orderId), "feedback", url);
};

export const useSupplierFeedbackList = (supplierId: NestorSupplierId): apiList<NestorOrderFeedback> => {
  const url = new URLSearchParams();
  return useNestorListApiCall<NestorOrderFeedback>(
    NestorServerRoutes.supplier_feedback_list(supplierId),
    "evaluation",
    url
  );
};
/*
export const useOrderFeedbackCategoriesList = ShimArrayToApiList(
  NestorServerRoutes.order_feedback_categories(),
  "Unable to load the category list"
};
*/

export const useOrderFeedbackCategoriesList = () => {
  return useNestorAPI<Array<NestorOrderFeedbackCategory>>(
    NestorServerRoutes.order_feedback_categories(),
    true,
    undefined,
    undefined
  );
};
