import { LinkDeviceOrderModal } from "nestor/components/modals/LinkDeviceOrder";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { OrderNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { NUIDColumn } from "nestor/components/tables/columns/NUIDColumn";
import { useDeviceOrderList } from "nestor/hooks/api/useDeviceSingle";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorOrder } from "nestor/types/purchasing/orders";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { TableRenderer } from "@csem/lists";
import { GenericListContent, HasAddition, HasDeletion, HasRefresh, RemoveMethod } from "@csem/lists";
import { OrderProjectColumn, OrderSupplierColumn, SAPColumn } from "../../purchasing/orders/List";
import { DeviceOrderLinkColumns } from "../../util/DeviceOrderLinkColumns";
import NestorServerRoutes from "nestor/server_routes";

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

type T = ArrayElement<NonNullable<ReturnType<typeof useDeviceOrderList>["list"]>>;
type FW = { device?: NestorDevice };
type FWLst = RemoveMethod<T, FW>;
type devices = "devices";
const OrderTable = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      OrderNavigationColumn,
      NUIDColumn,
      SAPColumn,
      OrderProjectColumn,
      OrderSupplierColumn,

      //EmployeeColumn<T>("user", "By", "employee.id", e => e.employee),
      EmployeeColumn("emply", "Initiator", "employee_id", (e) => e.employee),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ...DeviceOrderLinkColumns<T, devices>("devices"),
    ],

    useColumnVisibility: () => {
      return { select: false };
    },

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return {
        supplier: "250px",
      };
    },
  });
})();

export const OrderDeviceListCard = (props: { device: NestorDevice }) => {
  return (
    <div className="my-2">
      <CardComponent header="Orders linked to this device">
        <StandardTableFilterCtxProvider>
          <LinkableToDeviceOrderListContainer
            //cardHeader="Gateway list"
            list={useDeviceOrderList(props.device.id)}
            fwProps={{ device: props.device }}
            listTemplate={OrderTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

const LinkableToDeviceOrderListContainer = (function () {
  return HasDeletion(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<{}, T>())),
      LinkDeviceOrderModal,
      "Link to another order",
      "link",
      "primary"
    ),
    "Confirm link revocation",
    (v: T) => `Are you sure to revoke this device - order link ?`,
    (props) => {
      return useNestorAPI<undefined, NestorOrder>(
        (el) => {
          assert(el !== undefined);
          return NestorServerRoutes.inventory.devices.orderLinks.delete(props.fwProps.device!.id, el!.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();
