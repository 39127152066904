import { useDeviceParkList } from "nestor/hooks/api/useDeviceParkList";
import { NestorDevicePark } from "nestor/types/inventory/device_park";
import { GenericListRenderer } from "@csem/forms";
import { SearchElFilterFactory } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { DeviceParkRenderer } from "../../forms/fields/DeviceParkSearch";

type U = NestorDevicePark;

const Top = (props: { onSelect: (el: NestorDevicePark | null) => void }) => {
  return (
    <div className="">
      <button type="button" className="btn btn-secondary" onClick={() => props.onSelect(null)}>
        null (unassigned)
      </button>
    </div>
  );
};

export const DeviceParkColumn = <T,>(
  name: string,
  label: string,
  field: string | undefined,
  renderer: (el: T) => U | undefined | null
) => {
  const _renderer = (el: T) => {
    const _el = renderer(el);
    if (_el) {
      return <DeviceParkRenderer device_park={_el} />;
    } else {
      return null;
    }
  };

  if (!field) {
    return SimpleColumn(name, label, _renderer);
  }
  return FilterableColumn(
    SimpleColumn(name, label, _renderer),
    SearchElFilterFactory<U, U["id"]>({
      label: "Device park",
      apiListFactory: useDeviceParkList,
      traverser: (el) => el.id,
      filterKeys: {
        name: "contains",
        "responsible.initials": "eq",
        "responsible.fullname": "contains",
      },
      template: GenericListRenderer((e) => <DeviceParkRenderer device_park={e} />),
      key: "id",
      top: Top,
    }),
    field
  );
};
