import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { DeviceModelDetails } from "./DeviceModelDetails";
import { DevicesModelList } from "./DeviceModelList";

/**
 * Device Models Router and Menu handler
 * @returns
 */
export function DevicesModelsMain() {
  const { url } = useRouteMatch();

  const list = useMenuElement("List of device models", url + "/list");
  // const create = useMenuElement("Create a new device", url + "/create");
  //const ctx = useContext(XemwayMenuContext);

  return (
    <Switch>
      <Route path={`${url}/list`} key="list">
        <XemwayMenuProvider {...list}>
          <DevicesModelList></DevicesModelList>
        </XemwayMenuProvider>
      </Route>
      <Route path={`${url}/:device_model`}>
        <DeviceModelDetails></DeviceModelDetails>
      </Route>

      <Route>
        <Redirect to={`${url}/list`} />
      </Route>
    </Switch>
  );
}
