import { NestorSector } from "nestor/types/company/sector";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorProjectWorkpackage } from "nestor/types/projects/workpackage";

export const ProjectRenderer = (props: {
  project: NestorProject | null | undefined;
  workpackage?: NestorProjectWorkpackage | null;
  cost_center?: NestorSector | null;
  fallbackText?: string;
}) => {
  if (!props.project) {
    if (props.cost_center) {
      return (
        <>
          <div>
            {props.cost_center.division_id} - {props.cost_center.id}
          </div>
        </>
      );
    }

    return props.fallbackText ? <span className="text-muted">{props.fallbackText}</span> : null;
  }
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>{props.project?.code}</div>
        {props.workpackage ? <div>WP {props.workpackage.workpackage}</div> : null}
      </div>
      <span className="text-muted text-small">{props.project?.name}</span>
    </>
  );
};
