import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { StandardFormAddEditModalFactory } from "@csem/shared-utils";

import { useFormFileField } from "@csem/forms";

import assert from "assert";
import NestorServerRoutes from "nestor/server_routes";

export const DeviceUploadPhoto = StandardFormAddEditModalFactory<NestorDeviceModel | NestorDevice, {}, any>({
  title: (el) =>
    el ? ("model_id" in el ? "Upload a picture for the device model" : `Upload a picture for the device`) : "Error",
  buttonValidate: (el) => "Validate",

  useDefinition: (el, fw) => {
    assert(el !== undefined);

    const isDevice = "model_id" in el;

    let hasPicture: boolean = false;
    if (isDevice) {
      hasPicture = !!el.picture;
    } else {
      hasPicture = !!el.picture;
    }

    const picture = useFormFileField({
      label: "Picture",
      defaultValue: undefined,
      originalValue: hasPicture || undefined,
    });

    const apiCall = useNestorAPI<{ response: { id?: number } }>(
      !isDevice ? `inventory/device_models/${el.id}/forms/update` : NestorServerRoutes.device_update(el.id),
      false,
      undefined,
      { method: "POST" }
    );

    const cb = () => {
      let formData = new FormData();

      if (picture.value instanceof File) {
        formData.append("file", picture.value);
      }
      apiCall.doQuery(undefined, formData);
    };

    return {
      fields: {
        picture,
      },

      state: apiCall.state,
      out: apiCall.result,
      submit: cb,
    };
  },
});
