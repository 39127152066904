import { NestorSku } from "nestor/types/stock/sku";
import { IOState } from "@csem/api";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";

export const useSKUSingle = (elId: number | string): NestorApiElement<NestorSku, undefined> => {
  const apiCall = useNestorAPI<NestorSku>(`stock/skus/${elId}`, true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    loadingIO: <IOState loadingMessage="Loading sku..." source={apiCall.state} success={null} />,
  };
};
