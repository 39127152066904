// Hooks

import { ProjectRenderer } from "nestor/components/renderers/ProjectRenderer";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import DownloadColumn, {
  DownloadAttachmentColumn,
  DownloadAttachmentFbColumn,
} from "nestor/components/tables/columns/DownloadColumn";
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { SupplierQualificationNavigationColumn } from "nestor/components/tables/columns/NavigationColumns";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useSupplierQualificationList } from "nestor/hooks/api/useSupplierQualification";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { NestorSupplierQualification } from "nestor/types/purchasing/supplier_qualification";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, HasRevokation, RemoveButton, RevokeButton, RevokeMethod } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
import { ButtonsColumn, CheckboxFilterColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasModalMethod,
  HasRefresh,
  Method,
  RemoveMethod,
} from "@csem/lists";
import moment from "moment";
import { SupplierQualificationAddEdit } from "./modals/AddEditQualification";
import { SupplierQualificationProject } from "./modals/ProjectQualification";
import { useAmIElevated, useAmIQCManager } from "nestor/hooks/me";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ButtonLink } from "nestor/components/nestor/util/ButtonLink";

type T = NestorSupplierQualification;
type FW = { supplier: NestorSupplier; superuser: boolean };
type FWTable = RemoveMethod<T, FW> & RevokeMethod<T, FW> & Partial<Method<"editProjects", [T, FW]>>;

const GenericSupplierTable = (function () {
  return TableRenderer<T, FW, FWTable, {}, "id">({
    key: "id",
    columns: [
      SupplierQualificationNavigationColumn,

      SimpleColumn("tag", "Tag", (e) => {
        if (e.revoked_at) {
          return <div className="d-block badge bg-danger">revoked</div>;
        } else {
          const m = moment(e.expires_at).isBefore();
          if (m) {
            return <div className="d-block badge bg-warning">expired</div>;
          }
        }
      }),

      SimpleColumn("scope", "Scope", (e) => e.scope),

      CheckboxFilterColumn(
        "iso9001",
        "ISO 9001",
        "iso9001",
        (e) => (
          <>
            <em className={e.iso_9001 ? "bi bi-check" : "bi bi-x"}></em>{" "}
            {e.iso_9001 && e.iso_9001_date ? moment(e.iso_9001_date).format("DD.MM.YYYY") : ""}
          </>
        ),
        [{ label: "ISO 9001", on: 1, off: 0, filterKey: "" }]
      ),

      CheckboxFilterColumn(
        "iso13485",
        "ISO 13485",
        "iso13485",
        (e) => (
          <>
            <em className={e.iso_13485 ? "bi bi-check" : "bi bi-x"}></em>
            {e.iso_13485 && e.iso_13485_date ? moment(e.iso_9001_date).format("DD.MM.YYYY") : ""}
          </>
        ),
        [{ label: "ISO 13485", on: 1, off: 0, filterKey: "" }]
      ),

      EmployeeColumn("by", "Qualified by", "employee_id", (e) => e.employee),

      SimpleColumn("projects", "Projects", (e) => (
        <>
          {e.projects?.map((e) => (
            <ProjectRenderer project={e} fallbackText="No project information" />
          ))}
        </>
      )),

      DateColumn("end_at", "Expires on the", "end_at", (e) => e.expires_at),
      DateColumn("revoked_at", "Revoked on the", "end_at", (e) => e.deleted_at),
      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("actions", "", [
        //EditButton("Edit", (e) => !e.revoked_at),
        RemoveButton("Delete", (e, fw) => fw.superuser),
        RevokeButton("Revoke", (e) => !e.revoked_at),
        /* {
          title: "Projects",
          theme: "warning",
          visibility: (e, fw) => {
            return !e.revoked_at && fw.superuser;
          },
          cb: (e: T, fw) => {
            fw.editProjects?.(e, fw);
          },
        },*/
      ]),

      DownloadAttachmentFbColumn(
        "ef611dl",
        "Signed EF611",
        "EF611",
        (el) => (el.ef611 && el.ef611.length > 0 ? el.ef611[0] : undefined),
        <span className="text-danger">No signed EF-611 uploaded !</span>
      ),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth() {
      return {
        _nav: "0px",
        nuid: "65px",
      };
    },
  });
})();

const GenericSupplierListContainer = //HasModalMethod(
  HasDeletion(
    HasRevokation(
      //  HasEdition(
      //   HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<FW, T>())),
      // SupplierQualificationAddEdit,
      //   "Add a new qualification",
      //  "plus",
      //  "primary"
      //  ),
      //    SupplierQualificationAddEdit
      //   ),
      "Confirm qualification revocation",
      (v: T) => `Are you sure to revoke this qualification ?`,
      (props) => {
        return useNestorAPI<undefined, T>(
          (el) => {
            assert(el !== undefined);
            return NestorServerRoutes.supplier_qualification_revoke(el.id);
          },
          false,
          undefined,
          { method: "GET" },
          props.list.refresh
        );
      }
    ),
    "Confirm qualification deletion",
    (v: T) => `Are you sure to delete this qualification ?`,
    (props) => {
      return useNestorAPI<undefined, T>(
        (el) => {
          assert(el !== undefined);
          return NestorServerRoutes.supplier_qualification_delete(el.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
// SupplierQualificationProject,
// "editProjects"
//);

export const SupplierQualificationListCard = ({ supplier }: { supplier: NestorSupplier }) => {
  const superuser = useAmIQCManager();
  return (
    <div className="mb-2">
      <CardComponent header="Qualification list">
        <StandardTableFilterCtxProvider>
          <GenericSupplierListContainer
            //cardHeader="Gateway list"
            list={useSupplierQualificationList(supplier.id)}
            fwProps={useMemo(() => {
              return { supplier, superuser };
            }, [supplier, superuser])}
            listTemplate={GenericSupplierTable}
            additionalContent={[]}
            righthandContent={[
              <Link
                to={`/purchasing/suppliers/${supplier.id}/qualifications/create`}
                component={ButtonLink}
                className="btn btn-success"
                key="add"
              >
                <em className="bi bi-plus"></em> New Qualification
              </Link>,
            ]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
