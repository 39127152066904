import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorBuildingId } from "nestor/types/company/building";
import { NestorFloor } from "nestor/types/company/floor";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";
type T = NestorFloor;

export const useFloorField = (
  props: CommonFieldUI<string | number | undefined> & {
    buildingId?: NestorBuildingId | string;
  }
) => {
  return useFormSelectRemoteField<T, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: props.buildingId ? `company/buildings/${props.buildingId}/floors` : undefined,
    keyMap: (el: T) => el.id.toString(),
    valMap: (el: T) => el.name,
  });
};
