import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorSector } from "nestor/types/company/sector";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";

export const useSectionField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorSector, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: `company/sectors`,
    keyMap: (el: NestorSector) => el.id,
    valMap: (el: NestorSector) => el.id + "  " + el.name,
  });
};

export const useAllSectionsField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorSector, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: `company/sectors/all`,
    keyMap: (el: NestorSector) => el.id,
    valMap: (el: NestorSector) => el.division_id + " " + el.id + "  " + el.name,
  });
};
