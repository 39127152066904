import { DateRenderer } from "nestor/components/tables/columns/DateColumn";
import { NestorCalibration } from "nestor/types/inventory/calibration";
import { CardComponent } from "@csem/shared-utils";
import { EmployeeBadgeComplete } from "../../../company/employees/EmployeeBadge";
import { CalibrationTagRenderer } from "./Calibrations";

export const CalibrationInfos = ({ calibration }: { calibration: NestorCalibration }) => {
  return (
    <div className="col-6">
      <CardComponent header="Calibration information">
        <div className="row align-items-middle mb-3">
          <div className="col-4">Starting date</div>
          <div className="col-8">
            <h5>
              <DateRenderer el={calibration.start} />
            </h5>
          </div>
        </div>

        <div className="row align-items-middle mb-3">
          <div className="col-4">Ending date</div>
          <div className="col-8">
            <h5>
              <DateRenderer el={calibration.end} />
            </h5>
          </div>
        </div>

        <div className="row align-items-middle mb-3">
          <div className="col-4">Comment</div>
          <div className="col-8">
            <h5> {calibration.comment}</h5>
          </div>
        </div>

        <div className="row align-items-middle mb-3">
          <div className="col-4">Employee</div>
          <div className="col-8">
            {calibration.employee ? <EmployeeBadgeComplete employee={calibration.employee} /> : null}
          </div>
        </div>

        <div className="row align-items-middle mb-3">
          <div className="col-4">Calibrator</div>
          <div className="col-8">{calibration.calibrator}</div>
        </div>

        <div className="row align-items-middle mb-3">
          <div className="col-4">Range</div>
          <div className="col-8">{calibration.calibration_range?.name}</div>
        </div>

        <div className="row align-items-middle mb-3">
          <div className="col-4">Status</div>
          <div className="col-8">
            <div>
              <CalibrationTagRenderer calibration={calibration} />
            </div>
          </div>
        </div>
        {calibration.revoked_comment ? (
          <div className="row align-items-middle mb-3">
            <div className="col-4">Revoking comment</div>
            <div className="col-8">{calibration.revoked_comment}</div>
          </div>
        ) : null}
      </CardComponent>
    </div>
  );
};
