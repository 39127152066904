// Hooks
//# Tables
//## Nestor
import { SkuShortcutPositionAddEditModal } from "nestor/components/modals/AddEditSkuShortcutPosition";
import { SkuContainerRenderer } from "nestor/components/renderers/SkuContainerRenderer";
import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { useSkuShortcutPositionList } from "nestor/hooks/api/useSkuShortcutList";
import { NestorSkuShortcut, NestorSkuShortcutPosition } from "nestor/types/stock/shortcut";
import { CardComponent } from "@csem/shared-utils";
import { EditButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// Classes
// Components
// Contexts
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import { EditMethod, GenericListContent, HasAddition, HasEdition, HasPagination, HasRefresh } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorSkuShortcutPosition;
type FWLst = { shortcut: NestorSkuShortcut };
type FW = EditMethod<T, FWLst>;
type HOOK = {};
const GenericPCBMachinePositionTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("name", "Name", (e) => e.name),
      SimpleColumn("descr", "Position", (e) => e.position),
      SimpleColumn("handle", "Description", (e) => e.handle),
      SimpleColumn("sku", "SKU", (e) => <SkuContainerRenderer skuctn={e.container_sku?.[0]} />),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),

      ButtonsColumn("actions", "Actions", [EditButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const GenericContainer = (function () {
  return HasEdition(
    HasAddition(
      HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
      SkuShortcutPositionAddEditModal,
      "Add a new position",
      "plus",
      "primary"
    ),
    SkuShortcutPositionAddEditModal
  );
})();

export const SkuShortcutPositionListCard = (props: { shortcut: NestorSkuShortcut }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  return (
    <div className="m-2">
      <CardComponent header="SKU Shortcut Positions list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericContainer
            //cardHeader="Gateway list"
            list={useSkuShortcutPositionList(props.shortcut.id)}
            fwProps={{ shortcut: props.shortcut }}
            listTemplate={GenericPCBMachinePositionTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
