import {
  usePcbAssemblyFeederAutoListSearch,
  usePcbAssemblyFeederSemiListSearch,
  usePcbAssemblyFeederSetList,
} from "nestor/hooks/api/usePcbAssembly";
import { NestorPcbAssemblyFeeder, NestorPcbAssemblyFeederSet } from "nestor/types/manufacturing/pcb_assembly";
import { GenericListRenderer } from "@csem/forms";
import { FormSearchHookFactory } from "@csem/lists";

type T = NestorPcbAssemblyFeeder;
type U = NestorPcbAssemblyFeederSet;

const PCBFeederRenderer = ({ feeder }: { feeder: T }) => {
  return (
    <>
      <div className="fw-bold">
        {feeder.value} - {feeder.footprint}
      </div>
      <div className="text-muted">
        {feeder.feederset ? (
          <div>
            {feeder.feederset.name} ({feeder.feederset.mode}).
          </div>
        ) : null}
        x: {feeder.feeder_x}, y: {feeder.feeder_y}
      </div>
    </>
  );
};

const PCBFeederSetRenderer = ({ feeder }: { feeder: U }) => {
  return (
    <>
      <div className="fw-bold">{feeder.name}</div>
      <div className="text-muted">{feeder.mode}</div>
    </>
  );
};

export const PcbFeederSearchFactory = (hook: typeof usePcbAssemblyFeederAutoListSearch) => {
  return FormSearchHookFactory<NestorPcbAssemblyFeeder, NestorPcbAssemblyFeeder["id"]>({
    apiListFactory: hook,

    template: GenericListRenderer((el) => <PCBFeederRenderer feeder={el} />),
    filterKeys: {
      value: "contains",
      footprint: "contains",
    },
    traverser_display: (el) => {
      return el.value + " (" + el.footprint + ")";
    },
    uniqueKeyField: "id",
  });
};

export const useAutoPcbFeederSearch = PcbFeederSearchFactory(usePcbAssemblyFeederAutoListSearch);
export const useSemiautoPcbFeederSearch = PcbFeederSearchFactory(usePcbAssemblyFeederSemiListSearch);

export const usePcbFeederSetSearch = FormSearchHookFactory<
  NestorPcbAssemblyFeederSet,
  NestorPcbAssemblyFeederSet["id"]
>({
  apiListFactory: usePcbAssemblyFeederSetList,

  template: GenericListRenderer((el) => <PCBFeederSetRenderer feeder={el} />),
  filterKeys: {
    mode: "contains",
    name: "contains",
  },
  traverser_display: (el) => {
    return el.name + " (" + el.mode + ")";
  },
  uniqueKeyField: "id",
});
