import { NestorPcb } from "nestor/types/manufacturing/pcb";
import { NestorPcbLayoutId } from "nestor/types/manufacturing/pcb_layout";
import { NestorParcelId } from "nestor/types/purchasing/parcel";

import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorPcb;
export const usePcbList = (elId?: NestorPcbLayoutId): apiList<T> => {
  let url: string;
  if (elId) {
    url = `list/manufacturing/pcblayouts/${elId}/pcbs`;
  } else {
    url = `list/manufacturing/pcbs`;
  }
  return useNestorListApiCall<T>(url, "pcb");
};
