import { useBackofficeList } from "nestor/hooks/api/useBackofficeList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";
import { NestorBackoffice } from "nestor/types/company/backoffice";
import { useFormSelectRemoteField } from "@csem/forms";
import { useFormMultipleSelect } from "@csem/forms";
import { indexable } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";

export const useBackofficeField = (
  props: Omit<CommonFieldUI<Array<NestorBackoffice>>, "originalValue"> & {
    originalValue?: Array<NestorBackoffice> | Array<indexable> | undefined;
  }
) => {
  return useFormMultipleSelect({
    ...props,
    serverKey: "id",
    serverValue: "name",
    serverList: useBackofficeList(),
  });
};

export const useSingleBackofficeField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorBackoffice, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: NestorServerRoutes.backoffice_list(),

    keyMap: (el: NestorBackoffice) => el.id,
    valMap: (el: NestorBackoffice) => el.name,

    getterFromKey: (el: string | number | undefined, list) => {
      if (el === undefined || list === undefined) {
        return;
      }
      return list.find((e) => e.id === el);
    },
  });
};
