import NestorServerRoutes from "nestor/server_routes";
import { NestorSupplierId } from "nestor/types/purchasing/supplier";
import {
  NestorSupplierQualification,
  NestorSupplierQualificationId,
} from "nestor/types/purchasing/supplier_qualification";
import { apiList, IOState } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";
import { NestorApiElement } from "./types";
import { useNestorAPI } from "./useNestorAPI";
import { NestorProjectSupplier } from "nestor/types/projects/project_suppliers";

type T = NestorSupplierQualification;
export const useSupplierQualificationList = (supplier_id: NestorSupplierId): apiList<T> => {
  return useNestorListApiCall<T>(NestorServerRoutes.supplier_qualification_list(supplier_id), "qualification");
};

export const useSupplierQualificationSingle = (
  elId: NestorSupplierQualificationId
): NestorApiElement<NestorSupplierQualification, undefined> => {
  const apiCall = useNestorAPI<NestorSupplierQualification>(NestorServerRoutes.supplier_qualification_get(elId), true);

  return {
    element: apiCall.result || undefined,
    refresh: apiCall.doQuery,
    state: apiCall.state,
    loadingIO: <IOState loadingMessage="Loading qualification..." source={apiCall.state} success={null} />,
  };
};

export const useSupplierCriticalProjects = (): apiList<NestorProjectSupplier> => {
  return useNestorListApiCall<NestorProjectSupplier>(
    NestorServerRoutes.suppliers.criticalities.project_list(),
    "qualification"
  );
};
