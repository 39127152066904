import { OrderArticleListEdit } from "nestor/components/nestor/purchasing/orders/ArticlesEdit";
import { OrderArticleListInfo } from "nestor/components/nestor/purchasing/orders/ArticlesInfo";
import { DeviceOrderListCard } from "nestor/components/nestor/purchasing/orders/DeviceList";
import { OrderEditCreateForm } from "nestor/components/nestor/purchasing/orders/Edit";
import { OrderFeedbackListCard } from "nestor/components/nestor/purchasing/orders/feedbacks/List";
import { OrderInfos } from "nestor/components/nestor/purchasing/orders/Info";
import { OrderInvoiceListCard } from "nestor/components/nestor/purchasing/orders/Invoices";
import { SkuOrderListCard } from "nestor/components/nestor/purchasing/orders/SKUList";

import { ConversationCard } from "nestor/components/nestor/util/Conversation";
import { DocumentListCard } from "nestor/components/nestor/util/documents/Documents";
import { SnapshotListCard } from "nestor/components/nestor/util/snapshots/SnapshotListCard";
import { NestorPermissionContextProvider, useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useOrderSingle } from "nestor/hooks/api/useOrderSingle";
import { useLatch } from "nestor/hooks/latch";
import { NestorOrder } from "nestor/types/purchasing/orders";
import assert from "assert";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";

import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { ParcelsMain } from "./Parcels";
import { OrderFeedbacks } from "./feedbacks/OrderFeedbacks";
const OrderDetailInner: React.FunctionComponent<{
  order: NestorOrder;
  refresh: Function;
}> = (props) => {
  const route = useRouteMatch<{ order: string }>();

  const can_edit = useHasPermission("can_edit");
  useMenuElement("Details", route.url + "/details");
  useMenuElement("Edit information", route.url + "/edit", can_edit);
  useMenuElement("Edit articles", route.url + "/edit_articles");
  useMenuElement("Articles", route.url + "/articles");

  useMenuElement("Invoices", route.url + "/invoices");
  useMenuElement("Documents", route.url + "/documents");
  const feedbacks = useMenuElement("Evaluation", route.url + "/feedbacks", true);
  useMenuElement("Snapshots", route.url + "/snapshots", true);

  const conversations = useMenuElement("Conversation", route.url + "/conversation");
  const shipments = useMenuElement("Stock & Inventory", route.url + "/shipments");
  //useMenuElement("New Stock & Inventory entry", route.url + "/new_stock_inventory_entry");
  const ConvList = (
    <XemwayMenuProvider {...conversations}>
      <ConversationCard type="order" id={props.order.id}></ConversationCard>
    </XemwayMenuProvider>
  );
  return (
    <>
      <div className="d-none">{ConvList}</div>
      <Switch>
        <Route path={`${route.url}/details`}>
          {" "}
          <OrderInfos order={props.order} refresh={props.refresh}></OrderInfos>
        </Route>
        <Route path={`${route.url}/articles`}>
          <OrderArticleListInfo orderId={props.order.id} showArticleTables={true}></OrderArticleListInfo>
        </Route>
        <Route path={`${route.url}/edit`}>
          <OrderEditCreateForm order={props.order}></OrderEditCreateForm>
        </Route>
        <Route path={`${route.url}/documents`}>
          <DocumentListCard type="order" id={props.order.id}></DocumentListCard>
        </Route>{" "}
        <Route path={`${route.url}/conversation`} key="conversations">
          {ConvList}
        </Route>
        <Route path={`${route.url}/invoices`}>
          <OrderInvoiceListCard orderId={props.order.id}></OrderInvoiceListCard>
        </Route>
        <Route path={`${route.url}/feedbacks`}>
          <XemwayMenuProvider {...feedbacks}>
            <OrderFeedbacks order={props.order} />
          </XemwayMenuProvider>
        </Route>
        <Route path={`${route.url}/edit_articles`}>
          <OrderArticleListEdit orderId={props.order.id}></OrderArticleListEdit>
        </Route>
        <Route path={`${route.url}/snapshots`}>
          <SnapshotListCard type="order" id={props.order.id}></SnapshotListCard>
        </Route>
        <Route path={`${route.url}/shipments`}>
          <XemwayMenuProvider {...shipments}>
            <ParcelsMain order={props.order} />
          </XemwayMenuProvider>
        </Route>
        <Route>
          <Redirect to={`${route.url}/details`} />
        </Route>
      </Switch>
    </>
  );
};

export const OrderDetail: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ order: string }>();
  const order = useOrderSingle(parseInt(route.params.order));
  const menu = useMenuElement(order.element?.nuid || "Loading...", `Order ${route.params.order}`);
  assert(order.state);
  const _order = useLatch(order.element, order.state.error);
  return (
    <XemwayMenuProvider {...menu}>
      {order.loadingIO}
      <NestorPermissionContextProvider ressource="order" id={route.params.order}>
        {" "}
        <div className="container-fluid p-3">
          {_order ? <OrderDetailInner order={_order} refresh={order.refresh} /> : null}
        </div>
      </NestorPermissionContextProvider>
    </XemwayMenuProvider>
  );
};
