import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import NestorServerRoutes from "nestor/server_routes";

import { NestorDeviceModelId } from "nestor/types/inventory/device_model";
import {
  NestorDeviceModelParameter,
  NestorDeviceModelParameterType,
} from "nestor/types/inventory/device_model_parameter";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";

type T = NestorDeviceModelParameter;

export const useDeviceModelParameterList = (
  props: CommonFieldUI<string | number | undefined> & {
    filter?: NestorDeviceModelParameterType;
    modelId?: NestorDeviceModelId;
  }
) => {
  return useFormSelectRemoteField<T, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: props.modelId ? NestorServerRoutes.device_model_parameter_list_flat(props.modelId) : undefined,
    keyMap: (el: T) => el.id.toString(),
    valMap: (el: T) => el.name,
    filter: (el: T) => {
      if (props.filter) {
        return el.type === props.filter;
      }
      return true;
    },
  });
};
