import { SkuJournalCard } from "nestor/components/nestor/stock/Journal";
import { SkuInfos } from "nestor/components/nestor/stock/skus/Info";
import { SkuListCard } from "nestor/components/nestor/stock/skus/List";
import { NestorPermissionContextProvider, useHasPermission } from "nestor/contexts/NestorPermissionContext";
import { useSKUSingle } from "nestor/hooks/api/useSKUSingle";
import { NestorSku } from "nestor/types/stock/sku";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { SkuEditPage } from "./SKUEdit";

const SKUDetailsInner: React.FunctionComponent<{
  SKU: NestorSku;
  refresh: Function;
}> = (props) => {
  const route = useRouteMatch<{ Container: string }>();
  const canEdit = useHasPermission("edit");
  useMenuElement("Details", route.url + "/details");
  useMenuElement("Edit information", route.url + "/edit", canEdit);
  useMenuElement("Journal", route.url + "/journal");
  useMenuElement("Derived SKUs", route.url + "/derived");

  return (
    <NestorPermissionContextProvider ressource="Container" id={route.params.Container}>
      <Switch>
        <Route path={`${route.url}/details`}>
          <SkuInfos sku={props.SKU} refresh={props.refresh}></SkuInfos>
        </Route>

        {canEdit ? (
          <Route path={`${route.url}/edit`}>
            <SkuEditPage sku={props.SKU} />
          </Route>
        ) : null}

        <Route path={`${route.url}/journal`}>
          <SkuJournalCard sku={props.SKU}></SkuJournalCard>
        </Route>

        <Route path={`${route.url}/derived`}>
          <SkuListCard header="Derived SKU list" derived_from_sku={props.SKU}></SkuListCard>
        </Route>

        <Route>
          <Redirect to={`${route.url}/details`} />
        </Route>
      </Switch>
    </NestorPermissionContextProvider>
  );
};

export const SKUDetails: React.FunctionComponent<{}> = (props) => {
  const route = useRouteMatch<{ sku: string }>();
  const SKU = useSKUSingle(route.params.sku);
  const menu = useMenuElement(
    SKU.element ? `SKU ${SKU.element.nuid || " -- no ID"}` : "Loading...",
    `${route.url}/${route.params.sku}`
  );

  return (
    <XemwayMenuProvider {...menu}>
      {SKU.loadingIO}
      <NestorPermissionContextProvider ressource="sku" id={route.params.sku}>
        <div className="container-fluid p-3">
          {SKU.element ? <SKUDetailsInner SKU={SKU.element} refresh={SKU.refresh} /> : null}
        </div>
      </NestorPermissionContextProvider>
    </XemwayMenuProvider>
  );
};
