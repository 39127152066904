import { ButtonsColumn } from "@csem/lists";
import { NestorAttachment } from "nestor/types/system/attachment";
import settings from "settings";

/**
 *
 * @param urlBuilder A method that returns an URL from which the backend URL will be added.
 * @param text The text to show on the button
 * @param visibility A method that returns the visibility state, true for visible, false for a hidden button
 * @returns
 */
const DownloadColumn = function <T, FW>(
  urlBuilder: (el: T, fw: FW) => string | undefined,
  text = "Download",
  visibility?: (el: T, fw: FW) => boolean,
  id: string = "download"
) {
  return ButtonsColumn(id, "", [
    {
      title: text,
      theme: "info",
      // Only pass down the visibility if it exists, otherwise a method that returns true
      visibility: (el, fw) => {
        if (!urlBuilder(el, fw)) {
          return false;
        }
        return visibility ? visibility(el, fw) : true;
      },
      cb: (el: T, fw: FW) => {
        // Opens a new window
        window.open(settings.NESTOR_URL + urlBuilder(el, fw), "_blank");
      },
    },
  ]);
};

const urlFromAttachment = (attachment: NestorAttachment) =>
  `/download/attachment/${attachment.id}/${encodeURIComponent(attachment.name || "")}`;

export const DownloadAttachmentColumn = function <T>(attachmentGetter: (el: T) => NestorAttachment | null | undefined) {
  return DownloadColumn((el: T) => {
    const attachment = attachmentGetter(el);
    if (attachment) return urlFromAttachment(attachment);
    return undefined;
  });
};

export const DownloadAttachmentFbColumn = function <T, U, V>(
  name: string,
  title: string,
  btnText: string,
  attachmentGetter: (el: T) => NestorAttachment | null | undefined,
  elementWhenNull?: JSX.Element
) {
  return {
    name,
    label: title,
    renderer: function (el: T, fw: U, hk: V) {
      const attachment = attachmentGetter(el);
      console.log(attachment);
      if (!attachment) {
        return elementWhenNull || null;
      }

      return (
        <button
          key={btnText}
          className={`btn btn-info`}
          onClick={(e) => {
            window.open(settings.NESTOR_URL + urlFromAttachment(attachment), "_blank");
          }}
        >
          {btnText}
        </button>
      );
    },
  };
};

export default DownloadColumn;
