import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorDeviceCategory } from "nestor/types/inventory/device_category";
import { useFormSelectRemoteField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";
type T = NestorDeviceCategory;

export const useDeviceCategorySelectField = (props: CommonFieldUI<string | number | undefined>) => {
  return useFormSelectRemoteField<NestorDeviceCategory, any>({
    ...props,
    useApiHook: useNestorAPI,
    url: `inventory/categories/list`,
    keyMap: (el: T) => el.id.toString(),
    valMap: (el: T) => el.name,
  });
};
