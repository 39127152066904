import { DeviceUploadPhoto } from "nestor/components/modals/UploadPhoto";
import { LocationRenderer } from "nestor/components/renderers/LocationRenderer";
import NestorFrontendRoutes from "nestor/frontend_routes";
import { NestorDevice } from "nestor/types/inventory/device";
import { NestorDeviceModel } from "nestor/types/inventory/device_model";
import { LabelPrinterSizes } from "nestor/types/util/label_printer";
import { CardComponent, useConfirmationModal } from "@csem/shared-utils";
import assert from "assert";
import { NavigationLookup } from "@csem/lists";
import { useModalOfT } from "@csem/shared-utils";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import settings from "settings";
import { EmployeeInfo } from "../../../renderers/EmployeeInfos";
import { EmployeeBadgeComplete } from "../../company/employees/EmployeeBadge";
import { InfoBadge } from "../../util/InfoBadge";
import { LabelPrinterButton } from "../../util/LabelPrinter";
import { TextWithFallback } from "../../util/TextOrNoInfo";
import AssociatedToCard from "./AssociatedTo";
import { InventoryNumberSpan } from "./InventoryNumber";
import { MaintenanceListCard } from "./maintenances/Info";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";

export const DeviceImage = ({
  device,
  ...props
}: { device: NestorDevice } & React.HTMLAttributes<HTMLImageElement>) => {
  return (
    <div className="d-flex gap-3 align-items-center">
      {device.picture ? (
        <div>
          <img {...props} alt="Device" src={settings.NESTOR_URL + device.picture.blob?.url} />
          <br />
          <small className="text-muted">Photo of the device</small>
        </div>
      ) : null}

      {device.model?.picture ? (
        <div>
          <img {...props} alt="Model of the device" src={settings.NESTOR_URL + device.model.picture.blob?.url} />
          <br />
          <small className="text-muted">Generic image of the device model</small>
        </div>
      ) : null}
    </div>
  );
};

const NetworkingCard: React.FunctionComponent<{
  device: NestorDevice;
}> = ({ device }) => {
  return (
    <CardComponent header="Networking" additionalClasses="h-100">
      <div className="row align-items-middle mb-2">
        <div className="col-4">Hostname</div>
        <div className="col-8">
          <h6>
            <TextWithFallback text={device.hostname} fallback="No information" />
          </h6>
        </div>
      </div>

      <div className="row align-items-middle mb-2">
        <div className="col-4">MAC Address</div>
        <div className="col-8">
          <h6>
            <TextWithFallback text={device.mac_address} fallback="No information" />
          </h6>
        </div>
      </div>
    </CardComponent>
  );
};

const CalibrationCard: React.FunctionComponent<{
  device: NestorDevice;
}> = ({ device }) => {
  return (
    <CardComponent header="Calibration">
      {device.calibration_ranges?.length === 0 ? (
        device.is_calibrated > 0 ? (
          <div className="alert alert-danger">No calibration range for this device, but calibrations are required</div>
        ) : (
          <div className="alert alert-info">No calibration ranges</div>
        )
      ) : (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Calibration interval</th>
                <th>Metrological check interval</th> <th>Ending date</th>
              </tr>
            </thead>
            <tbody>
              {device.calibration_ranges!.map((c) => {
                // Print danger sign if there is no calibration for this range, or if it's too old
                let danger =
                  c.latest_calibration == null ||
                  !c.latest_calibration ||
                  moment(c.latest_calibration.end).isBefore(moment());

                let end = c.latest_calibration
                  ? moment(c.latest_calibration.end).format("YYYY-MM-DD")
                  : "No calibration for this range";

                let period =
                  c.interval_days > 0 ? (
                    <> {c.interval_days} days</>
                  ) : (
                    <span className="text-muted">No calibration interval</span>
                  );

                let metrological_period =
                  c.metrological_check_interval_days > 0 ? (
                    <>{c.metrological_check_interval_days} days</>
                  ) : (
                    <span className="text-muted">No metrological checks</span>
                  );

                return (
                  <tr className={danger ? "text-danger" : ""}>
                    <td>{c.name}</td>
                    <td>{c.description}</td>
                    <td>{period}</td>
                    <td>{metrological_period}</td>
                    <td>{end}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </CardComponent>
  );
};

export const DeviceOneLine: React.FunctionComponent<{
  device: NestorDevice;
  refresh: Function;
}> = ({ device, refresh }) => {
  return (
    <CardComponent header="Device you're looking at" additionalClasses={"mb-2"}>
      <div className="row">
        <div className="col-xl-1 col-md-4 col-6 align-self-center  mb-2">
          <InventoryNumberSpan text={device.nuid} device={device} />
        </div>

        <div className="col-xl-1  col-md-4 col-6 mb-2">
          <strong>Serial number:</strong>
          <br />
          {device.serialnumber || "N/A"}
        </div>

        <div className="col-xl-1  col-md-4 col-6  mb-2">
          <strong>Manufacturer:</strong>
          <br />
          {device.model?.manufacturer?.name}
        </div>

        <div className="col-xl-2  col-md-4 col-6  mb-2">
          <strong>Model:</strong>
          <br />
          {device.model?.model}
        </div>

        <div className="col-xl-2  col-md-4 col-6  mb-2">
          <strong>Description:</strong>
          <br />
          {device.model?.description}
        </div>

        <div className="col-xl-2  col-md-4 col-6  mb-2">
          <strong>Location:</strong>
          <br />
          <LocationRenderer location={device.location} fallbackText="No information"></LocationRenderer>
        </div>

        <div className="col-xl-2 col-md-4 col-6  mb-2">
          {device.owner ? <EmployeeBadgeComplete employee={device.owner} /> : null}
        </div>
      </div>
    </CardComponent>
  );
};

export const DeviceInfos: React.FunctionComponent<{
  device: NestorDevice;
  refresh: Function;
}> = ({ device, refresh }) => {
  /*useEffect(() => {
    refresh();
  }, [refresh]);
*/
  const { showModal, Modal } = useModalOfT<NestorDevice | NestorDeviceModel, {}>(DeviceUploadPhoto, refresh);

  const apiDeletePhoto = useNestorAPI<any, NestorDevice>(`inventory/devices/${device.id}/photo`, false, undefined, {
    method: "DELETE",
  });
  const apiDeletePhotoDoQuery = apiDeletePhoto.doQuery;
  const invalidate = useConfirmationModal<NestorDevice>(
    "Are you sure ?",
    (param) => {
      return "Please confirm that you want to delete this photo";
    },
    useCallback(() => {
      apiDeletePhotoDoQuery().then(() => {
        refresh();
      });
    }, [apiDeletePhotoDoQuery]),
    "Could not delete the photo",
    apiDeletePhoto
  );

  return (
    <>
      {Modal}
      {invalidate.Modal}
      {!!device.is_obsolete && (
        <div className="alert alert-warning">
          This equipment is <strong>obsolete</strong>.
        </div>
      )}
      <div className="row">
        <div className="col-md-6  mb-2">
          <CardComponent header="General information">
            <div className="row align-items-center mb-3">
              <div className="col-4">Nestor NUID</div>
              <div className="col-4">
                <h5 className="mb-0">
                  <InventoryNumberSpan text={device.nuid} device={device} />
                </h5>
              </div>
            </div>
            {device.NI ? (
              <div className="row  mb-3">
                <div className="col-4">Nestor 1 ID</div>
                <div className="col-8">
                  {" "}
                  <h5>
                    <InventoryNumberSpan text={device.NI} device={device} />
                  </h5>
                </div>
              </div>
            ) : null}

            <EmployeeInfo text={"Employee responsible"} employee={device.owner} />
            <div className="row mb-2">
              <div className="col-4">Location</div>
              <div className="col-8">
                <h6>
                  <LocationRenderer location={device.location} fallbackText="No information"></LocationRenderer>
                </h6>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Manufacturer</div>
              <div className="col-8">
                <h6> {device.model?.manufacturer?.name}</h6>
              </div>
            </div>

            {device.model && (
              <>
                <div className="row align-items-center mb-2">
                  <div className="col-4">Model</div>
                  <div className="col-7">
                    <h6> {device.model?.model}</h6>
                  </div>
                  <div className="col-1">
                    <NavigationLookup icon="search" path={NestorFrontendRoutes.devicemodel_details(device.model.id)} />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-4">Description</div>
                  <div className="col-8">
                    <h6> {device.model?.description}</h6>
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-4">Characterisics</div>
                  <div className="col-8">
                    <div dangerouslySetInnerHTML={{ __html: device.model?.characteristic }} />
                  </div>
                </div>
              </>
            )}

            {device.serialnumber ? (
              <div className="row align-items-center mb-3">
                <div className="col-4">Serial number</div>
                <div className="col-8">
                  <h6> {device.serialnumber}</h6>
                </div>
              </div>
            ) : null}
            <div className="row align-items-center mb-2">
              <div className="col-4">Category</div>
              <div className="col-8">
                <h6> {device.model?.type?.category.name}</h6>
              </div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col-4">Type</div>
              <div className="col-8">
                <h6> {device.model?.type?.name}</h6>
              </div>
            </div>

            <div className="hstack gap-2">
              {device.is_reference ? (
                <InfoBadge theme="warning" icon="shield-fill-exclamation">
                  Metrological reference
                </InfoBadge>
              ) : null}
              {device.active_loan !== null && device.active_loan !== undefined ? (
                <InfoBadge theme="warning" icon="alarm">
                  Currently loaned
                </InfoBadge>
              ) : device.is_fixed ? (
                <InfoBadge theme="danger" icon="alarm-fill">
                  May not be borrowed
                </InfoBadge>
              ) : (
                <InfoBadge theme="info" icon="alarm">
                  Available for a loan
                </InfoBadge>
              )}

              {device.is_obsolete ? (
                <InfoBadge theme="danger" icon="snow2">
                  Obsolete
                </InfoBadge>
              ) : device.is_unavailable ? (
                <InfoBadge theme="warning" icon="clock-history">
                  Not available
                </InfoBadge>
              ) : (
                <InfoBadge theme="info" icon="clock-fill">
                  Active
                </InfoBadge>
              )}
            </div>
          </CardComponent>
        </div>
        <div className="col-md-6  mb-2">
          <div className="vstack gap-2">
            <CardComponent header="Image">
              {/* TODO extract component */}
              <DeviceImage device={device} style={{ maxWidth: "100%" }} />

              <div className="d-flex justify-content-center gap-3 mt-4">
                {device.picture ? (
                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      invalidate.invoke(device);
                    }}
                  >
                    <em className="bi bi-x"></em>
                    &nbsp;Delete photo
                  </button>
                ) : null}

                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    showModal(device, {});
                  }}
                >
                  <em className="bi bi-upload"></em>
                  &nbsp;Upload a photo for this device
                </button>

                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    assert(device.model);
                    showModal(device.model, {});
                  }}
                >
                  {" "}
                  <em className="bi bi-upload"></em>
                  &nbsp; Upload a generic photo for this device model
                </button>
              </div>
            </CardComponent>
            <div className="row">
              <div className="col-xl-6 col-12">
                <CardComponent header="QR code labels" additionalClasses="h-100">
                  <div className="vstack gap-2">
                    <LabelPrinterButton
                      text="small format"
                      size={LabelPrinterSizes.SZ_12}
                      url={`inventory/devices/${device.id}/label/standard/pdf/print`}
                    />

                    <LabelPrinterButton
                      text="network format"
                      size={LabelPrinterSizes.SZ_24}
                      url={`inventory/devices/${device.id}/label/network/pdf/print`}
                    />

                    <LabelPrinterButton
                      text="address format"
                      size={LabelPrinterSizes.SZ_36}
                      url={`inventory/devices/${device.id}/label/address/pdf/print`}
                    />

                    <LabelPrinterButton
                      text="tiny format"
                      size={LabelPrinterSizes.SZ_9}
                      url={`inventory/devices/${device.id}/label/tiny/pdf/print`}
                    />
                  </div>
                </CardComponent>
              </div>
              <div className="col-xl-6 col-12">
                <NetworkingCard device={device} />
              </div>
            </div>

            <AssociatedToCard device={device} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-2">
          <CalibrationCard device={device} />
        </div>
        <div className="col-md-6 mb-2">
          <MaintenanceListCard device={device} />
        </div>
      </div>
    </>
  );
};
