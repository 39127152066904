import { useMenuElement } from "@csem/shared-utils";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import {
  NonConformCalibrationDeviceListCard,
  NonConformMetrologicalCheckDeviceListCard,
  NonConformMaintenanceDeviceListCard,
} from "nestor/components/nestor/inventory/devices/NonConformities";
export function NonConformitiesMain() {
  const { url } = useRouteMatch();

  useMenuElement("Uncalibrated devices", url + "/calibration");
  useMenuElement("Unchecked devices", url + "/metrological_checks");
  useMenuElement("Unmaintained devices", url + "/maintenances");

  return (
    <Switch>
      <Route path={`${url}/calibration`}>
        <NonConformCalibrationDeviceListCard></NonConformCalibrationDeviceListCard>
      </Route>
      <Route path={`${url}/metrological_checks`}>
        <NonConformMetrologicalCheckDeviceListCard></NonConformMetrologicalCheckDeviceListCard>
      </Route>

      <Route path={`${url}/maintenances`}>
        <NonConformMaintenanceDeviceListCard></NonConformMaintenanceDeviceListCard>
      </Route>

      <Route>
        <Redirect to={`${url}/calibration`} />
      </Route>
    </Switch>
  );
}
