import { useMyDivision } from "nestor/hooks/me";
import { NestorDivisions } from "nestor/types/company/division";
import { useMenuElement, XemwayMenuProvider } from "@csem/shared-utils";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { DeviceDetails } from "./DeviceDetail";
import { DevicesList } from "./DeviceList";
import { useEffect } from "react";
export function DevicesMain() {
  const { url } = useRouteMatch();

  NestorDivisions.forEach((d) => {
    /* eslint-disable react-hooks/rules-of-hooks */
    useMenuElement("List in BU " + d, url + "/list/" + d);
  });

  //const list = useMenuElement("List of current devices", url + "/list");
  // const create = useMenuElement("Create a new device", url + "/create");
  //const ctx = useContext(XemwayMenuContext);
  const myDiv = useMyDivision();

  return (
    <Switch>
      <Route path={`${url}/list/:div`} key="list">
        <DevicesList></DevicesList>
      </Route>

      <Route path={`${url}/:device`}>
        <DeviceDetails />
      </Route>

      <Route>
        <Redirect to={`${url}/list/${myDiv}`} />
      </Route>
    </Switch>
  );
}
