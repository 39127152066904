// Hooks
import { useAutoPcbFeederSearch, useSemiautoPcbFeederSearch } from "nestor/components/forms/fields/PcbFeederSearch";
import { NestorPOST, useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { usePcbComponentList } from "nestor/hooks/api/usePcbAssembly";
import NestorServerRoutes from "nestor/server_routes";
import {
  NestorPcbAssembly,
  NestorPcbComponent,
  NestorPcbComponentMountType,
} from "nestor/types/manufacturing/pcb_assembly";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { useFormCheckboxField } from "@csem/forms";
import { indexable } from "@csem/forms";
import { useFormSelectObj } from "@csem/forms";
import { Field } from "@csem/forms";
import { FieldLogic } from "@csem/forms";
// Classes
// Components
// Contexts
// ## Generic
import { SimpleColumn, TableRenderer } from "@csem/lists";
import { GenericListContent, HasButton, HasInlineEdition } from "@csem/lists";
import { cloneDeep } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { apiList } from "@csem/api";
import { useModalOfT } from "@csem/shared-utils";
import SemiAutoPlacementFile from "./SemiAutoFile";
import { Button, Modal } from "react-bootstrap";

function useEmpty(props: FieldLogic<indexable | undefined>) {
  return { value: null, Content: null };
}

type T = NestorPcbComponent & { __list?: Array<NestorPcbComponent> }; // For the grouped view
type FWLst = {
  grouped: boolean;
  assembly: NestorPcbAssembly;
  openSemiTop: (list: apiList<T, any, any>, fw: FWLst) => void;
  openSemiBottom: (list: apiList<T, any, any>, fw: FWLst) => void;
};
type FW = { commit: (el: T) => void };

const PlacementFile = (layer: "top" | "bottom") => {
  const C: React.FunctionComponent<{
    show: boolean;
    onValidated: (out?: any) => void;
    handleClose: () => void;
    el?: apiList<T, any, any>;
    fw: FWLst;
  }> = ({ show, onValidated, handleClose, el, fw }) => {
    if (!el) {
      return null;
    }
    return (
      <Modal size={"xl"} show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Semi-auto PCB placement file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SemiAutoPlacementFile assembly={fw.assembly} layer={layer} componentListAPI={el}></SemiAutoPlacementFile>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e) => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return C;
};

const PlacementFileSemiAutoTop = PlacementFile("top");
const PlacementFileSemiAutoBottom = PlacementFile("bottom");
type HOOK = {
  enabled: Field<any>;
  mode: Field<any>;
  //  designator: Field<any>;
  //   footprint: Field<any>;
  //   comment: Field<any>;
  /* mid_x: Field<any>;
    mid_y: Field<any>;
    pad_x: Field<any>;
    pad_y: Field<any>;
    rotation: Field<any>;
    layer: Field<any>;*/

  feeder: JSX.Element;
  faulty: Field<any>;
};

const Table = (function () {
  return TableRenderer<T, FWLst, FW, HOOK, "id">({
    key: "id",
    columns: [
      //            NUIDColumn,

      SimpleColumn("enabled", "Place", (e, fw, hk) => hk.enabled.Content),
      SimpleColumn("mode", "Mode", (e, fw, hk) => hk.mode.Content),
      SimpleColumn("designator", "Designator", (e, fw, hk) => e.designator),
      SimpleColumn("footprint", "Footprint", (e, fw, hk) => e.footprint),
      SimpleColumn("comment", "Comment", (e, fw, hk) => e.comment),
      SimpleColumn("description", "Description", (e, fw, hk) => e.description),
      SimpleColumn("feeder", "Feeder", (el, fw, hk) => hk.feeder),

      SimpleColumn("mid_x", "Mid X", (e, fw, hk) => e.mid_x),
      SimpleColumn("mid_y", "Mid Y", (e, fw, hk) => e.mid_y),
      SimpleColumn("rotation", "Rotation", (e, fw, hk) => e.rotation),
      SimpleColumn("layer", "Layer", (e, fw, hk) => (e.layer === "top" ? "Top" : "Bottom")),

      /*
            SimpleColumn("mid_x", "Mid X", (e, fw, hk) => hk.mid_x.Content),
            SimpleColumn("mid_y", "Mid Y", (e, fw, hk) => hk.mid_y.Content),
            SimpleColumn("pad_x", "Pad X", (e, fw, hk) => hk.pad_x.Content),
            SipleColumn("pad_y", "Pad y", (e, fw, hk) => hk.pad_y.Content),
            SimpleColumn("rotation", "Rotation", (e, fw, hk) => hk.rotation.Content),
            SimpleColumn("layer", "Layer", (e, fw, hk) => hk.layer.Content),*/
      SimpleColumn("faulty", "Faulty", (e, fw, hk) => hk.faulty.Content),
    ],

    useLineParamInjection: (el, forwarded) => {
      const enabled = useFormCheckboxField({
        label: "",
        defaultValue: !!el.enabled,
      });

      const mode = useFormSelectObj({
        label: "Mode",
        options: {
          [NestorPcbComponentMountType.MANUAL]: "Manual",
          [NestorPcbComponentMountType.ERZA]: "Erza",
          [NestorPcbComponentMountType.SEMIAUTO]: "Semi",
          [NestorPcbComponentMountType.AUTO]: "Auto",
          [NestorPcbComponentMountType.TBD]: "TBD",
        },
        originalValue: el.mode,
        defaultValue: "manual",
      });

      const autoFeeder = useAutoPcbFeederSearch({
        label: "PCB feeder",
        defaultValue: undefined,
        originalValue: el.feeder_id || undefined,
      });

      const semiautoFeeder = useSemiautoPcbFeederSearch({
        label: "PCB feeder",
        defaultValue: undefined,
        originalValue: el.feeder_id || undefined,
      });
      const saveFeederAssociation = useNestorAPI(
        mode.value === NestorPcbComponentMountType.SEMIAUTO
          ? semiautoFeeder.selectedItem
            ? NestorServerRoutes.pcb_assembly_feeder_add_alias(
                semiautoFeeder.selectedItem.feederset_id,
                semiautoFeeder.selectedItem.id
              )
            : undefined
          : mode.value === NestorPcbComponentMountType.AUTO
          ? autoFeeder.selectedItem
            ? NestorServerRoutes.pcb_assembly_feeder_add_alias(
                autoFeeder.selectedItem.feederset_id,
                autoFeeder.selectedItem.id
              )
            : undefined
          : undefined,
        false,
        undefined,
        NestorPOST
      );

      const faulty = useFormSelectObj({
        label: "Faulty",
        options: {
          1: "Yes",
          0: "No",
        },
        originalValue: el.faulty,
        defaultValue: 0,
      });

      const { value: enabledValue } = enabled;
      const { value: faultyValue } = faulty;
      const { value: autoFeederValue } = autoFeeder;
      const { value: semiautoFeederValue } = semiautoFeeder;
      const { commit } = forwarded;
      const { value: modeValue } = mode;
      useEffect(() => {
        assert(modeValue);
        //   console.log("in effect", enabledValue, faultyValue, modeValue, autoFeederValue, semiautoFeederValue, el);
        // If any of the field has been changed with respect to the original value
        // Be careful, if this condition passes on every render, we end up in an infinite loop
        // Therefore, we need to really make sure that the conditions only return true when there has been a user input
        if (
          enabledValue !== !!el.enabled ||
          faultyValue !== el.faulty ||
          modeValue !== el.mode ||
          (modeValue === NestorPcbComponentMountType.AUTO &&
            autoFeederValue !== el.feeder_id &&
            autoFeederValue !== undefined) ||
          (modeValue === NestorPcbComponentMountType.SEMIAUTO &&
            semiautoFeederValue !== el.feeder_id &&
            semiautoFeederValue !== undefined)
        ) {
          // Then commit it to the store

          if (el.__list) {
            el.__list = el.__list.map((el) => {
              Object.assign(el, {
                enabled: enabledValue ? 1 : 0,
                feeder_id: modeValue === NestorPcbComponentMountType.AUTO ? autoFeederValue : semiautoFeederValue,
                mode: modeValue as NestorPcbComponentMountType,
              });
              return el;
            });
            commit({
              ...el,
              enabled: enabledValue ? 1 : 0,
              feeder_id: modeValue === NestorPcbComponentMountType.AUTO ? autoFeederValue : semiautoFeederValue,
              mode: modeValue as NestorPcbComponentMountType,
            });
          } else {
            commit({
              ...el,
              enabled: enabledValue ? 1 : 0,

              feeder_id: modeValue === NestorPcbComponentMountType.AUTO ? autoFeederValue : semiautoFeederValue,
              mode: modeValue as NestorPcbComponentMountType,
            });
          }
        }
      }, [commit, enabledValue, faultyValue, modeValue, autoFeederValue, semiautoFeederValue, el]);

      const saveFeeder = (
        <button
          className="btn btn-primary h-100"
          onClick={() => saveFeederAssociation.doQuery(undefined, { alias: el.description })}
        >
          {saveFeederAssociation.state.loading ? (
            <em className="bi bi-hourglass"></em>
          ) : saveFeederAssociation.state.success ? (
            <em className="bi bi-check"></em>
          ) : (
            "Save alias"
          )}
        </button>
      );

      const feeder =
        modeValue === NestorPcbComponentMountType.AUTO ? (
          <div className="d-flex align-items-center">
            <div>{autoFeeder.Content}</div>
            {!!autoFeeder.selectedItem && saveFeeder}
          </div>
        ) : modeValue === NestorPcbComponentMountType.SEMIAUTO ? (
          <div className="d-flex  align-items-center">
            <div>{semiautoFeeder.Content}</div>
            {!!semiautoFeeder.selectedItem && saveFeeder}
          </div>
        ) : (
          <></>
        );

      return {
        enabled,
        mode,
        feeder,
        faulty,
      };
    },

    useColumnWidth: (e, fw) => {
      let columns = {
        enabled: "80px",
        mode: "100px",
        designator: "100px",
        mid_x: "70px",
        mid_y: "70px",
        pad_x: "70px",
        pad_y: "70px",
        rotation: "70px",
        layer: "70px",
        faulty: "95px",
      };

      if (fw.grouped) {
        columns.designator = "300px";
      }

      return columns;
    },

    useColumnVisibility: function (c, fw) {
      let o: { [x: string]: boolean } = {};
      if (fw.grouped) {
        o = {
          mid_x: false,
          mid_y: false,
          pad_x: false,
          pad_y: false,
          rotation: false,
          layer: false,
        };
      } else {
        o = {};
      }
      return o;
    },
  });
})();

const GenericContainer = HasInlineEdition(
  HasButton(
    HasButton(
      GenericListContent<FWLst, T>(),
      "_semi_top",
      "right",
      "Semi-automatic file (top)",
      "cpu",
      "info",
      undefined,
      (el, fw) => {
        fw.openSemiTop(el, fw);
      }
    ),
    "_semi_bottom",
    "right",
    "Semi-automatic file (bottom)",
    "cpu",
    "info",
    undefined,
    (el, fw) => {
      fw.openSemiBottom(el, fw);
    }
  ),
  (el: T) => el.id,
  function useSave(fw) {
    return useNestorAPI(NestorServerRoutes.pcb_assembly_save_bom(fw.assembly.id), false, undefined, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });
  }
);

const useComponentList = (componentListAPI: ReturnType<typeof usePcbComponentList>, grouped: boolean) => {
  let src = componentListAPI;
  if (grouped && src.list) {
    const newList: { [description: string]: { d: Array<string>; c: Array<NestorPcbComponent> } } = {};
    for (let o of src.list) {
      newList[o.description] = newList[o.description] || { d: [], c: [] };
      newList[o.description].c.push(o);
      newList[o.description].d.push(o.designator);
    }

    const outList: Array<T> = [];
    for (let o in newList) {
      outList.push(
        Object.assign(
          {},
          {
            ...newList[o].c[0],
            id: -newList[o].c[0].id,
            __list: newList[o].c,
            designator: newList[o].d.join(", "),
          }
        )
      );
    }

    src = { ...src, list: outList };
  }

  return src;
};
export const PCBComponentListCard = ({
  assembly,
  apiCall,
}: {
  assembly: NestorPcbAssembly;
  apiCall: ReturnType<typeof useComponentList>;
}) => {
  const [grouped, setGrouped] = useState<boolean>(false);

  const modalTopSemi = useModalOfT<apiList<T, any, any>, FWLst>(PlacementFileSemiAutoTop);
  const modalBottomSemi = useModalOfT<apiList<T, any, any>, FWLst>(PlacementFileSemiAutoBottom);

  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "designator",
      },
    ];

    defaultFilter.take = 100000;
    return defaultFilter;
  }, []);

  return (
    <div className="m-2">
      {modalTopSemi.Modal}
      {modalBottomSemi.Modal}
      <CardComponent header="PCB component list">
        <div className="row">
          <div className="col-sm-3">View Type</div>
          <div className="col-sm-9">
            <button
              className={`btn btn-${!grouped ? "primary active" : "secondary"}`}
              onClick={() => setGrouped(false)}
            >
              Individual view
            </button>
            <button className={`btn btn-${grouped ? "primary active" : "secondary"}`} onClick={() => setGrouped(true)}>
              Grouped view
            </button>
          </div>
        </div>

        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericContainer
            //cardHeader="Gateway list"
            list={useComponentList(apiCall, grouped)}
            fwProps={useMemo(() => {
              return {
                grouped,
                assembly,
                openSemiTop: modalTopSemi.showModal,
                openSemiBottom: modalBottomSemi.showModal,
              };
            }, [grouped, assembly, modalTopSemi.showModal, modalBottomSemi.showModal])}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
