import { useMyDivision } from "nestor/hooks/me";
import { CriticalityClasses } from "nestor/types/purchasing/article";
import { useFormSelectObj } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";

export const useCriticalityClass = function <T extends { class: CriticalityClasses }>(el?: T) {
  return useFormSelectObj({
    label: "Criticality",
    helpText: "Read EP-610 for more information on this",
    disabled: useMyDivision() !== "E",
    defaultValue: el ? el.class : "C",
    validationOnChange: useFieldOptRequired(useMyDivision() === "E"),
    options: { A: "A", B: "B", C: "C" },
  });
};
