import { DateColumn } from "nestor/components/tables/columns/DateColumn";
import { useCalibrationRangeList } from "nestor/hooks/api/useCalibrationRangeList";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { NestorCalibrationRange } from "nestor/types/inventory/calibration_range";
import { NestorDevice } from "nestor/types/inventory/device";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { CalibrationRangeAddEditModal } from "../modals/AddEditCalibrationRange";
import NestorServerRoutes from "nestor/server_routes";
type T = NestorCalibrationRange;
type FWLst = { device: NestorDevice };
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;

const GenericCalibrationRangeTable = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("name", "Name", (e) => e.name),
      SimpleColumn("description", "Description", (e) => e.description),
      SimpleColumn("periodicity", "Periodicity", (e) =>
        e.interval_days < 0 ? "On demand" : e.interval_days + " days"
      ),

      DateColumn("created_at", "Created", "created_at", (e) => e.created_at),
      DateColumn("revoked_at", "Revoked", "revoked_at", (e) => e.revoked_at),
      ButtonsColumn("actions", "Actions", [RemoveButton(), EditButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },

    useColumnWidth: () => {
      return { actions: "150px", created_at: "150px", revoked_at: "150px" };
    },
  });
})();

const GenericCalibrationRangeListContainer = (function () {
  return HasDeletion(
    HasEdition(
      HasAddition(
        HasRefresh(GenericListContent<FWLst, T>()),
        CalibrationRangeAddEditModal,
        "Add a new calibration range",
        "plus",
        "primary"
      ),
      CalibrationRangeAddEditModal
    ),
    "Confirm calibration range revocation",
    (v: T) => `Are you sure to revoke this calibration range ?`,
    (props) => {
      return useNestorAPI<undefined, NestorCalibrationRange>(
        (cal) => {
          assert(cal !== undefined);
          return NestorServerRoutes.inventory.devices.calibrations.ranges.delete(cal.id);
        },
        false,
        undefined,
        { method: "DELETE" },

        props.list.refresh
      );
    }
  );
})();

export const CalibrationRangesCard = (props: { device: NestorDevice }) => {
  return (
    <div className="m-2">
      <CardComponent header="Calibration ranges">
        <StandardTableFilterCtxProvider>
          <GenericCalibrationRangeListContainer
            //cardHeader="Gateway list"
            list={useCalibrationRangeList(props.device.id)}
            fwProps={{ device: props.device }}
            listTemplate={GenericCalibrationRangeTable}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
