// Hooks
import { PCBAssemblyFeederAddEditModal } from "nestor/components/nestor/manufacturing/pcb_assembly/modals/AddEditPcbAssemblyFeeder";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { usePcbAssemblyFeederList } from "nestor/hooks/api/usePcbAssembly";
import NestorServerRoutes from "nestor/server_routes";
import { NestorPcbAssemblyFeeder, NestorPcbAssemblyFeederSet } from "nestor/types/manufacturing/pcb_assembly";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TextFilterFactory } from "@csem/lists";
import { HasStdTablePagination } from "@csem/lists";
// Classes
// Components
// Contexts
// ## Generic
import { ButtonsColumn, FilterableColumn, SimpleColumn, SortableColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";

type T = NestorPcbAssemblyFeeder;
type FWLst = { feeder_set: NestorPcbAssemblyFeederSet };
type FW = EditMethod<T, FWLst> & RemoveMethod<T, FWLst>;
type HOOK = {};

const Table = (function () {
  return TableRenderer<T, FWLst, FW, {}, "id">({
    key: "id",
    columns: [
      FilterableColumn(
        SimpleColumn("value", "Value", (e) => e.value),
        TextFilterFactory({ label: "Search by value" }),
        "value"
      ),

      FilterableColumn(
        SimpleColumn("footprint", "Footprint", (e) => e.footprint),
        TextFilterFactory({ label: "Search by footprint" }),
        "footprint"
      ),

      SortableColumn(
        SimpleColumn("x", "Feeder X", (e) => e.feeder_x),
        "feeder_x"
      ),
      SortableColumn(
        SimpleColumn("y", "Feeder Y", (e) => e.feeder_y),
        "feeder_y"
      ),

      ButtonsColumn("actions", "", [EditButton(), RemoveButton()]),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const GenericContainer = (function () {
  return HasDeletion(
    HasEdition(
      HasAddition(
        HasRefresh(HasStdTablePagination(GenericListContent<FWLst, T>())),
        PCBAssemblyFeederAddEditModal,
        "Add a new feeder",
        "plus",
        "primary"
      ),
      PCBAssemblyFeederAddEditModal
    ),

    "Confirm feeder set deletion",
    (v: T) => `Are you sure to revoke the PCB feeder "${v.value} ${v.footprint}" ?`,
    (props) => {
      return useNestorAPI<undefined, NestorPcbAssemblyFeeder>(
        (el) => {
          assert(el !== undefined);
          return NestorServerRoutes.pcb_assembly_feeder_revoke(props.fwProps.feeder_set.id, el.id);
        },
        false,
        undefined,
        { method: "DELETE" },
        props.list.refresh
      );
    }
  );
})();

export const PCBAssemblyFeederListCard = (props: { feeder_set: NestorPcbAssemblyFeederSet }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.sort = [
      {
        dir: "desc",
        field: "created_at",
      },
    ];

    return defaultFilter;
  }, []);
  return (
    <div className="m-2">
      <CardComponent header="PCB Feeder list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <GenericContainer
            //cardHeader="Gateway list"
            list={usePcbAssemblyFeederList(props.feeder_set.id)}
            fwProps={{ feeder_set: props.feeder_set }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};
