import { NestorSelectContextProvider } from "nestor/contexts/NestorSelectContext";
import React from "react";
import { DeviceListCard } from "../inventory/devices/List";
import { MassEditCard } from "../inventory/devices/MassEditCard";

export const MassEditIventory = ({}: {}) => {
  return (
    <NestorSelectContextProvider>
      <MassEditCard />
      <DeviceListCard />
    </NestorSelectContextProvider>
  );
};
