// Hooks

// Classes

// Components

// Contexts
import { EmployeeColumn } from "nestor/components/tables/columns/EmployeeColumn";
import { SupplierColumn } from "nestor/components/tables/columns/SupplierColumn";
import { useNestorAPI } from "nestor/hooks/api/useNestorAPI";
import { useProjectCriticalSupplierList } from "nestor/hooks/api/useProject";
import { useAmIQCManager, useHaveIGrantOneOf, useMyId } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorEmployeeId } from "nestor/types/company/employee";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorProjectSupplier } from "nestor/types/projects/project_suppliers";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { EditButton, RemoveButton } from "@csem/lists";
import { StandardTableFilterContext, StandardTableFilterCtxProvider } from "@csem/lists";
import { DefaultStandardTableFilter } from "@csem/lists";
import { TableSearch } from "@csem/lists";
// ## Generic
import { ButtonsColumn, SimpleColumn, TableRenderer } from "@csem/lists";
import {
  EditMethod,
  GenericListContent,
  HasAddition,
  HasDeletion,
  HasEdition,
  HasPagination,
  HasRefresh,
  HasSearch,
  RemoveMethod,
} from "@csem/lists";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { CriticalSupplierProjectAddEditModal } from "./modals/AddEditCriticalSupplier";
import { ProjectColumn } from "nestor/components/tables/columns/ProjectColumn";
import { NestorSupplier } from "nestor/types/purchasing/supplier";
import { BadgeQualification } from "../../purchasing/suppliers/ListQuality";
import { DownloadAttachmentFbColumn } from "nestor/components/tables/columns/DownloadColumn";
import moment from "moment";

type T = NestorProjectSupplier;
type FW = {
  project?: NestorProject;
  supplier?: NestorSupplier;
  employee_id: NestorEmployeeId;
  qc_manager: boolean;
};
type FWLst = EditMethod<T, FW> & RemoveMethod<T, FW>;
const Table = (function () {
  return TableRenderer<T, FW, FWLst, {}, "id">({
    key: "id",
    columns: [
      SupplierColumn("supplier", "Supplier", "supplier_id", (e) => e.supplier),
      ProjectColumn("project", "Project", "project_id", true, false, (e) => e.project),
      SimpleColumn("class", "Criticality", (e) => e.class),
      SimpleColumn("scope", "Scope", (e) => e.scope),
      SimpleColumn("class", "Qualification status", (e) => <BadgeQualification criticality={e} />),

      SimpleColumn("iso9001", "ISO 9001", (e) => {
        if (!e.supplier_qualification) {
          return <span className="text-muted ">Not qualified</span>;
        }

        if (e.supplier_qualification.iso_9001) {
          return "Yes";
        } else {
          return "No";
        }
      }),
      SimpleColumn("iso13485", "ISO 13485", (e) => {
        if (!e.supplier_qualification) {
          return <span className="text-muted ">Not qualified</span>;
        }

        if (e.supplier_qualification.iso_13485) {
          return "Yes";
        } else {
          return "No";
        }
      }),

      EmployeeColumn("employee", "Qualified by", "employee_id", (e) => e.supplier_qualification?.employee),
      ButtonsColumn("actions", "Actions", [
        EditButton("Edit", (e, fw) => fw.project?.manager_id === fw.employee_id || fw.qc_manager),
        RemoveButton("Remove", (e, fw) => fw.project?.manager_id === fw.employee_id || fw.qc_manager),
      ]),
      DownloadAttachmentFbColumn(
        "ef611dl",
        "Signed EF611",
        "EF611",
        (el) =>
          el.supplier_qualification?.ef611 && el.supplier_qualification?.ef611.length > 0
            ? el.supplier_qualification?.ef611[0]
            : undefined,
        <span className="text-danger">- None -</span>
      ),
    ],

    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const TableSimple = (function () {
  return TableRenderer<T, FW, {}, {}, "id">({
    key: "id",
    columns: [
      ProjectColumn("project", "Project", "project_id", true, false, (e) => e.project),
      SimpleColumn("class", "Criticality", (e) => {
        return (
          <>
            <BadgeQualification criticality={e}></BadgeQualification>
            <br />
            {e.class}
            {e.supplier_qualification && e.supplier_qualification.revoked_at == null && (
              <>&nbsp;(qual. until {moment(e.supplier_qualification.expires_at).format("YYYY-MM-DD")})</>
            )}
          </>
        );
      }),
    ],
    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const TablePurchase = (function () {
  return TableRenderer<T, FW, {}, {}, "id">({
    key: "id",
    columns: [
      SimpleColumn("class", "Class", (e) => {
        return e.class;
      }),
      SimpleColumn("scope", "Scope", (e) => {
        return e.scope;
      }),

      SimpleColumn("class", "Qual.", (e) => {
        return (
          <>
            <BadgeQualification criticality={e}></BadgeQualification>

            {e.supplier_qualification && e.supplier_qualification.revoked_at == null && (
              <>&nbsp;(qual. until {moment(e.supplier_qualification.expires_at).format("YYYY-MM-DD")})</>
            )}
          </>
        );
      }),
    ],
    useLineParamInjection: (el, forwarded) => {
      return {};
    },
  });
})();

const ContainerSimple = HasPagination(GenericListContent<FW, T>(), undefined, StandardTableFilterContext);

const Container = HasDeletion(
  HasAddition(
    HasEdition(
      HasSearch(
        HasRefresh(ContainerSimple),
        {
          name: "contains",
          code: "contains",
        },
        "or",
        "",
        TableSearch
      ),
      CriticalSupplierProjectAddEditModal
    ),
    CriticalSupplierProjectAddEditModal,
    "Link",
    "plus",
    "success",
    function (fw) {
      if (!fw) {
        return false;
      }
      return fw.employee_id === fw.project?.manager_id || fw.qc_manager;
    }
  ),
  "Revoke",
  (v: T) => `Are you sure to revoke this criticality entry ?`,
  (props) => {
    return useNestorAPI<undefined, NestorProjectSupplier>(
      (el) => {
        assert(el !== undefined);
        return NestorServerRoutes.suppliers.criticalities.project_revoke(el.id);
      },
      false,
      undefined,
      { method: "DELETE" },
      props.list.refresh
    );
  }
);

export const ProjectCriticalSupplierListCard = (props: {
  shortform?: boolean;
  project?: NestorProject;
  supplier?: NestorSupplier;
}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    if (props.project) {
      defaultFilter.filter.filters[0].filters[0] = {
        field: "project_id",
        operator: "eq",
        value: props.project.id,
      };
    }

    if (props.supplier) {
      defaultFilter.filter.filters[0].filters[0] = {
        field: "supplier_id",
        operator: "eq",
        value: props.supplier.id,
      };
    }

    return defaultFilter;
  }, [props.project, props.supplier]);

  const employee_id = useMyId();
  const qc = useAmIQCManager();
  //  const isElevated = useAmIElevated();

  return (
    <div className="">
      <CardComponent header="Criticality list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <Container
            //cardHeader="Gateway list"
            list={useProjectCriticalSupplierList()}
            fwProps={{
              project: props.project,
              supplier: props.supplier,
              employee_id: employee_id,
              qc_manager: qc,
            }}
            listTemplate={Table}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

export const ProjectCriticalSupplierListCardSimple = (props: {
  shortform?: boolean;
  project?: NestorProject;
  supplier?: NestorSupplier;
}) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    if (props.project) {
      defaultFilter.filter.filters[0].filters[0] = {
        field: "project_id",
        operator: "eq",
        value: props.project.id,
      };
    }

    if (props.supplier) {
      defaultFilter.filter.filters[0].filters[0] = {
        field: "supplier_id",
        operator: "eq",
        value: props.supplier.id,
      };
    }

    return defaultFilter;
  }, [props.project, props.supplier]);

  const employee_id = useMyId();
  const qc = useAmIQCManager();
  return (
    <div className="">
      <CardComponent header="Criticality list">
        <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
          <ContainerSimple
            //cardHeader="Gateway list"
            list={useProjectCriticalSupplierList()}
            fwProps={{
              project: props.project,
              supplier: props.supplier,
              employee_id: employee_id,
              qc_manager: qc,
            }}
            listTemplate={TableSimple}
            additionalContent={[]}
            righthandContent={[]}
            lefthandContent={[]}
            bottomContent={[]}
          />
        </StandardTableFilterCtxProvider>
      </CardComponent>
    </div>
  );
};

export const ProjectCriticalSupplierListPurchase = (props: { project: NestorProject; supplier: NestorSupplier }) => {
  const defaultFilter = useMemo(() => {
    const defaultFilter = cloneDeep(DefaultStandardTableFilter);

    defaultFilter.filter.filters[0].filters[0] = {
      field: "project_id",
      operator: "eq",
      value: props.project.id,
    };

    defaultFilter.filter.filters[0].filters[0] = {
      field: "supplier_id",
      operator: "eq",
      value: props.supplier.id,
    };

    return defaultFilter;
  }, [props.project, props.supplier]);

  const employee_id = useMyId();
  const qc = useAmIQCManager();
  return (
    <StandardTableFilterCtxProvider defaultValue={defaultFilter}>
      <ContainerSimple
        //cardHeader="Gateway list"
        list={useProjectCriticalSupplierList()}
        fwProps={{
          project: props.project,
          supplier: props.supplier,
          employee_id: employee_id,
          qc_manager: qc,
        }}
        listTemplate={TablePurchase}
        additionalContent={[]}
        righthandContent={[]}
        lefthandContent={[]}
        bottomContent={[]}
      />
    </StandardTableFilterCtxProvider>
  );
};
