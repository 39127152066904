import { NestorDeviceType } from "nestor/types/inventory/device_type";
import { apiList } from "@csem/api";
import { useNestorListApiCall } from "./useNestorListApi";

type T = NestorDeviceType;

/**
 * Fetches all device types
 * @returns An API call to the list of device types
 */
export const useDeviceTypeList = (): apiList<T> => {
  return useNestorListApiCall<T>(`list/inventory/device_types`, "device type");
};
