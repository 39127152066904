import { ProposeSigneesModal } from "nestor/components/modals/ProposeSignees";
import { EmployeeInfo } from "nestor/components/renderers/EmployeeInfos";
import { useHasPermission, usePermissionRefresh } from "nestor/contexts/NestorPermissionContext";
import { NestorPOST, useNestorAPI, useNestorAPIForms } from "nestor/hooks/api/useNestorAPI";
import { useOrderChangeState } from "nestor/hooks/api/useOrderSingle";
import { useAmISecretary } from "nestor/hooks/me";
import NestorServerRoutes from "nestor/server_routes";
import { NestorSectorId } from "nestor/types/company/sector";
import { NestorProject } from "nestor/types/projects/projects";
import { NestorProjectWorkpackage } from "nestor/types/projects/workpackage";
import { NestorOrderable } from "nestor/types/purchasing/article";
import { NestorCostElementId } from "nestor/types/purchasing/cost_element";
import { NestorOrder, NestorOrderState, NestorOrderTransferingType } from "nestor/types/purchasing/orders";
import { NestorDateTimeString } from "nestor/types/util/dates";
import { CardComponent } from "@csem/shared-utils";
import assert from "assert";
import { FieldValueType, useFormTextField } from "@csem/forms";
import { useFieldOptRequired } from "@csem/forms";
import { useForm } from "@csem/forms";
import { FormTemplateDefault } from "@csem/forms";
import { IOState } from "@csem/api";
import { useConfirmationModal } from "@csem/shared-utils";
import { without } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import settings from "settings";
import url from "url";
import { SupplierInformationCard } from "../../company/suppliers/SupplierInformationCard";
import { MonetaryAmount } from "../../util/MonetaryAmountRenderer";
import { TextWithFallback } from "../../util/TextOrNoInfo";
import { OrderArticleListInfo } from "./ArticlesInfo";
import NestorOrderProgress, { useOrderChangeStateCb } from "./Progress";
import { SigningCard } from "./Signatures";

/*
const TransferModal = ({
  setNotifications,
}: {
  setNotifications: React.Dispatch<React.SetStateAction<Array<string>>>;
}) => {
  const apiEmails = useNestorAPI<Array<{ email: string; description: string }>>("company/divisions/emails", true);
  return (
    <>
      <IOState source={apiEmails.state} success={null} />
      {apiEmails.result?.map((email) => (
        <div className="mb-2">
          <label className="checkbox">
            <input
              type="checkbox"
              onChange={(e) =>
                setNotifications((n) => {
                  if (e.target.checked) {
                    return [...n, email.email];
                  } else {
                    return without(n, email.email);
                  }
                })
              }
            />{" "}
            <span className="fw-bold">{email.description}</span> <span className="text-muted">({email.email})</span>
          </label>
        </div>
      ))}
    </>
  );
};
*/
export const OrderInfos: React.FunctionComponent<{
  order: NestorOrder;
  refresh: Function;
}> = (props) => {
  const { refresh } = props;

  useEffect(() => {
    refresh();
  }, []);

  const secretary = useAmISecretary();

  // Go to stage 2
  const canReject = useHasPermission("can_return_edit");

  // Go to stage 3
  const canSendSecretary = useHasPermission("can_send_secretary");
  const canReturnToProcessing = useHasPermission("can_return_processed");

  // Go to stage 5
  const canValidate = useHasPermission("can_validate");
  const canReturnToProcessed = useHasPermission("can_return_transferring");

  // Go to stage 6
  const canTransfer = useHasPermission("can_transfer");

  // Go to stage 7
  const canFinalize = useHasPermission("can_finalize");

  // Allowed to cancel the order (stays in the database)
  const canCancel = useHasPermission("can_invalidate");

  // Allowed to delete the order (gets removed from the database)
  const canDelete = useHasPermission("can_delete");

  const canEdit = useHasPermission("can_edit");

  const changeState = useOrderChangeStateCb(props.order.id, refresh);

  const [notifications, setNotifications] = useState<Array<string>>([]);
  /*const transferEmailModal = useConfirmationModal<number>(
    "Who should we notify ?",
    <TransferModal setNotifications={setNotifications} />,
    (v) => changeState(v),
    "Could not change the order state",
    useChangeState
  );*/

  const order = props.order;

  const history = useHistory();
  const { url: route_url } = useRouteMatch();

  const apiInvalidate = useNestorAPI<any, NestorOrder>(`purchasing/orders/${order.id}/delete`, false);
  const apiInvalidateDoQuery = apiInvalidate.doQuery;
  const invalidate = useConfirmationModal<NestorOrder>(
    "Are you sure ?",
    (param) => {
      return "Please confirm that this order should be deleted";
    },
    useCallback(() => {
      apiInvalidateDoQuery().then(() => {
        history.push(url.resolve(route_url, "../.."));
      });
    }, [apiInvalidateDoQuery, route_url, history]),
    "Could not delete the order",
    apiInvalidate
  );

  const cancel = useConfirmationModal<NestorOrder>(
    "Are you sure ?",
    (param) => {
      return "Please confirm that this order should be cancelled";
    },
    useCallback(() => {
      changeState(200);
    }, [changeState]),
    "Could not cancel the order"
  );

  const [showSigneesModal, setShowSigneesModal] = useState<boolean>(false);

  return (
    <>
      {order.state === NestorOrderState.CANCELLED && (
        <div className="alert alert-danger">This order has been cancelled</div>
      )}

      <NestorOrderProgress order={order} refresh={refresh}></NestorOrderProgress>

      <div className="row">
        <div className="col-xl-4 mb-4 col-lg-6 col-12">
          {" "}
          <div className="vstack gap-2 h-100">
            <CardComponent
              header="Nestor information "
              additionalClasses={`h-100 ${
                order.state === NestorOrderState.PROCESSING && order.urgent
                  ? "bg-danger bg-opacity-25"
                  : order.has_chemicals
                  ? "bg-warning bg-opacity-25"
                  : ""
              }`}
            >
              {order.state === NestorOrderState.PROCESSING && order.urgent ? (
                <h5 className="fw-bold text-danger text-center mb-3">This is an urgent order</h5>
              ) : null}
              {order.has_chemicals ? (
                <h5 className="fw-bold text-danger text-center mb-3">This order has chemicals</h5>
              ) : null}

              <div className="row align-items-center mb-3">
                <div className="col-4">Nestor NUID</div>
                <div className="col-8">
                  <h5> {order.nuid}</h5>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-4">SAP ID</div>
                <div className="col-8">
                  <h5>
                    <TextWithFallback text={order.sap_id} />
                  </h5>
                </div>
              </div>

              <EmployeeInfo employee={order.employee} text="Initiated by" />

              {showSigneesModal && (
                <ProposeSigneesModal
                  handleClose={() => {
                    setShowSigneesModal(false);
                  }}
                  onValidated={() => {
                    refresh();
                  }}
                  fw={undefined}
                  el={order}
                  show={showSigneesModal}
                ></ProposeSigneesModal>
              )}

              <EmployeeInfo employee={order.signee_left} text="Left signee" />
              {order.signee_left_po && <EmployeeInfo employee={order.signee_left_po} text="Left signee (PO)" />}
              <EmployeeInfo employee={order.signee_right} text="Right signee" />
              {order.signee_right_po && <EmployeeInfo employee={order.signee_right_po} text="Right signee (PO)" />}
              <EmployeeInfo employee={order.secretary} text="Secretary" />

              <div className="row">
                {canEdit && (
                  <div className="col-6">
                    <button
                      type="button"
                      onClick={() => setShowSigneesModal(true)}
                      className="btn btn-outline-primary w-100 h-100"
                    >
                      Propose signees
                    </button>
                  </div>
                )}
              </div>
            </CardComponent>

            <CardComponent header="Actions" additionalClasses="h-100">
              <div className="row align-items-center mb-3">
                <div className="col-4">PDF Download</div>
                <div className="col-8">
                  <h4>
                    <a
                      href={`${settings.NESTOR_URL}/download/purchasing/orders/${order.id}/fr/print`}
                      className="btn btn-primary mx-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <em className="bi-download bi"></em> fr
                    </a>

                    <a
                      href={`${settings.NESTOR_URL}/download/purchasing/orders/${order.id}/de/print`}
                      className="btn btn-primary mx-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <em className="bi-download bi"></em> de
                    </a>

                    <a
                      href={`${settings.NESTOR_URL}/download/purchasing/orders/${order.id}/en/print`}
                      className="btn btn-primary mx-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <em className="bi-download bi"></em> en
                    </a>
                  </h4>
                </div>
              </div>

              <div className="row align-items-center mb-3">
                <div className="col-4">PDF Download (internal)</div>
                <div className="col-8">
                  <h4>
                    <a
                      href={`${settings.NESTOR_URL}/download/purchasing/orders/${order.id}/en/print/internal`}
                      className="btn btn-primary mx-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <em className="bi-download bi"></em> en
                    </a>
                  </h4>
                </div>
              </div>
              {canDelete ? (
                <div className="row align-items-center mb-3 text-danger">
                  {invalidate.Modal}

                  <div className="col-4">Delete order</div>
                  <div className="col-8">
                    <h4>
                      <button
                        type="button"
                        onClick={() => invalidate.invoke(order)}
                        className="btn btn-danger mx-1 text-white"
                      >
                        <em className="bi-x bi"></em> Delete
                      </button>
                    </h4>
                  </div>
                </div>
              ) : null}

              {canCancel ? (
                <div className="row align-items-center mb-3 text-danger">
                  {cancel.Modal}

                  <div className="col-4">Cancel order</div>
                  <div className="col-8">
                    <h4>
                      <button
                        type="button"
                        onClick={() => cancel.invoke(order)}
                        className="btn btn-danger mx-1 text-white"
                      >
                        <em className="bi-x bi"></em> Cancel
                      </button>
                    </h4>
                  </div>
                </div>
              ) : null}
            </CardComponent>
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 col-12 mb-4">
          <div className="vstack gap-2 h-100">
            <SupplierInformationCard supplier={order.supplier} />
            <SigningCard admin={secretary && canValidate} order={order} refresh={refresh} />
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-12 mb-4">
          <div className="vstack gap-2 h-100">
            <MiscellaniousCard order={order} />

            <OrderArticleListInfo orderId={props.order.id} showArticleTables={false} />

            {/*            <ImputingSourceCard source={order} />*/}
            <SnapshotCard order={order} />
          </div>
        </div>

        {/*}  <div className="col-lg-4 mb-4 ">
          <CardComponent header="Financial" additionalClasses="h-100">
            
          </CardComponent>
        </div>
              */}
      </div>
    </>
  );
};

const MiscellaniousCard = ({ order }: { order: NestorOrder }) => {
  const all_inventory_processed = order.articles!.reduce((acc, article) => {
    if (article.orderable_type !== NestorOrderable.DEVICE) {
      return acc;
    }
    return acc && article.devices!.reduce((acc, device) => acc && device.to_update === 0, true);
  }, true);

  const invoices_final = !!order.invoices!.find((i) => i.type === "final" || i.type === "full");
  return (
    <CardComponent header="Miscellaneous">
      <label className="text-muted">Who transfers the order ?</label>

      {order.transferring === NestorOrderTransferingType.INTERNAL ? (
        <h5 className="text-info">This order is internal. It does not require transferring to a supplier</h5>
      ) : order.transferring === NestorOrderTransferingType.HANDLER ? (
        <h5 className="text-secondary">The initiator is in charge of transferring to the supplier</h5>
      ) : (
        <h5 className="text-primary">An administrative assistant is in charge of transferring to the supplier</h5>
      )}

      <label className="text-muted">Who is the point of contact for this order with the supplier ?</label>
      {order.contact === "initiator" ? (
        <h5 className="text-info">The initiator</h5>
      ) : order.contact === "backoffice" ? (
        <h5 className="text-secondary">The backoffice / secretary</h5>
      ) : (
        <h5 className="text-primary">The project manager / group leader</h5>
      )}

      {order.state === NestorOrderState.WAITING_CLOSE && !all_inventory_processed ? (
        <label className="text-muted">
          <h5 className="text-danger">There are unprocessed draft inventory entries.</h5>
        </label>
      ) : null}

      {order.state === NestorOrderState.WAITING_CLOSE && !invoices_final ? (
        <label className="text-muted">
          <h5 className="text-danger">The order can only be closed when a final invoice has been entered.</h5>
        </label>
      ) : null}
    </CardComponent>
  );
};

const SnapshotCard = ({ order }: { order: NestorOrder }) => {
  const input = useFormTextField({
    label: "Reason for the snapshot",
    type: FieldValueType.STRING,
    defaultValue: "" as string,
    validationOnChange: useFieldOptRequired<string>(true),
  });

  const api = useNestorAPI(NestorServerRoutes.order_create_snapshot(order.id), false, undefined, NestorPOST);

  const form = useForm({
    onSubmit: () => {
      api.doQuery(undefined, {
        comment: input.value,
      });
    },
    Template: FormTemplateDefault,
    fields: {
      i: input,
    },
    fw: {},
  });

  return (
    <CardComponent header="Snapshotting" additionalClasses="h-100 flex-grow-1">
      <IOState source={api.state}></IOState>
      {form.Content}
      <button
        className="btn btn-primary"
        onClick={() => {
          form.submit();
        }}
      >
        Create a snapshot
      </button>
    </CardComponent>
  );
};

const ImputingSourceCard = <
  T extends {
    cost_element_id: NestorCostElementId;
    project: NestorProject | null;
    workpackage: NestorProjectWorkpackage | null;
    section_id: NestorSectorId | null;
  }
>({
  source,
}: {
  source: T;
}) => {
  return (
    <>
      <CardComponent header="Imputing source" additionalClasses="h-100 flex-grow-1">
        <div className="row align-items-center mb-3">
          <div className="col-4">Charged to</div>
          <div className="col-8">
            <h5>{source.project ? "Project" : "Group " + source.section_id}</h5>
          </div>
        </div>

        {source.project ? (
          <>
            <div className="row mb-3">
              <div className="col-4">Project</div>
              <div className="col-8">
                <h5>{source.project?.code}</h5>
                <div className="text-muted">{source.project?.name}</div>
              </div>
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-4">Workpackage</div>
              <div className="col-8">
                <h6>
                  {source.workpackage ? (
                    <>
                      {source.workpackage.workpackage}{" "}
                      <small className="text-muted">({source.workpackage.name}) </small>
                    </>
                  ) : (
                    "Unknown"
                  )}
                </h6>
              </div>
            </div>
          </>
        ) : /* source.section_id ? (
          <>
            <div className="row align-items-center mb-3">
              <div className="col-4">{lang.sector}</div>
              <div className="col-8">
                <h5>{source.section_id}</h5>
              </div>
            </div>
          </>
        ) :*/ null}

        <div className="row align-items-center mb-3">
          <div className="col-4">Cost element</div>
          <div className="col-8">
            <h5>
              {source.cost_element_id ? (
                source.cost_element_id
              ) : (
                <span className="text-muted">No cost information</span>
              )}{" "}
            </h5>
          </div>
        </div>
      </CardComponent>
    </>
  );
};
