import { NestorBuilding } from "nestor/types/company/building";
import { useFormSelectField } from "@csem/forms";
import { CommonFieldUI } from "@csem/forms";
import { NestorContext } from "nestor/contexts/NestorContext";
import { useContext } from "react";
import { NestorLabelPrinter } from "nestor/types/util/label_printer";

export const useLabelPrinterField = (props: CommonFieldUI<string | number | undefined>) => {
  const ctx = useContext(NestorContext);
  const printers = ctx?.printers;

  return useFormSelectField({
    ...props,
    options: printers,
    keyMap: (el: NestorLabelPrinter) => el.id,
    valMap: (el: NestorLabelPrinter) => el.name,

    getterFromKey: (el: string | number | undefined, list?: NestorLabelPrinter[]) => {
      if (el === undefined || list === undefined) {
        return;
      }
      return list.find((e) => e.id == el);
    },
  });
};
