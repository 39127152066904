import { CardComponent } from "@csem/shared-utils";
import { BuildingRenderer } from "nestor/components/renderers/BuildingRenderer";
import { useRoomSingle } from "nestor/hooks/api/useRoomList";
import { NestorRoom, NestorRoomId } from "nestor/types/company/room";

export const RoomOneLineWithFetch: React.FunctionComponent<{ roomid: NestorRoomId }> = (props) => {
  const room = useRoomSingle(props.roomid);
  if (!room.element) {
    return null;
  }

  return <RoomOneLine room={room.element} refresh={room.refresh} />;
};

export const RoomOneLine: React.FunctionComponent<{
  room: NestorRoom;
  refresh: Function;
}> = ({ room, refresh }) => {
  return (
    <CardComponent header="Room you're looking at" additionalClasses={"mb-2"}>
      <div className="row">
        <div className="col-xl-1 col-md-4 col-6 align-self-center  mb-2">
          {room.displayed_name ? room.displayed_name : room.name}
        </div>

        <div className="col-xl-1  col-md-4 col-6 mb-2">
          <strong>Alernate name:</strong>
          <br />
          {room.alternate_name}
        </div>

        <div className="col-xl-1  col-md-4 col-6  mb-2">
          <strong>Building:</strong>
          <br />
          <BuildingRenderer building={room.floor?.building || null} floor={room.floor} />
        </div>
      </div>
    </CardComponent>
  );
};
