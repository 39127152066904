import { NestorDeviceType } from "nestor/types/inventory/device_type";
import { FilterProps } from "@csem/lists";
import { FilterableColumn, SimpleColumn } from "@csem/lists";
import { useEffect } from "react";
import { useDeviceCatTypeFields } from "../../forms/bundles/DeviceCategoryType";
import { DeviceTypeCategoryRenderer } from "../../renderers/DeviceType";

export const DeviceTypeColumn = <T,>(
  name: string,
  label: string,
  device_type_field: string,
  device_category_field: string,
  renderer: (el: T) => NestorDeviceType | undefined
) => {
  const SearchField: React.FunctionComponent<FilterProps<string | number>> = function ({ onChange }) {
    const { category_id, device_type_id } = useDeviceCatTypeFields();

    useEffect(() => {
      if (device_type_id.value === undefined) {
        return;
      }
      onChange(parseInt(device_type_id.value as string), "eq", "type");
    }, [device_type_id.value, onChange]);

    useEffect(() => {
      if (category_id.value === undefined) {
        return;
      }
      onChange(parseInt(category_id.value as string), "eq", "category");
    }, [category_id.value, onChange]);

    return (
      <>
        <button
          className="btn btn-outline-primary mb-1"
          onClick={() => {
            onChange(undefined, "eq", "type");
            onChange(undefined, "eq", "category");
          }}
        >
          Clear
        </button>
        {category_id.Content}
        {device_type_id.Content}
      </>
    );
  };

  return FilterableColumn(
    SimpleColumn(name, label, (el: T) => {
      return <DeviceTypeCategoryRenderer type={renderer(el)} />;
    }),

    SearchField,

    {
      type: device_type_field,
      category: device_category_field,
    }
  );
};
